import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import {Button, Form, InputGroup} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';

import EditorConvertToHTML from 'components/editorConvertToHTML/editorConvertToHTML';

import {ReactComponent as IcoClose} from 'assets/svg/ico-close.svg';
import checkRealTextLength from 'utils/checkTextLength';
import {Welcome} from 'models/welcome';
import welcomeServices from 'store/welcomeServices';
import classNames from 'classnames';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

interface IWelcomeContent {
	myWelcome: Welcome;
	setMyWelcome: (value: Welcome) => void;
	warning: boolean;
	warningLink: boolean;
}
const WelcomeContent: FunctionComponent<IWelcomeContent> = function WelcomeContent({
	setMyWelcome,
	myWelcome,
	warning,
	warningLink,
}) {
	const translations = useL10n();

	const [isLink, setIsLink] = useState(true);
	const [isCentered, setIsCentered] = useState(false);

	const {welcome, setWelcome, welcomeDraft, setWelcomeDraft, currentStep} = useLocalObservable(
		() => welcomeServices
	);

	const headClassNames = classNames('welcome__template-header', {
		'welcome__template-header--centered': isCentered,
	});

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMyWelcome({
			...myWelcome,
			[event.target.name]: value.trim().length ? value : value.trim(),
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});
	};

	const onChangeIsLink = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsLink(checked);

		setMyWelcome({
			...myWelcome,
			buttonLink: checked ? myWelcome.buttonLink : '',
			buttonText: checked ? myWelcome.buttonText : '',
		});
	};

	const onChangeOpenTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setMyWelcome({
			...myWelcome,
			openType: value as LinkOpenType,
		});
	};

	const setUnsavedEditorChanges = (value: string) => {
		setMyWelcome({
			...myWelcome,
			text: value,
		});
		const elem = document.querySelector('.welcome__template-text p');
		if (elem && window.getComputedStyle(elem).textAlign === 'center') {
			setIsCentered(true);
		} else setIsCentered(false);
	};

	useEffect(() => {
		if (welcome && welcome.text) setWelcome({...welcome, text: welcome.text});
		if (welcomeDraft && myWelcome.text) setWelcomeDraft({...welcomeDraft, text: myWelcome.text});
	}, [currentStep]);

	return (
		<section className='welcome__content'>
			<div className='col-6'>
				<div className='block mb-3'>
					<p className='h5 mb-4'>{translations.welcome.steps.content.title}</p>
					<Form.Group controlId='highlightTitle' className='mb-2'>
						<Form.Label>{translations.welcome.steps.content.welcomeTitle}</Form.Label>
						{warning && (
							<p className='text-danger'>
								<b>{translations.welcome.warning}</b>
							</p>
						)}

						<Form.Control
							placeholder={translations.welcome.steps.content.welcomeTitle}
							name='title'
							value={myWelcome.title}
							onChange={onChangeHandler}
							maxLength={255}
						/>
						<p className='text-end text-secondary'>
							<small>{myWelcome.titleLength || 0}/255</small>
						</p>
					</Form.Group>
					<p>{translations.welcome.steps.content.welcomeText}</p>
					<EditorConvertToHTML
						html={welcome?.text || welcomeDraft?.text || ''}
						setUnsavedEditorChanges={setUnsavedEditorChanges}
						type='welcomeHTML'
						isBlockType={false}
						isFontSize={false}
					/>
				</div>
				<div className='block mb-5'>
					<p className='h5 mb-4'>{translations.welcome.steps.content.btn}</p>

					<Form.Group controlId='welcomeBtn' className='mb-2'>
						<Form.Label>{translations.welcome.steps.content.btnText}</Form.Label>
						<InputGroup>
							<InputGroup.Checkbox
								className='checkbox-black'
								checked={isLink}
								onChange={onChangeIsLink}
							/>

							<Form.Control
								placeholder={translations.welcome.steps.content.btnText}
								name='buttonText'
								required={isLink}
								disabled={!isLink}
								value={myWelcome.buttonText}
								onChange={onChangeHandler}
								maxLength={30}
							/>
						</InputGroup>
						<p className='text-end text-secondary'>
							<small>{myWelcome.buttonTextLength || 0}/30</small>
						</p>
					</Form.Group>
					<Form.Group controlId='welcomeLink' className='mb-2'>
						<Form.Label>{translations.welcome.steps.content.link}</Form.Label>
						{warningLink && (
							<p className='text-danger'>
								<b>{translations.welcome.warningLink}</b>
							</p>
						)}
						<Form.Control
							placeholder={translations.welcome.steps.content.btnText}
							name='buttonLink'
							disabled={!isLink}
							value={welcome?.buttonLink}
							onChange={onChangeHandler}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='welcomeLinkOpenType'>
						<div>
							<Form.Check
								inline
								className='checkbox-black'
								type='radio'
								label={translations.linkOpenType.newWindow}
								id='linkOpenType2'
								value={LinkOpenType.NEW_WINDOW}
								onChange={onChangeOpenTypeHandler}
								checked={myWelcome.openType === LinkOpenType.NEW_WINDOW}
							/>
						</div>

						<div>
							<Form.Check
								inline
								className='checkbox-black'
								type='radio'
								label={translations.linkOpenType.postMessage}
								id='linkOpenType3'
								value={LinkOpenType.POST_MESSAGE}
								onChange={onChangeOpenTypeHandler}
								checked={myWelcome.openType === LinkOpenType.POST_MESSAGE}
							/>
						</div>
					</Form.Group>
				</div>
			</div>
			<div className='col-6'>
				<div className='welcome__template'>
					<div className={headClassNames}>
						<p className='welcome__template-title'>
							{!welcome
								? myWelcome.title || translations.welcome.placeholder.title
								: myWelcome.title}
						</p>
						{(myWelcome.buttonLink || !myWelcome.buttonText) && (
							<IcoClose className='welcome__template-close' />
						)}
					</div>

					<div className='welcome__template-body'>
						<p
							className='welcome__template-text'
							dangerouslySetInnerHTML={{
								__html: !welcome
									? myWelcome?.text || translations.welcome.placeholder.text
									: myWelcome?.text,
							}}
						/>
					</div>

					{myWelcome.buttonText && (
						<div className='welcome__template-footer'>
							<Button className='welcome__template-btn'>
								{myWelcome.buttonText || translations.welcome.steps.content.btnText}
							</Button>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default observer(WelcomeContent);

import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import useL10n from 'l10n/useL10n';

import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import welcomeServices from 'store/welcomeServices';
import SettingsService from 'services/api/SettingsService';

export default () => {
	const translations = useL10n();

	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {setWelcome} = useLocalObservable(() => welcomeServices);

	const getWelcome = async (lang: string) => {
		const response = await SettingsService.getWelcome(accessToken, lang);
		if (response.status === ResponseStatus.SUCCESS) {
			setWelcome(response.data);
		}
	};

	const updateWelcome = async (body: any) => {
		const response = await SettingsService.postWelcome(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			getWelcome(body.lang);
			return true;
		}
		addToast({
			title: translations.toasts.errorTitle,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const deleteWelcome = async (lang: string) => {
		const response = await SettingsService.deleteWelcome(accessToken, lang);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			getWelcome(lang);
			return;
		}
		addToast({
			title: translations.toasts.errorTitle,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	return {
		getWelcome,
		updateWelcome,
		deleteWelcome,
	};
};

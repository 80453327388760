import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import FlaggedMessageService from 'services/api/FlaggedMessageService';
import flaggedMessageService from 'store/flaggedMessageService';
import messagesServices from 'store/messagesServices';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {flaggedMessageCount, setFlaggedMessageCount} = useLocalObservable(
		() => flaggedMessageService
	);
	const {updateMessageFieldById} = useLocalObservable(() => messagesServices);

	const getFlaggedMessages = async (limit: number, offset: number, order: string, filter: any) => {
		const response = await FlaggedMessageService.getFlaggedMessages(
			accessToken,
			limit,
			offset,
			order,
			filter
		);
		return response;
	};

	const getNewFlaggedMessagesCount = async () => {
		const response = await FlaggedMessageService.getNewFlaggedMessagesCount(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			if (!flaggedMessageCount) setFlaggedMessageCount(response.data);
		}
	};

	const resolveFlaggedMessage = async (body: {
		flaggedMessageId: number;
		status?: string;
		comment?: string;
	}) => {
		const response = await FlaggedMessageService.resolveFlaggedMessage(accessToken, body);
		return response;
	};

	const updateFlaggedMessage = async (flaggedMessageId: number, comment: string) => {
		const response = await FlaggedMessageService.updateFlaggedMessage(
			accessToken,
			flaggedMessageId,
			comment
		);
		return response;
	};

	const deleteFlaggedMessage = async (flaggedMessageId: number) => {
		const response = await FlaggedMessageService.deleteFlaggedMessage(
			accessToken,
			flaggedMessageId
		);
		if (response.status === ResponseStatus.SUCCESS) {
			updateMessageFieldById(response.data.message.id, {isFlagged: false});
		}

		return response;
	};

	return {
		getFlaggedMessages,
		getNewFlaggedMessagesCount,
		resolveFlaggedMessage,
		updateFlaggedMessage,
		deleteFlaggedMessage,
	};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';

import Papa from 'papaparse';
import autopostingService from 'store/autopostingService';
import useAutoposting from 'hooks/useAutoposting';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

const AutopostingFile: FunctionComponent = function AutopostingFile() {
	const translations = useL10n();
	const fileRef = useRef<HTMLInputElement>(null);
	const isConfirmed = useRef(false);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {data, setMessages} = useLocalObservable(() => autopostingService);
	const {appTheme} = useLocalObservable(() => appService);

	const [error, setError] = useState('');
	const [csvFile, setCsvFile] = useState<any>();

	const {addMessageMultiple, deleteMessages} = useAutoposting();

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setCsvFile(file);
			}
		}
	};

	const handleFile = async (file: any) => {
		if (file.type.indexOf('csv') === -1) {
			setError('Wrong file format');
			return;
		}
		Papa.parse(file, {
			skipEmptyLines: true,
			header: true,
			complete: async results => {
				setMessages([]);
				await deleteMessages(false);
				const messages: any = [];
				if (fileRef.current) fileRef.current.value = '';
				results.data.forEach((el: any) => {
					// eslint-disable-next-line no-restricted-syntax
					for (const [key, value] of Object.entries(el)) {
						messages.push({lang: key.toLowerCase(), text: value});
					}
				});
				if (messages.length) addMessageMultiple(messages);
			},
			error: (err: any) => {
				setError(err.message);
			},
		});
		setError('');
	};

	useEffect(() => {
		csvFile && handleFile(csvFile);
	}, [csvFile]);

	const showFileAlert = () => {
		showAlert({
			title: translations.autoposting.fileAlert.title,
			text: translations.autoposting.fileAlert.text,
			buttons: [
				{
					text: translations.autoposting.fileAlert.btns.replace,
					type: AlertBtnType.DARK,
					onClick: () => {
						isConfirmed.current = true;
						fileRef.current?.click();
						hideAlert();
					},
				},
				{
					text: translations.autoposting.fileAlert.btns.cancel,
					type: appTheme === Theme.LIGHT ? AlertBtnType.OUTLINE : AlertBtnType.OUTLINE_LIGHT,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const onChangeFileHandler = (e: any) => {
		if (
			!isConfirmed.current &&
			data
				.map(el => el.messages)
				.flat()
				.filter(el => !el.isNew).length
		) {
			e.preventDefault();
			showFileAlert();
		}
	};

	return (
		<div className='block w-50 mb-4'>
			<p className='h5 mb-2'>{translations.autoposting.fromFile.title}</p>
			<p className='text-secondary mb-2'>{translations.autoposting.fromFile.descr}</p>
			<p className='text-secondary mb-0'>{translations.autoposting.fromFile.text}</p>
			<p className='text-secondary mb-4'>{translations.autoposting.fromFile.warn}</p>
			{error && <p className='text-danger'>{error}</p>}
			<Form.Control
				type='file'
				ref={fileRef}
				onClick={onChangeFileHandler}
				onChange={uploadFile}
				accept='.csv'
			/>
		</div>
	);
};

export default observer(AutopostingFile);

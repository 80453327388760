/* eslint-disable no-param-reassign */
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class AutopostingService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		AutopostingService.baseUrl = baseUrl;
	};

	static getSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${AutopostingService.baseUrl}/settings`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postSettings = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(`${AutopostingService.baseUrl}/settings`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessageDefault = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${AutopostingService.baseUrl}/message/default`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessage = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${AutopostingService.baseUrl}/message`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addMessageDefault = async (token: UUID, body: {lang: string; text: string}) => {
		try {
			const {data, status} = await axios.post(
				`${AutopostingService.baseUrl}/message/default`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addMessage = async (token: UUID, body: {lang: string; text: string}) => {
		try {
			const {data, status} = await axios.post(`${AutopostingService.baseUrl}/message`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addMessageMultiple = async (token: UUID, messages: {lang: string; text: string}[]) => {
		try {
			const body = {
				messages,
			};
			const {data, status} = await axios.post(
				`${AutopostingService.baseUrl}/message/multiple`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessages = async (token: UUID, isDefault: boolean) => {
		try {
			const apiUrl = isDefault ? `/message/default/all` : `/message/all`;
			const {data, status} = await axios.delete(`${AutopostingService.baseUrl}${apiUrl}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessage = async (token: UUID, id: number, isDefault: boolean) => {
		try {
			const apiUrl = isDefault ? `/message/default/${id}` : `/message/${id}`;
			const {data, status} = await axios.delete(`${AutopostingService.baseUrl}${apiUrl}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessageLang = async (token: UUID, lang: string, isDefault: boolean) => {
		try {
			const apiUrl = isDefault ? `/message/default/lang/${lang}` : `/message/lang/${lang}`;
			const {data, status} = await axios.delete(`${AutopostingService.baseUrl}${apiUrl}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

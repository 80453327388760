import {useLocalObservable} from 'mobx-react-lite';
import PartnersCreatorService from 'services/api/PartnersCreatorService';
import SettingsService from 'services/api/SettingsService';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);

	const createProject = async (name: string) => {
		const response = await PartnersCreatorService.createProject(accessToken, name);
		return response;
	};

	const getSettings = async () => {
		const response = await PartnersCreatorService.getSettings(accessToken);
		return response;
	};

	const saveSettings = async (body: {dopplerAccessToken: string; gitlabAccessToken: string}) => {
		const response = await PartnersCreatorService.saveSettings(accessToken, body);
		return response;
	};

	const getProjects = async () => {
		const response = await SettingsService.getProjects(accessToken);
		return response;
	};

	const deleteProject = async (apikey: string) => {
		const response = await PartnersCreatorService.deleteProject(accessToken, apikey);
		return response;
	};

	return {createProject, getSettings, saveSettings, getProjects, deleteProject};
};

import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import ColumnResizer from 'column-resizer';
import {Table} from 'react-bootstrap';

interface IResizableTable {
	children: any;
	resizable: boolean;
	resizeOptions: any;
	variant?: string;
}
const ResizableTable: FunctionComponent<IResizableTable> = function ResizableTable({
	children,
	resizable,
	resizeOptions,
	variant,
}) {
	const tableRef = useRef<HTMLTableElement>(null);
	const [resizeElem, setResizeElem] = useState<any>();

	const enableResize = () => {
		if (!resizeElem) {
			const columnResizer = new ColumnResizer(tableRef.current, resizeOptions);
			if (tableRef.current)
				tableRef.current.className = tableRef.current?.className?.replace('grip-padding', '');
			setResizeElem(columnResizer);
		} else {
			resizeElem?.reset(resizeOptions);
		}
	};

	const disableResize = () => {
		if (resizeElem) {
			resizeElem?.reset({disable: true});
		}
	};

	useEffect(() => {
		if (tableRef.current && resizable) {
			enableResize();
		}
	}, [tableRef.current]);

	useEffect(() => {
		return disableResize();
	}, []);

	return (
		<Table variant={variant} responsive bordered hover ref={tableRef}>
			{children}
		</Table>
	);
};

export default ResizableTable;

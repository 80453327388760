import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button, Col, Container, Form, Row} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {Changelog as ChangelogType} from 'models/changelog';
import EditorConvertToHTML from 'components/editorConvertToHTML/editorConvertToHTML';
import useChangelog from 'hooks/useChangelog';
import {NavLink, useHistory, useParams} from 'react-router-dom';

const ChangelogCreate: FunctionComponent = function ChangelogCreate() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const translations = useL10n();
	const history = useHistory();

	const [validated, setValidated] = useState(false);
	const [post, setPost] = useState<ChangelogType | null>(null);
	const [currentPost, setCurrentPost] = useState<ChangelogType | null>(null);
	const [isTextWarning, setIsTextWarning] = useState(false);

	const {addChangelog, getChangelogById, updateChangelogById} = useChangelog();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setCurrentPost({
			...currentPost,
			[event.target.name]: value.trim().length ? value : value.trim(),
		});
		if (slug)
			setPost({
				...post,
				[event.target.name]: value,
			});
	};

	const setUnsavedEditorChanges = (value: string) => {
		if (value) setIsTextWarning(false);
		setCurrentPost({
			...currentPost,
			text: value,
		});
	};

	const saveChanges = async () => {
		if (slug) {
			currentPost && (await updateChangelogById(+slug, {...post, ...currentPost}));
			history.push('/changelog');
			return;
		}
		if (currentPost) {
			await addChangelog({...currentPost, date: new Date().toISOString()});
			history.push('/changelog');
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			setIsTextWarning(!currentPost?.text);
		} else {
			if (!currentPost?.text && !slug) {
				setIsTextWarning(true);
				return;
			}
			setIsTextWarning(false);
			saveChanges();
		}
	};

	const getChangelogByIdWithService = async () => {
		const response = await getChangelogById(+slug);
		if (response) {
			setPost(response);
		}
	};

	useEffect(() => {
		if (slug) {
			getChangelogByIdWithService();
		}
	}, [slug]);

	return (
		<Container fluid className='changelog pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.information.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/changelog'>{translations.sidebar.changelog.title}</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.btns.create}</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h2 mb-5'>{translations.btns.create}</p>
					<div className='block w-50'>
						<Form noValidate validated={validated} onSubmit={handleSubmit} id='changelogForm'>
							<Form.Group controlId='changelogCategory' className='mb-4'>
								<Form.Label>{translations.changelog.category}</Form.Label>
								<Form.Control
									placeholder={translations.changelog.category}
									name='name'
									value={post?.name}
									onChange={onChangeHandler}
									maxLength={100}
									required
								/>
							</Form.Group>
							<Form.Group controlId='changelogVersion' className='mb-4'>
								<Form.Label>{translations.changelog.version}</Form.Label>
								<Form.Control
									placeholder={translations.changelog.version}
									name='version'
									value={post?.version}
									onChange={onChangeHandler}
									maxLength={50}
									required
								/>
							</Form.Group>
							<div className='mb-3'>
								<Form.Label>{translations.changelog.description}</Form.Label>
								<EditorConvertToHTML
									html={post?.text || ''}
									setUnsavedEditorChanges={setUnsavedEditorChanges}
									type='changelogHTML'
									isImage
									isWarning={isTextWarning}
								/>
							</div>
							<Button
								name='save-changelog'
								variant='success'
								className='d-flex'
								type='submit'
								form='changelogForm'>
								{translations.btns.save}
							</Button>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(ChangelogCreate);

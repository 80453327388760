import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, FloatingLabel, Form} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import PageTitle from 'components/pageTitle/PageTitle';

const Slowmode: FunctionComponent = function Slowmode() {
	const translations = useL10n();

	const {settings} = useLocalObservable(() => settingsServices);
	const [isSlowmode, setIsSlowmode] = useState(settings?.enableSlowmode);
	const {getSettings, patchSettings} = useSettings();

	const onSwitchIsSlowmode = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsSlowmode(checked);
		patchSettings({
			enableSlowmode: checked,
		});
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		patchSettings({
			slowmodeDelayMS: +value,
		});
	};

	useEffect(() => {
		setIsSlowmode(settings?.enableSlowmode);
	}, [settings]);

	useEffect(() => {
		getSettings();
	}, []);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.slowMode.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.slowMode.title}
						text={translations.sidebar.slowMode.descr}
					/>
					<div className='block w-50'>
						<div className='mb-3'>
							<Form.Check
								onChange={onSwitchIsSlowmode}
								type='switch'
								id='slowmodeToggle'
								label={
									settings.enableSlowmode
										? translations.room.disableSlowMode
										: translations.room.enableSlowMode
								}
								checked={isSlowmode}
							/>
						</div>
						<FloatingLabel controlId='floatingSelect' label={translations.room.slowmodeDelay}>
							<Form.Select
								disabled={!settings.enableSlowmode}
								onChange={onSelect}
								value={settings.slowmodeDelayMS}>
								<option value='1000'>1 {translations.sec}</option>
								<option value='2000'>2 {translations.sec}</option>
								<option value='3000'>3 {translations.sec}</option>
								<option value='5000'>5 {translations.sec}</option>
								<option value='10000'>10 {translations.sec}</option>
								<option value='15000'>15 {translations.sec}</option>
								<option value='20000'>20 {translations.sec}</option>
								<option value='30000'>30 {translations.sec}</option>
								<option value='60000'>1 {translations.min}</option>
								<option value='120000'>2 {translations.min}</option>
								<option value='180000'>3 {translations.min}</option>
								<option value='300000'>5 {translations.min}</option>
							</Form.Select>
						</FloatingLabel>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Slowmode);

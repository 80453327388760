import {observer, useLocalObservable} from 'mobx-react-lite';
import {createRef, FunctionComponent, useEffect, useState} from 'react';

import {Row, Col, Button, Form} from 'react-bootstrap';
import {BsFillArrowUpCircleFill} from 'react-icons/bs';

import SocketIoService from 'services/SocketIoService';

import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import useAdminProxy from 'hooks/useAdminProxy';
import classNames from 'classnames';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import messagesServices from 'store/messagesServices';
import useChatMessage from 'hooks/useChatMessage';

interface IChatAdminForm {
	room: string;
}

const ChatAdminForm: FunctionComponent<IChatAdminForm> = function ChatAdminForm({room}) {
	const {admin, adminData} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {currentEditedMessage, setCurrentEditedMessage} = useLocalObservable(
		() => messagesServices
	);

	const {getAdmin} = useAdminProxy();
	const {editMessage} = useChatMessage();

	const translations = useL10n();

	const [messageData, setMessageData] = useState({
		name: '',
		text: '',
	});
	const [validated, setValidated] = useState(false);

	const textareaInputRef = createRef<HTMLTextAreaElement>();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMessageData({
			...messageData,
			[event.target.name]: value,
		});
	};

	const sendAdminMessage = () => {
		const data = {
			name: messageData.name,
			text: messageData.text,
			externalRoomId: room,
		};

		setMessageData({
			...messageData,
			text: '',
		});
		setTimeout(() => {
			SocketIoService.emitAdminMessage(data);
		}, 100);
	};

	const editAdminMessage = () => {
		if (currentEditedMessage) {
			editMessage(currentEditedMessage.id, {text: messageData.text});
			setMessageData({
				...messageData,
				text: '',
			});
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else {
			if (currentEditedMessage) {
				editAdminMessage();
				return;
			}
			sendAdminMessage();
		}
	};

	const getAdminWithServices = async () => {
		await getAdmin(adminData.adminId);
	};

	const cancelEditMessage = () => {
		setCurrentEditedMessage(null);
		setValidated(false);
		setMessageData({
			...messageData,
			text: '',
		});
	};

	useEffect(() => {
		if (currentEditedMessage)
			setMessageData({
				...messageData,
				text: currentEditedMessage.text,
			});
	}, [currentEditedMessage]);

	useEffect(() => {
		if (adminData?.adminId) getAdminWithServices();
	}, [adminData]);

	useEffect(() => {
		if (admin) setMessageData({...messageData, name: admin.chatName});
	}, [admin]);

	useEffect(() => {
		const textareaInputRefCurrent = textareaInputRef.current;
		if (textareaInputRefCurrent && textareaInputRefCurrent.value.length > 0) {
			textareaInputRefCurrent.removeAttribute('style');
			textareaInputRefCurrent.setAttribute(
				'style',
				`height:${textareaInputRefCurrent.scrollHeight}px;`
			);
			return;
		}
		textareaInputRefCurrent && textareaInputRefCurrent.removeAttribute('style');
	}, [messageData?.text]);

	return (
		<div className='chat-adminPanel'>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Row className='g-2 mb-1'>
					<Col md={2}>
						{admin && (
							<div className='d-flex align-items-center'>
								{translations.room.adminNameLabel}
								&nbsp;
								<b>{admin.chatName}</b>
							</div>
						)}
					</Col>
					<Col>
						<div className='d-flex'>
							<Form.Control
								ref={textareaInputRef}
								as='textarea'
								rows={1}
								name='text'
								placeholder={translations.chatAdminForm.message}
								value={messageData?.text}
								onChange={onChangeHandler}
								required
								maxLength={1500}
							/>
							&nbsp;&nbsp;
							{currentEditedMessage === null && (
								<Button
									type='submit'
									variant='link'
									className={classNames('p-0', {
										'text-primary': appTheme === Theme.DARK,
										'text-dark': appTheme === Theme.LIGHT,
									})}>
									<BsFillArrowUpCircleFill size={30} />
								</Button>
							)}
							{currentEditedMessage && (
								<div className='d-flex'>
									<Button
										type='button'
										variant={appTheme === Theme.DARK ? 'outline-secondary' : 'outline-dark'}
										onClick={cancelEditMessage}>
										{translations.btns.cancel}
									</Button>
									<Button type='submit' variant='dark' className='mx-2'>
										{translations.btns.save}
									</Button>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default observer(ChatAdminForm);

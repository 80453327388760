import {action, makeObservable, observable} from 'mobx';

class ChangelogService {
	@observable
	public unviewedChangelogCount = 0;

	@observable
	public lastViewedChangelogId: number | null = null;

	@action
	public setUnviewedChangelogCount = (value: number) => {
		this.unviewedChangelogCount = value;
	};

	@action
	public setLastViewedChangelogId = (value: number) => {
		this.lastViewedChangelogId = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new ChangelogService();

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useRef, useState} from 'react';
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import useRoom from 'hooks/useRoom';
import toastServices from 'store/toastServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {getFileSize} from 'utils/helpers';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';

interface IUploadVideoModal {
	externalRoomId?: string;
}
const UploadVideoModal: FunctionComponent<IUploadVideoModal> = function UploadVideoModal({
	externalRoomId,
}) {
	const translations = useL10n();
	const [visibleLoader, setVisibleLoader] = useState(false);
	const [validated, setValidated] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const {appTheme} = useLocalObservable(() => appService);
	const {uploadVideoModalVisible, toggleUploadVideoModalVisible} = useLocalObservable(
		() => modalServices
	);
	const inputFileRef = useRef<HTMLInputElement>(null);
	const {addToast} = useLocalObservable(() => toastServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {uploadStreamVideo} = useRoom();

	const onModalClose = () => {
		toggleUploadVideoModalVisible(false);
	};

	const upload = async () => {
		if (file && externalRoomId) {
			setVisibleLoader(true);
			const response = await uploadStreamVideo(externalRoomId, file);
			if (response.status === ResponseStatus.SUCCESS) {
				setVisibleLoader(false);
				addToast({
					title: translations.toasts.success,
					text: translations.toasts.success,
					variant: 'success',
				});
				toggleUploadVideoModalVisible(false);
				return;
			}
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else upload();
	};

	const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const value = event.target.files ? event.target.files[0] : null;
			if (value) {
				if (value.size <= 5 * 1024 * 1024 * 1024) {
					setFile(value);
					return;
				}
				showAlert({
					title: translations.imageReqs.videoReqs,
					text: `${translations.imageReqs.imageWeight(getFileSize(5 * 1024 * 1024 * 1024))}`,
					buttons: [
						{
							text: translations.alerts.btns.ok,
							type: AlertBtnType.NORMAL,
							onClick: () => {
								hideAlert();
							},
						},
					],
				});
				if (inputFileRef.current) inputFileRef.current.value = '';
			}
		}
	};

	return (
		<Modal
			show={uploadVideoModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title> {translations.btns.uploadStreamVideo} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{visibleLoader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}

				{!visibleLoader && (
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<Form.Group className='mb-3' controlId='uploadVideoStream'>
							<Form.Control
								type='file'
								ref={inputFileRef}
								required
								onChange={readFile}
								accept='.mp4, .mov, .webm'
							/>
						</Form.Group>
						<p className='text-muted'>{`${translations.imageReqs.imageWeight(
							getFileSize(5 * 1024 * 1024 * 1024)
						)}`}</p>

						<Modal.Footer>
							<Button variant='outline-secondary' onClick={onModalClose}>
								{translations.btns.cancel}
							</Button>
							<Button type='submit' variant='dark' disabled={visibleLoader}>
								{translations.btns.save}
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(UploadVideoModal);

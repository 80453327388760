import pollServices from 'store/pollServices';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import PollOptionItem from './PollOptionItem';
import './pollOptions.scss';

interface IPollOptionsPtops {
	viewType?: string;
}

const PollOptions: FunctionComponent<IPollOptionsPtops> = function PollOptions({viewType}) {
	const {pollPreview} = useLocalObservable(() => pollServices);

	const renderPollOption = useCallback(
		(option: any) => <PollOptionItem key={option.id} option={option} />,
		[]
	);

	return pollPreview?.options ? (
		<div className='poll-options'>{pollPreview?.options.map(renderPollOption)}</div>
	) : (
		<div className='poll-options'> </div>
	);
};

export default observer(PollOptions);

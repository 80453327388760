import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form, Badge} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import useL10n from 'l10n/useL10n';

import welcomeServices from 'store/welcomeServices';
import alertServices from 'store/alertServices';

import TemplateImg from 'assets/images/welcome/template-img.png';

import './welcome-config.scss';
import classNames from 'classnames';
import {AlertBtnType} from 'models/enums/Alert.enum';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';

const WelcomeConfig: FunctionComponent = function WelcomeConfig() {
	const translations = useL10n();
	const history = useHistory();

	const [isEnabled, setIsEnabled] = useState<any>(false);

	const {welcome, welcomeDraft, setWelcomeDraft} = useLocalObservable(() => welcomeServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {settings} = useLocalObservable(() => settingsServices);

	const {patchSettings} = useSettings();

	const configClassNames = classNames('welcome-config', {
		'welcome-config--new': !welcome && !welcomeDraft,
	});

	const headClassNames = classNames('welcome-config__head', {
		'welcome-config__head--dashed': !welcome && !welcomeDraft,
	});

	const checkIfHasDraft = () => {
		const draft = localStorage.getItem('welcomeDraft');
		if (draft) {
			showAlert({
				title: translations.alerts.restore,
				text: '',
				buttons: [
					{
						text: translations.alerts.btns.startOver,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
							history.push('/welcome/create');
							localStorage.removeItem('welcomeDraft');
							setWelcomeDraft(null);
						},
					},
					{
						type: AlertBtnType.SUCCESS,
						text: translations.alerts.btns.restore,
						onClick: () => {
							hideAlert();
							setWelcomeDraft(JSON.parse(draft));
							history.push('/welcome/create');
						},
					},
				],
			});
		} else history.push('/welcome/create');
	};

	const onClickHandler = () => {
		if (!welcome) {
			checkIfHasDraft();
			return;
		}
		history.push('/welcome/create');
	};

	const onChangeEnabledHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsEnabled(checked);
		patchSettings({
			enableWelcome: checked,
		});
	};

	useEffect(() => {
		if (settings) setIsEnabled(settings.enableWelcome);
	}, [settings]);

	useEffect(() => {
		const draft = localStorage.getItem('welcomeDraft');
		if (draft) {
			setWelcomeDraft(JSON.parse(draft));
		}
	}, []);

	return (
		<div className={configClassNames}>
			<div className={headClassNames}>
				<div>
					<div className='d-flex align-items-center'>
						<span className='h5 mb-0'>{translations.welcome.configure.title}</span>
						{welcomeDraft && !welcome && (
							<Badge bg='secondary' pill className='mx-2'>
								{translations.welcome.draft}
							</Badge>
						)}
					</div>

					<p className='text-secondary mb-0'>{translations.welcome.configure.text}</p>
				</div>
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-secondary' : 'outline-light'}
					onClick={onClickHandler}>
					{welcome || welcomeDraft
						? translations.welcome.btns.edit
						: translations.welcome.btns.configure}
				</Button>
			</div>
			{(welcome || welcomeDraft) && (
				<div className='welcome-config__content'>
					<img className='welcome-config__img' src={TemplateImg} alt='' />
					<div>
						<p className='welcome-config__title'>
							<b>{welcome?.title || welcomeDraft?.title}</b>
						</p>
						<p
							className='welcome-config__text text-secondary'
							dangerouslySetInnerHTML={{__html: welcome?.text || welcomeDraft?.text || ''}}
						/>
					</div>
					<div className='welcome-config__toggle'>
						<Form.Check
							name='isEnabled'
							onChange={onChangeEnabledHandler}
							type='switch'
							id='isWelcomeEnabled'
							label={translations.welcome.toggle.on}
							checked={isEnabled}
							disabled={welcomeDraft !== null && !welcome}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default observer(WelcomeConfig);

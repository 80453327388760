import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';

interface IPrivateRoute {
	children: React.ReactNode;
	path: string;
}

const PrivateRoute: React.FC<IPrivateRoute> = function PrivateRoute({children, path}) {
	const {setAccessToken} = useLocalObservable(() => userServices);
	const userToken = localStorage.getItem('accessToken');

	useEffect(() => {
		if (userToken) {
			setAccessToken(userToken);
		}
	}, []);

	return (
		<Route
			exact
			path={path}
			render={({location}) =>
				userToken && userToken !== '' ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: {from: location},
						}}
					/>
				)
			}
		/>
	);
};

export default observer(PrivateRoute);

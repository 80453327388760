import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {BsTrashFill} from 'react-icons/bs';

import {Button, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';

import './autoposting.scss';
import ResizableTable from 'components/resizableTable/resizableTable';
import SelectControl from 'components/select/SelectControl';
import autopostingService from 'store/autopostingService';
import useAutoposting from 'hooks/useAutoposting';
import userServices from 'store/userServices';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import settingsServices from 'store/settingsServices';

interface ITable {
	currentTab?: string;
}

const Table: FunctionComponent<ITable> = function Table({currentTab}) {
	const translations = useL10n();
	const theadRef = useRef<HTMLTableRowElement>(null);

	const {
		columns,
		updateColumnByIndex,
		addColumn,
		data,
		addLangToMessages,
		options,
		checkOptions,
		setColumns,
		clear,
	} = useLocalObservable(() => autopostingService);
	const {isSuperAdmin} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {settings} = useLocalObservable(() => settingsServices);
	const {
		getMessage,
		getMessageDefault,
		addMessage,
		addMessageDefault,
		deleteMessages,
		deleteMessage,
		deleteMessagesLang,
	} = useAutoposting();

	const [maxRowsElem, setMaxRowsElem] = useState<any>();
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const isDefault = currentTab === 'defaultList';

	const addLang = () => {
		const enabledOptions = options.filter(el => !el.isUsed);
		if (enabledOptions.length) {
			addColumn({value: enabledOptions[0].value, label: enabledOptions[0].label});
			addLangToMessages({lang: enabledOptions[0].value, messages: [{isNew: true}]});
		}
	};

	const onBlurChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, lang: string) => {
		const {value} = event.target;

		if (value.trim())
			isDefault ? addMessageDefault({lang, text: value}) : addMessage({lang, text: value});
	};

	const handleChange = (selectedOption: any, index: number) => {
		updateColumnByIndex(index, selectedOption);
		checkOptions();
	};

	const deleteRowMessages = (index: number) => {
		const ids: number[] = [];
		data
			.map(el => el.messages)
			.forEach(msg => {
				const id = msg[index]?.id;
				id && ids.push(id);
			});
		if (ids.length)
			ids.forEach(id => {
				deleteMessage(id, isDefault);
			});
	};

	const showDeleteAlert = () => {
		showAlert({
			title: translations.autoposting.deleteMessagesAlert.title,
			text: translations.autoposting.deleteMessagesAlert.text,
			buttons: [
				{
					text: translations.autoposting.deleteMessagesAlert.btns.delete,
					type: AlertBtnType.DARK,
					onClick: () => {
						hideAlert();
						deleteMessages(isDefault);
						clear();
						setMaxRowsElem([{}]);
					},
				},
				{
					text: translations.autoposting.deleteMessagesAlert.btns.cancel,
					type: appTheme === Theme.LIGHT ? AlertBtnType.OUTLINE : AlertBtnType.OUTLINE_LIGHT,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const getColumns = () => {
		const cols = data.map(el => {
			return {value: el.lang, label: el.lang};
		});
		setColumns(cols);
	};

	const getMaxRows = () => {
		const lengths = data.map(el => el.messages).map(a => a?.length);
		if (typeof lengths[0] !== 'undefined') {
			const elem = data[lengths.indexOf(Math.max(...lengths))].messages.slice();
			const all = data.map(el => el.messages);
			let isLastRow = false;
			all.forEach(el => {
				if (el[el.length - 1].id) isLastRow = true;
			});
			isLastRow && elem.push({});
			setMaxRowsElem(elem.slice());
		}
	};

	const getMessagesWithService = async () => {
		setVisiblePreloader(true);
		currentTab === 'defaultList' ? await getMessageDefault() : await getMessage();
		setVisiblePreloader(false);
	};

	const deleteColumn = async (lang: string) => {
		setVisiblePreloader(true);
		await deleteMessagesLang(lang, isDefault);
		getMessagesWithService();
	};

	const checkDeleteBtn = (rowIndex: number) => {
		const all = data.map(el => el.messages).slice();
		let rowMsgCount = 0;

		all.forEach(el => {
			const arr = el.slice();
			if (arr[rowIndex] && !arr[rowIndex].isNew) {
				rowMsgCount += 1;
			}
		});

		return rowMsgCount > 0;
	};

	const isSelectDisabled = (lang: string) => {
		const currentLang = data.find(el => el.lang === lang);
		if (currentLang) {
			return currentLang.messages?.filter(el => el.id).length > 0;
		}
		return false;
	};

	useEffect(() => {
		if (data.length) {
			getColumns();
			getMaxRows();
			return;
		}
		addLangToMessages({lang: settings.lang || 'en', messages: [{isNew: true}]});
	}, [data]);

	useEffect(() => {
		checkOptions();
	}, [columns]);

	useEffect(() => {
		clear();
		setMaxRowsElem([]);
		getMessagesWithService();
	}, [currentTab]);

	const renderHead = useCallback(
		(item: any, index: number) => {
			return (
				<th
					key={index}
					style={{
						width: `${100 / columns.length}%`,
					}}>
					{options.length > 0 && (
						<>
							<SelectControl
								handleChange={e => handleChange(e, index)}
								options={options.filter(el => !el.isUsed)}
								value={columns[index]}
								theme='light'
								isSearchable
								isClearable={false}
								disabled={isSelectDisabled(columns[index].value)}
							/>
							{isSelectDisabled(columns[index].value) && isSuperAdmin && (
								<Button variant='link' size='sm' onClick={() => deleteColumn(columns[index].value)}>
									<BsTrashFill className='text-danger' />
								</Button>
							)}
						</>
					)}
				</th>
			);
		},
		[columns]
	);

	const renderRow = useCallback(
		(item: any, rowIndex: number) => {
			return (
				<tr key={rowIndex}>
					{data.slice().map((el: any, index: any) => {
						const localDataArray = el.messages.slice();
						return (
							<td key={index}>
								{!localDataArray[rowIndex] || !localDataArray[rowIndex].text ? (
									<textarea
										className='autoposting__input'
										onBlur={(e: any) => onBlurChangeHandler(e, el.lang)}
										disabled={
											(!localDataArray[rowIndex - 1] && !localDataArray[0].isNew) ||
											(localDataArray[0].isNew && rowIndex > 0) ||
											(!isSuperAdmin && isDefault)
										}
									/>
								) : (
									<p className='autoposting__message'>{localDataArray[rowIndex]?.text}</p>
								)}
							</td>
						);
					})}
					<td className='text-center align-middle'>
						{checkDeleteBtn(rowIndex) && ((isSuperAdmin && isDefault) || !isDefault) && (
							<Button variant='danger' size='sm' onClick={() => deleteRowMessages(rowIndex)}>
								<BsTrashFill />
							</Button>
						)}
					</td>
				</tr>
			);
		},
		[data]
	);

	return (
		<section className='autoposting'>
			{options.filter(el => !el.isUsed).length > 0 && ((isSuperAdmin && isDefault) || !isDefault) && (
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					className='mb-3'
					onClick={addLang}>
					{translations.autoposting.addLang}
				</Button>
			)}

			{visiblePreloader ? (
				<div className='preloader'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			) : (
				<ResizableTable
					variant='light'
					resizable={false}
					resizeOptions={{
						minWidth: 120,
						serialize: false,
					}}>
					<thead>
						<tr ref={theadRef}>
							{columns.map(renderHead)}
							{data
								.map(el => el.messages)
								.flat()
								.filter(el => !el.isNew).length > 0 &&
								((isSuperAdmin && isDefault) || !isDefault) && (
									<th className='text-center align-middle'>
										<Button variant='danger' size='sm' onClick={() => showDeleteAlert()}>
											<BsTrashFill />
										</Button>
									</th>
								)}
						</tr>
					</thead>
					{maxRowsElem?.length && (
						<tbody>{maxRowsElem?.map((elem: any, index: number) => renderRow(elem, index))}</tbody>
					)}
				</ResizableTable>
			)}
		</section>
	);
};

export default observer(Table);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Form, Button, Spinner} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import {isJsonString} from 'utils/helpers';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IImport {
	currentTab: string;
}

const Import: FunctionComponent<IImport> = function Import({currentTab}) {
	const translations = useL10n();

	const [settings, setSettings] = useState('');
	const [isChanged, setIsChanged] = useState(false);
	const [jsonError, setJsonError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const {exportJSON, error} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {exportSettings, importSettings} = useSettings();

	const onClickSaveHandler = async () => {
		if (!isJsonString(settings)) {
			setJsonError('Invalid json');
			return;
		}

		setIsLoading(true);
		const parsedSettings = JSON.parse(settings);
		await importSettings(parsedSettings);

		setJsonError('');
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSettings(value);
		setIsChanged(exportJSON !== value);
	};

	const exportSettingsWithServices = async () => {
		setVisiblePreloader(true);
		await exportSettings();
		setVisiblePreloader(false);
	};

	useEffect(() => {
		setSettings(exportJSON);
		setIsChanged(false);
		setIsLoading(false);
	}, [exportJSON, currentTab]);

	useEffect(() => {
		currentTab === 'import' && exportSettingsWithServices();
		return () => {
			setSettings('');
			setIsLoading(false);
		};
	}, [currentTab]);

	return (
		<div className='block'>
			{error && <b className='text-danger'>{error}</b>}
			{jsonError && <b className='text-danger'>{jsonError}</b>}
			{visiblePreloader && (
				<div className='preloader'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			<Form.Control
				as='textarea'
				rows={20}
				size='sm'
				placeholder={translations.settings.import}
				className='h-100 mb-3'
				value={settings}
				onChange={onChangeHandler}
			/>

			<Button
				type='button'
				variant='success'
				disabled={!isChanged || isLoading || !settings}
				onClick={onClickSaveHandler}>
				{translations.btns.import}
			</Button>
		</div>
	);
};

export default observer(Import);

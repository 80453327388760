import {observer} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {Button, Badge} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import useL10n from 'l10n/useL10n';
import {Room} from 'models/rooms';

interface IPollSendingInfo {
	createResponse: any;
}

const PollSendingInfo: FunctionComponent<IPollSendingInfo> = function PollSendingInfo({
	createResponse,
}) {
	const history = useHistory();
	const translations = useL10n();

	const [availableRooms, setAvailableRooms] = useState([]);

	const goToHistory = () => {
		history.push('/polls');
	};

	const getAvailableRooms = () => {
		setAvailableRooms(
			createResponse.roomPolls.map((el: {id: number; room: Room}) => el.room.externalRoomId)
		);
	};

	const renderItem = useCallback(
		(elem: any, index: number, type: string) => {
			return (
				<Badge bg={type} className='m-1' key={index}>
					{elem}
				</Badge>
			);
		},
		[createResponse]
	);

	useEffect(() => {
		getAvailableRooms();
	}, [createResponse]);

	return (
		<div className='mb-5'>
			{availableRooms.length > 0 && (
				<div>
					<p className='h5'>{translations.pollCreating.availableRooms}</p>

					<p>
						{availableRooms.map((elem: any, index: number) => renderItem(elem, index, 'success'))}
					</p>
				</div>
			)}

			<Button variant='dark' onClick={goToHistory}>
				{translations.pollCreating.goback}
			</Button>
		</div>
	);
};

export default observer(PollSendingInfo);

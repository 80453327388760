import useSettings from 'hooks/useSettings';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import settingsServices from 'store/settingsServices';
import useL10n from 'l10n/useL10n';
import {languages} from 'constants/constants';

const Lang: FunctionComponent = function Lang() {
	const translations = useL10n();
	const {settings} = useLocalObservable(() => settingsServices);

	const [lang, setLang] = useState<any>('');
	const [mySettings, setMySettings] = useState<{
		enableMessageTranslation: boolean | undefined;
		availableLangs: string;
	}>({enableMessageTranslation: false, availableLangs: ''});

	const selectLanguages = languages.map(el => {
		return {lang: el, translation: translations.languages[el]};
	});
	const langs = [
		...languages.map((el: string) => {
			return {code: el, name: translations.languages[el]};
		}),
	];

	const {patchSettings} = useSettings();

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setLang(value);
		patchSettings({
			lang: value,
		});
		if (!settings.availableLangs?.includes(value)) {
			let {availableLangs} = settings;
			availableLangs += `,${value}`;
			patchSettings({
				availableLangs,
			});
		}
	};

	const onChangeAvailableLangs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = event.target;
		let allLangs: string[] = [];
		if (checked) {
			if (mySettings.availableLangs) allLangs = [...mySettings.availableLangs.split(','), value];
			else allLangs.push(value);
		} else {
			allLangs = [...mySettings.availableLangs.split(',').filter(el => el !== value)];
		}

		patchSettings({
			availableLangs: allLangs.map((el: string) => el.trim().toLowerCase()).toString(),
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name, value} = event.target;

		setMySettings({
			...mySettings,
			[name]: checked,
		});

		patchSettings({
			[name]: checked,
		});
	};

	useEffect(() => {
		if (settings) {
			setLang(settings.lang);

			setMySettings({
				enableMessageTranslation: settings.enableMessageTranslation,
				availableLangs: settings.availableLangs || '',
			});
		}
	}, [settings]);

	return (
		<div>
			<div className='block mb-4'>
				<p className='h5 mb-2'>{translations.settings.defaultLanguage.title}</p>
				<p className='text-secondary mb-4'>{translations.settings.defaultLanguage.descr}</p>
				<Form.Select onChange={onSelect} value={lang} className='mb-3 w-50'>
					{selectLanguages
						.sort((a, b) => (a.translation > b.translation ? 1 : -1))
						.map((el: {lang: string; translation: string}) => {
							return (
								<option key={el.lang} value={el.lang}>{`${el.lang.toUpperCase()} - ${
									el.translation
								}`}</option>
							);
						})}
				</Form.Select>
			</div>

			<div className='block mb-4'>
				<p className='h5 mb-2'>{translations.settings.availableLangs}</p>
				<p className='text-secondary mb-4'>{translations.settings.availableLangsHint}</p>
				{[
					{code: 'en', name: translations.languages.en},
					...langs.filter(el => el.code !== 'en').sort((a, b) => (a.name > b.name ? 1 : -1)),
				].map((item: any) => {
					return (
						<Form.Check
							key={item.code}
							className='mb-2'
							onChange={onChangeAvailableLangs}
							type='switch'
							name='availableLang'
							id={`availableLang-${item.code}`}
							label={`${item.name} (${item.code})`}
							value={item.code}
							checked={mySettings.availableLangs?.includes(item.code)}
						/>
					);
				})}
			</div>

			<div className='block mb-4'>
				<p className='h5 mb-4'>{translations.settings.enableMessageTranslation.title}</p>
				<div className='mb-2'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableMessageTranslation'
						id='enableMessageTranslationToggle'
						label={translations.settings.enableMessageTranslation.toggle}
						checked={mySettings.enableMessageTranslation}
					/>
				</div>
				<p className='text-secondary'>{translations.settings.enableMessageTranslation.descr}</p>
			</div>
		</div>
	);
};
export default observer(Lang);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Container, Row, Col, Breadcrumb, Button, Spinner, Table, Badge} from 'react-bootstrap';
import {BsTrashFill, BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import Moment from 'react-moment';

import HighlightsService from 'services/api/HighlightsService';

import userServices from 'store/userServices';
import alertServices from 'store/alertServices';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import highlightsServices from 'store/highlightsServices';
import {LIMIT} from 'constants/constants';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import PageTitle from 'components/pageTitle/PageTitle';
import {Highlight} from 'models/highlights';

const Highlights: FunctionComponent = function Highlighs() {
	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {setCurrentHighlight, setCurrentStep} = useLocalObservable(() => highlightsServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [highlights, setHighlights] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [localOffset, setLocalOffset] = useState(0);
	const [allowClick, setAllowClick] = useState(true);

	const history = useHistory();

	const getHighlights = async (offset: number) => {
		setVisiblePreloader(true);
		setAllowClick(false);
		const response = await HighlightsService.getHighlights(accessToken, LIMIT, offset);
		setVisiblePreloader(false);
		setAllowClick(true);
		if (response.status === ResponseStatus.SUCCESS) {
			setHighlights(response.data);
		}
	};

	const createHighlight = () => {
		const draft = localStorage.getItem('draft');
		if (draft) {
			showAlert({
				title: translations.alerts.restore,
				text: translations.alerts.restoreText,
				buttons: [
					{
						text: translations.alerts.btns.startOver,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
							history.push('/highlights/create');
							localStorage.removeItem('draft');
						},
					},
					{
						type: AlertBtnType.SUCCESS,
						text: translations.alerts.btns.restore,
						onClick: () => {
							hideAlert();
							setCurrentHighlight(JSON.parse(draft));
							setCurrentStep(2);
							history.push('/highlights/create');
						},
					},
				],
			});
		} else history.push('/highlights/create');
	};

	const editHighlight = (highlightId: number) => {
		history.push(`/highlights/edit/${highlightId}`);
	};

	const deleteHighlight = async (highlightId: number) => {
		const response = await HighlightsService.deleteHighlight(accessToken, highlightId);
		if (response.status === ResponseStatus.SUCCESS) {
			getHighlights(localOffset);
		}
	};

	const openAlert = (highlight: Highlight) => {
		showAlert({
			title: translations.btns.remove,
			text: `${translations.btns.remove} ${highlight.title || highlight.text}?`,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deleteHighlight(highlight.id);
					},
				},
			],
		});
	};

	const onNextPageBtnClickHandler = () => {
		setLocalOffset(localOffset + LIMIT);
		getHighlights(localOffset + LIMIT);
	};

	const onPrevPageBtnClickHandler = () => {
		if (localOffset - LIMIT >= 0) {
			setLocalOffset(localOffset - LIMIT);
			getHighlights(localOffset - LIMIT);
		}
	};

	useEffect(() => {
		accessToken && getHighlights(localOffset);
	}, [accessToken]);

	const renderHighlight = useCallback(
		(item: Highlight, index: number) => {
			return (
				<tr key={index}>
					<td className='align-middle'>
						<p className='text-truncate'>{item.title}</p>
					</td>
					<td className='text-secondary text-truncate align-middle'>{item.text}</td>
					<td className='text-center text-secondary align-middle'>
						{item.sendTime ? (
							<Moment local format='DD.MM.YY HH:mm'>
								{item.sendTime}
							</Moment>
						) : (
							item.createdAt && (
								<Moment local format='DD.MM.YY HH:mm'>
									{item.createdAt}
								</Moment>
							)
						)}
					</td>
					<td className='text-center align-middle'>
						{item.status === 'SENT' ? (
							<Badge bg='success'>{translations.highlights.sent}</Badge>
						) : (
							<Badge bg='info'>{translations.highlights.planned}</Badge>
						)}
					</td>
					<td className='text-center text-secondary align-middle'>{item.messagesLength}</td>
					<td className='text-center text-secondary align-middle'>{item.viewsLength}</td>
					<td className='text-center align-middle col-1'>
						<Button variant='secondary' size='sm' onClick={() => editHighlight(item.id)}>
							{translations.highlights.edit}
						</Button>
						&nbsp;
						<Button variant='danger' size='sm' onClick={() => openAlert(item)}>
							<BsTrashFill />
						</Button>
					</td>
				</tr>
			);
		},
		[highlights]
	);

	return (
		<Container fluid className='pt-4 highlights'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.highlights.title}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>

			<PageTitle
				title={translations.sidebar.highlights.title}
				text={translations.sidebar.highlights.descr}
			/>

			<div className='d-flex w-100 justify-content-between mb-3'>
				<div>
					<Button variant='dark' onClick={createHighlight}>
						{translations.highlights.create}
					</Button>
				</div>
				<div className='d-flex'>
					<Button
						variant='dark'
						className='d-flex'
						onClick={onPrevPageBtnClickHandler}
						disabled={localOffset === 0 || !allowClick}>
						<BsArrowLeftCircleFill />
					</Button>
					&nbsp;
					<Button
						variant='dark'
						className='d-flex'
						onClick={onNextPageBtnClickHandler}
						disabled={!highlights.length || highlights.length < LIMIT || !allowClick}>
						<BsArrowRightCircleFill />
					</Button>
				</div>
			</div>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{highlights.length > 0 ? (
				<Table
					responsive
					striped={appTheme === Theme.LIGHT}
					bordered
					hover
					variant={appTheme?.toLowerCase()}>
					<thead>
						<tr>
							<th className='align-middle'>
								<b>{translations.highlights.name}</b>
							</th>
							<th className='align-middle'>
								<b>{translations.highlights.text}</b>
							</th>
							<th className='col-2 text-center align-middle'>
								<b>{translations.highlights.sendDate}</b>
							</th>
							<th className='col-2 text-center align-middle'>
								<b>{translations.highlights.status}</b>
							</th>
							<th className='col-1 text-center align-middle'>
								<b>{translations.highlights.rooms}</b>
							</th>
							<th className='col-1 text-center align-middle'>
								<b>{translations.highlights.views}</b>
							</th>
							<th className='col-2 text-center align-middle'>
								<b>{translations.highlights.actions}</b>
							</th>
						</tr>
					</thead>
					<tbody>{highlights.map((elem, index) => renderHighlight(elem, index))}</tbody>
				</Table>
			) : (
				!visiblePreloader && <p>{translations.empty.highlightsEmpty}</p>
			)}
		</Container>
	);
};

export default observer(Highlights);

import {action, makeObservable, observable} from 'mobx';
import {Report} from 'models/reports';

class ReportServices {
	@observable
	public reportsCount = 0;

	@observable
	public reports: Report[] = [];

	@action
	public setReportsCount = (value: number) => {
		this.reportsCount = value;
	};

	@action
	public setReports = (value: Report[]) => {
		this.reports = value;
	};

	@action
	public addReport = (value: Report) => {
		const existSameReport = this.reports.find(
			elem =>
				elem.message.id === value.message.id &&
				elem.reason === value.reason &&
				elem.target.id === value.target.id
		);
		if (existSameReport) {
			if (existSameReport.size) existSameReport.size = Number(existSameReport.size) + 1;
			else existSameReport.size = 2;
			return;
		}
		this.reports = [value, ...this.reports];
	};

	@action
	public removeReport = (reportId: number) => {
		// this.reports = [...this.reports].filter(el => el.id !== reportId);
	};

	@action
	public increaseReportsCount = () => {
		this.reportsCount += 1;
	};

	@action
	public decreaseReportsCount = () => {
		this.reportsCount -= 1;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new ReportServices();

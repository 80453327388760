import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Container, Row, Col, Table, Breadcrumb, Spinner, Badge, Button} from 'react-bootstrap';
import {BsDownload, BsTrashFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

import {useHistory} from 'react-router-dom';
import useSemanticAnalysis from 'hooks/useSemanticAnalysis';
import {PromptStatus} from 'models/enums/PromptStatus.enum';
import Moment from 'react-moment';

const Semantic: FunctionComponent = function Semantic() {
	const translations = useL10n();
	const history = useHistory();

	const {appTheme} = useLocalObservable(() => appService);

	const [data, setData] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const {getPrompts, deletePrompt} = useSemanticAnalysis();

	const create = () => {
		history.push('/semantic/create');
	};

	const getPromptWithService = async () => {
		setVisiblePreloader(true);
		const response = await getPrompts();
		if (response) setData(response.data);
		setVisiblePreloader(false);
	};

	const deleteClickHandler = async (e: any, id: number) => {
		e.stopPropagation();
		await deletePrompt(id);
		await getPromptWithService();
	};

	const onRowClickHandler = (id: number) => {
		history.push(`/semantic/${id}`);
	};

	useEffect(() => {
		getPromptWithService();
	}, []);

	const renderItem = useCallback(
		(item: any, index: number) => {
			return (
				<tr key={index} onClick={() => onRowClickHandler(item.id)}>
					<td className='align-middle p-3'>
						<b>{translations.sidebar.semantic}</b>
					</td>
					<td className='align-middle p-3'>
						<Moment local format='DD.MM.YY'>
							{item.createdAt}
						</Moment>
					</td>
					<td className='align-middle p-3'> {item.openaiModel} </td>
					<td className='align-middle p-3'>
						{item.endProgress !== null ? `${item.endProgress}%` : '—'}
					</td>
					<td className='align-middle p-3'>
						{item.status === PromptStatus.IN_PROGRESS && (
							<Badge bg='secondary' pill>
								{translations.semantic.promptStatus.inProgress}
							</Badge>
						)}
						{item.status === PromptStatus.ERROR && (
							<Badge bg='danger' pill>
								{translations.semantic.promptStatus.error}
							</Badge>
						)}
						{item.status === PromptStatus.FINISHED && (
							<Badge bg='warning' pill>
								{translations.semantic.promptStatus.finished}
							</Badge>
						)}
						{item.status === PromptStatus.UPLOADED && (
							<Badge bg='success' pill>
								{translations.semantic.promptStatus.finished}
							</Badge>
						)}
						{item.status === PromptStatus.DRAFT && (
							<Badge bg='light' pill>
								{translations.semantic.promptStatus.draft}
							</Badge>
						)}
					</td>
					<td className='align-middle p-3'>
						<div className='d-flex justify-content-end'>
							{item.result && (
								<a href={item.result} className='text-info mx-2'>
									<BsDownload className='mx-2' />
									&nbsp;
									{translations.semantic.downloadResults}
								</a>
							)}

							<Button
								variant='link'
								className='text-danger p-1'
								onClick={e => deleteClickHandler(e, item.id)}>
								<BsTrashFill className='h6 mx-2' />
							</Button>
						</div>
					</td>
				</tr>
			);
		},
		[data]
	);

	return (
		<Container fluid className='pt-4'>
			<Row className='align-items-center'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.reporting}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.semantic}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.semantic}</p>
				</Col>
				<Col className='d-flex justify-content-end align-items-unset'>
					<Button
						type='button'
						variant={appTheme === Theme.DARK ? 'light' : 'dark'}
						className='mx-2'
						onClick={create}>
						{translations.semantic.new}
					</Button>
				</Col>
				<div className='block'>
					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{data.length > 0 ? (
						<Table
							responsive
							bordered
							hover
							variant={appTheme?.toLowerCase()}
							className='semantic-table'>
							<thead>
								<tr>
									<th className='align-middle text-muted'>{translations.semantic.analysis}</th>
									<th className='align-middle text-muted'>{translations.semantic.created}</th>
									<th className='align-middle text-muted'>{translations.semantic.model}</th>
									<th className='align-middle text-muted'>{translations.semantic.percent}</th>
									<th className='align-middle text-muted'>{translations.semantic.status}</th>
									<th className='align-middle text-muted'> </th>
								</tr>
							</thead>
							<tbody>{data.map(renderItem)}</tbody>
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.empty}</p>
					)}
				</div>
			</Row>
		</Container>
	);
};

export default observer(Semantic);

import axios from 'axios';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class ReportService {
	static getReports = async (token: UUID, limit: number, offset: number, order: string) => {
		try {
			const apiUrl = `/admin/report/new?limit=${limit}&offset=${offset}&order=${order}`;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getReportsCount = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/report/new/count`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getArchivedReports = async (token: UUID, limit: number, offset: number, order: string) => {
		try {
			const apiUrl = `/admin/report/solved?limit=${limit}&offset=${offset}&order=${order}`;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static solveReport = async (messageId: number, reason: string | null, token: UUID) => {
		try {
			const body = {messageId, reason};
			const {data, status} = await axios.post(`/admin/report/solve`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {filter} from 'models/filter';
import PollService from 'services/api/PollService';
import pollServices from 'store/pollServices';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';

import useL10n from 'l10n/useL10n';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {useHistory} from 'react-router-dom';
import {LIMIT} from 'constants/constants';

export default () => {
	const translations = useL10n();
	const history = useHistory();

	const {accessToken} = useLocalObservable(() => userServices);
	const {
		currentPollId,
		setPolls,
		currentPoll,
		setCurrentPoll,
		setPollWithPics,
		resetCurrentPoll,
		setCurrentPollId,
	} = useLocalObservable(() => pollServices);

	const {addToast} = useLocalObservable(() => toastServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const pollSortBy = localStorage.getItem('pollSortBy');

	const getPolls = async (
		limit?: number,
		offset?: number,
		order?: string,
		searchFilter?: filter
	) => {
		let allFilters: filter | null = null;
		if (searchFilter?.value) {
			allFilters = searchFilter;
		}
		const response = await PollService.getPolls(
			accessToken,
			limit || 10,
			offset || 0,
			order || pollSortBy || 'createdAt_desc',
			allFilters
		);

		if (response.status === ResponseStatus.SUCCESS) {
			setPolls(response.data);
		} else setPolls([]);
	};

	const showErrorAlert = () => {
		showAlert({
			title: translations.toasts.error,
			text: translations.alerts.roomNotFound,
			buttons: [
				{
					text: translations.alerts.btns.ok,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const getPoll = async (pollId: number) => {
		const response = await PollService.getPoll(accessToken, pollId);
		if (response.status === ResponseStatus.SUCCESS) {
			resetCurrentPoll(response.data);
			setCurrentPollId(response.data.id);
			response.data.options.length && setPollWithPics(Boolean(response.data.options[0].pic), false);
		}
	};

	const setPoll = async (pollWithPics: {value: boolean}) => {
		if (currentPoll) {
			const response = await PollService.setPoll(accessToken, currentPoll, pollWithPics);

			if (response.status === ResponseStatus.SUCCESS) {
				addToast({
					title: translations.toasts.success,
					text: translations.toasts.success,
					variant: 'success',
				});
				setPollWithPics(false, false);
				setCurrentPoll(null);
				getPolls();

				localStorage.removeItem('pollDraft');

				return response;
			}

			if (response.status === ResponseStatus.ERROR) {
				if (response.data === 'NO_ROOMS_FOUND') {
					showErrorAlert();
					return response.data;
				}
			}

			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
		return false;
	};

	const patchPoll = async (pollWithPics: {value: boolean}) => {
		if (currentPoll && currentPollId) {
			const response = await PollService.patchPoll(
				accessToken,
				currentPollId,
				currentPoll,
				pollWithPics
			);

			if (response.status === ResponseStatus.SUCCESS) {
				addToast({
					title: translations.toasts.success,
					text: translations.toasts.success,
					variant: 'success',
				});
				setPollWithPics(false, false);
				setCurrentPoll(null);
				getPolls();
				history.push('/polls');
				return response.data;
			}

			if (response.status === ResponseStatus.ERROR) {
				if (response.data === 'ROOM_NOT_FOUND') {
					showErrorAlert();
					return response.data;
				}
			}

			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
		return false;
	};

	const endPoll = async (pollId: number, share?: boolean) => {
		const response = share
			? await PollService.endPoll(pollId, accessToken, share)
			: await PollService.endPoll(pollId, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			getPolls();
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.pollEnded,
				variant: 'success',
			});
		} else {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const checkPoll = async (pollId?: string) => {
		if (currentPoll) {
			const response = pollId
				? await PollService.checkPoll(accessToken, currentPoll, pollId)
				: await PollService.checkPoll(accessToken, currentPoll);
			return response;
		}
		return false;
	};

	const deletePoll = async (pollId: number) => {
		await PollService.deletePoll(pollId, accessToken);
		getPolls();
	};

	return {setPoll, getPolls, endPoll, deletePoll, patchPoll, getPoll, checkPoll};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Form,
	FloatingLabel,
	Tabs,
	Tab,
	Spinner,
	Button,
} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import AntimatService from 'services/api/AntimatService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import settingsServices from 'store/settingsServices';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

const DefaultLists: FunctionComponent = function DefaultLists() {
	const translations = useL10n();
	const [versions, setVersions] = useState([]);
	const [key, setKey] = useState('phrase');
	const [words, setWords] = useState<any>();
	const [editedWords, setEditedWords] = useState<any>();
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {projectLanguage} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);

	const getVersions = async (type: string) => {
		const response = await AntimatService.getVersions(
			type,
			accessToken,
			'default',
			projectLanguage
		);
		setVersions(response.data);
	};

	const getVersionList = async (id: string) => {
		const response = await AntimatService.getVersionList(
			id,
			accessToken,
			'default',
			projectLanguage
		);
		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.words) {
				setWords(response.data.words);
				setEditedWords(response.data.words);
				return;
			}
			return;
		}
		setWords('');
		setEditedWords('');
	};

	const getWordList = async (type: string) => {
		setVisiblePreloader(true);
		const response = await AntimatService.getWordList(
			type,
			accessToken,
			'default',
			projectLanguage
		);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) {
			setWords(response.data);
			setEditedWords(response.data);
		}
	};

	const saveList = async (type: string) => {
		const response = await AntimatService.updateWordList(
			type,
			editedWords,
			accessToken,
			'default',
			projectLanguage
		);
		if (response.status === ResponseStatus.SUCCESS) {
			setWords(response.data.words);
			setEditedWords(response.data.words);
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.wordListUpdated,
				variant: 'success',
			});
		}
	};

	const onVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		getVersionList(value);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setEditedWords(value);
	};

	const tabClickHandler = (k: any) => {
		setKey(k);
		getVersions(k);
		getWordList(k);
		setWords('');
		setEditedWords('');
	};

	const isIncorrectContent = () => {
		return editedWords ? editedWords.match(/(†)\1+/) !== null || editedWords.endsWith('†') : false;
	};

	useEffect(() => {
		if (accessToken && projectLanguage) {
			getVersions(key);
			getWordList(key);
		}
	}, [accessToken, projectLanguage]);

	return (
		<Container fluid className='pt-4 text-lg rooms'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.wordList}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.defaultLists}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<p className='h3 mb-4'>{translations.sidebar.defaultLists}</p>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{!visiblePreloader && (
				<div className='mb-4'>
					<Row className='mb-4'>
						<Col md={4}>
							<FloatingLabel controlId='floatingSelect' label={translations.wordlist.version}>
								<Form.Select onChange={onVersionChange}>
									{versions.map((item: any, index: number) => {
										return (
											<option key={index} value={item.id}>
												{new Date(item.createdAt).toLocaleString()}
											</option>
										);
									})}
								</Form.Select>
							</FloatingLabel>
						</Col>
					</Row>

					<Tabs
						activeKey={key}
						onSelect={(k: any) => tabClickHandler(k)}
						className='nav-tabs'
						variant={appTheme.toLowerCase()}>
						<Tab
							eventKey='phrase'
							title={translations.sidebar.blackPhraseList.title}
							tabClassName='text-secondary'>
							<Form.Control
								as='textarea'
								rows={20}
								size='sm'
								placeholder={translations.sidebar.blackPhraseList.title}
								className='h-100'
								value={editedWords}
								onChange={onChangeHandler}
							/>
						</Tab>
						<Tab
							eventKey='black'
							title={translations.sidebar.blackList.title}
							tabClassName='text-secondary'>
							<Form.Control
								as='textarea'
								rows={20}
								size='sm'
								placeholder={translations.sidebar.blackList.title}
								className='h-100'
								value={editedWords}
								onChange={onChangeHandler}
							/>
						</Tab>
						<Tab
							eventKey='white'
							title={translations.sidebar.whiteList.title}
							tabClassName='text-secondary'>
							<Form.Control
								as='textarea'
								rows={20}
								size='sm'
								placeholder={translations.sidebar.whiteList.title}
								className='h-100'
								value={editedWords}
								onChange={onChangeHandler}
							/>
						</Tab>
						<Tab
							eventKey='site'
							title={translations.sidebar.siteList.title}
							tabClassName='text-secondary'>
							<Form.Control
								as='textarea'
								rows={20}
								size='sm'
								placeholder={translations.sidebar.siteList.title}
								className='h-100'
								value={editedWords}
								onChange={onChangeHandler}
							/>
						</Tab>
					</Tabs>
					<br />

					<Button
						variant='success'
						onClick={() => saveList(key)}
						disabled={words === editedWords || isIncorrectContent()}>
						{translations.btns.save}
					</Button>
				</div>
			)}
		</Container>
	);
};

export default observer(DefaultLists);

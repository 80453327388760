/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {NavLink, useHistory, useParams, Prompt} from 'react-router-dom';
import classNames from 'classnames';

import {Container, Row, Col, Breadcrumb, Button, Spinner} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {Theme} from 'models/enums/Theme.enum';

import userServices from 'store/userServices';
import alertServices from 'store/alertServices';
import appService from 'store/appService';
import toastServices from 'store/toastServices';

import settingsServices from 'store/settingsServices';

import useSettings from 'hooks/useSettings';
import useOffers from 'hooks/useOffers';

import './offer.scss';
import '../highlights/highlight.scss';

import offerService from 'store/offerService';
import OfferTemplate from './OfferTemplate';
import OfferContent from './OfferContent';
import OfferSettings from './OfferSettings';
import OfferInfo from './OfferInfo';
import OfferRecepients from './OfferRecepients';

const OfferCreate: FunctionComponent = function OfferCreate() {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();
	const history = useHistory();

	const {accessToken} = useLocalObservable(() => userServices);

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {language, appTheme} = useLocalObservable(() => appService);
	const {themes, setCurrentThemeId} = useLocalObservable(() => settingsServices);
	const {currentOffer, currentStep, setCurrentStep, clearCurrentOffer} = useLocalObservable(
		() => offerService
	);

	const [createResponse, setCreateResponse] = useState({});
	const [steps, setSteps] = useState<string[]>([]);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [warning, setWarning] = useState(false);
	const [warningLink, setWarningLink] = useState(false);

	const {getThemes, getColors} = useSettings();
	const {createOffer, getOffer, updateOffer} = useOffers();

	const goToHistory = () => {
		history.push('/offers');
	};

	const stepClasses = (step: number) =>
		classNames('offer__steps-item', {
			'offer__steps-item--active': currentStep === step,
		});

	const openConfirmAlert = () => {
		showAlert({
			title: translations.highlights.confirm,
			text: '',
			buttons: [
				{
					text: translations.btns.cancel,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.btns.send,
					type: AlertBtnType.SUCCESS,
					onClick: () => {
						createOffer(setCreateResponse, setConfirmedNavigation);
						hideAlert();
					},
				},
			],
		});
	};
	const nextStep = () => {
		if (!slug) {
			if (currentStep === steps.length) {
				openConfirmAlert();
			} else setCurrentStep(currentStep + 1);
		}
		if (slug) {
			if (currentStep === steps.length) updateOffer();
			else setCurrentStep(currentStep + 1);
		}
	};

	const saveDraft = (nextLocation?: any) => {
		setConfirmedNavigation(true);
		currentOffer.picFile = null;
		currentOffer.pic = '';
		localStorage.setItem('offerDraft', JSON.stringify(currentOffer));
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.draftCreated,
			variant: 'success',
		});
		!nextLocation &&
			setTimeout(() => {
				history.push('/offers');
			}, 100);
	};

	const handleBlockedNavigation = (nextLocation: any) => {
		setLastLocation(nextLocation.pathname);
		if (!confirmedNavigation && nextLocation.pathname !== '/login') {
			showAlert({
				title: translations.alerts.saveChanges,
				text: translations.alerts.unsavedChanges,
				buttons: [
					{
						text: translations.btns.dontSave,
						type: AlertBtnType.DANGER,
						onClick: () => {
							hideAlert();
							localStorage.removeItem('offerDraft');
							setConfirmedNavigation(true);
						},
					},
					{
						text: translations.alerts.btns.saveDraft,
						type: AlertBtnType.SUCCESS,
						onClick: () => {
							saveDraft(nextLocation);
							hideAlert();
							setConfirmedNavigation(true);
						},
					},
				],
			});
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (themes.length) {
			getColors(themes[0].id);
			setCurrentThemeId(themes[0].id);
		}
	}, [themes]);

	useEffect(() => {
		slug && setCurrentStep(2);
		slug && accessToken && getOffer(+slug);
		accessToken && getThemes();
		return () => {
			clearCurrentOffer();
		};
	}, [accessToken]);

	useEffect(() => {
		setSteps([
			translations.offers.template,
			translations.offers.content,
			translations.offers.settings,
			translations.offers.rooms,
		]);
	}, [language]);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation);
		}
	}, [confirmedNavigation, lastLocation]);

	return (
		<Container fluid className='pt-4' id='create-offer'>
			<div className='offer__create'>
				<Row>
					<Col>
						<Breadcrumb>
							<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
							<Breadcrumb.Item active>
								<NavLink to='/offers'>{translations.sidebar.offers.title}</NavLink>
							</Breadcrumb.Item>
							<Breadcrumb.Item active>
								{slug ? translations.offers.edit : translations.offers.create}
							</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row className='mb-5 align-items-start'>
					<Col>
						<p className='h3 mb-0'>
							{slug ? translations.offers.edit : translations.offers.create}
						</p>
					</Col>
					<Col className='d-flex justify-content-end'>
						{slug && (
							<Button variant='dark' onClick={goToHistory}>
								{translations.highlights.history}
							</Button>
						)}
						{!slug && currentStep !== 5 && (
							<Button
								variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
								onClick={() => saveDraft()}>
								{translations.highlights.draft}
							</Button>
						)}
					</Col>
				</Row>

				{visiblePreloader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}

				{!visiblePreloader && (
					<div>
						{currentStep !== 5 && currentStep !== 6 && (
							<ul className='offer__steps'>
								{steps.map((elem, index) => {
									return (
										<li className={stepClasses(index + 1)} key={index}>
											<p className='offer__steps-text'>{elem}</p>
										</li>
									);
								})}
							</ul>
						)}

						{currentStep === 1 && <OfferTemplate />}
						{currentStep === 2 && (
							<OfferContent nextStep={nextStep} warning={warning} warningLink={warningLink} />
						)}
						{currentStep === 3 && <OfferSettings nextStep={nextStep} steps={steps} />}
						{currentStep === 4 && <OfferRecepients nextStep={nextStep} steps={steps} />}

						{currentStep === 5 && <OfferInfo createResponse={createResponse} />}

						{!slug && <Prompt when message={handleBlockedNavigation} />}
					</div>
				)}
			</div>
		</Container>
	);
};

export default observer(OfferCreate);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback} from 'react';

import {Button, Modal} from 'react-bootstrap';

import alertService from 'store/alertServices';

const Alert: FunctionComponent = function Alert() {
	const {isVisible, alert, hideAlert} = useLocalObservable(() => alertService);

	const renderAlertButons = useCallback(
		(item: any, index: any) => (
			<Button key={index} variant={item.type} onClick={item.onClick || hideAlert}>
				{item.text}
			</Button>
		),
		[]
	);

	return (
		<Modal
			show={isVisible}
			onHide={hideAlert}
			animation={false}
			centered
			restoreFocus={false}
			className={alert?.className}
			data-bs-theme='dark'>
			<Modal.Header closeButton>
				<Modal.Title
					className='text-truncate'
					dangerouslySetInnerHTML={{__html: alert?.title || ''}}
				/>
			</Modal.Header>
			<Modal.Body>
				{alert?.description && <b className='d-block mb-3'>{alert?.description}</b>}
				{alert?.text && <p dangerouslySetInnerHTML={{__html: alert.text}} />}
			</Modal.Body>

			<Modal.Footer>{alert?.buttons.map(renderAlertButons)}</Modal.Footer>
		</Modal>
	);
};

export default observer(Alert);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import authService from 'store/authService';
import PasswordControl from 'components/password/PasswordControl';

interface IStepPersonalInfo {
	setCurrentStep?: (step: number) => void;
}

const StepPersonalInfo: FunctionComponent<IStepPersonalInfo> = function StepPersonalInfo(props) {
	const {auth} = useL10n();

	const [validated, setValidated] = useState(false);
	const [isPasswordChecked, setIsPasswordChecked] = useState(false);

	const [isFirstCondition, setIsFirstCondition] = useState(false);
	const [isSecondCondition, setIsSecondCondition] = useState(false);
	const [isThirdCondition, setIsThirdCondition] = useState(false);

	const {user, setUser} = useLocalObservable(() => authService);

	const checkPassword = (value: string) => {
		const has8Symbols = value.length >= 8;
		const hasCapitalLetter = /[A-Z]/.test(value);
		const hasSpecialSymbol = /[!#$%^&*]/.test(value);

		if (has8Symbols) {
			setIsFirstCondition(true);
		} else setIsFirstCondition(false);

		if (hasCapitalLetter) {
			setIsSecondCondition(true);
		} else setIsSecondCondition(false);

		if (hasSpecialSymbol) {
			setIsThirdCondition(true);
		} else setIsThirdCondition(false);

		if (has8Symbols && hasCapitalLetter && hasSpecialSymbol) {
			setIsPasswordChecked(true);
		} else setIsPasswordChecked(false);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;

		value = value.replace(/[А-Яа-яЁё\s]/g, '');

		checkPassword(value);
		setUser({...user, password: value});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		if (props.setCurrentStep) props.setCurrentStep(3);
	};

	const backClickHandler = () => {
		if (props.setCurrentStep) {
			props.setCurrentStep(1);
		}
	};

	useEffect(() => {
		if (user?.password && !isPasswordChecked) checkPassword(user?.password);
	}, [user]);

	return (
		<div className='auth__form-content'>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Form.Group className='mb-4'>
					<Form.Label>{auth.steps.password.email}</Form.Label>
					<Form.Control
						name='email'
						placeholder={auth.steps.password.email}
						value={user?.email}
						disabled
					/>
				</Form.Group>

				<PasswordControl
					label='Пароль'
					onChangeHandler={onChangeHandler}
					value={user?.password || ''}
					name='password'
					maxLength={32}
				/>

				<div className='auth__password-reqs'>
					<p className='h5 mb-3'>{auth.steps.password.reqsTitle}</p>
					<ul className='auth__password-reqs-list'>
						<li className={isFirstCondition ? 'success' : ''}>{auth.steps.password.reqs[0]}</li>
						<li className={isSecondCondition ? 'success' : ''}>{auth.steps.password.reqs[1]}</li>
						<li className={isThirdCondition ? 'success' : ''}>
							{auth.steps.password.reqs[2]} <b>(!#$%^&*)</b>
						</li>
					</ul>
				</div>

				<Button
					type='submit'
					variant='dark'
					size='lg'
					className='w-100 text-center'
					disabled={!isPasswordChecked}>
					{auth.btns.next}
				</Button>
				<div className='auth__form-footer'>
					<button type='button' className='btn-link text-info' onClick={backClickHandler}>
						{auth.btns.back}
					</button>
				</div>
			</Form>
		</div>
	);
};

export default observer(StepPersonalInfo);

import {FunctionComponent} from 'react';
import {useAccordionButton} from 'react-bootstrap';

interface ICustomToggle {
	eventKey: string;
	children: React.ReactNode;
}
const CustomToggle: FunctionComponent<ICustomToggle> = function CustomToggle({children, eventKey}) {
	const decoratedOnClick = useAccordionButton(eventKey, () => {
		return true;
	});

	return (
		<button
			type='button'
			style={{position: 'absolute', right: '1rem', top: '2.7rem'}}
			onClick={decoratedOnClick}>
			{children}
		</button>
	);
};

export default CustomToggle;

import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import ReportService from 'services/api/ReportService';
import reportServices from 'store/reportServices';
import userServices from 'store/userServices';

import {LIMIT} from 'constants/constants';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {reportsCount, setReportsCount, setReports} = useLocalObservable(() => reportServices);

	const getReports = async (limit?: number, offset?: number, order?: string) => {
		if (accessToken) {
			const response = await ReportService.getReports(
				accessToken,
				limit || LIMIT,
				offset || 0,
				order || 'createdAt_desc'
			);
			if (response.status === ResponseStatus.SUCCESS) {
				setReports(response.data.reports);
			}
		}
	};

	const getReportsCount = async () => {
		if (accessToken) {
			const response = await ReportService.getReportsCount(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				if (!reportsCount) setReportsCount(response.data.reportsLength);
			}
		}
	};

	return {
		getReports,
		getReportsCount,
	};
};

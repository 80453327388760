import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Form, InputGroup, Nav} from 'react-bootstrap';

import alertServices from 'store/alertServices';

import useL10n from 'l10n/useL10n';
import {isArray, isString} from 'lodash';
import {useParams} from 'react-router-dom';
import offerService from 'store/offerService';

interface IOfferUsers {
	nextStep: () => void;
}

const OfferUsers: FunctionComponent<IOfferUsers> = function OfferUsers({nextStep}) {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {currentOffer, setCurrentOffer} = useLocalObservable(() => offerService);

	const [externalUserIds, setexternalUserIds] = useState<string[] | File>([]);
	const [validated, setValidated] = useState(false);
	const [isManual, setIsManual] = useState(true);
	const [csvData, setCsvData] = useState<any>([]);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		//  = value.trim().split(',');
		const roomIds = value
			.trim()
			.split(',')
			.map(id => {
				return id.trim();
			});
		if (roomIds.length > 100) {
			showAlert({
				title: translations.highlights.rooms,
				text: translations.highlights.maxRooms,
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
			return;
		}
		setexternalUserIds(roomIds);
		setCurrentOffer({
			...currentOffer,
			externalUserIds: roomIds,
		});
	};

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setCurrentOffer({
					...currentOffer,
					externalUserIds: file,
				});
			}
		}
	};

	const onChangeIManual = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setexternalUserIds([]);
		setCurrentOffer({
			...currentOffer,
			externalUserIds: [],
		});
		if (inputFileRef.current) inputFileRef.current.value = '';
		setIsManual(!isManual);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else nextStep();
	};

	const prepareStringToCsvFormat = () => {
		let data: any;
		if (typeof externalUserIds === 'string') {
			data = `${externalUserIds}`;
			data = data.split(',');
			data = data.map((element: any) => {
				return [element];
			});
		}

		return data;
	};

	const exportToCsv = () => {
		const csvContent = `data:text/csv;charset=utf-8,${csvData
			.map((e: any) => e.join(','))
			.join('\n')}`;

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'Rooms.csv');
		document.body.appendChild(link);

		link.click();
	};

	useEffect(() => {
		if (isArray(currentOffer.externalUserIds) || typeof currentOffer.externalUserIds === 'string') {
			setexternalUserIds(currentOffer.externalUserIds);
			setIsManual(true);
		} else {
			setIsManual(false);
		}
	}, []);

	useEffect(() => {
		slug && typeof externalUserIds === 'string' && setCsvData(prepareStringToCsvFormat);
	}, [externalUserIds]);

	return (
		<section className='offer__rooms'>
			<div className='w-50'>
				<Form noValidate validated={validated} onSubmit={handleSubmit} id='offerUsersForm'>
					<div className='block'>
						{(isArray(externalUserIds) || isString(externalUserIds)) && (
							<Form.Group controlId='OfferUsers' className='mb-5'>
								<p className='h5 mb-3'>{translations.offers.userList}</p>
								<Form.Label className='text-secondary'>
									{translations.offers.userListDescr}
								</Form.Label>

								<InputGroup className='mb-2'>
									<InputGroup.Checkbox
										className='checkbox-black'
										checked={isManual}
										onChange={onChangeIManual}
										disabled={slug !== undefined}
									/>

									<Form.Control
										name='linkText'
										required={isManual}
										disabled={!isManual || slug !== undefined}
										placeholder='User1, User2'
										as='textarea'
										value={externalUserIds}
										onChange={onChangeHandler}
									/>
								</InputGroup>
								{slug && (
									<Nav.Link onClick={exportToCsv}>{translations.highlights.downloadFile}</Nav.Link>
								)}
							</Form.Group>
						)}

						{!slug && (
							<Form.Group>
								<p className='h5 mb-4'>{translations.highlights.downloadRoomList}</p>
								<p className='text-secondary mb-3'>{translations.offers.fileUserDescr}</p>
								<InputGroup>
									<InputGroup.Checkbox
										className='checkbox-black'
										checked={!isManual}
										onChange={onChangeIManual}
									/>

									<Form.Control
										type='file'
										required={!isManual}
										ref={inputFileRef}
										onChange={uploadFile}
										accept='.csv'
										disabled={isManual}
									/>
								</InputGroup>
							</Form.Group>
						)}
					</div>
				</Form>
			</div>
		</section>
	);
};
export default observer(OfferUsers);

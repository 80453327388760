import pollService from 'store/pollServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import './pollQuestion.scss';
import checkRealTextLength from 'utils/checkTextLength';
import {Form, FloatingLabel} from 'react-bootstrap';

const PollQuestion: FunctionComponent = function PollQuestion() {
	const {poll} = useL10n();

	const {currentPoll, updateCurrentPoll, setIsQuestionEmpty} = useLocalObservable(
		() => pollService
	);

	const [inputText, setInputText] = useState('');
	const [textLengthVisible, setTextLengthVisible] = useState(false);

	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const {value} = e.target;
		setInputText(value);
		setIsQuestionEmpty(value.trim().length === 0);
	};

	const onKeyupHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		updateCurrentPoll({text: inputText});
	};

	useEffect(() => {
		setInputText(currentPoll?.text ? currentPoll.text : '');
		setIsQuestionEmpty(!currentPoll?.text);
	}, [currentPoll]);

	return (
		<div className='mb-5'>
			<p>{poll.question.title}</p>

			<div className='poll-question__input-container'>
				<Form.Control
					id='poll-question'
					as='textarea'
					placeholder={poll.question.inputPlaceholder}
					name='question'
					required
					value={inputText}
					onChange={onChangeHandler}
					onKeyUp={onKeyupHandler}
					maxLength={150}
					onBlur={() => setTextLengthVisible(false)}
					onFocus={() => setTextLengthVisible(true)}
				/>

				{textLengthVisible && (
					<span className='poll-question__icounter text-secondary'>
						{150 - checkRealTextLength(inputText)}{' '}
					</span>
				)}
			</div>
		</div>
	);
};

export default observer(PollQuestion);

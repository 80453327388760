import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useRef, useState} from 'react';
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Form,
	InputGroup,
	Button,
	Spinner,
	Badge,
	Tabs,
	Tab,
} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import useBadge from 'hooks/useBadge';
import './badges-config.scss';
import {BsDownload} from 'react-icons/bs';

const TopX: FunctionComponent = function TopX() {
	const translations = useL10n();
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [rankingFile, setRankingFile] = useState<File | null>(null);

	const {appTheme} = useLocalObservable(() => appService);
	const {uploadRanking} = useBadge();

	const inputTopListFileRef = useRef<HTMLInputElement>(null);

	const save = async (file: File) => {
		setVisiblePreloader(true);
		file && (await uploadRanking(file));
		setVisiblePreloader(false);
		if (inputTopListFileRef.current) inputTopListFileRef.current.value = '';
		setRankingFile(null);
	};

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setRankingFile(file);
				save(file);
			}
		}
	};

	return (
		<div className='block'>
			<div className='badges-config__wrapper'>
				<div className='badges-config__head'>
					<div>
						<div className='d-flex align-items-center'>
							<span className='h5'>{translations.uploadBadges.topX.title}</span>
						</div>

						<p className='text-secondary mb-1'>{translations.uploadBadges.topX.description}</p>
						<Badge pill bg='secondary'>
							<small>Top-10</small>
						</Badge>
						<Badge pill bg='secondary' className='mx-2'>
							<small>Top-100</small>
						</Badge>
						<Badge pill bg='secondary'>
							<small>Top-1000</small>
						</Badge>
					</div>
				</div>
				<div className='badges-config__content'>
					<div className='w-100 d-flex justify-content-between'>
						<p
							className='badges-config__text text-secondary'
							dangerouslySetInnerHTML={{__html: translations.uploadBadges.topX.text}}
						/>
						<a href='./images/topX.csv' className='text-info'>
							<BsDownload />
							&nbsp;
							{translations.uploadBadges.downloadExample}
						</a>
					</div>
					<InputGroup className='mb-3'>
						<Form.Control
							type='file'
							ref={inputTopListFileRef}
							onChange={uploadFile}
							accept='.csv'
						/>
					</InputGroup>
					<div className='d-flex'>
						{visiblePreloader && (
							<div className='position-relative d-flex align-items-center'>
								<span>{translations.uploadBadges.loading}</span>
								<Spinner animation='border' variant={appTheme.toLowerCase()} className='m-2' />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(TopX);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useRef, useState} from 'react';

import useL10n from 'l10n/useL10n';
import {Form, InputGroup, Button, Spinner, Badge} from 'react-bootstrap';
import useBadge from 'hooks/useBadge';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import {useHistory} from 'react-router-dom';
import badgeService from 'store/badgeService';
import {Badge as BadgeType} from 'models/badge';
import {textEnding} from 'utils/helpers';

interface ICustomBadges {
	visiblePreloader: boolean;
}

const CustomBadges: FunctionComponent<ICustomBadges> = function CustomBadges({visiblePreloader}) {
	const translations = useL10n();
	const history = useHistory();

	const {appTheme} = useLocalObservable(() => appService);
	const {badges} = useLocalObservable(() => badgeService);
	const {uploadUserLists} = useBadge();

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>, id?: number) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length &&
			id
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				uploadUserLists(id, file);
			}
		}
	};

	const renderBadge = useCallback(
		(elem: BadgeType, index: number) => {
			return (
				<div className='badges-config__wrapper mb-3' key={elem.id}>
					<div className='badges-config__head'>
						<div className='w-75'>
							<p className='h5 mb-2'>{elem.text}</p>
							{elem.tooltip && (
								<div className='d-flex justify-content-between'>
									<p className='badges-config__text text-secondary mb-1'>{elem.tooltip}</p>
								</div>
							)}
							<span
								className='badge rounded-pill'
								style={{backgroundColor: elem.backgroundColor, color: elem.textColor}}>
								{elem.text}
							</span>
						</div>
						<Button
							variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
							onClick={() => history.push(`edit-badge/${elem.id}`)}>
							{translations.btns.edit}
						</Button>
					</div>
					<div className='badges-config__content'>
						<p className='badges-config__text text-secondary'>
							{translations.uploadBadges.uploadText}
						</p>
						<div className='badges-config__file'>
							<InputGroup className='mb-3'>
								<Form.Control
									type='file'
									onChange={(e: any) => uploadFile(e, elem.id)}
									accept='.csv'
								/>
							</InputGroup>

							{elem.userCount !== undefined && (
								<span className='badges-config__users text-muted'>
									{`${elem.userCount} ${textEnding(
										elem.userCount,
										translations.uploadBadges.usersEnding
									)}`}
								</span>
							)}
						</div>
					</div>
				</div>
			);
		},
		[badges]
	);

	return (
		<div className='block'>
			<div className='badges-config__create mb-3'>
				<div className='d-flex align-items-center justify-content-between w-100'>
					<div>
						<span className='h5'>{translations.uploadBadges.customBadges.title}</span>
						<p
							className='text-secondary mb-0'
							dangerouslySetInnerHTML={{__html: translations.uploadBadges.customBadges.description}}
						/>
					</div>
					<Button
						type='button'
						variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
						onClick={() => history.push('/create-badge')}>
						{translations.btns.create}
					</Button>
				</div>
			</div>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{badges.length > 0 && badges.map((elem, index) => renderBadge(elem, index))}
		</div>
	);
};

export default observer(CustomBadges);

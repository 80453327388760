import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback} from 'react';

import {Button, Badge} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import useL10n from 'l10n/useL10n';
import offerService from 'store/offerService';
import {OfferType} from 'models/enums/OfferType.enum';

interface IOfferInfo {
	createResponse: any;
}

const OfferInfo: FunctionComponent<IOfferInfo> = function OfferInfo({createResponse}) {
	const history = useHistory();
	const translations = useL10n();

	const {currentOffer} = useLocalObservable(() => offerService);

	const responseSuccess =
		currentOffer.type === OfferType.ROOM
			? createResponse.availableExternalRoomIds
			: createResponse.availableExternalUserIds;
	const responseError =
		currentOffer.type === OfferType.ROOM
			? createResponse.unavailableExternalRoomIds
			: createResponse.unavailableExternalUserIds;

	const goToHistory = () => {
		history.push('/offers');
	};

	const renderItem = useCallback(
		(elem: any, index: number, type: string) => {
			return (
				<Badge bg={type} className='m-1' key={index}>
					{elem}
				</Badge>
			);
		},
		[createResponse]
	);

	return (
		<section>
			<div className='mb-5'>
				{responseSuccess?.length > 0 && (
					<div>
						<p className='h5'>
							{currentOffer.type === OfferType.ROOM
								? translations.offers.availableRooms
								: translations.offers.availableUsers}
						</p>
						{responseSuccess && (
							<p>
								{responseSuccess.map((elem: any, index: number) =>
									renderItem(elem, index, 'success')
								)}
							</p>
						)}
					</div>
				)}

				{responseError?.length > 0 && (
					<div>
						<p className='h5'>
							{currentOffer.type === OfferType.ROOM
								? translations.offers.unavailableRooms
								: translations.offers.unavailableUsers}
						</p>
						<p>
							{responseError.map((elem: any, index: number) => renderItem(elem, index, 'warning'))}
						</p>
					</div>
				)}

				<Button variant='dark' onClick={goToHistory}>
					{translations.highlights.goToPrevious}
				</Button>
			</div>
		</section>
	);
};

export default observer(OfferInfo);

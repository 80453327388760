import {PollType} from 'models/enums/Poll.enum';
import pollService from 'store/pollServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import {Form, Alert} from 'react-bootstrap';

import './pollSettings.scss';

const PollSettings: FunctionComponent = function PollSettings() {
	const {poll} = useL10n();

	const {currentPoll, updateCurrentPoll, resetIsRightPollCreatedOptions} = useLocalObservable(
		() => pollService
	);

	const setTypeHandler = (value: PollType) => {
		updateCurrentPoll({
			type: value,
			autoSendResults: value === PollType.QUIZ ? false : currentPoll?.autoSendResults,
		});
		resetIsRightPollCreatedOptions();
	};

	const setMultipleHandler = (value: boolean) => {
		updateCurrentPoll({
			isMultiple: value,
		});
		resetIsRightPollCreatedOptions();
	};

	return (
		<div>
			<div className='mb-5'>
				<div className='mb-2'>
					<Form.Group controlId='formQuizMode'>
						<Form.Check
							name='quizMode'
							type='switch'
							id='quizMode'
							label={poll.settings.switches.quizMode}
							checked={currentPoll?.type === PollType.QUIZ}
							onChange={() =>
								setTypeHandler(currentPoll?.type === PollType.QUIZ ? PollType.POLL : PollType.QUIZ)
							}
						/>
					</Form.Group>
				</div>
				<div className='mb-4'>
					<Form.Group controlId='formMultipleChoice'>
						<Form.Check
							name='multipleChoice'
							type='switch'
							id='multipleChoice'
							label={poll.settings.switches.multipleChoice}
							checked={currentPoll?.isMultiple}
							onChange={() => setMultipleHandler(!currentPoll?.isMultiple)}
						/>
					</Form.Group>
				</div>

				<Alert>
					<small>{poll.settings.alert}</small>
				</Alert>
			</div>
		</div>
	);
};

export default observer(PollSettings);

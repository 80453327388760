import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Badge, Image, Alert, Button, Form} from 'react-bootstrap';
import Moment from 'react-moment';
import {NavLink} from 'react-router-dom';
import useL10n from 'l10n/useL10n';
import FlaggedMessageStatus from 'models/enums/FlaggedMessageStatus.enum';
import useFlaggedMessage from 'hooks/useFlaggedMessage';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import appService from 'store/appService';

interface IFlaggedMessageItem {
	item: any;
	getFlaggedMessages: () => void;
}

const FlaggedMessageItem: FunctionComponent<IFlaggedMessageItem> = function FlaggedMessageItem({
	item,
	getFlaggedMessages,
}) {
	const translations = useL10n();

	const {id, message, createdAt, resolvedAt} = item;
	const {user} = message;

	const {resolveFlaggedMessage, updateFlaggedMessage, deleteFlaggedMessage} = useFlaggedMessage();
	const {addToast} = useLocalObservable(() => toastServices);
	const {admins, isAdmin} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [comment, setComment] = useState('');

	const resolveBtnClickHandler = async (status: FlaggedMessageStatus) => {
		await resolveFlaggedMessage({flaggedMessageId: id, status, comment});
		getFlaggedMessages();
	};

	const removeBtnClickHandler = async () => {
		await deleteFlaggedMessage(id);
		getFlaggedMessages();
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setComment(value);
	};

	const onBlurHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
		const {value} = event.target;
		if (value.trim() === '' && !item.comment) return;
		if (value !== item.comment) {
			const response = await updateFlaggedMessage(id, value);
			let responseText = '';
			if (response.status === ResponseStatus.SUCCESS) {
				responseText = translations.toasts.banReasonChanged;
			} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
			addToast({
				title: '',
				text: responseText,
			});
		}
	};

	const getSolver = (solverId: number) => {
		return admins.find(el => el.userId === solverId);
	};

	useEffect(() => {
		item && setComment(item.comment || '');
	}, [item]);

	return (
		<tr>
			<td className='align-middle'>
				{user && (
					<div className='user-info'>
						<div
							className={`user-info__photo user-info__photo--sm background-color-${
								user.color || 0
							}`}>
							<Image src={user.pic} roundedCircle fluid />
						</div>
						<div className='text-truncate'>
							<Badge bg={appTheme.toLowerCase()} pill>
								id{user.id}
							</Badge>
							<div className='user-info__id'>
								<Badge bg={appTheme.toLowerCase()} pill>
									ext:
									{user.externalId}
								</Badge>
							</div>

							<div className='text-truncate'>{user.name}</div>
						</div>
					</div>
				)}
			</td>
			<td className='text-center align-middle text-secondary'>
				{createdAt && (
					<small>
						<Moment format='DD.MM.YY HH:mm'>{createdAt}</Moment>
					</small>
				)}
			</td>
			<td className='align-middle text-center text-secondary'>
				<div className='text-truncate'>
					<NavLink to={`/room/${encodeURIComponent(message.room.externalRoomId)}`}>
						{message.room.externalRoomId}
					</NavLink>
				</div>
			</td>
			<td className='align-middle text-secondary'>
				<Alert key='warning' variant='secondary'>
					<small className='text-break'>{message.text}</small>
				</Alert>
				<div className='text-end'>
					{message.updatedAt ? (
						<small>
							<Moment format='DD.MM.YY HH:mm'>{message.updatedAt}</Moment>
							&nbsp; edited
						</small>
					) : (
						<small>
							<Moment format='DD.MM.YY HH:mm'>{message.createdAt}</Moment>
						</small>
					)}
				</div>
			</td>
			<td className='align-middle text-secondary'>
				{item.status !== FlaggedMessageStatus.REJECTED && (
					<div className='mb-1'>
						<Button
							variant='success'
							size='sm'
							className='d-flex w-100'
							onClick={() => resolveBtnClickHandler(FlaggedMessageStatus.APPROVED)}
							disabled={item.status === FlaggedMessageStatus.APPROVED}>
							{translations.flaggedMessage.considered}
						</Button>
					</div>
				)}

				{item.status !== FlaggedMessageStatus.APPROVED && (
					<div className='mb-1'>
						<Button
							variant='danger'
							size='sm'
							className='w-100'
							onClick={() => resolveBtnClickHandler(FlaggedMessageStatus.REJECTED)}
							disabled={item.status === FlaggedMessageStatus.REJECTED}>
							{item.status === FlaggedMessageStatus.REJECTED
								? translations.flaggedMessage.rejected
								: translations.flaggedMessage.reject}
						</Button>
					</div>
				)}

				<div>
					<Button
						variant='link'
						size='sm'
						className='w-100 text-danger'
						onClick={() => removeBtnClickHandler()}>
						{translations.btns.remove}
					</Button>
				</div>
			</td>
			<td className='text-center align-middle text-secondary'>
				<Form.Group controlId='exampleForm.ControlTextarea1'>
					<Form.Control
						as='textarea'
						rows={5}
						size='sm'
						maxLength={250}
						value={comment}
						onBlur={onBlurHandler}
						onChange={onChangeHandler}
					/>
				</Form.Group>
			</td>
			<td className='text-center align-middle text-secondary'>
				{resolvedAt && (
					<small>
						<Moment format='DD.MM.YY HH:mm'>{resolvedAt}</Moment>
					</small>
				)}
			</td>
			{isAdmin && (
				<td className='text-center align-middle'>
					{item.creatorAdminId && getSolver(item.creatorAdminId) && (
						<div className='text-truncate'>
							{getSolver(item.creatorAdminId) && (
								<Badge bg={appTheme.toLowerCase()} pill>
									id{item.creatorAdminId}
								</Badge>
							)}
							<div className='text-truncate'>
								{getSolver(item.creatorAdminId) && (
									<span>{getSolver(item.creatorAdminId).user.name}</span>
								)}
							</div>
							{getSolver(item.creatorAdminId) && (
								<Badge bg='info' pill>
									{getSolver(item.creatorAdminId).role}
								</Badge>
							)}
						</div>
					)}
				</td>
			)}
		</tr>
	);
};

export default observer(FlaggedMessageItem);

/* eslint-disable no-param-reassign */
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class PartnersCreatorService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		PartnersCreatorService.baseUrl = baseUrl;
	};

	static createProject = async (token: UUID, name: string) => {
		try {
			const {data, status} = await axios.post(
				`${PartnersCreatorService.baseUrl}/project`,
				{name},
				{
					transformRequest: (requestData, headers) => {
						if (headers) delete headers.common;
						requestData = {
							name,
						};
						return JSON.stringify(requestData);
					},
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json; charset=utf-8',
						Accept: 'application/json',
					},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response.data.detail
					? error.response.data.detail.messages[0]
					: error.response.data.message
					? error.response.data.message
					: error.message
			);
		}
	};

	static getSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${PartnersCreatorService.baseUrl}/settings`, {
				transformRequest: (requestData, headers) => {
					if (headers) delete headers.common;
				},
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static saveSettings = async (
		token: UUID,
		body: {dopplerAccessToken: string; gitlabAccessToken: string}
	) => {
		try {
			const {data, status} = await axios.post(`${PartnersCreatorService.baseUrl}/settings`, body, {
				transformRequest: (requestData, headers) => {
					if (headers) delete headers.common;
					requestData = body;
					return JSON.stringify(requestData);
				},
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json; charset=utf-8',
					Accept: 'application/json',
				},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteProject = async (token: UUID, apikey: string) => {
		try {
			const {data, status} = await axios.delete(
				`${PartnersCreatorService.baseUrl}/project/${apikey}`,
				{
					transformRequest: (requestData, headers) => {
						if (headers) delete headers.common;
					},
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

/* eslint-disable max-lines */
import {action, computed, makeObservable, observable} from 'mobx';
import {MessageHiddenBy} from 'models/enums/MessageHiddenBy.enum';
import MessagesStreamType from 'models/enums/MessagesStream.enum';
import {Message, Talker} from 'models/room';
import {User} from 'models/user';

const MAX_MESSAGES_COUNT = 300;

class MessagesService {
	@observable
	public messages: Message[] = [];

	@observable
	public socketMessages: Message[] = [];

	@observable
	public messagesQueue: Message[] = [];

	@observable
	public stream: MessagesStreamType | null = null;

	@observable
	public split: boolean | undefined = undefined;

	@observable
	public throttling = false;

	@observable
	public isThrottling = false;

	@observable
	public throttlingDelay = 200;

	@observable
	public chatScrollPosition = false;

	@observable
	public currentUserId: number | null = null;

	@observable
	public lastViewedMessageId: number | null = null;

	@observable
	public lastViewedMessageProjects: {project: string; id: number}[] | null = null;

	@observable
	public isNextMessages = false;

	@observable
	public ignoreAutoposted = true;

	@observable
	public hideImages = false;

	@observable
	public editedMessageId: number | null = null;

	@observable
	public reactionMessage: Message | null = null;

	@observable
	public currentEditedMessage: Message | null = null;

	@action
	public setIsNextMessages = (value: boolean) => {
		this.isNextMessages = value;
	};

	@action
	public setCurrentUserId = (value: number | null) => {
		this.currentUserId = value;
	};

	@action
	public setLastViewedMessageId = (value: number | null) => {
		this.lastViewedMessageId = value;
	};

	@action
	public getLastViewedMessageId = (project: string) => {
		const item = localStorage.getItem('lastViewedMessageId');
		if (item) {
			const itemParsed = JSON.parse(item);

			let id = null;
			if (itemParsed.find((el: any) => el.project === project)) {
				id = itemParsed.find((el: any) => el.project === project).id;
				id && this.setLastViewedMessageId(id);
			}

			return id;
		}
		return false;
	};

	@action
	public setLastViewedMessageProjects = (value: number | null, project: string) => {
		const item = localStorage.getItem('lastViewedMessageId');
		let itemParsed = item ? JSON.parse(item) : [];

		if (itemParsed.find((el: any) => el.project === project)) {
			itemParsed = itemParsed.map((el: any) => {
				if (el.project === project) return {...el, id: value};
				return el;
			});
		} else
			itemParsed.push({
				project,
				id: value,
			});

		localStorage.setItem('lastViewedMessageId', JSON.stringify(itemParsed));
	};

	@action
	public setStream = (value: MessagesStreamType) => {
		this.stream = value;
	};

	@action
	public setQueue = (value: Message[]) => {
		this.messagesQueue = value;
	};

	@action
	public setSplit = (value: boolean) => {
		this.split = value;
	};

	@action
	public setThrottling = (value: boolean) => {
		this.throttling = value;
	};

	@action
	public setIsThrottling = (value: boolean) => {
		this.isThrottling = value;
	};

	@action
	public setChatScrollPosition = (value: boolean) => {
		this.chatScrollPosition = value;
	};

	@action
	public shiftQueue = () => {
		this.messagesQueue.shift();
	};

	@action
	public setThrottlingDelay = (value: number) => {
		this.throttlingDelay = value;
	};

	@action
	public setCurrentEditedMessage = (value: Message | null) => {
		this.currentEditedMessage = value;
	};

	@action
	public setMessages = (value: Message[]) => {
		if (this.socketMessages.length) {
			let messages = [...value];
			messages = messages.map(message => {
				if (this.socketMessages.find(el => el.id === message.id)) {
					this.socketMessages = this.socketMessages.filter(el => el.id !== message.id);
					return {...message, isUnread: true};
				}

				return message;
			});
			this.messages = [...messages];
			return;
		}
		this.messages = value;
	};

	@action
	public addMessages = (value: Message[]) => {
		this.messages = [...value, ...this.messages];
	};

	@action
	public addMessagesNext = (value: Message[]) => {
		if (this.socketMessages.length) {
			let nextMessages = [...value];
			nextMessages = nextMessages.map(message => {
				if (this.socketMessages.find(el => el.id === message.id)) {
					this.socketMessages = this.socketMessages.filter(el => el.id !== message.id);
					return {...message, isUnread: true};
				}

				return message;
			});
			this.messages = [...this.messages, ...nextMessages];
			return;
		}
		this.messages = [...this.messages, ...value];
	};

	@action
	public addNewMessage = (value: Message | any) => {
		if (this.throttling) {
			this.messagesQueue = [...this.messagesQueue, value];
			return;
		}

		if (this.messages.length >= MAX_MESSAGES_COUNT && !this.chatScrollPosition) {
			this.messages = [...this.messages.slice(-100), value];
			return;
		}

		this.messages = [...this.messages, value];
	};

	@action
	public addSocketMessage = (value: Message | any) => {
		if (this.ignoreAutoposted && value.isAutoposted) return;
		if (this.throttling) {
			this.messagesQueue = [...this.messagesQueue, {...value, isUnread: true}];
			return;
		}

		if (!this.chatScrollPosition && !this.socketMessages.length && !this.isNextMessages) {
			this.addNewMessage(value);
			return;
		}

		this.socketMessages = [...this.socketMessages, value];
	};

	@action
	public setSocketMessages = (value: Message[] | []) => {
		this.socketMessages = value;
	};

	@action
	public sliceMessages = () => {
		if (this.messages.length >= MAX_MESSAGES_COUNT) {
			this.messages = [...this.messages.slice(-100)];
		}
	};

	@action
	public addQueueToMessages = () => {
		if (
			this.messages.length + this.messagesQueue.length >= MAX_MESSAGES_COUNT &&
			!this.chatScrollPosition
		) {
			this.messages = [...this.messages.slice(-100), ...this.messagesQueue];
			this.messagesQueue = [];
			return;
		}
		this.messages = [...this.messages, ...this.messagesQueue];
		this.messagesQueue = [];
	};

	@action
	public addMessage = (value: Message | any) => {
		if (this.chatScrollPosition) {
			this.socketMessages = [...this.socketMessages, value];
			return;
		}

		if (this.messages.length >= MAX_MESSAGES_COUNT && !this.chatScrollPosition) {
			this.messages = [...this.messages.slice(-100), value];
			return;
		}

		this.messages = [...this.messages, value];
	};

	@action
	public deleteMessage = (messageId: number) => {
		this.messages = this.messages.filter(m => m.id !== messageId);
	};

	@action
	public updateMessage = (updatedMessage: Message) => {
		this.messages = this.messages.map(message => {
			if (message.id === updatedMessage.id) {
				return {...message, ...updatedMessage};
			}
			return message;
		});
	};

	@action
	public updateMessageFieldById = (messageId: number, updatedField: any) => {
		this.messages = this.messages.map(message => {
			if (message.id === messageId) {
				return {...message, ...updatedField};
			}
			return message;
		});
	};

	@action
	public updateUserByTalkerId = (updatedUser: User) => {
		this.messages = this.messages.map(message => {
			const {user} = message;
			if (user && user.id === updatedUser.id) {
				return {...message, user: {...user, ...updatedUser}};
			}
			return message;
		});
	};

	@action
	public updateTalkerMessagesByTalkerId = (updatedTalker: Talker) => {
		this.messages = this.messages.map(message => {
			const {talker} = message;
			if (talker && talker.id === updatedTalker.id) {
				return {...message, talker: {...updatedTalker}};
			}
			return message;
		});
	};

	@action
	public updateMessagesByTalkerId = (
		talkerId: number,
		visible: boolean,
		hiddenAt: string | null
	) => {
		this.messages = this.messages.map(message => {
			const {talker} = message;
			if (talker && talker.id === talkerId) {
				return {...message, isVisible: visible, hiddenAt, hiddenBy: MessageHiddenBy.MODER};
			}
			return message;
		});
	};

	@action
	public changeConnectionQualityByUserId = (userId: number, value: string) => {
		this.messages = this.messages.map(message => {
			const {user} = message;
			if (user && user?.id === userId) {
				return {...message, isBadConnection: value === 'bad_connection'};
			}
			return message;
		});
	};

	@action
	public setIgnoreAutoposted = (value: boolean) => {
		this.ignoreAutoposted = value;
	};

	@action
	public setHideImages = (value: boolean) => {
		this.hideImages = value;
	};

	@action
	public setEditedMessageId = (value: number | null) => {
		this.editedMessageId = value;
	};

	@action
	public setReactionMessage = (value: Message | null) => {
		this.reactionMessage = value;
	};

	@computed
	get getMessages() {
		switch (this.stream) {
			case 'ALL':
				return this.messages.slice().sort((a, b) => {
					return a.id - b.id;
				});

			case 'FIRST':
				return this.messages
					.filter(msg => msg.id % 2 !== 0)
					.slice()
					.sort((a, b) => {
						return a.id - b.id;
					});

			case 'SECOND':
				return this.messages
					.filter(msg => msg.id % 2 === 0)
					.slice()
					.sort((a, b) => {
						return a.id - b.id;
					});

			default:
				return this.messages.slice().sort((a, b) => {
					return a.id - b.id;
				});
		}
	}

	constructor() {
		makeObservable(this);
	}
}
export default new MessagesService();

class Log {
	public error(description: string, stacktrace?: unknown): void {
		// eslint-disable-next-line no-console
		console.log('');
		// eslint-disable-next-line no-console
		console.error(description, stacktrace || '');
	}

	public log(description: string, stacktrace?: unknown): void {
		// eslint-disable-next-line no-console
		console.log('');
		// eslint-disable-next-line no-console
		console.log(description, stacktrace || '');
	}

	public debug(description: string, stacktrace?: unknown): void {
		// eslint-disable-next-line no-console
		console.log('');
		// eslint-disable-next-line no-console
		console.log(description, stacktrace || '');
	}
}

export default new Log();

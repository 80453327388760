import {observer, useLocalObservable} from 'mobx-react-lite';
import {AgoraRecord} from 'models/room';
import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Modal, Spinner} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';

import modalServices from 'store/modalServices';
import AgoraService from 'services/api/AgoraService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import RecordStatus from 'models/enums/RecordStatus.enum';

interface IRoomAgoraVoiceModal {
	externalRoomId?: string | null;
}
const RoomAgoraVoiceModal: FunctionComponent<IRoomAgoraVoiceModal> = function RoomAgoraVoiceModal({
	externalRoomId,
}) {
	const {agoraRecordModal, hideAgoraRecordModal} = useLocalObservable(() => modalServices);
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const [recordStatus, setRecordStatus] = useState('');
	const [disabledBtn, setBtnStatus] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const translations = useL10n();

	const getRecordStatus = async () => {
		if (externalRoomId) {
			setVisiblePreloader(true);
			const response = await AgoraService.statusRecord(externalRoomId, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setRecordStatus(response.data.status);
				setVisiblePreloader(false);
			}
		}
	};

	const onClickStartRecordHandler = async () => {
		if (externalRoomId) {
			setBtnStatus(true);
			setVisiblePreloader(true);
			await AgoraService.startRecord(externalRoomId, accessToken).then(() => {
				setBtnStatus(false);
				setVisiblePreloader(false);
				getRecordStatus();
			});
		}
	};

	const stopRecord = async () => {
		if (externalRoomId) {
			setBtnStatus(true);
			setVisiblePreloader(true);
			await AgoraService.stopRecord(externalRoomId, accessToken).then(() => {
				setBtnStatus(false);
				setVisiblePreloader(false);
				getRecordStatus();
			});
		}
	};

	const onClickStopRecordHandler = async () => {
		showAlert({
			title: translations.modals.callRecord.title,
			text: translations.alerts.stopRecord,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.stop,
					type: AlertBtnType.DANGER,
					onClick: () => {
						stopRecord();
						hideAlert();
					},
				},
			],
		});
	};

	useEffect(() => {
		getRecordStatus();
	}, [externalRoomId]);

	return (
		<Modal
			className='modal--dark modal--agora'
			show={agoraRecordModal}
			onHide={hideAgoraRecordModal}
			animation={false}
			centered
			adaptive='true'
			restoreFocus={false}>
			<Modal.Header closeButton className='text-primary'>
				<Modal.Title className='text-primary'>{translations.modals.callRecord.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex align-items-center'>
					{recordStatus === RecordStatus.NOT_RECORDING ? (
						<Button type='button' onClick={onClickStartRecordHandler} disabled={disabledBtn}>
							{translations.modals.callRecord.btns.startRecod}
						</Button>
					) : (
						<Button type='button' onClick={onClickStopRecordHandler} disabled={disabledBtn}>
							{translations.modals.callRecord.btns.stopRecord}
						</Button>
					)}

					{visiblePreloader && (
						<div className='d-flex align-items-center'>
							<Spinner animation='border' variant='primary' className='mb-3' />
							&nbsp; &nbsp;
							<p className='text-primary'>Loading...</p>
						</div>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default observer(RoomAgoraVoiceModal);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import settingsServices from 'store/settingsServices';
import useL10n from 'l10n/useL10n';
import {Button, FloatingLabel, Form, Tab, Tabs} from 'react-bootstrap';
import checkRealTextLength from 'utils/checkTextLength';
import useSettings from 'hooks/useSettings';

import Preview1Img from 'assets/images/roomstates.png';
import Preview2Img from 'assets/images/roomstates2.png';

import './room-states.scss';
import RoomStatus from 'models/enums/RoomStatus.enum';
import appService from 'store/appService';

interface IRoomStates {
	currentTab: string;
}

const RoomStates: FunctionComponent<IRoomStates> = function RoomStates({currentTab}) {
	const [key, setKey] = useState('ru');
	const [availableLangs, setAvailableLangs] = useState<string[]>([]);
	const [validated, setValidated] = useState(false);
	const [validatedEnded, setValidatedEnded] = useState(false);
	const [roomStatusesData, setRoomStatusesData] = useState<any>([]);
	const [roomSoon, setRoomSoon] = useState<any>({
		title: '',
		text: '',
		lang: 'ru',
		status: RoomStatus.SOON,
	});

	const [roomEnded, setRoomEnded] = useState<any>({
		title: '',
		lang: 'ru',
		status: RoomStatus.SOON,
	});

	const translations = useL10n();
	const {getRoomStatus, postRoomStatus, patchRoomStatus, deleteRoomStatus} = useSettings();
	const {projectLanguage, settings} = useLocalObservable(() => settingsServices);
	const {projectId} = useLocalObservable(() => appService);

	const onChangeSoonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setRoomSoon({
			...roomSoon,
			[event.target.name]: value,
		});
	};

	const onChangeEndedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setRoomEnded({
			...roomEnded,
			[event.target.name]: value,
		});
	};

	const getDataWithService = async () => {
		const response = await getRoomStatus();
		setRoomStatusesData(response);
	};

	const resetRoomSoonHandler = async () => {
		await deleteRoomStatus(roomSoon.id);
		getDataWithService();
	};

	const resetRoomEndedHandler = async () => {
		await deleteRoomStatus(roomEnded.id);
		getDataWithService();
	};

	const handleSubmitSoon = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else {
			roomSoon.lang === key && roomSoon.project && roomSoon.project !== 'default'
				? await patchRoomStatus(roomSoon)
				: await postRoomStatus({
						status: RoomStatus.SOON,
						text: roomSoon.text,
						title: roomSoon.title,
						lang: key,
						project: projectId,
				  });
			getDataWithService();
		}
	};

	const handleSubmitEnded = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedEnded(true);
		else {
			roomEnded.lang === key && roomEnded.project && roomSoon.project !== 'default'
				? await patchRoomStatus(roomEnded)
				: await postRoomStatus({
						status: RoomStatus.ENDED,
						text: roomEnded.text,
						title: roomEnded.title,
						lang: key,
						project: projectId,
				  });
			getDataWithService();
		}
	};

	useEffect(() => {
		if (roomStatusesData.length) {
			setRoomSoon(
				roomStatusesData.find(
					(el: any) => el.lang === projectLanguage && el.status === RoomStatus.SOON
				) || {
					title: '',
					text: '',
					lang: projectLanguage,
				}
			);
			setRoomEnded(
				roomStatusesData.find(
					(el: any) => el.lang === projectLanguage && el.status === RoomStatus.ENDED
				) || {
					title: '',
					text: '',
					lang: projectLanguage,
				}
			);
		}
	}, [roomStatusesData]);

	useEffect(() => {
		setValidated(false);
		setValidatedEnded(false);
		if (currentTab === 'roomstates' && roomStatusesData) {
			const valueSoon =
				roomStatusesData.find((el: any) => el.lang === key && el.status === RoomStatus.SOON) ||
				roomStatusesData.find(
					(el: any) => el.lang === projectLanguage && el.status === RoomStatus.SOON
				);

			const valueEnded =
				roomStatusesData.find((el: any) => el.lang === key && el.status === RoomStatus.ENDED) ||
				roomStatusesData.find(
					(el: any) => el.lang === projectLanguage && el.status === RoomStatus.ENDED
				);

			if (valueSoon) {
				setRoomSoon(valueSoon);
			} else {
				setRoomSoon({
					title: '',
					text: '',
					lang: key,
					status: RoomStatus.SOON,
				});
			}

			if (valueEnded) {
				setRoomEnded(valueEnded);
			} else {
				setRoomEnded({
					title: '',
					text: '',
					lang: key,
					status: RoomStatus.ENDED,
				});
			}
		}
	}, [key, roomStatusesData, currentTab]);

	useEffect(() => {
		if (settings) settings.availableLangs && setAvailableLangs(settings.availableLangs?.split(','));
	}, [settings]);

	useEffect(() => {
		if (currentTab === 'roomstates') {
			getDataWithService();
		}
		setKey(projectLanguage);
	}, [currentTab]);

	return (
		<div className='block room-states'>
			<Tabs activeKey={key} onSelect={(k: any) => setKey(k)} id='roomstate-tabs' className='mb-3'>
				{availableLangs.length &&
					availableLangs.map((el: string) => {
						return (
							<Tab eventKey={el} title={el.toUpperCase()} tabClassName='text-secondary' key={el}>
								<div className='room-states__content'>
									<div className='w-50 mr-3 room-states__form'>
										<p className='h5 mb-2'>{translations.settings.roomStates.soon.title}</p>
										<p className='text-secondary mb-5'>
											{translations.settings.roomStates.soon.text}
										</p>
										<Form noValidate validated={validated} onSubmit={handleSubmitSoon}>
											<div className='position-relative'>
												<FloatingLabel
													controlId={`floatingIdTitle${roomSoon.lang}`}
													label={translations.settings.roomStates.soon.windowTitle}
													className='w-100 text-secondary'>
													<Form.Control
														name='title'
														as='textarea'
														maxLength={100}
														placeholder={translations.settings.roomStates.soon.windowTitle}
														className='mb-2'
														required
														value={roomSoon.title}
														onChange={onChangeSoonHandler}
													/>
												</FloatingLabel>
												{roomSoon.title && (
													<span className='settings__incounter'>
														{100 - checkRealTextLength(roomSoon.title)}{' '}
													</span>
												)}
											</div>
											<div className='position-relative'>
												<FloatingLabel
													controlId={`floatingIdText${roomSoon.lang}`}
													label={translations.settings.roomStates.soon.countdownSubtitle}
													className='w-100 text-secondary mb-3'>
													<Form.Control
														name='text'
														as='textarea'
														maxLength={140}
														placeholder={translations.settings.roomStates.soon.countdownSubtitle}
														required
														value={roomSoon.text}
														onChange={onChangeSoonHandler}
													/>
												</FloatingLabel>
												{roomSoon.text && (
													<span className='settings__incounter'>
														{140 - checkRealTextLength(roomSoon.text)}{' '}
													</span>
												)}
											</div>
											<Button type='submit' variant='success'>
												{translations.btns.save}
											</Button>
											&nbsp;
											<Button
												variant='danger'
												disabled={
													!roomSoon.project ||
													roomSoon.project === 'default' ||
													roomSoon.lang !== key
												}
												onClick={resetRoomSoonHandler}>
												{translations.btns.reset}
											</Button>
										</Form>
									</div>
									<div className='room-states__preview room-states__preview--soon'>
										<div className='room-states__preview-wrap'>
											<p className='room-states__preview-title'>{roomSoon.title}</p>
											<p className='room-states__preview-date'>27.10.2024</p>
											<div className='room-states__preview-timer'>
												<p className='room-states__preview-text'>{roomSoon.text}</p>
												<img src={Preview1Img} alt='' />
											</div>
										</div>
									</div>
								</div>
								<div className='room-states__content'>
									<div className='w-50 mr-3 room-states__form'>
										<p className='h5 mb-2'>{translations.settings.roomStates.ended.title}</p>
										<p className='text-secondary mb-5'>
											{translations.settings.roomStates.ended.text}
										</p>
										<Form noValidate validated={validatedEnded} onSubmit={handleSubmitEnded}>
											<div className='position-relative'>
												<FloatingLabel
													controlId={`floatingIdTitle${roomEnded?.lang}`}
													label={translations.settings.roomStates.ended.title}
													className='w-100 text-secondary'>
													<Form.Control
														name='title'
														as='textarea'
														maxLength={60}
														placeholder={translations.settings.roomStates.ended.title}
														className='mb-2'
														required
														value={roomEnded.title}
														onChange={onChangeEndedHandler}
													/>
												</FloatingLabel>
												{roomSoon.title && (
													<span className='settings__incounter'>
														{60 - checkRealTextLength(roomEnded.title)}{' '}
													</span>
												)}
											</div>
											<Button type='submit' variant='success'>
												{translations.btns.save}
											</Button>
											&nbsp;
											<Button
												variant='danger'
												disabled={
													!roomEnded?.project ||
													roomSoon.project === 'default' ||
													roomEnded.lang !== key
												}
												onClick={resetRoomEndedHandler}>
												{translations.btns.reset}
											</Button>
										</Form>
									</div>
									<div className='room-states__preview room-states__preview--ended'>
										<div className='room-states__preview-wrap'>
											<img src={Preview2Img} alt='' />
											<p className='room-states__preview-title'>{roomEnded.title}</p>
										</div>
									</div>
								</div>
							</Tab>
						);
					})}
			</Tabs>
		</div>
	);
};

export default observer(RoomStates);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Button,
	FloatingLabel,
	Form,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import {BsDownload} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import {ReactComponent as IcoHistory} from 'assets/svg/ico-history.svg';
import {ReactComponent as IcoBookmark} from 'assets/svg/ico-bookmark.svg';
import './semantic.scss';
import modalServices from 'store/modalServices';
import PromptHistoryModal from 'components/modals/promptHistory/PromptHistoryModal';
import {NavLink, useHistory, useParams} from 'react-router-dom';
import useSemanticAnalysis from 'hooks/useSemanticAnalysis';
import {PromptStatus} from 'models/enums/PromptStatus.enum';
import Papa from 'papaparse';
import {getTopWords} from 'utils/helpers';
import userServices from 'store/userServices';
import SocketIoPromptService from 'services/SocketIoPromptService';
import promptService from 'store/promptService';

const CreateSemantic: FunctionComponent = function CreateSemantic() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const translations = useL10n();
	const history = useHistory();
	const {appTheme, projectId} = useLocalObservable(() => appService);
	const {togglePromptHistoryModalVisible} = useLocalObservable(() => modalServices);
	const {accessToken} = useLocalObservable(() => userServices);
	const {prompt, setPrompt, updatePrompt, clearPrompt} = useLocalObservable(() => promptService);

	const {
		getPrompt,
		getModels,
		getOpenaiKey,
		addPrompt,
		addPromptHistory,
		endPrompt,
		promptDraft,
		getPromptHistory,
	} = useSemanticAnalysis();

	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [error, setError] = useState('');
	const [topWords, setTopWords] = useState<string[]>([]);
	const [promptError, setPromptError] = useState('');
	const [isSavedInHistory, setIsSavedInHistory] = useState(false);

	const [models, setModels] = useState<any>([]);
	const answers = useRef<
		{
			question: string;
			message: string;
			answer: string;
			progress: number;
		}[]
	>([]);

	const [csvFile, setCsvFile] = useState<any>();
	const csvFileRef = useRef<HTMLInputElement>(null);

	const isFieldDisabled =
		typeof prompt.status !== 'undefined' &&
		prompt.status !== PromptStatus.DRAFT &&
		prompt.status !== PromptStatus.FINISHED &&
		prompt.status !== PromptStatus.UPLOADED;

	const setText = (val: string) => {
		updatePrompt('text', val);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value, name} = event.target;
		setPrompt({...prompt, [name]: name === 'openaiApiKey' ? value.trim() : value});
	};

	const onModelSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setPrompt({...prompt, openaiModel: value});
	};

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setCsvFile(file);
			}
		}
	};

	const getModelsWithService = async (key?: string) => {
		const response = await getModels(key || prompt.openaiApiKey);
		if (response && response.data.length) setModels(response.data);
	};

	const getOpenaiKeyWithService = async () => {
		const response = await getOpenaiKey();
		if (response && response.data.openaiApiKey) {
			setPrompt({...prompt, openaiApiKey: response.data.openaiApiKey});
			getModelsWithService(response.data.openaiApiKey);
		}
	};

	const handleFile = async (file: any) => {
		if (file.type.indexOf('csv') === -1) {
			setError('Wrong file format');
			return;
		}
		Papa.parse(file, {
			skipEmptyLines: true,
			complete: results => {
				if (results.data.length > 10000) {
					setError('The file must contain less then 10000 lines');
					updatePrompt('messages', []);
					return;
				}

				updatePrompt('messages', results.data.flat());

				setTopWords(getTopWords(results.data.flat()));
			},
			error: (err: any) => {
				console.log(err.message);
			},
		});
		setError('');
	};

	const getPromptWithService = async (id: number) => {
		setVisiblePreloader(true);
		const response = await getPrompt(id);
		if (response) {
			setPrompt(response.data);
			if (csvFile) handleFile(csvFile);
		}
		setVisiblePreloader(false);
	};

	const clearAnswers = () => {
		answers.current = [];
	};

	const start = async () => {
		clearAnswers();
		clearPrompt();
		setTopWords([]);
		const response = await addPrompt(prompt);
		if (response) history.push(`/semantic/${response.data.id}`);
	};

	const test = async () => {
		clearAnswers();
		clearPrompt();
		const testedData = {...prompt};
		testedData.messages = prompt.messages?.slice(0, 100);

		const response = await addPrompt(testedData);
		if (response) history.push(`/semantic/${response.data.id}`);
	};

	const endAnalysisClick = async () => {
		if (prompt.id) {
			await endPrompt(prompt.id);
			getPromptWithService(prompt.id);
		}
	};

	const saveDraft = async () => {
		setVisiblePreloader(true);
		const body = {
			openaiApiKey: prompt.openaiApiKey,
			openaiModel: prompt.openaiModel,
			text: prompt.text,
		};
		await promptDraft(body);
		setVisiblePreloader(false);
		history.push('/semantic');
	};

	const saveToHistory = async () => {
		await addPromptHistory(+slug);
		setIsSavedInHistory(true);
	};

	const getHistory = async (id: number) => {
		const response = await getPromptHistory();
		if (response) {
			if (response.data.find((el: any) => el.id === id)) setIsSavedInHistory(true);
		}
	};

	const onPromptAnswerCreatedHandler = (value: {
		question: string;
		message: string;
		answer: string;
		progress: number;
	}) => {
		answers.current.push(value);
		updatePrompt('endProgress', value.progress);
	};

	const onPromptProgressHandler = (value: {progress: number}) => {
		updatePrompt('endProgress', value.progress);
	};

	const onPromptEndHandler = (value: {progress: number}) => {
		updatePrompt('endProgress', value.progress);
		updatePrompt('status', PromptStatus.FINISHED);
	};

	const onPromptErrorHandler = (value: {error: string}) => {
		value.error !== 'Prompter not found' && setPromptError(value.error);
	};

	const onPromptResultHandler = (value: {url: string}) => {
		updatePrompt('result', value.url);
		updatePrompt('status', PromptStatus.UPLOADED);
	};

	const subscribeOnSocketMessages = async () => {
		SocketIoPromptService.emitAdminJoinPrompt(+slug);
		SocketIoPromptService.onPromptAnswerCreated(onPromptAnswerCreatedHandler);
		SocketIoPromptService.onPromptProgress(onPromptProgressHandler);
		SocketIoPromptService.onPromptError(onPromptErrorHandler);
		SocketIoPromptService.onPromptEnd(onPromptEndHandler);
		SocketIoPromptService.onPromptResult(onPromptResultHandler);
	};

	const unSubscribeSocketMessages = () => {
		SocketIoPromptService.offPromptAnswerCreated(onPromptAnswerCreatedHandler);
		SocketIoPromptService.offPromptProgress(onPromptProgressHandler);
		SocketIoPromptService.offPromptError(onPromptErrorHandler);
		SocketIoPromptService.offPromptEnd(onPromptEndHandler);
		SocketIoPromptService.offPromptResult(onPromptResultHandler);
		SocketIoPromptService.soketDisconnect();
	};

	useEffect(() => {
		if (slug && accessToken) {
			getPromptWithService(+slug);
			if (accessToken) {
				SocketIoPromptService.init(
					accessToken,
					projectId,
					subscribeOnSocketMessages,
					unSubscribeSocketMessages
				);
			}
		}
	}, [slug, accessToken]);

	useEffect(() => {
		if (models.length && !slug)
			if (models.includes('gpt-3.5-turbo')) setPrompt({...prompt, openaiModel: 'gpt-3.5-turbo'});
	}, [models]);

	useEffect(() => {
		csvFile && handleFile(csvFile);
	}, [csvFile]);

	useEffect(() => {
		prompt.openaiApiKey && getModelsWithService();
	}, [prompt.openaiApiKey]);

	useEffect(() => {
		if (prompt && prompt.id) {
			getHistory(prompt.id);
		}
	}, [prompt]);

	useEffect(() => {
		!slug && getOpenaiKeyWithService();
	}, []);

	useEffect(() => {
		return () => {
			setIsSavedInHistory(false);
			clearAnswers();
			clearPrompt();
			unSubscribeSocketMessages();
			SocketIoPromptService.soketDisconnect();
		};
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row className='align-items-center'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.reporting}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/semantic'>{translations.sidebar.semantic}</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.semantic.new}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.semantic}</p>
				</Col>
				<Col className='d-flex justify-content-end align-items-unset'>
					{!slug && (
						<Button
							type='button'
							variant={appTheme === Theme.DARK ? 'secondary' : 'dark'}
							className='mx-2'
							disabled={!prompt.openaiApiKey && !prompt.openaiModel && !prompt.text}
							onClick={saveDraft}>
							{translations.btns.saveDraft}
						</Button>
					)}
				</Col>
			</Row>

			<Row className='semantic'>
				<Col md={6}>
					<div className='block mb-4'>
						<p className='h5 mb-4'>{translations.semantic.settings}</p>
						<div className='mb-4'>
							<p className='text-secondary'>API key for OpenAI</p>
							<FloatingLabel
								controlId='apikey'
								label='API key for OpenAI'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									name='openaiApiKey'
									type='text'
									placeholder='API key for OpenAI'
									value={prompt.openaiApiKey}
									onChange={onChangeHandler}
									required
									disabled={isFieldDisabled}
									className='semantic__apikey-input'
								/>
							</FloatingLabel>
						</div>

						<div className='mb-4'>
							<p className='text-secondary'>{translations.semantic.model}</p>
							<FloatingLabel controlId='modelSelect' label={translations.semantic.model}>
								<Form.Select
									onChange={onModelSelect}
									value={prompt.openaiModel}
									disabled={!models.length || isFieldDisabled}>
									{models.map((el: any, index: number) => {
										return (
											<option value={el} key={index}>
												{el}
											</option>
										);
									})}
								</Form.Select>
							</FloatingLabel>
						</div>
					</div>
					<div className='block mb-4'>
						<div className='d-flex justify-content-between align-items-start'>
							<div>
								<p className='h5 mb-2'>{translations.semantic.prompt}</p>
								<p className='text-secondary'>{translations.semantic.promptDescr}</p>
							</div>
							<div className='d-flex align-items-center'>
								{slug && (
									<OverlayTrigger
										placement='top'
										delay={{show: 50, hide: 50}}
										overlay={
											<Tooltip id='button-tooltip-2'>
												<span>{translations.semantic.historySave}</span>
											</Tooltip>
										}>
										<Button
											variant='link'
											className='p-0 mx-2'
											disabled={isSavedInHistory}
											onClick={saveToHistory}>
											<IcoBookmark className='semantic__ico' />
										</Button>
									</OverlayTrigger>
								)}

								<OverlayTrigger
									placement='top'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<span>{translations.semantic.promptHistory}</span>
										</Tooltip>
									}>
									<Button
										variant='link'
										className='p-0'
										onClick={() => togglePromptHistoryModalVisible(true)}>
										<IcoHistory className='semantic__ico' />
									</Button>
								</OverlayTrigger>
							</div>
						</div>
						<Form.Control
							as='textarea'
							rows={25}
							name='text'
							className='semantic__prompt-textarea'
							onChange={onChangeHandler}
							value={prompt.text}
							disabled={isFieldDisabled}
						/>
					</div>
					<div className='block mb-4'>
						<p className='h5 mb-2'>{translations.semantic.data}</p>
						<p className='text-secondary'>{translations.semantic.dataDescr}</p>
						<div className='mb-3'>
							<div className='text-muted mb-1 text-end'>10 000 {translations.semantic.words}</div>
							<Form.Control
								type='file'
								ref={csvFileRef}
								onChange={uploadFile}
								accept='.csv'
								disabled={isFieldDisabled}
							/>
							{error && <p className='text-danger mt-1'>{error}</p>}
						</div>

						<p className='text-secondary mb-2'>{translations.semantic.top100}</p>
						<Form.Control
							as='textarea'
							rows={15}
							name='top'
							disabled
							className='semantic__top100-textarea text-secondary'
							value={topWords}
						/>
					</div>
				</Col>
				<Col md={6}>
					{slug && prompt.status !== PromptStatus.DRAFT && (
						<div className='semantic__result block mb-4 '>
							<p className='text-danger mb-2'>
								<b>{promptError}</b>
							</p>
							<div className='d-flex align-items-center justify-content-between mb-4'>
								<p className='h4'>
									{prompt.endProgress &&
									prompt.endProgress < 100 &&
									prompt.status === PromptStatus.IN_PROGRESS
										? translations.semantic.analysisStarted
										: translations.semantic.analysisFinished}
								</p>
								<span className='h4 text-muted'>{prompt.endProgress}%</span>
							</div>

							<div className='semantic__progress mb-4'>
								<span
									className='semantic__progress-bar'
									style={{
										width: `${prompt.endProgress}%`,
									}}
								/>
							</div>

							{prompt.result && (
								<a href={prompt.result} className='text-info'>
									<BsDownload />
									&nbsp;
									{translations.semantic.downloadResults}
								</a>
							)}

							{answers.current.length > 0 && (
								<Container fluid className='semantic__result-content mt-4'>
									{answers.current.map((el, index: number) => {
										return (
											<Row key={index} className='mb-2 border-bottom'>
												<Col md={8}>
													<p>{el.message}</p>
												</Col>
												<Col md={4}>
													<p className='text-muted'>{el.answer}</p>
												</Col>
											</Row>
										);
									})}
								</Container>
							)}
						</div>
					)}
				</Col>
			</Row>
			<div className='fixed-panel'>
				<div className='d-flex'>
					{prompt.status === PromptStatus.IN_PROGRESS && (
						<Button
							variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
							onClick={endAnalysisClick}>
							{translations.semantic.stop}
						</Button>
					)}
					{(typeof prompt.status === 'undefined' || prompt.status !== PromptStatus.IN_PROGRESS) && (
						<Button
							variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
							onClick={test}
							disabled={
								!prompt.openaiApiKey ||
								!prompt.openaiModel ||
								!prompt.text ||
								!prompt.messages?.length ||
								prompt.text.trim() === ''
							}>
							{translations.semantic.test}
						</Button>
					)}

					{prompt.status !== PromptStatus.IN_PROGRESS && (
						<Button
							variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
							className='mx-2'
							onClick={start}
							disabled={
								!prompt.openaiApiKey ||
								!prompt.openaiModel ||
								!prompt.text ||
								!prompt.messages?.length ||
								prompt.text.trim() === ''
							}>
							{slug && prompt.status !== PromptStatus.DRAFT
								? translations.semantic.startAgain
								: translations.semantic.start}
						</Button>
					)}
				</div>
			</div>
			<PromptHistoryModal setText={setText} />
		</Container>
	);
};

export default observer(CreateSemantic);

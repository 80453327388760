/* eslint-disable max-lines */
import SocketEvents from 'models/enums/SocketEvents.enum';

import socketIOClient, {Socket} from 'socket.io-client';
import {
	GetMessageListener,
	RoomCreatedListener,
	UserUpdatedListener,
	OnMessagesDeletedListener,
	OnMessageDeletedListener,
	OnMessagesHiddenListener,
	OnMessageHiddenListener,
	UserJoinedListener,
	UserLeftListener,
	OnTalkerBanSetListener,
	OnMuteSetListener,
	OnUserBanSetListener,
	OnModerSetListener,
	OnRoleSetListener,
	OnRoomStatusSetListener,
	OnRoomSpeakSetListener,
	OnRoomSlowmodeSetListener,
	OnReportCreatedListener,
	OnReportSolvedListener,
	OnAutoBanCreatedListener,
	OnAutoBanDeletedListener,
	OnBanUpdatedListener,
	onFlaggedMessageCreatedListener,
	onFlaggedMessageResolvedListener,
	onFlaggedMessageDeletedListener,
	onBroadcastListener,
	OnMessageEditedListener,
	MessagePinnedListener,
} from 'models/socketio';

import Log from 'utils/log';
import {EmitMessage} from 'models/room';

type SocketSubscribeEvents = () => void;
type SocketUnSubscribeEvents = () => void;

export default class SocketIoService {
	static socket: Socket | null = null;

	static socketIoServiceBaseUrl = '';

	static setBaseUrl = (url: string) => {
		SocketIoService.socketIoServiceBaseUrl = url;
	};

	static init = async (
		token: Token,
		projectId: string,
		socketSubscribeEvents?: SocketSubscribeEvents,
		socketUnSubscribeEvents?: SocketUnSubscribeEvents
	) => {
		try {
			this.socket = socketIOClient(`${SocketIoService.socketIoServiceBaseUrl}`, {
				auth: {
					access_token: token,
				},
				query: {
					project: projectId,
				},
				transports: ['websocket'],
				upgrade: false,
			});

			this.listenServerEvents(socketSubscribeEvents, socketUnSubscribeEvents);
		} catch (error) {
			Log.error('Init socket.io: ', error);
		}
	};

	static listenServerEvents = (
		socketSubscribeEvents?: SocketSubscribeEvents,
		socketUnSubscribeEvents?: SocketUnSubscribeEvents
	) => {
		this.socket?.on(SocketEvents.Connect, () => {
			if (socketSubscribeEvents) {
				socketSubscribeEvents();
			}
			Log.debug(`socket.io -> socketid: ${this.socket?.id} -> connect - success`);
		});

		this.socket?.on(SocketEvents.Connected, data => {
			Log.debug(`socket.io -> socketid: ${this.socket?.id} -> connected: `, data);
		});

		this.socket?.on(SocketEvents.ConnectError, error => {
			Log.error(`socket.io -> socketid: ${this.socket?.id} -> connected - error: `, error);
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});

		this.socket?.on(SocketEvents.Error, error => {
			Log.error('socket.io -> error', error);
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});

		this.socket?.on(SocketEvents.Disconnect, () => {
			Log.log('socket.io -> disconnect');
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});
	};

	/* -----------------  ON ------------------ */

	static onGetMessage = (listener: GetMessageListener) => {
		this.socket?.on(SocketEvents.Message, listener);
	};

	static onRoomCreated = (listener: RoomCreatedListener) => {
		this.socket?.on(SocketEvents.RoomCreated, listener);
	};

	static onUserUpdated = (listener: UserUpdatedListener) => {
		this.socket?.on(SocketEvents.UserUpdated, listener);
	};

	static onUserJoined = (listener: UserJoinedListener) => {
		this.socket?.on(SocketEvents.UserJoined, listener);
	};

	static onUserLeft = (listener: UserLeftListener) => {
		this.socket?.on(SocketEvents.UserLeft, listener);
	};

	static onMessagedDeleted = (listener: OnMessagesDeletedListener) => {
		this.socket?.on(SocketEvents.MessagesDeleted, listener);
	};

	static onMessageDeleted = (listener: OnMessageDeletedListener) => {
		this.socket?.on(SocketEvents.MessageDeleted, listener);
	};

	static onMessagesHidden = (listener: OnMessagesHiddenListener) => {
		this.socket?.on(SocketEvents.MessagesHidden, listener);
	};

	static onMessageHidden = (listener: OnMessageHiddenListener) => {
		this.socket?.on(SocketEvents.MessageHidden, listener);
	};

	static onMessageEdited = (listener: OnMessageEditedListener) => {
		this.socket?.on(SocketEvents.MessageEdited, listener);
	};

	static onTalkerBanSet = (listener: OnTalkerBanSetListener) => {
		this.socket?.on(SocketEvents.TalkerBanSet, listener);
	};

	static onMuteSet = (listener: OnMuteSetListener) => {
		this.socket?.on(SocketEvents.MuteSet, listener);
	};

	static onUserBanSet = (listener: OnUserBanSetListener) => {
		this.socket?.on(SocketEvents.UserBanSet, listener);
	};

	static onModerSet = (listener: OnModerSetListener) => {
		this.socket?.on(SocketEvents.ModerSet, listener);
	};

	static onRoleSet = (listener: OnRoleSetListener) => {
		this.socket?.on(SocketEvents.RoleSet, listener);
	};

	static onRoomStatusSet = (listener: OnRoomStatusSetListener) => {
		this.socket?.on(SocketEvents.RoomStatusSet, listener);
	};

	static onRoomSpeakSet = (listener: OnRoomSpeakSetListener) => {
		this.socket?.on(SocketEvents.RoomSpeakSet, listener);
	};

	static onRoomSlowmodeSet = (listener: OnRoomSlowmodeSetListener) => {
		this.socket?.on(SocketEvents.RoomSlowmodeSet, listener);
	};

	static onReportCreated = (listener: OnReportCreatedListener) => {
		this.socket?.on(SocketEvents.ReportCreated, listener);
	};

	static onReportSolved = (listener: OnReportSolvedListener) => {
		this.socket?.on(SocketEvents.ReportSolved, listener);
	};

	static onAutoBanCreated = (listener: OnAutoBanCreatedListener) => {
		this.socket?.on(SocketEvents.AutoBanCreated, listener);
	};

	static onAutoBanDeleted = (listener: OnAutoBanDeletedListener) => {
		this.socket?.on(SocketEvents.AutoBanDeleted, listener);
	};

	static onBanUpdated = (listener: OnBanUpdatedListener) => {
		this.socket?.on(SocketEvents.BanUpdated, listener);
	};

	static onFlaggedMessageCreated = (listener: onFlaggedMessageCreatedListener) => {
		this.socket?.on(SocketEvents.FlaggedMessageCreated, listener);
	};

	static onFlaggedMessageResolved = (listener: onFlaggedMessageResolvedListener) => {
		this.socket?.on(SocketEvents.FlaggedMessageResolved, listener);
	};

	static onFlaggedMessageDeleted = (listener: onFlaggedMessageDeletedListener) => {
		this.socket?.on(SocketEvents.FlaggedMessageDeleted, listener);
	};

	static onMessagePinned = (listener: MessagePinnedListener) => {
		this.socket?.on(SocketEvents.MessagePinned, listener);
	};

	static onMessageUnpinned = (listener: MessagePinnedListener) => {
		this.socket?.on(SocketEvents.MessageUnpinned, listener);
	};

	static onBroadcast = (listener: onBroadcastListener) => {
		this.socket?.on(SocketEvents.Broadcast, listener);
	};

	/* -----------------  END - ON ------------------ */

	/* -----------------  OFF ------------------ */

	static offGetMessage = (listener: GetMessageListener) => {
		this.socket?.off(SocketEvents.Message, listener);
	};

	static offRoomCreated = (listener: RoomCreatedListener) => {
		this.socket?.off(SocketEvents.RoomCreated, listener);
	};

	static offUserUpdated = (listener: UserUpdatedListener) => {
		this.socket?.off(SocketEvents.UserUpdated, listener);
	};

	static offUserJoined = (listener: UserJoinedListener) => {
		this.socket?.off(SocketEvents.UserJoined, listener);
	};

	static offUserLeft = (listener: UserLeftListener) => {
		this.socket?.off(SocketEvents.UserLeft, listener);
	};

	static offMessagedDeleted = (listener: OnMessagesDeletedListener) => {
		this.socket?.off(SocketEvents.MessagesDeleted, listener);
	};

	static offMessageDeleted = (listener: OnMessageDeletedListener) => {
		this.socket?.off(SocketEvents.MessageDeleted, listener);
	};

	static offMessagesHidden = (listener: OnMessagesHiddenListener) => {
		this.socket?.off(SocketEvents.MessagesHidden, listener);
	};

	static offMessageHidden = (listener: OnMessageHiddenListener) => {
		this.socket?.off(SocketEvents.MessageHidden, listener);
	};

	static offMessageEdited = (listener: OnMessageEditedListener) => {
		this.socket?.off(SocketEvents.MessageEdited, listener);
	};

	static offTalkerBanSet = (listener: OnTalkerBanSetListener) => {
		this.socket?.off(SocketEvents.TalkerBanSet, listener);
	};

	static offMuteSet = (listener: OnMuteSetListener) => {
		this.socket?.off(SocketEvents.MuteSet, listener);
	};

	static offUserBanSet = (listener: OnUserBanSetListener) => {
		this.socket?.off(SocketEvents.UserBanSet, listener);
	};

	static offModerSet = (listener: OnModerSetListener) => {
		this.socket?.off(SocketEvents.ModerSet, listener);
	};

	static offRoleSet = (listener: OnRoleSetListener) => {
		this.socket?.off(SocketEvents.RoleSet, listener);
	};

	static offRoomStatusSet = (listener: OnRoomStatusSetListener) => {
		this.socket?.off(SocketEvents.RoomStatusSet, listener);
	};

	static offRoomSpeakSet = (listener: OnRoomSpeakSetListener) => {
		this.socket?.off(SocketEvents.RoomSpeakSet, listener);
	};

	static offRoomSlowmodeSet = (listener: OnRoomSlowmodeSetListener) => {
		this.socket?.off(SocketEvents.RoomSlowmodeSet, listener);
	};

	static offReportCreated = (listener: OnReportCreatedListener) => {
		this.socket?.off(SocketEvents.ReportCreated, listener);
	};

	static offReportSolved = (listener: OnReportSolvedListener) => {
		this.socket?.off(SocketEvents.ReportSolved, listener);
	};

	static offAutoBanCreated = (listener: OnAutoBanCreatedListener) => {
		this.socket?.off(SocketEvents.AutoBanCreated, listener);
	};

	static offAutoBanDeleted = (listener: OnAutoBanDeletedListener) => {
		this.socket?.off(SocketEvents.AutoBanDeleted, listener);
	};

	static offBanUpdated = (listener: OnBanUpdatedListener) => {
		this.socket?.off(SocketEvents.BanUpdated, listener);
	};

	static offFlaggedMessageCreated = (listener: onFlaggedMessageCreatedListener) => {
		this.socket?.off(SocketEvents.FlaggedMessageCreated, listener);
	};

	static offFlaggedMessageResolved = (listener: onFlaggedMessageResolvedListener) => {
		this.socket?.off(SocketEvents.FlaggedMessageResolved, listener);
	};

	static offFlaggedMessageDeleted = (listener: onFlaggedMessageDeletedListener) => {
		this.socket?.off(SocketEvents.FlaggedMessageDeleted, listener);
	};

	static offMessagePinned = (listener: MessagePinnedListener) => {
		this.socket?.off(SocketEvents.MessagePinned, listener);
	};

	static offMessageUnpinned = (listener: MessagePinnedListener) => {
		this.socket?.off(SocketEvents.MessageUnpinned, listener);
	};

	static offBroadcast = (listener: onBroadcastListener) => {
		this.socket?.off(SocketEvents.Broadcast, listener);
	};

	/* -----------------  END - OFF ------------------ */

	static emitAdminJoin = (externalRoomId: string) => {
		this.socket?.emit(SocketEvents.AdminJoin, {
			externalRoomId,
		});
	};

	static emitAdminLeave = (externalRoomId: string) => {
		this.socket?.emit(SocketEvents.AdminLeave, {
			externalRoomId,
		});
	};

	static emitAdminMessage = (data: EmitMessage) => {
		const {name, text, externalRoomId} = data;
		this.socket?.emit(SocketEvents.AdminMessage, {
			name,
			externalRoomId,
			text,
		});
	};

	static soketDisconnect = () => {
		if (this.socket !== null) {
			this.socket?.disconnect();
			this.socket = null;
		}
	};
}

import {action, makeObservable, observable} from 'mobx';
import translations from 'l10n/translations';
import {Offer} from 'models/offer';
import {ShowFrequency} from 'models/enums/ShowEntry.enum';
import {OfferType} from 'models/enums/OfferType.enum';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

class OfferService {
	@observable
	public currentStep = 1;

	@observable
	public currentOffer: Offer = {
		id: 0,
		title: '',
		text: '',
		link: '',
		linkText: translations.highlights.learnMore,
		linkTextLength: 18,
		pic: '',
		picFile: null,
		template: null,
		templateData: null,
		externalRoomIds: [],
		externalUserIds: [],
		showMultipleTimes: false,
		showDelayMS: 0,
		showFrerquency: ShowFrequency.DAILY,
		type: OfferType.GLOBAL,
		openType: LinkOpenType.NEW_WINDOW,
	};

	@action
	public setCurrentStep = (value: number) => {
		this.currentStep = value;
	};

	@action
	public setCurrentOffer = (value: Offer) => {
		this.currentOffer = value;
	};

	@action
	public clearCurrentOffer = () => {
		this.currentOffer = {
			id: 0,
			title: '',
			text: '',
			link: '',
			linkText: translations.highlights.learnMore,
			linkTextLength: 18,
			pic: '',
			picFile: null,
			template: null,
			externalRoomIds: [],
			externalUserIds: [],
			showMultipleTimes: false,
			showDelayMS: 0,
			showFrerquency: ShowFrequency.DAILY,
			type: OfferType.GLOBAL,
		};
		this.currentStep = 1;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new OfferService();

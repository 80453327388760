/* eslint-disable jsx-a11y/media-has-caption */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';

import {Modal} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';

import {BsDownload} from 'react-icons/bs';

interface IRoomRecordModal {
	room: any;
}

const RoomRecordModal: FunctionComponent<IRoomRecordModal> = function RoomRecordModal({room}) {
	const translations = useL10n();

	const {roomRecordModalVisible, toggleRoomRecordModalVisible} = useLocalObservable(
		() => modalServices
	);

	const onModalClose = () => {
		toggleRoomRecordModalVisible(false);
	};

	return (
		<Modal
			className='modal--dark'
			show={roomRecordModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton className='text-primary'>
				<Modal.Title className='text-primary'>
					{translations.room.listenStreamRecording}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{(room?.record || room?.audioRecord || room?.videoRecord) && (
					<div>
						{!room?.isRecordVideo && (
							<audio controls className='w-100'>
								<source src={room.record || room.audioRecord} type='audio/mpeg' />
							</audio>
						)}
						{room?.isRecordVideo && (
							<video controls className='w-100'>
								<source src={`${room.videoRecord}#t=0.1`} />
							</video>
						)}
						<p>
							<a
								href={
									room?.isRecordVideo
										? room.record || room.videoRecord
										: room.record || room.audioRecord
								}
								className='text-primary mx-2'>
								<BsDownload />
								&nbsp;
								{translations.btns.save}
							</a>
						</p>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(RoomRecordModal);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import {getFileSize} from 'utils/helpers';

import {NamedPicType} from 'models/enums/NamedPicType.enum';

import settingsServices from 'store/settingsServices';

import NamedPic from 'pages/main/components/settings/appearence/NamedPic';
import UploadImage from 'components/uploadImage/uploadImage';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IAvatars {
	currentTab: string;
}

const Avatars: FunctionComponent<IAvatars> = function Avatars({currentTab}) {
	const translations = useL10n();
	const {getAvatars, addNamedPic} = useSettings();
	const {theme, avatars, setAvatars} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const addPic = async (file: File) => {
		addNamedPic(NamedPicType.AVATAR, file);
	};

	const setPic = (file: File | null) => {
		file && addPic(file);
	};

	const getAvatarsWithServices = async () => {
		setVisiblePreloader(true);
		await getAvatars();
		setVisiblePreloader(false);
	};

	useEffect(() => {
		currentTab === NamedPicType.AVATAR && getAvatarsWithServices();
		return () => {
			setAvatars([]);
		};
	}, [theme, currentTab]);

	return (
		<div className='block mb-5'>
			<p className='h5 mb-5'>{translations.settings.avatars}</p>

			<div className='mb-3 col-6'>
				<p>
					<small>{translations.imageReqs.imageWeight(getFileSize(204800))}</small>
					<small>{translations.imageReqs.imageMinSize([132, 132])}</small>
				</p>
				<p>
					<small>{translations.imageReqs.formats('.png, .svg')}</small>
				</p>

				<UploadImage
					id='uploadAvatars'
					setPic={setPic}
					objectMinSize={{width: 132, height: 132}}
					size={204800}
					acceptedFileTypes='.png, .svg'
					isSquare
					clearAfterLoad
				/>
			</div>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{avatars.length > 0 ? (
				<div className='settings__namedPics'>
					{avatars.map((elem, index) => {
						return (
							<NamedPic
								index={index}
								key={elem.id}
								elem={elem.pic}
								name={elem.name}
								payload='pic'
								id={elem.id}
								type={NamedPicType.AVATAR}
							/>
						);
					})}
				</div>
			) : (
				!visiblePreloader && <p>{translations.empty.avatarsEmpty}</p>
			)}
		</div>
	);
};

export default observer(Avatars);

/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Form, InputGroup, Button} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import {useParams} from 'react-router-dom';

import useL10n from 'l10n/useL10n';

import highlightsServices from 'store/highlightsServices';
import appService from 'store/appService';

import checkRealTextLength from 'utils/checkTextLength';
import {getFileSize, getContrastColor} from 'utils/helpers';

import useHighlights from 'hooks/useHighlights';

import {HighlightGradient, PICKER} from 'models/enums/Highlights.enum';

import UploadImage from 'components/uploadImage/uploadImage';
import Template from 'components/highlights/Template';
import CustomSettings from 'components/highlights/templateDataSettings';
import settingsServices from 'store/settingsServices';
import {Theme} from 'models/enums/Theme.enum';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';
import {Highlight} from 'models/highlights';

interface IHighlightSettings {
	nextStep: () => void;
}

const HighlightSettings: FunctionComponent<IHighlightSettings> = function HighlightSettings({
	nextStep,
}) {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();

	const {currentHighlight, currentStep, setCurrentHighlight, setCurrentStep, isLoaded} =
		useLocalObservable(() => highlightsServices);
	const {language, appTheme} = useLocalObservable(() => appService);
	const {brandingColors, customHighlightColors} = useLocalObservable(() => settingsServices);

	const [validated, setValidated] = useState(false);
	const [ad, setAd] = useState<Highlight>({
		id: 0,
		title: '',
		titleLength: 0,
		text: '',
		textLength: 0,
		link: '',
		linkTextLength: 18,
		linkText: translations.highlights.learnMore,
		pic: '',
		picFile: null,
		template: null,
		openType: LinkOpenType.NEW_WINDOW,
	});
	const [isLink, setIsLink] = useState(true);
	const [color, setColor] = useState('');
	const [picker, setPicker] = useState('');
	const [gradient, setGradient] = useState(false);

	const {updateHighlight} = useHighlights();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setAd({
			...ad,
			[event.target.name]: value.trim().length ? value : value.trim(),
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});

		setCurrentHighlight({
			...currentHighlight,
			[event.target.name]: value.trim().length ? value : value.trim(),
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});
	};

	const onChangeOpenTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setAd({
			...ad,
			openType: value as LinkOpenType,
		});

		setCurrentHighlight({
			...currentHighlight,
			openType: value as LinkOpenType,
		});
	};

	const onChangeIsLink = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsLink(checked);

		setAd({
			...ad,
			linkText: checked ? translations.highlights.learnMore : '',
			link: checked ? ad.link : '',
			linkTextLength: checkRealTextLength(checked ? translations.highlights.learnMore : ''),
		});

		setCurrentHighlight({
			...currentHighlight,
			linkText: checked ? translations.highlights.learnMore : '',
			link: checked ? currentHighlight.link : '',
			linkTextLength: checkRealTextLength(checked ? translations.highlights.learnMore : ''),
		});
	};

	const setPic = (file: File | null) => {
		setAd({
			...ad,
			pic: file ? URL.createObjectURL(file) : '',
		});
		setCurrentHighlight({
			...currentHighlight,
			pic: file ? URL.createObjectURL(file) : '',
			picFile: file,
		});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		const btnName = e.nativeEvent.submitter.getAttribute('name');
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else btnName === 'save-highlight' ? updateHighlight() : nextStep();
	};

	const isCustomTemplate =
		currentHighlight.template !== undefined &&
		currentHighlight.template !== null &&
		(currentHighlight.template < 3 || currentHighlight.template === 11);

	const isImageTemplate = currentHighlight.template === 11;

	const setData = () => {
		let highlight = {...currentHighlight};
		if (slug) {
			setIsLink(currentHighlight.link !== '');
			if (highlight.template !== 10) delete highlight.templateData;
		} else setIsLink(currentHighlight.linkText !== '');

		if (currentHighlight.link === '' && currentHighlight.linkText !== '')
			highlight.linkText = translations.highlights.learnMore;
		if (currentHighlight.title === null) highlight.title = '';
		if (currentHighlight.text === null) highlight.text = '';
		if (!isCustomTemplate && currentHighlight.pic) {
			highlight.pic = '';
			highlight.picFile = null;
		}

		if (highlight.template === 10 && !highlight.templateData) {
			highlight = {
				...highlight,
				templateData: {
					alignment: 'left',
					bgColor: customHighlightColors?.bg || brandingColors.bg,
					btnColor: customHighlightColors?.btn || brandingColors.btn,
					btnTextColor: getContrastColor(customHighlightColors?.btn || brandingColors.btn),
					textColor: getContrastColor(
						customHighlightColors?.gradient?.from || customHighlightColors?.bg || brandingColors.bg
					),
					gradient: customHighlightColors?.gradient?.from !== undefined,
					gradientFrom: customHighlightColors?.gradient?.from,
					gradientTo: customHighlightColors?.gradient?.to,
					gradientDirection: HighlightGradient.TOP,
				},
			};
		}

		if (highlight.template === 11) {
			highlight.text = '';
			highlight.title = '';
			highlight.linkText = '';
			if (highlight.link) setIsLink(true);
		}

		setGradient(highlight.templateData?.gradient || false);
		setCurrentHighlight(highlight);
		setAd(highlight);
	};

	useEffect(() => {
		if (slug) {
			if (isLoaded) setData();
		} else setData();
	}, [language, isLoaded]);

	useEffect(() => {
		if (color) {
			if (picker === PICKER.BG || picker === PICKER.GRADIENT_FROM)
				setCurrentHighlight({
					...currentHighlight,
					templateData: {
						...currentHighlight.templateData,
						textColor: getContrastColor(color),
					},
				});

			if (picker === PICKER.BTN)
				setCurrentHighlight({
					...currentHighlight,
					templateData: {
						...currentHighlight.templateData,
						btnTextColor: getContrastColor(color),
					},
				});
		}
	}, [color]);

	useEffect(() => {
		document.getElementById('create-highlight')?.scrollIntoView({behavior: 'smooth'});
	}, []);

	return (
		<Container fluid className='highlights__settings'>
			<div className='d-flex'>
				<div className='w-50 mb-5'>
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<div className='block'>
							<p className='h5 mb-4'>{translations.highlights.settings}</p>
							{isCustomTemplate && (
								<div className='mb-3'>
									<Form.Label>{translations.highlights.image}</Form.Label>
									<p>
										<small>{translations.imageReqs.imageWeight(getFileSize(10485760))}</small>
										<small>
											{translations.imageReqs.imageMinSize(
												!isImageTemplate ? [600, 400] : [600, 240]
											)}
										</small>
									</p>

									<UploadImage
										id='uploadHighlight'
										setPic={setPic}
										pic={ad.pic}
										objectMinSize={
											!isImageTemplate ? {width: 600, height: 400} : {width: 600, height: 240}
										}
										size={10485760}
										acceptedFileTypes='.jpg, .jpeg, .png, .gif, .svg'
									/>
								</div>
							)}

							{!isImageTemplate && (
								<>
									<Form.Group controlId='highlightTitle' className='mb-2'>
										<Form.Label>{translations.highlights.title}</Form.Label>

										<Form.Control
											placeholder={translations.highlights.title}
											name='title'
											required={!ad.text}
											value={ad.title}
											onChange={onChangeHandler}
											maxLength={23}
										/>
										<p className='text-end text-secondary'>
											<small>{ad.titleLength || 0}/23</small>
										</p>
									</Form.Group>

									<Form.Group controlId='highlightText' className='mb-2'>
										<Form.Label>{translations.highlights.text}</Form.Label>

										<Form.Control
											as='textarea'
											rows={4}
											placeholder={translations.highlights.text}
											name='text'
											required={!ad.title}
											value={ad.text}
											onChange={onChangeHandler}
											maxLength={180}
										/>
										<p className='text-end text-secondary'>
											<small>{ad.textLength || 0}/180</small>
										</p>
									</Form.Group>
									<Form.Group controlId='highlightText' className='mb-2'>
										<Form.Label>{translations.highlights.linkText}</Form.Label>
										<InputGroup>
											<InputGroup.Checkbox
												className='checkbox-black'
												checked={isLink}
												onChange={onChangeIsLink}
											/>

											<Form.Control
												placeholder={translations.highlights.linkText}
												name='linkText'
												required={isLink}
												disabled={!isLink}
												value={ad.linkText}
												onChange={onChangeHandler}
												maxLength={28}
											/>
										</InputGroup>
										<p className='text-end text-secondary'>
											<small>{ad.linkTextLength || 0}/28</small>
										</p>
									</Form.Group>
								</>
							)}
							{isLink && (
								<>
									<Form.Group controlId='highlightLink' className='mb-3'>
										<Form.Label>{translations.highlights.link}</Form.Label>

										<Form.Control
											placeholder={translations.highlights.link}
											name='link'
											required={isLink}
											value={ad.link}
											onChange={onChangeHandler}
											type='url'
										/>
									</Form.Group>
									<Form.Group className='mb-3' controlId='linkOpenType'>
										<div>
											<Form.Check
												inline
												className='checkbox-black'
												type='radio'
												label={translations.linkOpenType.newWindow}
												id='linkOpenType2'
												value={LinkOpenType.NEW_WINDOW}
												required
												onChange={onChangeOpenTypeHandler}
												checked={ad.openType === LinkOpenType.NEW_WINDOW}
											/>
										</div>

										<div>
											<Form.Check
												inline
												className='checkbox-black'
												type='radio'
												label={translations.linkOpenType.postMessage}
												id='linkOpenType3'
												value={LinkOpenType.POST_MESSAGE}
												required
												onChange={onChangeOpenTypeHandler}
												checked={ad.openType === LinkOpenType.POST_MESSAGE}
											/>
										</div>
									</Form.Group>
								</>
							)}

							{currentHighlight.template === 10 && (
								<CustomSettings
									color={color}
									picker={picker}
									gradient={gradient}
									setPicker={setPicker}
									setColor={setColor}
									setGradient={setGradient}
								/>
							)}

							<div className='d-flex justify-content-end mt-4'>
								<Button
									variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
									className='d-flex'
									onClick={() => setCurrentStep(currentStep - 1)}>
									<BsArrowLeftCircleFill />
									&nbsp;&nbsp;
									{translations.highlights.prevStep}
								</Button>
								&nbsp;
								<Button
									variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
									type='submit'
									className='d-flex'
									disabled={currentHighlight.template === null}>
									{translations.highlights.nextStep}
									&nbsp;&nbsp;
									<BsArrowRightCircleFill />
								</Button>
								&nbsp;
								{slug && (
									<Button name='save-highlight' variant='success' className='d-flex' type='submit'>
										{translations.btns.save}
									</Button>
								)}
							</div>
						</div>
					</Form>
				</div>
				<div>
					{currentHighlight.template !== null && currentHighlight.template !== undefined && (
						<Template template={currentHighlight.template} ad={ad} isSettings isLink={isLink} />
					)}
				</div>
			</div>
		</Container>
	);
};

export default observer(HighlightSettings);

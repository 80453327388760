import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Alert, Button, Container, Form, Image, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {ReactComponent as IcoWarn} from 'assets/svg/ico-warn.svg';
import {useHistory} from 'react-router-dom';

import Logo from 'assets/images/auth-logo.png';
import SelectControl from 'components/select/SelectControl';
import {findUrlValueByKey} from 'utils/helpers';
import configService from 'store/configService';
import PartnersCreatorService from 'services/api/PartnersCreatorService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import useProjects from 'hooks/useProjects';
import useAdminProxy from 'hooks/useAdminProxy';
import RegisterFooter from '../footer/RegisterFooter';

const StepCompany: FunctionComponent = function StepCompany() {
	const {auth, toasts} = useL10n();
	const history = useHistory();
	const {serviceUrls} = useLocalObservable(() => configService);
	const {addToast} = useLocalObservable(() => toastServices);

	const [validated, setValidated] = useState(false);
	const [projectName, setProjectName] = useState('');
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [clusters, setClusters] = useState<string[]>([]);
	const [cluster, setCluster] = useState({
		value: '',
		label: '',
	});
	const [error, setError] = useState('');
	const [options, setOptions] = useState<{value: string; label: string}[]>([]);

	const {createProject} = useProjects();
	const {getAllProjectUsers} = useAdminProxy();

	const onChangeProjectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		value = value.replace(/[^a-zA-Z0-9- ]/i, '');
		setProjectName(value.trim() ? value : value.trim());
		setError('');
	};

	const handleSelect = (selectedOption: any) => {
		setCluster(selectedOption);
	};

	const create = async () => {
		setVisiblePreloader(true);
		const response = await createProject(projectName);
		if (response.status === ResponseStatus.SUCCESS) {
			await getAllProjectUsers();
			setTimeout(() => {
				setVisiblePreloader(false);
				history.push('/');
			}, 1000);

			return;
		}
		setError(response.data);
		addToast({
			title: toasts.errorTitle,
			text: toasts.error,
			variant: 'danger',
		});
		setVisiblePreloader(false);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		if (cluster) {
			const partnersCreatorServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_PARTNERS_CREATOR_URL_${cluster.value.toUpperCase()}`
			);
			PartnersCreatorService.setBaseUrl(partnersCreatorServiceURL);
			await create();
		}
	};

	const getClusters = () => {
		const findClusters = findUrlValueByKey(serviceUrls, `REACT_APP_CLUSTERS`);
		setClusters(JSON.parse(findClusters));
	};

	useEffect(() => {
		if (clusters.length) {
			setCluster({value: clusters[0], label: clusters[0]});
			setOptions(
				clusters.map(el => {
					return {value: el, label: el};
				})
			);
		}
	}, [clusters.length]);

	useEffect(() => {
		getClusters();
	}, []);

	return (
		<section className='auth'>
			<Container className='mt-auto'>
				<p className='h3 mb-5 text-center'>{auth.steps.project.title}</p>
				<div className='auth__start'>
					<div className='auth__form'>
						<div className='auth__form-content'>
							<div className='auth__personal-info'>
								<Form noValidate validated={validated} onSubmit={handleSubmit}>
									<Form.Group className='mb-4'>
										<Form.Label>{auth.steps.project.name}</Form.Label>
										<Form.Control
											name='projectName'
											placeholder={auth.steps.project.name}
											value={projectName}
											onChange={onChangeProjectHandler}
											required
										/>
									</Form.Group>
									<Form.Group className='mb-4'>
										<Form.Label>{auth.steps.project.cluster}</Form.Label>
										<SelectControl
											handleChange={handleSelect}
											options={options}
											value={cluster}
											theme='light'
										/>
									</Form.Group>

									<Alert variant='danger' className='d-flex align-items-start mb-5'>
										<IcoWarn className='ico-lg' />
										<div className='mx-2'>
											<p className='text-black mb-0'>{auth.steps.project.clusterWarn}</p>
										</div>
									</Alert>
									{error && <p className='text-danger text-center mb-2'>{error}</p>}
									{visiblePreloader && (
										<div className='text-center mt-4 mb-4'>
											<Spinner animation='border' />
										</div>
									)}
									<Button
										type='submit'
										variant='dark'
										size='lg'
										className='w-100 text-center'
										disabled={!!error || visiblePreloader}>
										{auth.btns.next}
									</Button>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</Container>
			<RegisterFooter />
		</section>
	);
};

export default observer(StepCompany);

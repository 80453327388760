/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useDrag, useDrop} from 'react-dnd';
import {PollType} from 'models/enums/Poll.enum';
import type {Identifier, XYCoord} from 'dnd-core';
import pollService from 'store/pollServices';
import _ from 'lodash';
import useL10n from 'l10n/useL10n';

import {getFileSize} from 'utils/helpers';

import './pollAnswers.scss';
import {FloatingLabel, Form, Button} from 'react-bootstrap';
import {BsTrashFill} from 'react-icons/bs';

import PollService from 'services/api/PollService';
import userServices from 'store/userServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertServices from 'store/alertServices';

interface IPollAnswerElementProps {
	index: number;
	id: string | number;
	text: string;
	img: string | null;
	isRight: boolean;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	changeData: (id: string | number, data: any, isRadio?: boolean) => void;
	removeElementOnBlur: (e: React.FocusEvent<HTMLInputElement>, id: string) => void;
}

interface IDragItem {
	index: number;
	id: string;
	type: string;
}

const PollAnswerElement: FunctionComponent<IPollAnswerElementProps> = function PollAnswerElement(
	props
) {
	const {poll, alerts, imageReqs} = useL10n();

	const {index, id, text, img, isRight, moveCard, changeData, removeElementOnBlur} = props;
	const {currentPoll, pollWithPics, setPollWithPics} = useLocalObservable(() => pollService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const [textLengthVisible, setTextLengthVisible] = useState(false);
	const [tipVisibility, setTipVisibility] = useState(false);
	const [textLengthLimit, setTextLengthLimit] = useState(75);
	const [answerText, setAnswerText] = useState(text);

	const ref = useRef<HTMLDivElement>(null);
	const tetxInputRef = useRef<HTMLInputElement>(null);
	const uniqId = id;

	let timeout: ReturnType<typeof setTimeout> | null = null;

	const [{handlerId}, drop] = useDrop<IDragItem, void, {handlerId: Identifier | null}>({
		accept: 'CARD',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: IDragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveCard(dragIndex, hoverIndex);
			// eslint-disable-next-line no-param-reassign
			item.index = hoverIndex;
		},
	});

	const [{isDragging}, drag] = useDrag({
		type: 'CARD',
		item: () => {
			return {id, index};
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	const checkImageSize = (url: any) => {
		const newImg = new Image();

		const promise = new Promise((resolve, reject) => {
			newImg.onload = () => {
				const width = newImg.naturalWidth;
				const height = newImg.naturalHeight;
				resolve({width, height});
			};

			newImg.onerror = reject;
		});

		newImg.src = window.URL.createObjectURL(url);

		return promise;
	};

	const uploadPic = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			checkImageSize(e.target.files[0]).then((object: any) => {
				(async () => {
					if (object.width > 300 && object.height > 300 && e.target.files) {
						if (e.target.files[0].size < 10485760) {
							const formData = new FormData();
							formData.append('pic', e.target.files[0], e.target.files[0].name);
							const response = await PollService.uploadPollOptionPicture(formData, accessToken);
							if (response) {
								setAnswerText(answerText.substring(0, 25));
								changeData(uniqId, {pic: response.data.pic, text: answerText.substring(0, 25)});
								setPollWithPics(true);
							}
							return;
						}
					}
					showAlert({
						title: imageReqs.imageReqs,
						text: `${imageReqs.imageWeight(getFileSize(10485760))}  ${imageReqs.imageMinSize([
							300, 300,
						])}`,
						buttons: [
							{
								text: alerts.btns.ok,
								type: AlertBtnType.NORMAL,
								onClick: () => {
									hideAlert();
								},
							},
						],
					});
				})();
			});
		}
	};

	const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		setAnswerText(value);
		changeData(uniqId, {text: value});
	};

	const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		removeElementOnBlur(e, `${uniqId}`);
		setTextLengthVisible(false);
	};

	const onChangeCheckboxHandler = () => {
		// setTipVisibility(false);
		if (!currentPoll?.isMultiple) {
			changeData(uniqId, {isRight: !isRight}, true);
			return;
		}
		changeData(uniqId, {isRight: !isRight});
	};

	const removePic = () => {
		changeData(id, {pic: null});
		setPollWithPics(
			(currentPoll?.options?.filter(elem => elem.pic !== null).length &&
				currentPoll?.options?.filter(elem => elem.pic !== null).length > 1) ||
				false,
			false
		);
	};

	useEffect(() => {
		pollWithPics.value ? setTextLengthLimit(25) : setTextLengthLimit(75);
	}, [pollWithPics.value]);

	useEffect(() => {
		currentPoll?.type === PollType.QUIZ && setTipVisibility(true);
		timeout = setTimeout(() => {
			setTipVisibility(false);
		}, 3000);
	}, [currentPoll?.type]);

	useEffect(() => {
		if (tetxInputRef.current) {
			tetxInputRef.current.focus();
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, []);

	return (
		<div className='poll-answers__element' style={{opacity}} ref={ref}>
			{currentPoll?.type === PollType.QUIZ && (
				<div className='poll-answers__radio'>
					{index === 0 && tipVisibility && (
						<span className='poll-answers__radio-tip'>{poll.answer.tooltip}</span>
					)}
					<input
						type='checkbox'
						id={`answer-${uniqId}`}
						checked={isRight}
						className='poll-answers__radio-input'
						onChange={onChangeCheckboxHandler}
					/>
					<label htmlFor={`answer-${uniqId}`} className='poll-answers__radio-label' />
				</div>
			)}

			<div className='poll-answers__element-wrapper'>
				<div className='poll-answers__element-input'>
					<div>
						{img && img !== '' && pollWithPics && (
							<img src={img} alt='answerPic' className='poll-answers__img-preview' />
						)}
					</div>

					<FloatingLabel
						controlId='floatingId'
						label={poll.answer.inputPlaceholder}
						className='w-100 text-secondary'>
						<Form.Control
							as='textarea'
							value={answerText}
							name='externalRoomId'
							placeholder={poll.answer.inputPlaceholder}
							maxLength={textLengthLimit}
							onChange={onChangeInputHandler}
							onBlur={onBlurHandler}
							onFocus={() => setTextLengthVisible(true)}
						/>
					</FloatingLabel>

					{textLengthVisible && (
						<span className='poll-answers__input-length text-secondary'>
							{textLengthLimit - _.toArray(answerText).length}
						</span>
					)}
				</div>

				<div className='poll-answers__btns'>
					{img && img !== '' && pollWithPics ? (
						<div>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<Button variant='outline-light' onClick={removePic}>
								<p className='h-3 m-0'>
									<BsTrashFill className='text-danger h-3' />
								</p>
							</Button>
						</div>
					) : (
						<label htmlFor={`${uniqId}`} className='poll-answers__file'>
							<input
								id={`${uniqId}`}
								type='file'
								accept='.jpg, .jpeg, .png, .gif, .svg'
								className='poll-answers__file-input'
								onChange={uploadPic}
							/>
						</label>
					)}

					<span className='poll-answers__dragger' />
				</div>
			</div>
		</div>
	);
};

export default observer(PollAnswerElement);

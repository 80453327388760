import classNames from 'classnames';
import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import MessagesStreamType from 'models/enums/MessagesStream.enum';
import {Theme} from 'models/enums/Theme.enum';
import {FunctionComponent, useEffect, useState} from 'react';

import {InputGroup, Form, FloatingLabel, Button} from 'react-bootstrap';
import {BsGearFill, BsFillArrowRightCircleFill} from 'react-icons/bs';
import appService from 'store/appService';

import messagesServices from 'store/messagesServices';
import roomServices from 'store/roomServices';

import {textEnding} from 'utils/helpers';

const ChatThrottling: FunctionComponent = function ChatThrottling() {
	const translations = useL10n();

	const {
		messagesQueue,
		throttling,
		setThrottling,
		setThrottlingDelay,
		split,
		setSplit,
		stream,
		setStream,
		ignoreAutoposted,
		setIgnoreAutoposted,
		hideImages,
		setHideImages,
	} = useLocalObservable(() => messagesServices);

	const {pinnedMessages} = useLocalObservable(() => roomServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [delay, setDelay] = useState(200);
	const [isOpen, setIsOpen] = useState(false);

	const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setThrottling(checked);
	};

	const onCheckAutoposting = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIgnoreAutoposted(checked);
	};

	const onCheckImages = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setHideImages(checked);
	};

	const onCheckSplit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setSplit(checked);
		localStorage.setItem('isSplitting', checked.toString());
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Math.min(5000, Number(event.target.value.replace(/[^0-9.]/g, '')));
		setDelay(value);
	};

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		const {value} = event.target;
		if (!value || +value < 200) {
			setDelay(200);
			setThrottlingDelay(200);
		} else setThrottlingDelay(delay);
	};

	const setMessagesStream = (value: MessagesStreamType) => {
		setStream(value);
		localStorage.setItem('chatStream', value);
	};

	const btnStreamClasses = (type: MessagesStreamType) =>
		classNames('btn', {
			'btn-primary': type === stream,
			'btn-light': type !== stream,
		});

	const controlClasses = classNames('throttling', {
		'throttling--isOpen': isOpen,
		'throttling--isPinned': pinnedMessages.length > 0,
	});

	useEffect(() => {
		const isSplitting = localStorage.getItem('isSplitting');
		if (isSplitting !== null) setSplit(isSplitting === 'true');
		else setSplit(false);
	}, []);

	return (
		<div className={controlClasses}>
			{!isOpen && (
				<div className='d-flex'>
					<Button className='throttling__opener' variant='link' onClick={() => setIsOpen(!isOpen)}>
						<BsGearFill
							className={classNames('h6', {
								'text-dark': appTheme === Theme.LIGHT,
								'text-primary': appTheme === Theme.DARK,
							})}
						/>
					</Button>
				</div>
			)}
			{isOpen && (
				<Button className='throttling__opener' onClick={() => setIsOpen(!isOpen)}>
					<BsFillArrowRightCircleFill />
				</Button>
			)}

			{isOpen && (
				<Form.Check
					checked={ignoreAutoposted}
					onChange={onCheckAutoposting}
					type='switch'
					id='autoposting'
					label={translations.autoposting.toggle}
				/>
			)}

			{isOpen && (
				<Form.Check
					checked={hideImages}
					onChange={onCheckImages}
					type='switch'
					id='images'
					label={translations.images.hideImages}
				/>
			)}

			{isOpen && <hr />}

			{!throttling && split !== undefined && isOpen && (
				<Form.Check
					checked={split}
					onChange={onCheckSplit}
					type='switch'
					id='streams'
					className='mb-3'
					label={translations.splitStreams}
				/>
			)}

			{split && isOpen && (
				<div className='chat-streams'>
					<Button
						className={btnStreamClasses(MessagesStreamType.ALL)}
						onClick={() => setMessagesStream(MessagesStreamType.ALL)}>
						{translations.all}
					</Button>
					&nbsp;
					<Button
						className={btnStreamClasses(MessagesStreamType.FIRST)}
						onClick={() => setMessagesStream(MessagesStreamType.FIRST)}>
						1 {translations.stream}
					</Button>
					&nbsp;
					<Button
						className={btnStreamClasses(MessagesStreamType.SECOND)}
						onClick={() => setMessagesStream(MessagesStreamType.SECOND)}>
						2 {translations.stream}
					</Button>
				</div>
			)}

			{!split && isOpen && (
				<div className='throttling__control w-100 mb-2'>
					<InputGroup size='sm' className='w-100'>
						<InputGroup.Checkbox
							className='checkbox-black'
							disabled={split}
							checked={throttling}
							onChange={onCheck}
						/>
						<FloatingLabel controlId='floatingSelect' label={translations.throttling}>
							<Form.Control
								size='sm'
								name='name'
								value={delay}
								disabled={!throttling}
								onChange={onChangeHandler}
								onBlur={onBlurHandler}
							/>
						</FloatingLabel>
					</InputGroup>
				</div>
			)}

			{throttling && (
				<p className='throttling__text'>
					{!isOpen && <span>&nbsp;&nbsp;&nbsp;</span>}
					{translations.inQueue} <b>{messagesQueue.length}</b>{' '}
					{textEnding(messagesQueue.length, translations.textEndingMessages)}
				</p>
			)}
		</div>
	);
};

export default observer(ChatThrottling);

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import MessageService from 'services/api/MessageService';

import useL10n from 'l10n/useL10n';
import {useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';
import messagesServices from 'store/messagesServices';
import toastServices from 'store/toastServices';

import {Message} from 'models/room';
import useFlaggedMessage from './useFlaggedMessage';

export default () => {
	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {updateMessageFieldById, setCurrentEditedMessage} = useLocalObservable(
		() => messagesServices
	);
	const {addToast} = useLocalObservable(() => toastServices);
	const {deleteFlaggedMessage} = useFlaggedMessage();

	const setMessageVisibility = async (message: Message) => {
		const {id, room, talker, isVisible} = message;
		let responseText = '';
		const response = await MessageService.setMessageVisibility(id, !isVisible, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			updateMessageFieldById(message.id, {isVisible: !isVisible});
		} else if (response.status === ResponseStatus.ERROR) {
			responseText = translations.toasts.error;
			addToast({
				title: '',
				text: responseText,
				variant: 'danger',
			});
		}
	};

	const setMessageFlagged = async (message: Message) => {
		const response = await MessageService.setMessageFlagged(accessToken, message.id);
		if (response.status === ResponseStatus.SUCCESS) {
			updateMessageFieldById(message.id, {isFlagged: true});
			addToast({
				title: '',
				text: translations.toasts.messageSent(message.text),
				button: {
					text: translations.btns.cancel,
					afterClickText: translations.btns.canceled,
					onClick: () => deleteFlaggedMessage(response.data.id),
				},
				variant: 'success',
			});
			return;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const getEditedMessage = async (messageId: number) => {
		const response = await MessageService.getEditedMessage(accessToken, messageId);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const editMessage = async (messageId: number, body: {text: string}) => {
		const response = await MessageService.editMessage(accessToken, messageId, body);
		if (response.status === ResponseStatus.SUCCESS) {
			setCurrentEditedMessage(null);
			return response.data;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const changePicVisible = async (messageId: number, body: {picData: string}) => {
		const response = await MessageService.editMessage(accessToken, messageId, body);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return response.data;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const deleteMessage = async (messageId: number) => {
		const response = await MessageService.deleteMessage(messageId, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const getMessageReactions = async (body: {
		messageId: number;
		limit?: number;
		offset?: number;
		csv?: boolean;
	}) => {
		const response = await MessageService.getMessageReactions({
			token: accessToken,
			messageId: body.messageId,
			limit: body.limit,
			offset: body.offset,
			csv: body.csv,
		});
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	return {
		setMessageVisibility,
		setMessageFlagged,
		getEditedMessage,
		editMessage,
		deleteMessage,
		changePicVisible,
		getMessageReactions,
	};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect} from 'react';

import {Modal} from 'react-bootstrap';
import modalServices from 'store/modalServices';
import settingsServices from 'store/settingsServices';

import useL10n from 'l10n/useL10n';
import NamedPic from 'pages/main/components/settings/appearence/NamedPic';
import {NamedPicType} from 'models/enums/NamedPicType.enum';
import UploadImage from 'components/uploadImage/uploadImage';
import useSettings from 'hooks/useSettings';

interface IReactionModal {
	onCloseSuccess?: () => void;
	onCloseError?: (error: string) => void;
}

const ReactionModal: FunctionComponent<IReactionModal> = function ReactionModal({
	onCloseSuccess,
	onCloseError,
}) {
	const {
		reactionModalVisible,
		reactionModalElem,
		setReactionModalElem,
		toggleReactionModalVisible,
	} = useLocalObservable(() => modalServices);

	const {reactions} = useLocalObservable(() => settingsServices);

	const {patchEmotion} = useSettings();

	const translations = useL10n();

	const elem: any = reactionModalElem;

	const onModalClose = () => {
		toggleReactionModalVisible(false);
		setReactionModalElem(null);
	};

	const addPic = async (file: File) => {
		elem && patchEmotion(elem.id, file, 'animation');
	};

	const setPic = (file: File | null) => {
		file && addPic(file);
	};

	useEffect(() => {
		elem && setReactionModalElem(reactions.find(el => el.id === elem.id));
	}, [reactions]);

	return (
		<Modal
			show={reactionModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title>{translations.settings.reactions}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{elem && (
					<div className='d-flex w-100 justify-content-between'>
						<div className='w-50 d-flex flex-column align-items-center'>
							<p className='text-center'>pic</p>
							<NamedPic
								index={elem.index}
								elem={elem.pic}
								name={elem.name}
								payload='pic'
								id={elem.id}
								type={NamedPicType.EMOTION}
								editModeOnly
								objectMinSize={{width: 64, height: 64}}
								size={204800}
								acceptedFileTypes='.png'
							/>
						</div>
						<div className='w-50 d-flex flex-column align-items-center'>
							<p className='text-center'>animation</p>
							{!elem.animation ? (
								<UploadImage
									id='uploadAnimation'
									setPic={setPic}
									objectMinSize={{width: 64, height: 64}}
									size={204800}
									acceptedFileTypes='.png'
									clearAfterLoad
									isSquare
								/>
							) : (
								<NamedPic
									index={elem.index}
									elem={elem.animation}
									name={elem.name}
									payload='animation'
									id={elem.id}
									type={NamedPicType.EMOTION}
									editModeOnly
									objectMinSize={{width: 64, height: 64}}
									size={204800}
									acceptedFileTypes='.png'
								/>
							)}
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(ReactionModal);

import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class UserService {
	static encryptChatUserId = async (token: UUID, id: string) => {
		try {
			const {data, status} = await axios.get(`/admin/user/encrypt/${encodeURIComponent(id)}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSelf = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/user/me`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateAdminPic = async (token: UUID, pic?: File) => {
		try {
			const formData = new FormData();
			if (pic) formData.append('pic', pic);

			const {data, status} = await axios.patch(`/admin/user/me`, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {FunctionComponent, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import modalServices from 'store/modalServices';
import useChatMessage from 'hooks/useChatMessage';
import messagesServices from 'store/messagesServices';
import Moment from 'react-moment';

const EditedMessageHistoryModal: FunctionComponent = function EditedMessageHistoryModal() {
	const translations = useL10n();
	const {editedMessageModalVisible, toggleEditedMessageModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {editedMessageId, setEditedMessageId} = useLocalObservable(() => messagesServices);

	const {getEditedMessage} = useChatMessage();

	const [editedMessageHistory, setEditedMessageHistory] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const onShowHandler = async () => {
		if (editedMessageId) {
			setVisiblePreloader(true);
			const response = await getEditedMessage(editedMessageId);
			if (response) setEditedMessageHistory(response);
			setVisiblePreloader(false);
		}
	};

	const onHideHandler = () => {
		toggleEditedMessageModalVisible(false);
		setEditedMessageHistory([]);
		setEditedMessageId(null);
	};

	return (
		<Modal
			className='modal--editedMessage'
			show={editedMessageModalVisible}
			onShow={onShowHandler}
			onHide={onHideHandler}
			animation={false}
			restoreFocus={false}>
			<div className='modal-head'>
				<Modal.Header closeButton>
					<Modal.Title>
						<p className='h3 mb-0'>{translations.modals.editedMessageHistory.title}</p>
					</Modal.Title>
				</Modal.Header>
			</div>

			<Modal.Body>
				{visiblePreloader ? (
					<div className='text-center mt-4'>
						<Spinner animation='border' />
					</div>
				) : (
					<div>
						{editedMessageHistory
							.sort((a: any, b: any) => {
								return Date.parse(b.createdAt) - Date.parse(a.createdAt);
							})
							.map((el: any) => {
								return (
									<div className='mb-3' key={el.id}>
										<p className='mb-0'>{el.text}</p>
										<small className='text-muted'>
											<Moment format='DD.MM.YY HH:mm'>{el.createdAt}</Moment>
										</small>
									</div>
								);
							})}
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(EditedMessageHistoryModal);

import axios from 'axios';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class EnvironmentService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		EnvironmentService.baseUrl = baseUrl;
	};

	static getProject = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${EnvironmentService.baseUrl}/admin/project`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateApiKey = async (token: UUID, projectId: number, apiKey: string) => {
		try {
			const {data, status} = await axios.patch(
				`${EnvironmentService.baseUrl}/admin/project/${projectId}`,
				{apiKey},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {useLocalObservable} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';

import HighlightsService from 'services/api/HighlightsService';

import highlightsServices from 'store/highlightsServices';
import userServices from 'store/userServices';
import alertServices from 'store/alertServices';
import settingsServices from 'store/settingsServices';
import useL10n from 'l10n/useL10n';

import useSettings from './useSettings';

const NO_ROOMS = 'В списке нет существующих комнат';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {currentHighlight, setIsLoaded, setCurrentStep, setCurrentHighlight} = useLocalObservable(
		() => highlightsServices
	);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {colors, currentThemeId} = useLocalObservable(() => settingsServices);

	const translations = useL10n();
	const history = useHistory();

	const {setColors} = useSettings();

	const createHighlight = async (setCreateResponse: any, setConfirmedNavigation: any) => {
		setConfirmedNavigation(true);
		const response = await HighlightsService.createHighlight(accessToken, currentHighlight);
		if (response.status === ResponseStatus.SUCCESS) {
			setCurrentStep(5);
			setCreateResponse(response.data);
			localStorage.removeItem('draft');

			if (currentHighlight.template === 10) {
				const {templateData} = currentHighlight;
				const updatedColors = {...colors};
				updatedColors.highlight = {
					btn: templateData?.btnColor,
					bg: templateData?.bgColor,
				};
				if (templateData?.gradient)
					updatedColors.highlight.gradient = {
						from: templateData?.gradientFrom,
						to: templateData?.gradientTo,
					};

				currentThemeId && setColors(currentThemeId, JSON.stringify(updatedColors, null, '\t'));
			}
		} else if (response.status === ResponseStatus.ERROR) {
			setCurrentStep(4);
			showAlert({
				title: translations.highlights.create,
				text: response.data === NO_ROOMS ? translations.highlights.noExistingRooms : response.data,
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
		}
	};

	const updateHighlight = async () => {
		const response = await HighlightsService.updateHighlight(
			accessToken,
			currentHighlight.id,
			currentHighlight
		);
		if (response.status === ResponseStatus.SUCCESS) {
			if (currentHighlight.template === 10) {
				const {templateData} = currentHighlight;
				const updatedColors = {...colors};
				updatedColors.highlight = {
					btn: templateData?.btnColor,
					bg: templateData?.bgColor,
				};
				if (templateData?.gradient)
					updatedColors.highlight.gradient = {
						from: templateData?.gradientFrom,
						to: templateData?.gradientTo,
					};

				currentThemeId && setColors(currentThemeId, JSON.stringify(updatedColors, null, '\t'));
			}
			history.push('/highlights');
		}
		if (response.status === ResponseStatus.ERROR) {
			setCurrentStep(4);
			showAlert({
				title: translations.highlights.edit,
				text: response.data,
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
		}
	};

	const getHighlight = async (highlightId: number) => {
		const response = await HighlightsService.getHighlight(accessToken, highlightId);

		if (response.status === ResponseStatus.SUCCESS) {
			setCurrentHighlight({
				...response.data[0],
				templateData: JSON.parse(response.data[0].templateData),
			});
			setIsLoaded(true);
		}
	};

	return {createHighlight, updateHighlight, getHighlight};
};

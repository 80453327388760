import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import PageTitle from 'components/pageTitle/PageTitle';

const LiveStreams: FunctionComponent = function LiveStreams() {
	const translations = useL10n();

	const [mySettings, setMySettings] = useState<{
		enableAgora?: boolean;
		enableAutoRecord?: boolean;
		showAudioQualityCheckPopup?: boolean;
		enableRoomSpeak?: boolean;
	}>({});

	const {settings} = useLocalObservable(() => settingsServices);
	const {patchSettings} = useSettings();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = event.target;

		if (name === 'enableAgora' && !checked) {
			setMySettings({
				...mySettings,
				[name]: checked,
			});
			patchSettings({
				[name]: checked,
				enableScreenSharing: checked,
			});
			return;
		}

		setMySettings({
			...mySettings,
			[name]: checked,
		});

		patchSettings({
			[name]: checked,
		});
	};

	useEffect(() => {
		if (settings)
			setMySettings({
				enableAgora: settings.enableAgora,
				enableAutoRecord: settings.enableAutoRecord,
				showAudioQualityCheckPopup: settings.showAudioQualityCheckPopup,
				enableRoomSpeak: settings.enableRoomSpeak,
			});
	}, [settings]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.liveStreams}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.liveStreams}
						link='https://www.notion.so/watchers/API-List-live-premade-audio-streams-2f4a17ffa31f472788b7c82fb2bd2241'
					/>

					<div className='block w-50 mb-3'>
						<p className='h5 mb-4'>{translations.sidebar.liveStreams}</p>
						<div>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableAgora'
								id='enableAgoraToggle'
								label={translations.settings.enableAgora}
								checked={mySettings.enableAgora}
							/>
						</div>
					</div>
					<div className='block w-50 mb-5'>
						<p className='h5 mb-4'>{translations.liveStream.settings}</p>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								id='enableAutoRecord'
								name='enableAutoRecord'
								label={translations.liveStream.autorecording.title}
								checked={mySettings.enableAutoRecord}
								className='mb-2'
							/>
							<p className='text-secondary settings__description'>
								{translations.liveStream.autorecording.text}
							</p>
						</div>
						<div className='mb-4'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='showAudioQualityCheckPopup'
								id='showAudioQualityCheckPopup'
								label={translations.liveStream.qualitySurvey.title}
								checked={mySettings.showAudioQualityCheckPopup}
								className='mb-2'
							/>
							<p className='text-secondary settings__description'>
								{translations.liveStream.qualitySurvey.text}
							</p>
						</div>
						<div className='mb-4'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableRoomSpeak'
								id='enableRoomSpeak'
								label={translations.liveStream.globalStreamAccess.title}
								checked={mySettings.enableRoomSpeak}
								className='mb-2'
							/>
							<p className='text-secondary settings__description'>
								{translations.liveStream.globalStreamAccess.text}
							</p>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(LiveStreams);

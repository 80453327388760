import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';

import Template from 'components/highlights/Template';
import offerService from 'store/offerService';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import {Highlight} from 'models/highlights';

const OfferTemplate: FunctionComponent = function OfferTemplate() {
	const translations = useL10n();
	const {highlights} = translations;
	const {currentOffer, currentStep, setCurrentOffer, setCurrentStep} = useLocalObservable(
		() => offerService
	);
	const {appTheme} = useLocalObservable(() => appService);

	const templates = [0, 1, 2, 11, 10];

	const [ad, setAd] = useState<Highlight>({
		id: 0,
		template: 0,
	});
	const [customAd, setCustomAd] = useState<Highlight>({
		id: 0,
		template: 0,
	});

	const checkTemplateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = event.target;
		setCurrentOffer({...currentOffer, template: +value});
	};

	const onPrevClickHandler = () => {
		setCurrentStep(currentStep - 1);
	};

	const onNextClickHandler = () => {
		setCurrentStep(currentStep + 1);
	};

	useEffect(() => {
		setAd({
			id: 0,
			template: 0,
			title: highlights.specialOffer,
			text: highlights.specialOfferText,
			linkText: highlights.learnMore,
			link: '11',
		});
		setCustomAd({
			id: 0,
			template: 0,
			title: highlights.specialOffer,
			text: highlights.specialOfferTextShort,
			linkText: highlights.learnMore,
			link: '11',
		});
	}, [highlights]);

	useEffect(() => {
		document.getElementById('create-offer')?.scrollIntoView({behavior: 'smooth'});
	}, []);

	return (
		<section className='offer__selection'>
			<div className='offer__selection-list'>
				{templates.map((el: number) => {
					return (
						<Form.Label htmlFor={`offer-template${el}`} key={el} className='offer__selection-item'>
							<div className='offer__selection-item-content'>
								<Template template={el} ad={customAd} isOffer />
							</div>
							<div className='offer__selection-radio'>
								<Form.Check
									inline
									name='offer-template'
									type='radio'
									label={translations.offers[`template${el}`]}
									id={`offer-template${el}`}
									value={el}
									checked={currentOffer.template === el}
									onChange={checkTemplateHandler}
								/>
							</div>
						</Form.Label>
					);
				})}
			</div>

			<div className='offer__footer'>
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					className='mx-2'
					onClick={onNextClickHandler}
					disabled={currentStep === 1 && currentOffer.template === null}>
					{translations.welcome.steps.btns.next}
					&nbsp;&nbsp;
					<BsArrowRightCircleFill />
				</Button>
			</div>
		</section>
	);
};

export default observer(OfferTemplate);

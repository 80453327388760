import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import useL10n from 'l10n/useL10n';

import Template from 'components/highlights/Template';
import {Highlight} from 'models/highlights';

const HighlightTemplate: FunctionComponent = function HighlightTemplate() {
	const translations = useL10n();
	const {highlights} = translations;

	const [ad, setAd] = useState<Highlight>({
		id: 0,
		template: 0,
	});
	const [customAd, setCustomAd] = useState<Highlight>({
		id: 0,
		template: 0,
	});

	useEffect(() => {
		setAd({
			id: 0,
			template: 0,
			title: highlights.specialOffer,
			text: highlights.specialOfferText,
			linkText: highlights.learnMore,
			link: '11',
		});
		setCustomAd({
			id: 0,
			template: 0,
			title: highlights.specialOffer,
			text: highlights.specialOfferTextShort,
			linkText: highlights.learnMore,
			link: '11',
		});
	}, [highlights]);

	useEffect(() => {
		document.getElementById('create-highlight')?.scrollIntoView({behavior: 'smooth'});
	}, []);

	return (
		<section className='highlights__selection'>
			<p className='h5 mb-4'>{translations.highlights.templates}</p>
			<div className='d-flex flex-wrap mb-5'>
				<Template template={3} ad={ad} />
				<Template template={4} ad={ad} />
				<Template template={5} ad={ad} />
				<Template template={6} ad={ad} />
				<Template template={7} ad={ad} />
				<Template template={8} ad={ad} />
				<Template template={9} ad={ad} />
			</div>
			<p className='h5 mb-4'>{translations.highlights.custom}</p>
			<div className='d-flex align-items-start flex-wrap mb-5'>
				<Template template={0} ad={customAd} />
				<Template template={1} ad={customAd} />
				<Template template={2} ad={customAd} />
			</div>
			<p className='h5 mb-4'>{translations.highlights.textType}</p>
			<div className='mb-5'>
				<Template template={10} ad={ad} />
			</div>
			<p className='h5 mb-4'>{translations.highlights.imageType}</p>
			<div className='mb-5'>
				<Template template={11} ad={ad} />
			</div>
		</section>
	);
};

export default observer(HighlightTemplate);

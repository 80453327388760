import {PollOption} from 'models/poll';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';

interface IPollOptionProps {
	option: PollOption;
}

const PollOptionItem: FunctionComponent<IPollOptionProps> = function PollOptionItem({option}) {
	const {pic, text} = option;

	return (
		<div className='poll-options__item'>
			<div className='poll-options__item-container'>
				{pic && <div className='poll-options__item-img' style={{backgroundImage: `url(${pic})`}} />}
				<div className='poll-options__item-content'>
					<p className='poll-options__item-title'>{text}</p>
				</div>
			</div>
		</div>
	);
};

export default observer(PollOptionItem);

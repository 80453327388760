import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form, FloatingLabel, Button} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import PageTitle from 'components/pageTitle/PageTitle';

const Email: FunctionComponent = function Email() {
	const translations = useL10n();

	const [email, setEmail] = useState('');
	const [validated, setValidated] = useState(false);
	const [isContactUsButton, setIsContactUsButton] = useState<any>(false);
	const {settings} = useLocalObservable(() => settingsServices);
	const {patchSettings} = useSettings();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsContactUsButton(checked);
		patchSettings({enableContactUsButton: checked});
	};

	const onChangeEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setEmail(value);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else {
			patchSettings({
				feedbackEmail: email,
			});
		}
	};

	useEffect(() => {
		settings?.feedbackEmail && setEmail(settings?.feedbackEmail);
		if (settings) setIsContactUsButton(settings.enableContactUsButton);
	}, [settings]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.email.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.email.title}
						text={translations.sidebar.email.descr}
					/>

					<div className='block w-50'>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableContactUsButton'
								id='enableContactUsButtonToggle'
								label={translations.settings.enableContactUsButton.title}
								checked={isContactUsButton}
							/>
							<p className='text-secondary settings__description'>
								{translations.settings.enableContactUsButton.descr}
							</p>
						</div>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<FloatingLabel controlId='email' label='Email' className='w-100 text-secondary mb-3'>
								<Form.Control
									name='email'
									placeholder='Email'
									type='email'
									value={email}
									onChange={onChangeEmailHandler}
									required
								/>
							</FloatingLabel>
							<Button type='submit'>{translations.btns.save}</Button>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Email);

import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class BotService {
	static getBot = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/bot`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static createBot = async (token: UUID, bot: Bot) => {
		try {
			const formData = new FormData();
			// eslint-disable-next-line array-callback-return
			Object.entries(bot).map(([key, value]) => {
				if (value) {
					if (key === 'pic') {
						if (typeof value !== 'string' && typeof value !== 'number')
							formData.append('pic', value);
						return;
					}
					formData.append(key, `${value}`);
				}
			});

			const {data, status} = await axios.post(`/admin/bot`, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateBot = async (token: UUID, bot: Bot, id: number) => {
		try {
			const formData = new FormData();
			formData.append('name', `${bot.name}`);
			formData.append('color', `${bot.userColorId}`);
			if (bot.pic) {
				formData.append('pic', bot.pic);
			}

			const apiUrl = `/admin/bot/${id}`;

			const {data, status} = await axios.patch(apiUrl, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteBot = async (token: UUID, id: number) => {
		try {
			const apiUrl = `/admin/bot/${id}`;

			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

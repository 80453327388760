import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {AuthUser} from 'models/auth';

export default class AdminProxyService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		AdminProxyService.baseUrl = baseUrl;
	};

	static register = async (user: AuthUser) => {
		try {
			const {data, status} = await axios.post(`${AdminProxyService.baseUrl}/auth/register`, user);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message || error.message || null);
		}
	};

	static authConfirm = async (token: string) => {
		try {
			const {data, status} = await axios.post(
				`${AdminProxyService.baseUrl}/auth/register/confirm`,
				{token}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static self = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${AdminProxyService.baseUrl}/user/me`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static login = async (loginData: {email: string; password: string}) => {
		try {
			const {data, status} = await axios.post(`${AdminProxyService.baseUrl}/auth/login`, loginData);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static passwordReset = async (email: string) => {
		try {
			const {data, status} = await axios.post(`${AdminProxyService.baseUrl}/auth/password/reset`, {
				email,
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message || error.message || null);
		}
	};

	static passwordUpdate = async (token: string, password: string) => {
		try {
			const body = {
				token,
				password,
			};
			const {data, status} = await axios.post(
				`${AdminProxyService.baseUrl}/auth/password/update`,
				body
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message || error.message || null);
		}
	};

	static getProjectName = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${AdminProxyService.baseUrl}/project`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateProjectName = async (token: UUID, name: string) => {
		try {
			const {data, status} = await axios.patch(
				`${AdminProxyService.baseUrl}/project`,
				{name},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAdmins = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${AdminProxyService.baseUrl}/project_user`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAdmin = async (token: UUID, userId: number) => {
		try {
			const {data, status} = await axios.get(`${AdminProxyService.baseUrl}/user/${userId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteProjectUser = async (id: number, token: UUID) => {
		try {
			const {data, status} = await axios.delete(`${AdminProxyService.baseUrl}/project_user/${id}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addAdmin = async (
		adminData: {name: string; password: string; role: string},
		token: UUID
	) => {
		try {
			const body = {
				name: adminData.name,
				password: adminData.password,
				role: adminData.role,
			};
			const {data, status} = await axios.post(`${AdminProxyService.baseUrl}/user`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static updateAdmin = async (
		token: UUID,
		adminData: {name?: string; chatName?: string; password?: string}
	) => {
		try {
			const {data, status} = await axios.patch(`${AdminProxyService.baseUrl}/user`, adminData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updatePassword = async (token: UUID, oldPassword: string, password: string) => {
		try {
			const body = {
				oldPassword,
				password,
			};

			const {data, status} = await axios.post(`${AdminProxyService.baseUrl}/user/password`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static updateProjectUser = async (token: UUID, id: number, adminData: {role: string}) => {
		try {
			const {data, status} = await axios.patch(
				`${AdminProxyService.baseUrl}/project_user/${id}`,
				adminData,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';

import {
	BsTextCenter,
	BsTextLeft,
	BsX,
	BsArrowRightShort,
	BsArrowUp,
	BsArrowDownRight,
	BsArrowDownLeft,
} from 'react-icons/bs';

import {HexColorPicker} from 'react-colorful';
import highlightsServices from 'store/highlightsServices';
import {HighlightGradient, PICKER} from 'models/enums/Highlights.enum';

import {getContrastColor} from 'utils/helpers';

import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import offerService from 'store/offerService';
import {templateData} from 'models/highlights';

interface ICustomSettings {
	color: string;
	picker: string;
	gradient: boolean;
	setPicker: (value: string) => void;
	setColor: (value: string) => void;
	setGradient: (value: boolean) => void;
	isOffer?: boolean;
}
const CustomSettings: FunctionComponent<ICustomSettings> = function CustomSettings({
	color,
	picker,
	gradient,
	setPicker,
	setColor,
	setGradient,
	isOffer,
}) {
	const {currentHighlight, setCurrentHighlight} = useLocalObservable(() => highlightsServices);
	const {currentOffer, setCurrentOffer} = useLocalObservable(() => offerService);
	const {brandingColors, clearCustomHighlightColors, clearGradientColors} = useLocalObservable(
		() => settingsServices
	);

	const translations = useL10n();

	const currentElem = isOffer ? currentOffer : currentHighlight;

	const setCurrentElem = (value: any) => {
		if (isOffer) {
			setCurrentOffer(value);
			return;
		}
		setCurrentHighlight(value);
	};

	const onCheckGradient = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setGradient(checked);

		if (checked) {
			setCurrentElem({
				...currentElem,
				templateData: {
					...currentElem.templateData,
					gradient: true,
					gradientFrom: currentElem.templateData?.bgColor,
					gradientTo: currentElem.templateData?.bgColor,
					gradientDirection: HighlightGradient.TOP,
				},
			});
		} else {
			clearGradientColors();
			setCurrentElem({
				...currentElem,
				templateData: {
					...currentElem.templateData,
					gradient: false,
					gradientFrom: null,
					gradientTo: null,
					gradientDirection: null,
				},
			});
		}
	};

	const colorClickHandler = (currentPicker: string) => {
		setPicker(currentPicker);
		if (currentElem.templateData) {
			const currentColor = currentElem.templateData[currentPicker as keyof templateData];
			setColor(`${currentColor}`);
		}
	};

	const setHexColor = (pickedColor: string) => {
		setColor(pickedColor);
		setCurrentElem({
			...currentElem,
			templateData: {...currentElem.templateData, [`${picker}`]: pickedColor},
		});
	};

	const resetClickHandler = () => {
		clearCustomHighlightColors();
		setPicker('');
		setColor('');
		setGradient(false);
		clearGradientColors();
		setCurrentElem({
			...currentElem,
			templateData: {
				...currentElem.templateData,
				bgColor: brandingColors.bg,
				btnColor: brandingColors.btn,
				textColor: getContrastColor(brandingColors.bg),
				btnTextColor: getContrastColor(brandingColors.btn),
				gradient: false,
				gradientFrom: null,
				gradientTo: null,
				gradientDirection: null,
			},
		});
	};

	const alignClickHandler = (value: string) => {
		setCurrentElem({
			...currentElem,
			templateData: {...currentElem.templateData, alignment: value},
		});
	};

	const directionClickHandler = (value: HighlightGradient) => {
		setCurrentElem({
			...currentElem,
			templateData: {
				...currentElem.templateData,
				gradientDirection: value,
			},
		});
	};

	const isResetBtn =
		brandingColors.bg !== currentElem.templateData?.bgColor ||
		brandingColors.btn !== currentElem.templateData?.btnColor ||
		currentElem.templateData?.gradient;

	return (
		<div>
			<p>{translations.highlights.alignment}</p>
			<div className='d-flex mb-5'>
				<Button
					variant={currentElem.templateData?.alignment === 'left' ? 'primary' : 'text'}
					onClick={() => alignClickHandler('left')}>
					<BsTextLeft size={25} />
				</Button>
				&nbsp;
				<Button
					variant={currentElem.templateData?.alignment === 'center' ? 'primary' : 'text'}
					onClick={() => alignClickHandler('center')}>
					<BsTextCenter size={25} />
				</Button>
			</div>

			<Row className='mb-5 pt-4 position-relative'>
				<div className='d-flex justify-content-end position-absolute top-0'>
					{isResetBtn && (
						<button type='button' className='link d-flex' onClick={resetClickHandler}>
							<BsX size={25} />
							Сбросить
						</button>
					)}
				</div>
				<Col md={3}>
					<p>{translations.highlights.btnColor}</p>
					<button
						type='button'
						className={`highlight__color ${
							picker === PICKER.BTN ? `highlight__color--active` : ''
						}`}
						style={{backgroundColor: currentElem.templateData?.btnColor}}
						onClick={() => colorClickHandler(PICKER.BTN)}>
						{' '}
					</button>
				</Col>

				<Col md={3}>
					<div>
						<p>{translations.highlights.bgColor}</p>
						<div className='d-flex'>
							{!gradient && (
								<button
									type='button'
									className={`highlight__color ${
										picker === PICKER.BG ? `highlight__color--active` : ''
									}`}
									style={{backgroundColor: currentElem.templateData?.bgColor}}
									onClick={() => colorClickHandler(PICKER.BG)}>
									{' '}
								</button>
							)}

							{gradient && (
								<>
									<button
										type='button'
										className={`highlight__color ${
											picker === PICKER.GRADIENT_FROM ? `highlight__color--active` : ''
										}`}
										style={{
											backgroundColor: currentElem.templateData?.gradientFrom || '',
										}}
										onClick={() => colorClickHandler(PICKER.GRADIENT_FROM)}>
										{' '}
									</button>
									&nbsp;
									<BsArrowRightShort size={25} />
									&nbsp;
									<button
										type='button'
										className={`highlight__color ${
											picker === PICKER.GRADIENT_TO ? `highlight__color--active` : ''
										}`}
										style={{
											backgroundColor: currentElem.templateData?.gradientTo || '',
										}}
										onClick={() => colorClickHandler(PICKER.GRADIENT_TO)}>
										{' '}
									</button>
								</>
							)}
						</div>
					</div>
				</Col>

				<Col md={4}>
					<Form.Check
						checked={gradient}
						onChange={onCheckGradient}
						type='switch'
						id='gradient'
						className='text-secondary mb-3'
						label={translations.highlights.bgGradient}
					/>

					<div className='d-flex'>
						{gradient && (
							<div>
								<Button
									variant={
										currentElem.templateData?.gradientDirection === HighlightGradient.TOP
											? 'primary'
											: 'text'
									}
									onClick={() => directionClickHandler(HighlightGradient.TOP)}>
									<BsArrowUp size={22} />
								</Button>
								&nbsp;
								<Button
									variant={
										currentElem.templateData?.gradientDirection === HighlightGradient.RIGHT
											? 'primary'
											: 'text'
									}
									onClick={() => directionClickHandler(HighlightGradient.RIGHT)}>
									<BsArrowDownRight size={22} />
								</Button>
								&nbsp;
								<Button
									variant={
										currentElem.templateData?.gradientDirection === HighlightGradient.LEFT
											? 'primary'
											: 'text'
									}
									onClick={() => directionClickHandler(HighlightGradient.LEFT)}>
									<BsArrowDownLeft size={22} />
								</Button>
							</div>
						)}
					</div>
				</Col>
			</Row>
			{picker && (
				<HexColorPicker
					color={color}
					onChange={pickedColor => setHexColor(pickedColor)}
					className='w-100'
				/>
			)}
		</div>
	);
};

export default observer(CustomSettings);

import {LIMIT} from 'constants/constants';
import {useLocalObservable} from 'mobx-react-lite';
import {Ban} from 'models/ban';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import BanService from 'services/api/BanService';
import banServices from 'store/banServices';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {autoBansCount, setAutoBans, setAutoBansCount} = useLocalObservable(() => banServices);

	const getAutoBanned = async (limit?: number, offset?: number, order?: string) => {
		const response = await BanService.getAutoBanned(
			accessToken,
			limit || LIMIT,
			offset || 0,
			order || 'createdAt_desc'
		);
		if (response.status === ResponseStatus.SUCCESS) {
			setAutoBans(response.data.bans);
			!autoBansCount &&
				setAutoBansCount(response.data.bans.filter((el: Ban) => !el.isApproved).length);
		}
	};
	return {
		getAutoBanned,
	};
};

import axios from 'axios';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import {filter} from 'models/filter';

export default class FlaggedMessageService {
	static getFlaggedMessages = async (
		token: UUID,
		limit: number,
		offset: number,
		order: string,
		searchFilter?: filter | null
	) => {
		try {
			let apiUrl;
			if (searchFilter) {
				let filtersString = '';

				filtersString += `&${searchFilter.key}=${searchFilter.prefix}${
					searchFilter.prefix ? '_' : ''
				}${searchFilter.value}`;

				apiUrl = `admin/flaggedMessage?limit=${limit}&offset=${offset}&order=${order}${filtersString}`;
			} else apiUrl = `admin/flaggedMessage?limit=${limit}&offset=${offset}&order=${order}`;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getNewFlaggedMessagesCount = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`admin/flaggedMessage/countNew`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static resolveFlaggedMessage = async (
		token: UUID,
		body: {flaggedMessageId: number; status?: string; comment?: string}
	) => {
		try {
			const {data, status} = await axios.post(`/admin/flaggedMessage/resolve`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateFlaggedMessage = async (token: UUID, flaggedMessageId: number, comment: string) => {
		const body = {
			comment,
		};
		try {
			const {data, status} = await axios.patch(`/admin/flaggedMessage/${flaggedMessageId}`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteFlaggedMessage = async (token: UUID, flaggedMessageId: number) => {
		try {
			const {data, status} = await axios.delete(`/admin/flaggedMessage/${flaggedMessageId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Container, Row, Col, Breadcrumb, Tabs, Tab} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';

import useSettings from 'hooks/useSettings';
import PageTitle from 'components/pageTitle/PageTitle';
import Greeting from './components/settings/lang/Greeting';
import ChatRules from './components/settings/lang/ChatRules';
import Lang from './components/settings/lang/Lang';
import RoomStates from './components/settings/lang/RoomStates';

const Language: FunctionComponent = function Language() {
	const [key, setKey] = useState('lang');

	const {getSettings} = useSettings();

	const translations = useL10n();

	useEffect(() => {
		getSettings();
	}, []);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.language.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.language.title}
						text={translations.sidebar.language.descr}
					/>
				</Col>
			</Row>

			<Tabs
				activeKey={key}
				onSelect={(k: any) => setKey(k)}
				defaultActiveKey='profile'
				id='uncontrolled-tab-example'
				className='mb-3'>
				<Tab eventKey='lang' title={translations.settings.lang} tabClassName='text-secondary'>
					<div className='w-50'>
						<Lang />
					</div>
				</Tab>

				<Tab
					eventKey='greeting'
					title={translations.settings.greeting.title}
					tabClassName='text-secondary'>
					<div className='w-50'>
						<Greeting currentTab={key} />
					</div>
				</Tab>

				<Tab
					eventKey='chatrules'
					title={translations.settings.chatRules.title}
					tabClassName='text-secondary'>
					<div className='w-100'>
						<ChatRules currentTab={key} />
					</div>
				</Tab>

				<Tab
					eventKey='roomstates'
					title={translations.settings.roomStates.title}
					tabClassName='text-secondary'>
					<div className='w-100'>
						<RoomStates currentTab={key} />
					</div>
				</Tab>
			</Tabs>
		</Container>
	);
};

export default observer(Language);

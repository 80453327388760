import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Button,
	Image,
	Accordion,
	Spinner,
} from 'react-bootstrap';
import userServices from 'store/userServices';
import ModerService from 'services/api/ModerService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertService from 'store/alertServices';

import useL10n from 'l10n/useL10n';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

const Moders: FunctionComponent = function Moders() {
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {appTheme} = useLocalObservable(() => appService);

	const [moders, setModers] = useState<any>([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const translations = useL10n();

	const getModers = async () => {
		if (accessToken) {
			setVisiblePreloader(true);
			const response = await ModerService.getModers(accessToken);
			setVisiblePreloader(false);
			if (response.status === ResponseStatus.SUCCESS) {
				const modersFilter = new Map();

				response.data.forEach((moder: any) => {
					if (modersFilter.has(moder.user.id)) {
						modersFilter.set(moder.user.id, [...modersFilter.get(moder.user.id), moder]);
					} else {
						modersFilter.set(moder.user.id, [moder]);
					}
				});
				setModers(Array.from(modersFilter.values()));
			}
		}
	};

	const unMode = async (talkerId: number, externalRoomId: string) => {
		const moderData = {
			talkerId,
			value: false,
			externalRoomId,
		};
		const response = await ModerService.toggleTalkerModer(moderData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			getModers();
			showAlert({
				title: translations.alerts.modersList,
				text: translations.alerts.unMode,
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.SUCCESS,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
		}
	};

	useEffect(() => {
		getModers();
	}, [accessToken]);

	const renderModer = useCallback(
		(item: any, index: any) => {
			return (
				<Accordion defaultActiveKey={['0']} className='mb-2' alwaysOpen key={index}>
					<Accordion.Item eventKey='index'>
						<Accordion.Header>
							<div>
								<div className='user-info text-truncate'>
									<div className={`user-info__photo background-color-${item[0].user.color || 0}`}>
										<Image src={item[0].user.pic} roundedCircle fluid />
									</div>
									<span className='d-inline'>{item[0].user.name}</span>
								</div>
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<Table
								responsive
								striped={appTheme === Theme.LIGHT}
								bordered
								hover
								variant={appTheme?.toLowerCase()}>
								<thead>
									<tr>
										<th>
											<b>{translations.table.externalRoomId}</b>
										</th>
										<th>
											<b>{translations.table.name}</b>
										</th>
										<th className='col-2 text-center'>
											<b> </b>
										</th>
									</tr>
								</thead>
								<tbody>
									{item.map((elem: any, i: number) => {
										return (
											<tr key={i}>
												<td>{elem.room.externalRoomId}</td>
												<td>{elem.room.name || translations.empty.nameEmpty}</td>
												<td className='col-2 text-center'>
													<Button
														variant='danger'
														onClick={() => unMode(item[0].id, elem.room.externalRoomId)}>
														{translations.btns.unMode}
													</Button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			);
		},
		[moders]
	);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.admin}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.roomModers}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.roomModers}</p>
					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{moders.length > 0 && <div>{moders.map(renderModer)}</div>}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Moders);

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {Message} from 'models/room';

export default class MessageService {
	static setMessageVisibility = async (messageId: number, isVisible: boolean, token: UUID) => {
		try {
			const body = {
				messageId,
				isVisible,
			};
			const {data, status} = await axios.post(`/admin/message/visible`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setUserVisibility = async (
		message: Message,
		isVisible: boolean,
		token: UUID,
		externalRoomId: string | null
	) => {
		try {
			const body = {
				userId: message.user.id,
				externalRoomId: externalRoomId || message.externalRoomId,
				isVisible,
			};
			const {data, status} = await axios.post(`/admin/message/user/visible`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteMessage = async (messageId: number, token: string) => {
		try {
			const {data, status} = await axios.delete(`/admin/message/${messageId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessages = async (body: {
		token: UUID;
		ignoreAutoposted?: number;
		limit?: number;
		type?: {
			name: string;
			id: number;
		};
		hideImages?: boolean;
	}) => {
		try {
			const messageType = body.hideImages
				? 'messageType=USER,BET'
				: 'messageType=USER,BET,PIC,GAMBLE';
			let apiUrl = `/admin/message?limit=${body.limit || 50}&ignoreAutoposted=${
				body.ignoreAutoposted
			}&${messageType}`;

			if (body.type) {
				apiUrl = `${apiUrl}&${body.type.name}=${body.type.id}`;
			}
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${body.token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getUserMessages = async (token: UUID, userId: number, limit?: number, lastId?: number) => {
		try {
			let apiUrl = `/admin/message/user/${userId}`;
			if (typeof lastId !== 'undefined') {
				apiUrl = `${apiUrl}?limit=${limit || 100}&lastId=${lastId}`;
			}
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAroundMessages = async (body: {
		token: UUID;
		messageId: number;
		limit?: number;
		ignoreAutoposted?: number;
		hideImages: boolean;
	}) => {
		try {
			let apiUrl = `/admin/message/around/${body.messageId}?limit=${body.limit || 100}`;
			const messageType = body.hideImages
				? 'messageType=USER,BET'
				: 'messageType=USER,BET,PIC,GAMBLE';
			if (body.ignoreAutoposted) {
				apiUrl = `${apiUrl}&ignoreAutoposted=${Number(body.ignoreAutoposted)}&${messageType}`;
			}

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${body.token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setMessageFlagged = async (token: UUID, messageId: number) => {
		try {
			const body = {
				messageId,
			};
			const {data, status} = await axios.post(`/admin/message/flag`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getEditedMessage = async (token: UUID, messageId: number) => {
		try {
			const {data, status} = await axios.get(`admin/message/edited/${messageId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static editMessage = async (token: UUID, messageId: number, body: any) => {
		try {
			const {data, status} = await axios.patch(`/admin/message/${messageId}`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessageReactions = async (body: {
		token: UUID;
		messageId: number;
		limit?: number;
		offset?: number;
		csv?: boolean;
	}) => {
		try {
			const apiUrl = `/admin/reaction/message/${body.messageId}?limit=${body.limit || 50}&offset=${
				body.offset || 0
			}&csv=${body.csv || 0}`;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${body.token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Spinner, Tabs, Tab, Button} from 'react-bootstrap';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	TimeScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import AnalyticsService from 'services/api/AnalyticsService';
import appService from 'store/appService';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import 'chartjs-adapter-moment';
import {tabs} from 'constants/analytics';
import {Theme} from 'models/enums/Theme.enum';

ChartJS.register(
	CategoryScale,
	LinearScale,
	TimeScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const Analytics: FunctionComponent = function Analytics() {
	const translations = useL10n();
	const {accessToken, isSuperAdmin} = useLocalObservable(() => userServices);
	const {projectId, appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [analyticsData, setAnalyticsData] = useState<any>(null);
	const [key, setKey] = useState('dau90d');
	const [currentTabData, setCurrentTabData] = useState<any>(null);
	const [isReloadAnalyticsStarted, setIsReloadAnalyticsStarted] = useState(false);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
		},
		scales: {
			x: {
				type: 'time' as const,
				display: true,
				time: {
					unit: 'month' as const,
				},
			},
		},
	};

	const getAnalytics = async () => {
		setVisiblePreloader(true);
		const response = await AnalyticsService.getAnalytics(accessToken, projectId);
		if (response.status === ResponseStatus.SUCCESS) {
			setAnalyticsData(response.data);
			setVisiblePreloader(false);
		}
	};

	const reloadAnalytics = async () => {
		setIsReloadAnalyticsStarted(true);
		await AnalyticsService.reloadAnalytics(accessToken, projectId);
	};

	const setData = (data: string | undefined) => {
		if (data) {
			const currentTab = tabs.find(tab => tab.key === key);
			if (currentTab) {
				const tabData = {
					labels: JSON.parse(data).map((el: any) => el[currentTab.labels.value]),
					datasets: [
						{
							label: currentTab.datasets[0]?.label,
							data: JSON.parse(data).map((el: any) => el[currentTab.datasets[0].value]),
							borderColor: 'rgb(55, 99, 132)',
							backgroundColor: 'rgba(55, 99, 132, 0.5)',
						},
					],
				};
				if (currentTab.datasets[1]) {
					tabData.datasets.push({
						label: currentTab.datasets[1]?.label,
						data: JSON.parse(data).map((el: any) => el[currentTab.datasets[1]?.value || 0]),
						borderColor: 'rgb(53, 162, 235)',
						backgroundColor: 'rgba(53, 162, 235, 0.5)',
					});
				}
				setCurrentTabData(tabData);
			}
		}
	};

	useEffect(() => {
		key && analyticsData && setData(analyticsData[key]);
	}, [key]);

	useEffect(() => {
		analyticsData && setData(analyticsData.dau90d);
	}, [analyticsData]);

	useEffect(() => {
		getAnalytics();
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row className='align-items-center'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.analytics}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.analytics}</p>
				</Col>
				{isSuperAdmin && (
					<Col className='d-flex flex-column justify-content-end align-items-end'>
						<Button
							type='button'
							variant={appTheme === Theme.LIGHT ? 'dark' : 'outline-light'}
							onClick={reloadAnalytics}
							className='mx-2'
							disabled={isReloadAnalyticsStarted}>
							{translations.btns.refresh}
						</Button>
						{isReloadAnalyticsStarted && (
							<small className='text-secondary'>{translations.analytics.updateRequestSent}</small>
						)}
					</Col>
				)}
			</Row>
			<div className='block'>
				{visiblePreloader ? (
					<div className='text-center mt-4'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				) : (
					<Tabs
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						defaultActiveKey='analytics'
						id='analytics-tabs'
						className='mb-3'>
						{tabs.map((tab, index) => {
							return (
								<Tab
									key={tab.key}
									eventKey={tab.key}
									title={tab.label}
									tabClassName='text-secondary'>
									<Line options={options} data={currentTabData} />
								</Tab>
							);
						})}
					</Tabs>
				)}
			</div>
		</Container>
	);
};

export default observer(Analytics);

import {FunctionComponent} from 'react';
import Logo from 'assets/images/auth-logo.png';
import {Image} from 'react-bootstrap';

const RegisterFooter: FunctionComponent = function RegisterFooter() {
	return (
		<div className='auth__footer'>
			<div className='auth__footer-wrapper'>
				<ul className='auth__footer-links'>
					<li>
						<a
							href='https://watchers.io/'
							target='_blank'
							rel='noreferrer'
							className='auth__footer-link text-secondary'>
							Home
						</a>
					</li>
					<li>
						<a
							href='https://legal.watchers.io/privacy_policy.pdf'
							target='_blank'
							rel='noreferrer'
							className='auth__footer-link text-secondary'>
							Privacy
						</a>
					</li>
					<li>
						<a
							href='mailto:hello@watchers.io'
							target='_blank'
							rel='noreferrer'
							className='auth__footer-link text-secondary'>
							Contacts
						</a>
					</li>
				</ul>
				<Image src={Logo} alt='' className='auth__footer-logo' />
			</div>
		</div>
	);
};

export default RegisterFooter;

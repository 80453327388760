enum AlertBtnType {
	DANGER = 'danger',
	NORMAL = 'secondary',
	SUCCESS = 'success',
	DARK = 'dark',
	LIGHT = 'light',
	OUTLINE = 'outline-dark',
	OUTLINE_LIGHT = 'outline-light',
}

export {AlertBtnType};

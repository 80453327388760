import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {FunctionComponent, useRef} from 'react';

import {Form, Button} from 'react-bootstrap';
import {BsTrashFill} from 'react-icons/bs';

import alertServices from 'store/alertServices';
import './uploadImage.scss';

import useL10n from 'l10n/useL10n';

import {checkImageSize, getFileSize} from 'utils/helpers';

interface IUploadImage {
	id: string;
	pic?: string | null;
	setPic: (file: File | null) => void;
	size: number;
	objectMinSize: {
		width: number;
		height: number;
	};
	isSquare?: boolean;
	acceptedFileTypes: string;
	clearAfterLoad?: boolean;
	required?: boolean;
}

const uploadImage: FunctionComponent<IUploadImage> = function uploadImage({
	id,
	pic,
	setPic,
	size,
	objectMinSize,
	isSquare,
	acceptedFileTypes,
	clearAfterLoad,
	required = true,
}) {
	const translations = useL10n();

	const inputFileRef = useRef<HTMLInputElement>(null);

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const clear = () => {
		if (inputFileRef.current) {
			inputFileRef.current.type = '';
			inputFileRef.current.type = 'file';
			inputFileRef.current.value = '';
		}
	};

	const uploadPic = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			checkImageSize(e.target.files[0]).then((object: any) => {
				(async () => {
					if (
						object.height >= objectMinSize.height &&
						object.width >= objectMinSize.width &&
						e.target.files
					) {
						if (e.target.files[0].size < size) {
							if (isSquare) {
								if (object.width / object.height === 1) {
									setPic(e.target.files[0]);
									clearAfterLoad && clear();
									return;
								}
							} else {
								setPic(e.target.files[0]);
								clearAfterLoad && clear();
								return;
							}
						}
					}
					showAlert({
						title: translations.imageReqs.imageReqs,
						text: `${translations.imageReqs.imageWeight(
							getFileSize(size)
						)}  ${translations.imageReqs.imageMinSize([
							objectMinSize.width,
							objectMinSize.height,
						])}`,
						buttons: [
							{
								text: translations.alerts.btns.ok,
								type: AlertBtnType.NORMAL,
								onClick: () => {
									hideAlert();
								},
							},
						],
					});
					if (inputFileRef.current) {
						inputFileRef.current.type = '';
						inputFileRef.current.type = 'file';
						inputFileRef.current.value = '';
					}

					setPic(null);
				})();
			});
		}
	};

	return (
		<div className='upload-image'>
			{!pic ? (
				<label className='btn btn-outline-dark' htmlFor={id}>
					{translations.btns.chooseFile}
					<input
						id={id}
						className='settings__namedPics-inputfile'
						type='file'
						ref={inputFileRef}
						onChange={uploadPic}
						accept={acceptedFileTypes}
						required={required}
					/>
				</label>
			) : (
				<div className='upload-image__preview'>
					<img src={pic} className='upload-image__preview-pic image-preview--lg' alt='' />
					<Button
						variant='danger'
						size='sm'
						className='upload-image__delete'
						onClick={() => setPic(null)}>
						<BsTrashFill />
					</Button>
				</div>
			)}
		</div>
	);
};

export default observer(uploadImage);

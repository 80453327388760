/* eslint-disable @typescript-eslint/ban-ts-comment */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import ResizableTable from 'components/resizableTable/resizableTable';
import useL10n from 'l10n/useL10n';
import {Poll} from 'models/poll';
import {filter} from 'models/filter';
import pollServices from 'store/pollServices';

import {ReactComponent as IcoArrow} from 'assets/svg/ico-arrow.svg';
import classNames from 'classnames';

import PollsTableItem from './PollsTableItem';

interface IPollsTable {
	tableCurrentWidths: number[];
	setOffset: (value: number) => void;
	setSortBy: (value: string) => void;
	getPollsWithServices: (
		limit?: number,
		currentOffset?: number,
		order?: string,
		filters?: filter
	) => void;
	limit: number;
	searchFilter: filter;
}
const PollsTable: FunctionComponent<IPollsTable> = function PollsTable({
	tableCurrentWidths,
	setOffset,
	setSortBy,
	getPollsWithServices,
	limit,
	searchFilter,
}) {
	const translations = useL10n();

	const theadRef = useRef<HTMLTableRowElement>(null);

	const {polls} = useLocalObservable(() => pollServices);

	const headItems = useRef([
		{
			title: 'title',
		},
		{
			title: 'options',
		},
		{
			title: 'votesLength',
			sortBy: 'votesLength',
			value: 'votesLength_asc',
		},
		{
			title: 'status',
			sortBy: 'status',
			value: 'status_asc',
		},
		{
			title: 'room',
		},
		{
			title: 'startTime',
			sortBy: 'createdAt',
			value: 'createdAt_asc',
		},
		{
			title: 'endTime',
			sortBy: 'endTime',
			value: 'endTime_asc',
		},
	]);

	const onResize = () => {
		if (theadRef.current) {
			const currentWidths: number[] = [];
			theadRef.current?.childNodes.forEach((el: any, index: number) => {
				currentWidths[index] = Number(
					window.getComputedStyle(el).width.replace(/px/, '')
				).valueOf();
			});
			localStorage.setItem('tableCurrentWidths', JSON.stringify(currentWidths));
		}
	};

	const getHeadItemsValue = (sortBy: string) => {
		return headItems.current.find(el => el.sortBy === sortBy)?.value;
	};

	const clearHeadItemValues = (sortBy: string) => {
		headItems.current = headItems.current.map((el: any) => {
			if (el.sortBy && el.sortBy !== sortBy)
				return {
					...el,
					value: el.value.includes('desc') ? el.value.replace(/desc/, 'asc') : el.value,
				};
			return el;
		});
	};

	const updateHeadItemValue = (sortBy: string, newValue: string) => {
		headItems.current = headItems.current.map((el: any) => {
			if (el.sortBy && el.sortBy === sortBy) {
				return {...el, value: newValue};
			}
			return el;
		});
	};

	const onSortingClickHandler = (sortBy: string) => {
		const findValue = getHeadItemsValue(sortBy);
		clearHeadItemValues(sortBy);
		const newValue = findValue?.includes('asc')
			? findValue?.replace(/asc/, 'desc')
			: findValue?.replace(/desc/, 'asc');

		if (newValue) {
			localStorage.setItem('pollSortBy', newValue);
			updateHeadItemValue(sortBy, newValue);
			setSortBy(newValue);
			setOffset(0);
			getPollsWithServices(limit, 0, newValue, searchFilter);
		}
	};

	const sortingBtnClasses = (sortBy: string) =>
		classNames('polls-table__sorting-btn', {
			'polls-table__sorting-btn--asc': getHeadItemsValue(sortBy)?.split('_')[1] === 'asc',
		});

	const renderPoll = useCallback(
		(item: Poll, index: number) => {
			return <PollsTableItem item={item} index={index} key={item.id} />;
		},
		[polls]
	);

	const renderTh = useCallback(
		(item: any, index: number) => {
			return !item.sortBy ? (
				<th className='align-middle text-truncate' key={index}>
					{/* @ts-ignore-start */}
					<small className='text-secondary text-uppercase'>{translations.polls[item.title]}</small>
					{/* @ts-ignore-end */}
				</th>
			) : (
				<th className='align-middle' key={index}>
					<button
						type='button'
						className={sortingBtnClasses(item.sortBy)}
						onClick={() => onSortingClickHandler(item.sortBy)}>
						{/* @ts-ignore-start */}
						<small className='text-secondary text-truncate'>{translations.polls[item.title]}</small>
						{/* @ts-ignore-end */}
						<IcoArrow />
					</button>
				</th>
			);
		},
		[headItems]
	);

	useEffect(() => {
		const usedSortingValue = localStorage.getItem('pollSortBy');
		if (usedSortingValue) {
			updateHeadItemValue(usedSortingValue.split('_')[0], usedSortingValue);
		} else updateHeadItemValue('createdAt', 'createdAt_desc');
	}, []);

	return (
		<div className='block polls-table'>
			<ResizableTable
				variant='light'
				resizable
				resizeOptions={{
					minWidth: 120,
					onResize,
					widths: tableCurrentWidths,
					serialize: false,
				}}>
				<thead>
					<tr ref={theadRef}>
						{headItems.current.map(renderTh)}
						<th className='text-center'> </th>
					</tr>
				</thead>
				<tbody>{polls.map((elem, index) => renderPoll(elem, index))}</tbody>
			</ResizableTable>
		</div>
	);
};

export default observer(PollsTable);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';

import {Button, Modal, Image, Carousel} from 'react-bootstrap';
import {ReactComponent as IcoClose} from 'assets/svg/ico-close-black.svg';

import '../modal.scss';
import './imageGallery.scss';

import modalServices from 'store/modalServices';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs';
import useChatMessage from 'hooks/useChatMessage';

const imageGalleryModal: FunctionComponent = function imageGalleryModal() {
	const {appTheme} = useLocalObservable(() => appService);
	const {
		imageGalleryModalVisible,
		imageGalleryFiles,
		imageGalleryMessageId,
		setImageGalleryFiles,
		toggleImageGalleryModalVisible,
	} = useLocalObservable(() => modalServices);
	const translations = useL10n();
	const {changePicVisible} = useChatMessage();

	const onModalClose = () => {
		toggleImageGalleryModalVisible(false);
	};

	const setPicVisibility = (index: number) => {
		const updatedPic = imageGalleryFiles.map((el, elIndex) => {
			if (elIndex === index) {
				return {...el, isVisible: !el.isVisible};
			}
			return el;
		});
		setImageGalleryFiles(updatedPic);

		if (imageGalleryMessageId)
			changePicVisible(imageGalleryMessageId, {picData: JSON.stringify(updatedPic)});
	};

	return (
		<Modal
			show={imageGalleryModalVisible}
			onHide={onModalClose}
			className='modal modal--image-preview'
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Body>
				<div className='image-gallery__close'>
					<button type='button' onClick={() => onModalClose()}>
						<IcoClose className='ico-medium' />
					</button>
				</div>
				<Carousel slide={false}>
					{imageGalleryFiles.map((el, index) => {
						return (
							<Carousel.Item key={index}>
								<Image src={el.url} />
								<div className='image-gallery__panel'>
									<Button
										variant={appTheme?.toLowerCase()}
										onClick={() => setPicVisibility(index)}
										className={el.isVisible ? 'text-danger' : 'text-success'}>
										<span>
											{!el.isVisible ? translations.btns.unblock : translations.btns.block}
										</span>
										&nbsp;
										{el.isVisible && <BsFillEyeSlashFill className='text-danger' />}
										{!el.isVisible && <BsFillEyeFill className='text-success' />}
									</Button>
								</div>
							</Carousel.Item>
						);
					})}
				</Carousel>
			</Modal.Body>
		</Modal>
	);
};

export default observer(imageGalleryModal);

import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import axios from 'axios';
import {useLocalObservable} from 'mobx-react-lite';
import configService from 'store/configService';
import App from 'components/app';
import AdminProxyService from 'services/api/AdminProxyService';
import {findUrlValueByKey} from 'utils/helpers';

const Root = function Root() {
	const [dataLoaded, setDataLoaded] = useState(false);
	const {setServiceUrls} = useLocalObservable(() => configService);

	const getConfig = async () => {
		await axios
			.get(`${window.location.origin}/secrets/settings.json`)
			.then(response => {
				const {data} = response;
				// setDataLoaded(true);
				const adminProxyURL = findUrlValueByKey(data, 'REACT_APP_PROXY_URL');
				AdminProxyService.setBaseUrl(adminProxyURL);
				setServiceUrls(data);
			})
			.catch(error => {
				console.error('Error fetching Doppler variables:', error);
			});
	};

	useEffect(() => {
		const fetchData = async () => {
			await getConfig();
			setDataLoaded(true);
		};

		fetchData();
	}, []);

	return (
		<BrowserRouter>
			<DndProvider backend={HTML5Backend}>{dataLoaded && <App />}</DndProvider>
		</BrowserRouter>
	);
};

ReactDOM.render(<Root />, document.getElementById('root'));

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Button,
	Form,
	Alert,
	Tabs,
	Tab,
	FloatingLabel,
	Spinner,
} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import useProjects from 'hooks/useProjects';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import useAdminProxy from 'hooks/useAdminProxy';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

const Project: FunctionComponent = function Project() {
	const translations = useL10n();

	const [projectName, setProjectName] = useState('');
	const [validated, setValidated] = useState(false);
	const [validatedSettings, setValidatedSettings] = useState(false);
	const [apikey, setApikey] = useState('');
	const [key, setKey] = useState('new');
	const [error, setError] = useState('');
	const [settings, setSettings] = useState({
		dopplerAccessToken: '',
		gitlabAccessToken: '',
	});
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {addToast} = useLocalObservable(() => toastServices);
	const {adminData} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const {createProject, getSettings, saveSettings} = useProjects();
	const {getAdmin} = useAdminProxy();

	const clear = () => {
		setProjectName('');
		setValidated(false);
	};

	const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		value = value.replace(/[^a-zA-Z0-9- ]/i, '');
		setProjectName(value.trim() ? value : value.trim());
	};

	const onChangeInputSettingsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		const valueTrim = value.trim();
		setSettings({
			...settings,
			[event.target.name]: valueTrim,
		});
	};

	const onClickNewProjectHandler = () => {
		setApikey('');
	};

	const create = async () => {
		setVisiblePreloader(true);
		const response = await createProject(projectName);
		if (response.status === ResponseStatus.SUCCESS) {
			setApikey(response.data.apiKey);
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			clear();
			setVisiblePreloader(false);
			await getAdmin(adminData.adminId);
			return;
		}
		setError(response.data);
		addToast({
			title: translations.toasts.errorTitle,
			text: translations.toasts.error,
			variant: 'danger',
		});
		setVisiblePreloader(false);
	};

	const save = async () => {
		setVisiblePreloader(true);
		const response = await saveSettings(settings);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			setVisiblePreloader(false);
			return;
		}
		addToast({
			title: translations.toasts.errorTitle,
			text: translations.toasts.error,
			variant: 'danger',
		});
		setVisiblePreloader(false);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		setValidated(true);
		if (form.checkValidity() === false) setValidated(true);
		else create();
	};

	const handleSubmitSettings = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		setValidatedSettings(true);
		if (form.checkValidity() === false) setValidatedSettings(true);
		else save();
	};

	const getSettingsWithServices = async () => {
		const response = await getSettings();
		if (response.status === ResponseStatus.SUCCESS)
			setSettings({
				dopplerAccessToken: response.data.dopplerAccessToken,
				gitlabAccessToken: response.data.gitlabAccessToken,
			});
	};
	useEffect(() => {
		getSettingsWithServices();
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row className='align-items-center'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.projects}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.createProject}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.createProject}</p>
				</Col>
			</Row>
			<Tabs
				activeKey={key}
				onSelect={(k: any) => setKey(k)}
				defaultActiveKey='new'
				id='uncontrolled-tab-example'
				className='mb-3'>
				<Tab eventKey='new' title={translations.projects.new} tabClassName='text-secondary'>
					<div className='block w-50'>
						{!apikey && (
							<div className='mb-3'>
								<p>
									<b>{translations.projects.new}</b>
								</p>
								<Form noValidate validated={validated} onSubmit={handleSubmit}>
									<FloatingLabel controlId='floatingSelect2' label={translations.projects.name}>
										<Form.Control
											className='mb-3'
											value={projectName}
											placeholder={translations.projects.name}
											onChange={onChangeInputHandler}
											required
										/>
									</FloatingLabel>

									{visiblePreloader && (
										<div>
											<Spinner
												animation='border'
												variant={appTheme === Theme.DARK ? 'light' : 'dark'}
											/>
										</div>
									)}
									{!visiblePreloader && <Button type='submit'>{translations.btns.save}</Button>}
									{error && (
										<Alert variant='danger' className='mt-3 mb-3'>
											{error}
										</Alert>
									)}
								</Form>
							</div>
						)}
						{apikey && (
							<div>
								<p>
									<b>{translations.projects.created}</b>
								</p>
								<Alert className='mt-3 mb-3'>apikey: {apikey}</Alert>
								<Button type='button' onClick={onClickNewProjectHandler}>
									{translations.projects.btn.new}
								</Button>
							</div>
						)}
					</div>
				</Tab>

				<Tab
					eventKey='settings'
					title={translations.projects.settings}
					tabClassName='text-secondary'>
					<div className='block w-50'>
						<div className='mb-3'>
							<p>
								<b>{translations.projects.settings}</b>
							</p>
							<Form noValidate validated={validatedSettings} onSubmit={handleSubmitSettings}>
								<FloatingLabel controlId='floatingSelect1' label='dopplerAccessToken'>
									<Form.Control
										className='mb-3'
										value={settings.dopplerAccessToken}
										onChange={onChangeInputSettingsHandler}
										name='dopplerAccessToken'
										required
									/>
								</FloatingLabel>

								<FloatingLabel controlId='floatingSelect2' label='gitlabAccessToken'>
									<Form.Control
										className='mb-3'
										value={settings.gitlabAccessToken}
										onChange={onChangeInputSettingsHandler}
										name='gitlabAccessToken'
										required
									/>
								</FloatingLabel>

								{visiblePreloader && (
									<div>
										<Spinner
											animation='border'
											variant={appTheme === Theme.DARK ? 'light' : 'dark'}
										/>
									</div>
								)}

								{!visiblePreloader && <Button type='submit'>{translations.btns.save}</Button>}
							</Form>
						</div>
					</div>
				</Tab>
			</Tabs>
		</Container>
	);
};

export default observer(Project);

import {observer} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Container} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import '../auth.scss';
import classNames from 'classnames';
import StepStart from './steps/StepStart';
import StepPersonalInfo from './steps/StepPersonalInfo';
import StepPassword from './steps/StepPassword';
import StepCompany from './steps/StepCompany';
import RegisterFooter from './footer/RegisterFooter';

const Register: FunctionComponent = function Register() {
	const {auth} = useL10n();

	const [currentStep, setCurrentStep] = useState(0);

	const steps = Object.entries(auth.steps).map(([key, value]) => {
		return {
			key,
			...value,
		};
	});

	const stepClasses = (index: number) =>
		classNames('auth__steps-item', {
			'auth__steps-item--active': currentStep >= index,
		});

	return (
		<section className='auth'>
			<Container className='mt-auto'>
				<p className='h3 mb-5 text-center'>{auth.createAccount}</p>
				<div className='auth__start'>
					<ul className='auth__steps'>
						{steps.map((el, index) => {
							return (
								el.title && (
									<li className={stepClasses(index)} key={index}>
										{el.title}
									</li>
								)
							);
						})}
					</ul>

					<div className='auth__form'>
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<p className='auth__form-title'>{steps[currentStep].form?.title}</p>
						{currentStep === 0 && <StepStart setCurrentStep={setCurrentStep} />}
						{currentStep === 1 && <StepPersonalInfo setCurrentStep={setCurrentStep} />}
						{currentStep === 2 && <StepPassword setCurrentStep={setCurrentStep} />}
						{currentStep === 3 && <StepCompany setCurrentStep={setCurrentStep} />}
					</div>
				</div>
			</Container>
			<RegisterFooter />
		</section>
	);
};

export default observer(Register);

import {PollType} from 'models/enums/Poll.enum';
import modalServices from 'store/modalServices';
import pollServices from 'store/pollServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {Modal, Button} from 'react-bootstrap';
import './pollModal.scss';
import {PollOptions} from 'components/poll/pollOptions';

const PollModal: FunctionComponent = function PollModal() {
	const translations = useL10n();
	const {pollModalVisible, togglePollModalVisible} = useLocalObservable(() => modalServices);
	const {pollPreview, setPollPreview} = useLocalObservable(() => pollServices);

	const getSupTitle = () => {
		switch (pollPreview?.type) {
			case PollType.QUIZ:
				return pollPreview?.isMultiple
					? translations.poll.supTitles.typeQuizMultiple
					: translations.poll.supTitles.typeQuiz;
			case PollType.POLL:
				return pollPreview?.isMultiple
					? translations.poll.supTitles.typePollMultiple
					: translations.poll.supTitles.typePoll;
			default:
				return translations.poll.supTitles.default;
		}
	};

	const onModalClose = () => {
		togglePollModalVisible(false);
		setPollPreview(null);
	};

	return (
		<Modal
			show={pollModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton className='d-flex justify-content-end'>
				{' '}
			</Modal.Header>

			<Modal.Body>
				<div className='modal__body'>
					<div className='poll-modal__axis-y'>
						<div className='poll-modal__container'>
							<div className='poll-modal__top'>
								<div className='poll-modal__head'>
									<div className='poll-modal__suptitle'>{getSupTitle()}</div>
									<div className='poll-modal__title'>{pollPreview?.text}</div>
								</div>
								<div className='poll-modal__content'>
									<PollOptions />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default observer(PollModal);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Modal, Form, FloatingLabel, Button, InputGroup} from 'react-bootstrap';

import {BsFillEyeSlashFill, BsFillEyeFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import useAdminProxy from 'hooks/useAdminProxy';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import classNames from 'classnames';
import ResponseMessage from 'models/enums/ResponseMessage.enum';

const ChangePassword: FunctionComponent = function ChangePassword() {
	const translations = useL10n();
	const [oldPassword, setOldPassword] = useState('');
	const [password, setPassword] = useState('');
	const [validated, setValidated] = useState(false);
	const [isShownPassword, setIsShownPassword] = useState(false);
	const [isShownNewPassword, setIsShownNewPassword] = useState(false);
	const [isError, setIsError] = useState(false);
	const {addToast} = useLocalObservable(() => toastServices);
	const {changePasswordModalVisible, toggleChangePasswordModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {updatePassword} = useAdminProxy();

	const clear = () => {
		setPassword('');
		setOldPassword('');
		setValidated(false);
		setIsShownNewPassword(false);
		setIsShownPassword(false);
		toggleChangePasswordModalVisible(false);
		setIsError(false);
	};
	const onModalClose = () => {
		clear();
		toggleChangePasswordModalVisible(false);
	};

	const onChangePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setOldPassword(value);
		setIsError(false);
	};

	const onChangeNewPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setPassword(value);
		setIsError(false);
	};

	const onShowPasswordHandler = () => {
		setIsShownPassword(!isShownPassword);
	};

	const onShowNewPasswordHandler = () => {
		setIsShownNewPassword(!isShownNewPassword);
	};

	const formControlClasses = classNames('', {
		'is-error': isError,
	});

	const updatePasswordWithService = async () => {
		const response = await updatePassword(oldPassword, password);
		if (response.status === ResponseStatus.SUCCESS) {
			onModalClose();
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		if (response.data === ResponseMessage.PASSWORDS_DONT_MATCH) {
			setIsError(true);
			setValidated(false);
			return;
		}
		addToast({
			title: response.data,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else await updatePasswordWithService();
	};

	return (
		<Modal
			className='modal--changePassword'
			show={changePasswordModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title> {translations.profileSettings.changePassword}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className='mb-3' controlId='changePassword'>
						<InputGroup>
							<FloatingLabel
								controlId='oldPassword'
								label={translations.profileSettings.currentPassword}>
								<Form.Control
									type={isShownPassword ? 'text' : 'password'}
									placeholder={translations.profileSettings.currentPassword}
									name='oldPassword'
									required
									value={oldPassword}
									onChange={onChangePasswordHandler}
									className={formControlClasses}
								/>
								<Form.Control.Feedback type='invalid' tooltip>
									{translations.modals.createModer.passwordWarn}
								</Form.Control.Feedback>
							</FloatingLabel>
							<InputGroup.Text>
								<button type='button' className='d-flex' onClick={onShowPasswordHandler}>
									{!isShownPassword && <BsFillEyeSlashFill className='h3 mb-0' />}
									{isShownPassword && <BsFillEyeFill className='h3 mb-0' />}
								</button>
							</InputGroup.Text>
						</InputGroup>
					</Form.Group>
					<Form.Group className='mb-3' controlId='changePassword'>
						<InputGroup>
							<FloatingLabel
								controlId='newPassword'
								label={translations.profileSettings.newPassword}>
								<Form.Control
									type={isShownNewPassword ? 'text' : 'password'}
									placeholder={translations.profileSettings.newPassword}
									name='password'
									required
									value={password}
									onChange={onChangeNewPasswordHandler}
									className={formControlClasses}
								/>
								<Form.Control.Feedback type='invalid' tooltip>
									{translations.modals.createModer.passwordWarn}
								</Form.Control.Feedback>
							</FloatingLabel>
							<InputGroup.Text>
								<button type='button' className='d-flex' onClick={onShowNewPasswordHandler}>
									{!isShownNewPassword && <BsFillEyeSlashFill className='h3 mb-0' />}
									{isShownNewPassword && <BsFillEyeFill className='h3 mb-0' />}
								</button>
							</InputGroup.Text>
						</InputGroup>
					</Form.Group>
					{isError && <p className='text-danger'>{translations.profileSettings.passwordWarn}</p>}
					<Button type='submit' variant='dark'>
						{translations.btns.save}
					</Button>
					&nbsp;
					<Button type='button' variant='outline-secondary' onClick={onModalClose}>
						{translations.btns.cancel}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default observer(ChangePassword);

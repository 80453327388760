import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import update from 'immutability-helper';
import classNames from 'classnames';
import {v4 as uuidv4} from 'uuid';

import useL10n from 'l10n/useL10n';

import {Button} from 'react-bootstrap';

import {PollCreatedOption} from 'models/poll';
import {PollType} from 'models/enums/Poll.enum';
import {Project} from 'models/enums/Project.enum';

import pollService from 'store/pollServices';
import appService from 'store/appService';

import PollAnswerElement from './PollAnswerElement';
import './pollAnswers.scss';

const basePollCreatedOption = {
	text: '',
	pic: null,
	isRight: false,
};

const PollAnswers: FunctionComponent = function PollAnswers() {
	const translations = useL10n();
	const {projectId} = useLocalObservable(() => appService);
	const project = projectId.split('-')[0];

	const {
		pollWithPics,
		currentPoll,
		updateCurrentPoll,
		isFieldsEmpty,
		setIsNotValidFields,
		setIsFieldsEmpty,
	} = useLocalObservable(() => pollService);
	const [elements, setElements] = useState(
		currentPoll?.options ? currentPoll.options : [{...basePollCreatedOption, id: uuidv4()}]
	);

	const pollAnswersListClasses = classNames('poll-answers__list', {
		'poll-answers__list--hide-button': elements.length <= 4 && isFieldsEmpty,
	});

	const addNewFreshElement = () => {
		setElements([...elements, {...basePollCreatedOption, id: uuidv4()}]);
		setIsNotValidFields(true);
	};

	const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
		setElements((prevCards: PollCreatedOption[]) => {
			return update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex] as PollCreatedOption],
				],
			});
		});
	}, []);

	const changeOptionData = (id: string | number, data: any, isRadio = false) => {
		setElements((prevCards: PollCreatedOption[]) => {
			return prevCards.map(el => {
				if (el.id === id) {
					return {...el, ...data};
				}
				if (isRadio) {
					return {...el, isRight: false};
				}
				return el;
			});
		});
	};

	const blurHandler = (e: React.FocusEvent<HTMLInputElement>, id: string) => {
		if (e.target.value === '' && elements.length > 1) {
			const newArray: PollCreatedOption[] = elements.filter(
				(el: PollCreatedOption) => el.id !== id
			);
			setElements(newArray);
		}
	};

	const checkElemsCount = () => {
		return project === Project.MATCHTV ? elements.length <= 9 : elements.length <= 4;
	};

	const renderElements = useCallback(
		(card: PollCreatedOption, index: number) => {
			return (
				<PollAnswerElement
					key={card.id}
					index={index}
					id={card.id}
					text={card.text}
					img={card.pic}
					isRight={card.isRight}
					moveCard={moveCard}
					changeData={changeOptionData}
					removeElementOnBlur={blurHandler}
				/>
			);
		},
		[elements]
	);

	useEffect(() => {
		setIsFieldsEmpty(
			elements.some((elem: PollCreatedOption) => {
				if (pollWithPics.value) {
					return elem.pic === null || elem.text.trim().length === 0;
				}
				return elem.text.trim().length === 0;
			})
		);

		if (elements.length <= 1) {
			setIsNotValidFields(true);
			return;
		}

		if (currentPoll?.type === PollType.QUIZ) {
			setIsNotValidFields(
				!elements.some((elem: PollCreatedOption) => {
					return elem.isRight;
				})
			);
			return;
		}
		setIsNotValidFields(false);
	}, [elements, currentPoll?.type, pollWithPics.value]);

	useEffect(() => {
		updateCurrentPoll({options: elements});
	}, [elements]);

	useEffect(() => {
		if (currentPoll?.options) {
			setElements(currentPoll.options);
		}
	}, [currentPoll]);

	return (
		<div className='poll-answers'>
			<p>{translations.poll.answers.title}</p>
			<div className={pollAnswersListClasses}>
				<div className='poll-answers__list-dnd-container'>
					{elements.map((elem, i) => renderElements(elem, i))}
				</div>

				{checkElemsCount() && !isFieldsEmpty && (
					<div>
						<Button variant='dark' size='sm' onClick={addNewFreshElement}>
							{translations.poll.btns.addAnswer}
						</Button>
					</div>
				)}
			</div>
			<small>{translations.poll.answers.warning(project === Project.MATCHTV ? 10 : 5)}</small>
		</div>
	);
};

export default observer(PollAnswers);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Container, Form, Row, Col, Button, InputGroup, Nav} from 'react-bootstrap';
import {BsArrowLeftCircleFill} from 'react-icons/bs';

import alertServices from 'store/alertServices';
import highlightsServices from 'store/highlightsServices';

import useL10n from 'l10n/useL10n';
import {isArray, isString} from 'lodash';
import {useParams} from 'react-router-dom';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

interface IHighlightRooms {
	nextStep: () => void;
	steps: string[];
}

const HighlightRooms: FunctionComponent<IHighlightRooms> = function HighlightRooms({
	nextStep,
	steps,
}) {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {currentHighlight, currentStep, setCurrentHighlight, setCurrentStep} = useLocalObservable(
		() => highlightsServices
	);
	const {appTheme} = useLocalObservable(() => appService);

	const [externalRoomIds, setExternalRoomIds] = useState<string[] | File>([]);
	const [validated, setValidated] = useState(false);
	const [isManual, setIsManual] = useState(true);
	const [csvData, setCsvData] = useState<any>([]);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		//  = value.trim().split(',');
		const roomIds = value
			.trim()
			.split(',')
			.map(id => {
				return id.trim();
			});
		if (roomIds.length > 100) {
			showAlert({
				title: translations.highlights.rooms,
				text: translations.highlights.maxRooms,
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
			return;
		}
		setExternalRoomIds(roomIds);
		setCurrentHighlight({
			...currentHighlight,
			externalRoomIds: roomIds,
		});
	};

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setCurrentHighlight({
					...currentHighlight,
					externalRoomIds: file,
				});
			}
		}
	};

	const onChangeIManual = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setExternalRoomIds([]);
		setCurrentHighlight({
			...currentHighlight,
			externalRoomIds: [],
		});
		if (inputFileRef.current) inputFileRef.current.value = '';
		setIsManual(!isManual);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else nextStep();
	};

	const prepareStringToCsvFormat = () => {
		let data: any;
		if (typeof externalRoomIds === 'string') {
			data = `${externalRoomIds}`;
			data = data.split(',');
			data = data.map((element: any) => {
				return [element];
			});
		}

		return data;
	};

	const exportToCsv = () => {
		const csvContent = `data:text/csv;charset=utf-8,${csvData
			.map((e: any) => e.join(','))
			.join('\n')}`;

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'Rooms.csv');
		document.body.appendChild(link);

		link.click();
	};

	useEffect(() => {
		if (
			isArray(currentHighlight.externalRoomIds) ||
			typeof currentHighlight.externalRoomIds === 'string'
		) {
			setExternalRoomIds(currentHighlight.externalRoomIds);
			setIsManual(true);
		} else {
			setIsManual(false);
		}
	}, []);

	useEffect(() => {
		slug && typeof externalRoomIds === 'string' && setCsvData(prepareStringToCsvFormat);
	}, [externalRoomIds]);

	return (
		<Container fluid className='highlights__step'>
			<Row>
				<Col md={6}>
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<div className='block'>
							{(isArray(externalRoomIds) || isString(externalRoomIds)) && (
								<Form.Group controlId='highlightRooms' className='mb-5'>
									<p className='h5 mb-4'>{translations.highlights.manualRoomList}</p>
									<Form.Label>{translations.highlights.manual}</Form.Label>

									<InputGroup className='mb-2'>
										<InputGroup.Checkbox
											className='checkbox-black'
											checked={isManual}
											onChange={onChangeIManual}
											disabled={slug !== undefined}
										/>

										<Form.Control
											name='linkText'
											required={isManual}
											disabled={!isManual || slug !== undefined}
											placeholder='Room1, Room2'
											as='textarea'
											value={externalRoomIds}
											onChange={onChangeHandler}
										/>
									</InputGroup>
									{slug && (
										<Nav.Link onClick={exportToCsv}>
											{translations.highlights.downloadFile}
										</Nav.Link>
									)}
								</Form.Group>
							)}

							{!slug && (
								<Form.Group>
									<p className='h5 mb-4'>{translations.highlights.downloadRoomList}</p>
									<Form.Label>{translations.highlights.downloadRoomList}</Form.Label>

									<InputGroup>
										<InputGroup.Checkbox
											className='checkbox-black'
											checked={!isManual}
											onChange={onChangeIManual}
										/>

										<Form.Control
											type='file'
											required={!isManual}
											ref={inputFileRef}
											onChange={uploadFile}
											accept='.csv'
											disabled={isManual}
										/>
									</InputGroup>
								</Form.Group>
							)}
							<div className='d-flex justify-content-end mt-4'>
								{currentStep > 1 && (
									<Button
										variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
										className='d-flex'
										onClick={() => setCurrentStep(currentStep - 1)}>
										<BsArrowLeftCircleFill />
										&nbsp;&nbsp;
										{translations.highlights.prevStep}
									</Button>
								)}
								&nbsp;
								<Button
									variant='success'
									className='d-flex'
									type='submit'
									disabled={currentHighlight.template === null}>
									{!slug ? translations.btns.send : translations.btns.save}
								</Button>
							</div>
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};
export default observer(HighlightRooms);

import {useLocalObservable} from 'mobx-react-lite';
import {Changelog} from 'models/changelog';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import ChangelogService from 'services/api/ChangelogService';
import changelogService from 'store/changelogService';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {setUnviewedChangelogCount, setLastViewedChangelogId} = useLocalObservable(
		() => changelogService
	);

	const getChangelog = async (value: {limit?: number; offset?: number}) => {
		const response = await ChangelogService.getChangelog(accessToken, value.limit, value.offset);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const addChangelog = async (value: Changelog) => {
		const response = await ChangelogService.postChangelog(accessToken, value);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const getChangelogById = async (id: number) => {
		const response = await ChangelogService.getChangelogById(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const updateChangelogById = async (id: number, value: Changelog) => {
		const response = await ChangelogService.patchChangelogById(accessToken, id, value);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const deleteChangelogById = async (id: number) => {
		const response = await ChangelogService.deleteChangelogById(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const getCountUnviewed = async () => {
		const response = await ChangelogService.getCountUnviewed(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.count) setUnviewedChangelogCount(+response.data.count);
			if (response.data.lastViewedChangelogId)
				setLastViewedChangelogId(response.data.lastViewedChangelogId);
		}
	};

	const postViewedChangelogId = async (id: number) => {
		const response = await ChangelogService.postViewedChangelogId(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return true;
		}
		return false;
	};

	const postChangelogSubscribe = async (email: string) => {
		const response = await ChangelogService.postChangelogSubscribe(accessToken, email);
		if (response.status === ResponseStatus.SUCCESS) {
			return true;
		}
		return false;
	};

	const uploadPic = async (file: File) => {
		const response = await ChangelogService.uploadPic(accessToken, file);
		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.pic) return response.data.pic;
		}
		return false;
	};

	return {
		getChangelog,
		addChangelog,
		getChangelogById,
		updateChangelogById,
		deleteChangelogById,
		getCountUnviewed,
		postViewedChangelogId,
		postChangelogSubscribe,
		uploadPic,
	};
};

import {FunctionComponent, useCallback, useRef, useState} from 'react';
import {Badge, Modal, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import modalServices from 'store/modalServices';
import useChatMessage from 'hooks/useChatMessage';
import messagesServices from 'store/messagesServices';
import Moment from 'react-moment';
import ChatMessage from 'components/chat/ChatMessage';

import './reactions.scss';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import {BsDownload} from 'react-icons/bs';
import {debounce} from 'lodash';

const LIMIT = 100;

const ReactionsModal: FunctionComponent = function ReactionsModal() {
	const translations = useL10n();
	const scrollRef = useRef<any>(null);
	const isLoading = useRef<boolean>(false);
	const isFinished = useRef<boolean>(false);
	const currentOffset = useRef<number>(0);
	const messageReactions = useRef<any[]>([]);

	const {reactionsModalVisible, setReactionsModalVisible} = useLocalObservable(() => modalServices);
	const {reactionMessage, setReactionMessage} = useLocalObservable(() => messagesServices);
	const {reactions} = useLocalObservable(() => settingsServices);

	const {getMessageReactions} = useChatMessage();
	const {getEmotions} = useSettings();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [visiblePreloaderBottom, setVisiblePreloaderBottom] = useState(false);

	const checkScrollBottom = async (event: any) => {
		const {scrollTop, scrollHeight} = event.target;

		if (
			reactionMessage &&
			scrollRef.current &&
			(Math.round(scrollRef.current.offsetHeight + scrollTop) === Math.abs(scrollHeight) ||
				Math.ceil(scrollRef.current.offsetHeight + scrollTop) === Math.abs(scrollHeight) ||
				Math.floor(scrollRef.current.offsetHeight + scrollTop) === Math.abs(scrollHeight))
		) {
			if (!isLoading.current && !isFinished.current) {
				isLoading.current = true;
				setVisiblePreloaderBottom(true);
				scrollRef.current.scroll({top: scrollRef.current.scrollHeight});

				const response = await getMessageReactions({
					messageId: reactionMessage.id,
					limit: LIMIT,
					offset: currentOffset.current + LIMIT,
				});
				if (response) {
					if (response.reactions.length < LIMIT) {
						isFinished.current = true;
					}

					currentOffset.current += LIMIT;
					messageReactions.current = [...messageReactions.current, ...response.reactions];

					setVisiblePreloaderBottom(false);

					setTimeout(() => {
						isLoading.current = false;
					}, 1000);
				}
			}
		}
	};

	const onScrollHandler = useCallback(
		debounce(scrollEvent => checkScrollBottom(scrollEvent), 50),
		[messageReactions, visiblePreloader, visiblePreloaderBottom, reactionMessage, currentOffset]
	);

	const onShowHandler = async () => {
		await getEmotions();
		if (reactionMessage) {
			setVisiblePreloader(true);
			const response = await getMessageReactions({messageId: reactionMessage.id, limit: LIMIT});
			if (response) {
				messageReactions.current = response.reactions;
			}
			setVisiblePreloader(false);
		}
		const element = document.querySelector('.modal--reactions');
		scrollRef.current = element;
		scrollRef.current?.addEventListener('scroll', onScrollHandler);
	};

	const onHideHandler = () => {
		setReactionsModalVisible(false);
		messageReactions.current = [];
		setReactionMessage(null);
	};

	const saveTextAsCSV = (textData: string) => {
		const csvContent = `data:text/csv;charset=utf-8,${textData}`;
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'data.csv');
		document.body.appendChild(link);
		link.click();
	};

	const downloadCsvClick = async () => {
		let response = null;
		if (reactionMessage) {
			response = await getMessageReactions({
				messageId: reactionMessage.id,
				limit: LIMIT,
				csv: true,
			});
			saveTextAsCSV(response);
		}
	};

	return (
		<Modal
			className='modal--reactions'
			show={reactionsModalVisible}
			onShow={onShowHandler}
			onHide={onHideHandler}
			animation={false}
			restoreFocus={false}>
			<div className='modal-head'>
				<Modal.Header closeButton>
					<Modal.Title>
						<p className='h3 mb-0'>{translations.reactions.title}</p>
					</Modal.Title>
				</Modal.Header>
			</div>

			<Modal.Body>
				{visiblePreloader ? (
					<div className='text-center mt-4'>
						<Spinner animation='border' />
					</div>
				) : (
					<div className='reactions'>
						{reactionMessage && (
							<div>
								<ChatMessage
									message={reactionMessage}
									visibleMessageSubmenu={false}
									visibleAvatarSubmenu={false}
									visibleAvatar
									visibleDate={false}
									visibleHeader
									visibleNewMessagesLabel={false}
									pics={reactionMessage.picData}
									isVisibilityButtons={false}
									renderTime={false}
									reactions={messageReactions.current.map((el: any) => {
										return {...el, pic: reactions.find(e => e.name === el.emotion)?.pic};
									})}
								/>
							</div>
						)}
						{messageReactions.current?.length > 0 ? (
							messageReactions.current?.map((el: any) => {
								const findReactionData = reactions.find(e => e.name === el.emotion);

								return (
									<div className='reactions__item' key={el.id}>
										<img className='reactions__item-pic' src={el.talker?.user?.pic} alt='' />
										<p className='reactions__item-name'>{el.talker?.user?.name}</p>
										<div className='reactions__item-badge'>
											<Badge bg='light' pill>
												ext:{el.talker?.user?.externalId}
											</Badge>
										</div>

										<small className='text-muted reactions__item-date'>
											<Moment format='DD.MM.YY HH:mm'>{el.createdAt}</Moment>
										</small>
										<div className='reactions__item-reaction'>
											<img src={findReactionData?.pic} alt='' />
										</div>
									</div>
								);
							})
						) : (
							<p className='reactions__empty-text text-muted text-center'>
								{translations.reactions.noReations}
							</p>
						)}
						{visiblePreloaderBottom && (
							<div className='text-center'>
								<Spinner animation='border' />
							</div>
						)}
					</div>
				)}
			</Modal.Body>
			<div className='modal__footer'>
				<button type='button' className='text-info' onClick={downloadCsvClick}>
					<BsDownload />
					&nbsp;
					{translations.btns.downloadCsv}
				</button>
			</div>
		</Modal>
	);
};

export default observer(ReactionsModal);

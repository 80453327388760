import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';

import UserService from 'services/api/UserService';

export default () => {
	const {accessToken, setAdminData} = useLocalObservable(() => userServices);

	const getSelf = async () => {
		const response = await UserService.getSelf(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setAdminData(response.data);
		}
	};

	const uploadAdminPic = async (pic: File) => {
		const response = await UserService.updateAdminPic(accessToken, pic);
		return response;
	};

	return {
		getSelf,

		uploadAdminPic,
	};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Badge,
	Image,
	Alert,
	Spinner,
	Form,
	OverlayTrigger,
	Tooltip,
	Navbar,
	Button,
} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import userServices from 'store/userServices';

import ReportService from 'services/api/ReportService';
import AdminProxyService from 'services/api/AdminProxyService';

import {Report} from 'models/reports';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import useL10n from 'l10n/useL10n';
import useReportReason from 'hooks/useReportReason';
import {LIMIT} from 'constants/constants';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import PageTitle from 'components/pageTitle/PageTitle';

const ReportsArchive: FunctionComponent = function ReportsArchive() {
	const {accessToken, admins, setAdmins} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [archivedReports, setArchivedReports] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [offset, setOffset] = useState(0);
	const [sortBy, setSortBy] = useState('createdAt_desc');

	const translations = useL10n();
	const {getReason} = useReportReason();

	const getArchivedReports = async (limit?: number, currentOffset?: number, order?: string) => {
		setVisiblePreloader(true);
		const response = await ReportService.getArchivedReports(
			accessToken,
			limit || LIMIT,
			currentOffset || 0,
			order || 'createdAt_desc'
		);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) setArchivedReports(response.data.reports);
	};

	const getAdmins = async () => {
		setVisiblePreloader(true);
		const response = await AdminProxyService.getAdmins(accessToken);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) setAdmins(response.data);
	};

	const getSolver = (solverId: number) => {
		return admins.find(el => el.userId === solverId);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getArchivedReports(LIMIT, offset - LIMIT, sortBy);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getArchivedReports(LIMIT, offset + LIMIT, sortBy);
	};

	const onSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSortBy(value);
		setOffset(0);

		getArchivedReports(LIMIT, 0, value);
	};

	useEffect(() => {
		if (accessToken) {
			getArchivedReports();
			getAdmins();
		}
	}, [accessToken]);

	const renderReport = useCallback(
		(item: Report, index: number) => {
			return (
				<tr key={index}>
					<td>
						<div className='user-info'>
							<div className='user-info__photo background-color-3'>
								<Image src={item.initiator?.pic} roundedCircle fluid />
							</div>
							<div className='text-truncate'>
								<p className='d-inline'>{item.initiator?.name}</p>
							</div>
						</div>
					</td>
					<td>
						<div className='user-info'>
							<div className='user-info__photo'>
								<Image src={item.target.pic} roundedCircle fluid />
							</div>
							<div className='text-truncate'>
								<p className='d-inline'>{item.target.name}</p>
							</div>
						</div>
					</td>
					<td className='text-center align-middle'>
						<small>
							{getReason(item.reason) ? (
								<Alert variant='warning'>{getReason(item.reason)?.text}</Alert>
							) : (
								<Alert variant='warning'>{item.reason}</Alert>
							)}
						</small>
					</td>
					{item.message?.text ? (
						<td className='text-truncate'>
							<Alert key='warning' variant='secondary'>
								<p className='text-wrap text-truncate'>{item.message.text}</p>
							</Alert>
						</td>
					) : (
						<td>
							<Alert key='warning' variant='warning'>
								-
							</Alert>
						</td>
					)}
					<td className='text-center align-middle'>
						{item.solverId === null && <small>{translations.table.autoReviewed}</small>}
						{item.solverId && getSolver(item.solverId) && (
							<div className='text-truncate'>
								{getSolver(item.solverId) && (
									<Badge bg='light' pill>
										id{item.solverId}
									</Badge>
								)}
								<div className='text-truncate'>
									{getSolver(item.solverId) && <span>{getSolver(item.solverId).user.name}</span>}
								</div>
								{getSolver(item.solverId) && (
									<Badge bg='info' pill>
										{getSolver(item.solverId).role}
									</Badge>
								)}
							</div>
						)}
					</td>
				</tr>
			);
		},
		[archivedReports]
	);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.reports.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.archivedReports.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.archivedReports.title}
						text={translations.sidebar.archivedReports.descr}
					/>

					<Navbar className='d-flex w-100 p-0 mb-2'>
						<div className=' w-100 d-flex align-items-center justify-content-between'>
							<div className='filters__btns'>
								<Button
									variant='dark'
									className='d-flex'
									onClick={onPrevPageBtnClickHandler}
									disabled={(!archivedReports.length && offset === 0) || offset === 0}>
									<BsArrowLeftCircleFill />
								</Button>
								&nbsp;
								<Button
									variant='dark'
									className='d-flex'
									onClick={onNextPageBtnClickHandler}
									disabled={!archivedReports.length || archivedReports.length < LIMIT}>
									<BsArrowRightCircleFill />
								</Button>
							</div>
						</div>
					</Navbar>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{archivedReports?.length > 0 ? (
						<Table
							responsive
							striped={appTheme === Theme.LIGHT}
							bordered
							hover
							variant={appTheme?.toLowerCase()}>
							<thead>
								<tr>
									<th>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.accuser}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy}>
												<option value='solverId_desc'>{translations.table.accuser} &darr;</option>
												<option value='solverId_asc'>{translations.table.accuser} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th>
										<b>{translations.table.accused}</b>
									</th>
									<th className='text-center col-2'>
										<b>{translations.table.reason}</b>
									</th>
									<th className='col-3'>
										<b>{translations.table.message}</b>
									</th>
									<th className='text-center col-2'>
										<b>{translations.table.reviewed}</b>
									</th>
								</tr>
							</thead>
							<tbody>{archivedReports.map((elem, index) => renderReport(elem, index))}</tbody>
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.reportsEmpty}</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(ReportsArchive);

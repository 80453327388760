import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import useL10n from 'l10n/useL10n';
import {Form, Button, Image, Alert} from 'react-bootstrap';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';

const Preloader: FunctionComponent = function Preloader() {
	const translations = useL10n();
	const {updateLoader, patchSettings, getDefaultSettings} = useSettings();
	const {settings} = useLocalObservable(() => settingsServices);

	const [fileLoader, setFileLoader] = useState<any>('');
	const [loader, setLoader] = useState<any>('');
	const [isDefaultLoader, setIsDefaultLoader] = useState(false);

	const fileRef = useRef<any>(null);

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setFileLoader(file);
			}
		}
	};

	const onClickSaveHandler = async () => {
		updateLoader(fileLoader);
		setIsDefaultLoader(false);
		setFileLoader('');
		fileRef.current.value = null;
	};

	const onResetCLickHandler = async () => {
		const defaultSettings = await getDefaultSettings();
		if (defaultSettings) {
			patchSettings({
				loader: defaultSettings.loader,
			});
			setIsDefaultLoader(true);
			setFileLoader('');
			fileRef.current.value = null;
		}
	};

	useEffect(() => {
		setLoader(settings.loader);
	}, [settings]);

	return (
		<div className='block w-50'>
			<p className='h5 mb-5'>{translations.settings.loader}</p>
			<Alert variant='secondary' className='mb-3'>
				{loader && <Image src={loader} className='image-preview' fluid />}
			</Alert>

			<div className='mb-4'>
				<Form.Control type='file' onChange={uploadFile} accept='.svg, .gif' ref={fileRef} />
			</div>

			<Button type='button' variant='success' onClick={onClickSaveHandler} disabled={!fileLoader}>
				{translations.btns.save}
			</Button>
			<Button
				type='button'
				onClick={onResetCLickHandler}
				className='mx-2'
				variant='light'
				disabled={isDefaultLoader}>
				{translations.btns.reset}
			</Button>
		</div>
	);
};

export default observer(Preloader);

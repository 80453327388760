import {action, makeObservable, observable} from 'mobx';
import {FlaggedMessage} from 'models/room';

class FlaggedMessageService {
	@observable
	public flaggedMessageCount = 0;

	@observable
	public flaggedMessages: FlaggedMessage[] = [];

	@action
	public setFlaggedMessageCount = (value: number) => {
		this.flaggedMessageCount = value;
	};

	@action
	public setFlaggedMessages = (value: FlaggedMessage[]) => {
		this.flaggedMessages = value;
	};

	@action
	public increaseFlaggedMessageCount = () => {
		this.flaggedMessageCount += 1;
	};

	@action
	public decreaseFlaggedMessageCount = () => {
		this.flaggedMessageCount -= 1;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new FlaggedMessageService();

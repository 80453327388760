import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import authService from 'store/authService';

import SelectControl from 'components/select/SelectControl';
import appService from 'store/appService';
import LanguageTag from 'models/enums/LanguageTag.enum';
import {countries} from 'constants/countries';

interface IStepPersonalInfo {
	setCurrentStep: (step: number) => void;
}

const StepPersonalInfo: FunctionComponent<IStepPersonalInfo> = function StepPersonalInfo(props) {
	const {auth} = useL10n();
	const {personalInfo} = auth.steps;
	const {user, setUser} = useLocalObservable(() => authService);
	const {language} = useLocalObservable(() => appService);

	const [validated, setValidated] = useState(false);
	const [countriesData, setCountriesData] = useState<{english: any; russian: any}>({
		english: [],
		russian: [],
	});
	const [countrySelect, setCountrySelect] = useState({value: '', label: ''});
	const [countriesList, setCountriesList] = useState<any>([]);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		if (!value.trim().length) {
			value = event.target.value.replace(/\s/g, '');
		}

		setUser({...user, [event.target.name]: value});
	};

	const handleSelect = (selectedOption: any) => {
		setCountrySelect(selectedOption);
		setUser({...user, country: selectedOption.value});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		props.setCurrentStep(2);
		window.scrollTo({
			top: 0,
		});
	};

	const backClickHandler = () => {
		props.setCurrentStep(0);
	};

	const getCountries = () => {
		const allCountriesData = {
			english: countries.map((country: any) => country.name.common).sort(),
			russian: countries.map((country: any) => country.translations.rus.common).sort(),
		};

		setCountriesData(allCountriesData);
	};

	useEffect(() => {
		if (!countriesData.english.length) {
			getCountries();
			return;
		}
		if (countriesData.english && countriesData.russian) {
			const countriesByLang =
				language === LanguageTag.en ? countriesData.english : countriesData.russian;
			setCountrySelect({value: countriesByLang[0], label: countriesByLang[0]});
			setUser({...user, country: countriesByLang[0]});
			setCountriesList(
				countriesByLang.map((el: string) => {
					return {value: el, label: el};
				})
			);
		}
	}, [countriesData]);

	return (
		<div className='auth__form-content'>
			<div className='auth__personal-info'>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className='mb-4'>
						<Form.Label>{personalInfo.form.name}</Form.Label>
						<Form.Control
							name='name'
							placeholder={personalInfo.form.namePlaceholder}
							value={user?.name || ''}
							onChange={onChangeHandler}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-4'>
						<Form.Label>{personalInfo.form.company}</Form.Label>
						<Form.Control
							name='company'
							placeholder={personalInfo.form.companyPlaceholder}
							value={user?.company || ''}
							onChange={onChangeHandler}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-4'>
						<Form.Label>{personalInfo.form.companyRole}</Form.Label>
						<Form.Control
							name='companyRole'
							placeholder={personalInfo.form.companyRolePlaceholder}
							value={user?.companyRole || ''}
							onChange={onChangeHandler}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-4'>
						<Form.Label>{personalInfo.form.country}</Form.Label>
						<SelectControl
							handleChange={handleSelect}
							options={countriesList}
							value={countrySelect}
							theme='light'
							placeholder={personalInfo.form.country}
							isSearchable
						/>
					</Form.Group>

					<Button type='submit' variant='dark' size='lg' className='w-100 text-center'>
						{auth.btns.next}
					</Button>
				</Form>

				<div className='auth__form-footer'>
					<button type='button' className='btn-link text-info' onClick={backClickHandler}>
						{auth.btns.back}
					</button>
				</div>
			</div>
		</div>
	);
};

export default observer(StepPersonalInfo);

enum PollStatus {
	ACTIVE = 'ACTIVE',
	ENDED = 'ENDED',
	PLANNED = 'PLANNED',
}

enum PollType {
	POLL = 'POLL',
	QUIZ = 'QUIZ',
}

export {PollStatus, PollType};

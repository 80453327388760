import {observer, useLocalObservable} from 'mobx-react-lite';
import {Talker} from 'models/room';
import {FunctionComponent, useEffect, useState} from 'react';
import {Modal, Badge, InputGroup, Button, Form} from 'react-bootstrap';
// import {Scrollbars} from 'react-custom-scrollbars-2';
import useL10n from 'l10n/useL10n';
import {BsXLg, BsPersonPlusFill} from 'react-icons/bs';
import modalServices from 'store/modalServices';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

import RoomTalkerElem from './RoomTalkerElem';

interface IRoomTalkersModal {
	talkers: Talker[];
	externalRoomId?: string | null;
}
const RoomTalkersModal: FunctionComponent<IRoomTalkersModal> = function RoomTalkersModal({
	talkers,
	externalRoomId,
}) {
	const {roomTalkersModalVisible, hideRoomTalkersModal, toggleAddTalkerModalVisible} =
		useLocalObservable(() => modalServices);
	const {appTheme} = useLocalObservable(() => appService);
	const [sortedTalkers, setSortedTalkers] = useState<Talker[]>([]);
	const [filteredTalkers, setFilteredTalkers] = useState<Talker[]>([]);
	const [search, setSearch] = useState('');

	const translations = useL10n();

	const sortTalkers = () => {
		setSortedTalkers(
			talkers
				.slice()
				.sort((a, b) => Number(b.isModer) - Number(a.isModer))
				// eslint-disable-next-line no-nested-ternary
				.sort((a, b) => (b.role > a.role ? 1 : a.role > b.role ? -1 : 0))
		);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
	};

	const onSearchClickHandler = () => {
		if (search)
			setFilteredTalkers(
				sortedTalkers.filter((el: Talker) =>
					el.user.name.toLowerCase().includes(search.toLowerCase())
				)
			);
	};

	const onOpenAddTalkerModalHandler = () => {
		hideRoomTalkersModal();
		toggleAddTalkerModalVisible(true);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearchClickHandler();
		}
	};

	const clear = () => {
		setSearch('');
		sortTalkers();
	};

	useEffect(() => {
		sortedTalkers.length && setFilteredTalkers(sortedTalkers);
	}, [sortedTalkers]);

	useEffect(() => {
		sortTalkers();
	}, [talkers]);

	return (
		<Modal
			className='modal--talkers'
			show={roomTalkersModalVisible}
			onHide={hideRoomTalkersModal}
			animation={false}
			centered
			restoreFocus={false}>
			<div className='modal-head'>
				<Modal.Header closeButton>
					<Modal.Title>
						<h2 className='mb-0'>{translations.modals.roomTalkers.title}</h2>
					</Modal.Title>
					<Badge pill bg={appTheme.toLowerCase()} className='mx-2 h4'>
						{talkers.length}
					</Badge>
				</Modal.Header>

				<div className='d-flex align-items-unset p-4'>
					<InputGroup>
						<Form.Control
							placeholder={`${translations.search.searchBy} ${translations.search.talker}`}
							aria-label={translations.search.searchBy}
							value={search}
							onChange={onChangeHandler}
							onKeyPress={handleKeyPress}
						/>
						{(search || !filteredTalkers.length) && (
							<Button variant='link' className='modal-clear-btn text-secondary' onClick={clear}>
								<BsXLg />
							</Button>
						)}
						<Button
							variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
							className='px-4'
							onClick={onSearchClickHandler}>
							{translations.btns.search}
						</Button>
					</InputGroup>
					&nbsp;
					<Button
						variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'dark'}
						className='d-flex align-items-center'
						onClick={onOpenAddTalkerModalHandler}>
						<BsPersonPlusFill className='h4 mb-0' />
						&nbsp;
						{translations.btns.add}
					</Button>
				</div>
			</div>

			<Modal.Body>
				<div className='modal__talker-list'>
					{!filteredTalkers.length ? (
						<p className='mx-2'>{translations.empty.usersEmpty}</p>
					) : (
						filteredTalkers.map((talker: Talker, index: number) => {
							return (
								<RoomTalkerElem
									key={talker.id}
									talker={talker}
									index={index}
									externalRoomId={externalRoomId}
								/>
							);
						})
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default observer(RoomTalkersModal);

import {observer} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect} from 'react';

import {Button, Badge} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import useL10n from 'l10n/useL10n';

interface IHighlightInfo {
	createResponse: any;
}

const HighlightInfo: FunctionComponent<IHighlightInfo> = function HighlightInfo({createResponse}) {
	const history = useHistory();
	const translations = useL10n();

	const goToHistory = () => {
		history.push('/highlights');
	};

	const renderItem = useCallback(
		(elem: any, index: number, type: string) => {
			return (
				<Badge bg={type} className='m-1' key={index}>
					{elem}
				</Badge>
			);
		},
		[createResponse]
	);

	return (
		<div className='mb-5'>
			{createResponse.availableExternalRoomIds?.length > 0 && (
				<div>
					<p className='h5'>{translations.highlights.availableRooms}</p>
					{createResponse.availableExternalRoomIds && (
						<p>
							{createResponse.availableExternalRoomIds.map((elem: any, index: number) =>
								renderItem(elem, index, 'success')
							)}
						</p>
					)}
				</div>
			)}

			{createResponse.unavailableExternalRoomIds?.length > 0 && (
				<div>
					<p className='h5'>{translations.highlights.unavailableRooms}</p>
					<p>
						{createResponse.unavailableExternalRoomIds.map((elem: any, index: number) =>
							renderItem(elem, index, 'warning')
						)}
					</p>
				</div>
			)}

			<Button variant='dark' onClick={goToHistory}>
				{translations.highlights.goToPrevious}
			</Button>
		</div>
	);
};

export default observer(HighlightInfo);

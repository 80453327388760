import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import SemanticAnalysisService from 'services/api/SemanticAnalysisService';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import useL10n from 'l10n/useL10n';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const translations = useL10n();

	const getPrompts = async () => {
		const response = await SemanticAnalysisService.getPrompts(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const getPrompt = async (id: number) => {
		const response = await SemanticAnalysisService.getPrompt(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const getPromptHistory = async () => {
		const response = await SemanticAnalysisService.getPromptHistory(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const addPromptHistory = async (promptId: number) => {
		const response = await SemanticAnalysisService.addPromptHistory(accessToken, promptId);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const deletePromptHistory = async (id: number) => {
		const response = await SemanticAnalysisService.deletePromptHistory(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const getModels = async (key: string) => {
		const response = await SemanticAnalysisService.getModels(accessToken, key);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const getOpenaiKey = async () => {
		const response = await SemanticAnalysisService.getOpenaiKey(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const addPrompt = async (body: any) => {
		const response = await SemanticAnalysisService.addPrompt(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const deletePrompt = async (id: number) => {
		const response = await SemanticAnalysisService.deletePrompt(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const endPrompt = async (promptId: number) => {
		const body = {
			promptId,
		};
		const response = await SemanticAnalysisService.endPrompt(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: response.data,
			variant: 'danger',
		});
		return false;
	};

	const promptDraft = async (body: any) => {
		const response = await SemanticAnalysisService.promptDraft(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			return response;
		}

		addToast({
			title: translations.toasts.error,
			text: response.data,
			variant: 'danger',
		});
		return false;
	};

	return {
		getPrompts,
		getPrompt,
		getPromptHistory,
		getModels,
		getOpenaiKey,
		addPrompt,
		deletePromptHistory,
		addPromptHistory,
		endPrompt,
		promptDraft,
		deletePrompt,
	};
};

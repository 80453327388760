import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {Changelog} from 'models/changelog';

export default class ChangelogService {
	static changelogServiceBaseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		ChangelogService.changelogServiceBaseUrl = baseUrl;
	};

	static getChangelog = async (token: UUID, limit?: number, offset?: number) => {
		try {
			const url = `/admin/changelog?limit=${limit || 20}&offset=${offset || 0}&order=id_desc`;
			const {data, status} = await axios.get(`${ChangelogService.changelogServiceBaseUrl}${url}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postChangelog = async (token: UUID, value: Changelog) => {
		try {
			const {data, status} = await axios.post(
				`${ChangelogService.changelogServiceBaseUrl}/admin/changelog`,
				value,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getChangelogById = async (token: UUID, id: number) => {
		try {
			const url = `/admin/changelog/${id}`;
			const {data, status} = await axios.get(`${ChangelogService.changelogServiceBaseUrl}${url}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchChangelogById = async (token: UUID, id: number, value: Changelog) => {
		try {
			const url = `/admin/changelog/${id}`;
			const {data, status} = await axios.patch(
				`${ChangelogService.changelogServiceBaseUrl}${url}`,
				value,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteChangelogById = async (token: UUID, id: number) => {
		try {
			const url = `/admin/changelog/${id}`;
			const {data, status} = await axios.delete(
				`${ChangelogService.changelogServiceBaseUrl}${url}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getCountUnviewed = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${ChangelogService.changelogServiceBaseUrl}/admin/changelog/countUnviewed`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postViewedChangelogId = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.post(
				`${ChangelogService.changelogServiceBaseUrl}/admin/changelog/view/${id}`,
				null,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postChangelogSubscribe = async (token: UUID, email: string) => {
		try {
			const url = `/admin/changelog/subscribe`;
			const {data, status} = await axios.post(
				url,
				{email},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadPic = async (token: UUID, file: File) => {
		try {
			const formData = new FormData();
			formData.append('pic', file);
			const {data, status} = await axios.post(`/admin/pic`, formData, {
				headers: {Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data'},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

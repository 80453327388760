/* eslint-disable jsx-a11y/media-has-caption */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Modal, Form, InputGroup, Alert, Image, Badge, Spinner} from 'react-bootstrap';
import {BsPersonXFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import RoomService from 'services/api/RoomService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import UserRole from 'models/enums/UserRole.enum';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IAddTalkerModal {
	externalRoomId?: string;
}

const AddTalkerModal: FunctionComponent<IAddTalkerModal> = function RoomRecordModal({
	externalRoomId,
}) {
	const translations = useL10n();
	const [userID, setUserID] = useState('');
	const [isModer, setIsModer] = useState(false);
	const [role, setRole] = useState<UserRole>(UserRole.GUEST);
	const [currentUser, setCurrentUser] = useState<any>(undefined);
	const [currentTalker, setCurrentTalker] = useState<any>(undefined);
	const [isAssignRoleScreen, setIsAssignRoleScreen] = useState(false);
	const [validated, setValidated] = useState(false);
	const [visibleLoader, setVisibleLoader] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const {addToast} = useLocalObservable(() => toastServices);
	const {addTalkerModalVisible, toggleAddTalkerModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {accessToken} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const onChangeModerRoleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsModer(checked);
		currentTalker && setIsButtonDisabled(checked === currentTalker?.isModer);
	};

	const onChangeSpeakerRoleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		const checkedRole = checked ? UserRole.SPEAKER : UserRole.GUEST;
		setRole(checkedRole);
		currentTalker && setIsButtonDisabled(checkedRole === currentTalker?.role);
	};

	const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		value = value.replace(/[а-яА-ЯЁё]/, '').trim();
		setUserID(value);
		if (!value) setCurrentUser(undefined);
	};

	const clear = () => {
		toggleAddTalkerModalVisible(false);
		setUserID('');
		setIsModer(false);
		setRole(UserRole.GUEST);
		setCurrentUser(undefined);
		setCurrentTalker(undefined);
		setIsAssignRoleScreen(false);
		setValidated(false);
		setIsButtonDisabled(false);
	};

	const onBtnRoleClickHandler = () => {
		setIsAssignRoleScreen(true);
	};

	const onSearchClickHandler = async () => {
		if (userID && externalRoomId) {
			setVisibleLoader(true);
			const response = await RoomService.getUserByUserId(accessToken, {
				externalRoomId,
				externalUserId: userID,
			});
			setVisibleLoader(false);
			if (response.status === ResponseStatus.SUCCESS) {
				setCurrentUser(response.data.user);
				if (response.data.talker) {
					setCurrentTalker(response.data.talker);
					setIsModer(response.data.talker.isModer);
					setRole(response.data.talker.role);
				}
				return;
			}
			setCurrentUser(null);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearchClickHandler();
		}
	};

	const assignRole = async () => {
		if (externalRoomId) {
			const talkerData: any = {
				externalRoomId,
				externalUserId: userID,
			};
			if (isModer !== currentTalker?.isModer) talkerData.isModer = isModer;
			if (role !== currentTalker?.role) talkerData.role = role;

			const response = await RoomService.precreateTalker(accessToken, talkerData);
			if (response.status === ResponseStatus.SUCCESS) {
				toggleAddTalkerModalVisible(false);
				clear();
				addToast({
					title: translations.toasts.success,
					text: response.data?.isUserNew
						? translations.toasts.userAdded(userID)
						: translations.toasts.rightsAssigned(userID),
					variant: 'success',
				});
				return;
			}
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		assignRole();
	};

	const onModalClose = () => {
		clear();
	};

	useEffect(() => {
		if (currentUser) {
			setIsButtonDisabled(isModer === currentTalker?.isModer && role === currentTalker?.role);
		}
	}, [currentUser, isModer, role]);

	return (
		<Modal
			className='modal--addTalker'
			show={addTalkerModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header className='p-0' closeButton>
				{' '}
			</Modal.Header>
			<Modal.Body className='pt-1'>
				{!isAssignRoleScreen && (
					<div>
						<Modal.Title className='mb-1'>{translations.modals.addTalker.title}</Modal.Title>
						<p className='text-secondary mb-4'>{translations.modals.addTalker.subtitle}</p>
						<div className='mb-3'>
							<InputGroup>
								<Form.Control
									placeholder={translations.modals.addTalker.inputPlaceholder}
									value={userID}
									onChange={onChangeInputHandler}
									required
									onKeyPress={handleKeyPress}
								/>
								<Button
									variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
									className='px-4'
									onClick={onSearchClickHandler}>
									{translations.btns.search}
								</Button>
							</InputGroup>
						</div>

						{visibleLoader && (
							<div className='text-center'>
								<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
							</div>
						)}

						{!visibleLoader && (
							<div>
								{currentUser === null && userID && (
									<Alert variant='danger' className='mb-4 pl-0'>
										<div className='d-flex align-items-center'>
											<BsPersonXFill className='flex-shrink-0 text-danger h1 m-3 mx-4' />
											<div>
												<p className='mb-1'>
													<b className='text-danger'>{translations.modals.addTalker.noUsers}</b>
												</p>
												<p className='text-secondary mb-0'>
													{translations.modals.addTalker.noUsersText}
												</p>
											</div>
										</div>
									</Alert>
								)}

								{currentUser && (
									<Alert variant='light' className='mb-4 pl-0'>
										<div className='d-flex align-items-center'>
											<div className='user-info'>
												{currentUser.pic && (
													<div
														className={`user-info__photo background-color-${
															currentUser.color || 0
														}`}>
														<Image
															src={currentUser.pic}
															roundedCircle
															fluid
															className='h-100 object-fit-cover'
														/>
													</div>
												)}
											</div>

											<div>
												<p className='mb-1'>
													<b>{currentUser.name}</b>
												</p>
												<div className='d-flex align-items-center'>
													<Badge bg='light' className='mb-0' pill>
														id{currentUser.id}
													</Badge>
													<Badge bg='light' className='mb-0 mx-2' pill>
														ext:{currentUser.externalId}
													</Badge>
												</div>
											</div>
										</div>
									</Alert>
								)}

								{currentUser !== undefined && (
									<Button
										variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
										type='button'
										onClick={onBtnRoleClickHandler}>
										{currentUser === null
											? translations.modals.addTalker.btnCreateAndRole
											: translations.modals.addTalker.btnRole}
									</Button>
								)}
							</div>
						)}
					</div>
				)}

				{isAssignRoleScreen && (
					<div>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<Modal.Title className='mb-1'>{translations.modals.addTalker.btnRole}</Modal.Title>
							<p className='text-secondary mb-4'>
								{translations.modals.addTalker.assignRoleSubtitle}
							</p>
							<div className='mb-3'>
								<Form.Check
									name='isModer'
									onChange={onChangeModerRoleHandler}
									type='switch'
									id='isModerToggler'
									label={translations.modals.addTalker.roomModer}
									checked={isModer}
									required={role !== UserRole.SPEAKER}
								/>
							</div>
							<div className='mb-4'>
								<Form.Check
									name='isSpeaker'
									onChange={onChangeSpeakerRoleHandler}
									type='switch'
									id='isSpeakerToggler'
									label={translations.modals.addTalker.speaker}
									checked={role === UserRole.SPEAKER}
									required={!isModer}
								/>
							</div>
							<Button
								variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
								type='submit'
								disabled={isButtonDisabled}>
								{translations.modals.addTalker.btnRole}
							</Button>
						</Form>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(AddTalkerModal);

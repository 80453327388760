import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {ExportTypes} from 'models/enums/ExportTypes.enum';

export default class ExporterService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		ExporterService.baseUrl = baseUrl;
	};

	static getDump = async (token: UUID, limit: number, offset: number) => {
		try {
			const apiUrl = `/dump?limit=${limit}&offset=${offset}`;
			const {data, status} = await axios.get(`${ExporterService.baseUrl}${apiUrl}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static exportUserActivity = async (
		token: UUID,
		body: {from: string; to: string; type?: string},
		type: ExportTypes
	) => {
		try {
			let apiUrl = '';
			switch (type) {
				case ExportTypes.USER_ACTIVITY: {
					apiUrl = '/user_activity';
					break;
				}
				case ExportTypes.MESSAGES: {
					apiUrl = '/message';
					break;
				}
				case ExportTypes.BAN: {
					apiUrl = '/ban';
					break;
				}
				default: {
					apiUrl = '/message';
					break;
				}
			}

			const {data, status} = await axios.post(`${ExporterService.baseUrl}${apiUrl}`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';

import {Button, Modal, Form, FloatingLabel, Image} from 'react-bootstrap';

import modalService from 'store/modalServices';

import '../modal.scss';

import useL10n from 'l10n/useL10n';
import useBot from 'hooks/useBot';
import toastServices from 'store/toastServices';

interface ICreateBotModal {
	onCloseSuccess: () => void;

	mode: string;
	editedBot: GetBot | null;
}

const createBotModal: FunctionComponent<ICreateBotModal> = function createBotModal({
	onCloseSuccess,

	mode,
	editedBot,
}) {
	const {botModalVisible, setBotModalVisible} = useLocalObservable(() => modalService);

	const [validated, setValidated] = useState(false);
	const [botData, setBotData] = useState<Bot | null>(null);
	const [pic, setPic] = useState<Blob | null>();
	const [image, setImage] = useState('');
	const {addToast} = useLocalObservable(() => toastServices);
	const translations = useL10n();
	const {createBot, updateBot} = useBot();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		if (event.target.name === 'externalUserId') {
			value = value.replace(/[^a-zA-Z0-9-_]/i, '').trim();
			if (!value.startsWith('bot_')) {
				value = `bot_${value}`;
			}
		}

		if (event.target.name === 'name') {
			value = value.replace(/[^a-zA-Z0-9-_]/i, '').trim();
		}
		if (event.target.name === 'userColorId') {
			value = value.replace(/[^1-9]/g, '').slice(0, 1);
		}

		setBotData({
			...botData,
			[event.target.name]: value,
		});
	};

	const clear = () => {
		setValidated(false);
		setImage('');
		setPic(null);
		setBotData(null);
		setBotModalVisible(false);
	};

	const create = async () => {
		if (botData) {
			const response = await createBot({...botData, pic: pic || ''});

			if (response) {
				clear();
				setBotModalVisible(false);
				onCloseSuccess();
				return;
			}
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const update = async () => {
		if (botData && editedBot) {
			const response = await updateBot({...botData, pic: pic || ''}, editedBot.id);
			if (response) {
				clear();
				setBotModalVisible(false);
				onCloseSuccess();
				return;
			}
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const onModalClose = () => {
		clear();
		setBotModalVisible(false);
	};

	const onModalShow = () => {
		if (mode === 'edit' && editedBot) {
			setBotData({
				userColorId: editedBot.color,
				externalUserId: editedBot.externalId,
				name: editedBot.name,
				pic: editedBot.pic,
			});
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		if (mode === 'edit') {
			update();
			return;
		}
		create();
	};

	const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setPic(file);
				setImage(URL.createObjectURL(file));
			}
		}
	};

	return (
		<Modal
			className='modal'
			show={botModalVisible}
			onHide={onModalClose}
			onShow={onModalShow}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title>
					{mode === 'create' ? translations.btns.create : translations.btns.edit}{' '}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					{mode === 'create' && (
						<Form.Group className='mb-3' controlId='botExternalUserId'>
							<Form.Label>ExternalUserId</Form.Label>
							<FloatingLabel
								controlId='floatingId'
								label='ExternalUserId'
								className='text-secondary'>
								<Form.Control
									placeholder='ExternalUserId'
									name='externalUserId'
									required
									value={botData?.externalUserId}
									onChange={onChangeHandler}
								/>
								<Form.Control.Feedback type='invalid' tooltip>
									invalid
								</Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
					)}

					<Form.Group className='mb-3' controlId='botNickname'>
						<Form.Label>Nickname</Form.Label>
						<FloatingLabel controlId='floatingName' label='Nickname' className='text-secondary'>
							<Form.Control
								placeholder='Nickname'
								name='name'
								required
								value={botData?.name}
								onChange={onChangeHandler}
								maxLength={25}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								invalid
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className='mb-3' controlId='botColor'>
						<Form.Label>Color</Form.Label>
						<FloatingLabel controlId='floatingName' label='Color' className='text-secondary'>
							<Form.Control
								placeholder='Color'
								name='userColorId'
								value={botData?.userColorId}
								onChange={onChangeHandler}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								invalid
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					<Form.Group controlId='formFile' className='mb-3'>
						<div>
							<Form.Label>Avatar</Form.Label>
						</div>

						{(image || botData?.pic) && (
							<Image src={image || botData?.pic} className='mb-2' fluid style={{height: '8rem'}} />
						)}
						<Form.Control type='file' onChange={readFile} />
					</Form.Group>

					<Modal.Footer>
						<Button variant='outline-secondary' onClick={clear}>
							{translations.btns.cancel}
						</Button>
						<Button type='submit' variant='dark'>
							{mode === 'create' ? translations.btns.create : translations.btns.save}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default observer(createBotModal);

enum ReportReasonEnum {
	VIOLENCE = 'VIOLENCE',
	PROVOCATIONS = 'PROVOCATIONS',
	PERSONALDETAILS = 'PERSONAL_DETAILS',
	FLOOD = 'FLOOD',
	SPAM = 'SPAM',
	FRAUDBEGGING = 'FRAUD/BEGGING',
	OTHER = 'OTHER',
	ABUSE = 'ABUSE',
	PORN = 'PORN',
}

export {ReportReasonEnum};

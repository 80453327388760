import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Navbar,
	Form,
	Button,
	Spinner,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import userServices from 'store/userServices';
import reportServices from 'store/reportServices';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

import useL10n from 'l10n/useL10n';

import useReports from 'hooks/useReports';
import {LIMIT} from 'constants/constants';

import PageTitle from 'components/pageTitle/PageTitle';
import CreateBanModal from 'components/modals/createBan/CreateBanModal';
import ReportsItem from './components/reports/ReportsItem';

const Reports: FunctionComponent = function Reports() {
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [offset, setOffset] = useState(0);
	const [sortBy, setSortBy] = useState('createdAt_desc');

	const {reports} = useLocalObservable(() => reportServices);
	const {accessToken} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const {getReports} = useReports();
	const translations = useL10n();

	const getReportsWithServices = async (limit: number, currentOffset: number, order: string) => {
		setVisiblePreloader(true);
		await getReports(limit, currentOffset, order);
		setVisiblePreloader(false);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getReportsWithServices(LIMIT, offset - LIMIT, sortBy);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getReportsWithServices(LIMIT, offset + LIMIT, sortBy);
	};

	const onSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === '') return;
		setSortBy(value);
		setOffset(0);

		getReports(LIMIT, 0, value);
	};

	useEffect(() => {
		accessToken && getReportsWithServices(LIMIT, offset, sortBy);
	}, [accessToken]);

	return (
		<Container fluid className='pt-4 reports'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.reports.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.reports.title}
						text={translations.sidebar.reports.descr}
					/>
					<Navbar className='d-flex w-100 p-0 mb-2'>
						<div className=' w-100 d-flex align-items-center justify-content-between'>
							<div className='filters__btns'>
								<Button
									variant='dark'
									className='d-flex'
									onClick={onPrevPageBtnClickHandler}
									disabled={(!reports.length && offset === 0) || offset === 0}>
									<BsArrowLeftCircleFill />
								</Button>
								&nbsp;
								<Button
									variant='dark'
									className='d-flex'
									onClick={onNextPageBtnClickHandler}
									disabled={!reports.length || reports.length < LIMIT}>
									<BsArrowRightCircleFill />
								</Button>
							</div>
						</div>
					</Navbar>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{reports.length > 0 ? (
						<Table
							responsive
							striped={appTheme === Theme.LIGHT}
							bordered
							hover
							variant={appTheme?.toLowerCase()}>
							<thead>
								<tr>
									<th className='text-center text-truncate align-middle col-1'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.sorting.reportsCount}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.sorting.reportsCount}</option>
												<option value='size_desc'>
													{translations.sorting.reportsCount} &darr;
												</option>
												<option value='size_asc'>{translations.sorting.reportsCount} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='text-center text-truncate align-middle col-1'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.accused}</b>
												</Tooltip>
											}>
											<b className='text-center'>{translations.table.accused}</b>
										</OverlayTrigger>
									</th>
									<th className='text-center text-truncate align-middle col-2'>
										<b>{translations.table.reason}</b>
									</th>
									<th className=' align-middle text-truncate col-3'>
										<b>{translations.table.message}</b>
									</th>
									<th className='text-center align-middle text-truncate col-1'>
										<b>{translations.table.room}</b>
									</th>
									<th className='align-middle text-center text-truncate col-1'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.reportTime}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.table.reportTime}</option>
												<option value='createdAt_desc'>
													{translations.table.reportTime} &darr;
												</option>
												<option value='createdAt_asc'>
													{translations.table.reportTime} &uarr;
												</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='align-middle col-3'> </th>
								</tr>
							</thead>
							<tbody>
								{reports.map((elem, index) => (
									<ReportsItem report={elem} getReports={getReports} key={index} />
								))}
							</tbody>
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.reportsEmpty}</p>
					)}
				</Col>
			</Row>
			<CreateBanModal />
		</Container>
	);
};

export default observer(Reports);

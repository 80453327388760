/* eslint-disable no-nested-ternary */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {Container, Row, Col, Table, Breadcrumb, Spinner, Badge, Button} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';
import ExporterService from 'services/api/ExporterService';
import {ExportStatus, ExportTypes} from 'models/enums/ExportTypes.enum';
import {BsDownload, BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import Moment from 'react-moment';
import modalServices from 'store/modalServices';
import ExportModal from 'components/modals/exportModal/ExportModal';

import {LIMIT} from 'constants/constants';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import PageTitle from 'components/pageTitle/PageTitle';

const Feedback: FunctionComponent = function Feedback() {
	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {toggleExportModalVisible} = useLocalObservable(() => modalServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [offset, setOffset] = useState(0);
	const [data, setData] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const getExportsWithServices = async (limit?: number, currentOffset?: number) => {
		setVisiblePreloader(true);
		const response = await ExporterService.getDump(accessToken, limit || LIMIT, currentOffset || 0);
		if (response.status === ResponseStatus.SUCCESS) {
			setData(response.data);
			setVisiblePreloader(false);
		}
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getExportsWithServices(LIMIT, offset - LIMIT);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getExportsWithServices(LIMIT, offset + LIMIT);
	};

	const renderItem = useCallback(
		(item: any, index: number) => {
			return (
				<tr key={index}>
					<td className='align-middle'>
						<b>
							{item.type === ExportTypes.USER_ACTIVITY
								? translations.export.users
								: item.type.includes(ExportTypes.BAN)
								? translations.export.bans
								: translations.export.messages}
						</b>
					</td>
					<td className='text-center align-middle'>
						<Moment format='DD.MM.YYYY'>{item.dateFrom}</Moment>
					</td>
					<td className='text-center align-middle'>
						<Moment format='DD.MM.YYYY'>{item.dateTo}</Moment>
					</td>
					<td className='text-center align-middle'>
						{item.status === ExportStatus.READY && (
							<Badge pill bg='success'>
								{translations.export.ready}
							</Badge>
						)}
						{item.status === ExportStatus.PENDING && (
							<Badge pill bg='secondary'>
								{translations.export.forming}
							</Badge>
						)}
						{item.status === ExportStatus.FAILED && (
							<Badge pill bg='danger'>
								failed
							</Badge>
						)}
					</td>
					<td className='text-center align-middle'>
						{item.status === ExportStatus.READY && (
							<a href={item.url} className='text-info'>
								<BsDownload />
								&nbsp;
								{translations.btns.save}
							</a>
						)}{' '}
					</td>
				</tr>
			);
		},
		[data]
	);

	useEffect(() => {
		if (accessToken) {
			getExportsWithServices();
		}
	}, [accessToken]);

	return (
		<Container fluid className='pt-4'>
			<Row className='align-items-center'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.reporting}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.export.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.export.title}
						text={translations.sidebar.export.descr}
					/>
				</Col>
				<Col className='d-flex justify-content-end align-items-unset'>
					<Button
						type='button'
						variant={appTheme === Theme.DARK ? 'light' : 'dark'}
						onClick={() => toggleExportModalVisible(true)}
						className='mx-2'>
						{translations.export.new}
					</Button>
				</Col>
			</Row>

			<div className='block col-8'>
				<div className='d-flex justify-content-end mb-3'>
					<Button
						className='d-flex'
						variant='dark'
						onClick={onPrevPageBtnClickHandler}
						disabled={(!data.length && offset === 0) || offset === 0}>
						<BsArrowLeftCircleFill className='h6 m-0' />
					</Button>
					&nbsp;
					<Button
						className='d-flex'
						variant='dark'
						onClick={onNextPageBtnClickHandler}
						disabled={!data.length || data.length < LIMIT}>
						<BsArrowRightCircleFill className='h6 m-0' />
					</Button>
				</div>

				{visiblePreloader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}

				{data.length > 0 ? (
					<Table
						responsive
						striped={appTheme === Theme.LIGHT}
						bordered
						hover
						variant={appTheme?.toLowerCase()}>
						<thead>
							<tr>
								<th className='align-middle'>{translations.export.type}</th>
								<th className='text-center align-middle'>{translations.export.from}</th>
								<th className='text-center align-middle'>{translations.export.to}</th>
								<th className='text-center align-middle'>{translations.export.status}</th>
								<th className='text-center align-middle'> </th>
							</tr>
						</thead>
						<tbody>{data.map(renderItem)}</tbody>
					</Table>
				) : (
					!visiblePreloader && <p>{translations.empty.feedbackEmpty}</p>
				)}
			</div>
			<ExportModal onClose={getExportsWithServices} />
		</Container>
	);
};
export default observer(Feedback);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';

import {Form, FloatingLabel} from 'react-bootstrap';
import pollServices from 'store/pollServices';

import useL10n from 'l10n/useL10n';
import {useParams} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {PollStatus, PollType} from 'models/enums/Poll.enum';
import classNames from 'classnames';

type time = {
	hours?: string;
	minutes?: string;
	seconds?: string;
};

const pollDate: FunctionComponent = function pollDate() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const translations = useL10n();

	const today: Date = new Date();
	const time = useRef<time>({
		hours: '',
		minutes: '',
		seconds: '',
	});
	const {
		currentPoll,
		updateCurrentPoll,
		setIsStartTimeEmpty,
		setIsEndTimeEmpty,
		isStartTimeEmpty,
		isEndTimeEmpty,
	} = useLocalObservable(() => pollServices);

	const [sendType, setSendType] = useState('NOW');
	const [duration, setDuration] = useState('ENDLESS');
	const [startTime, setStartTime] = useState<Date>();
	const [endTime, setEndTime] = useState<Date | null>();
	const [autoSendResults, setAutoSendResults] = useState<boolean>(true);

	const onSelectStartTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSendType(value);
		value !== 'NOW' ? setDuration('CUSTOM') : setDuration('ENDLESS');
		updateCurrentPoll({
			startTime: undefined,
		});
		setStartTime(undefined);
	};

	const filterPassedTime = (value: any, date?: Date) => {
		const currentDate = date || new Date();
		const selectedDate = new Date(value);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const setStart = (date: Date) => {
		setStartTime(date);
		updateCurrentPoll({
			startTime: date ? date.toISOString() : undefined,
		});
		setIsStartTimeEmpty(date === null || date === undefined);
	};

	const setEnd = (date: Date) => {
		setEndTime(date);
		updateCurrentPoll({
			endTime: date ? date.toISOString() : undefined,
		});
		setIsEndTimeEmpty(date === null || date === undefined);
	};

	const onChangeTimeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/[^0-9.]/g, '');
		time.current = {
			...time.current,
			[event.target.name]: value,
		};
		updateCurrentPoll({
			time: {...time.current},
		});
		setIsEndTimeEmpty(!time.current.hours && !time.current.minutes && !time.current.seconds);
	};

	const onSelectDuration = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setDuration(value);
		setEndTime(null);
		updateCurrentPoll({
			duration: value,
			endTime: null,
			time: null,
		});
		time.current = {
			hours: '',
			minutes: '',
			seconds: '',
		};
	};

	const onCheckAutoSendHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setAutoSendResults(checked);
		updateCurrentPoll({
			autoSendResults: checked,
		});
	};

	const startTimeClasses = classNames('form-control', {
		'form-control--warning': isStartTimeEmpty,
	});
	const endTimeClasses = classNames('form-control', {
		'form-control--warning': isEndTimeEmpty,
	});

	useEffect(() => {
		setIsStartTimeEmpty(sendType !== 'NOW' && !currentPoll?.startTime);
	}, [sendType]);

	useEffect(() => {
		setIsEndTimeEmpty(duration !== 'ENDLESS' && !currentPoll?.endTime && !currentPoll?.time);
	}, [duration]);

	useEffect(() => {
		if (currentPoll?.time) time.current = currentPoll?.time;
		currentPoll?.duration && setDuration(currentPoll?.duration);

		if (currentPoll?.startTime) {
			setStartTime(new Date(currentPoll?.startTime));
			setSendType(!currentPoll?.startTime || currentPoll?.startTime === 'NOW' ? 'NOW' : 'CUSTOM');
		}

		if (currentPoll?.endTime) {
			setEndTime(new Date(currentPoll?.endTime));
			setDuration('CUSTOM');
		}

		currentPoll?.autoSendResults || typeof currentPoll?.autoSendResults === undefined
			? setAutoSendResults(true)
			: setAutoSendResults(false);
	}, []);

	return (
		<div>
			{currentPoll?.status !== PollStatus.ACTIVE && (
				<div className='mb-4'>
					<FloatingLabel controlId='floatingSelect' label={translations.poll.date} className='mb-2'>
						<Form.Select onChange={onSelectStartTime} value={sendType}>
							{!slug && <option value='NOW'>{translations.highlights.sendNow}</option>}
							<option value='CUSTOM'>{translations.highlights.chooseDate}</option>
						</Form.Select>
					</FloatingLabel>

					{sendType === 'CUSTOM' && (
						<Form.Group className='mb-3' controlId='formDateStart'>
							<DatePicker
								className={startTimeClasses}
								selected={startTime}
								placeholderText={translations.poll.date}
								dateFormat='dd.MM.yyyy, HH:mm'
								minDate={today}
								timeInputLabel={`${translations.modals.createRoom.time}:`}
								showTimeSelect
								filterTime={filterPassedTime}
								timeFormat='HH:mm'
								timeIntervals={5}
								timeCaption='time'
								required
								onChange={(date: Date) => setStart(date)}
							/>
						</Form.Group>
					)}
				</div>
			)}

			<FloatingLabel controlId='floatingSelect' label={translations.poll.date} className='mb-4'>
				<Form.Select onChange={onSelectDuration} value={duration}>
					<option value='ENDLESS' disabled={sendType === 'CUSTOM'}>
						{translations.highlights.durationEndless}
					</option>
					{(!slug || currentPoll?.status !== PollStatus.ACTIVE) && (
						<option value='LASTING'>{translations.highlights.setDuration}</option>
					)}
					<option value='CUSTOM'>{translations.highlights.setEndDate}</option>
				</Form.Select>
			</FloatingLabel>

			{duration === 'LASTING' && (
				<div className='mb-4'>
					<FloatingLabel
						controlId='floatingControlHours'
						label={translations.highlights.hours}
						className='mb-2 text-secondary'>
						<Form.Control
							className={endTimeClasses}
							placeholder={translations.highlights.hours}
							name='hours'
							onChange={onChangeTimeHandler}
							value={time.current.hours}
							required={!time.current.minutes && !time.current.seconds}
							maxLength={2}
						/>
					</FloatingLabel>

					<FloatingLabel
						controlId='floatingControlMinutes'
						label={translations.highlights.minutes}
						className='mb-2 text-secondary'>
						<Form.Control
							className={endTimeClasses}
							placeholder={translations.highlights.minutes}
							name='minutes'
							onChange={onChangeTimeHandler}
							value={time.current.minutes}
							required={!time.current.hours && !time.current.seconds}
							maxLength={2}
						/>
					</FloatingLabel>

					<FloatingLabel
						controlId='floatingControlSeconds'
						label={translations.highlights.seconds}
						className='mb-2 text-secondary'>
						<Form.Control
							className={endTimeClasses}
							placeholder={translations.highlights.seconds}
							name='seconds'
							onChange={onChangeTimeHandler}
							value={time.current.seconds}
							required={!time.current.hours && !time.current.minutes}
							maxLength={2}
						/>
					</FloatingLabel>
				</div>
			)}

			{duration === 'CUSTOM' && (
				<Form.Group className='mb-3' controlId='formDateStart'>
					<DatePicker
						className={endTimeClasses}
						selected={endTime}
						placeholderText={translations.highlights.endDateTime}
						dateFormat='dd.MM.yyyy, HH:mm'
						minDate={startTime}
						timeInputLabel={`${translations.modals.createRoom.time}:`}
						required
						showTimeSelect
						filterTime={value => filterPassedTime(value, startTime)}
						timeFormat='HH:mm'
						timeIntervals={5}
						timeCaption='time'
						onChange={(date: Date) => setEnd(date)}
					/>
				</Form.Group>
			)}

			{duration !== 'ENDLESS' && currentPoll?.type !== PollType.QUIZ && (
				<Form.Check type='checkbox' id='autoSendResults'>
					<Form.Check.Input isValid onChange={onCheckAutoSendHandler} checked={autoSendResults} />
					<Form.Check.Label>{translations.poll.autoSendResults}</Form.Check.Label>
				</Form.Check>
			)}
		</div>
	);
};

export default observer(pollDate);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {NavLink} from 'react-router-dom';
import Moment from 'react-moment';

import {Image, Form, Badge, Alert} from 'react-bootstrap';

import userServices from 'store/userServices';
import BanService from 'services/api/BanService';

import {Ban} from 'models/ban';

import translations from 'l10n/translations';

import useReportReasons from 'hooks/useBanReasons';
import {getBanDuration} from 'utils/helpers';
import {BanReasonEnum} from 'models/enums/BanReason.enum';
import appService from 'store/appService';

interface IBannedHistoryItem {
	ban: Ban;
	getBans: () => void;
}

const BannedHistoryItem: FunctionComponent<IBannedHistoryItem> = function BannedHistoryItem({
	ban,
	getBans,
}) {
	const {accessToken, admins} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {getReason} = useReportReasons();

	const [reason, setReason] = useState('');
	const [creator, setCreator] = useState<any | null>(null);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setReason(value);
	};

	const changeReason = async (value: string) => {
		const data = {
			description: value,
		};
		ban.talker
			? await BanService.patchTalkerBan(accessToken, ban.id, data)
			: await BanService.patchUserBan(accessToken, ban.id, data);
	};

	const onBlurHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
		const {value} = event.target;
		if (value.trim() === '' && !ban.description) return;
		if (value !== ban.description) {
			changeReason(value);
		}
	};

	const getCreator = () => {
		if (ban.creatorId === null) {
			return;
		}
		const admin = admins.find(el => el.userId === ban.creatorId);
		if (admin) setCreator(admin);
	};

	useEffect(() => {
		setReason(ban.description || '');
		ban && getCreator();
	}, [ban]);

	return (
		<tr>
			<td className='align-middle'>
				<div className='user-info'>
					<div
						className={`user-info__photo background-color-${
							ban.talker?.user?.color || ban.user?.color || 0
						}`}>
						<Image src={ban.talker?.user.pic || ban.user?.pic} roundedCircle fluid />
					</div>
					<div className='text-truncate'>
						<Badge bg={appTheme.toLowerCase()} pill>
							id{ban.talker?.user.id || ban.user.id}
						</Badge>
						<div className='text-truncate'>{ban.talker?.user.name || ban.user.name}</div>
					</div>
				</div>
			</td>
			<td className='align-middle text-center'>
				{ban.talker && ban.room ? (
					<div>
						<Badge pill bg={appTheme.toLowerCase()}>
							{translations.inRoom}
						</Badge>
						<div className='text-truncate'>
							<NavLink to={`/room/${encodeURIComponent(ban.room.externalRoomId)}`}>
								{ban.room.externalRoomId}
							</NavLink>
						</div>
					</div>
				) : (
					<div>
						<Badge pill bg={appTheme.toLowerCase()}>
							{translations.inApp}
						</Badge>
						{ban.user?.banRoom && (
							<div className='text-truncate'>
								<NavLink to={`/room/${encodeURIComponent(ban.user.banRoom.externalRoomId)}`}>
									{ban.user.banRoom.externalRoomId}
								</NavLink>
							</div>
						)}
						{ban.room && (
							<div className='text-truncate'>
								<NavLink to={`/room/${encodeURIComponent(ban.room.externalRoomId)}`}>
									{ban.room.externalRoomId}
								</NavLink>
							</div>
						)}
					</div>
				)}
			</td>
			<td className='text-center text-secondary align-middle'>
				{ban.createdAt && <Moment format='DD.MM.YY HH:mm'>{ban.createdAt}</Moment>}
			</td>
			<td className='text-center text-secondary align-middle'>
				{ban.expires && ban.createdAt ? (
					<Badge pill bg='warning'>
						{getBanDuration(ban)}
					</Badge>
				) : (
					!ban.expires && (
						<Badge pill bg='danger'>
							{translations.forever}
						</Badge>
					)
				)}
			</td>
			<td className='text-center text-secondary align-middle'>
				<div className='small'>
					{getReason(ban.reason) && <Alert variant='warning'>{getReason(ban.reason)?.text}</Alert>}
				</div>
			</td>
			<td className='text-center text-secondary'>
				<Form.Group controlId='exampleForm.ControlTextarea1'>
					<Form.Control
						as='textarea'
						rows={5}
						size='sm'
						value={reason}
						onBlur={onBlurHandler}
						onChange={onChangeHandler}
						maxLength={250}
					/>
				</Form.Group>
			</td>
			<td className='text-center align-middle'>
				<div className='text-truncate'>
					{creator && (
						<Badge bg='light' pill>
							id{ban.creatorId}
						</Badge>
					)}
					<div className='text-truncate'>{creator && <span>{creator.user.name}</span>}</div>
					{creator && (
						<Badge bg='info' pill>
							{creator.role}
						</Badge>
					)}
					{!creator && ban.reason === BanReasonEnum.AUTO && (
						<small>{translations.table.autoBan}</small>
					)}
				</div>
			</td>
			<td className='text-center col-1 align-middle'>
				{ban.deletedAt && <Moment format='DD.MM.YY HH:mm'>{ban.deletedAt}</Moment>}
			</td>
		</tr>
	);
};

export default observer(BannedHistoryItem);

import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Form} from 'react-bootstrap';
import {textEnding} from 'utils/helpers';
import {Theme} from 'models/enums/Theme.enum';
import useSticker from 'hooks/useSticker';
import appService from 'store/appService';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertServices from 'store/alertServices';

interface IStickerPack {
	elem: any;
	index: number;
	getPacks: () => void;
}
const StickerPack: FunctionComponent<IStickerPack> = function StickerPack({elem, index, getPacks}) {
	const translations = useL10n();
	const history = useHistory();

	const [isEnabled, setIsEnabled] = useState(false);

	const {updateStickerPack, deleteStickerPack} = useSticker();
	const {appTheme} = useLocalObservable(() => appService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = event.target;
		setIsEnabled(checked);
		updateStickerPack({id: elem.id, name: elem.name, isEnabled: checked});
	};

	const deletePack = async (id: number) => {
		await deleteStickerPack(id);
		getPacks();
	};

	const deletePackClickHandler = (id: number) => {
		showAlert({
			title: translations.btns.remove,
			text: translations.toasts.deleted,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deletePack(id);
					},
				},
			],
		});
	};
	useEffect(() => {
		if (elem) setIsEnabled(elem.isEnabled);
	}, [elem]);

	return (
		<div className='config__wrapper mb-3' key={elem.id}>
			<div className='config__head'>
				<div className='w-75'>
					<p className='h5 mb-2'>{elem.name}</p>
					{elem.stickers.length > 0 && (
						<div className='d-flex justify-content-between'>
							<p className='config__text text-secondary mb-1'>
								{elem.stickers.length}{' '}
								{textEnding(elem.stickers.length, translations.stickers.count)}
							</p>
						</div>
					)}
				</div>
				<div>
					<Button
						className='mx-2'
						variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
						onClick={() => history.push(`stickers/${elem.id}`)}>
						{translations.btns.edit}
					</Button>
					<Button variant='outline-danger' onClick={() => deletePackClickHandler(elem.id)}>
						{translations.btns.remove}
					</Button>
				</div>
			</div>
			<div className='config__content'>
				<div className='d-flex stickers__packs-wrapper'>
					{elem.stickers.map((el: Sticker, ind: number) => {
						if (ind < 15)
							return <img src={el.pic} alt='' key={el.id} className='stickers__packs-pic' />;
						return null;
					})}
					{elem.stickers.length > 15 && (
						<div className='stickers__packs-count'>{elem.stickers.length - 15}+</div>
					)}
				</div>
				<Form.Check
					onChange={onChangeHandler}
					type='switch'
					name='isPackEnabled'
					id='isPackEnabled'
					label={translations.toggle.on}
					checked={isEnabled}
					className='mb-2'
				/>
			</div>
		</div>
	);
};

export default observer(StickerPack);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {User} from 'models/user';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {
	Badge,
	Button,
	Form,
	InputGroup,
	Table,
	Image,
	Spinner,
	FloatingLabel,
} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {
	BsArrowLeftCircleFill,
	BsArrowRightCircleFill,
	BsFillPencilFill,
	BsXLg,
} from 'react-icons/bs';
import badgeService from 'store/badgeService';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import useBadge from 'hooks/useBadge';
import modalServices from 'store/modalServices';
import BadgesModal from 'components/modals/badge/BadgesModal';
import SelectControl from 'components/select/SelectControl';

const LIMIT = 10;

interface IAllBadgeUsers {
	currentTab: string;
}
const AllBadgeUsers: FunctionComponent<IAllBadgeUsers> = function AllBadgeUsers({currentTab}) {
	const translations = useL10n();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [selectedOption, setSelectedOption] = useState<any>();

	const {
		badge,
		badges,
		users,
		setCurrentUser,
		filter,
		search,
		setFilter,
		setSearch,
		setOffset,
		offset,
	} = useLocalObservable(() => badgeService);
	const {appTheme} = useLocalObservable(() => appService);
	const {toggleBadgesModalVisible} = useLocalObservable(() => modalServices);

	const {getAllBadgeUsers, getBadges} = useBadge();

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getAllBadgeUsers(LIMIT, offset - LIMIT, search, filter);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getAllBadgeUsers(LIMIT, offset + LIMIT, search, filter);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch({...search, value});
	};

	const onSearchSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSearch({...search, type: value});
	};

	const onSearchClickHandler = () => {
		if (search && users) {
			getAllBadgeUsers(LIMIT, 0, search, filter);
			setOffset(0);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearchClickHandler();
		}
	};

	const clear = () => {
		getAllBadgeUsers(LIMIT, 0, {...search, value: ''}, filter);
		setSearch({...search, value: ''});
		setOffset(0);
	};

	const editClickHandler = (user: User) => {
		toggleBadgesModalVisible(true);
		setCurrentUser(user);
	};

	const getUsers = () => {
		getAllBadgeUsers();
		setVisiblePreloader(false);
	};

	const onSelectHandler = (value: any) => {
		setSelectedOption(value);
		setOffset(0);
		const badgesFilter = value.map((el: any) => el.value).toString();

		if (value) {
			setFilter({type: 'badgeText', value: badgesFilter});
			getAllBadgeUsers(LIMIT, 0, search, {type: 'badgeText', value: badgesFilter});
		} else {
			setFilter({...filter, value: ''});
			getAllBadgeUsers(LIMIT, 0, search, {type: 'badgeText', value: ''});
		}
	};

	useEffect(() => {
		if (currentTab !== 'users') {
			setSelectedOption(null);
			setSearch({
				type: 'name',
				value: '',
			});
		}
	}, [currentTab]);

	useEffect(() => {
		getUsers();
		getBadges();
		return () => {
			setSelectedOption(null);
		};
	}, []);

	const renderBadgeUserItem = useCallback(
		(user: User, index: number) => {
			return (
				<tr key={user.id}>
					<td className='align-middle'>
						{user && (
							<div className='user-info'>
								<div
									className={`user-info__photo user-info__photo--sm background-color-${
										user?.color || 0
									}`}>
									<Image src={user.pic} roundedCircle fluid />
								</div>
								<div className='text-truncate'>
									<div className='text-truncate'>{user.name}</div>
								</div>
							</div>
						)}
					</td>
					<td>
						<div className='text-truncate'>
							<Badge bg={appTheme.toLowerCase()} pill>
								id{user.id}
							</Badge>
							<div className='user-info__id'>
								<Badge bg={appTheme.toLowerCase()} pill>
									ext:
									{user.externalId}
								</Badge>
							</div>
						</div>
					</td>
					<td>
						{user.badges && user.badges?.length > 0 && (
							<div>
								{user.badges.map((el: any) => {
									return (
										<span
											key={el.id}
											className='badge rounded-pill mx-1 text-truncate mw-100'
											style={{backgroundColor: el.backgroundColor, color: el.textColor}}>
											{el.text}
										</span>
									);
								})}
							</div>
						)}
					</td>
					<td className='align-middle text-secondary'>
						<div className='d-flex justify-content-end'>
							<Button
								variant='light'
								size='sm'
								className='d-flex p-1'
								onClick={() => editClickHandler(user)}>
								<BsFillPencilFill className='text-secondary' />
							</Button>
						</div>
					</td>
				</tr>
			);
		},
		[users]
	);

	return (
		<div className='badges-config__all-users'>
			<div className='block'>
				{visiblePreloader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}
				<div className='d-flex align-items-unset mb-2 w-50'>
					<InputGroup>
						<FloatingLabel controlId='floatingSelect' label={translations.search.placeholder}>
							<Form.Select onChange={onSearchSelect} value={search.type}>
								<option value='name'>{translations.search.talker}</option>
								<option value='externalUserId'>{translations.search.userId}</option>
							</Form.Select>
						</FloatingLabel>
						<Form.Control
							placeholder={translations.search.btn}
							aria-label={translations.search.searchBy}
							value={search.value}
							onChange={onChangeHandler}
							onKeyPress={handleKeyPress}
						/>
						{search.value && (
							<button
								type='button'
								className='badges-config__clear-btn text-secondary'
								onClick={clear}>
								<BsXLg />
							</button>
						)}
						<Button
							variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
							className='px-4'
							onClick={onSearchClickHandler}>
							{translations.btns.search}
						</Button>
					</InputGroup>
				</div>

				<Table responsive bordered hover variant={appTheme?.toLowerCase()}>
					<thead>
						<tr>
							<th className='align-middle col-2'>{translations.table.user}</th>
							<th className='align-middle col-3'>ID</th>
							<th className='align-middle col-3'>
								<SelectControl
									handleChange={onSelectHandler}
									options={badges.map(el => {
										return {value: el.text, label: el.text};
									})}
									isClearable
									isMulti
									theme={appTheme === Theme.LIGHT ? 'light' : 'dark'}
									value={selectedOption}
									placeholder={translations.uploadBadges.assignedBadges}
								/>
							</th>
							<th className='align-middle col-1'> </th>
						</tr>
					</thead>
					{users.length > 0 && (
						<tbody>
							{users.map((user: User, index: number) => {
								return renderBadgeUserItem(user, index);
							})}
						</tbody>
					)}
				</Table>
				{!users.length && <p className='mx-2'>{translations.empty.usersEmpty}</p>}
				<BadgesModal />
			</div>
			<div className='fixed-panel'>
				<div className='d-flex'>
					<Button
						variant={appTheme.toLowerCase()}
						className='d-flex align-items-center'
						onClick={onPrevPageBtnClickHandler}
						disabled={(!users.length && offset === 0) || offset === 0}>
						<BsArrowLeftCircleFill className='ico-sm' />
						&nbsp;
						<span>{translations.prev}</span>
					</Button>
					<Button
						variant={appTheme.toLowerCase()}
						className='mx-2 d-flex align-items-center'
						onClick={onNextPageBtnClickHandler}
						disabled={!users.length || users.length < LIMIT}>
						<span>{translations.next}</span>
						&nbsp;
						<BsArrowRightCircleFill className='ico-sm' />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default observer(AllBadgeUsers);

import {action, makeObservable, observable} from 'mobx';
import {Badge} from 'models/badge';
import {User} from 'models/user';

class BadgeService {
	@observable
	public badges: Badge[] = [];

	@observable
	public badge: Badge = {
		text: '',
		backgroundColor: '#77797B',
		textColor: '#ffffff',
		tooltip: '',
		communicationTitle: '',
		communicationPic: '',
		communicationText: '',
	};

	@observable
	public currentBadge: Badge = {
		text: '',
		backgroundColor: '',
		textColor: '',
		tooltip: '',
		communicationTitle: '',
		communicationPic: '',
		communicationText: '',
		picFile: null,
		tooltipLength: 0,
		communicationTitleLength: 0,
		communicationTextLength: 0,
	};

	@observable
	public currentTab = '';

	@observable
	public currentUser: User | null = null;

	@observable
	public users: User[] = [];

	@observable
	public offset = 0;

	@observable
	public isSearch = false;

	@observable
	public search = {
		type: 'name',
		value: '',
	};

	@observable
	public filter = {
		type: 'badgeText',
		value: '',
	};

	@action
	public setOffset = (value: number) => {
		this.offset = value;
	};

	@action
	public setSearch = (value: {type: string; value: string}) => {
		this.search = value;
	};

	@action
	public setFilter = (value: {type: string; value: string}) => {
		this.filter = value;
	};

	@action
	public setBadges = (value: Badge[]) => {
		this.badges = value;
	};

	@action
	public setBadge = (value: Badge) => {
		this.badge = value;
	};

	@action
	public setCurrentBadge = (value: Badge) => {
		this.currentBadge = value;
	};

	@action
	public setUsers = (value: User[]) => {
		this.users = value;
	};

	@action
	public setCurrentTab = (value: string) => {
		this.currentTab = value;
	};

	@action
	public setCurrentUser = (value: User | null) => {
		this.currentUser = value;
	};

	@action
	public setIsSearch = (value: boolean) => {
		this.isSearch = value;
	};

	@action
	public clear = () => {
		this.badge = {
			id: 0,
			text: '',
			backgroundColor: '#77797B',
			textColor: '#ffffff',
			tooltip: '',
			communicationTitle: '',
			communicationPic: '',
			communicationText: '',
		};
		this.currentBadge = {
			text: '',
			backgroundColor: '',
			textColor: '',
			tooltip: '',
			communicationTitle: '',
			communicationPic: '',
			communicationText: '',
			picFile: null,
			tooltipLength: 0,
			communicationTitleLength: 0,
			communicationTextLength: 0,
		};
	};

	constructor() {
		makeObservable(this);
	}
}

export default new BadgeService();

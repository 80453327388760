import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useL10n from 'l10n/useL10n';
import {ShowFrequency} from 'models/enums/ShowEntry.enum';
import offerService from 'store/offerService';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IOffersSettings {
	nextStep: () => void;
	steps: string[];
}

const OffersSettings: FunctionComponent<IOffersSettings> = function OffersSettings({
	nextStep,
	steps,
}) {
	const translations = useL10n();
	const today: Date = new Date();

	const [delay, setDelay] = useState(0);
	const [isDelayed, setIsDelayed] = useState(false);
	const [startTime, setStartTime] = useState<Date>();
	const [endTime, setEndTime] = useState<Date | null>();
	const [validated, setValidated] = useState(false);

	const {appTheme} = useLocalObservable(() => appService);
	const {currentOffer, currentStep, setCurrentOffer, setCurrentStep} = useLocalObservable(
		() => offerService
	);

	const onPrevClickHandler = () => {
		setCurrentStep(currentStep - 1);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		const btnName = e.nativeEvent.submitter.getAttribute('name');
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else setCurrentStep(4);
	};

	const filterPassedTime = (value: any, date?: Date) => {
		const currentDate = date || new Date();
		const selectedDate = new Date(value);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const setStartDate = (date: Date) => {
		setStartTime(date);
		setCurrentOffer({
			...currentOffer,
			startTime: date.toISOString(),
		});
	};

	const setEndDate = (date: Date) => {
		setEndTime(date);
		setCurrentOffer({
			...currentOffer,
			endTime: date.toISOString(),
		});
	};

	const onChangeShowEntryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setCurrentOffer({
			...currentOffer,
			showMultipleTimes: value !== 'showOnce',
		});
	};

	const onChangeFrequencyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setCurrentOffer({
			...currentOffer,
			showFrerquency: value === ShowFrequency.DAILY ? ShowFrequency.DAILY : ShowFrequency.ON_ENTRY,
		});
	};

	const onChangeDelayHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setIsDelayed(value === 'withDelay');

		if (value === 'withDelay')
			setCurrentOffer({
				...currentOffer,
				showDelayMS: 5000,
			});

		if (value === 'onEntry')
			setCurrentOffer({
				...currentOffer,
				showDelayMS: 0,
			});
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setDelay(+value);
		setCurrentOffer({
			...currentOffer,
			showDelayMS: +value,
		});
	};

	useEffect(() => {
		setIsDelayed(currentOffer.showDelayMS !== 0);
		if (currentOffer.showDelayMS) setDelay(currentOffer.showDelayMS);
		if (currentOffer.startTime) setStartTime(new Date(currentOffer.startTime));
		if (currentOffer.endTime) setEndTime(new Date(currentOffer.endTime));
	}, [currentOffer]);

	return (
		<section className='offer__settings'>
			<div>
				<div className='block col-6 mb-3'>
					<p className='h5'>{translations.offers.period.title}</p>
					<p className='text-secondary'>{translations.offers.period.text}</p>
					<Form noValidate validated={validated} onSubmit={handleSubmit} id='offerSettingsForm'>
						<div className='d-flex'>
							<Form.Group className='mb-3' controlId='formDateStart'>
								<DatePicker
									className='form-control'
									selected={startTime}
									placeholderText={translations.offers.from}
									dateFormat='dd.MM.yyyy, HH:mm'
									minDate={today}
									timeInputLabel={`${translations.modals.createRoom.time}:`}
									showTimeSelect
									filterTime={filterPassedTime}
									timeFormat='HH:mm'
									timeIntervals={5}
									timeCaption='time'
									required
									onChange={(date: Date) => setStartDate(date)}
								/>
							</Form.Group>
							<Form.Group className='mx-2' controlId='formDateStart'>
								<DatePicker
									className='form-control'
									selected={endTime}
									placeholderText={translations.offers.to}
									dateFormat='dd.MM.yyyy, HH:mm'
									minDate={startTime}
									timeInputLabel={`${translations.modals.createRoom.time}:`}
									showTimeSelect
									filterTime={value => filterPassedTime(value, startTime)}
									timeFormat='HH:mm'
									timeIntervals={5}
									timeCaption='time'
									onChange={(date: Date) => setEndDate(date)}
								/>
							</Form.Group>
						</div>
					</Form>
				</div>
				<div className='block col-6 mb-5'>
					<div>
						<p className='h5'>{translations.welcome.steps.showEntry.showRepeat}</p>
						<p className='text-secondary'>{translations.offers.contentSettings.multipleTimes}</p>
						<Form.Group controlId='showEntry'>
							<div>
								<Form.Check
									inline
									name='showEntry'
									type='radio'
									label={translations.welcome.steps.showEntry.showOnce}
									id='showEntry1'
									value='showOnce'
									required
									onChange={onChangeShowEntryHandler}
									checked={!currentOffer.showMultipleTimes}
									className='checkbox-black'
								/>
							</div>
							<div>
								<Form.Check
									inline
									name='showEntry'
									type='radio'
									label={translations.welcome.steps.showEntry.showTimes}
									id='showEntry2'
									value='showTimes'
									required
									onChange={onChangeShowEntryHandler}
									checked={currentOffer.showMultipleTimes}
									className='checkbox-black'
								/>
							</div>
						</Form.Group>
					</div>
					{currentOffer.showMultipleTimes && (
						<div className='mt-5'>
							<div className='mb-5'>
								<p className='h5'>{translations.welcome.steps.frequency.title}</p>
								<p className='text-secondary'>{translations.welcome.steps.frequency.descr}</p>
								<Form.Group className='mb-3' controlId='frequency'>
									<div>
										<Form.Check
											inline
											name='frequency'
											type='radio'
											label={translations.welcome.steps.frequency.daily}
											id='frequency1'
											value={ShowFrequency.DAILY}
											required
											onChange={onChangeFrequencyHandler}
											checked={currentOffer.showFrerquency === ShowFrequency.DAILY}
											className='checkbox-black'
										/>
									</div>
									<div>
										<Form.Check
											inline
											name='frequency'
											type='radio'
											label={translations.welcome.steps.frequency.onEntry}
											id='frequency2'
											value={ShowFrequency.ON_ENTRY}
											required
											onChange={onChangeFrequencyHandler}
											checked={currentOffer.showFrerquency === ShowFrequency.ON_ENTRY}
											className='checkbox-black'
										/>
									</div>
								</Form.Group>
							</div>
							<div className='mb-5'>
								<p className='h5'>{translations.welcome.steps.moment.title}</p>
								<p className='text-secondary'>{translations.welcome.steps.moment.descr}</p>
								<Form.Group className='mb-3' controlId='delay'>
									<div>
										<Form.Check
											inline
											name='delay'
											type='radio'
											label={translations.welcome.steps.moment.onEntry}
											id='delay1'
											value='onEntry'
											required
											onChange={onChangeDelayHandler}
											checked={!isDelayed}
											className='checkbox-black'
										/>
									</div>
									<div className='mb-3'>
										<Form.Check
											inline
											name='delay'
											type='radio'
											label={translations.welcome.steps.moment.withDelay}
											id='delay2'
											value='withDelay'
											required
											onChange={onChangeDelayHandler}
											checked={isDelayed}
											className='checkbox-black'
										/>
									</div>
									{isDelayed && (
										<div className='col-3'>
											<p className='text-secondary'>
												{translations.welcome.steps.moment.showDelay}
											</p>
											<Form.Select onChange={onSelect} value={delay}>
												<option value='5000'>5{translations.sec}</option>
												<option value='10000'>10{translations.sec}</option>
												<option value='15000'>15{translations.sec}</option>
											</Form.Select>
										</div>
									)}
								</Form.Group>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='offer__footer'>
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					onClick={onPrevClickHandler}>
					<BsArrowLeftCircleFill />
					&nbsp;&nbsp;
					{translations.welcome.steps.btns.prev}
				</Button>

				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					className='mx-2'
					form='offerSettingsForm'
					type='submit'>
					{translations.welcome.steps.btns.next}
					&nbsp;&nbsp;
					<BsArrowRightCircleFill />
				</Button>

				{/* {currentStep === 4 && (
					<Button
						variant='success'
						className='d-flex mx-2'
						type='button'
						onClick={() => (!slug ? openConfirmAlert() : updateOffer())}>
						{!slug ? translations.btns.send : translations.btns.save}
					</Button>
				)} */}
			</div>
		</section>
	);
};

export default observer(OffersSettings);

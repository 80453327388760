import useL10n from 'l10n/useL10n';
import {BanReasonEnum} from 'models/enums/BanReason.enum';

export default () => {
	const translations = useL10n();

	const reportReasons = [
		{
			text: translations.banReasons.insults,
			type: BanReasonEnum.INSULTS,
		},
		{
			text: translations.banReasons.personal,
			type: BanReasonEnum.PERSONAL,
		},
		{
			text: translations.banReasons.flood,
			type: BanReasonEnum.FLOOD,
		},
		{
			text: translations.banReasons.links,
			type: BanReasonEnum.LINKS,
		},
		{
			text: translations.banReasons.violence,
			type: BanReasonEnum.VIOLENCE,
		},
		{
			text: translations.banReasons.fraud,
			type: BanReasonEnum.FRAUD,
		},
		{
			text: translations.banReasons.nickname,
			type: BanReasonEnum.NICKNAME,
		},
		{
			text: translations.banReasons.political,
			type: BanReasonEnum.POLITICAL,
		},
		{
			text: translations.banReasons.repeated,
			type: BanReasonEnum.REPEATED,
		},
		{
			text: translations.banReasons.spam,
			type: BanReasonEnum.SPAM,
		},
		{
			text: translations.banReasons.auto,
			type: BanReasonEnum.AUTO,
		},
	];

	const getReasons = () => {
		return reportReasons;
	};

	const getReason = (type: number) => {
		return reportReasons.find(el => el.type === type);
	};

	return {
		getReasons,
		getReason,
	};
};

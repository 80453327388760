import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import useL10n from 'l10n/useL10n';
import {Modal, Image, Badge, Form, InputGroup, Button, Spinner, Alert} from 'react-bootstrap';
import modalServices from 'store/modalServices';
import appService from 'store/appService';
import useBadge from 'hooks/useBadge';
import badgeService from 'store/badgeService';
import {Theme} from 'models/enums/Theme.enum';
import {BsPersonXFill} from 'react-icons/bs';

const BadgesModal: FunctionComponent = function BadgesModal() {
	const translations = useL10n();

	const [userID, setUserID] = useState('');
	const [visibleLoader, setVisibleLoader] = useState(false);
	const [warning, setWarning] = useState(false);

	const {badgesModalVisible, toggleBadgesModalVisible} = useLocalObservable(() => modalServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {badges, currentUser, setCurrentUser, isSearch, setIsSearch} = useLocalObservable(
		() => badgeService
	);

	const {deleteUserBadge, getUserById, addUserBadge, getBadges} = useBadge();

	const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>, badgeId?: number) => {
		const {value, checked} = event.target;

		if (badgeId && !checked && currentUser) await deleteUserBadge(badgeId, currentUser.id, true);
		if (badgeId && checked && currentUser) await addUserBadge(badgeId, currentUser.id, true);
		if (currentUser?.externalId) getUserById(currentUser.externalId);
	};

	const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		value = value.replace(/[а-яА-ЯЁё]/, '').trim();
		setUserID(value);
		setWarning(false);
		if (!value) setCurrentUser(null);
	};

	const onSearchClickHandler = async () => {
		if (userID) {
			setVisibleLoader(true);
			await getUserById(userID);
			setVisibleLoader(false);
			setWarning(true);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearchClickHandler();
		}
	};

	const onShow = () => {
		getBadges();
	};

	const onHide = () => {
		toggleBadgesModalVisible(false);
		setCurrentUser(null);
		setUserID('');
		setIsSearch(false);
	};

	return (
		<Modal
			className='modal--badges'
			show={badgesModalVisible}
			onShow={onShow}
			onHide={onHide}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton className='text-primary px-0 mb-2'>
				<Modal.Title className={appTheme === Theme.LIGHT ? 'text-dark' : 'text-light'}>
					{' '}
					{currentUser ? translations.modals.badges.title : translations.modals.badges.giveBadge}
				</Modal.Title>
			</Modal.Header>
			{isSearch && (
				<InputGroup className='mb-3'>
					<Form.Control
						placeholder={translations.modals.addTalker.inputPlaceholder}
						value={userID}
						onChange={onChangeInputHandler}
						required
						onKeyPress={handleKeyPress}
					/>
					<Button
						variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
						className='px-4'
						onClick={onSearchClickHandler}>
						{translations.btns.search}
					</Button>
				</InputGroup>
			)}
			<Modal.Body>
				{isSearch && (
					<div>
						{visibleLoader && (
							<div className='text-center'>
								<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
							</div>
						)}
						{!visibleLoader && (
							<div>
								{currentUser === null && userID && warning && (
									<div className='d-flex align-items-center'>
										<BsPersonXFill className='flex-shrink-0 text-danger h1 m-3 mx-4' />
										<div>
											<p className='mb-1'>
												<b className='text-danger'>{translations.modals.addTalker.noUsers}</b>
											</p>
										</div>
									</div>
								)}
							</div>
						)}

						{!currentUser && !warning && (
							<p className='text-secondary text-center p-3'>{translations.modals.badges.text}</p>
						)}
					</div>
				)}
				{currentUser && (
					<div className=''>
						<div className='user-info mb-3'>
							<div
								className={`user-info__photo user-info__photo--sm background-color-${
									currentUser.color || 0
								}`}>
								<Image src={currentUser.pic} roundedCircle fluid />
							</div>
							<div className='text-truncate'>
								<div className='text-truncate'>{currentUser.name}</div>
								<Badge bg={appTheme.toLowerCase()} pill>
									id{currentUser.id}
								</Badge>
								<Badge bg={appTheme.toLowerCase()} pill>
									ext:
									{currentUser.externalId}
								</Badge>
							</div>
						</div>
						<div>
							{badges?.map(el => {
								return (
									<Form.Check
										key={el.id}
										checked={currentUser.badges?.find(badge => badge.id === el.id) !== undefined}
										onChange={(e: any) => onChangeHandler(e, el.id)}
										type='switch'
										id={`badge${el.id}`}
										label={el.text}
									/>
								);
							})}
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(BadgesModal);

import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Tabs, Tab, Alert} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useAutoposting from 'hooks/useAutoposting';
import Table from './components/autoposting/Table';
import AutopostingSettings from './components/autoposting/AutopostingSettings';
import AutopostingFile from './components/autoposting/AutopostingFile';

const Autoposting: FunctionComponent = function Autoposting() {
	const translations = useL10n();
	const [key, setKey] = useState('settings');

	const [isCustomMessagesList, setIsCustomMessageList] = useState(false);
	const {getMessage} = useAutoposting();

	const getMessageWithService = async () => {
		const response = await getMessage();
		if (response.length) {
			setIsCustomMessageList(true);
			return;
		}
		setIsCustomMessageList(false);
	};

	useEffect(() => {
		if (key === 'settings') getMessageWithService();
	}, [key]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.autoposting}</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h3 mb-5'>{translations.autoposting.title}</p>
					<Tabs
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						defaultActiveKey='settings'
						id='autoposting-tab'
						className='nav-tabs mb-3'
						variant='dark'>
						<Tab
							eventKey='settings'
							title={translations.autoposting.settings.tabTitle}
							tabClassName='text-secondary'>
							<AutopostingSettings isCustomMessagesList={isCustomMessagesList} />
						</Tab>
						<Tab
							eventKey='defaultList'
							title={translations.autoposting.defaultList}
							tabClassName='text-secondary'>
							<div className='block'>{key === 'defaultList' && <Table currentTab={key} />}</div>
						</Tab>
						<Tab
							eventKey='customList'
							title={translations.autoposting.customList}
							tabClassName='text-secondary'>
							<AutopostingFile />
							<div className='block'>{key === 'customList' && <Table currentTab={key} />}</div>
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Autoposting);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Container, Row, Col, Breadcrumb, Button, Spinner, Table, Badge} from 'react-bootstrap';
import {BsTrashFill, BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import Moment from 'react-moment';

import userServices from 'store/userServices';
import alertServices from 'store/alertServices';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {LIMIT} from 'constants/constants';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import PageTitle from 'components/pageTitle/PageTitle';
import offerService from 'store/offerService';
import OfferService from 'services/api/OfferService';
import {Offer} from 'models/offer';

const Offers: FunctionComponent = function Offers() {
	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {setCurrentOffer, setCurrentStep} = useLocalObservable(() => offerService);
	const {appTheme} = useLocalObservable(() => appService);

	const [offers, setOffers] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [allowClick, setAllowClick] = useState(true);
	const [localOffset, setLocalOffset] = useState(0);

	const history = useHistory();

	const getOffers = async (offset: number) => {
		setVisiblePreloader(true);
		setAllowClick(false);
		const response = await OfferService.getOffers(accessToken, LIMIT, offset);
		setVisiblePreloader(false);
		setAllowClick(true);
		if (response.status === ResponseStatus.SUCCESS) {
			setOffers(response.data);
		}
	};

	const onNextPageBtnClickHandler = () => {
		setLocalOffset(localOffset + LIMIT);
		getOffers(localOffset + LIMIT);
	};

	const onPrevPageBtnClickHandler = () => {
		if (localOffset - LIMIT >= 0) {
			setLocalOffset(localOffset - LIMIT);
			getOffers(localOffset - LIMIT);
		}
	};

	const createOffer = () => {
		const draft = localStorage.getItem('offerDraft');
		if (draft) {
			showAlert({
				title: translations.alerts.restore,
				text: translations.alerts.restoreText,
				buttons: [
					{
						text: translations.alerts.btns.startOver,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
							history.push('/offers/create');
							localStorage.removeItem('offerDraft');
						},
					},
					{
						type: AlertBtnType.SUCCESS,
						text: translations.alerts.btns.restore,
						onClick: () => {
							hideAlert();
							setCurrentOffer(JSON.parse(draft));
							setCurrentStep(2);
							history.push('/offers/create');
						},
					},
				],
			});
		} else history.push('/offers/create');
	};

	const editOffer = (offerId: number) => {
		history.push(`/offers/edit/${offerId}`);
	};

	const deleteOffer = async (offerId: number) => {
		const response = await OfferService.deleteOffer(accessToken, offerId);
		if (response.status === ResponseStatus.SUCCESS) {
			getOffers(localOffset);
		}
	};

	const openAlert = (offer: Offer) => {
		showAlert({
			title: translations.btns.remove,
			text: `${translations.btns.remove}?`,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deleteOffer(offer.id);
					},
				},
			],
		});
	};

	useEffect(() => {
		accessToken && getOffers(localOffset);
	}, [accessToken]);

	const renderOffer = useCallback(
		(item: Offer, index: number) => {
			return (
				<tr key={index}>
					<td className='align-middle'>
						<p className='text-truncate'>{item.title}</p>
					</td>
					<td className='text-secondary text-truncate align-middle'>{item.text}</td>
					<td className='text-center text-secondary align-middle'>
						<Moment local format='DD.MM.YY HH:mm'>
							{item.startTime}
						</Moment>
					</td>
					<td className='text-center text-secondary align-middle'>
						{item.endTime && (
							<Moment local format='DD.MM.YY HH:mm'>
								{item.endTime}
							</Moment>
						)}
					</td>
					<td className='text-center align-middle col-1'>
						<Button variant='secondary' size='sm' onClick={() => editOffer(item.id)}>
							{translations.offers.edit}
						</Button>
						&nbsp;
						<Button variant='danger' size='sm' onClick={() => openAlert(item)}>
							<BsTrashFill />
						</Button>
					</td>
				</tr>
			);
		},
		[offers]
	);

	return (
		<Container fluid className='pt-4 highlights'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.offers.title}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>

			<PageTitle
				title={translations.sidebar.offers.title}
				text={translations.sidebar.offers.descr}
			/>

			<div className='d-flex w-100 justify-content-between mb-3'>
				<div>
					<Button variant='dark' onClick={createOffer}>
						{translations.offers.create}
					</Button>
				</div>
				<div className='d-flex'>
					<Button
						variant='dark'
						className='d-flex'
						onClick={onPrevPageBtnClickHandler}
						disabled={localOffset === 0 || !allowClick}>
						<BsArrowLeftCircleFill />
					</Button>
					&nbsp;
					<Button
						variant='dark'
						className='d-flex'
						onClick={onNextPageBtnClickHandler}
						disabled={!offers.length || offers.length < LIMIT || !allowClick}>
						<BsArrowRightCircleFill />
					</Button>
				</div>
			</div>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{offers.length > 0 ? (
				<Table
					responsive
					striped={appTheme === Theme.LIGHT}
					bordered
					hover
					variant={appTheme?.toLowerCase()}>
					<thead>
						<tr>
							<th className='align-middle'>
								<b>{translations.offers.title}</b>
							</th>
							<th className='align-middle'>
								<b>{translations.offers.text}</b>
							</th>
							<th className='col-2 text-center align-middle'>
								<b>{translations.offers.startTime}</b>
							</th>
							<th className='col-2 text-center align-middle'>
								<b>{translations.offers.endTime}</b>
							</th>
							<th className='col-2 text-center align-middle'> </th>
						</tr>
					</thead>
					<tbody>{offers.map((elem, index) => renderOffer(elem, index))}</tbody>
				</Table>
			) : (
				!visiblePreloader && <p>{translations.empty.empty}</p>
			)}
		</Container>
	);
};

export default observer(Offers);

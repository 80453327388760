import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';

interface IAuthRoutes {
	children: React.ReactNode;
	path: string;
	exact?: boolean;
}

const AuthRoutes: React.FC<IAuthRoutes> = function AuthRoutes({children, path, exact = false}) {
	const {setAccessToken} = useLocalObservable(() => userServices);
	const userToken = localStorage.getItem('accessToken');

	useEffect(() => {
		if (userToken) {
			setAccessToken(userToken);
		}
	}, []);

	return (
		<Route
			exact={exact}
			path={path}
			render={({location}) =>
				userToken && userToken !== '' ? (
					<Redirect
						to={{
							pathname: '/',
							state: {from: location},
						}}
					/>
				) : (
					children
				)
			}
		/>
	);
};

export default observer(AuthRoutes);

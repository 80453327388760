import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form, FloatingLabel, Button} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';

const Amplitude: FunctionComponent = function Amplitude() {
	const translations = useL10n();

	const [apikey, setApikey] = useState('');
	const [validated, setValidated] = useState(false);

	const {settings} = useLocalObservable(() => settingsServices);
	const {patchSettings} = useSettings();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setApikey(value);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else {
			patchSettings({
				amplitudeApiKey: apikey,
			});
		}
	};

	useEffect(() => {
		settings?.amplitudeApiKey && setApikey(settings?.amplitudeApiKey);
	}, [settings]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.amplitude}</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h3 mb-5'>{translations.sidebar.amplitude}</p>
					<div className='block w-50'>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<FloatingLabel
								controlId='apikey'
								label='apikey'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									name='apikey'
									placeholder='apikey'
									value={apikey}
									onChange={onChangeHandler}
									required
								/>
							</FloatingLabel>
							<Button type='submit'>{translations.btns.save}</Button>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Amplitude);

import {SIZE_ICON_IMG, SIZE_ICON_SVG} from 'constants/constants';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {NamedPicType} from 'models/enums/NamedPicType.enum';
import {FunctionComponent, useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import {getFileSize} from 'utils/helpers';
import NamedPic from 'pages/main/components/settings/appearence/NamedPic';
import settingsServices from 'store/settingsServices';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IIcons {
	currentTab: string;
}

const Icons: FunctionComponent<IIcons> = function Icons({currentTab}) {
	const translations = useL10n();
	const {getIcons} = useSettings();
	const {theme, currentThemeId, icons, setIcons} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const getIconsWithServices = async () => {
		setVisiblePreloader(true);
		currentThemeId && (await getIcons(currentThemeId));
		setVisiblePreloader(false);
	};

	useEffect(() => {
		currentTab === NamedPicType.ICON && getIconsWithServices();
	}, [theme, currentThemeId]);

	useEffect(() => {
		currentTab === NamedPicType.ICON && getIconsWithServices();
		return () => {
			setIcons([]);
		};
	}, [currentTab]);

	return (
		<div className='block mb-5'>
			<p className='h5 mb-5'>{translations.settings.icons}</p>
			<div className='mb-3 col-6'>
				<p>
					<small>{translations.highlights.iconWeight(getFileSize(204800))}</small>
					<small>{translations.highlights.iconMinSize(SIZE_ICON_IMG, SIZE_ICON_SVG)}</small>
				</p>
				<p>
					<small>{translations.imageReqs.formats('.png, .svg')}</small>
				</p>
			</div>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{icons.length > 0 ? (
				<div className='settings__namedPics settings__namedPics--icons'>
					{icons.map((elem, index) => {
						return (
							<NamedPic
								index={index}
								elem={elem.pic}
								name={elem.name}
								payload='pic'
								id={elem.id}
								key={elem.id}
								type={NamedPicType.ICON}
								showName
								editModeOnly
								acceptedFileTypes='.png, .svg'
								objectMinSize={{width: SIZE_ICON_IMG, height: SIZE_ICON_IMG}}
								size={204800}
							/>
						);
					})}
				</div>
			) : (
				!visiblePreloader && <p>{translations.empty.iconsEmpty}</p>
			)}
		</div>
	);
};

export default observer(Icons);

import {ResponseStatusCode} from 'models/enums/Network.enums';

type Obj = Record<string, unknown>;

const checkResponse = (data: Obj, status: number) => {
	if (status === ResponseStatusCode.Unauthorized) {
		throw Error(`Server returned status code ${status}`);
	}

	if (status === ResponseStatusCode.Forbidden) {
		throw Error(`Server returned status code ${status}`);
	}

	if (status > ResponseStatusCode.BadRequest) {
		throw Error(`Server returned status code ${status}`);
	}
};

export default checkResponse;

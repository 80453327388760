import {LIMIT} from 'constants/constants';
import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import ImageService from 'services/api/ImagesService';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);

	const getImages = async (limit?: number, offset?: number) => {
		const response = await ImageService.getImages(accessToken, limit || LIMIT, offset || 0);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};
	return {
		getImages,
	};
};

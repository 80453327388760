/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {useParams, NavLink} from 'react-router-dom';
import {BsGearFill, BsLink} from 'react-icons/bs';

import {TALKER_STUB} from 'constants/constants';

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Spinner,
	Button,
	FloatingLabel,
	Form,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import RoomService from 'services/api/RoomService';

import userServices from 'store/userServices';
import messagesServices from 'store/messagesServices';
import toastServices from 'store/toastServices';
import alertServices from 'store/alertServices';
import modalServices from 'store/modalServices';
import roomServices from 'store/roomServices';
import appService from 'store/appService';

import {Message, Room, Talker} from 'models/room';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import MessageType from 'models/enums/MessageType';

import Chat from 'components/chat/Chat';
import ChatAdminForm from 'components/chat/ChatAdminForm';
import RoomTalkersModal from 'components/modals/roomTalkers/RoomTalkersModal';
import ChatThrottling from 'components/chat/ChatThrottling';

import useL10n from 'l10n/useL10n';
import RoomAgoraVoiceModal from 'components/modals/roomAgoraVoiceModal/roomAgoraVoiceModal';
import HighlightsService from 'services/api/HighlightsService';
import highlightsServices from 'store/highlightsServices';
import RoomSettingsModal from 'components/modals/roomSettings/RoomSettingsModal';
import RoomStatus from 'models/enums/RoomStatus.enum';
import RoomRecordModal from 'components/modals/roomRecord/RoomRecordModal';
import AddTalkerModal from 'components/modals/addTalkerModal/AddTalkerModal';
import settingsServices from 'store/settingsServices';
import UserService from 'services/api/UserService';
import SettingsService from 'services/api/SettingsService';
import {Theme} from 'models/enums/Theme.enum';
import useRoom from 'hooks/useRoom';
import CreateRoomModal from 'components/modals/createRoom/CreateRoomModal';
import UploadVideoModal from 'components/modals/uploadVideoModal/UploadVideoModal';
import StreamSettingsModal from 'components/modals/streamSettingsModal/StreamSettingsModal';
import configService from 'store/configService';

const LIMIT = 50;

const ChatRoom: FunctionComponent = function ChatRoom() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const {projectApiKey, setProjectApiKey, appTheme, projectId} = useLocalObservable(
		() => appService
	);
	const {
		messages,
		setMessages,
		addMessages,
		addMessagesNext,
		socketMessages,
		setSocketMessages,
		ignoreAutoposted,
	} = useLocalObservable(() => messagesServices);
	const {accessToken, chatUserId, setChatUserId, adminData} = useLocalObservable(
		() => userServices
	);
	const {addToast} = useLocalObservable(() => toastServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {
		showRoomTalkersModal,
		toggleRoomSettingsModalVisible,
		toggleRoomRecordModalVisible,
		toggleAddTalkerModalVisible,
		toggleUploadVideoModalVisible,
	} = useLocalObservable(() => modalServices);
	const {
		talkers,
		setTalkers,
		addTalker,
		removeTalker,
		setCurrentRoomId,
		status,
		setStatus,
		setIsSpeak,
		isSpeak,
		currentMessageId,
		setCurrentMessageId,
		setIsSlowmode,
		setSlowmodeTimeout,
	} = useLocalObservable(() => roomServices);
	const {setHighlightPics} = useLocalObservable(() => highlightsServices);
	const {settings} = useLocalObservable(() => settingsServices);
	const {hideImages} = useLocalObservable(() => messagesServices);
	const {chatUrl} = useLocalObservable(() => configService);

	const [room, setRoom] = useState<Room | null>(null);
	const [messagesLoaded, setMessagesLoaded] = useState(false);
	const [iframeShown, setIframeShown] = useState<boolean>(false);
	const [scrollToBottom, setScrollToBottom] = useState(false);
	const [nickname, setNickname] = useState('');
	const [image, setImage] = useState(
		'https://storage.yandexcloud.net/matchtv/4e874821-3ac0-447c-ac2e-e79c15c921d5.jpeg'
	);

	const translations = useL10n();
	const {getPinnedMessage} = useRoom();

	const getRoom = async () => {
		if (accessToken) {
			const response = await RoomService.getRoom(slug, accessToken);
			setRoom(response.data.room);
			setStatus(response.data.room.status);
			setIsSpeak(response.data.room.isSpeak);
			setIsSlowmode(response.data.room.isSlowmode);
			response.data.room.slowmodeDelayMS !== 0 &&
				setSlowmodeTimeout(response.data.room.slowmodeDelayMS);
			setTalkers(response.data.talkers);
			setCurrentRoomId(decodeURIComponent(response.data.room.externalRoomId));
		}
	};

	const getAroundMessages = async (messageId: number) => {
		if (room && messageId) {
			const response = await RoomService.getAroundMessages({
				token: accessToken,
				externalRoomId: room?.externalRoomId || slug,
				messageId,
				ignoreAutoposted: Number(ignoreAutoposted),
				hideImages,
			});
			if (response.status === ResponseStatus.SUCCESS) {
				setMessages(
					response.data
						.filter(
							(el: Message) =>
								(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
						)
						.map((msg: any) => {
							const isUnreadMessage = socketMessages.find(el => el.id === msg.id);
							if (msg.talker) {
								return isUnreadMessage ? {...msg, isUnread: true} : msg;
							}
							return isUnreadMessage
								? {...msg, talker: TALKER_STUB, isUnread: true}
								: {...msg, talker: TALKER_STUB};
						})
				);
				setMessagesLoaded(true);
			}
		}
	};

	const getRoomMessages = async () => {
		if (room) {
			const response = await RoomService.getRoomMessages({
				token: accessToken,
				externalRoomId: room.externalRoomId,
				limit: LIMIT,
				ignoreAutoposted: Number(ignoreAutoposted),
				hideImages,
			});

			if (response.status === ResponseStatus.SUCCESS) {
				setMessages(
					response.data
						.filter(
							(el: Message) =>
								(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
						)
						.map((msg: any) => {
							if (msg.talker) {
								return msg;
							}
							return {...msg, talker: TALKER_STUB};
						})
				);
				setMessagesLoaded(true);
				setSocketMessages([]);
			}
		}
	};

	const getPreviousMessages = async () => {
		const response = await RoomService.getRoomMessages({
			token: accessToken,
			externalRoomId: room?.externalRoomId || slug,
			limit: LIMIT,
			type: {
				name: 'lastId',
				id: messages[0].id,
			},
			ignoreAutoposted: Number(ignoreAutoposted),
			hideImages,
		});
		if (response.status === ResponseStatus.SUCCESS) {
			addMessages(
				response.data.filter(
					(el: Message) =>
						(el.type && el.type !== MessageType.GAMBLE && el.type !== MessageType.BET) || el.text
				)
			);
		}
		return response.status;
	};

	const getNextMessages = async () => {
		const response = await RoomService.getRoomMessages({
			token: accessToken,
			externalRoomId: room?.externalRoomId || slug,
			limit: LIMIT,
			type: {
				name: 'firstId',
				id: messages[messages.length - 1].id,
			},
			ignoreAutoposted: Number(ignoreAutoposted),
			hideImages,
		});
		if (response.status === ResponseStatus.SUCCESS) {
			addMessagesNext(
				response.data
					.filter(
						(el: Message) =>
							(el.type && el.type !== MessageType.GAMBLE && el.type !== MessageType.BET) || el.text
					)
					.map((msg: any) => {
						if (msg.talker) {
							return msg;
						}
						return {...msg, talker: TALKER_STUB};
					})
			);
		}
		return {
			status: response.status,
			data: response.data,
		};
	};

	const setRoomStatus = async (value: string) => {
		const response = await RoomService.setRoomStatus(
			accessToken,
			room?.externalRoomId || slug,
			value
		);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
			return;
		}
		if (value === RoomStatus.ENDED) getRoom();
	};

	const addTalkerBySocket = (talker: Talker) => {
		addTalker(talker);
	};

	const removeTalkerBySocket = (talker: Talker) => {
		removeTalker(talker);
	};

	const onChangeRoomStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === RoomStatus.ENDED)
			showAlert({
				title: translations.toasts.room(slug),
				text: translations.toasts.endRoom,
				buttons: [
					{
						text: translations.alerts.btns.cancel,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
					{
						text: translations.alerts.btns.yes,
						type: AlertBtnType.DANGER,
						onClick: () => {
							setRoomStatus(value);
							hideAlert();
						},
					},
				],
			});
		else setRoomStatus(value);
	};

	const textEnding = (number: number, txt: string[]) => {
		const cases = [2, 0, 1, 1, 1, 2];
		return txt[
			number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
		];
	};

	const openTalkersModal = async () => {
		await getRoom();
		showRoomTalkersModal();
	};

	const getHighlightPics = async () => {
		const response = await HighlightsService.getHighlightPics(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setHighlightPics(response.data);
		}
	};

	const getApiKey = async () => {
		const response = await SettingsService.getApiKey(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setProjectApiKey(response.data);
		}
	};

	const encryptChatUserId = async (userId: string) => {
		if (userId && accessToken) {
			const response = await UserService.encryptChatUserId(accessToken, userId);

			if (response.status === ResponseStatus.SUCCESS) {
				return response.data;
			}
			return false;
		}
		return false;
	};

	const getChatUrl = () => {
		let url = `${chatUrl}/?roomId=${slug}&userId=${chatUserId}&apikey=${projectApiKey}`;
		if (settings.enableRegistrationName) url = `${url}&nickname=${nickname}`;
		if (settings.enableRegistrationPicUrl && projectId.includes('matchtv'))
			url = `${url}&image=${image}`;
		return url;
	};

	const generateChatLink = async () => {
		let url = `${chatUrl}/?roomId=${slug}&apikey=${projectApiKey}`;
		if (settings.enableRegistrationName) url = `${url}&nickname=${nickname}`;
		if (settings.enableRegistrationPicUrl && projectId.includes('matchtv'))
			url = `${url}&image=${image}`;
		const userId = Date.now().toString();
		if (settings.enableDecrypt) {
			const encryptedUserId = await encryptChatUserId(userId);
			if (encryptedUserId) {
				window.open(`${url}&userId=${encodeURIComponent(encryptedUserId.encrypted)}`, '_blank');
				return;
			}
		}
		window.open(`${url}&userId=${userId}`, '_blank');
	};

	useEffect(() => {
		if (adminData) {
			setNickname(adminData.name);
			setChatUserId(adminData.externalId);
		}
	}, [adminData]);

	useEffect(() => {
		if (accessToken) {
			!projectApiKey && getApiKey();
			getHighlightPics();
		}
	}, [accessToken]);

	useEffect(() => {
		setMessages([]);
	}, [accessToken]);

	useEffect(() => {
		getRoom();
		getPinnedMessage(slug);
		return () => {
			setCurrentRoomId(null);
			setStatus('');
			setIsSpeak(true);
			setIsSlowmode(false);
			setSlowmodeTimeout(1000);
			setTalkers([]);
			setCurrentMessageId(null);
		};
	}, [slug, accessToken]);

	useEffect(() => {
		if (accessToken && room) {
			currentMessageId ? getAroundMessages(currentMessageId) : getRoomMessages();
		}
	}, [room, accessToken, ignoreAutoposted, hideImages]);

	useEffect(() => {
		if (!iframeShown) {
			getRoomMessages();
			setScrollToBottom(true);
		}
	}, [iframeShown]);

	return (
		<Container fluid>
			{room && (
				<Container fluid className='pt-3 text-lg room'>
					<Row>
						<Col md={4} className='d-flex align-items-center'>
							<Breadcrumb>
								<Breadcrumb.Item className='text-truncate' active>
									<NavLink to='/rooms'>{translations.sidebar.rooms.title}</NavLink>
								</Breadcrumb.Item>
								<Breadcrumb.Item className='text-truncate breadcrumb-item-name' active>
									<span>{translations.room.title}</span>
									<span> {room.externalRoomId}</span>
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
						<Col md={8} className='d-flex justify-content-end align-items-unset'>
							{(room.record || room.audioRecord || room.videoRecord) && (
								<Button
									type='button'
									variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
									onClick={() => toggleRoomRecordModalVisible(true)}
									className='mx-2'>
									{translations.room.listenStreamRecording}
								</Button>
							)}

							{talkers.length > 0 ? (
								<Button
									variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
									onClick={openTalkersModal}
									className='mx-2'>
									{talkers.length} {textEnding(talkers.length, translations.textEnding)}
								</Button>
							) : (
								<p className='m-0'>{translations.empty.usersEmpty}</p>
							)}

							<Button
								variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
								onClick={() => toggleUploadVideoModalVisible(true)}>
								{translations.btns.uploadStreamVideo}
							</Button>

							<Button
								variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
								onClick={() => toggleAddTalkerModalVisible(true)}
								className='mx-2'>
								{translations.btns.add}
							</Button>

							<div className='d-flex'>
								<FloatingLabel controlId='floatingSelect' label={translations.room.status.title}>
									<Form.Select onChange={onChangeRoomStatus} value={status}>
										<option value={RoomStatus.LIVE}>{translations.room.status.live}</option>
										<option value={RoomStatus.SOON}>{translations.room.status.soon}</option>
										<option value={RoomStatus.DISABLED}>{translations.room.status.disabled}</option>
										<option value={RoomStatus.ENDED}>{translations.room.status.ended}</option>
									</Form.Select>
								</FloatingLabel>
								&nbsp;
								{messages.length > 0 && projectApiKey && (
									<Button
										type='submit'
										variant={appTheme === Theme.LIGHT ? 'dark' : 'outline-light'}
										onClick={() => setIframeShown(!iframeShown)}>
										{translations.btns.switchChat}
									</Button>
								)}
								&nbsp;
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>{translations.room.generateChatLink}</Tooltip>
									}>
									<Button
										className='d-flex align-items-center'
										variant='dark'
										onClick={generateChatLink}>
										<BsLink className='h4 m-0' />
									</Button>
								</OverlayTrigger>
								&nbsp;
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>{translations.btns.roomSettings}</Tooltip>
									}>
									<Button
										variant='dark'
										className='d-flex align-items-center'
										onClick={() => toggleRoomSettingsModalVisible(true)}>
										<BsGearFill className='h4 m-0' />
									</Button>
								</OverlayTrigger>
							</div>
						</Col>
					</Row>
				</Container>
			)}
			{!messagesLoaded || !projectApiKey ? (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			) : (
				!iframeShown && (
					<div className='chat-room'>
						{!iframeShown && <ChatThrottling />}
						<Chat
							room={decodeURIComponent(slug)}
							scrollToBottom={scrollToBottom}
							getPreviousMessages={getPreviousMessages}
							getNextMessages={getNextMessages}
							getLastMessages={getRoomMessages}
							getAroundMessages={getAroundMessages}
							addTalker={(talker: Talker) => addTalkerBySocket(talker)}
							removeTalker={(talker: Talker) => removeTalkerBySocket(talker)}
						/>
						<ChatAdminForm room={slug} />
					</div>
				)
			)}
			{iframeShown && (
				<div className='chat chat--iframe'>
					<iframe
						title='chat'
						src={getChatUrl()}
						frameBorder='0'
						width='100%'
						height='100%'
						allow='clipboard-read; clipboard-write; microphone'
					/>
				</div>
			)}
			<RoomTalkersModal talkers={talkers} externalRoomId={room?.externalRoomId} />
			<RoomAgoraVoiceModal externalRoomId={room?.externalRoomId} />
			<RoomSettingsModal room={room} slug={slug} />
			<RoomRecordModal room={room} />
			<AddTalkerModal externalRoomId={room?.externalRoomId} />
			<CreateRoomModal mode='edit' editedRoom={room} onCloseSuccess={getRoom} />
			<UploadVideoModal externalRoomId={room?.externalRoomId} />
			<StreamSettingsModal />
		</Container>
	);
};

export default observer(ChatRoom);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {debounce} from 'lodash';

import {getParent, containsClass} from 'utils/helpers';

import {Spinner, Badge} from 'react-bootstrap';

import SocketIoService from 'services/SocketIoService';

import messagesServices from 'store/messagesServices';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';

import {Message, Talker} from 'models/room';
import MessageType from 'models/enums/MessageType';
import MessagesStreamType from 'models/enums/MessagesStream.enum';

import ChatMessage from 'components/chat/ChatMessage';
import CreateBanModal from 'components/modals/createBan/CreateBanModal';

import useChat from 'hooks/useChat';
import useSocketHandlers from 'hooks/useSocketHandlers';

import classNames from 'classnames';

import useL10n from 'l10n/useL10n';

import {TALKER_STUB} from 'constants/constants';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import EditedMessageHistoryModal from 'components/modals/editedMessageHistoryModal/EditedMessageHistoryModal';
import ReactionsModal from 'components/modals/reactionsModal/reactionsModal';
import ImageGalleryModal from 'components/modals/imageGallery/ImageGalleryModal';
import ChatPinnedMessage from './ChatPinnedMessage';

interface IChat {
	room?: string;
	scrollToBottom?: boolean;
	getPreviousMessages: () => Promise<'success' | 'error'>;
	getNextMessages?: () => Promise<{status: 'success' | 'error'; data: any}>;
	getLastMessages?: () => Promise<void>;
	getAroundMessages?: (id: number) => Promise<void>;
	addTalker?: (talker: Talker) => void;
	removeTalker?: (talker: Talker) => void;
	isLastMessages?: boolean;
	isUserMessages?: boolean;
}

const Chat: FunctionComponent<IChat> = function Chat({
	room,
	scrollToBottom,
	getPreviousMessages,
	getNextMessages,
	getLastMessages,
	getAroundMessages,
	addTalker,
	removeTalker,
	isLastMessages,
	isUserMessages,
}) {
	const {accessToken} = useLocalObservable(() => userServices);
	const {
		messagesQueue,
		setQueue,
		throttling,
		isThrottling,
		getMessages,
		addQueueToMessages,
		throttlingDelay,
		shiftQueue,
		addMessage,
		setIsThrottling,
		split,
		chatScrollPosition,
		setChatScrollPosition,
		stream,
		setStream,
		addSocketMessage,
		socketMessages,
		setSocketMessages,
		setThrottling,
		lastViewedMessageId,
		getLastViewedMessageId,
		setLastViewedMessageProjects,
		setLastViewedMessageId,
		setIsNextMessages,
		isNextMessages,
	} = useLocalObservable(() => messagesServices);
	const {currentMessageId, setCurrentMessageId, setActivePinnedMessage} = useLocalObservable(
		() => roomServices
	);
	const {scrollToMessage, checkScrollTop, visibleMessageElements} = useChat();
	const {
		onUserUpdatedHandler,
		onMessagedDeletedHandler,
		onMessageDeletedHandler,
		onMessageHiddenHandler,
		onMessagesHiddenHandler,
		onMessageEditedHandler,
		onTalkerBanSetHandler,
		onUserBanSetHandler,
		onMuteSetHandler,
		onModerSetHandler,
		onRoleSetHandler,
		onRoomStatusSetHandler,
		onRoomSpeakSetHandler,
		onRoomSlowmodeSetHandler,
		onBroadcastHandler,
		onMessagePinHandler,
		onMessageUnPinHandler,
	} = useSocketHandlers();

	const {
		submenuMessage,
		submenuAvatar,
		setSubmenuMessage,
		setSubmenuMessagePinned,
		setSubmenuAvatar,
		pinnedMessages,
		activePinnedMessage,
	} = useLocalObservable(() => roomServices);
	const {projectId, appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [lastViewedMessage, setLastViewedMessage] = useState<number | null>(null);
	const [newMessagesCount, setNewMessagesCount] = useState<number>(0);
	const [scrollToLastMessage, setScrollToLastMessage] = useState(false);
	const chatScrollRef = useRef<HTMLDivElement>(null);
	const throttlingTimerRef: {current: NodeJS.Timeout | null} = useRef(null);

	const translations = useL10n();

	const visibleMessageSubmenu = (messageId: number) => {
		if (submenuMessage === messageId) {
			return true;
		}
		return false;
	};

	const visibleAvatarSubmenu = (messageId: number) => {
		if (submenuAvatar === messageId) {
			return true;
		}
		return false;
	};

	const visibleNewMessagesLabel = (messageId: number) => {
		if (
			lastViewedMessageId &&
			messageId === +lastViewedMessageId &&
			messageId !== getMessages[getMessages.length - 1].id
		)
			return true;
		return false;
	};

	const renderMessage = useCallback(
		(message: Message, index: number) => {
			return (
				<ChatMessage
					key={index}
					message={message}
					highlightMessage={message.advertisement}
					visibleMessageSubmenu={visibleMessageSubmenu(message.id)}
					visibleAvatarSubmenu={visibleAvatarSubmenu(message.id)}
					{...visibleMessageElements(index, message, getMessages)}
					visibleNewMessagesLabel={visibleNewMessagesLabel(message.id)}
					pics={message.picData}
				/>
			);
		},
		[getMessages, submenuMessage, submenuAvatar, lastViewedMessageId]
	);

	const onGetMessageHandler = (data: {message: Message}) => {
		const localMessage: any = data.message;
		if (!localMessage.talker) localMessage.talker = TALKER_STUB;
		const localMessageUserId = localMessage.talker.user.id;
		const scrollContainer = document.querySelector('.chat__scroll');
		const checkSrollTop =
			scrollContainer &&
			scrollContainer.scrollTop + scrollContainer.clientHeight < scrollContainer.scrollHeight - 100;

		localMessage.isUnread = localMessageUserId ? true : checkSrollTop;

		if (isLastMessages) {
			if (
				localMessage.type === MessageType.USER ||
				localMessage.type === MessageType.PIC ||
				(localMessage.type === MessageType.BET && localMessage.text) ||
				(localMessage.type === MessageType.GAMBLE && localMessage.text)
			) {
				addSocketMessage(localMessage);
			}
			return;
		}

		if (
			localMessage.type !== MessageType.VOTE &&
			localMessage.type !== MessageType.POLL_RESULTS &&
			(localMessage.type !== MessageType.BET ||
				(localMessage.type === MessageType.BET && localMessage.text)) &&
			localMessage.type !== MessageType.STICKER
		) {
			addSocketMessage(localMessage);
		}
	};

	const onUserJoinedHandler = (data: {talker: Talker}) => {
		if (addTalker) addTalker(data.talker);
	};

	const onUserLeftHandler = (talker: Talker) => {
		if (removeTalker) removeTalker(talker);
	};

	const subscribeOnSocketMessages = async () => {
		room ? SocketIoService.emitAdminJoin(room) : SocketIoService.emitAdminJoin('admin');
		// SocketIoService.emitAdminJoin('admin:reports');
		SocketIoService.onUserUpdated(onUserUpdatedHandler);
		SocketIoService.onMessagedDeleted(onMessagedDeletedHandler);
		SocketIoService.onMessageDeleted(onMessageDeletedHandler);
		SocketIoService.onMessageHidden(onMessageHiddenHandler);
		SocketIoService.onMessagesHidden(onMessagesHiddenHandler);
		SocketIoService.onMessageEdited(onMessageEditedHandler);
		SocketIoService.onTalkerBanSet(onTalkerBanSetHandler);
		SocketIoService.onUserBanSet(onUserBanSetHandler);
		SocketIoService.onMuteSet(onMuteSetHandler);
		SocketIoService.onModerSet(onModerSetHandler);
		SocketIoService.onRoleSet(onRoleSetHandler);
		SocketIoService.onBroadcast(onBroadcastHandler);
		SocketIoService.onMessagePinned(onMessagePinHandler);
		SocketIoService.onMessageUnpinned(onMessageUnPinHandler);

		if (!isUserMessages) {
			SocketIoService.onGetMessage(onGetMessageHandler);
		}

		if (room) {
			SocketIoService.onUserJoined(onUserJoinedHandler);
			SocketIoService.onUserLeft(onUserLeftHandler);
			SocketIoService.onRoomStatusSet(onRoomStatusSetHandler);
			SocketIoService.onRoomSpeakSet(onRoomSpeakSetHandler);
			SocketIoService.onRoomSlowmodeSet(onRoomSlowmodeSetHandler);
		}
	};

	const unSubscribeSocketMessages = () => {
		SocketIoService.offGetMessage(onGetMessageHandler);
		SocketIoService.offUserUpdated(onUserUpdatedHandler);
		SocketIoService.offMessagedDeleted(onMessagedDeletedHandler);
		SocketIoService.offMessageDeleted(onMessageDeletedHandler);
		SocketIoService.offMessageHidden(onMessageHiddenHandler);
		SocketIoService.offMessagesHidden(onMessagesHiddenHandler);
		SocketIoService.offMessageEdited(onMessageEditedHandler);
		SocketIoService.offTalkerBanSet(onTalkerBanSetHandler);
		SocketIoService.offUserBanSet(onUserBanSetHandler);
		SocketIoService.offMuteSet(onMuteSetHandler);
		SocketIoService.offModerSet(onModerSetHandler);
		SocketIoService.offRoleSet(onRoleSetHandler);
		SocketIoService.offBroadcast(onBroadcastHandler);
		SocketIoService.offMessagePinned(onMessagePinHandler);
		SocketIoService.offMessageUnpinned(onMessageUnPinHandler);

		if (room) {
			SocketIoService.onUserJoined(onUserJoinedHandler);
			SocketIoService.offUserLeft(onUserLeftHandler);
			SocketIoService.offRoomStatusSet(onRoomStatusSetHandler);
			SocketIoService.offRoomSpeakSet(onRoomSpeakSetHandler);
			SocketIoService.offRoomSlowmodeSet(onRoomSlowmodeSetHandler);
		}
	};

	const emitAdminLeaveBySocket = () => {
		room ? SocketIoService.emitAdminLeave(room) : SocketIoService.emitAdminLeave('admin');
		unSubscribeSocketMessages();
	};

	const setChatScrollTop = (value: number) => {
		if (chatScrollRef.current && chatScrollRef.current instanceof HTMLElement) {
			chatScrollRef.current.scrollTop = value;
		}
	};

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				checkScrollTop(
					setVisiblePreloader,
					setLastViewedMessage,
					setNewMessagesCount,
					chatScrollRef.current,
					getPreviousMessages,
					setChatScrollTop,
					scrollEvent,
					isNextMessages,
					setIsNextMessages,
					getNextMessages
				),
			50
		),
		[
			getMessages,
			visiblePreloader,
			lastViewedMessage,
			chatScrollPosition,
			socketMessages,
			throttling,
			lastViewedMessageId,
			isNextMessages,
		]
	);

	const chatClasses = classNames('chat', {
		'chat--room': room,
		'chat--split': split,
		'chat--lastMessages': isLastMessages,
		'chat--userMessages': isUserMessages,
	});

	const chatLoaderClasses = classNames('chat__loader', {
		'chat__loader--pinned': pinnedMessages.length,
	});

	const processMessage = (data: Message | undefined) => {
		return new Promise((res, rej) => {
			throttlingTimerRef.current = setTimeout(async () => {
				res(data);
			}, throttlingDelay);
		});
	};

	const throttleMessage = async () => {
		if (messagesQueue.length) {
			const data = messagesQueue[0];
			setIsThrottling(true);
			await processMessage(data);
			setIsThrottling(false);
			shiftQueue();
			addMessage(data);
		}
	};

	const setMessagesStream = (value: MessagesStreamType) => {
		setStream(value);
		localStorage.setItem('chatStream', value);
	};

	const onContextMenuHandler = (event: any) => {
		const eventTarget = event.target;
		if (eventTarget && getParent(eventTarget, 'chat__message')) {
			const messageId = parseInt(getParent(eventTarget, 'chat__message').dataset.id, 10);

			if (getParent(eventTarget, 'chat__message') || containsClass(eventTarget, 'chat__message')) {
				event.preventDefault();
			}
			if (
				!containsClass(eventTarget, 'chat__message-avatar-btn') &&
				(containsClass(eventTarget, 'chat__message-body') ||
					getParent(eventTarget, 'chat__message-container'))
			)
				setSubmenuMessage(messageId);

			if (containsClass(eventTarget, 'chat__message-avatar-btn')) setSubmenuAvatar(messageId);
		}
		if (eventTarget && getParent(eventTarget, 'chat__pinned-message')) {
			event.preventDefault();
			if (pinnedMessages.length) setSubmenuMessagePinned(true);
		}
	};

	const clearState = () => {
		setSocketMessages([]);
		setNewMessagesCount(0);
		setChatScrollPosition(false);
		setScrollToLastMessage(false);
		setQueue([]);
		setIsThrottling(false);
		setMessagesStream(MessagesStreamType.ALL);
		setIsNextMessages(false);
	};

	const scrollBottom = () => {
		chatScrollRef.current?.scrollTo(0, chatScrollRef.current?.scrollHeight);
	};

	const loadLastMessages = async () => {
		setIsNextMessages(false);
		getLastMessages && (await getLastMessages());
		setCurrentMessageId(null);
		setLastViewedMessageId(null);
		setSocketMessages([]);
		scrollBottom();
	};

	const onScrollToHandler = async () => {
		if (socketMessages.length && !scrollToLastMessage) {
			// add next messages from socket array

			const lastRenderedMsgIndex = socketMessages
				.map(msg => msg.id)
				.indexOf(getMessages[getMessages.length - 1].id);

			const messageIdToScroll =
				lastRenderedMsgIndex === -1
					? socketMessages[0].id
					: socketMessages[lastRenderedMsgIndex + 1].id;

			if (getAroundMessages) {
				await getAroundMessages(messageIdToScroll);
				setTimeout(() => {
					scrollToMessage(messageIdToScroll, chatScrollRef.current, 'end');
				});
			}

			setCurrentMessageId(null);
			setNewMessagesCount(newMessagesCount - 1);
			setScrollToLastMessage(true);
		} else {
			await loadLastMessages();
			setCurrentMessageId(null);
			setLastViewedMessageId(null);
			setActivePinnedMessage(pinnedMessages[pinnedMessages.length - 1]);
		}
	};

	useEffect(() => {
		getLastViewedMessageId(projectId);
	}, []);

	useEffect(() => {
		if (lastViewedMessageId && !room) {
			scrollToMessage(+lastViewedMessageId, chatScrollRef.current, 'center');
			setChatScrollPosition(true);
			setIsNextMessages(true);
		}
	}, [lastViewedMessageId, room]);

	useEffect(() => {
		if (chatScrollPosition) {
			setIsNextMessages(true);
		}
	}, [chatScrollPosition]);

	useEffect(() => {
		if (currentMessageId) {
			throttling && setThrottling(false);
			setTimeout(() => {
				scrollToMessage(currentMessageId, chatScrollRef.current, 'center');
			}, 1000);
		}
	}, [room, currentMessageId]);

	useEffect(() => {
		if (socketMessages.length) {
			const lastRenderedMsgIndex = socketMessages
				.map(msg => msg.id)
				.indexOf(getMessages[getMessages.length - 1].id);

			setNewMessagesCount(
				socketMessages.filter((el, index) => index > lastRenderedMsgIndex).length +
					getMessages.filter(item => item.isUnread).length
			);
		}
	}, [getMessages, lastViewedMessage, socketMessages, chatScrollPosition]);

	useEffect(() => {
		if (socketMessages.length && chatScrollRef.current && !visiblePreloader) {
			lastViewedMessage === null && setLastViewedMessage(getMessages[getMessages.length - 1].id);
			setIsNextMessages(true);
		}
	}, [socketMessages.length]);

	useEffect(() => {
		if (!lastViewedMessageId) {
			setLastViewedMessageProjects(getMessages[getMessages.length - 1]?.id, projectId);
		}

		if (lastViewedMessageId === getMessages[getMessages.length - 1]?.id) {
			setLastViewedMessageId(null);
			setLastViewedMessageProjects(null, projectId);
		}

		if (!chatScrollPosition && !lastViewedMessageId && !isNextMessages) {
			setTimeout(() => {
				scrollBottom();
				setLastViewedMessageProjects(getMessages[getMessages.length - 1]?.id, projectId);
				setIsNextMessages(false);
			});
		}
	}, [getMessages.length, lastViewedMessageId, isNextMessages, chatScrollPosition]);

	useEffect(() => {
		room && currentMessageId === null && scrollBottom();
	}, [scrollToBottom]);

	useEffect(() => {
		if (accessToken && projectId && SocketIoService.socket === null) {
			SocketIoService.init(
				accessToken,
				projectId,
				subscribeOnSocketMessages,
				unSubscribeSocketMessages
			);
		}
	}, [accessToken, projectId]);

	useEffect(() => {
		if (SocketIoService.socket) {
			subscribeOnSocketMessages();
		}
	}, [SocketIoService.socket]);

	useEffect(() => {
		return () => {
			emitAdminLeaveBySocket();
			if (throttlingTimerRef.current) clearTimeout(throttlingTimerRef.current);
			clearState();
		};
	}, []);

	useEffect(() => {
		if (!split) {
			if (lastViewedMessageId === null && !currentMessageId === null) loadLastMessages();
			setMessagesStream(MessagesStreamType.ALL);
		}
	}, [split]);

	useEffect(() => {
		if ((stream && stream !== MessagesStreamType.ALL) || split) {
			loadLastMessages();
		}
		// clearState();
	}, [stream, split]);

	useEffect(() => {
		if (!isThrottling) {
			throttleMessage();
		}
	}, [messagesQueue.length, isThrottling]);

	useEffect(() => {
		if (
			!throttling &&
			messagesQueue.length === 0 &&
			currentMessageId === null &&
			lastViewedMessageId === null
		)
			scrollBottom();
	}, [messagesQueue.length]);

	useEffect(() => {
		addQueueToMessages();
		if (throttlingTimerRef.current) clearTimeout(throttlingTimerRef.current);
		setIsThrottling(false);
		if (chatScrollPosition) {
			loadLastMessages();
		}

		// clearState();
	}, [throttling]);

	useEffect(() => {
		const chatStream = localStorage.getItem('chatStream');

		switch (chatStream) {
			case MessagesStreamType.ALL:
				setStream(MessagesStreamType.ALL);
				break;
			case MessagesStreamType.FIRST:
				setStream(MessagesStreamType.FIRST);
				break;
			case MessagesStreamType.SECOND:
				setStream(MessagesStreamType.SECOND);
				break;
			default:
				setStream(MessagesStreamType.ALL);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('contextmenu', onContextMenuHandler);
		return () => {
			document.removeEventListener('contextmenu', onContextMenuHandler);
		};
	}, []);

	return (
		<>
			{getMessages.length ? (
				<div className={chatClasses}>
					{visiblePreloader && (
						<div className={chatLoaderClasses}>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{room && !!pinnedMessages.length && !room.includes('thread_') && (
						<ChatPinnedMessage getAroundMessages={getAroundMessages} />
					)}

					<div className='chat__scroll' onScroll={onScrollHandler} ref={chatScrollRef}>
						<div className='chat__messages'>
							{getMessages.map((message: Message, index: number) => renderMessage(message, index))}
						</div>
					</div>
					<div className='chat__scrollbtns'>
						{chatScrollPosition && (
							<div className='chat__unreaded-messages'>
								{!!newMessagesCount && (
									<Badge pill bg='warning' className='chat__unreaded-messages-count'>
										{newMessagesCount}
									</Badge>
								)}
								<button
									type='button'
									onClick={onScrollToHandler}
									className='chat__unreaded-messages-btn'>
									scroll to
								</button>
							</div>
						)}
					</div>
				</div>
			) : (
				<p className='chat__no-messages'>{translations.empty.chatEmpty}</p>
			)}
			<CreateBanModal />
			<EditedMessageHistoryModal />
			<ImageGalleryModal />
			<ReactionsModal />
		</>
	);
};

export default observer(Chat);

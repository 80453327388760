/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Spinner,
	Navbar,
	Form,
	FloatingLabel,
	Button,
	InputGroup,
} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import pollServices from 'store/pollServices';
import alertServices from 'store/alertServices';
import appService from 'store/appService';

import {filter} from 'models/filter';

import {AlertBtnType} from 'models/enums/Alert.enum';
import {Theme} from 'models/enums/Theme.enum';

import usePolls from 'hooks/usePolls';
import PageTitle from 'components/pageTitle/PageTitle';
import {PollModal} from 'components/modals/pollModal';
import PollRoomModal from 'components/modals/pollRoomModal/pollRoomModal';
import PollsTable from './components/polls/PollsTable';

const LIMIT = 10;

const Polls: FunctionComponent = function Polls() {
	const translations = useL10n();
	const history = useHistory();

	const {accessToken} = useLocalObservable(() => userServices);
	const {polls, setPolls, setCurrentPoll} = useLocalObservable(() => pollServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [offset, setOffset] = useState(0);
	const [search, setSearch] = useState('');
	const [searchBy, setSearchBy] = useState('text');
	const [isSearching, setIsSearching] = useState(false);

	const [searchFilter, setSearchFilter] = useState<filter>({
		key: 'text',
		value: null,
		prefix: 'like',
	});

	const sortBy = useRef('createdAt_desc');
	const tableCurrentWidths = useRef([]);

	const {getPolls} = usePolls();

	const getPollsWithServices = async (
		limit?: number,
		currentOffset?: number,
		order?: string,
		filters?: filter
	) => {
		setVisiblePreloader(true);
		await getPolls(limit, currentOffset, order, filters);
		setVisiblePreloader(false);
	};

	const setSortBy = (value: string) => {
		sortBy.current = value;
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getPollsWithServices(LIMIT, offset - LIMIT, sortBy.current, searchFilter);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getPollsWithServices(LIMIT, offset + LIMIT, sortBy.current, searchFilter);
	};

	const onCreateClickHandler = () => {
		const draft = localStorage.getItem('pollDraft');
		if (draft) {
			showAlert({
				title: translations.alerts.restore,
				text: translations.alerts.restoreTextPoll,
				buttons: [
					{
						text: translations.alerts.btns.startOver,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
							history.push('/polls/create');
							localStorage.removeItem('pollDraft');
						},
					},
					{
						type: AlertBtnType.SUCCESS,
						text: translations.alerts.btns.restore,
						onClick: () => {
							hideAlert();
							setCurrentPoll(JSON.parse(draft));
							history.push('/polls/create');
						},
					},
				],
			});
		} else history.push('/polls/create');
	};

	const onSearchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
		setSearchFilter({...searchFilter, value});
	};

	const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		getPollsWithServices(LIMIT, 0, sortBy.current, searchFilter);
		setOffset(0);
		setIsSearching(!isSearching);
	};

	const onSearchSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSearchFilter({...searchFilter, key: value, value: search});
		setSearchBy(value);
	};

	const clearFilters = () => {
		setIsSearching(!isSearching);
		setSearchBy('text');
		setSearch('');
		setSearchFilter({
			key: 'text',
			value: null,
			prefix: 'like',
		});

		sortBy.current = 'createdAt_desc';
		localStorage.removeItem('pollSortBy');
		setOffset(0);
		getPollsWithServices(LIMIT, 0, sortBy.current, {
			key: 'text',
			value: null,
			prefix: 'like',
		});
	};

	useEffect(() => {
		const pollSortBy = localStorage.getItem('pollSortBy');
		const currentWidths = localStorage.getItem('tableCurrentWidths');
		if (pollSortBy) sortBy.current = pollSortBy;
		if (currentWidths) tableCurrentWidths.current = JSON.parse(currentWidths);
	}, []);

	useEffect(() => {
		if (isSearching) {
			setOffset(0);
			getPollsWithServices(LIMIT, 0, sortBy.current, searchFilter);
		}
	}, [isSearching]);

	useEffect(() => {
		accessToken && getPollsWithServices(LIMIT, 0, sortBy.current, searchFilter);
		return () => {
			setPolls([]);
		};
	}, [accessToken]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.pollsQuizzes.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.pollsQuizzes.title}
						text={translations.sidebar.pollsQuizzes.descr}
					/>
				</Col>
				<Col>
					<Container fluid>
						<Navbar className='w-100 d-flex p-0'>
							<div className=' w-100 d-flex align-items-center justify-content-end'>
								<div className='d-flex'>
									<Form onSubmit={handleSearch}>
										<InputGroup>
											<FloatingLabel
												controlId='floatingSelect'
												label={translations.search.placeholder}>
												<Form.Select onChange={onSearchSelect} value={searchBy}>
													<option value='text'>{translations.search.pollTitle}</option>
													<option value='room_poll__room.externalRoomId'>
														{translations.search.externalRoomId}
													</option>
												</Form.Select>
											</FloatingLabel>
											<Form.Control
												placeholder={translations.search.btn}
												value={search}
												onChange={onSearchChangeHandler}
											/>
											<Button variant='dark' id='button-search' type='submit'>
												{translations.search.btn}
											</Button>
										</InputGroup>
									</Form>
									&nbsp;
									<Button variant='dark' onClick={clearFilters}>
										{translations.clear}
									</Button>
									&nbsp;
									<Button variant='dark' onClick={onCreateClickHandler}>
										{translations.polls.create}
									</Button>
								</div>
							</div>
						</Navbar>
					</Container>
				</Col>
			</Row>

			{visiblePreloader && (
				<div className='text-center position-relative'>
					<Spinner
						animation='border'
						variant={appTheme === Theme.DARK ? 'light' : 'dark'}
						className='position-absolute'
					/>
				</div>
			)}

			{polls.length > 0 ? (
				<PollsTable
					tableCurrentWidths={tableCurrentWidths.current}
					setOffset={setOffset}
					setSortBy={setSortBy}
					getPollsWithServices={getPollsWithServices}
					limit={LIMIT}
					searchFilter={searchFilter}
				/>
			) : (
				!visiblePreloader && <p>{translations.empty.pollsEmpty}</p>
			)}
			<div className='fixed-panel'>
				<div className='d-flex'>
					<Button
						variant={appTheme.toLowerCase()}
						className='d-flex align-items-center'
						onClick={onPrevPageBtnClickHandler}
						disabled={(!polls.length && offset === 0) || offset === 0}>
						<BsArrowLeftCircleFill className='ico-sm' />
						&nbsp;
						<span>{translations.prev}</span>
					</Button>
					<Button
						variant={appTheme.toLowerCase()}
						className='mx-2 d-flex align-items-center'
						onClick={onNextPageBtnClickHandler}
						disabled={!polls.length || polls.length < LIMIT}>
						<span>{translations.next}</span>
						&nbsp;
						<BsArrowRightCircleFill className='ico-sm' />
					</Button>
				</div>
			</div>

			<PollModal />
			<PollRoomModal />
		</Container>
	);
};

export default observer(Polls);

enum SocketEvents {
	Connect = 'connect',
	Connected = 'connected',
	ConnectError = 'connect_error',
	Error = 'error',
	Disconnect = 'disconnect',
	Message = 'message',
	MessageEdited = 'messageEdited',
	RoomCreated = 'roomCreated',
	AdminJoin = 'adminJoin',
	AdminLeave = 'adminLeave',
	AdminMessage = 'adminMessage',
	UserUpdated = 'userUpdated',
	UserJoined = 'userJoined',
	UserLeft = 'userLeft',
	MessagesDeleted = 'messagesDeleted',
	MessageDeleted = 'messageDeleted',
	MessageHidden = 'messageVisibleSet',
	MessagesHidden = 'messagesVisibleSet',
	TalkerBanSet = 'talkerBanSet',
	MuteSet = 'muteSet',
	UserBanSet = 'userBanSet',
	ModerSet = 'talkerModerSet',
	RoleSet = 'roleSet',
	RoomStatusSet = 'roomStatusSet',
	RoomSpeakSet = 'roomSpeakSet',
	RoomSlowmodeSet = 'roomSlowmodeSet',
	ReportCreated = 'reportCreated',
	ReportSolved = 'reportSolved',
	AutoBanCreated = 'autoBanCreated',
	AutoBanDeleted = 'autoBanDeleted',
	BanUpdated = 'banUpdated',
	FlaggedMessageCreated = 'created',
	FlaggedMessageResolved = 'resolved',
	FlaggedMessageDeleted = 'deleted',
	Broadcast = 'broadcast',
	MessagePinned = 'messagePinned',
	MessageUnpinned = 'messageUnpinned',
	UnpinMessage = 'unpinMessage',
	ChangelogCreated = 'changelogCreated',

	// prompt

	PromptJoin = 'join',
	PromptAnswerCreated = 'answerCreated',
	PromptEnd = 'end',
	PromptResult = 'result',
	PromptProgress = 'progress',
	PromptError = 'error',
}

export default SocketEvents;

import {action, observable} from 'mobx';

class ConfigService {
	@observable
	public serviceUrls = null;

	@observable
	public chatUrl = '';

	@observable
	public appInstance = '';

	@action
	public setServiceUrls = (value: any) => {
		this.serviceUrls = value;
	};

	@action
	public setChatUrl = (value: string) => {
		this.chatUrl = value;
	};

	@action
	public setAppInstance = (value: string) => {
		this.appInstance = value;
	};
}

export default new ConfigService();

import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './editor.scss';
import {Button} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useChangelog from 'hooks/useChangelog';

interface IEditorConvertToHTML {
	html: string;
	unsavedChanges?: string;
	save?: (value: {rulesHTML?: string; policyHTML?: string; changelogHTML?: string}) => void;
	setUnsavedEditorChanges: (value: string) => void;
	type: string;
	isSaveBtn?: boolean;
	isBlockType?: boolean;
	isFontSize?: boolean;
	isImage?: boolean;
	isWarning?: boolean;
}
const editorConvertToHTML: FunctionComponent<IEditorConvertToHTML> = function editorConvertToHTML({
	html,
	unsavedChanges,
	save,
	setUnsavedEditorChanges,
	type,
	isSaveBtn,
	isBlockType = true,
	isFontSize = true,
	isImage = false,
	isWarning = false,
}) {
	const translations = useL10n();
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [isChanged, setIsChanged] = useState(false);
	const [toolbar, setToolbar] = useState<any>({
		options: ['inline', 'link', 'textAlign', 'list', 'history', 'remove'],
		inline: {
			options: ['bold', 'italic'],
		},
		list: {
			options: ['unordered', 'ordered'],
		},
		blockType: {
			options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
		},
		fontSize: {
			options: [8, 9, 10, 11, 12, 14, 15, 16, 18, 24, 30, 36, 48, 60, 72, 96],
		},
		image: {
			uploadEnabled: true,
			previewImage: true,
		},
	});

	const {uploadPic} = useChangelog();

	const saveChanges = () => {
		save && save({[type]: draftToHtml(convertToRaw(editorState.getCurrentContent()))});
	};

	const onEditorStateChange = (event: any) => {
		// setIsChanged(true);
		setEditorState(event);
	};

	const handleImageUpload = async (file: File) => {
		const response = await uploadPic(file);
		if (response) {
			return {data: {link: response}};
		}
		return {link: ''};
	};
	const onContentStateChange = () => {
		setIsChanged(true);
		const plainText = editorState.getCurrentContent().getPlainText('\u0001');
		setUnsavedEditorChanges(
			plainText ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : ''
		);
	};

	useEffect(() => {
		const contentBlock = html ? htmlToDraft(html) : htmlToDraft('');
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorContent = EditorState.createWithContent(contentState);
			setEditorState(editorContent);
		}
	}, [html]);

	useEffect(() => {
		setIsChanged(unsavedChanges !== '');
	}, [unsavedChanges]);

	useEffect(() => {
		if (isBlockType) {
			const newOptions = toolbar.options;
			newOptions.unshift('blockType');
			setToolbar({...toolbar, options: newOptions});
		}

		if (isFontSize) {
			const newOptions = toolbar.options;
			newOptions.unshift('fontSize');
			setToolbar({...toolbar, options: newOptions});
		}

		if (isImage) {
			const newOptions = toolbar.options;
			newOptions.push('image');

			setToolbar({...toolbar, options: newOptions});
		}
	}, []);

	return (
		<div>
			<div className={`${isWarning ? 'editor--warning' : 'editor'}`}>
				<Editor
					stripPastedStyles
					editorState={editorState}
					wrapperClassName='editor__wrapper'
					editorClassName='editor__content'
					onContentStateChange={onContentStateChange}
					onEditorStateChange={onEditorStateChange}
					toolbar={toolbar}
					uploadCallback={handleImageUpload}
				/>
			</div>
			{isSaveBtn && (
				<Button variant='success' onClick={saveChanges} className='mb-3' disabled={!isChanged}>
					{translations.btns.save}
				</Button>
			)}
		</div>
	);
};

export default observer(editorConvertToHTML);

/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useRef, useState} from 'react';
import Moment from 'react-moment';
import classNames from 'classnames';
import isEmptyObj from 'lodash/isEmpty';
import {v4 as uuidv4} from 'uuid';

import {Badge, Button, Tooltip, Image, OverlayTrigger, Spinner} from 'react-bootstrap';
import {
	BsFillEyeSlashFill,
	BsFillEyeFill,
	BsFillExclamationTriangleFill,
	BsFillCheckCircleFill,
} from 'react-icons/bs';
import {Message} from 'models/room';
import AnonimAva from 'assets/images/anonim-ava.jpg';

import './chat.scss';

import roomServices from 'store/roomServices';
import settingsServices from 'store/settingsServices';
import appService from 'store/appService';
import modalServices from 'store/modalServices';
import messagesServices from 'store/messagesServices';

import useL10n from 'l10n/useL10n';

import MessageType from 'models/enums/MessageType';
import Template from 'components/highlights/Template';
import {Theme} from 'models/enums/Theme.enum';
import {Highlight} from 'models/highlights';

import {ReactComponent as IcoInvited} from 'assets/svg/ico-invited.svg';
import {ReactComponent as IcoEdited} from 'assets/svg/ico-edit.svg';

import useChatMessage from 'hooks/useChatMessage';

import ChatMessageSubmenu from './ChatMessageSubmenu';
import ChatAvatarSubmenu from './ChatAvatarSubmenu';

interface IChatMessage {
	message: Message;
	visibleAvatar: boolean;
	visibleHeader: boolean;
	visibleDate: boolean;
	highlightMessage?: Highlight;
	visibleMessageSubmenu: boolean;
	visibleAvatarSubmenu: boolean;
	visibleNewMessagesLabel: boolean;
	pics: string;
	isVisibilityButtons?: boolean;
	reactions?: any[];
	renderTime?: boolean;
}
const ChatMessage: FunctionComponent<IChatMessage> = function ChatMessage({
	message,
	visibleAvatar,
	visibleHeader,
	visibleDate,
	highlightMessage,
	visibleMessageSubmenu,
	visibleAvatarSubmenu,
	visibleNewMessagesLabel,
	pics,
	isVisibilityButtons = true,
	reactions,
	renderTime = true,
}) {
	const {setMessageVisibility} = useChatMessage();
	const {currentMessageId} = useLocalObservable(() => roomServices);
	const {settings} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {
		editedMessageModalVisible,
		toggleEditedMessageModalVisible,
		toggleImageGalleryModalVisible,
		setImageGalleryFiles,
		setImageGalleryMessageId,
	} = useLocalObservable(() => modalServices);
	const {setEditedMessageId} = useLocalObservable(() => messagesServices);

	const translations = useL10n();

	const {
		id,
		room,
		talker,
		user,
		text,
		isVisible,
		createdAt,
		type,
		hiddenAt,
		hiddenBy,
		isAutoposted,
		isEdited,
	} = message;
	const [keywordsString, setKeywordsString] = useState('администратор модератор админ модер');

	const messageToxicityOPAI: any[] = message.toxicityOPAI ? message.toxicityOPAI.split(':') : [];
	const messageToxicityOPAIReason = messageToxicityOPAI.length ? messageToxicityOPAI[0] : false;
	const messageToxicityOPAIValue = messageToxicityOPAI.length ? Number(messageToxicityOPAI[1]) : 0;

	const {changePicVisible} = useChatMessage();

	const clickImagePreviewHandler = (images: any[]) => {
		toggleImageGalleryModalVisible(true);
		setImageGalleryFiles(images);
		setImageGalleryMessageId(message.id);
	};

	const setPicVisibility = (index: number) => {
		const updatedPic = JSON.parse(pics).map((el: any, elIndex: number) => {
			if (elIndex === index) {
				return {...el, isVisible: !el.isVisible};
			}
			return el;
		});

		changePicVisible(message.id, {picData: JSON.stringify(updatedPic)});
	};

	const chatMessageClasses = classNames('chat__message', {
		'chat__message--hidden': !isVisible,
		'chat__message--moder': talker?.isModer,
		'chat__message--banned': talker?.bans.length || user?.bans.length,
		'chat__message--no-avatar': !visibleAvatar,
		'chat__message--highlighted': currentMessageId === message.id,
		'chat__message--deleted': user === null,
		'chat__message--highlight': highlightMessage,
		'chat__message--danger':
			(settings.enableToxicityCheckDBRT && message.toxicityDBRT && +message.toxicityDBRT === 1) ||
			(settings.enableToxicityCheckOPAI &&
				messageToxicityOPAIReason &&
				messageToxicityOPAIValue > 0),
		'chat__message--bad-connection': message.isBadConnection,
	});

	const chatMessageHideClasses = classNames('chat__message-hide', {
		'text-success': !isVisible,
		'text-danger': isVisible,
	});

	const chatMessagePicClasses = (image: any) =>
		classNames('chat__message-pic', {
			'chat__message-pic--danger': !image.isVisible,
		});

	const renderDateMessage = () => (
		<div className='chat__message-date'>
			<div className='chat__message-date-item'>
				<Moment format='DD.MMM'>{createdAt}</Moment>
			</div>
		</div>
	);

	const renderNewMessagesLabel = () => (
		<div className='chat__message-new'>
			<p>{translations.newMessages}</p>
		</div>
	);

	const renderTimeMessage = useCallback(
		() => (
			<div className='chat__message-time'>
				{hiddenAt ? (
					<div>
						{`${translations.message.hidden} `}
						<Moment format='HH:mm'>{hiddenAt}</Moment>
						{hiddenBy && <span>{` ${hiddenBy}`}</span>}
					</div>
				) : (
					<Moment format='HH:mm'>{createdAt}</Moment>
				)}
			</div>
		),
		[createdAt, hiddenAt]
	);

	const getEditedMessageHistory = async (messageId: number) => {
		setEditedMessageId(messageId);
		if (!editedMessageModalVisible) {
			toggleEditedMessageModalVisible(true);
		}
	};

	const highlightText = (messageText: string) => {
		const keywords = keywordsString.split(/\s/);
		const pattern = new RegExp(`(${keywords.join('|')})`, 'gi');
		const parts = messageText.split(pattern);
		return (
			<span>
				{parts
					.filter(part => part)
					.map((part, i) =>
						pattern.test(part) ? (
							<b key={i} className='chat__message-highlight'>
								{part}
							</b>
						) : (
							<span key={i}>{part}</span>
						)
					)}
			</span>
		);
	};

	const groupingByKey = (arrray: any, key = 'emotion') => {
		const resultObject = arrray.reduce((acc: any, item: any) => {
			// eslint-disable-next-line no-param-reassign
			(acc[item[key]] = acc[item[key]] || []).push(item);
			return acc;
		}, {});
		if (!isEmptyObj(resultObject)) {
			return Object.getOwnPropertyNames(resultObject).map(k => ({
				id: uuidv4(),
				name: resultObject[k][0].emotion,
				messageId: resultObject[k][0].messageId,
				items: resultObject[k],
				pic: resultObject[k][0].pic,
			}));
		}
		return [];
	};

	return (
		<div className={chatMessageClasses} data-id={message.id}>
			{visibleDate && renderDateMessage()}
			<div className='chat__message-container'>
				<div className='chat__message-avatar'>
					{visibleAvatar && !highlightMessage && (
						<div className={`chat__message-avatar-wrapper background-color-${user?.color || 0}`}>
							{message.isBadConnection && (
								<span>
									<OverlayTrigger
										placement='right'
										delay={{show: 50, hide: 100}}
										overlay={
											<Tooltip
												id='tooltip-badConnection'
												className='my-tooltip text-left text-nowrap'>
												<div>
													<span dangerouslySetInnerHTML={{__html: translations.badConnection}} />
												</div>
											</Tooltip>
										}>
										<span className='chat__message-bad-inet-ico'> </span>
									</OverlayTrigger>
								</span>
							)}
							<img
								src={user?.pic ? user.pic : AnonimAva}
								alt=''
								className='chat__message-avatar-img'
							/>

							<button className='chat__message-avatar-btn' type='button'>
								{' '}
							</button>
						</div>
					)}
					{visibleAvatarSubmenu && <ChatAvatarSubmenu message={message} />}
				</div>
				<div className='chat__message-wrapper'>
					{visibleHeader && !highlightMessage && (
						<div className='chat__message-header'>
							<div className={`chat__message-author color-${user?.color || 0}`}>
								{user?.name ? user.name : 'User deleted'}
							</div>
							{user?.isInvitedGuest && <IcoInvited className='chat__message-icon' />}
							<div className='chat__message-badges'>
								{(talker?.isModer || user?.isModer) && (
									<>
										<Badge pill bg='secondary'>
											{user?.isModer ? translations.roles.admin : translations.roles.moder}
										</Badge>
										&nbsp;
									</>
								)}
								{user?.bans.length > 0 && (
									<>
										<Badge pill bg='danger'>
											{translations.badges.userBanned}
										</Badge>
										&nbsp;
									</>
								)}

								{talker?.bans.length > 0 && (
									<>
										<Badge pill bg='danger'>
											{translations.badges.talkerBanned}
										</Badge>
										&nbsp;
									</>
								)}

								{talker?.role === 'SPEAKER' && (
									<>
										<Badge pill bg='info'>
											{translations.badges.speaker}
										</Badge>
										&nbsp;
									</>
								)}

								{user?.isVip && (
									<>
										<Badge pill bg='black' className='chat__message-room'>
											{user?.vipStatus}
										</Badge>
										&nbsp;
									</>
								)}

								{user?.id && (
									<>
										<Badge pill bg={appTheme?.toLowerCase()} className='chat__message-room'>
											id{user?.id}
										</Badge>
										&nbsp;
									</>
								)}
								{user?.externalId && (
									<>
										<Badge pill bg={appTheme?.toLowerCase()} className='chat__message-externalId'>
											ext:{user?.externalId}
										</Badge>
										&nbsp;
									</>
								)}

								{user?.decryptedExternalId && (
									<>
										<Badge pill bg={appTheme?.toLowerCase()} className='chat__message-externalId'>
											decrypted:{user?.decryptedExternalId}
										</Badge>
										&nbsp;
									</>
								)}

								{room && (
									<Badge pill bg={appTheme?.toLowerCase()} className='chat__message-room'>
										@
										{room?.name && room?.name !== 'Нет названия'
											? room?.name
											: room?.externalRoomId}
									</Badge>
								)}

								{Boolean(isAutoposted) && (
									<Badge pill bg={appTheme === Theme.DARK ? 'light' : 'secondary'} className='mx-2'>
										{translations.autoposting.badge}
									</Badge>
								)}
							</div>
						</div>
					)}

					{highlightMessage && highlightMessage.template ? (
						<div className='chat__message-body'>
							<Template
								ad={highlightMessage}
								template={highlightMessage.template}
								templateDataJSON={highlightMessage.templateData}
							/>
						</div>
					) : (
						<div className='chat__message-body'>
							<div className='chat__message-body-content'>
								<div className='chat__message-body-wrapper'>
									{type === MessageType.PIC && pics && (
										<div className='chat__message-pics'>
											{Array.from(JSON.parse(pics)).map((image: any, i: number) => (
												<div className={chatMessagePicClasses(image)} key={i}>
													<button
														type='button'
														className='chat__message-pics-link'
														onClick={() => clickImagePreviewHandler(Array.from(JSON.parse(pics)))}>
														{' '}
													</button>
													<div className='chat__message-pic-loader'>
														<Spinner
															animation='border'
															variant={appTheme === Theme.DARK ? 'light' : 'dark'}
														/>
													</div>
													{isVisibilityButtons && (
														<Button
															variant={appTheme?.toLowerCase()}
															size='sm'
															onClick={() => setPicVisibility(i)}
															className={
																image.isVisible
																	? 'chat__message-pic-btn text-danger'
																	: 'chat__message-pic-btn text-success'
															}>
															<span>
																{!image.isVisible
																	? translations.btns.unblock
																	: translations.btns.block}
															</span>
															&nbsp;
															{image.isVisible && <BsFillEyeSlashFill className='text-danger' />}
															{!image.isVisible && <BsFillEyeFill className='text-success' />}
														</Button>
													)}
													<Image
														src={image.url}
														className='chat__message-sticker-img'
														loading='lazy'
													/>
													{!image.isVisible && (
														<BsFillExclamationTriangleFill className='chat__message-pic-ico text-danger' />
													)}
												</div>
											))}
										</div>
									)}
									<div className='chat__message-content'>
										{text && <div className='chat__message-text'>{highlightText(text)}</div>}

										{message.type === MessageType.BET && (
											<Badge pill bg='light'>
												(bet)
											</Badge>
										)}
										{message.type === MessageType.GAMBLE && (
											<Badge bg='light' pill>
												(win)
											</Badge>
										)}

										{type === MessageType.STICKER && (
											<div className='chat__message-text'>
												{message.sticker && (
													<Badge bg='light'>
														{translations.stickers.sticker}: &nbsp;id
														{message.sticker?.id}
													</Badge>
												)}
												{message.stickerPic && (
													<Image src={message.stickerPic} className='image-preview' />
												)}
												{!message.stickerPic && !message.sticker && (
													<Badge bg='light'>{translations.stickers.stickerRemoved}</Badge>
												)}
											</div>
										)}

										{renderTime && renderTimeMessage()}

										{Boolean(isEdited) && (
											<div className='chat__message-edited'>
												<button
													onClick={() => getEditedMessageHistory(id)}
													type='button'
													className='chat__message-edited-btn'>
													<IcoEdited className='chat__message-edited-ico' />
													<span>{translations.message.edited}</span>
												</button>
											</div>
										)}

										{((settings.enableToxicityCheckOPAI && messageToxicityOPAIReason) ||
											(settings.enableToxicityCheckDBRT && message.toxicityDBRT)) && (
											<OverlayTrigger
												placement='top'
												delay={{show: 50, hide: 100}}
												overlay={
													<Tooltip id='tooltip-toloka' className='my-tooltip text-left text-nowrap'>
														<div>
															<span>{translations.settings.toxicityEstimation}</span>
														</div>

														{settings.enableToxicityCheckDBRT && message.toxicityDBRT !== null && (
															<div className='d-flex'>
																<span className='text-muted'>Toxicity:</span>&nbsp;
																<span>{message.toxicityDBRT}</span>
															</div>
														)}
														{settings.enableToxicityCheckOPAI && message.toxicityOPAI !== null && (
															<div className='d-flex'>
																<span className='text-muted'> OPAI:</span>
																&nbsp;
																<span>
																	{messageToxicityOPAIValue > 0
																		? messageToxicityOPAIValue.toFixed(2)
																		: messageToxicityOPAIValue}
																	&nbsp;
																	{messageToxicityOPAIReason}
																</span>
															</div>
														)}
													</Tooltip>
												}>
												<div className='chat__message-toxicity'>
													<BsFillExclamationTriangleFill className='text-danger' />
													<BsFillCheckCircleFill className='text-secondary' />
												</div>
											</OverlayTrigger>
										)}
									</div>
								</div>
								{visibleMessageSubmenu && <ChatMessageSubmenu message={message} />}

								{isVisibilityButtons && (
									<Button
										size='sm'
										variant={appTheme?.toLowerCase()}
										className={chatMessageHideClasses}
										onClick={() => setMessageVisibility(message)}>
										<span>
											{!isVisible
												? translations.submenu.showMessage
												: translations.submenu.hideMessage}
										</span>
										&nbsp;
										{isVisible && <BsFillEyeSlashFill className='text-danger' />}
										{!isVisible && <BsFillEyeFill className='text-success' />}
									</Button>
								)}
							</div>
							{!!reactions?.length && (
								<div className='chat__message-reactions'>
									{groupingByKey(reactions).map(el => {
										return (
											<div className='chat__message-reaction' key={el.name}>
												{el.pic && <img src={el.pic} alt='' />}
												<span className='chat__message-reaction-count'>{el.items.length}</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			{visibleNewMessagesLabel && renderNewMessagesLabel()}
		</div>
	);
};

export default observer(ChatMessage);

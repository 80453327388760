import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import SelectControl from 'components/select/SelectControl';
import appService from 'store/appService';

interface ISelectProject {
	projectUsers: any[];
	sidebar?: boolean;
	activeProjectId?: string | null;
	setActiveProjectId?: (value: string) => void;
	theme?: string;
}
const projectSelect: FunctionComponent<ISelectProject> = function projectSelect({
	projectUsers,
	activeProjectId,
	setActiveProjectId,
	sidebar,
	theme = 'dark',
}) {
	const [selectedProject, setSelectedProject] = useState<any>();
	const [options, setOptions] = useState<any>([]);

	const {projectId, setProjectId} = useLocalObservable(() => appService);

	const getUserBySelectedProject = (project: string) => {
		return projectUsers.find((el: any) => el.projectId === project);
	};

	const setUserData = async (project: string) => {
		if (setActiveProjectId) setActiveProjectId(project);
		if (sidebar) {
			setProjectId(project);
			localStorage.setItem('projectId', project);
			localStorage.setItem('user', JSON.stringify(getUserBySelectedProject(project)));
		}
	};

	const setCurrentProject = (id?: string) => {
		const project = id || activeProjectId;
		setSelectedProject({
			value: project,
			label: projectUsers.find(el => el.projectId === project)?.project.name || project,
		});
	};

	const getItemsForSelect = () => {
		const elems = projectUsers.map(el => {
			return {value: el.projectId, label: el.project.name};
		});
		setOptions(elems);
	};

	const handleChange = (selectedOption: any) => {
		setSelectedProject(selectedOption);
		setUserData(selectedOption.value);
		if (sidebar) {
			window.location.reload();
		}
	};

	useEffect(() => {
		if (sidebar) getItemsForSelect();
	}, [projectUsers]);

	useEffect(() => {
		if (!sidebar && setActiveProjectId) setActiveProjectId(projectUsers[0].projectId);
		getItemsForSelect();
	}, []);

	useEffect(() => {
		if (!sidebar && activeProjectId) setCurrentProject();
	}, [activeProjectId]);

	useEffect(() => {
		if (sidebar && projectId) setCurrentProject(projectId);
	}, [projectId]);

	return (
		<SelectControl
			handleChange={handleChange}
			options={options}
			value={selectedProject}
			theme={theme}
		/>
	);
};

export default observer(projectSelect);

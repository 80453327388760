import {observer, useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {FunctionComponent, useState} from 'react';

import {Button, Modal, Form, FloatingLabel} from 'react-bootstrap';
import modalServices from 'store/modalServices';

import '../modal.scss';

import useL10n from 'l10n/useL10n';
import useAdminProxy from 'hooks/useAdminProxy';

interface ICreateAdminModal {
	onCloseSuccess: (role: string, name: string) => void;
	onCloseError: (error: string) => void;
}
const createAdminModal: FunctionComponent<ICreateAdminModal> = function createAdminModal({
	onCloseSuccess,
	onCloseError,
}) {
	const {adminModalVisible, hideAdminModal} = useLocalObservable(() => modalServices);

	const [validated, setValidated] = useState(false);
	const [adminData, setAdminData] = useState({
		name: '',
		password: '',
		role: 'MODER',
	});

	const translations = useL10n();
	const {addAdmin} = useAdminProxy();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		const valueTrim = value.trim();
		setAdminData({
			...adminData,
			[event.target.name]: event.target.name === 'password' ? valueTrim : value,
		});
	};

	const clear = () => {
		setAdminData({
			name: '',
			password: '',
			role: '',
		});

		setValidated(false);
	};

	const createAdmin = async () => {
		const admin = {
			...adminData,
		};
		const response = await addAdmin(admin);
		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.error) {
				onCloseError(response.data.error);
			} else {
				onCloseSuccess(adminData.role, adminData.name);
				clear();
				hideAdminModal();
			}
		} else if (response.status === ResponseStatus.ERROR) {
			if (response.data === 'ER_DUP_ENTRY') {
				onCloseError(translations.alerts.nameAlreadyExist);
			}
			if (
				response.data === 'USER_ALREADY_EXISTS' ||
				response.data === 'PROJECT_USER_ALREADY_EXISTS'
			) {
				onCloseError(translations.alerts.nameAlreadyExist);
			}
		}
	};

	const onModalClose = () => {
		clear();
		hideAdminModal();
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else createAdmin();
	};

	return (
		<Modal
			show={adminModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}
			className='modal--addModer'>
			<Modal.Header closeButton>
				<Modal.Title>{translations.modals.createModer.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className='mb-3' controlId='formRoomName'>
						<Form.Label>{translations.modals.createModer.name}</Form.Label>
						<FloatingLabel controlId='floatingId' label={translations.modals.createModer.name}>
							<Form.Control
								placeholder={translations.modals.createModer.name}
								name='name'
								required
								value={adminData.name}
								onChange={onChangeHandler}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								{translations.modals.createModer.nameWarn}
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formRoomPassword'>
						<Form.Label>{translations.modals.createModer.password}</Form.Label>
						<FloatingLabel
							controlId='floatingName'
							label={translations.modals.createModer.password}>
							<Form.Control
								placeholder={translations.modals.createModer.password}
								name='password'
								required
								value={adminData.password}
								onChange={onChangeHandler}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								{translations.modals.createModer.passwordWarn}
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formRoomRole'>
						<div className='mb-2'>
							<Form.Check
								inline
								name='role'
								type='radio'
								label={translations.roles.moder}
								id='admin-role2'
								value='MODER'
								required
								onChange={onChangeHandler}
							/>
							<p className='text-secondary px-4'>
								{translations.modals.createModer.moderatorDescr}
							</p>
						</div>

						<div>
							<Form.Check
								inline
								name='role'
								type='radio'
								label={translations.roles.admin}
								id='admin-role1'
								value='ADMIN'
								required
								onChange={onChangeHandler}
							/>
							<p className='text-secondary px-4'>{translations.modals.createModer.adminDescr}</p>
						</div>
					</Form.Group>

					<Modal.Footer>
						<Button variant='outline-secondary' onClick={hideAdminModal}>
							{translations.btns.cancel}
						</Button>
						<Button type='submit' variant='dark'>
							{translations.btns.create}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
export default observer(createAdminModal);

import SocketEvents from 'models/enums/SocketEvents.enum';
import {
	PromptAnswerCreatedListener,
	PromptEndListener,
	PromptErrorListener,
	PromptProgressListener,
	PromptResultListener,
} from 'models/socketio';
import socketIOClient, {Socket} from 'socket.io-client';
import Log from 'utils/log';

type SocketSubscribeEvents = () => void;
type SocketUnSubscribeEvents = () => void;

export default class SocketIoService {
	static socket: Socket | null = null;

	static init = async (
		token: Token,
		projectId: string,
		socketSubscribeEvents?: SocketSubscribeEvents,
		socketUnSubscribeEvents?: SocketUnSubscribeEvents
	) => {
		try {
			this.socket = socketIOClient(`${process.env.REACT_APP_SEMANTIC_ANALYSIS_SERVICE}`, {
				auth: {
					access_token: token,
				},
				query: {
					project: projectId,
				},
				transports: ['websocket'],
				upgrade: false,
			});

			this.listenServerEvents(socketSubscribeEvents, socketUnSubscribeEvents);
		} catch (error) {
			Log.error('Init socket.io: ', error);
		}
	};

	static listenServerEvents = (
		socketSubscribeEvents?: SocketSubscribeEvents,
		socketUnSubscribeEvents?: SocketUnSubscribeEvents
	) => {
		this.socket?.on(SocketEvents.Connect, () => {
			if (socketSubscribeEvents) {
				socketSubscribeEvents();
			}
			Log.debug(`socket.io -> socketid: ${this.socket?.id} -> connect - success`);
		});

		this.socket?.on(SocketEvents.Connected, data => {
			Log.debug(`socket.io -> socketid: ${this.socket?.id} -> connected: `, data);
		});

		this.socket?.on(SocketEvents.ConnectError, error => {
			Log.error(`socket.io -> socketid: ${this.socket?.id} -> connected - error: `, error);
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});

		this.socket?.on(SocketEvents.Error, error => {
			Log.error('socket.io -> error', error);
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});

		this.socket?.on(SocketEvents.Disconnect, () => {
			Log.log('socket.io -> disconnect');
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});
	};

	/* -----------------  ON ------------------ */

	static onPromptAnswerCreated = (listener: PromptAnswerCreatedListener) => {
		this.socket?.on(SocketEvents.PromptAnswerCreated, listener);
	};

	static onPromptEnd = (listener: PromptEndListener) => {
		this.socket?.on(SocketEvents.PromptEnd, listener);
	};

	static onPromptProgress = (listener: PromptProgressListener) => {
		this.socket?.on(SocketEvents.PromptProgress, listener);
	};

	static onPromptResult = (listener: PromptResultListener) => {
		this.socket?.on(SocketEvents.PromptResult, listener);
	};

	static onPromptError = (listener: PromptErrorListener) => {
		this.socket?.on(SocketEvents.PromptError, listener);
	};

	/* -----------------  END - ON ------------------ */

	/* -----------------  OFF ------------------ */

	static offPromptAnswerCreated = (listener: PromptAnswerCreatedListener) => {
		this.socket?.off(SocketEvents.PromptAnswerCreated, listener);
	};

	static offPromptEnd = (listener: PromptEndListener) => {
		this.socket?.off(SocketEvents.PromptEnd, listener);
	};

	static offPromptProgress = (listener: PromptProgressListener) => {
		this.socket?.off(SocketEvents.PromptProgress, listener);
	};

	static offPromptResult = (listener: PromptResultListener) => {
		this.socket?.off(SocketEvents.PromptResult, listener);
	};

	static offPromptError = (listener: PromptErrorListener) => {
		this.socket?.off(SocketEvents.PromptError, listener);
	};

	/* -----------------  END - OFF ------------------ */
	static emitAdminJoinPrompt = (promptId: number) => {
		this.socket?.emit(SocketEvents.PromptJoin, {
			promptId,
		});
	};

	static soketDisconnect = () => {
		if (this.socket !== null) {
			this.socket?.disconnect();
			this.socket = null;
		}
	};
}

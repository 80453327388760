import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form, FloatingLabel, Button} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import useAdminProxy from 'hooks/useAdminProxy';
import SettingsService from 'services/api/SettingsService';
import appService from 'store/appService';
import EnvironmentService from 'services/api/EnvironmentService';

const APIKey: FunctionComponent = function APIKey() {
	const translations = useL10n();

	const [name, setName] = useState('');
	const [apiKey, setApiKey] = useState('');
	const [projectData, setProjectData] = useState<any>(null);
	const [validated, setValidated] = useState(false);
	const [isBtnDisabled, setIsBtnDisabled] = useState(true);

	const {accessToken, adminData} = useLocalObservable(() => userServices);
	const {showHiddenSettings, projectId} = useLocalObservable(() => appService);

	const {getProjectName, updateProjectName, getAdmin} = useAdminProxy();

	const onChangeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		value = value.replace(/[^a-zA-Z0-9- ]/i, '');
		setName(value.trim() ? value : value.trim());
		if (apiKey) setIsBtnDisabled(false);
	};

	const onChangeApiKeyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setApiKey(value.trim() || value);
		if (name) setIsBtnDisabled(false);
	};

	const updateApiKeyWithServices = async () => {
		const response = await EnvironmentService.updateApiKey(accessToken, projectData.id, apiKey);
		if (response.status === ResponseStatus.SUCCESS) {
			setApiKey(response.data?.find((el: any) => el.name === projectId).apiKey);
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		await updateProjectName(name);
		if (showHiddenSettings && projectData && apiKey !== projectData?.apiKey)
			await updateApiKeyWithServices();
		setIsBtnDisabled(true);
		await getAdmin(adminData.adminId);
	};

	const getApiKeyWithServices = async () => {
		const response = await EnvironmentService.getProject(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setProjectData(response.data.find((el: any) => el.name === projectId));
			setApiKey(response.data.find((el: any) => el.name === projectId).apiKey);
		}
	};

	const getProjectNameWithServices = async () => {
		const response = await getProjectName();
		if (response.status === ResponseStatus.SUCCESS) {
			setName(response.data.name);
		}
	};

	useEffect(() => {
		if (accessToken) {
			getApiKeyWithServices();
			getProjectNameWithServices();
		}
	}, [accessToken]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>APIKey</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h3 mb-5'>APIKey</p>
					<div className='block w-50'>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<FloatingLabel
								controlId='apiKey'
								label='Project APIKey'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									placeholder='APIKey'
									type='text'
									value={apiKey}
									onChange={onChangeApiKeyHandler}
									disabled={!showHiddenSettings}
									required
								/>
							</FloatingLabel>
							<FloatingLabel
								controlId='name'
								label='Application name'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									name='name'
									placeholder='Application name'
									type='text'
									value={name}
									onChange={onChangeNameHandler}
									required
								/>
							</FloatingLabel>
							<Button type='submit' variant='success' disabled={isBtnDisabled}>
								{translations.btns.save}
							</Button>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(APIKey);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {Modal, Button} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import pollServices from 'store/pollServices';

const pollRoomModal: FunctionComponent = function pollRoomModal() {
	const translations = useL10n();

	const {pollRoomModalVisible, togglePollRoomModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {currentPoll, setCurrentPoll} = useLocalObservable(() => pollServices);

	const onModalClose = () => {
		togglePollRoomModalVisible(false);
		setCurrentPoll(null);
	};

	const goToRoom = (externalRoomId: string) => {
		window.open(`/room/${encodeURIComponent(externalRoomId)}`, '_blank');
	};

	return (
		<Modal
			show={pollRoomModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title> {translations.polls.room} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{currentPoll?.roomPolls?.map((item, index) => {
					return (
						<span key={item.id}>
							{index !== 0 && <i>, </i>}
							<button
								type='button'
								className='link'
								key={item.id}
								onClick={() => goToRoom(item.room.externalRoomId)}>
								{item.room.externalRoomId}
							</button>
						</span>
					);
				})}
			</Modal.Body>
		</Modal>
	);
};
export default observer(pollRoomModal);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';

import {Button, Modal, Form, FloatingLabel, Image} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import '../modal.scss';
import 'react-datepicker/dist/react-datepicker.css';

import ResponseStatus from 'models/enums/ResponseStatus.enum';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import modalServices from 'store/modalServices';

import {IRoom, Room} from 'models/room';
import useRoom from 'hooks/useRoom';

interface ICreateRoomModal {
	onCloseSuccess?: (externalRoomId: string) => void;
	onCloseError?: (error: string) => void;
	mode: string;
	editedRoom: Room | null;
}

const createRoomModal: FunctionComponent<ICreateRoomModal> = function createRoomModal({
	onCloseSuccess,
	onCloseError,
	mode,
	editedRoom,
}) {
	const {roomModalVisible, hideRoomModal} = useLocalObservable(() => modalServices);
	const {projectId} = useLocalObservable(() => appService);

	const [startDate, setStartDate] = useState<Date | null>();
	const [endDate, setEndDate] = useState<Date | null>();
	const [validated, setValidated] = useState(false);
	const [pic, setPic] = useState<Blob | null>(null);
	const [roomData, setRoomData] = useState<IRoom | null>(null);

	const translations = useL10n();
	const {createRoom, updateRoomData} = useRoom();

	const isTinkoff = projectId.includes('tinkoff') || projectId === 'dev';

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		if (event.target.name === 'externalRoomId') {
			value = value.replace(/[^a-zA-Z0-9-]/i, '').trim();
		}

		if (event.target.name === 'about') {
			value = value.replace(/\r?\n/g, ' ');
		}
		setRoomData({
			...roomData,
			[event.target.name]: value,
		});
	};

	const clear = () => {
		setRoomData(null);
		setStartDate(null);
		setEndDate(null);
		setValidated(false);
		setPic(null);
	};

	const closeModal = () => {
		clear();
		hideRoomModal();
	};

	const createNewRoom = async () => {
		const room = {
			...roomData,
			startTime: startDate ? startDate.toISOString() : null,
			endTime: endDate ? endDate.toISOString() : null,
		};
		const response = await createRoom(room, pic);

		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.error && onCloseError) {
				onCloseError(response.data.error);
			} else {
				if (roomData?.externalRoomId && onCloseSuccess) onCloseSuccess(roomData.externalRoomId);
				clear();
				hideRoomModal();
			}
		}
	};

	const updateRoom = async () => {
		if (roomData?.externalRoomId) {
			const room = {
				...roomData,
				startTime: startDate ? startDate.toISOString() : null,
				endTime: endDate ? endDate.toISOString() : null,
			};
			const response = await updateRoomData(room, roomData.externalRoomId, pic);
			if (response.status === ResponseStatus.SUCCESS) {
				if (response.data.error && onCloseError) {
					onCloseError(response.data.error);
				} else {
					if (roomData?.externalRoomId && onCloseSuccess) onCloseSuccess(roomData?.externalRoomId);
					clear();
					hideRoomModal();
				}
			}
		}
	};

	const onModalClose = () => {
		clear();
		hideRoomModal();
	};

	const onModalShow = () => {
		if (mode === 'edit' && editedRoom) {
			setRoomData({
				name: editedRoom.name,
				externalRoomId: editedRoom.externalRoomId,
				about: editedRoom.about,
				isSpeak: editedRoom.isSpeak,
				pic: editedRoom.pic,
				streamUrl: editedRoom.streamUrl,
			});
			if (editedRoom.startTime) setStartDate(new Date(editedRoom.startTime));
			if (editedRoom.endTime) setEndDate(new Date(editedRoom.endTime));
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		if (mode === 'edit') {
			updateRoom();
			return;
		}
		createNewRoom();
	};

	const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				setPic(file);
			}
		}
	};

	const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setRoomData({
			...roomData,
			[event.target.name]: checked,
		});
	};

	return (
		<Modal
			className='modal--room'
			show={roomModalVisible}
			onHide={onModalClose}
			onShow={onModalShow}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title>
					{' '}
					{mode === 'create' ? translations.modals.createRoom.title : translations.btns.edit}{' '}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					{mode === 'create' && (
						<Form.Group className='mb-3' controlId='formRoomId'>
							<Form.Label>{translations.modals.createRoom.externakRoomId} </Form.Label>
							<FloatingLabel
								controlId='floatingId'
								label={translations.modals.createRoom.externakRoomId}
								className='text-secondary'>
								<Form.Control
									placeholder={translations.modals.createRoom.externakRoomId}
									name='externalRoomId'
									required
									value={roomData?.externalRoomId}
									onChange={onChangeHandler}
								/>
								<Form.Control.Feedback type='invalid' tooltip>
									{translations.modals.createRoom.externakRoomIdWarn}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
					)}

					<Form.Group className='mb-3' controlId='formRoomName'>
						<Form.Label>{translations.modals.createRoom.name} </Form.Label>
						<FloatingLabel
							controlId='floatingName'
							label={translations.modals.createRoom.name}
							className='text-secondary'>
							<Form.Control
								placeholder={translations.modals.createRoom.name}
								name='name'
								required
								value={roomData?.name}
								onChange={onChangeHandler}
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								{translations.modals.createRoom.nameWarn}
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					{isTinkoff && (
						<Form.Group className='mb-3' controlId='formRoomName'>
							<Form.Label>{translations.modals.createRoom.description} </Form.Label>
							<FloatingLabel
								controlId='floatingName'
								label={translations.modals.createRoom.description}
								className='text-secondary'>
								<Form.Control
									as='textarea'
									rows={5}
									size='sm'
									maxLength={30}
									placeholder={translations.modals.createRoom.description}
									name='about'
									required
									value={roomData?.about}
									onChange={onChangeHandler}
								/>
								<Form.Control.Feedback type='invalid' tooltip>
									{translations.modals.createRoom.descriptionWarn}
								</Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
					)}

					<Form.Group className='mb-3' controlId='formDateStart'>
						<Form.Label>{translations.modals.createRoom.dateStart}</Form.Label>
						<DatePicker
							className='form-control'
							selected={startDate}
							placeholderText={translations.modals.createRoom.dateStart}
							required={isTinkoff}
							dateFormat='dd.MM.yyyy, HH:mm'
							locale='ru'
							timeInputLabel={`${translations.modals.createRoom.time}:`}
							showTimeInput
							onChange={(date: Date) => setStartDate(date)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formDateStart'>
						<Form.Label>{translations.modals.createRoom.dateEnd}</Form.Label>
						<DatePicker
							className='form-control'
							selected={endDate}
							placeholderText={translations.modals.createRoom.dateEnd}
							required={isTinkoff}
							dateFormat='dd.MM.yyyy, HH:mm'
							locale='ru'
							timeInputLabel={`${translations.modals.createRoom.time}:`}
							showTimeInput
							onChange={(date: Date) => setEndDate(date)}
						/>
					</Form.Group>

					{isTinkoff && (
						<Form.Group controlId='formFile' className='mb-3'>
							<div>
								<Form.Label>{translations.table.pic}</Form.Label>
							</div>

							{(pic || roomData?.pic) && (
								<Image
									src={pic ? URL.createObjectURL(pic) : roomData?.pic}
									className='mb-2'
									fluid
									style={{height: '8rem'}}
								/>
							)}
							<Form.Control type='file' required={mode === 'create'} onChange={readFile} />
						</Form.Group>
					)}

					<Form.Group className='mb-3' controlId='formRoomName'>
						<Form.Label>Stream url</Form.Label>
						{roomData?.streamUrl && (
							<div>
								<iframe
									src={roomData.streamUrl}
									width='100%'
									height='250px'
									allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
									style={{border: 'none'}}
									frameBorder='0'
									allowFullScreen
									title='tinkoff'
									className='stream-video-iframe'
								/>
							</div>
						)}
						<FloatingLabel controlId='floatingName' label='Stream url' className='text-secondary'>
							<Form.Control
								placeholder='Stream url'
								name='streamUrl'
								value={roomData?.streamUrl}
								onChange={onChangeHandler}
								type='url'
							/>
							<Form.Control.Feedback type='invalid' tooltip>
								{translations.modals.createRoom.streamUrlWarn}
							</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>

					{isTinkoff && (
						<Form.Group className='mb-3' controlId='formIsSpeak'>
							<Form.Check
								name='isSpeak'
								type='switch'
								id='isSpeak'
								label={translations.room.switch}
								onChange={onCheck}
								checked={roomData?.isSpeak}
							/>
						</Form.Group>
					)}

					<Modal.Footer>
						<Button variant='secondary' onClick={closeModal}>
							{translations.btns.cancel}
						</Button>
						<Button type='submit'>
							{mode === 'create' ? translations.btns.create : translations.btns.save}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default observer(createRoomModal);

/* eslint-disable max-lines */

import UploadImage from 'components/uploadImage/uploadImage';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import {useHistory, useParams} from 'react-router-dom';
import {getFileSize} from 'utils/helpers';
import badgeService from 'store/badgeService';
import checkRealTextLength from 'utils/checkTextLength';
import useBadge from 'hooks/useBadge';
import {Badge} from 'models/badge';
import {HexColorPicker} from 'react-colorful';
import {BsTrashFill} from 'react-icons/bs';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import BadgePreview from './BadgePreview';

const BadgeSettings: FunctionComponent = function BadgeSettings() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const translations = useL10n();
	const history = useHistory();

	const [validated, setValidated] = useState(false);
	const [isNotificationEnabled, setIsNotificationEnabled] = useState<any>(false);
	const [picker, setPicker] = useState('');
	const [color, setColor] = useState('');
	const {appTheme} = useLocalObservable(() => appService);
	const {badge, setBadge, currentBadge, setCurrentBadge, setCurrentTab, clear} = useLocalObservable(
		() => badgeService
	);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const {createBadge, updateBadge, deleteBadge} = useBadge();

	const cancelClickHandler = () => {
		history.push('/badges');
		setCurrentTab('badge');
	};

	const save = async () => {
		badge.id ? await updateBadge(badge.id, currentBadge) : await createBadge(currentBadge);
		history.push('/badges');
		setCurrentTab('badge');
	};

	const deleteBadgeWithServices = async () => {
		if (badge.id) {
			await deleteBadge(badge.id);
			history.push('/badges');
			setCurrentTab('badge');
		}
	};

	const deleteClickHandler = async () => {
		showAlert({
			title: translations.btns.remove,
			text: `${translations.btns.remove}?`,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deleteBadgeWithServices();
					},
				},
			],
		});
	};

	const colorClickHandler = (currentPicker: string) => {
		setPicker(currentPicker);
		if (currentBadge) {
			const currentColor = currentBadge[currentPicker as keyof Badge];
			setColor(`${currentColor}`);
		}
	};

	const setHexColor = (pickedColor: string) => {
		setColor(pickedColor);
		setCurrentBadge({
			...currentBadge,
			[`${picker}`]: pickedColor,
		});
	};

	const onChangeToggleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsNotificationEnabled(checked);
		if (!checked) {
			setCurrentBadge({
				...currentBadge,
				communicationPic: '',
				communicationText: '',
				communicationTitle: '',
				picFile: null,
			});
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setCurrentBadge({
			...currentBadge,
			[event.target.name]: value,
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});
	};

	const setPic = (file: File | null) => {
		setCurrentBadge({
			...currentBadge,
			communicationPic: file ? URL.createObjectURL(file) : '',
			picFile: file,
		});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		const btnName = e.nativeEvent.submitter.getAttribute('name');
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else save();
	};

	useEffect(() => {
		if (slug) {
			setCurrentBadge({
				...badge,
				tooltip: badge.tooltip === null ? '' : badge.tooltip,
				tooltipLength: checkRealTextLength(badge.tooltip),
				communicationTitleLength: checkRealTextLength(badge.communicationTitle),
				communicationTextLength: checkRealTextLength(badge.communicationText),
			});
			setIsNotificationEnabled(
				badge.communicationText || badge.communicationTitle || badge.communicationPic
			);
		}
	}, [slug]);

	return (
		<div>
			<div className='d-flex'>
				<div className='badges-config__creating w-50 mb-5'>
					<Form noValidate validated={validated} onSubmit={handleSubmit} id='createBadgeForm'>
						<div className='block mb-3'>
							<p className='h5 mb-3'>{translations.uploadBadges.create.badge.name}</p>
							<p className='text-secondary'>{translations.uploadBadges.create.badge.description}</p>
							<Form.Group controlId='badgeTitle' className='mb-2'>
								<Form.Control
									placeholder={translations.uploadBadges.create.badge.name}
									name='text'
									value={currentBadge.text}
									onChange={onChangeHandler}
									maxLength={140}
									required
								/>
							</Form.Group>
							<Row className='mb-4 pt-4 position-relative'>
								<Col md={3}>
									<p>{translations.uploadBadges.backgroundColor}</p>
									<button
										type='button'
										className={`badges-config__color ${
											picker === 'backgroundColor' ? `badges-config__color--active` : ''
										}`}
										style={{backgroundColor: currentBadge.backgroundColor}}
										onClick={() => colorClickHandler('backgroundColor')}>
										{' '}
									</button>
								</Col>

								<Col md={3}>
									<div>
										<p>{translations.uploadBadges.textColor}</p>
										<div className='d-flex'>
											<button
												type='button'
												className={`badges-config__color ${
													picker === 'textColor' ? `badges-config__color--active` : ''
												}`}
												style={{backgroundColor: currentBadge.textColor}}
												onClick={() => colorClickHandler('textColor')}>
												{' '}
											</button>
										</div>
									</div>
								</Col>
							</Row>
							{picker && (
								<HexColorPicker
									color={color}
									onChange={pickedColor => setHexColor(pickedColor)}
									className='w-100'
								/>
							)}
						</div>
						<div className='block mb-3'>
							<p className='h5 mb-3'>{translations.uploadBadges.create.notification.title}</p>
							<p className='text-secondary'>
								{translations.uploadBadges.create.notification.description}
							</p>

							<Form.Check
								name='notification'
								onChange={onChangeToggleHandler}
								type='switch'
								id='isNotificationEnabled'
								label={translations.uploadBadges.create.notification.toggle}
								checked={isNotificationEnabled}
								className='mb-4'
							/>

							{isNotificationEnabled && (
								<div className='mb-3'>
									<div>
										<div className='mb-4'>
											<Form.Label>{translations.uploadBadges.image}</Form.Label>
											<p>
												<small>{translations.imageReqs.imageWeight(getFileSize(10485760))}</small>
												<small>{translations.imageReqs.imageMinSize([190, 190])}</small>
											</p>

											<UploadImage
												id='uploadHighlight'
												setPic={setPic}
												pic={currentBadge.communicationPic}
												objectMinSize={{width: 190, height: 190}}
												size={10485760}
												acceptedFileTypes='.jpg, .jpeg, .png, .gif, .svg'
												required={false}
											/>
										</div>

										<Form.Group controlId='notificationTitle' className='mb-4'>
											<p className='mb-1'>{translations.uploadBadges.title}</p>
											<Form.Control
												placeholder={translations.uploadBadges.title}
												name='communicationTitle'
												value={currentBadge.communicationTitle}
												onChange={onChangeHandler}
												maxLength={140}
												required={isNotificationEnabled && !badge.communicationText}
											/>
										</Form.Group>
										<p className='text-end text-secondary'>
											<small>{currentBadge.communicationTitleLength || 0}/140</small>
										</p>

										<Form.Group controlId='notificationDescr' className='mb-2'>
											<p className='mb-1'>{translations.uploadBadges.description}</p>
											<Form.Control
												as='textarea'
												rows={4}
												placeholder={translations.uploadBadges.description}
												name='communicationText'
												value={currentBadge.communicationText}
												onChange={onChangeHandler}
												maxLength={180}
												required={isNotificationEnabled && !badge.communicationTitle}
											/>
										</Form.Group>
										<p className='text-end text-secondary'>
											<small>{currentBadge.communicationTextLength || 0}/180</small>
										</p>
									</div>
								</div>
							)}
						</div>

						<div className='block'>
							<p className='h5 mb-3'>{translations.uploadBadges.create.tooltip.title}</p>
							<p className='text-secondary'>
								{translations.uploadBadges.create.tooltip.description}
							</p>

							<Form.Group controlId='notificationDescr' className='mb-2'>
								<Form.Control
									as='textarea'
									rows={4}
									placeholder={translations.uploadBadges.create.tooltip.title}
									name='tooltip'
									value={currentBadge.tooltip}
									onChange={onChangeHandler}
									maxLength={140}
								/>
							</Form.Group>
							<p className='text-end text-secondary'>
								<small>{currentBadge.tooltipLength || 0}/140</small>
							</p>
						</div>
					</Form>
				</div>
				<div>
					<BadgePreview />
				</div>
			</div>
			<div className='badges-config__footer'>
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					onClick={cancelClickHandler}>
					{translations.btns.cancel}
				</Button>

				<Button
					name='save-badge'
					variant='dark'
					className='d-flex mx-2'
					type='submit'
					form='createBadgeForm'>
					{translations.btns.save}
				</Button>

				{slug && (
					<button
						type='button'
						className='text-danger badges-config__delete-btn'
						onClick={() => deleteClickHandler()}>
						<BsTrashFill className='mx-2 h5 m-0 text-danger' />
						&nbsp;
						{translations.btns.remove}
					</button>
				)}
			</div>
		</div>
	);
};

export default observer(BadgeSettings);

/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {NavLink, useHistory, useParams, Prompt} from 'react-router-dom';
import classNames from 'classnames';

import {Container, Row, Col, Breadcrumb, Button, Spinner} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {Theme} from 'models/enums/Theme.enum';

import userServices from 'store/userServices';
import highlightsServices from 'store/highlightsServices';
import alertServices from 'store/alertServices';
import appService from 'store/appService';
import toastServices from 'store/toastServices';

import HighlightsService from 'services/api/HighlightsService';
import settingsServices from 'store/settingsServices';

import useHighlights from 'hooks/useHighlights';
import useSettings from 'hooks/useSettings';

import HighlightTemplates from './HighlightTemplates';
import HighlightSettings from './HighlightSettings';
import HighlightDuration from './HighlightDuration';
import HighlightRooms from './HighlightRooms';
import HighlightInfo from './HighlightInfo';

import './highlight.scss';

const CreateHighlight: FunctionComponent = function CreateHighlight() {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();
	const history = useHistory();

	const {accessToken} = useLocalObservable(() => userServices);
	const {
		currentHighlight,
		highlightPics,
		setHighlightPics,
		currentStep,
		setCurrentStep,
		clearCurrentHighlight,
		setIsLoaded,
	} = useLocalObservable(() => highlightsServices);

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {language, appTheme} = useLocalObservable(() => appService);
	const {themes, setCurrentThemeId} = useLocalObservable(() => settingsServices);

	const [createResponse, setCreateResponse] = useState({});
	const [steps, setSteps] = useState<string[]>([]);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const {getHighlight, createHighlight, updateHighlight} = useHighlights();
	const {getThemes, getColors} = useSettings();

	const toHighlightHistory = () => {
		history.push('/highlights');
	};

	const openConfirmAlert = () => {
		showAlert({
			title: translations.highlights.confirm,
			text: '',
			buttons: [
				{
					text: translations.btns.cancel,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.btns.send,
					type: AlertBtnType.SUCCESS,
					onClick: async () => {
						setVisiblePreloader(true);
						hideAlert();
						await createHighlight(setCreateResponse, setConfirmedNavigation);
						setVisiblePreloader(false);
					},
				},
			],
		});
	};

	const nextStep = () => {
		if (!slug) {
			if (currentStep === steps.length) {
				openConfirmAlert();
			} else setCurrentStep(currentStep + 1);
		}
		if (slug) {
			if (currentStep === steps.length) updateHighlight();
			else setCurrentStep(currentStep + 1);
		}
	};

	const saveDraft = (nextLocation?: any) => {
		setConfirmedNavigation(true);
		currentHighlight.picFile = null;
		currentHighlight.pic = '';
		localStorage.setItem('draft', JSON.stringify(currentHighlight));
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.draftCreated,
			variant: 'success',
		});
		!nextLocation &&
			setTimeout(() => {
				history.push('/highlights');
			}, 100);
	};

	const handleBlockedNavigation = (nextLocation: any) => {
		setLastLocation(nextLocation.pathname);
		if (!confirmedNavigation && nextLocation.pathname !== '/login') {
			showAlert({
				title: translations.alerts.saveChanges,
				text: translations.alerts.unsavedChanges,
				buttons: [
					{
						text: translations.btns.dontSave,
						type: AlertBtnType.DANGER,
						onClick: () => {
							hideAlert();
							localStorage.removeItem('draft');
							setConfirmedNavigation(true);
						},
					},
					{
						text: translations.alerts.btns.saveDraft,
						type: AlertBtnType.SUCCESS,
						onClick: () => {
							saveDraft(nextLocation);
							hideAlert();
							setConfirmedNavigation(true);
						},
					},
				],
			});
			return false;
		}
		return true;
	};

	const getHighlightPics = async () => {
		setVisiblePreloader(true);
		const response = await HighlightsService.getHighlightPics(accessToken);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) {
			setHighlightPics(response.data);
		}
	};

	const stepClasses = (step: number) =>
		classNames('highlights__steps-item', {
			'highlights__steps-item--active': currentStep === step,
		});

	useEffect(() => {
		if (themes.length) {
			getColors(themes[0].id);
			setCurrentThemeId(themes[0].id);
		}
	}, [themes]);

	useEffect(() => {
		slug && setCurrentStep(2);
		slug && accessToken && getHighlight(+slug);
		accessToken && getThemes();
		return () => {
			clearCurrentHighlight();
		};
	}, [accessToken]);

	useEffect(() => {
		if (accessToken) {
			getHighlightPics();
		}
	}, [accessToken]);

	useEffect(() => {
		setSteps([
			translations.highlights.selectTemplate,
			translations.highlights.settings,
			translations.highlights.selectTime,
			translations.highlights.selectRooms,
		]);
	}, [language]);

	useEffect(() => {
		return () => {
			setIsLoaded(false);
		};
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation);
		}
	}, [confirmedNavigation, lastLocation]);

	return (
		<Container fluid className='pt-4' id='create-highlight'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/highlights'>{translations.sidebar.highlights.title}</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							{slug ? translations.highlights.edit : translations.highlights.create}
						</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row className='mb-5 align-items-start'>
				<Col>
					<p className='h3 mb-0'>
						{slug ? translations.highlights.edit : translations.highlights.create}
					</p>
				</Col>
				<Col className='d-flex justify-content-end'>
					{slug && (
						<Button variant='dark' onClick={toHighlightHistory}>
							{translations.highlights.history}
						</Button>
					)}
					{!slug && currentStep !== 5 && (
						<Button
							variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
							onClick={() => saveDraft()}>
							{translations.highlights.draft}
						</Button>
					)}
				</Col>
			</Row>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{!visiblePreloader && highlightPics.length > 0 && (
				<div>
					{currentStep !== 5 && currentStep !== 6 && (
						<ul className='highlights__steps'>
							{steps.map((elem, index) => {
								return (
									<li className={stepClasses(index + 1)} key={index}>
										<p className='highlights__steps-text'>{elem}</p>
									</li>
								);
							})}
						</ul>
					)}

					{currentStep === 1 && <HighlightTemplates />}
					{currentStep === 2 && <HighlightSettings nextStep={nextStep} />}
					{currentStep === 3 && <HighlightDuration nextStep={nextStep} steps={steps} />}
					{currentStep === 4 && !visiblePreloader && (
						<HighlightRooms nextStep={nextStep} steps={steps} />
					)}
					{visiblePreloader && currentStep === 4 && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}
					{currentStep === 5 && <HighlightInfo createResponse={createResponse} />}

					{!slug && <Prompt when message={handleBlockedNavigation} />}
				</div>
			)}
		</Container>
	);
};

export default observer(CreateHighlight);

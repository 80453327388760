import {action, makeObservable, observable} from 'mobx';
import {Ban} from 'models/ban';

class BanServices {
	@observable
	public autoBansCount = 0;

	@observable
	public autoBans: Ban[] = [];

	@action
	public setAutoBansCount = (value: number) => {
		this.autoBansCount = value;
	};

	@action
	public setAutoBans = (value: Ban[]) => {
		this.autoBans = value;
	};

	@action
	public addAutoBan = (value: Ban) => {
		this.autoBans = [value, ...this.autoBans];
	};

	@action
	public removeAutoBan = (banId: number) => {
		this.autoBans = [...this.autoBans].filter(el => el.id !== banId);
	};

	@action
	public increaseBansCount = () => {
		this.autoBansCount += 1;
	};

	@action
	public decreaseBansCount = () => {
		this.autoBansCount -= 1;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new BanServices();

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import Moment from 'react-moment';

import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Button,
	Spinner,
	Image,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import {BsFillPencilFill, BsTrashFill} from 'react-icons/bs';

import RoomService from 'services/api/RoomService';
import userServices from 'store/userServices';
import toastService from 'store/toastServices';
import alertService from 'store/alertServices';
import appService from 'store/appService';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';

import CreateRoomModal from 'components/modals/createRoom/CreateRoomModal';

import useL10n from 'l10n/useL10n';
import {Theme} from 'models/enums/Theme.enum';
import PageTitle from 'components/pageTitle/PageTitle';
import modalServices from 'store/modalServices';
import {Room} from 'models/room';

const PreCreated: FunctionComponent = function PreCreated() {
	const [rooms, setRooms] = useState([]);
	const [editedRoom, setEditedRoom] = useState<Room | null>(null);
	const [page, setPage] = useState(0);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [mode, setMode] = useState('create');
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {showRoomModal} = useLocalObservable(() => modalServices);
	const {projectId, appTheme} = useLocalObservable(() => appService);

	const translations = useL10n();

	const isTinkoff = projectId.includes('tinkoff') || projectId === 'dev';

	const getRooms = async () => {
		if (accessToken) {
			setVisiblePreloader(true);
			const response = await RoomService.getPreCreatedRooms(accessToken);
			setVisiblePreloader(false);
			if (response.status === ResponseStatus.SUCCESS) setRooms(response.data.rooms);
		}
	};

	const deleteRoom = async (externalRoomId: string) => {
		let responseText;
		const response = await RoomService.deleteRoom(externalRoomId, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.roomDeleted;
			getRooms();
		} else responseText = translations.toasts.error;
		addToast({
			title: translations.toasts.room(externalRoomId),
			text: responseText,
		});
	};

	const openAlert = (externalRoomId: string) => {
		showAlert({
			title: translations.alerts.room(externalRoomId),
			text: translations.alerts.deleteRoom,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.delete,
					type: AlertBtnType.DANGER,
					onClick: () => {
						deleteRoom(externalRoomId);
						hideAlert();
					},
				},
			],
		});
	};

	const openAlertSuccess = (externalRoomId: string) => {
		getRooms();
		addToast({
			title: translations.toasts.createRoom(externalRoomId),
			text: translations.toasts.roomCreated(externalRoomId),
			variant: 'success',
		});
	};

	const openAlertError = (errorText: string) => {
		showAlert({
			title: translations.alerts.createRoom,
			text:
				errorText === 'Комната с таким ключом уже существует'
					? translations.alerts.alreadyExist
					: translations.toasts.error,
			buttons: [
				{
					text: translations.alerts.btns.ok,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const createRoomHandler = () => {
		setMode('create');
		showRoomModal();
	};

	const editClickHandler = async (room: Room) => {
		setEditedRoom(room);
		setMode('edit');
		showRoomModal();
	};

	useEffect(() => {
		getRooms();
	}, [accessToken]);

	const renderRoom = useCallback(
		(item: Room, index: number) => {
			return (
				<tr key={index}>
					<td>
						<p className='text-truncate'>
							<NavLink to={`/room/${encodeURIComponent(item.externalRoomId)}`}>
								{item.externalRoomId}
							</NavLink>
						</p>
					</td>
					<td>
						<p>{item.name || translations.empty.nameEmpty}</p>
					</td>
					{isTinkoff && (
						<td>
							<p className='text-truncate'>{item.about || ''}</p>
						</td>
					)}
					{isTinkoff && (
						<td className='text-center'>
							<Image src={item.pic} className='image-preview' />
						</td>
					)}
					<td className='text-center text-secondary'>
						{item.startTime && (
							<Moment local format='DD.MM.YY HH:mm'>
								{item.startTime}
							</Moment>
						)}
					</td>
					<td className='text-center text-secondary'>
						{item.endTime && (
							<Moment local format='DD.MM.YY HH:mm'>
								{item.endTime}
							</Moment>
						)}
					</td>
					<td className='text-center col-1'>
						<OverlayTrigger
							placement='bottom'
							delay={{show: 50, hide: 50}}
							overlay={
								<Tooltip id='button-tooltip-2'>
									<b>{translations.btns.edit}</b>
								</Tooltip>
							}>
							<Button
								size='sm'
								variant='secondary'
								className='mx-2'
								onClick={() => editClickHandler(item)}>
								<BsFillPencilFill />
							</Button>
						</OverlayTrigger>
						<Button variant='danger' size='sm' onClick={() => openAlert(item.externalRoomId)}>
							<BsTrashFill />
						</Button>
					</td>
				</tr>
			);
		},
		[rooms]
	);

	return (
		<Container fluid className='pt-4 text-lg'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.rooms.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.createdRooms.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.createdRooms.title}
						text={translations.sidebar.createdRooms.descr}
					/>

					<Button variant='dark' className='mb-4' onClick={createRoomHandler}>
						{translations.btns.createRoom}
					</Button>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{rooms?.length > 0 ? (
						<Table
							responsive
							striped={appTheme === Theme.LIGHT}
							bordered
							hover
							variant={appTheme?.toLowerCase()}>
							<thead>
								<tr>
									<th>
										<b>{translations.table.externalRoomId}</b>
									</th>
									<th>
										<b>{translations.table.name}</b>
									</th>
									{isTinkoff && (
										<th>
											<b>{translations.table.description}</b>
										</th>
									)}
									{isTinkoff && (
										<th className='col-1 text-center'>
											<b>{translations.table.pic}</b>
										</th>
									)}
									<th className='text-center col-2'>
										<b>{translations.table.timeStart}</b>
									</th>

									<th className='text-center col-2'>
										<b>{translations.table.timeEnd}</b>
									</th>
									<th className='col-1 text-center'> </th>
								</tr>
							</thead>
							<tbody>{rooms.map((elem, index) => renderRoom(elem, index))}</tbody>
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.roomsEmpty}</p>
					)}
				</Col>
			</Row>
			<CreateRoomModal
				onCloseSuccess={externalRoomId => openAlertSuccess(externalRoomId)}
				onCloseError={error => openAlertError(error)}
				mode={mode}
				editedRoom={editedRoom}
			/>
		</Container>
	);
};

export default observer(PreCreated);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import useL10n from 'l10n/useL10n';
import {Container, Row, Col, Breadcrumb, Badge, Image, Button} from 'react-bootstrap';
import PollService from 'services/api/PollService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';
import {Poll} from 'models/poll';
import Moment from 'react-moment';
import pollServices from 'store/pollServices';
import modalServices from 'store/modalServices';
import PollRoomModal from 'components/modals/pollRoomModal/pollRoomModal';
import './components/polls/polls-result.scss';
import {textEnding} from 'utils/helpers';

const PollsResult: FunctionComponent = function PollsRsult() {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [poll, setPoll] = useState<Poll | null>(null);
	const [votes, setVotes] = useState(0);

	const {accessToken} = useLocalObservable(() => userServices);
	const {setCurrentPoll} = useLocalObservable(() => pollServices);
	const {togglePollRoomModalVisible} = useLocalObservable(() => modalServices);

	const getPollWithServices = async (id: number) => {
		const response = await PollService.getPoll(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			setPoll(response.data);
		}
		setVisiblePreloader(false);
	};

	const openPollRoomsModal = () => {
		togglePollRoomModalVisible(true);
		setCurrentPoll(poll);
	};

	useEffect(() => {
		if (poll) setVotes(poll.options.map(el => el.votesLength || 0).reduce((sum, a) => sum + a, 0));
	}, [poll]);

	useEffect(() => {
		slug && getPollWithServices(+slug);
	}, [slug]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.pollsQuizzes.title}</Breadcrumb.Item>
					</Breadcrumb>
					<div className='d-flex align-items-center mb-4'>
						<NavLink to='/polls'>{translations.prev}</NavLink>
					</div>
					{!visiblePreloader && poll && (
						<p className='h3 mb-5'>
							{translations.polls.results} «{poll.text}»
						</p>
					)}
				</Col>
			</Row>
			<section>
				<Row className='mb-5'>
					<Col md={2}>
						<p className='text-muted'>{translations.polls.startTime}</p>
						<div>
							<b>
								{' '}
								{poll?.startTime ? (
									<Moment format='DD.MM.YY HH:mm'>{poll?.startTime}</Moment>
								) : (
									<Moment format='DD.MM.YY HH:mm'>{poll?.createdAt}</Moment>
								)}
							</b>
						</div>
					</Col>
					{(poll?.endTime || poll?.finishedAt) && (
						<Col md={2}>
							<p className='text-muted'>{translations.polls.endTime}</p>
							<div>
								<b>
									{' '}
									{poll?.endTime ? (
										<Moment format='DD.MM.YY HH:mm'>{poll?.endTime}</Moment>
									) : (
										poll?.finishedAt && <Moment format='DD.MM.YY HH:mm'>{poll?.finishedAt}</Moment>
									)}
								</b>
							</div>
						</Col>
					)}
					<Col md={2}>
						<p className='text-muted'>{translations.polls.roomList}</p>
						{poll?.roomPolls.length === 1 ? (
							<div>
								<NavLink
									to={`/room/${encodeURIComponent(poll?.roomPolls[0]?.room.externalRoomId)}`}>
									{poll?.roomPolls[0]?.room.externalRoomId}
								</NavLink>
							</div>
						) : (
							<div className='text-truncate'>
								<div>
									{poll?.roomPolls[0]?.room.externalRoomId && (
										<NavLink
											to={`/room/${encodeURIComponent(poll?.roomPolls[0]?.room.externalRoomId)}`}>
											{poll?.roomPolls[0]?.room.externalRoomId}
											...
										</NavLink>
									)}
									<div className='m-2'>
										<Button size='sm' variant='light' onClick={openPollRoomsModal}>
											{translations.polls.roomList}
										</Button>
									</div>
								</div>
							</div>
						)}
					</Col>
					<Col md={4}>
						<p className='text-muted'>{translations.polls.creator}</p>
						{poll?.creatorTalker ? (
							<div className='user-info'>
								<div
									className={`user-info__photo user-info__photo--sm background-color-${
										poll.creatorTalker.user?.color || 0
									}`}>
									<Image src={poll.creatorTalker.user?.pic} roundedCircle fluid />
								</div>
								<div className='text-truncate'>
									<Badge bg='light' pill>
										id{poll.creatorTalker.user?.id}
									</Badge>
									<div className='text-truncate'>{poll.creatorTalker.user?.name}</div>
								</div>
							</div>
						) : (
							<div>
								<b>admin</b>
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						{poll?.options.map((el, index) => {
							return (
								<div className='block mb-2 d-flex' key={index}>
									{el.pic && (
										<div className='polls-result__pic'>
											<Image src={el.pic} fluid />
										</div>
									)}
									<div className='polls-result__option'>
										<div className='d-flex justify-content-between align-items-center mb-3'>
											<div>
												<b>{el.text}</b>
											</div>
											{votes > 0 && (
												<div>
													<p className='text-secondary'>
														{Math.round((el.votesLength / votes) * 100)}%
													</p>
												</div>
											)}
										</div>
										<div className='polls-result__progress'>
											<span
												className='polls-result__progress-bar'
												style={{
													width: `${Math.round((el.votesLength / votes) * 100)}%`,
												}}
											/>
										</div>

										{el.votesLength > 0 && (
											<small className='text-muted'>
												{el.votesLength}{' '}
												{textEnding(el.votesLength, translations.poll.results.words)}
											</small>
										)}
									</div>
								</div>
							);
						})}
					</Col>
					<Col md={4}>
						<div className='block'>
							<div>
								<small className='text-muted'>{translations.polls.totalAnswers}</small>
							</div>
							{votes > 0 && (
								<p className='display-1'>
									<b>{votes}</b>
								</p>
							)}
						</div>
					</Col>
				</Row>
			</section>
			<PollRoomModal />
		</Container>
	);
};

export default observer(PollsResult);

import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class SemanticAnalysisService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		SemanticAnalysisService.baseUrl = baseUrl;
	};

	static getPrompts = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${SemanticAnalysisService.baseUrl}/prompt`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPrompt = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.get(`${SemanticAnalysisService.baseUrl}/prompt/${id}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deletePrompt = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.delete(`${SemanticAnalysisService.baseUrl}/prompt/${id}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPromptHistory = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${SemanticAnalysisService.baseUrl}/prompt/history`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addPromptHistory = async (token: UUID, promptId: number) => {
		try {
			const body = {
				promptId,
			};
			const {data, status} = await axios.post(
				`${SemanticAnalysisService.baseUrl}/prompt/history`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deletePromptHistory = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.delete(
				`${SemanticAnalysisService.baseUrl}/prompt/history/${id}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getModels = async (token: UUID, key: string) => {
		try {
			const {data, status} = await axios.get(
				`${SemanticAnalysisService.baseUrl}/openai/models/${key}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getOpenaiKey = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`${SemanticAnalysisService.baseUrl}/prompt/key`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addPrompt = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(`${SemanticAnalysisService.baseUrl}/prompt`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static endPrompt = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(
				`${SemanticAnalysisService.baseUrl}/prompt/end`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message);
		}
	};

	static promptDraft = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(
				`${SemanticAnalysisService.baseUrl}/prompt/draft`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response?.data?.message);
		}
	};
}

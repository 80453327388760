/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Spinner,
	Button,
	Navbar,
	FloatingLabel,
	Form,
	InputGroup,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import flaggedMessageService from 'store/flaggedMessageService';

import useFlaggedMessage from 'hooks/useFlaggedMessage';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import AdminProxyService from 'services/api/AdminProxyService';

import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

import PageTitle from 'components/pageTitle/PageTitle';
import FlaggedMessageItem from './components/flaggedMessage/FlaggedMessageItem';

const LIMIT = 20;

const FlaggedMessage: FunctionComponent = function FlaggedMessage() {
	const translations = useL10n();
	const {accessToken, setAdmins, isAdmin} = useLocalObservable(() => userServices);
	const {flaggedMessages, setFlaggedMessages} = useLocalObservable(() => flaggedMessageService);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [sortBy, setSortBy] = useState('createdAt_desc');
	const [offset, setOffset] = useState(0);
	const [search, setSearch] = useState('');
	const [searchBy, setSearchBy] = useState('message__room__externalRoomId');
	const [isSearching, setIsSearching] = useState(false);
	const [searchFilter, setSearchFilter] = useState<any>({
		key: 'message__room__externalRoomId',
		value: null,
		prefix: 'like',
	});

	const {getFlaggedMessages} = useFlaggedMessage();

	const getFlaggedMessagesWithService = async (
		limit?: number,
		currentOffset?: number,
		order?: string,
		filter?: any
	) => {
		let allFilters = null;
		if (filter?.value) {
			allFilters = filter;
		}
		setVisiblePreloader(true);
		const response = await getFlaggedMessages(
			limit || LIMIT,
			currentOffset || 0,
			order || 'createdAt_desc',
			allFilters
		);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) {
			setFlaggedMessages(response.data);
		} else setFlaggedMessages([]);
	};

	const getAdmins = async () => {
		setVisiblePreloader(true);
		const response = await AdminProxyService.getAdmins(accessToken);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) setAdmins(response.data);
	};

	const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsSearching(!isSearching);
	};

	const onSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === '') return;
		setSortBy(value);
		setOffset(0);

		getFlaggedMessagesWithService(LIMIT, 0, value, searchFilter);
	};

	const onSearchSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSearchFilter({...searchFilter, key: value, value: search});
		setSearchBy(value);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getFlaggedMessagesWithService(LIMIT, offset - LIMIT, sortBy);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getFlaggedMessagesWithService(LIMIT, offset + LIMIT, sortBy);
	};

	const onSearchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
		setSearchFilter({...searchFilter, value});
	};

	const clearFilters = () => {
		setIsSearching(!isSearching);
		setSearchBy('message__room__externalRoomId');
		setSearch('');
		setSearchFilter({
			key: 'message__room__externalRoomId',
			value: null,
			prefix: 'like',
		});

		setSortBy('createdAt_desc');
	};

	useEffect(() => {
		if (accessToken) {
			setOffset(0);
			getFlaggedMessagesWithService(LIMIT, 0, sortBy, searchFilter);
			getAdmins();
		}
	}, [accessToken, isSearching]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.feedback}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.flaggedMessage.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.flaggedMessage.title}
						text={translations.sidebar.flaggedMessage.descr}
					/>
				</Col>
			</Row>
			<Navbar variant='dark' bg='dark' expand='lg' className='mb-3'>
				<Container fluid>
					<Navbar className='w-100 d-flex p-0'>
						<div className=' w-100 d-flex align-items-center justify-content-between'>
							<div className='d-flex'>
								<Form onSubmit={handleSearch}>
									<InputGroup>
										<FloatingLabel
											controlId='floatingSelect'
											label={translations.search.placeholder}>
											<Form.Select onChange={onSearchSelect} value={searchBy}>
												<option value='message__room__externalRoomId'>
													{translations.table.event}
												</option>
												<option value='message__text'>{translations.table.message}</option>
											</Form.Select>
										</FloatingLabel>

										<Form.Control
											placeholder={translations.search.btn}
											value={search}
											onChange={onSearchChangeHandler}
										/>

										<Button variant='secondary' id='button-search' type='submit'>
											{translations.search.btn}
										</Button>
									</InputGroup>
								</Form>
								&nbsp;
								<Button variant='dark' onClick={clearFilters}>
									{translations.clear}
								</Button>
							</div>
							<div className='filters__btns'>
								<Button
									className='d-flex'
									onClick={onPrevPageBtnClickHandler}
									disabled={(!flaggedMessages.length && offset === 0) || offset === 0}>
									<BsArrowLeftCircleFill />
								</Button>
								&nbsp;
								<Button
									className='d-flex'
									onClick={onNextPageBtnClickHandler}
									disabled={!flaggedMessages.length || flaggedMessages.length < LIMIT}>
									<BsArrowRightCircleFill />
								</Button>
							</div>
						</div>
					</Navbar>
				</Container>
			</Navbar>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{flaggedMessages.length > 0 ? (
				<Table
					responsive
					striped={appTheme === Theme.LIGHT}
					bordered
					hover
					variant={appTheme?.toLowerCase()}>
					<thead>
						<tr>
							<th className='align-middle col-2'>
								<b>{translations.table.user}</b>
							</th>
							<th className='align-middle col-1'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.sentAt}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.sentAt}</option>
										<option value='createdAt_desc'>{translations.table.sentAt} &darr;</option>
										<option value='createdAt_asc'>{translations.table.sentAt} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='align-middle text-center col-1'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.event}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.event}</option>
										<option value='message__room__id_desc'>
											{translations.table.event} &darr;
										</option>
										<option value='message__room__id_asc'>{translations.table.event} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='align-middle text-center col-3'>
								<b>{translations.table.message}</b>
							</th>
							<th className='align-middle text-center col-1'>
								<b>{translations.table.actions}</b>
							</th>
							<th className='align-middle col-2'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.comment}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.comment}</option>
										<option value='comment_desc'>{translations.table.comment} &darr;</option>
										<option value='comment_asc'>{translations.table.comment} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='align-middle col-1'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.resolveTime}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.resolveTime}</option>
										<option value='resolvedAt_desc'>{translations.table.resolveTime} &darr;</option>
										<option value='resolvedAt_asc'>{translations.table.resolveTime} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							{isAdmin && (
								<th className='text-center col-1'>
									<b>{translations.table.creator}</b>
								</th>
							)}
						</tr>
					</thead>
					<tbody>
						{flaggedMessages
							.filter(el => el.message)
							.map((elem, index) => (
								<FlaggedMessageItem
									item={elem}
									key={index}
									getFlaggedMessages={() =>
										getFlaggedMessagesWithService(LIMIT, offset, sortBy, searchFilter)
									}
								/>
							))}
					</tbody>
				</Table>
			) : (
				!visiblePreloader && <p>{translations.empty.chatEmpty}</p>
			)}
		</Container>
	);
};

export default observer(FlaggedMessage);

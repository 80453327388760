import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import useL10n from 'l10n/useL10n';
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Form,
	FloatingLabel,
	Button,
	Badge,
	Alert,
} from 'react-bootstrap';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import WebhooksService from 'services/api/WebhooksService';
import userServices from 'store/userServices';
import appService from 'store/appService';
import modalServices from 'store/modalServices';
import WebhooksResponseModal from 'components/modals/webhooksResponseModal/WebhooksResponseModal';
import {ReactComponent as IcoWarn} from 'assets/svg/ico-warn.svg';
import {Theme} from 'models/enums/Theme.enum';

const Webhooks: FunctionComponent = function Webhooks() {
	const translations = useL10n();
	const [eventWebhookUrl, setEventWebhookUrl] = useState('');
	const [roomWebhookUrl, setRoomWebhookUrl] = useState('');
	const [eventWebhookFailed, setEventWebhookFailed] = useState(false);
	const [roomWebhookFailed, setRoomWebhookFailed] = useState(false);
	const [validatedEventUrl, setValidatedEventUrl] = useState(false);
	const [validatedRoomUrl, setValidatedRoomUrl] = useState(false);
	const [response, setResponse] = useState<any>(null);
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [enableEventWebhook, setEnableEventWebhook] = useState(false);
	const [enableRoomWebhook, setEnableRoomWebhook] = useState(false);
	const [currentUrl, setCurrentUrl] = useState('');
	const {accessToken} = useLocalObservable(() => userServices);
	const {settings} = useLocalObservable(() => settingsServices);
	const {projectId, appTheme} = useLocalObservable(() => appService);
	const {toggleWebhooksResponseModalVisible} = useLocalObservable(() => modalServices);

	const {patchSettings} = useSettings();

	const onChangeEventUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setEventWebhookUrl(value);
	};

	const onChangeRoomUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setRoomWebhookUrl(value);
	};

	const onToggleEventWebhookHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = event.target;
		setEnableEventWebhook(checked);
		if (eventWebhookFailed) setEventWebhookFailed(false);

		patchSettings({
			[name]: checked,
		});
	};

	const onToggleRoomWebhookHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = event.target;
		setEnableRoomWebhook(checked);
		if (roomWebhookFailed) setRoomWebhookFailed(false);

		patchSettings({
			[name]: checked,
		});
	};

	const handleSubmitEventUrl = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedEventUrl(true);
		else {
			patchSettings({
				eventWebhookUrl,
			});
		}
	};

	const handleSubmitRoomUrl = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedRoomUrl(true);
		else {
			patchSettings({
				roomWebhookUrl,
			});
		}
	};

	const testUrl = async (url: string) => {
		setCurrentUrl(url);
		setVisiblePreloader(true);
		const resp = await WebhooksService.checkWebhooksUrl(accessToken, url, {
			event: 'test',
			value: {
				user_id: 'test',
				project: projectId,
			},
		});
		setVisiblePreloader(false);
		setResponse(resp);
		toggleWebhooksResponseModalVisible(true);
	};

	useEffect(() => {
		if (settings?.enableEventWebhook) setEnableEventWebhook(settings.enableEventWebhook);
		if (settings?.eventWebhookUrl) setEventWebhookUrl(settings.eventWebhookUrl);
		if (settings?.eventWebhookFailed) setEventWebhookFailed(settings.eventWebhookFailed);
		if (settings?.roomWebhookUrl) setRoomWebhookUrl(settings.roomWebhookUrl);
		if (settings?.enableRoomWebhook) setEnableRoomWebhook(settings.enableRoomWebhook);
		if (settings?.roomWebhookFailed) setRoomWebhookFailed(settings.roomWebhookFailed);
	}, [settings]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.webhooks}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.webhooks}</p>
				</Col>
			</Row>

			<Row className='mb-3'>
				<Col md={6}>
					<div className='block'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<span className='h5'>{translations.webhooks.getEvents}</span>
							{eventWebhookFailed && (
								<Badge bg='danger' pill>
									Paused
								</Badge>
							)}
							{!eventWebhookFailed && settings?.enableEventWebhook && (
								<Badge bg='success' pill>
									Active
								</Badge>
							)}
						</div>

						<div className='mb-3'>
							<Form.Check
								onChange={onToggleEventWebhookHandler}
								type='switch'
								name='enableEventWebhook'
								id='enableEventWebhookToggle'
								label={translations.webhooks.enable}
								checked={enableEventWebhook}
							/>
						</div>

						<Form noValidate validated={validatedEventUrl} onSubmit={handleSubmitEventUrl}>
							<FloatingLabel
								controlId='eventWebhookUrl'
								label='URL'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									name='eventWebhookUrl'
									placeholder='Oauth Url'
									value={eventWebhookUrl}
									onChange={onChangeEventUrlHandler}
									required
									type='url'
								/>
							</FloatingLabel>
							<Button type='submit' variant='dark'>
								{translations.btns.save}
							</Button>
							<Button
								variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
								className='mx-2'
								onClick={() => testUrl(eventWebhookUrl)}
								disabled={!eventWebhookUrl}>
								{translations.btns.test}
							</Button>
						</Form>
					</div>
				</Col>
				<Col md={3}>
					{eventWebhookFailed && (
						<Alert variant='danger' className='d-flex align-items-start'>
							<IcoWarn className='ico-lg' />
							<div className='mx-2'>
								<p className='h6 text-danger'>{translations.webhooks.errorAlertTitle}</p>
								<p className='text-black mb-0'>{translations.webhooks.errorAlertText}</p>
							</div>
						</Alert>
					)}
				</Col>
			</Row>

			<Row>
				<Col md={6}>
					<div className='block'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<span className='h5'>{translations.webhooks.rooms}</span>
							{roomWebhookFailed && (
								<Badge bg='danger' pill>
									Paused
								</Badge>
							)}
							{!roomWebhookFailed && settings?.enableRoomWebhook && (
								<Badge bg='success' pill>
									Active
								</Badge>
							)}
						</div>

						<div className='mb-3'>
							<Form.Check
								onChange={onToggleRoomWebhookHandler}
								type='switch'
								name='enableRoomWebhook'
								id='enableRoomWebhookToggle'
								label={translations.webhooks.enable}
								checked={enableRoomWebhook}
							/>
						</div>

						<Form noValidate validated={validatedRoomUrl} onSubmit={handleSubmitRoomUrl}>
							<FloatingLabel
								controlId='roomWebhookUrl'
								label='URL'
								className='w-100 text-secondary mb-3'>
								<Form.Control
									name='roomWebhookUrl'
									placeholder='Oauth Url'
									value={roomWebhookUrl}
									onChange={onChangeRoomUrlHandler}
									required
									type='url'
								/>
							</FloatingLabel>
							<Button type='submit' variant='dark'>
								{translations.btns.save}
							</Button>
							<Button
								variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
								className='mx-2'
								onClick={() => testUrl(roomWebhookUrl)}
								disabled={!roomWebhookUrl}>
								{translations.btns.test}
							</Button>
						</Form>
					</div>
				</Col>
				<Col md={3}>
					{roomWebhookFailed && (
						<Alert variant='danger' className='d-flex align-items-start'>
							<IcoWarn className='ico-lg' />
							<div className='mx-2'>
								<p className='h6 text-danger'>{translations.webhooks.errorAlertTitle}</p>
								<p className='text-black mb-0'>{translations.webhooks.errorAlertText}</p>
							</div>
						</Alert>
					)}
				</Col>
			</Row>

			<WebhooksResponseModal
				response={response}
				setResponse={setResponse}
				testUrl={() => testUrl(currentUrl)}
				visiblePreloader={visiblePreloader}
			/>
		</Container>
	);
};

export default observer(Webhooks);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Button, Container, Form, Image} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import Logo from 'assets/images/auth-logo.png';
import {useHistory} from 'react-router-dom';
import authService from 'store/authService';

import '../auth.scss';
import useAdminProxy from 'hooks/useAdminProxy';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import RegisterFooter from '../register/footer/RegisterFooter';

const Recover: FunctionComponent = function Recover() {
	const {auth} = useL10n();
	const history = useHistory();

	const [validated, setValidated] = useState(false);
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {user, setUser} = useLocalObservable(() => authService);
	const {passwordReset} = useAdminProxy();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setEmail(value);
		setUser({email: value});
		setError('');
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		setVisiblePreloader(true);
		const response = await passwordReset(email);
		if (response.status === ResponseStatus.SUCCESS) {
			setVisiblePreloader(false);
			history.push('/recover/confirm');
			return;
		}

		if (response.status === ResponseStatus.ERROR) {
			setError(response.data);
		}
	};

	return (
		<section className='auth'>
			<Container className='mt-auto'>
				<div>
					<p className='h3 mb-2 text-center'>{auth.recover.title}</p>
					<p className='text-secondary mb-5 text-center'>{auth.recover.description}</p>
				</div>

				<div className='auth__form'>
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<Form.Group className='mb-4'>
							<Form.Label>Ваш адрес эл. почты</Form.Label>
							<Form.Control
								type='email'
								placeholder='mail@example.com'
								value={email}
								onChange={onChangeHandler}
								required
							/>
							{error && <p className='text-danger text-center mt-2'>{error}</p>}
						</Form.Group>

						<Button
							type='submit'
							variant='dark'
							size='lg'
							className='w-100 text-center'
							disabled={visiblePreloader}>
							{auth.recover.reset}
						</Button>
						<div className='auth__form-footer'>
							<p className='auth__form-footer-text'>{auth.recover.back}</p>

							<button
								type='button'
								className='btn-link text-info'
								onClick={() => history.push('/login')}>
								{auth.recover.loginForm}
							</button>
						</div>
					</Form>
				</div>

				{/* <div className='auth__recover-text'>
						<p className='text-secondary'>
							{auth.recover.footer.text}
							<button type='button' className='btn-link text-info'>
								{' '}
								{auth.recover.footer.btnText}
							</button>
						</p>
					</div> */}
			</Container>
			<RegisterFooter />
		</section>
	);
};

export default observer(Recover);

import {action, makeObservable, observable} from 'mobx';
import {Message, Talker} from 'models/room';

class RoomServices {
	@observable
	public count = '';

	@observable
	public submenuMessage: number | null = null;

	@observable
	public submenuMessagePinned = false;

	@observable
	public submenuAvatar: number | null = null;

	@observable
	public talkers: Talker[] = [];

	@observable
	public currentRoomId: string | null = null;

	@observable
	public status = '';

	@observable
	public isSpeak = true;

	@observable
	public isSlowmode = false;

	@observable
	public slowmodeTimeout = 1000;

	@observable
	public currentMessageId: number | null = null;

	@observable
	public pinnedMessages: Message[] = [];

	@observable
	public activePinnedMessage: Message | null = null;

	@action
	public setCurrentRoomId = (value: string | null) => {
		this.currentRoomId = value;
	};

	@action
	public setStatus = (value: string) => {
		this.status = value;
	};

	@action
	public setIsSpeak = (value: boolean) => {
		this.isSpeak = value;
	};

	@action
	public setIsSlowmode = (value: boolean) => {
		this.isSlowmode = value;
	};

	@action
	public setSlowmodeTimeout = (value: number) => {
		this.slowmodeTimeout = value;
	};

	@action
	public setRoomsCount = (value: string) => {
		this.count = value;
	};

	@action
	public setCurrentMessageId = (value: number | null) => {
		this.currentMessageId = value;
	};

	@action
	public setTalkers = (value: Talker[]) => {
		this.talkers = value;
	};

	@action
	public updateTalkerByTalkerId = (updatedTalker: Talker) => {
		this.talkers = this.talkers.map(talker => {
			if (talker && talker.id === updatedTalker.id) {
				return {...talker, ...updatedTalker};
			}
			return talker;
		});
	};

	@action
	public addTalker = (talker: Talker) => {
		if (this.talkers.find(el => el.id === talker.id) === undefined)
			this.talkers = [...this.talkers, talker];
	};

	@action
	public removeTalker = (talker: Talker) => {
		this.talkers = this.talkers.filter(t => t.id !== talker.id);
	};

	@action
	public setSubmenuMessage = (data: number | null) => {
		this.submenuMessage = data;
	};

	@action
	public setSubmenuMessagePinned = (data: boolean) => {
		this.submenuMessagePinned = data;
	};

	@action
	public setSubmenuAvatar = (data: number | null) => {
		this.submenuAvatar = data;
	};

	@action
	public setPinnedMessages = (data: Message[]) => {
		this.pinnedMessages = data;
		if (!this.activePinnedMessage) this.activePinnedMessage = data[data.length - 1];
	};

	@action
	public setActivePinnedMessage = (data: Message) => {
		this.activePinnedMessage = data;
	};

	@action
	public addPinnedMessage = (data: Message) => {
		this.pinnedMessages.push(data);
		this.pinnedMessages = this.pinnedMessages.sort(
			(a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
		);
		this.activePinnedMessage = data;
	};

	@action
	public removePinnedMessage = (message: Message) => {
		if (this.pinnedMessages.find(m => m.id === message.id)) {
			this.pinnedMessages = this.pinnedMessages.filter(item => item.id !== message.id);
		}
		const pinnedMessageIndex = this.pinnedMessages.findIndex(element => element.id === message.id);
		if (this.pinnedMessages.length) {
			this.setActivePinnedMessage(
				this.pinnedMessages[
					pinnedMessageIndex > 0 ? pinnedMessageIndex - 1 : pinnedMessageIndex + 1
				]
			);
		}
	};

	constructor() {
		makeObservable(this);
	}
}

export default new RoomServices();

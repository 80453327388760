/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Form,
	FloatingLabel,
	Button,
	Tabs,
	Tab,
} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import appService from 'store/appService';
import userServices from 'store/userServices';

const FunctionalSettings: FunctionComponent = function FunctionalSettings() {
	const translations = useL10n();
	const [mySettings, setMySettings] = useState<{
		enableUserStatus?: boolean;
		enableDecrypt?: boolean;
		enableRegistrationName?: boolean;
		enableRegistrationPicId?: boolean;
		enableRegistrationPicUrl?: boolean;
		enableAgora?: boolean;
		enableSaveUserIP?: boolean;
		enableLinksBlock?: boolean;
		enableOauth?: boolean;
		oauthUrl?: string;
		enableEventWebhook?: boolean;
		enableDomainWhitelist?: boolean;
		domainWhitelist?: string;
		isStand?: boolean;
	}>({});

	const {settings, globalSettings, projectLanguage} = useLocalObservable(() => settingsServices);
	const {showHiddenSettings} = useLocalObservable(() => appService);
	const {isCreator} = useLocalObservable(() => userServices);

	const {patchSettings, getGlobalSettings, patchGlobalSettings} = useSettings();

	const [validatedPicUrl, setValidatedPicUrl] = useState(false);
	const [validatedOauthUrl, setValidatedOauthUrl] = useState(false);
	const [validatedDomainWhitelist, setValidatedDomainWhitelist] = useState(false);

	const [externalPicUrl, setExternalPicUrl] = useState('');
	const [oauthUrl, setOauthUrl] = useState('');
	const [domainWhitelist, setDomainWhitelist] = useState('');
	const [isChatEnabled, setIsChatEnabled] = useState<boolean | undefined>(true);
	const [availableLangs, setAvailableLangs] = useState<string[]>([]);
	const [key, setKey] = useState('ru');
	const [langTextData, setLangTextData] = useState<{lang: string; text: string}[]>([]);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = event.target;
		setMySettings({
			...mySettings,
			[name]: checked,
		});

		patchSettings({
			[name]: checked,
		});
	};

	const onChangeTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		if (langTextData)
			setLangTextData(
				langTextData.map(el => {
					if (el.lang === key) return {...el, text: value};
					return el;
				})
			);
	};

	const onChangeGlobalSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = event.target;
		setIsChatEnabled(checked);

		patchGlobalSettings({
			[name]: checked,
		});
	};

	const onChangeUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setExternalPicUrl(value);
	};

	const onChangeOauthUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setOauthUrl(value);
	};

	const onChangeDomainWhitelistHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		const domainList = value
			.trim()
			.split(',')
			.map(id => {
				return id.trim();
			});
		setDomainWhitelist(domainList.toString());
	};

	const handleSubmitPicUrl = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedPicUrl(true);
		else {
			patchSettings({
				externalPicUrl,
			});
		}
	};

	const handleSubmitOauthUrl = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedOauthUrl(true);
		else {
			patchSettings({
				oauthUrl,
			});
		}
	};

	const handleSubmitDomainWhitelist = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedDomainWhitelist(true);
		else {
			patchSettings({
				domainWhitelist,
			});
		}
	};

	const saveDisabledChatText = () => {
		const value = JSON.stringify(langTextData);
		patchGlobalSettings({disabledChatText: value});
	};

	useEffect(() => {
		if (isCreator && availableLangs.length) {
			const value: any = [];
			availableLangs.forEach(lang => {
				value.push({lang, text: ''});
			});

			setLangTextData(value);

			if (globalSettings?.disabledChatText) {
				const getExistLansData = JSON.parse(globalSettings?.disabledChatText);

				if (getExistLansData) {
					const array = [...new Set([...getExistLansData, ...value])];

					setLangTextData(
						Array.from(
							array
								.reduce((acc, obj) => {
									const k = obj.lang;

									if (obj.text !== '' || !acc.has(k)) {
										acc.set(k, obj);
									}

									return acc;
								}, new Map())
								.values()
						)
					);
				}
			}
		}
	}, [availableLangs, isCreator, globalSettings]);

	useEffect(() => {
		if (isCreator) getGlobalSettings();
	}, [isCreator]);

	useEffect(() => {
		setIsChatEnabled(globalSettings?.enableChat);
	}, [globalSettings]);

	useEffect(() => {
		setKey(projectLanguage);
	}, [projectLanguage]);

	useEffect(() => {
		setMySettings({
			enableUserStatus: settings?.enableUserStatus,
			enableDecrypt: settings?.enableDecrypt,
			enableRegistrationName: settings?.enableRegistrationName,
			enableRegistrationPicId: settings?.enableRegistrationPicId,
			enableRegistrationPicUrl: settings?.enableRegistrationPicUrl,
			enableAgora: settings?.enableAgora,
			enableSaveUserIP: settings?.enableSaveUserIP,
			enableLinksBlock: settings?.enableLinksBlock,
			enableOauth: settings?.enableOauth,
			oauthUrl: settings?.oauthUrl,
			enableEventWebhook: settings?.enableEventWebhook,
			enableDomainWhitelist: settings?.enableDomainWhitelist,
			domainWhitelist: settings?.domainWhitelist,
			isStand: settings?.isStand,
		});
		if (settings?.externalPicUrl) setExternalPicUrl(settings?.externalPicUrl);
		if (settings?.oauthUrl) setOauthUrl(settings?.oauthUrl);
		if (settings?.domainWhitelist) setDomainWhitelist(settings?.domainWhitelist);
		if (settings.availableLangs) setAvailableLangs(settings.availableLangs?.split(','));
	}, [settings]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.functionalSettings}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.functionalSettings}</p>
					<div className='block w-50'>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableUserStatus'
								id='enableUserStatusToggle'
								label={translations.settings.enableUserStatus}
								checked={mySettings.enableUserStatus}
							/>
						</div>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableDecrypt'
								id='enableDecryptToggle'
								label={translations.settings.enableDecrypt}
								checked={mySettings.enableDecrypt}
							/>
						</div>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableRegistrationName'
								id='enableRegistrationNameToggle'
								label={translations.settings.enableRegistrationName}
								checked={mySettings.enableRegistrationName}
							/>
						</div>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableRegistrationPicId'
								id='enableRegistrationPicIdToggle'
								label={translations.settings.enableRegistrationPicId}
								checked={mySettings.enableRegistrationPicId}
								className='mb-3'
								disabled={mySettings.enableRegistrationPicUrl}
							/>
							{mySettings.enableRegistrationPicId && (
								<Form noValidate validated={validatedPicUrl} onSubmit={handleSubmitPicUrl}>
									<FloatingLabel
										controlId='externalPicUrl'
										label={translations.settings.externalPicUrl}
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='externalPicUrl'
											placeholder='ExternalPicUrl'
											value={externalPicUrl}
											onChange={onChangeUrlHandler}
											required
											type='url'
										/>
									</FloatingLabel>
									<Button type='submit'>{translations.btns.save}</Button>
								</Form>
							)}
						</div>
						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableRegistrationPicUrl'
								id='enableRegistrationPicUrlToggle'
								label={translations.settings.enableRegistrationPicUrl}
								checked={mySettings.enableRegistrationPicUrl}
								disabled={mySettings.enableRegistrationPicId}
							/>
						</div>

						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableSaveUserIP'
								id='enableSaveUserIPToggle'
								label={translations.settings.enableSaveUserIP}
								checked={mySettings.enableSaveUserIP}
							/>
						</div>

						<div className='mb-3'>
							<Form.Check
								onChange={onChangeHandler}
								type='switch'
								name='enableLinksBlock'
								id='enableLinksBlockToggle'
								label={translations.settings.enableLinksBlock}
								checked={mySettings.enableLinksBlock}
							/>
						</div>

						<div className='mb-3'>
							<Form.Check
								className='mb-3'
								onChange={onChangeHandler}
								type='switch'
								name='enableOauth'
								id='enableOauthToggle'
								label={translations.settings.enableOauth}
								checked={mySettings.enableOauth}
							/>

							{mySettings.enableOauth && (
								<Form noValidate validated={validatedOauthUrl} onSubmit={handleSubmitOauthUrl}>
									<FloatingLabel
										controlId='oauthUrl'
										label={translations.settings.oauthUrl}
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='oauthUrl'
											placeholder='Oauth Url'
											value={oauthUrl}
											onChange={onChangeOauthUrlHandler}
											required
											type='url'
										/>
									</FloatingLabel>
									<Button type='submit'>{translations.btns.save}</Button>
								</Form>
							)}
						</div>

						<div className='mb-3'>
							<Form.Check
								className='mb-3'
								onChange={onChangeHandler}
								type='switch'
								name='enableDomainWhitelist'
								id='enableDomainWhitelistToggle'
								label={translations.settings.enableDomainWhitelist.title}
								checked={mySettings.enableDomainWhitelist}
							/>
							{mySettings.enableDomainWhitelist && (
								<Form
									noValidate
									validated={validatedDomainWhitelist}
									onSubmit={handleSubmitDomainWhitelist}>
									<FloatingLabel
										controlId='oauthUrl'
										label={translations.settings.domainWhitelist}
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='domainWhitelist'
											value={domainWhitelist}
											onChange={onChangeDomainWhitelistHandler}
											required
											placeholder='https://example1.com, https://example2.com'
											as='textarea'
										/>
									</FloatingLabel>
									<Button type='submit'>{translations.btns.save}</Button>
								</Form>
							)}
						</div>
						{showHiddenSettings && (
							<div className='mb-3'>
								<Form.Check
									onChange={onChangeHandler}
									type='switch'
									name='isStand'
									id='isStandToggle'
									label='isStand'
									checked={mySettings.isStand}
								/>
							</div>
						)}

						{isCreator && (
							<>
								<div className='mb-3'>
									<Form.Check
										onChange={onChangeGlobalSettings}
										type='switch'
										name='enableChat'
										id='enableChatToggle'
										label='enableChat'
										checked={isChatEnabled}
									/>
								</div>
								{!isChatEnabled && (
									<Tabs
										activeKey={key}
										onSelect={(k: any) => setKey(k)}
										id='disabledChatLangs'
										className='mb-3'>
										{availableLangs.length &&
											availableLangs.map((el: string) => {
												return (
													<Tab
														eventKey={el}
														title={el.toUpperCase()}
														tabClassName='text-secondary'
														key={el}>
														<FloatingLabel
															controlId={`floatingIdTitle${el}`}
															label={translations.text}
															className='w-100 text-secondary'>
															<Form.Control
																name='title'
																as='textarea'
																maxLength={200}
																placeholder={translations.text}
																className='mb-2'
																value={langTextData?.find((elem: any) => elem.lang === key)?.text}
																onChange={onChangeTextHandler}
															/>
														</FloatingLabel>
														<Button type='button' variant='success' onClick={saveDisabledChatText}>
															{translations.btns.save}
														</Button>
													</Tab>
												);
											})}
									</Tabs>
								)}
							</>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(FunctionalSettings);

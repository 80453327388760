import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';

import {Container, Form, Row, Col, FloatingLabel, Button} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';
import 'react-datepicker/dist/react-datepicker.css';

import useL10n from 'l10n/useL10n';
import useHighlights from 'hooks/useHighlights';

import highlightsServices from 'store/highlightsServices';
import {useParams} from 'react-router-dom';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IHighlightDuration {
	nextStep: () => void;
	steps: string[];
}

type time = {
	hours?: string;
	minutes?: string;
	seconds?: string;
};

const HighlightDuration: FunctionComponent<IHighlightDuration> = function HighlightDuration({
	nextStep,
	steps,
}) {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();

	const {currentHighlight, currentStep, setCurrentHighlight, setCurrentStep} = useLocalObservable(
		() => highlightsServices
	);
	const {appTheme} = useLocalObservable(() => appService);

	const [sendType, setSendType] = useState('NOW');
	const [duration, setDuration] = useState('ENDLESS');
	const time = useRef<time>({
		hours: '',
		minutes: '',
		seconds: '',
	});
	const [sendTime, setSendTime] = useState<Date>();
	const [deleteTime, setDeleteTime] = useState<Date | null>();
	const [validated, setValidated] = useState(false);

	const {updateHighlight} = useHighlights();

	const today: Date = new Date();

	const onSelectSendTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSendType(value);
		setCurrentHighlight({
			...currentHighlight,
			sendTime: value,
		});
		setSendTime(undefined);
	};

	const onSelectDuration = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setDuration(value);
		setDeleteTime(null);
		setCurrentHighlight({
			...currentHighlight,
			duration: value,
			deleteTime: '',
			time: null,
		});
		time.current = {
			hours: '',
			minutes: '',
			seconds: '',
		};
	};

	const setSendDate = (date: Date) => {
		setSendTime(date);
		setCurrentHighlight({
			...currentHighlight,
			sendTime: date.toISOString(),
		});
	};

	const setDeleteDate = (date: Date) => {
		setDeleteTime(date);
		setCurrentHighlight({
			...currentHighlight,
			deleteTime: date.toISOString(),
		});
	};

	const filterPassedTime = (value: any, date?: Date) => {
		const currentDate = date || new Date();
		const selectedDate = new Date(value);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const onChangeTimeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/[^0-9.]/g, '');
		time.current = {
			...time.current,
			[event.target.name]: value,
		};
		setCurrentHighlight({
			...currentHighlight,
			time: {...time.current},
		});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		const btnName = e.nativeEvent.submitter.getAttribute('name');
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else btnName === 'save-highlight' ? updateHighlight() : nextStep();
	};

	useEffect(() => {
		setSendType(currentHighlight.sendTime === 'NOW' ? 'NOW' : 'CUSTOM');
		currentHighlight.duration && setDuration(currentHighlight.duration);

		currentHighlight.sendTime &&
			currentHighlight.sendTime !== 'NOW' &&
			setSendTime(new Date(currentHighlight.sendTime));

		if (currentHighlight.deleteTime) {
			setDeleteTime(new Date(currentHighlight.deleteTime));
			setDuration('CUSTOM');
		}

		if (currentHighlight.time) {
			time.current = {...currentHighlight.time};
		}
	}, []);

	return (
		<Container fluid className='highlights__step'>
			<Row>
				<Col md={6}>
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<div className='block'>
							{currentHighlight.status !== 'SENT' && (
								<div className='mb-5'>
									<p className='h5 mb-4'>{translations.highlights.sendDateTime}</p>

									<Form.Select onChange={onSelectSendTime} value={sendType} className='mb-3'>
										{!slug && <option value='NOW'>{translations.highlights.sendNow}</option>}
										<option value='CUSTOM'>{translations.highlights.chooseDate}</option>
									</Form.Select>

									{sendType === 'CUSTOM' && (
										<Form.Group className='mb-3' controlId='formDateStart'>
											<Form.Label>{translations.highlights.sendDateTime}</Form.Label>
											<DatePicker
												className='form-control'
												selected={sendTime}
												placeholderText={translations.highlights.sendDateTime}
												dateFormat='dd.MM.yyyy, HH:mm'
												minDate={today}
												timeInputLabel={`${translations.modals.createRoom.time}:`}
												showTimeSelect
												filterTime={filterPassedTime}
												timeFormat='HH:mm'
												timeIntervals={5}
												timeCaption='time'
												required
												onChange={(date: Date) => setSendDate(date)}
											/>
										</Form.Group>
									)}
								</div>
							)}

							<p className='h5 mb-4'>{translations.highlights.duration}</p>

							<Form.Select onChange={onSelectDuration} value={duration} className='mb-3'>
								<option value='ENDLESS'>{translations.highlights.durationEndless}</option>
								{(!slug || currentHighlight.status !== 'SENT') && (
									<option value='LASTING'>{translations.highlights.setDuration}</option>
								)}
								<option value='CUSTOM'>{translations.highlights.setEndDate}</option>
							</Form.Select>

							{duration === 'LASTING' && (
								<div>
									<Form.Group className='mb-3' controlId='hours'>
										<Form.Label>{translations.highlights.hours}</Form.Label>
										<Form.Control
											placeholder={translations.highlights.hours}
											name='hours'
											onChange={onChangeTimeHandler}
											value={time.current.hours}
											required={!time.current.minutes && !time.current.seconds}
											maxLength={2}
										/>
									</Form.Group>
									<Form.Group className='mb-3' controlId='minutes'>
										<Form.Label>{translations.highlights.minutes}</Form.Label>
										<Form.Control
											placeholder={translations.highlights.minutes}
											name='minutes'
											onChange={onChangeTimeHandler}
											value={time.current.minutes}
											required={!time.current.hours && !time.current.seconds}
											maxLength={2}
										/>
									</Form.Group>
									<Form.Group className='mb-3' controlId='seconds'>
										<Form.Label>{translations.highlights.seconds}</Form.Label>
										<Form.Control
											placeholder={translations.highlights.seconds}
											name='seconds'
											onChange={onChangeTimeHandler}
											value={time.current.seconds}
											required={!time.current.hours && !time.current.minutes}
											maxLength={2}
										/>
									</Form.Group>
								</div>
							)}

							{duration === 'CUSTOM' && (
								<Form.Group className='mb-3' controlId='formDateStart'>
									<Form.Label>{translations.highlights.endDateTime}</Form.Label>
									<DatePicker
										className='form-control'
										selected={deleteTime}
										placeholderText={translations.highlights.endDateTime}
										dateFormat='dd.MM.yyyy, HH:mm'
										minDate={sendTime}
										timeInputLabel={`${translations.modals.createRoom.time}:`}
										required
										showTimeSelect
										filterTime={value => filterPassedTime(value, sendTime)}
										timeFormat='HH:mm'
										timeIntervals={5}
										timeCaption='time'
										onChange={(date: Date) => setDeleteDate(date)}
									/>
								</Form.Group>
							)}
							<div className='d-flex justify-content-end mt-4'>
								{currentStep > 1 && (
									<Button
										variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
										className='d-flex'
										onClick={() => setCurrentStep(currentStep - 1)}>
										<BsArrowLeftCircleFill />
										&nbsp;&nbsp;
										{translations.highlights.prevStep}
									</Button>
								)}
								&nbsp;
								<Button
									variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
									className='d-flex'
									type='submit'
									disabled={currentHighlight.template === null}>
									{currentStep === steps.length
										? translations.btns.send
										: translations.highlights.nextStep}
									&nbsp;&nbsp;
									<BsArrowRightCircleFill />
								</Button>
								&nbsp;
								{slug && (
									<Button name='save-highlight' variant='success' className='d-flex' type='submit'>
										{translations.btns.save}
									</Button>
								)}
							</div>
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};
export default observer(HighlightDuration);

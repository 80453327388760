import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {NavLink, Prompt, useHistory, useParams} from 'react-router-dom';

import {Container, Row, Col, Breadcrumb, Button, Spinner} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import toastServices from 'store/toastServices';
import pollServices from 'store/pollServices';
import alertServices from 'store/alertServices';
import modalServices from 'store/modalServices';
import appService from 'store/appService';

import {PollSettings} from 'components/poll/pollSettings';
import {PollQuestion} from 'components/poll/pollQuestion';
import {PollAnswers} from 'components/poll/pollAnswers';
import PollRooms from 'components/poll/pollRooms/PollRooms';
import PollDate from 'components/poll/pollDate/pollDate';
import {PollModal} from 'components/modals/pollModal';

import {Theme} from 'models/enums/Theme.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import usePolls from 'hooks/usePolls';

import PollSendingInfo from './PollSendingInfo';

const CreatePoll: FunctionComponent = function CreatePoll() {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();
	const history = useHistory();

	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [disablePublishPollBtn, setDisablePublishPollBtn] = useState(false);
	const [createResponse, setCreateResponse] = useState(null);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {appTheme} = useLocalObservable(() => appService);
	const {addToast} = useLocalObservable(() => toastServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {togglePollModalVisible} = useLocalObservable(() => modalServices);
	const {
		currentPoll,
		setCurrentPoll,
		isNotValidFields,
		isFieldsEmpty,
		isQuestionEmpty,
		pollWithPics,
		isRoomsEmpty,
		setPollPreview,
		setPollWithPics,
		isStartTimeEmpty,
		isEndTimeEmpty,
	} = useLocalObservable(() => pollServices);

	const blockedPublishPoll =
		isNotValidFields ||
		isFieldsEmpty ||
		isQuestionEmpty ||
		isStartTimeEmpty ||
		isEndTimeEmpty ||
		(!slug && (!currentPoll?.externalRoomIds || isRoomsEmpty));

	const {setPoll, patchPoll, getPoll, checkPoll} = usePolls();

	const createPoll = async () => {
		const response = await setPoll(pollWithPics);
		if (response.status === ResponseStatus.SUCCESS) setCreateResponse(response.data);
	};

	const updatePoll = async () => {
		await patchPoll(pollWithPics);
	};

	const checkPollBefore = async () => {
		const checkResponse = slug ? await checkPoll(slug) : await checkPoll();
		if (checkResponse && checkResponse.status === ResponseStatus.SUCCESS) {
			if (checkResponse.data.length) {
				showAlert({
					title: translations.alerts.publishPoll,
					text: `${translations.alerts.publishPollWarning} ${checkResponse.data.join(',')}`,
					buttons: [
						{
							text: translations.alerts.btns.backToEdit,
							type: AlertBtnType.NORMAL,
							onClick: () => {
								hideAlert();
							},
						},
						{
							text: translations.alerts.btns.yes,
							type: AlertBtnType.SUCCESS,
							onClick: () => {
								slug ? updatePoll() : createPoll();
								hideAlert();
							},
						},
					],
				});
				return;
			}
			slug ? updatePoll() : createPoll();
		}
		if (checkResponse && checkResponse.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const publishPoll = async () => {
		setConfirmedNavigation(true);
		setDisablePublishPollBtn(true);

		if (!blockedPublishPoll && !currentPoll?.startTime) {
			checkPollBefore();
		} else if (currentPoll?.startTime) {
			slug ? updatePoll() : createPoll();
		}
		setDisablePublishPollBtn(false);
	};

	const savePollClickHandler = async () => {
		setDisablePublishPollBtn(true);
		if (!blockedPublishPoll && !currentPoll?.startTime) {
			checkPollBefore();
		} else if (currentPoll?.startTime) {
			slug ? updatePoll() : createPoll();
		}
		setDisablePublishPollBtn(false);
	};

	const showPublishPollAlert = () => {
		showAlert({
			title: translations.alerts.publishPoll,
			buttons: [
				{
					text: translations.alerts.btns.backToEdit,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.yes,
					type: AlertBtnType.SUCCESS,
					onClick: () => {
						publishPoll();
						hideAlert();
					},
				},
			],
		});
	};

	const saveDraft = (nextLocation?: any) => {
		setConfirmedNavigation(true);
		localStorage.setItem('pollDraft', JSON.stringify(currentPoll));
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.draftCreated,
			variant: 'success',
		});
		!nextLocation &&
			setTimeout(() => {
				history.push('/polls');
			}, 100);
	};

	const showPreview = () => {
		togglePollModalVisible(true);
		setPollPreview(currentPoll);
	};

	const handleBlockedNavigation = (nextLocation: any) => {
		setLastLocation(nextLocation.pathname);
		if (!confirmedNavigation && nextLocation.pathname !== '/login') {
			showAlert({
				title: translations.alerts.saveChanges,
				text: translations.alerts.unsavedChanges,
				buttons: [
					{
						text: translations.btns.dontSave,
						type: AlertBtnType.DANGER,
						onClick: () => {
							hideAlert();
							localStorage.removeItem('pollDraft');
							setConfirmedNavigation(true);
						},
					},
					{
						text: translations.alerts.btns.saveDraft,
						type: AlertBtnType.SUCCESS,
						onClick: () => {
							setConfirmedNavigation(true);
							saveDraft(nextLocation);
							hideAlert();
						},
					},
				],
			});
			return false;
		}
		return true;
	};

	const getPollWithServices = async (id: number) => {
		setVisiblePreloader(true);
		await getPoll(id);
		setVisiblePreloader(false);
	};

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation);
		}
	}, [confirmedNavigation, lastLocation]);

	useEffect(() => {
		slug && getPollWithServices(+slug);
	}, [slug]);

	useEffect(() => {
		return () => {
			setCurrentPoll(null);
			setPollWithPics(false);
		};
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/polls'>{translations.sidebar.pollsQuizzes.title}</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							{slug ? translations.highlights.edit : translations.pollCreating.titles.created}
						</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row className='mb-5 align-items-center'>
				<Col>
					<p className='h3 mb-0'>
						{slug ? translations.highlights.edit : translations.pollCreating.titles.created}
					</p>
				</Col>
			</Row>

			{visiblePreloader ? (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			) : (
				!createResponse && (
					<div className='d-flex w-100 mb-3'>
						<div className='block'>
							<p className='h5 mb-5'>{translations.poll.settings.title}</p>
							<PollSettings />
							<PollQuestion />
							<PollAnswers />
						</div>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<div className='w-100'>
							<div className='block mb-3'>
								<p className='h5 mb-5'>{translations.poll.dateAndDuration}</p>
								<PollDate />
							</div>
							<div className='block mb-3'>
								<p className='h5 mb-5'>{translations.poll.rooms}</p>
								<PollRooms />
							</div>

							<div className='d-flex justify-content-end mb-5'>
								<div className='d-flex align-items-center'>
									<NavLink to='/polls'>{translations.prev}</NavLink>
								</div>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<Button variant='outline-secondary' onClick={() => showPreview()}>
									{translations.poll.btns.preview}
								</Button>
								&nbsp;&nbsp;
								{!slug && (
									<Button variant='secondary' onClick={() => saveDraft()}>
										{translations.pollCreating.btns.saveDraft}
									</Button>
								)}
								&nbsp;&nbsp;
								{slug && (
									<Button
										variant='success'
										onClick={savePollClickHandler}
										disabled={disablePublishPollBtn || blockedPublishPoll}>
										{translations.btns.save}
									</Button>
								)}
								{!slug && (
									<Button
										variant='success'
										onClick={showPublishPollAlert}
										disabled={disablePublishPollBtn || blockedPublishPoll}>
										{translations.poll.btns.publish}
									</Button>
								)}
							</div>
						</div>
					</div>
				)
			)}

			{createResponse && <PollSendingInfo createResponse={createResponse} />}
			{!slug && <Prompt when message={handleBlockedNavigation} />}
			<PollModal />
		</Container>
	);
};

export default observer(CreatePoll);

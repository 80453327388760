import {action, makeObservable, observable} from 'mobx';
import {Settings} from 'models/autoposting';

class AutopostingService {
	@observable
	public data: {
		lang: string;
		messages: {
			id?: number;
			project?: string;
			text?: string;
			lang?: string;
			createdAt?: string;
			isNew?: boolean;
		}[];
	}[] = [];

	@observable
	public columns: {value: string; label: string}[] = [{value: 'en', label: 'en'}];

	@observable
	public options: {value: string; label: string; isUsed?: boolean}[] = [
		{
			value: 'en',
			label: 'en',
		},
		{
			value: 'ru',
			label: 'ru',
		},
		{
			value: 'hy',
			label: 'hy',
		},
		{
			value: 'sr',
			label: 'sr',
		},
		{
			value: 'de',
			label: 'de',
		},
		{
			value: 'es',
			label: 'es',
		},
		{
			value: 'ar',
			label: 'ar',
		},
		{
			value: 'ro',
			label: 'ro',
		},
		{
			value: 'tr',
			label: 'tr',
		},
		{
			value: 'hi',
			label: 'hi',
		},
		{
			value: 'pt',
			label: 'pt',
		},
		{
			value: 'zh',
			label: 'zh',
		},
		{
			value: 'ms',
			label: 'ms',
		},
		{
			value: 'bn',
			label: 'bn',
		},
		{
			value: 'fr',
			label: 'fr',
		},
		{
			value: 'fi',
			label: 'fi',
		},
		{
			value: 'is',
			label: 'is',
		},
		{
			value: 'ko',
			label: 'ko',
		},
		{
			value: 'pl',
			label: 'pl',
		},
		{
			value: 'az',
			label: 'az',
		},
		{
			value: 'uz',
			label: 'uz',
		},
		{
			value: 'uk',
			label: 'uk',
		},
		{
			value: 'ja',
			label: 'ja',
		},
	];

	@observable
	public autopostingSettings: Settings | null = null;

	@action
	public clear = () => {
		this.setColumns([{value: 'en', label: 'en'}]);
		this.setMessages([]);
	};

	@action
	public setColumns = (value: any) => {
		this.columns = value;
	};

	@action
	public setAutopostingSettings = (value: Settings) => {
		this.autopostingSettings = value;
	};

	@action
	public setMessages = (value: any) => {
		this.data = value;
	};

	@action
	public addLangToMessages = (value: {
		lang: string;
		messages: {
			id?: number;
			project?: string;
			text?: string;
			lang?: string;
			createdAt?: string;
			isNew?: boolean;
		}[];
	}) => {
		this.data = [...this.data, value];
	};

	@action
	public checkOptions = () => {
		this.options = this.options.map(r => {
			if (this.columns.map(el => el.value).includes(r.value)) {
				return {...r, isUsed: true};
			}
			return {...r, isUsed: false};
		});
	};

	@action
	public addColumn = (data: {value: string; label: string}) => {
		this.columns = [...this.columns, data];
		this.checkOptions();
	};

	@action
	public updateColumnByIndex = (index: number, selectedOption: {value: string; label: string}) => {
		this.columns = this.columns.map((column, columnIndex) => {
			if (columnIndex === index) {
				return {...column, value: selectedOption.value, label: selectedOption.label};
			}
			return column;
		});

		this.data = this.data.map((elem, columnIndex) => {
			if (columnIndex === index) {
				return {...elem, lang: selectedOption.value};
			}
			return elem;
		});
	};

	@action
	public addLocale = (value: string) => {
		if (typeof this.data.find(el => el.lang === value) === 'undefined')
			this.data = [...this.data, {lang: value, messages: []}];
	};

	constructor() {
		makeObservable(this);
	}
}
export default new AutopostingService();

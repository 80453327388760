import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';

import userServices from 'store/userServices';

import SettingsService from 'services/api/SettingsService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import GreetingForm from 'pages/main/components/settings/lang/GreetingForm';
import settingsServices from 'store/settingsServices';
import {Greeting as GreetingType} from 'models/settings';

interface IGreeting {
	currentTab: string;
}

const Greeting: FunctionComponent<IGreeting> = function Greeting({currentTab}) {
	const [key, setKey] = useState('ru');
	const [greetingData, setGreetingData] = useState<GreetingType[]>([]);
	const [currentGreeting, setCurrentGreeting] = useState<GreetingType>({
		title: '',
		text: '',
		lang: 'ru',
	});

	const [availableLangs, setAvailableLangs] = useState<string[]>([]);

	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {projectLanguage, settings} = useLocalObservable(() => settingsServices);

	const getGreeting = async (lang?: string) => {
		const response = await SettingsService.getGreeting(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setGreetingData(response.data);
			if (!response.data.length) {
				setCurrentGreeting({
					title: '',
					text: '',
					lang: projectLanguage || 'ru',
				});
				return;
			}
			if (lang) {
				setCurrentGreeting(
					response.data.find((el: GreetingType) => el.lang === lang) ||
						response.data.find((el: GreetingType) => el.lang === projectLanguage) || {
							title: '',
							text: '',
							lang,
						}
				);
			} else {
				setCurrentGreeting(
					response.data.find((el: GreetingType) => el.lang === projectLanguage) || {
						title: '',
						text: '',
						lang: projectLanguage,
					}
				);
			}
		}
	};

	useEffect(() => {
		const value =
			greetingData.find((el: GreetingType) => el.lang === key) ||
			greetingData.find((el: GreetingType) => el.lang === projectLanguage);

		value
			? setCurrentGreeting(value)
			: setCurrentGreeting({
					title: '',
					text: '',
					lang: key,
			  });
	}, [key]);

	useEffect(() => {
		if (settings) settings.availableLangs && setAvailableLangs(settings.availableLangs?.split(','));
	}, [settings]);

	useEffect(() => {
		currentTab === 'greeting' && getGreeting();
		setKey(projectLanguage);
	}, [currentTab]);

	return (
		<div className='block'>
			<p className='h5 mb-2'>{translations.settings.greeting.title}</p>
			<p className='text-secondary mb-5'>{translations.settings.greeting.description}</p>
			<Tabs
				activeKey={key}
				onSelect={(k: any) => setKey(k)}
				id='uncontrolled-tab-example'
				className='mb-3'>
				{availableLangs.length &&
					availableLangs.map((el: string) => {
						return (
							<Tab eventKey={el} title={el.toUpperCase()} tabClassName='text-secondary' key={el}>
								<GreetingForm
									currentGreeting={currentGreeting}
									setCurrentGreeting={setCurrentGreeting}
									getGreeting={getGreeting}
									tabLang={el}
								/>
							</Tab>
						);
					})}
			</Tabs>
		</div>
	);
};

export default observer(Greeting);

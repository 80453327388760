import {TALKER_STUB} from 'constants/constants';
import translations from 'l10n/translations';
import {useLocalObservable} from 'mobx-react-lite';

import {Message, Room, Talker} from 'models/room';
import {User} from 'models/user';
import {useEffect, useRef} from 'react';

import messagesServices from 'store/messagesServices';
import roomServices from 'store/roomServices';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';

export default () => {
	const adminDataRef = useRef<any | null>();

	const {
		updateUserByTalkerId,
		updateTalkerMessagesByTalkerId,
		setMessages,
		updateMessage,
		updateMessagesByTalkerId,
		deleteMessage,
		changeConnectionQualityByUserId,
	} = useLocalObservable(() => messagesServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {setStatus, setIsSpeak, setIsSlowmode, setSlowmodeTimeout} = useLocalObservable(
		() => roomServices
	);
	const {adminData, setAdminData} = useLocalObservable(() => userServices);
	const {addPinnedMessage, removePinnedMessage} = useLocalObservable(() => roomServices);

	useEffect(() => {
		if (adminData) {
			adminDataRef.current = adminData;
		}
	}, [adminData]);

	const onUserUpdatedHandler = (data: {user: User}) => {
		updateUserByTalkerId(data.user);
		if (adminDataRef.current && adminDataRef.current.id === data.user.id) {
			setAdminData({...adminDataRef.current, name: data.user.name});
		}
	};

	const onMessagedDeletedHandler = () => {
		setMessages([]);
	};

	const onMessageDeletedHandler = (data: {messageId: number}) => {
		deleteMessage(data.messageId);
	};

	const onMessageHiddenHandler = (data: {message: Message}) => {
		updateMessage(data.message);
	};

	const onMessagesHiddenHandler = (data: any) => {
		updateMessagesByTalkerId(data.talkerId, data.isVisible, data.hiddenAt);
	};

	const onMessageEditedHandler = (data: {message: Message}) => {
		updateMessage(data.message);
	};

	const onTalkerBanSetHandler = (data: {talker: Talker}) => {
		updateTalkerMessagesByTalkerId(data.talker);
	};

	const onUserBanSetHandler = (data: {user: User}) => {
		updateUserByTalkerId(data.user);
	};

	const onMuteSetHandler = (data: {talker: Talker}) => {
		updateTalkerMessagesByTalkerId(data.talker);
	};

	const onModerSetHandler = (data: {talker: Talker}) => {
		updateTalkerMessagesByTalkerId(data.talker);
	};

	const onRoleSetHandler = (data: {talker: Talker}) => {
		updateTalkerMessagesByTalkerId(data.talker);
	};

	const onRoomStatusSetHandler = (data: {room: Room}) => {
		setStatus(data.room.status);
	};

	const onRoomSpeakSetHandler = (data: {room: Room}) => {
		setIsSpeak(data.room.isSpeak);
	};

	const onMessagePinHandler = (data: {message: Message}) => {
		addPinnedMessage(data.message);
		updateMessage(data.message);
	};

	const onMessageUnPinHandler = (data: {message: Message}) => {
		removePinnedMessage(data.message);
		updateMessage(data.message);
	};

	const onRoomSlowmodeSetHandler = (data: {room: Room}) => {
		setIsSlowmode(data.room.isSlowmode);
		setSlowmodeTimeout(data.room.slowmodeDelayMS);
		addToast({
			title: translations.toasts.roomChanged(data.room.externalRoomId),
			text: data.room.isSlowmode ? translations.toasts.slowmode : translations.toasts.noSlowmode,
		});
	};

	const onBroadcastHandler = (data: {userId: number; type: string; value: string}) => {
		if (data.type === 'connection') {
			changeConnectionQualityByUserId(data.userId, data.value);
		}
	};

	return {
		onUserUpdatedHandler,
		onMessagedDeletedHandler,
		onMessageDeletedHandler,
		onMessageHiddenHandler,
		onMessagesHiddenHandler,
		onMessageEditedHandler,
		onTalkerBanSetHandler,
		onUserBanSetHandler,
		onMuteSetHandler,
		onModerSetHandler,
		onRoleSetHandler,
		onRoomStatusSetHandler,
		onRoomSpeakSetHandler,
		onRoomSlowmodeSetHandler,
		onBroadcastHandler,
		onMessagePinHandler,
		onMessageUnPinHandler,
	};
};

import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import useL10n from 'l10n/useL10n';
import AutopostingService from 'services/api/AutopostingService';
import autopostingService from 'store/autopostingService';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {setAutopostingSettings, setMessages} = useLocalObservable(() => autopostingService);

	const translations = useL10n();

	const getSettings = async () => {
		const response = await AutopostingService.getSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setAutopostingSettings(response.data);
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const updateSettings = async (body: any) => {
		const response = await AutopostingService.postSettings(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			setAutopostingSettings(response.data);
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const getMessageDefault = async () => {
		const response = await AutopostingService.getMessageDefault(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setMessages(response.data);
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const getMessage = async () => {
		const response = await AutopostingService.getMessage(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setMessages(response.data);
			return response.data;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
		return false;
	};

	const addMessageDefault = async (body: any) => {
		const response = await AutopostingService.addMessageDefault(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			getMessageDefault();
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const addMessage = async (body: any) => {
		const response = await AutopostingService.addMessage(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			getMessage();
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const addMessageMultiple = async (body: any) => {
		const response = await AutopostingService.addMessageMultiple(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS) {
			getMessage();
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const deleteMessages = async (isDefault: boolean) => {
		const response = await AutopostingService.deleteMessages(accessToken, isDefault);
		if (response.status === ResponseStatus.SUCCESS) {
			isDefault ? getMessageDefault() : getMessage();
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const deleteMessage = async (id: number, isDefault: boolean) => {
		const response = await AutopostingService.deleteMessage(accessToken, id, isDefault);
		if (response.status === ResponseStatus.SUCCESS) {
			isDefault ? getMessageDefault() : getMessage();
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const deleteMessagesLang = async (lang: string, isDefault: boolean) => {
		const response = await AutopostingService.deleteMessageLang(accessToken, lang, isDefault);
		if (response.status === ResponseStatus.SUCCESS) {
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	return {
		getSettings,
		updateSettings,
		getMessageDefault,
		getMessage,
		addMessage,
		addMessageMultiple,
		addMessageDefault,
		deleteMessages,
		deleteMessage,
		deleteMessagesLang,
	};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';

import {Container, Spinner, Breadcrumb, Badge} from 'react-bootstrap';

import userServices from 'store/userServices';
import lastMessagesService from 'store/messagesServices';
import toastServices from 'store/toastServices';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Message} from 'models/room';
import MessageType from 'models/enums/MessageType';

import MessageService from 'services/api/MessageService';

import Chat from 'components/chat/Chat';
import {Theme} from 'models/enums/Theme.enum';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';

const UserMessages: FunctionComponent = function UserMessages() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const {accessToken} = useLocalObservable(() => userServices);
	const {messages, setMessages, addMessages, setCurrentUserId} = useLocalObservable(
		() => lastMessagesService
	);
	const {hideToasts} = useLocalObservable(() => toastServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [limit, setLimit] = useState(20);
	const [scrollToBottom, setScrollToBottom] = useState(false);
	const [messagesLoaded, setMessagesLoaded] = useState(false);

	const translations = useL10n();

	const getMessages = async () => {
		if (accessToken) {
			const response = await MessageService.getUserMessages(accessToken, +slug, 20);
			if (response.status === ResponseStatus.SUCCESS) {
				setMessages(
					response.data.filter(
						(el: Message) =>
							(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
					)
				);
				setScrollToBottom(true);
				setMessagesLoaded(true);
			}
		}
	};
	const getPreviousMessages = async () => {
		const response = await MessageService.getUserMessages(
			accessToken,
			+slug,
			limit,
			messages[0].id
		);
		if (response.status === ResponseStatus.SUCCESS) {
			addMessages(
				response.data.filter(
					(el: Message) =>
						(el.type !== MessageType.BET && el.type !== MessageType.GAMBLE) || el.text
				)
			);
		}
		return response.status;
	};

	useEffect(() => {
		getMessages();
	}, [accessToken]);

	useEffect(() => {
		setCurrentUserId(+slug);
		return () => {
			hideToasts();
			setCurrentUserId(null);
		};
	}, []);

	return (
		<Container fluid>
			<Container fluid className='pt-4 page-header'>
				<Breadcrumb>
					<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
					<Breadcrumb.Item className='text-truncate' active>
						<NavLink to='/last-messages'>{translations.sidebar.lastMessages}</NavLink>
					</Breadcrumb.Item>
					<Breadcrumb.Item className='text-truncate breadcrumb-item-name' active>
						<span>{translations.table.user} </span>
						<b>id{slug}</b>
					</Breadcrumb.Item>
				</Breadcrumb>
				<p className='h3 mb-2'>
					<span>{translations.submenu.allUserMessages} </span>
					<b>id{slug}</b>
				</p>
			</Container>
			{!messagesLoaded ? (
				<div className='text-center mt-5'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			) : (
				<div className='chat-wrapper'>
					<Chat
						scrollToBottom={scrollToBottom}
						getPreviousMessages={getPreviousMessages}
						isUserMessages
					/>
				</div>
			)}
		</Container>
	);
};

export default observer(UserMessages);

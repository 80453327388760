import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Tabs, Tab} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';

import useSettings from 'hooks/useSettings';

import Colors from './Colors';
import Icons from './Icons';

interface ITheme {
	themeId: number;
	currentTab: string;
}

const Theme: FunctionComponent<ITheme> = function Theme({themeId, currentTab}) {
	const translations = useL10n();

	const [key, setKey] = useState('colors');

	const {getTheme} = useSettings();

	useEffect(() => {
		currentTab === `${themeId}` && getTheme(themeId);
	}, [themeId, currentTab]);

	return (
		<div>
			<Tabs
				activeKey={key}
				onSelect={(k: any) => setKey(k)}
				id='theme-settings'
				className='nav-tabs mb-3'
				variant='dark'>
				<Tab eventKey='colors' title={translations.settings.colors} tabClassName='text-secondary'>
					<div className='w-100'>
						<Colors currentTab={key} />
					</div>
				</Tab>
				<Tab eventKey='ICON' title={translations.settings.icons} tabClassName='text-secondary'>
					<div className='w-100'>
						<Icons currentTab={key} />
					</div>
				</Tab>
			</Tabs>
		</div>
	);
};

export default observer(Theme);

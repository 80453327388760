/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import WordListService from 'services/api/AntimatService';
import userServices from 'store/userServices';

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Navbar,
	FloatingLabel,
	Form,
	Button,
	InputGroup,
	Spinner,
	Alert,
} from 'react-bootstrap';
import {BsTrashFill} from 'react-icons/bs';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import alertService from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import toastService from 'store/toastServices';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import PageTitle from 'components/pageTitle/PageTitle';
import configService from 'store/configService';

interface IWordList {
	type: string;
	title?: string;
}
const WordList: FunctionComponent<IWordList> = function WordList({type, title}) {
	const {projectId, appTheme} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);
	const {appInstance} = useLocalObservable(() => configService);

	const [versions, setVersions] = useState([]);
	const [words, setWords] = useState<string[]>([]);
	const [newWord, setNewWord] = useState('');
	const [validated, setValidated] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const translations = useL10n();
	const {search} = useLocation();
	const watchersInstanceFromUrl = new URLSearchParams(search).get('instance');

	const watchersAppInstance = appInstance === 'watchers' || watchersInstanceFromUrl === 'watchers';

	const getVersions = async () => {
		if (accessToken) {
			const response = await WordListService.getVersions(type, accessToken, projectId);
			setVersions(response.data);
		}
	};
	const getWordList = async () => {
		if (accessToken) {
			setVisiblePreloader(true);
			const response = await WordListService.getWordList(type, accessToken, projectId);
			setVisiblePreloader(false);
			if (response.status === ResponseStatus.SUCCESS) {
				if (response.data.length) {
					setWords(response.data.toString().split('†'));
					return;
				}
			}
			setWords([]);
		}
	};

	const getVersionList = async (id: string) => {
		const response = await WordListService.getVersionList(id, accessToken, projectId);
		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.words) setWords(response.data.words.toString().split('†'));
			else setWords([]);
		} else setWords([]);
	};

	const onVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		getVersionList(value);
	};

	const showRemovingToast = (word: string) => {
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.deleteWord(word),
		});
	};

	const showAddingToast = (word: string) => {
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.addWord(word),
		});
	};

	const saveList = async (updatedList: string[], word: string, action: string) => {
		let wordList = '';

		updatedList.forEach((item: string) => {
			wordList += item;
			wordList += '†';
		});

		wordList = wordList.slice(0, -1);
		const response = await WordListService.updateWordList(type, wordList, accessToken, projectId);
		if (response.status === ResponseStatus.SUCCESS) {
			setWords(updatedList);
			if (action === 'remove') showRemovingToast(word);
			else showAddingToast(word);
			getVersions();
		}
	};

	const removeWord = (word: string, index: number) => {
		const updatedList = [...words];
		updatedList.splice(index, 1);
		saveList(updatedList, word, 'remove');
	};

	const openErrorAlert = (text: string) => {
		showAlert({
			title: translations.wordlist.addWord,
			text,
			buttons: [
				{
					text: translations.alerts.btns.ok,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const addWord = () => {
		const trimmedWord: string = newWord.trim().toLowerCase();

		if (words.indexOf(trimmedWord) !== -1) {
			setTimeout(() => {
				openErrorAlert(translations.wordlist.alreadyExist);
			}, 100);

			setNewWord('');
			setValidated(false);
			return false;
		}

		if (trimmedWord.length <= 1) {
			setTimeout(() => {
				openErrorAlert(translations.wordlist.failedSave(trimmedWord));
			}, 100);
			setNewWord('');
			setValidated(false);
			return false;
		}

		if (type === 'site') {
			const wrongInput = trimmedWord.match(/[а-яА-ЯёЁ]/g);

			if (wrongInput) {
				setTimeout(() => {
					openErrorAlert(translations.wordlist.failedSaveSymbol(wrongInput[0]));
				}, 100);
				setNewWord('');
				setValidated(false);
				return false;
			}
		}

		saveList([trimmedWord, ...words], newWord, 'add');
		setNewWord('');
		return true;
	};

	const openRemoveAlert = (word: string, index: number) => {
		const text = type === 'phrase' ? translations.wordlist.phrase : translations.wordlist.word;
		showAlert({
			title:
				text === 'phrase' ? translations.wordlist.deletePhrase : translations.wordlist.deleteWord,
			text: translations.wordlist.confirmDeleteWord(word),
			buttons: [
				{
					text: translations.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.btns.remove,
					type: AlertBtnType.DANGER,
					onClick: () => {
						removeWord(word, index);
						hideAlert();
					},
				},
			],
		});
	};

	const checkContent = () => {
		return !watchersAppInstance ? newWord.match(/[^а-яё]/i) : /\s/g.test(newWord);
	};

	const openAddAlert = () => {
		if (checkContent() && type === 'black') {
			showAlert({
				title: translations.wordlist.addWord,
				text: translations.wordlist.blackListWordsWarning(watchersAppInstance),
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
		} else {
			showAlert({
				title: type === 'phrase' ? translations.wordlist.addPhrase : translations.wordlist.addWord,
				text: translations.wordlist.confirmAddWord(newWord),
				buttons: [
					{
						text: translations.btns.cancel,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
					{
						text: translations.btns.add,
						type: AlertBtnType.SUCCESS,
						onClick: () => {
							addWord();
							hideAlert();
						},
					},
				],
			});
		}
	};

	const renderWord = useCallback(
		(item: any, index: any) => {
			return (
				<div key={index} className='mb-1'>
					<Button variant='danger' className='p-0' onClick={() => openRemoveAlert(item, index)}>
						<span className='m-2'>{item}</span>
						<BsTrashFill className='m-2' />
					</Button>
				</div>
			);
		},
		[words]
	);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setNewWord(value);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else openAddAlert();
	};

	useEffect(() => {
		getVersions();
		getWordList();
		setValidated(false);
		setNewWord('');
	}, [accessToken, type]);

	const text = () => {
		let description = '';
		if (type === 'black') description = translations.sidebar.blackList.descr;
		if (type === 'white') description = translations.sidebar.whiteList.descr;
		if (type === 'phrase') description = translations.sidebar.blackPhraseList.descr;
		if (type === 'site') description = translations.sidebar.siteList.descr;
		return description;
	};

	return (
		<Container fluid className='pt-4 text-lg rooms'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.wordList}</Breadcrumb.Item>
						{title !== translations.sidebar.wordList && (
							<Breadcrumb.Item active>{title}</Breadcrumb.Item>
						)}
					</Breadcrumb>

					{title && <PageTitle title={title} text={text()} />}

					{type === 'black' && (
						<Alert variant='warning' className='d-inline-block'>
							{translations.wordlist.blackListWordsAlert(watchersAppInstance)}
						</Alert>
					)}

					{type === 'phrase' && (
						<Alert variant='warning' className='d-inline-block'>
							{translations.wordlist.blackListPhrasesAlert}
						</Alert>
					)}

					{versions && (
						<Navbar variant='dark' bg='dark' expand='lg' className='mb-3'>
							<Container fluid>
								<Row className='w-100'>
									<Col xs={4}>
										<Navbar.Brand className='mb-1 d-block'>
											{translations.wordlist.version}
										</Navbar.Brand>
										<FloatingLabel controlId='floatingSelect' label={translations.wordlist.version}>
											<Form.Select onChange={onVersionChange}>
												{versions.map((item: any, index: number) => {
													return (
														<option key={index} value={item.id}>
															{new Date(item.createdAt).toLocaleString()}
														</option>
													);
												})}
											</Form.Select>
										</FloatingLabel>
									</Col>
									<Col xs={4}>
										<Navbar.Brand className='mb-1 d-block'>
											{
												// eslint-disable-next-line no-nested-ternary
												type === 'phrase'
													? translations.wordlist.addPhrase
													: type === 'site'
													? translations.wordlist.addLink
													: translations.wordlist.addWord
											}
										</Navbar.Brand>
										<Form noValidate validated={validated} onSubmit={handleSubmit}>
											<InputGroup className='mb-3'>
												<FloatingLabel
													controlId='floatingWord'
													label={
														// eslint-disable-next-line no-nested-ternary
														type === 'phrase'
															? translations.wordlist.newPhrase
															: type === 'site'
															? translations.wordlist.newLink
															: translations.wordlist.newWord
													}
													className='text-secondary'>
													<Form.Control
														placeholder={
															// eslint-disable-next-line no-nested-ternary
															type === 'phrase'
																? translations.wordlist.newPhrase
																: type === 'site'
																? translations.wordlist.newLink
																: translations.wordlist.newWord
														}
														name='word'
														value={newWord}
														onChange={onChangeHandler}
														required
													/>
													<Form.Control.Feedback type='invalid' tooltip>
														{
															// eslint-disable-next-line no-nested-ternary
															type === 'phrase'
																? translations.wordlist.phraseWarn
																: type === 'site'
																? translations.wordlist.linkWarn
																: translations.wordlist.wordWarn
														}
													</Form.Control.Feedback>
												</FloatingLabel>
												<Button variant='secondary' id='button-addon2' type='submit'>
													{translations.btns.add}
												</Button>
											</InputGroup>
										</Form>
									</Col>
								</Row>
							</Container>
						</Navbar>
					)}

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{words.length > 0 ? (
						<div>{words.map(renderWord)}</div>
					) : (
						!visiblePreloader && <p>{translations.empty.wordlistEmpty}</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(WordList);

import {action, makeObservable, observable} from 'mobx';
import {Welcome} from 'models/welcome';

class WelcomeService {
	@observable
	public welcome: Welcome | null = null;

	@observable
	public welcomeDraft: Welcome | null = null;

	@observable
	public currentStep = 1;

	@action
	public setWelcome = (value: Welcome | null) => {
		this.welcome = value;
	};

	@action
	public setWelcomeDraft = (value: Welcome | null) => {
		this.welcomeDraft = value;
	};

	@action
	public setCurrentStep = (value: number) => {
		this.currentStep = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new WelcomeService();

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Container, Row, Col, Breadcrumb, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useWelcome from 'hooks/useWelcome';
import appService from 'store/appService';
import settingsServices from 'store/settingsServices';

import {Theme} from 'models/enums/Theme.enum';

import WelcomeConfig from './components/welcome/WelcomeConfig';

const Welcome: FunctionComponent = function Welcome() {
	const translations = useL10n();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const {appTheme} = useLocalObservable(() => appService);
	const {settings} = useLocalObservable(() => settingsServices);

	const {getWelcome} = useWelcome();

	const getWelcomeWithServices = async (defaultLang: string) => {
		await getWelcome(defaultLang);
		setVisiblePreloader(false);
	};

	useEffect(() => {
		if (settings.lang) getWelcomeWithServices(settings.lang);
	}, [settings]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.widgets}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.welcome}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.welcome.title}</p>
				</Col>
			</Row>
			<div className='block'>
				{visiblePreloader && (
					<div className='text-center mt-4'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}
				{!visiblePreloader && <WelcomeConfig />}
			</div>
		</Container>
	);
};

export default observer(Welcome);

import {action, makeObservable, observable} from 'mobx';
import translations from 'l10n/translations';
import {Highlight} from 'models/highlights';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

class HighlightsService {
	@observable
	public currentStep = 1;

	@observable
	public isLoaded = false;

	@observable
	public highlightPics = [];

	@observable
	public currentHighlight: Highlight = {
		id: 0,
		title: '',
		text: '',
		link: '',
		linkText: translations.highlights.learnMore,
		linkTextLength: 18,
		pic: '',
		picFile: null,
		template: null,
		templateData: null,
		externalRoomIds: [],
		sendTime: 'NOW',
		deleteTime: '',
		duration: 'ENDLESS',
		openType: LinkOpenType.NEW_WINDOW,
	};

	@action
	public setCurrentStep = (value: number) => {
		this.currentStep = value;
	};

	@action
	public setIsLoaded = (value: boolean) => {
		this.isLoaded = value;
	};

	@action
	public setCurrentHighlight = (value: Highlight) => {
		this.currentHighlight = value;
	};

	@action
	public setHighlightPics = (value: []) => {
		this.highlightPics = value;
	};

	@action
	public clearCurrentHighlight = () => {
		this.currentHighlight = {
			id: 0,
			title: '',
			text: '',
			link: '',
			linkText: translations.highlights.learnMore,
			linkTextLength: 18,
			pic: '',
			picFile: null,
			template: null,
			externalRoomIds: [],
			sendTime: 'NOW',
		};
		this.currentStep = 1;
		this.isLoaded = false;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new HighlightsService();

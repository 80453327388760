import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class ModerService {
	static getModers = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/talker/moder`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static toggleTalkerModer = async (
		moderData: {talkerId: number; externalRoomId: string; value: boolean},
		token: UUID
	) => {
		try {
			const {data, status} = await axios.post(`/admin/talker/moder`, moderData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static assignModerByUserId = async (
		token: UUID,
		externalRoomId: string,
		externalUserId: string
	) => {
		try {
			const moderData = {
				externalRoomId,
				externalUserId,
				value: true,
			};
			const {data, status} = await axios.post(`/admin/talker/moder`, moderData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};
}

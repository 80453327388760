import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import userServices from 'store/userServices';
import useTheme from 'hooks/useTheme';
import badgeService from 'store/badgeService';
import AvatarImg from 'assets/images/profile-avatar.jpg';

const BadgePreview: FunctionComponent = function BadgePreview() {
	const translations = useL10n();

	const [colors, setColors] = useState<any>(null);
	const [currentTheme, setCurrentTheme] = useState(1);
	const [lightColors, setLightColors] = useState<any>('');
	const [darkColors, setDarkColors] = useState<any>('');

	const {accessToken} = useLocalObservable(() => userServices);
	const {themes, lightThemeColors, darkThemeColors} = useLocalObservable(() => settingsServices);
	const {currentBadge} = useLocalObservable(() => badgeService);

	const {getThemes, getColors} = useSettings();
	const {getThemeColors, setThemeColors} = useTheme();

	const themeClickHandler = (id: number) => {
		setCurrentTheme(id);
	};

	const getAllThemeColors = async () => {
		const colorsLight = await getColors(themes[0].id);
		const colorsDark = await getColors(themes[1].id);
		if (colorsLight) {
			getThemeColors(colorsLight, 1);
		}
		if (colorsDark) {
			getThemeColors(colorsDark, 2);
		}
	};

	useEffect(() => {
		if (colors) setThemeColors(colors);
	}, [colors]);

	useEffect(() => {
		if (lightColors && darkColors) {
			setColors(currentTheme === 1 ? lightColors : darkColors);
		}
	}, [currentTheme, lightColors, darkColors]);

	useEffect(() => {
		if (lightThemeColors) setLightColors(lightThemeColors);
	}, [lightThemeColors]);

	useEffect(() => {
		if (darkThemeColors) setDarkColors(darkThemeColors);
	}, [darkThemeColors]);

	useEffect(() => {
		if (themes.length) {
			getAllThemeColors();
		}
	}, [themes]);

	useEffect(() => {
		accessToken && getThemes();
	}, [accessToken]);

	return (
		<div className='badges-config__preview'>
			<div className='badges-config__preview-elem'>
				{colors && (
					<div
						className='badges-config__preview-wrapper'
						style={{backgroundColor: colors['--background-chat']}}>
						<div className='chat__message'>
							<div className='chat__message-container'>
								<div className='chat__message-avatar'>
									<div className='chat__message-avatar-wrapper'>
										<img src={AvatarImg} alt='' className='chat__message-avatar-img' />
									</div>
								</div>
								<div className='chat__message-wrapper'>
									<div
										className='chat__message-body'
										style={{
											backgroundColor: colors['--chat-opponent-background'],
											padding: colors['--chat-message-padding'],
											borderRadius: colors['--chat-bubble-border-radius'],
											border: `1px solid ${colors['--chat-opponent-stroke']}`,
										}}>
										<div className='chat__message-header'>
											<div className='chat__message-author color-5'>Konstantin</div>
											<div className='chat__message-badges'>
												<span
													className='badge rounded-pill'
													style={{
														backgroundColor: currentBadge.backgroundColor,
														color: currentBadge.textColor,
													}}>
													{currentBadge.text || 'New badge'}
												</span>
											</div>
										</div>
										<div className='chat__message-body-content'>
											<div className='chat__message-content'>
												<div
													className='chat__message-text'
													style={{color: colors['--chat-opponent-text']}}>
													Lorem ipsum dolor sit amet, consetur adipiscing elit, sed do eiusmod
												</div>
												<div className='chat__message-time'>22:30</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className='badges-config__preview-footer'>
					<div className='badges-config__preview-btns'>
						<Button
							variant={currentTheme === 2 ? 'secondary' : 'outline-dark'}
							className='mx-2'
							onClick={() => themeClickHandler(2)}>
							{translations.darkTheme}
						</Button>
						<Button
							variant={currentTheme === 1 ? 'secondary' : 'outline-dark'}
							onClick={() => themeClickHandler(1)}>
							{translations.lightTheme}
						</Button>
					</div>
				</div>
			</div>

			{(currentBadge.communicationText ||
				currentBadge.communicationTitle ||
				currentBadge.picFile) &&
				colors && (
					<div className='badges-config__preview-elem'>
						<div
							className='badges-config__preview-wrapper'
							style={{backgroundColor: colors['--background-chat']}}>
							<div className='highlight highlight--custom_3'>
								<div
									className='highlight__container'
									style={{backgroundColor: colors['--chat-opponent-background']}}>
									{currentBadge.communicationPic && (
										<div
											className='highlight__img'
											style={{backgroundImage: `url(${currentBadge.communicationPic})`}}
										/>
									)}

									<div className='highlight__body'>
										<div
											className='highlight__title'
											style={{color: colors['--chat-opponent-text']}}>
											{currentBadge.communicationTitle}
										</div>
										<div
											className='highlight__text'
											style={{color: colors['--chat-opponent-text']}}>
											{currentBadge.communicationText}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='badges-config__preview-footer'>
							<div className='badges-config__preview-btns'>
								<Button
									variant={currentTheme === 2 ? 'secondary' : 'outline-dark'}
									onClick={() => themeClickHandler(2)}>
									{translations.darkTheme}
								</Button>
								<Button
									variant={currentTheme === 1 ? 'secondary' : 'outline-dark'}
									className='mx-2'
									onClick={() => themeClickHandler(1)}>
									{translations.lightTheme}
								</Button>
							</div>
						</div>
					</div>
				)}
		</div>
	);
};

export default observer(BadgePreview);

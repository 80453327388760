import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import useL10n from 'l10n/useL10n';
import authService from 'store/authService';
import {ReactComponent as IcoEmail} from 'assets/svg/ico-email.svg';
import {Container, Image} from 'react-bootstrap';
import Logo from 'assets/images/auth-logo.png';
import RegisterFooter from 'pages/auth/register/footer/RegisterFooter';

const RecoverConfirm: FunctionComponent = function Confirm() {
	const {auth} = useL10n();
	const {user} = useLocalObservable(() => authService);

	return (
		<section className='auth'>
			<Container className='mt-auto'>
				<div className='auth__start'>
					<div className='auth__form'>
						<div className='auth__confirm'>
							<IcoEmail className='auth__confirm-ico' />

							<p
								className='h2 text-center'
								dangerouslySetInnerHTML={{__html: auth.confirm.password}}
							/>

							{user?.email && (
								<div className='auth__confirm-footer'>
									<p className='text-secondary'>
										{auth.confirm.description}
										<b> {user.email}</b>
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
			<RegisterFooter />
		</section>
	);
};

export default observer(RecoverConfirm);

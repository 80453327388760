import {AnalyticsTabType} from 'models/analytics';

export const tabs: AnalyticsTabType[] = [
	{
		key: 'dau90d',
		label: 'DAU last 90 days',
		labels: {
			value: 'Date',
		},
		datasets: [
			{
				label: 'DAU',
				value: 'DAU',
			},
			{
				label: 'Users who sent ≥ 1 message',
				value: 'UsersSentMessage',
			},
		],
	},
	{
		key: 'wau12w',
		label: 'WAU  last 12 weeks',
		labels: {
			value: 'WeekStartDate',
		},
		datasets: [
			{
				label: 'WAU',
				value: 'WAU',
			},
			{
				label: 'UsersSentMessage',
				value: 'UsersSentMessage',
			},
		],
	},
	{
		key: 'mau6m',
		label: 'MAU last 6 month',
		labels: {
			value: 'MonthStartDate',
		},
		datasets: [
			{
				label: 'MAU',
				value: 'MAU',
			},
			{
				label: 'UsersSentMessage',
				value: 'UsersSentMessage',
			},
		],
	},
	{
		key: 'msg90d',
		label: 'Total message sent last 90 days',
		labels: {
			value: 'Date',
		},
		datasets: [
			{
				label: 'TotalMessagesSent',
				value: 'TotalMessagesSent',
			},
		],
	},
	{
		key: 'msg6m',
		label: 'Total message sent last 6 month',
		labels: {
			value: 'MonthStartDate',
		},
		datasets: [
			{
				label: 'TotalMessagesSent',
				value: 'TotalMessagesSent',
			},
		],
	},
	{
		key: 'emoji90d',
		label: 'Animated reactions sent last 90 days',
		labels: {
			value: 'Date',
		},
		datasets: [
			{
				label: 'TotalEmojisSent',
				value: 'TotalEmojisSent',
			},
		],
	},
	{
		key: 'emoji6m',
		label: 'Animated reactions sent last 6 month',
		labels: {
			value: 'MonthStartDate',
		},
		datasets: [
			{
				label: 'TotalEmojisSent',
				value: 'TotalEmojisSent',
			},
		],
	},
	{
		key: 'react90d',
		label: 'Reaction to messages 90 days',
		labels: {
			value: 'Date',
		},
		datasets: [
			{
				label: 'TotalReactions',
				value: 'TotalReactions',
			},
		],
	},
	{
		key: 'react6m',
		label: 'Reaction to messages 6 month',
		labels: {
			value: 'MonthStartDate',
		},
		datasets: [
			{
				label: 'TotalReactions',
				value: 'TotalReactions',
			},
		],
	},
	{
		key: 'active90d',
		label: 'Active users last 90 days',
		labels: {
			value: 'Date',
		},
		datasets: [
			{
				label: 'ActiveUsers',
				value: 'ActiveUsers',
			},
		],
	},
	{
		key: 'active6m',
		label: 'Active users last 6 month',
		labels: {
			value: 'MonthStartDate',
		},
		datasets: [
			{
				label: 'ActiveUsers',
				value: 'ActiveUsers',
			},
		],
	},
];

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {Modal, Alert, Button, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import {ReactComponent as IcoWarn} from 'assets/svg/ico-warn.svg';
import {ReactComponent as IcoSuccess} from 'assets/svg/ico-success.svg';
import classNames from 'classnames';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

interface IWebhooksResponseModal {
	response: {status: any; data: any} | null;
	setResponse: (value: any) => void;
	testUrl: () => void;
	visiblePreloader: boolean;
}

const WebhooksResponseModal: FunctionComponent<IWebhooksResponseModal> =
	function WebhooksResponseModal({response, setResponse, testUrl, visiblePreloader}) {
		const translations = useL10n();

		const {webhooksResponseModalVisible, toggleWebhooksResponseModalVisible} = useLocalObservable(
			() => modalServices
		);
		const {appTheme} = useLocalObservable(() => appService);

		const onModalClose = () => {
			toggleWebhooksResponseModalVisible(false);
			setResponse(null);
		};

		const success = response?.status >= 200 && response?.status <= 299;

		const titleClasses = classNames('mb-0', {
			'text-success': success,
			'text-danger': !success,
		});

		return (
			<Modal
				className='modal'
				show={webhooksResponseModalVisible}
				onHide={onModalClose}
				animation={false}
				centered
				backdrop='static'
				restoreFocus={false}>
				<Modal.Header closeButton className='text-primary'>
					<Modal.Title className={appTheme === Theme.LIGHT ? 'text-dark' : 'text-light'}>
						{success ? translations.responseStatus.success : translations.responseStatus.error}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Alert variant={success ? 'success' : 'danger'} className='d-flex align-items-start'>
							{success ? <IcoSuccess className='ico-lg' /> : <IcoWarn className='ico-lg' />}
							<div className='mx-2'>
								<p className={titleClasses}>
									{response?.status} {response?.data?.message || response?.data}
								</p>
								<p className='text-black mb-0'>
									{success
										? translations.responseStatus.successText
										: translations.responseStatus.errorText}
								</p>
							</div>
						</Alert>
						<div className='d-flex align-items-center'>
							<Button variant='dark' onClick={onModalClose}>
								{translations.btns.close}
							</Button>
							<Button
								variant={appTheme === Theme.LIGHT ? 'outline-dark mx-2' : 'outline-light mx-2'}
								onClick={testUrl}>
								{translations.btns.repeat}
							</Button>
							{visiblePreloader && (
								<div className='text-center'>
									<Spinner animation='border' />
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	};

export default observer(WebhooksResponseModal);

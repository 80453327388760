import {observer, useLocalObservable} from 'mobx-react-lite';
import {Changelog} from 'models/changelog';
import {FunctionComponent} from 'react';
import './changelog.scss';
import {Badge, Button} from 'react-bootstrap';
import Moment from 'react-moment';
import userServices from 'store/userServices';
import useL10n from 'l10n/useL10n';
import {BsFillPencilFill, BsTrashFill} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import useChangelog from 'hooks/useChangelog';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IChangelogElem {
	elem: Changelog;
	getChangelog: () => void;
}

const ChangelogElem: FunctionComponent<IChangelogElem> = function ChangelogElem({
	elem,
	getChangelog,
}) {
	const translations = useL10n();
	const history = useHistory();

	const {isSuperAdmin} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {deleteChangelogById} = useChangelog();

	const editClickHandler = () => {
		history.push(`/changelog/${elem.id}`);
	};
	const deleteChangelog = async (id: number) => {
		await deleteChangelogById(id);
		getChangelog();
	};

	const deleteClickHandler = () => {
		showAlert({
			title: `${translations.alerts.btns.delete}?`,
			text: '',
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.delete,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
						if (elem.id) deleteChangelog(elem.id);
					},
				},
			],
		});
	};

	return (
		<div className='changelog__item block mb-3' data-id={elem.id}>
			<div className='changelog__item-head'>
				<div>
					<Badge bg='secondary' pill>
						<Moment format='DD MMMM YYYY'>{elem.date}</Moment>
					</Badge>
					<Badge bg='light' pill className='mx-2'>
						{elem.version}
					</Badge>
				</div>
				<div className='changelog__item-category'>{elem.name}</div>
			</div>
			{elem.text && (
				<div dangerouslySetInnerHTML={{__html: elem.text}} className='changelog__item-text mb-2' />
			)}
			{isSuperAdmin && (
				<div className='changelog__item-btns'>
					<Button
						size='sm'
						variant={appTheme === Theme.DARK ? 'outline-light' : 'outline-dark'}
						className='changelog__item-btn'
						onClick={editClickHandler}>
						<BsFillPencilFill />
						<span className='mx-2'>{translations.btns.edit}</span>
					</Button>
					<Button
						size='sm'
						variant='danger'
						className='changelog__item-btn mx-2'
						onClick={deleteClickHandler}>
						<BsTrashFill className='text-white' />
						<span className='mx-2'>{translations.btns.remove}</span>
					</Button>
				</div>
			)}
		</div>
	);
};

export default observer(ChangelogElem);

import useSettings from 'hooks/useSettings';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {FunctionComponent} from 'react';

import {Button, Image} from 'react-bootstrap';
import {BsTrashFill, BsFillPencilFill} from 'react-icons/bs';

import alertServices from 'store/alertServices';
import modalServices from 'store/modalServices';

import useL10n from 'l10n/useL10n';
import {NamedPicType} from 'models/enums/NamedPicType.enum';

interface IReaction {
	index: number;
	elem: any;
	type: NamedPicType;
}
const Reaction: FunctionComponent<IReaction> = function Reaction({index, elem, type}) {
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {setReactionModalElem, toggleReactionModalVisible} = useLocalObservable(
		() => modalServices
	);

	const translations = useL10n();

	const {deleteNamedPic} = useSettings();

	const onClickHandler = () => {
		const namedPic = {...elem, index};
		setReactionModalElem(namedPic);
		toggleReactionModalVisible(true);
	};

	const openAlert = () => {
		showAlert({
			title: translations.btns.remove,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deleteNamedPic(type, elem.id);
					},
				},
			],
		});
	};

	return (
		<div className='settings__namedPics-reaction m-3'>
			<Button
				className='settings__namedPics-btn-delete'
				variant='danger'
				size='sm'
				onClick={() => openAlert()}>
				<BsTrashFill />
			</Button>
			<Button
				className='settings__namedPics-btn-openModal'
				variant='light'
				size='sm'
				onClick={onClickHandler}>
				<BsFillPencilFill />
			</Button>
			<Image key={elem.id} src={elem.pic} alt='' />
		</div>
	);
};

export default observer(Reaction);

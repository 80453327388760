import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import BotService from 'services/api/BotService';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);

	const getBot = async () => {
		const response = await BotService.getBot(accessToken);
		if (response.status === ResponseStatus.SUCCESS) return response.data;
		return [];
	};

	const createBot = async (bot: any) => {
		const response = await BotService.createBot(accessToken, bot);
		if (response.status === ResponseStatus.SUCCESS) return response.data;
		return false;
	};

	const updateBot = async (bot: any, id: number) => {
		const response = await BotService.updateBot(accessToken, bot, id);
		if (response.status === ResponseStatus.SUCCESS) return response.data;
		return false;
	};

	const deleteBot = async (id: number) => {
		const response = await BotService.deleteBot(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};
	return {getBot, createBot, updateBot, deleteBot};
};

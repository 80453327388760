import {action, makeObservable, observable} from 'mobx';
import {AuthUser} from 'models/auth';

class AuthService {
	@observable
	public user: AuthUser | null = null;

	@action
	public setUser = (value: any) => {
		this.user = value;
	};

	constructor() {
		makeObservable(this);
	}
}
export default new AuthService();

/* eslint-disable max-lines */
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {NavLink} from 'react-router-dom';

import {AlertBtnType} from 'models/enums/Alert.enum';

import {BsTrashFill, BsFillExclamationCircleFill} from 'react-icons/bs';
import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Button,
	Badge,
	Tooltip,
	Spinner,
	Form,
	Popover,
	OverlayTrigger,
} from 'react-bootstrap';

import RoomService from 'services/api/RoomService';

import userServices from 'store/userServices';
import toastService from 'store/toastServices';
import alertService from 'store/alertServices';

import {Room} from 'models/rooms';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import useL10n from 'l10n/useL10n';

import Filters from 'pages/main/components/rooms/Filters';
import {LIMIT} from 'constants/constants';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import PageTitle from 'components/pageTitle/PageTitle';

type filter = {
	key: string;
	value: any | null;
	prefix: string;
};

const Rooms: FunctionComponent = function Rooms() {
	const [rooms, setRooms] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {appTheme} = useLocalObservable(() => appService);

	const [searchFilter, setSearchFilter] = useState<filter>({
		key: 'external_room_id',
		value: null,
		prefix: 'like',
	});

	const [dateStartFilter, setDateStartFilter] = useState<filter>({
		key: 'created_at',
		value: null,
		prefix: '',
	});

	const [dateEndFilter, setDateEndFilter] = useState<filter>({
		key: 'created_at',
		value: null,
		prefix: 'lt',
	});
	const [sortBy, setSortBy] = useState('active_talkers_length_desc');
	const [offset, setOffset] = useState(0);

	const translations = useL10n();

	const setSearch = (value: string, key: string) => {
		setSearchFilter({...searchFilter, value, key});
	};

	const clearDatesFilter = () => {
		setDateStartFilter({
			key: 'created_at',
			value: null,
			prefix: '',
		});
		setDateEndFilter({
			key: 'created_at',
			value: null,
			prefix: 'lt',
		});
	};

	const setDateStart = (dates: any, value: string | null) => {
		const [start, end] = dates;
		setDateStartFilter({
			...dateStartFilter,
			value,
			prefix: end || start !== end ? 'gte' : '',
		});
	};
	const setDateEnd = (value: string | null) => {
		setDateEndFilter({
			...dateEndFilter,
			value,
		});
	};

	const getRooms = async (limit: number, currentOffset: number, order: string) => {
		setVisiblePreloader(true);
		setRooms([]);
		if (accessToken) {
			const allFilters: filter[] = [];
			if (searchFilter.value) allFilters.push(searchFilter);
			if (dateStartFilter.value) allFilters.push(dateStartFilter);
			if (dateEndFilter.value) allFilters.push(dateEndFilter);

			const response = await RoomService.getRooms(
				accessToken,
				limit,
				currentOffset,
				order,
				allFilters
			);
			setVisiblePreloader(false);
			if (response.status === ResponseStatus.SUCCESS) {
				setRooms(response.data.rooms);
			}
		}
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === '') return;
		setSortBy(value);
		setOffset(0);

		getRooms(LIMIT, 0, value);
	};

	const deleteMessages = async (externalRoomId: string) => {
		let responseText;
		const response = await RoomService.deleteMessages(externalRoomId, accessToken);
		if (response.data === 'Empty data') responseText = translations.toasts.nothingChanged;
		else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		addToast({
			title: `${translations.room.title} ${externalRoomId}`,
			text: responseText,
		});
	};

	const openAlert = (externalRoomId: string) => {
		showAlert({
			title: translations.alerts.room(externalRoomId),
			text: translations.alerts.clearRoom,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.delete,
					type: AlertBtnType.DANGER,
					onClick: () => {
						deleteMessages(externalRoomId);
						hideAlert();
					},
				},
			],
		});
	};

	const renderRoom = useCallback(
		(item: Room, index: number) => {
			return (
				<tr key={index}>
					<td>
						<p className='text-truncate'>
							<NavLink to={`/room/${encodeURIComponent(item.externalRoomId)}`}>
								{item.externalRoomId}
							</NavLink>
						</p>
					</td>
					<td>
						<p className='align-middle text-truncate'>
							{item.name || translations.empty.nameEmpty}
						</p>
					</td>
					<td className='text-center text-secondary align-middle'>
						{item.activeTalkersLength} / {item.talkersLength}
					</td>
					<td className='text-center text-secondary align-middle'>
						{item.lastDayMessagesLength} / {item.lastMonthMessagesLength} / {item.messagesLength}
					</td>
					<td className='text-center align-middle'>
						<Badge bg='secondary'>{item.status}</Badge>
					</td>
					<td className='text-center align-middle'>
						<h5 className='mb-0'>
							{item.reportsLength > 0 && (
								<Badge bg='warning'>
									<NavLink to='/reports' className='d-flex text-white'>
										<span>{item.reportsLength} &nbsp;</span>

										<BsFillExclamationCircleFill />
									</NavLink>
								</Badge>
							)}
						</h5>
					</td>
					<td className='text-center col-1 align-middle'>
						<OverlayTrigger
							placement='left'
							delay={{show: 50, hide: 50}}
							overlay={<Tooltip id='button-tooltip-2'>{translations.alerts.clearRoom}</Tooltip>}>
							<Button
								variant='danger'
								size='sm'
								onClick={() => openAlert(encodeURIComponent(item.externalRoomId))}>
								<BsTrashFill />
							</Button>
						</OverlayTrigger>
					</td>
				</tr>
			);
		},
		[rooms]
	);

	return (
		<Container fluid className='pt-4 text-lg rooms'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.rooms.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.rooms.title}
						text={translations.sidebar.rooms.descr}
					/>

					<Filters
						getRooms={getRooms}
						rooms={rooms}
						setSearchFilter={setSearch}
						setDateStartFilter={setDateStart}
						setDateEndFilter={setDateEnd}
						sortBy={sortBy}
						setSortBy={setSortBy}
						offset={offset}
						setOffset={setOffset}
						clearDatesFilter={clearDatesFilter}
					/>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{rooms?.length > 0 ? (
						<Table
							responsive
							striped={appTheme === Theme.LIGHT}
							bordered
							hover
							variant={appTheme?.toLowerCase()}>
							<thead>
								<tr>
									<th>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.externalRoomId}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.table.externalRoomId}</option>
												<option value='externalRoomId_desc'>
													{translations.table.externalRoomId} &darr;
												</option>
												<option value='externalRoomId_asc'>
													{translations.table.externalRoomId} &uarr;
												</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.name}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.table.name}</option>
												<option value='name_desc'>{translations.table.name} &darr;</option>
												<option value='name_asc'>{translations.table.name} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='text-center text-truncate'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.users}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.table.users}</option>
												<option value='active_talkers_length_desc'>
													{translations.sorting.talkersOnline} &darr;
												</option>
												<option value='active_talkers_length_asc'>
													{translations.sorting.talkersOnline} &uarr;
												</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='text-center text-truncate'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.messages}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.table.messages}</option>
												<option value='messages_length_desc'>
													{translations.sorting.msg} &darr;
												</option>
												<option value='messages_length_asc'>
													{translations.sorting.msg} &uarr;
												</option>
												<option value='last_day_messages_length_desc'>
													{translations.sorting.msgday} &darr;
												</option>
												<option value='last_day_messages_length_asc'>
													{translations.sorting.msgday} &uarr;
												</option>
												<option value='last_month_messages_length_desc'>
													{translations.sorting.msgmonth} &darr;
												</option>
												<option value='last_month_messages_length_asc'>
													{translations.sorting.msgmonth} &uarr;
												</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='col-1 text-center text-truncate'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.status}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
												<option value=''>{translations.table.status}</option>
												<option value='status_desc'>{translations.table.status} &darr;</option>
												<option value='status_asc'>{translations.table.status} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='col-1 text-center text-truncate align-middle'>
										<b>{translations.table.reports}</b>
									</th>
									<th className='col-1 text-center text-truncate align-middle'>
										<b>{translations.table.clear}</b>
									</th>
								</tr>
							</thead>
							<tbody>{rooms.map((elem, index) => renderRoom(elem, index))}</tbody>
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.roomsEmpty}</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Rooms);

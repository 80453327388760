import {action, makeObservable, observable} from 'mobx';
import {Pic, Settings} from 'models/settings';
import {isJsonString} from 'utils/helpers';

type HighlightColors = {
	bg: string;
	btn: string;
	gradient: {from: string; to: string} | null;
};

class SettingsServices {
	@observable
	public settings: Settings = {};

	@observable
	public globalSettings: {enableChat: boolean; disabledChatText: string} = {
		enableChat: true,
		disabledChatText: '',
	};

	@observable
	public projectLanguage = '';

	@observable
	public themes: {id: number; name: string}[] = [];

	@observable
	public theme: any = {};

	@observable
	public themeColors = '';

	@observable
	public lightThemeColors: any = {};

	@observable
	public darkThemeColors: any = {};

	@observable
	public currentThemeId: number | null = null;

	@observable
	public font: {id: number; urls: string; name: string} | null = null;

	@observable
	public avatars: Pic[] = [];

	@observable
	public icons: Pic[] = [];

	@observable
	public reactions: Pic[] = [];

	@observable
	public exportJSON = '';

	@observable
	public error = '';

	@observable
	public colors: any = {};

	@observable
	public brandingColors = {
		bg: '',
		btn: '',
	};

	@observable
	public tabKey = '';

	@observable
	public customHighlightColors: HighlightColors | null = null;

	@observable
	public emojiPicker: number | null = null;

	@action
	public setSettings = (value: any) => {
		this.settings = value;
	};

	@action
	public setGlobalSettings = (value: any) => {
		this.globalSettings = value;
	};

	@action
	public setProjectLanguage = (value: string) => {
		this.projectLanguage = value;
	};

	@action
	public setThemes = (value: {id: number; name: string}[]) => {
		this.themes = value.sort((a, b) => {
			return a.id - b.id;
		});
	};

	@action
	public setTheme = (value: any) => {
		this.theme = value;
	};

	@action
	public setLightThemeColors = (value: any) => {
		this.lightThemeColors = value;
	};

	@action
	public setDarkThemeColors = (value: any) => {
		this.darkThemeColors = value;
	};

	@action
	public setThemeColors = (value: string) => {
		this.themeColors = value;
	};

	@action
	public setCurrentThemeId = (value: number) => {
		this.currentThemeId = value;
	};

	@action
	public setFonts = (value: {id: number; urls: string; name: string} | null) => {
		this.font = value;
	};

	@action
	public setAvatars = (value: Pic[]) => {
		this.avatars = value;
	};

	@action
	public setIcons = (value: Pic[]) => {
		this.icons = value;
	};

	@action
	public setReactions = (value: Pic[]) => {
		this.reactions = value;
	};

	@action
	public setExportJSON = (value: any) => {
		this.exportJSON = value;
	};

	@action
	public setError = (value: string) => {
		this.error = value;
	};

	@action
	public setCustomHighlightColors = (value: HighlightColors) => {
		if (value) {
			this.customHighlightColors = value;
		}
	};

	@action
	public setBrandingColors = (value: string) => {
		if (isJsonString(value)) {
			this.colors = JSON.parse(value);
			this.brandingColors = {
				bg: JSON.parse(value)?.background?.chat?.value,
				btn: JSON.parse(value)?.branding?.value,
			};
			this.setCustomHighlightColors(JSON.parse(value)?.highlight);
		}
	};

	@action
	public clearCustomHighlightColors = () => {
		this.customHighlightColors = null;
	};

	@action
	public clearGradientColors = () => {
		if (this.customHighlightColors) this.customHighlightColors.gradient = null;
	};

	@action
	public setTabKey = (value: string) => {
		this.tabKey = value;
	};

	@action
	public setEmojiPicker = (value: number | null) => {
		this.emojiPicker = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new SettingsServices();

import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {Container, Navbar, Button, Form, FloatingLabel, InputGroup} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';

import {Room} from 'models/room';
import classNames from 'classnames';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

interface IFilters {
	getRooms: (limit: number, offset: number, order: string) => void;
	rooms: Room[];
	setSearchFilter: (value: string, key: string) => void;
	setDateStartFilter: (dates: any, value: string | null) => void;
	setDateEndFilter: (value: string | null) => void;
	sortBy: string;
	setSortBy: (value: string) => void;
	offset: number;
	setOffset: (value: number) => void;
	clearDatesFilter: () => void;
}

const LIMIT = 20;

const Filters: FunctionComponent<IFilters> = function Filters({
	getRooms,
	rooms,
	setSearchFilter,
	setDateStartFilter,
	setDateEndFilter,
	sortBy,
	setSortBy,
	offset,
	setOffset,
	clearDatesFilter,
}) {
	const [search, setSearch] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const [searchBy, setSearchBy] = useState('external_room_id');
	const [startDate, setStartDate] = useState<Date | null>();
	const [endDate, setEndDate] = useState<Date | null>();
	const [maxDate, setMaxDate] = useState<Date | null>();
	const [calendarOpen, setCalendarOpen] = useState(false);
	const [interval, setInterval] = useState<number | null>(null);
	const translations = useL10n();

	const {projectId, appTheme} = useLocalObservable(() => appService);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
		setSearchFilter(value, searchBy);
	};

	const onSearchSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSearchFilter(search, value);
		setSearchBy(value);
	};

	const dateRange = (days: number, value?: Date | null) => {
		const date = value || new Date();
		return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - days));
	};

	const addDays = (days: number, date: Date) => {
		return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + days));
	};

	const setDefaultPeriod = (period: number | null) => {
		if (period) {
			setStartDate(dateRange(period, new Date()));
			setEndDate(new Date());
			setDateStartFilter(
				[dateRange(period, new Date()), new Date()],
				dateRange(period, new Date()).toISOString()
			);
			setDateEndFilter(new Date().toISOString());
			return;
		}
		setStartDate(null);
		setEndDate(null);
		clearDatesFilter();
	};

	const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsSearching(!isSearching);
		setOffset(LIMIT);
		if (search) {
			setStartDate(null);
			setEndDate(null);
			setMaxDate(null);
			setDateStartFilter([], null);
			setDateEndFilter(null);
		} else {
			setDefaultPeriod(interval);
		}
	};

	const getPrevPage = () => {
		setOffset(offset - LIMIT);
		getRooms(LIMIT, offset - LIMIT, sortBy);
	};

	const getNextPage = () => {
		setOffset(offset + LIMIT);
		getRooms(LIMIT, offset + LIMIT, sortBy);
	};

	const onDateChange = (dates: any) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		setMaxDate(addDays(interval || 30, start));

		const setStart = start
			? new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())).toISOString()
			: null;

		setDateStartFilter(dates, setStart);

		const setEnd = end
			? new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate() + 1)).toISOString()
			: null;

		setDateEndFilter(setEnd);
	};

	const onCalendarClose = () => {
		setMaxDate(null);
		setCalendarOpen(false);
	};

	const onCalendarOpen = () => {
		setCalendarOpen(true);
	};

	const setStartValues = () => {
		setSearchBy('external_room_id');
		setSearch('');
		setSearchFilter('', 'external_room_id');
		setDefaultPeriod(interval);
		setSortBy('active_talkers_length_desc');
		setIsSearching(false);
	};

	const clearFilters = () => {
		setStartValues();
	};

	const datesSelectClasses = classNames('form-control form-select form-datepicker', {
		'form-datepicker--opened': calendarOpen,
	});

	useEffect(() => {
		if ((startDate && endDate) || (startDate === null && endDate === null)) {
			setOffset(0);
			getRooms(LIMIT, 0, sortBy);
		}
	}, [startDate, endDate, isSearching]);

	useEffect(() => {
		if (projectId.includes('liga-')) {
			setInterval(9);
			setDefaultPeriod(9);
		} else setDefaultPeriod(interval);
	}, [projectId]);

	return (
		<Navbar variant='dark' bg='dark' expand='lg' className='mb-3'>
			<Container fluid>
				<Navbar className='w-100 p-0 d-flex justify-content-between flex-wrap'>
					<div className='filters'>
						<div className='filters__search'>
							<Form onSubmit={handleSearch}>
								<InputGroup>
									<FloatingLabel controlId='floatingSelect' label={translations.search.placeholder}>
										<Form.Select onChange={onSearchSelect} value={searchBy}>
											<option value='external_room_id'>{translations.search.roomId}</option>
											<option value='name'>{translations.search.name}</option>
										</Form.Select>
									</FloatingLabel>

									<Form.Control
										placeholder={translations.search.btn}
										value={search}
										onChange={onChangeHandler}
									/>

									<Button
										variant={appTheme === Theme.LIGHT ? 'secondary' : 'dark'}
										id='button-search'
										type='submit'>
										{translations.search.btn}
									</Button>
								</InputGroup>
							</Form>
						</div>
						<div className='d-flex'>
							<div className='filters__datepicker'>
								<DatePicker
									className={datesSelectClasses}
									placeholderText={translations.date}
									dateFormat='dd.MM.yyyy'
									onChange={onDateChange}
									onCalendarClose={onCalendarClose}
									onCalendarOpen={onCalendarOpen}
									startDate={startDate}
									endDate={endDate}
									maxDate={maxDate}
									selectsRange
								/>
							</div>
							&nbsp;
							<Button variant='dark' onClick={clearFilters}>
								{translations.clear}
							</Button>
						</div>

						<div className='filters__btns'>
							<Button
								className='d-flex'
								onClick={getPrevPage}
								disabled={!rooms.length || offset === 0}>
								<BsArrowLeftCircleFill />
							</Button>
							&nbsp;
							<Button
								className='d-flex'
								onClick={getNextPage}
								disabled={!rooms.length || rooms.length < LIMIT}>
								<BsArrowRightCircleFill />
							</Button>
						</div>
					</div>
				</Navbar>
			</Container>
		</Navbar>
	);
};

export default observer(Filters);

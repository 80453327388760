import {observer} from 'mobx-react-lite';
import {FunctionComponent} from 'react';

import Select, {StylesConfig} from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface ISelectControl {
	handleChange: (selectedOption: any) => void;
	value?: {value: string; label: string};
	options: {value: string; label: string}[];
	theme?: string;
	isUppercase?: boolean;
	isSearchable?: boolean;
	isClearable?: boolean;
	isCreatable?: boolean;
	disabled?: boolean;
	isMulti?: boolean;
	placeholder?: string;
}

const SelectControl: FunctionComponent<ISelectControl> = function SelectControl({
	handleChange,
	options,
	value,
	theme,
	isUppercase,
	isSearchable = false,
	isClearable = false,
	isCreatable = false,
	disabled = false,
	isMulti = false,
	placeholder = '',
}) {
	const colourStylesDark: StylesConfig<any> = {
		control: (styles, {isDisabled, isFocused}) => ({
			...styles,
			backgroundColor: 'rgba(46, 50, 58, 1)',
			borderColor: 'rgba(46, 50, 58, 1)',
			color: 'white',
			cursor: 'pointer',
		}),
		dropdownIndicator: (styles, {isDisabled, isFocused}) => ({
			...styles,
			color: 'white',
		}),
		option: (styles, {data, isDisabled, isFocused, isSelected}) => {
			return {
				...styles,
				color: 'white',
				cursor: 'pointer',
			};
		},
		input: styles => ({...styles, color: 'white'}),
		placeholder: styles => ({...styles, color: 'white'}),
		singleValue: (styles, {data}) => ({
			...styles,
			color: 'white',
			fontWeight: 600,
			textTransform: isUppercase ? 'uppercase' : 'unset',
		}),
		menu: styles => ({
			...styles,
			zIndex: '999',
			backgroundColor: 'rgb(53,57,65)',
		}),
		menuList: styles => ({
			...styles,
			height: 'auto',
		}),
	};

	const colourStylesLight: StylesConfig<any> = {
		control: (styles, {isDisabled, isFocused}) => ({
			...styles,
			backgroundColor: '#f8f9fa',
			borderColor: '#f8f9fa',
			color: 'rgb(108, 117, 125)',
			cursor: 'pointer',
		}),
		dropdownIndicator: (styles, {isDisabled, isFocused}) => ({
			...styles,
			color: 'rgb(108, 117, 125)',
		}),
		option: (styles, {data, isDisabled, isFocused, isSelected}) => {
			return {
				...styles,
				color: 'rgb(108, 117, 125)',
				cursor: 'pointer',
				backgroundColor: isFocused ? 'rgba(53,57,65,0.1)' : 'white',
				fontSize: '0.8rem',
				lineHeight: '1rem',
				textOverflow: 'ellipsis',
				textAlign: 'left',
				overflow: 'hidden',
			};
		},
		input: styles => ({...styles, color: 'black'}),
		placeholder: styles => ({...styles, color: 'white'}),
		singleValue: (styles, {data}) => ({
			...styles,
			color: 'rgb(108, 117, 125)',
			fontWeight: 300,
			textTransform: isUppercase ? 'uppercase' : 'unset',
		}),
		menu: styles => ({
			...styles,
			zIndex: '999',
			backgroundColor: 'white',
		}),
		menuList: styles => ({
			...styles,
			height: 'auto',
		}),
	};

	return !isCreatable ? (
		<Select
			isSearchable={isSearchable}
			isClearable={isClearable}
			value={value}
			onChange={handleChange}
			options={options}
			classNamePrefix='select'
			styles={theme === 'dark' ? colourStylesDark : colourStylesLight}
			theme={selectTheme => ({
				...selectTheme,
				borderRadius: 0,
				colors: {
					...selectTheme.colors,
					primary25: 'rgb(62,66,74)',
					primary: 'black',
				},
			})}
			maxMenuHeight={600}
			isDisabled={disabled}
			isMulti={isMulti}
			placeholder={placeholder}
		/>
	) : (
		<CreatableSelect
			isSearchable={isSearchable}
			isClearable={isClearable}
			value={value}
			onChange={handleChange}
			options={options}
			classNamePrefix='select'
			styles={theme === 'dark' ? colourStylesDark : colourStylesLight}
			theme={selectTheme => ({
				...selectTheme,
				borderRadius: 0,
				colors: {
					...selectTheme.colors,
					primary25: 'rgb(62,66,74)',
					primary: 'black',
				},
			})}
			maxMenuHeight={600}
			isDisabled={disabled}
		/>
	);
};

export default observer(SelectControl);

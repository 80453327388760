import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Container, Row, Col, Breadcrumb, Spinner} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import {Theme} from 'models/enums/Theme.enum';

import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import appService from 'store/appService';
import PageTitle from 'components/pageTitle/PageTitle';
import AppearanceSettings from './components/settings/appearence/AppearanceSettings';

const Settings: FunctionComponent = function Settings() {
	const {getThemes} = useSettings();
	const {themes} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const translations = useL10n();

	const getThemesWithServices = async () => {
		await getThemes();
		setVisiblePreloader(false);
	};
	useEffect(() => {
		getThemesWithServices();
	}, []);

	return (
		<Container fluid className='settings pt-4'>
			<Row className='mb-4'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.appearance.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.appearance.title}
						text={translations.sidebar.appearance.descr}
					/>
				</Col>
			</Row>

			{visiblePreloader && (
				<div className='preloader'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{themes.length > 0 && <AppearanceSettings />}
		</Container>
	);
};

export default observer(Settings);

import LanguageTag from 'models/enums/LanguageTag.enum';
import {action, makeObservable, observable} from 'mobx';
import translations from 'l10n/translations';
import {Theme} from 'models/enums/Theme.enum';

class AppService {
	@observable
	public language: LanguageTag = LanguageTag.ru;

	@observable
	public userLocale = '';

	@observable
	public projectId = '';

	@observable
	public projectApiKey = '';

	@observable
	public showHiddenSettings = false;

	@observable
	public appTheme = Theme.LIGHT;

	@observable
	public appThemeMode = Theme.LIGHT;

	@action
	public setLanguage = (lang: LanguageTag) => {
		translations.setLanguage(lang);
		this.language = lang;
	};

	@action
	public setProjectId = (projectId: string) => {
		this.projectId = projectId;
	};

	@action
	public setProjectApiKey = (apiKey: string) => {
		this.projectApiKey = apiKey;
	};

	@action
	public setUserLocale = (value: string) => {
		this.userLocale = value;
	};

	@action
	public setShowHiddenSettings = (value: boolean) => {
		this.showHiddenSettings = value;
	};

	@action
	public setAppTheme = (value: Theme) => {
		this.appTheme = value;
	};

	@action
	public setAppThemeMode = (value: Theme) => {
		this.appThemeMode = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new AppService();

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import useL10n from 'l10n/useL10n';
import authService from 'store/authService';
import {ReactComponent as IcoEmail} from 'assets/svg/ico-email.svg';
import {Container, Image} from 'react-bootstrap';
import Logo from 'assets/images/auth-logo.png';
import useAdminProxy from 'hooks/useAdminProxy';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {useHistory} from 'react-router-dom';
import RegisterFooter from '../footer/RegisterFooter';

const RegisterConfirm: FunctionComponent = function Confirm() {
	const {auth} = useL10n();

	const {user, setUser} = useLocalObservable(() => authService);
	const {authConfirm} = useAdminProxy();

	const [token, setToken] = useState('');
	const [isConfirmed, setIsConfirmed] = useState(false);
	const history = useHistory();

	const confirm = async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const queryToken = urlParams.get('token');

		if (queryToken) {
			setToken(queryToken);
			const response = await authConfirm(queryToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setUser(null);
				setIsConfirmed(true);
				setTimeout(() => {
					history.push('/login');
				}, 500);
			}
		}
	};

	useEffect(() => {
		confirm();
	}, []);

	return (
		<section className='auth'>
			<Container className='mt-auto'>
				<p className='h3 mb-5 text-center'>{auth.createAccount}</p>
				<div className='auth__start'>
					<div className='auth__form'>
						<div className='auth__confirm'>
							<IcoEmail className='auth__confirm-ico' />
							{!token && (
								<p
									className='h2 text-center'
									dangerouslySetInnerHTML={{__html: auth.confirm.register}}
								/>
							)}
							{token && !isConfirmed && (
								<p
									className='h2 text-center'
									dangerouslySetInnerHTML={{__html: auth.confirm.process}}
								/>
							)}
							{isConfirmed && (
								<p
									className='h2 text-center'
									dangerouslySetInnerHTML={{__html: auth.confirm.emailConfirmed}}
								/>
							)}
							{user?.email && !token && (
								<div className='auth__confirm-footer'>
									<p className='text-secondary'>
										{auth.confirm.description}
										<b> {user.email}</b>
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
			<RegisterFooter />
		</section>
	);
};

export default observer(RegisterConfirm);

/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import {Switch, Route, useHistory, useLocation} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import axios from 'axios';
import './app.scss';
import {useEffect} from 'react';
import {containsClass} from 'utils/helpers';

import Login from 'pages/auth/login/Login';
import Register from 'pages/auth/register/Register';
import Recover from 'pages/auth/recover/Recover';
import RegisterConfirm from 'pages/auth/register/confirm/RegisterConfirm';
import RegisterProject from 'pages/auth/register/project/RegisterProject';
import RecoverConfirm from 'pages/auth/recover/confirm/RecoverConfirm';
import RecoverPasword from 'pages/auth/recover/password/RecoverPasword';

import Main from 'pages/Main';

import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import appService from 'store/appService';
import toastService from 'store/toastServices';
import reportServices from 'store/reportServices';
import banServices from 'store/banServices';
import flaggedMessageService from 'store/flaggedMessageService';
import settingsServices from 'store/settingsServices';
import configService from 'store/configService';

import SocketIoService from 'services/SocketIoService';

import ToastList from 'components/toaster/ToastList';
import Alert from 'components/alert/Alert';

import {Report} from 'models/reports';
import LanguageTag from 'models/enums/LanguageTag.enum';
import {ResponseStatusCode} from 'models/enums/Network.enums';
import UserRole from 'models/enums/UserRole.enum';
import FlaggedMessageStatus from 'models/enums/FlaggedMessageStatus.enum';
import ResponseMessage from 'models/enums/ResponseMessage.enum';
import {Theme} from 'models/enums/Theme.enum';

import {Ban} from 'models/ban';
import {FlaggedMessage} from 'models/room';
import useReports from 'hooks/useReports';
import useAutoBans from 'hooks/useAutoBans';
import useSettings from 'hooks/useSettings';
import useAdminProxy from 'hooks/useAdminProxy';
import useFlaggedMessage from 'hooks/useFlaggedMessage';
import useTheme from 'hooks/useTheme';
import useChangelog from 'hooks/useChangelog';
import useClusterUrls from 'hooks/useClusterUrls';
import useUser from 'hooks/useUser';

import PrivateRoute from './PrivateRoute';
import AuthRoutes from './AuthRoutes';

const HTMLNode = document.querySelector('html');

const App = function App<IApp>() {
	const {setAccessToken, setIsAdmin} = useLocalObservable(() => userServices);
	const {
		submenuMessage,
		setSubmenuMessage,
		submenuAvatar,
		setSubmenuAvatar,
		submenuMessagePinned,
		setSubmenuMessagePinned,
	} = useLocalObservable(() => roomServices);
	const {
		setLanguage,
		setUserLocale,
		projectId,
		setProjectId,
		setShowHiddenSettings,
		appTheme,
		appThemeMode,
		setAppTheme,
		setAppThemeMode,
	} = useLocalObservable(() => appService);
	const {toasts} = useLocalObservable(() => toastService);
	const {accessToken, setProjectUsers, adminData} = useLocalObservable(() => userServices);
	const {addReport, setReportsCount, increaseReportsCount, decreaseReportsCount} =
		useLocalObservable(() => reportServices);
	const {increaseBansCount, decreaseBansCount, addAutoBan, removeAutoBan} = useLocalObservable(
		() => banServices
	);
	const {clearUserData, setIsSuperAdmin, setIsCreator} = useLocalObservable(() => userServices);
	const {increaseFlaggedMessageCount, decreaseFlaggedMessageCount, setFlaggedMessageCount} =
		useLocalObservable(() => flaggedMessageService);
	const {settings, lightThemeColors, darkThemeColors, emojiPicker, setEmojiPicker} =
		useLocalObservable(() => settingsServices);
	const {serviceUrls} = useLocalObservable(() => configService);

	const {search} = useLocation();
	const {getCountUnviewed} = useChangelog();
	const {getClusterBackendUrls} = useClusterUrls();

	const userToken = localStorage.getItem('accessToken');
	const user = localStorage.getItem('user');
	const project = localStorage.getItem('projectId');
	const projectUsers = localStorage.getItem('projectUsers');
	const lang = localStorage.getItem('watcherswebLanguage');
	const isSuperAdmin = localStorage.getItem('isSuperAdmin');
	const isCreator = localStorage.getItem('isCreator');
	const showHiddenSettings = new URLSearchParams(search).get('showKey');
	const watchersAdminTheme = localStorage.getItem('watchersAdminTheme');

	const history = useHistory();

	const {getReportsCount} = useReports();
	const {getAutoBanned} = useAutoBans();
	const {getSettings, getColors} = useSettings();
	const {getAdmin} = useAdminProxy();
	const {getSelf} = useUser();
	const {getNewFlaggedMessagesCount} = useFlaggedMessage();
	const {getThemeColors, setThemeColors} = useTheme();

	const onClickDocumentHandler = (event: any) => {
		const eventTarget = event.target;
		if (!containsClass(eventTarget, 'chat__message-submenu-btn') && submenuMessage) {
			setSubmenuMessage(null);
		}
		if (!containsClass(eventTarget, 'chat__message-submenu-btn') && submenuMessagePinned) {
			setSubmenuMessagePinned(false);
		}
		if (!containsClass(eventTarget, 'chat__message-submenu-btn') && submenuAvatar) {
			setSubmenuAvatar(null);
		}
		if (eventTarget.tagName !== 'EM-EMOJI-PICKER') {
			setEmojiPicker(null);
		}
	};

	const onReportCreatedHandler = (data: {report: Report}) => {
		increaseReportsCount();
		addReport(data.report);
	};
	const onReportSolvedHandler = (data: {report: {id: number}}) => {
		// removeReport(data.report.id);
		decreaseReportsCount();
	};

	const onAutoBanCreatedHandler = (data: {ban: Ban}) => {
		addAutoBan(data.ban);
		increaseBansCount();
	};

	const onAutoBanDeletedHandler = (data: {ban: Ban}) => {
		removeAutoBan(data.ban.id);
		decreaseBansCount();
	};

	const onBanUpdatedHandler = (data: {ban: Ban}) => {
		if (data.ban.isApproved) decreaseBansCount();
	};

	const onFlaggedMessageCreatedHandler = (data: {flaggedMessage: FlaggedMessage}) => {
		increaseFlaggedMessageCount();
	};

	const onFlaggedMessageResolvedHandler = (data: {flaggedMessage: FlaggedMessage}) => {
		if (data.flaggedMessage.status !== FlaggedMessageStatus.NEW) decreaseFlaggedMessageCount();
	};

	const onFlaggedMessageDeletedHandler = (data: {flaggedMessage: FlaggedMessage}) => {
		if (data.flaggedMessage.status === FlaggedMessageStatus.NEW) decreaseFlaggedMessageCount();
	};

	const subscribeOnSocketMessages = async () => {
		SocketIoService.emitAdminJoin('admin:reports');
		SocketIoService.emitAdminJoin('admin:bans');
		SocketIoService.emitAdminJoin('admin:flagged-message');
		SocketIoService.emitAdminJoin('admin:changelog');

		SocketIoService.onReportCreated(onReportCreatedHandler);
		SocketIoService.onReportSolved(onReportSolvedHandler);
		SocketIoService.onAutoBanCreated(onAutoBanCreatedHandler);
		SocketIoService.onAutoBanDeleted(onAutoBanDeletedHandler);
		SocketIoService.onBanUpdated(onBanUpdatedHandler);
		SocketIoService.onFlaggedMessageCreated(onFlaggedMessageCreatedHandler);
		SocketIoService.onFlaggedMessageResolved(onFlaggedMessageResolvedHandler);
		SocketIoService.onFlaggedMessageDeleted(onFlaggedMessageDeletedHandler);
	};

	const unSubscribeSocketMessages = () => {
		SocketIoService.offReportCreated(onReportCreatedHandler);
		SocketIoService.offReportSolved(onReportSolvedHandler);
		SocketIoService.offAutoBanCreated(onAutoBanCreatedHandler);
		SocketIoService.offAutoBanDeleted(onAutoBanDeletedHandler);
		SocketIoService.offBanUpdated(onBanUpdatedHandler);
		SocketIoService.offFlaggedMessageCreated(onFlaggedMessageCreatedHandler);
		SocketIoService.offFlaggedMessageResolved(onFlaggedMessageResolvedHandler);
		SocketIoService.offFlaggedMessageDeleted(onFlaggedMessageDeletedHandler);
	};

	const unSubscribeOnSocketMessages = () => {
		unSubscribeSocketMessages();
		SocketIoService.soketDisconnect();
	};

	const getDefaultThemeColors = async (id: number) => {
		const colorsDefault = await getColors(id);
		if (colorsDefault) {
			getThemeColors(colorsDefault, 1);
		}
	};

	useEffect(() => {
		if (lightThemeColors || darkThemeColors) {
			setThemeColors(lightThemeColors || darkThemeColors);
		}
	}, [lightThemeColors, darkThemeColors]);

	useEffect(() => {
		let theme = appThemeMode;
		if (appThemeMode === Theme.SYSTEM) {
			theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT;
		}

		setAppTheme(theme);
		HTMLNode?.setAttribute('theme', theme);
	}, [appThemeMode]);

	useEffect(() => {
		const userLocale =
			navigator.languages && navigator.languages.length
				? navigator.languages[0]
				: navigator.language;
		setUserLocale(userLocale);
		setLanguage(userLocale === 'ru' || userLocale === 'ru-RU' ? LanguageTag.ru : LanguageTag.en);
	}, []);

	useEffect(() => {
		if (submenuMessage || submenuAvatar || submenuMessagePinned || emojiPicker) {
			document.addEventListener('mousedown', onClickDocumentHandler);
		}

		return () => {
			document.removeEventListener('mousedown', onClickDocumentHandler);
		};
	}, [submenuMessage, submenuAvatar, submenuMessagePinned, emojiPicker]);

	useEffect(() => {
		userToken && setAccessToken(userToken);
		project && setProjectId(project);
		if (
			window.location.pathname !== '/register' &&
			window.location.pathname !== '/register/confirm' &&
			window.location.pathname !== '/recover' &&
			window.location.pathname !== '/recover/password' &&
			window.location.pathname !== '/change-password' &&
			window.location.pathname !== '/login/mobile' &&
			(!userToken || !project)
		) {
			history.push('/login');
			clearUserData();
			setProjectId('');
			setProjectUsers([]);
		}

		if (user) setIsAdmin(JSON.parse(user).role === UserRole.ADMIN);
		if (projectUsers) setProjectUsers(JSON.parse(projectUsers));
		if (lang) lang === LanguageTag.en ? setLanguage(LanguageTag.en) : setLanguage(LanguageTag.ru);
		if (isSuperAdmin) setIsSuperAdmin(true);
		if (isCreator) setIsCreator(true);
		if (showHiddenSettings) {
			setShowHiddenSettings(true);
		}

		if (watchersAdminTheme) setAppThemeMode(watchersAdminTheme as Theme);

		axios.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				if (
					error.response?.status &&
					(error.response.status === ResponseStatusCode.Unauthorized ||
						error.response.data.message === ResponseMessage.FORBIDDEN ||
						error.response.data.message === ResponseMessage.INVALID_TOKEN ||
						error.response.data.message === ResponseMessage.REQURED_TOKEN)
				) {
					clearUserData();
					setProjectId('');
					setProjectUsers([]);
					history.push('/login');
				}

				return Promise.reject(error);
			}
		);

		if (project) axios.defaults.headers.common.project = project;
	}, []);

	useEffect(() => {
		if (adminData) getAdmin(adminData.adminId);
	}, [adminData]);

	useEffect(() => {
		if (settings?.defaultTheme?.id) {
			getDefaultThemeColors(settings?.defaultTheme?.id);
		}
	}, [settings]);

	useEffect(() => {
		if (accessToken && projectId && serviceUrls) {
			getReportsCount();
			getAutoBanned();
			getSettings();
			getSelf();
			getNewFlaggedMessagesCount();
			getCountUnviewed();
		}
		if (accessToken && projectId && SocketIoService.socket === null) {
			SocketIoService.init(
				accessToken,
				projectId,
				subscribeOnSocketMessages,
				unSubscribeSocketMessages
			);
		}

		return () => {
			unSubscribeOnSocketMessages();
			setReportsCount(0);
			setFlaggedMessageCount(0);
		};
	}, [accessToken, projectId, serviceUrls]);

	useEffect(() => {
		if (serviceUrls && projectUsers && project)
			getClusterBackendUrls(JSON.parse(projectUsers), project);
	}, [serviceUrls, projectUsers, project]);

	return (
		<div className='main'>
			<Switch>
				<AuthRoutes exact path='/login'>
					<Login />
				</AuthRoutes>

				<AuthRoutes exact path='/register'>
					<Register />
				</AuthRoutes>

				<AuthRoutes exact path='/register/confirm'>
					<RegisterConfirm />
				</AuthRoutes>

				<AuthRoutes exact path='/recover'>
					<Recover />
				</AuthRoutes>

				<AuthRoutes exact path='/recover/confirm'>
					<RecoverConfirm />
				</AuthRoutes>

				<AuthRoutes exact path='/recover/password'>
					<RecoverPasword />
				</AuthRoutes>

				<Route exact path='/register/project'>
					<RegisterProject />
				</Route>

				{projectId && (
					<PrivateRoute path='/*'>
						<Main />
					</PrivateRoute>
				)}
			</Switch>

			{toasts.length > 0 && <ToastList />}
			<Alert />
		</div>
	);
};

export default observer(App);

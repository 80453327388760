import axios from 'axios';
import {useLocalObservable} from 'mobx-react-lite';
import {ProjectUser} from 'models/projectUser';
import SocketIoService from 'services/SocketIoService';
import AnalyticsService from 'services/api/AnalyticsService';
import AntimatService from 'services/api/AntimatService';
import AutopostingService from 'services/api/AutopostingService';
import ChangelogService from 'services/api/ChangelogService';
import EnvironmentService from 'services/api/EnvironmentService';
import ExporterService from 'services/api/ExporterService';
import PartnersCreatorService from 'services/api/PartnersCreatorService';
import SemanticAnalysisService from 'services/api/SemanticAnalysisService';
import SettingsService from 'services/api/SettingsService';
import configService from 'store/configService';
import {findUrlValueByKey} from 'utils/helpers';

export default () => {
	const {serviceUrls, setChatUrl, setAppInstance} = useLocalObservable(() => configService);

	const getClusterBackendUrls = (projectUsers: ProjectUser[], activeProjectId: string) => {
		const cluster = projectUsers
			.find(el => el.projectId === activeProjectId)
			?.project.cluster?.toUpperCase();
		if (cluster) {
			const backendURL = findUrlValueByKey(serviceUrls, `REACT_APP_BACKEND_URL_${cluster}`);
			const chatURL = findUrlValueByKey(serviceUrls, `REACT_APP_CHAT_URL_${cluster}`);
			const instance = findUrlValueByKey(serviceUrls, `REACT_APP_INSTANCE_${cluster}`);

			const settingsServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_SETTINGS_SERVICE_URL_${cluster}`
			);
			const changelogServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_CHANGELOG_SERVICE_URL_${cluster}`
			);

			const antimatServiceURL = findUrlValueByKey(serviceUrls, `REACT_APP_ANTIMAT_URL_${cluster}`);
			const exporterServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_EXPORTER_URL_${cluster}`
			);
			const partnersCreatorServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_PARTNERS_CREATOR_URL_${cluster}`
			);
			const environmentServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_ENVIRONMENT_SERVICE_URL_${cluster}`
			);
			const autopostingServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_AUTOPOSTING_SERVICE_URL_${cluster}`
			);
			const analyticsServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_ANALYTICS_URL_${cluster}`
			);
			const semanticAnalysisServiceURL = findUrlValueByKey(
				serviceUrls,
				`REACT_APP_SEMANTIC_ANALYSIS_SERVICE_URL_${cluster}`
			);

			setAppInstance(instance);
			setChatUrl(chatURL);
			SettingsService.setBaseUrl(settingsServiceURL);
			SocketIoService.setBaseUrl(backendURL);
			ChangelogService.setBaseUrl(changelogServiceURL);
			AntimatService.setBaseUrl(antimatServiceURL);
			ExporterService.setBaseUrl(exporterServiceURL);
			PartnersCreatorService.setBaseUrl(partnersCreatorServiceURL);
			EnvironmentService.setBaseUrl(environmentServiceURL);
			AutopostingService.setBaseUrl(autopostingServiceURL);
			AnalyticsService.setBaseUrl(analyticsServiceURL);
			SemanticAnalysisService.setBaseUrl(semanticAnalysisServiceURL);

			axios.defaults.baseURL = backendURL;
		}
	};

	return {
		getClusterBackendUrls,
	};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';

import {Container, Row, Col, Breadcrumb, Spinner, Tabs, Tab} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import PageTitle from 'components/pageTitle/PageTitle';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import badgeService from 'store/badgeService';
import useBadge from 'hooks/useBadge';
import BadgeSettings from './BadgeSettings';
import BadgeUsers from './BadgeUsers';

const CreateBadge: FunctionComponent = function CreateBadge() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const translations = useL10n();
	const [key, setKey] = useState('settings');
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {appTheme} = useLocalObservable(() => appService);
	const {badge, clear} = useLocalObservable(() => badgeService);

	const {getBadge} = useBadge();

	const getBadgeWithServices = async () => {
		setVisiblePreloader(true);
		await getBadge(+slug);
		setVisiblePreloader(false);
	};

	useEffect(() => {
		if (slug) {
			getBadgeWithServices();
		}
	}, [slug]);

	useEffect(() => {
		return () => {
			clear();
		};
	}, []);

	return (
		<Container fluid className='pt-4 badges-config'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/badges'>{translations.sidebar.badges.title}</NavLink>
						</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={slug ? translations.uploadBadges.edit : translations.uploadBadges.create.title}
					/>

					<Tabs
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						id='badgeTabs'
						className='nav-tabs mb-3'
						variant={appTheme.toLowerCase()}>
						<Tab
							eventKey='settings'
							title={translations.uploadBadges.create.settings}
							tabClassName='text-secondary'>
							{visiblePreloader && (
								<div className='text-center'>
									<Spinner
										animation='border'
										variant={appTheme === Theme.DARK ? 'light' : 'dark'}
									/>
								</div>
							)}
							{!visiblePreloader && <BadgeSettings />}
						</Tab>

						{slug && (
							<Tab
								eventKey='users'
								title={translations.uploadBadges.create.users}
								tabClassName='text-secondary'>
								<BadgeUsers />
							</Tab>
						)}
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(CreateBadge);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import SelectControl from 'components/select/SelectControl';
import useL10n from 'l10n/useL10n';
import {Form, Spinner} from 'react-bootstrap';
import {Settings as AutopostingSettings} from 'models/autoposting';
import {Theme as AppTheme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import useAutoposting from 'hooks/useAutoposting';
import autopostingService from 'store/autopostingService';
import useSettings from 'hooks/useSettings';
import settingsServices from 'store/settingsServices';
import {RoomType} from 'models/enums/Autoposting.enum';
import {textEnding} from 'utils/helpers';

interface ISettings {
	isCustomMessagesList?: boolean;
}

const Settings: FunctionComponent<ISettings> = function Settings({isCustomMessagesList}) {
	const translations = useL10n();

	const {appTheme, language} = useLocalObservable(() => appService);
	const {autopostingSettings} = useLocalObservable(() => autopostingService);
	const {settings} = useLocalObservable(() => settingsServices);

	const {getSettings, updateSettings} = useAutoposting();
	const {patchSettings} = useSettings();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [enableAutoposting, setEnableAutoposting] = useState(false);

	const [mySettings, setMySettings] = useState<AutopostingSettings>({
		id: 1,
		project: '',
		sendTo: RoomType.EMPTY,
		inactivityTimerHours: 5,
		sendDelaySeconds: 1,
		useDefaultMessagesList: true,
	});

	const [inactivityTimerHours, setInactivityTimerHours] = useState({
		value: '12',
		label: '12 часов',
	});
	const [sendDelaySeconds, setSendDelaySeconds] = useState({
		value: '5',
		label: '5 секунд',
	});

	const notActiveOptions = useRef<any>([
		{
			value: '12',
		},
		{
			value: '24',
		},
		{
			value: '48',
		},
		{
			value: '60',
		},
		{
			value: '120',
		},
	]);

	const delayOptions = useRef<any>([
		{
			value: '1',
		},
		{
			value: '5',
		},
		{
			value: '10',
		},
		{
			value: '15',
		},
		{
			value: '20',
		},
		{
			value: '30',
		},
	]);

	const onChangeToggleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setEnableAutoposting(checked);
		patchSettings({
			enableAutoposting: checked,
		});
	};

	const onChangeRadioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name, value} = event.target;
		setMySettings({
			...mySettings,
			[name]: name === 'sendTo' ? value : value === 'default',
		});
		updateSettings({
			[name]: name === 'sendTo' ? value : value === 'default',
		});
	};

	const handleChangeInactivity = (selectedOption: any) => {
		setInactivityTimerHours(selectedOption);
		if (selectedOption.value) {
			setMySettings({
				...mySettings,
				inactivityTimerHours: +selectedOption.value,
			});
			updateSettings({
				inactivityTimerHours: +selectedOption.value,
			});
		}
	};

	const handleChangeDelay = (selectedOption: any) => {
		setSendDelaySeconds(selectedOption);
		if (selectedOption.value) {
			setMySettings({
				...mySettings,
				sendDelaySeconds: +selectedOption.value,
			});
			updateSettings({
				sendDelaySeconds: +selectedOption.value,
			});
		}
	};

	const getSettingsWithService = async () => {
		setVisiblePreloader(true);
		await getSettings();
		setVisiblePreloader(false);
	};

	const translate = () => {
		setInactivityTimerHours({
			value: `${mySettings.inactivityTimerHours}`,
			label: `${mySettings.inactivityTimerHours} ${textEnding(
				+mySettings.inactivityTimerHours,
				translations.hoursEnding
			)}`,
		});
		setSendDelaySeconds({
			value: `${mySettings.sendDelaySeconds}`,
			label: `${mySettings.sendDelaySeconds} ${textEnding(
				+mySettings.sendDelaySeconds,
				translations.secEnding
			)}`,
		});
	};

	useEffect(() => {
		translate();
	}, [language]);

	useEffect(() => {
		if (mySettings) {
			translate();
		}
	}, [mySettings]);

	useEffect(() => {
		settings && setEnableAutoposting(settings.enableAutoposting || false);
	}, [settings]);

	useEffect(() => {
		if (autopostingSettings) {
			setMySettings(autopostingSettings);
		}
	}, [autopostingSettings]);

	useEffect(() => {
		getSettingsWithService();
	}, []);

	const translatedTime = (timeArray: {value: string}[], type = 'hours') => {
		return timeArray.map((item: {value: string}) => {
			return {
				...item,
				label: `${item.value}  ${textEnding(
					Number(item.value),
					type === 'hours' ? translations.hoursEnding : translations.secEnding
				)}`,
			};
		});
	};

	return (
		<>
			{visiblePreloader && (
				<div className='preloader'>
					<Spinner animation='border' variant={appTheme === AppTheme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			<div className='block w-50'>
				<div className='mb-5'>
					<p className='h5'>{translations.autoposting.title}</p>
					<p className='text-secondary mb-2'>{translations.autoposting.descr}</p>
					<p className='text-secondary mb-4'>{translations.autoposting.warning}</p>
					<div className='mb-3'>
						<Form.Check
							onChange={onChangeToggleHandler}
							type='switch'
							name='enableAutoposting'
							id='enableAutoposting'
							label={translations.autoposting.toggleLabel}
							checked={enableAutoposting}
						/>
					</div>
				</div>
				<div className='mb-5'>
					<p className='h5'>{translations.autoposting.settings.title}</p>
					<p className='text-secondary mb-4'>{translations.autoposting.settings.descr}</p>
					<Form.Group className='mb-4' controlId='autoposting-radio1'>
						<div className='mb-2'>
							<Form.Check
								inline
								className='checkbox-black'
								name='sendTo'
								type='radio'
								label={translations.autoposting.settings.radio.empty}
								id='sendTo1'
								value={RoomType.EMPTY}
								onChange={onChangeRadioHandler}
								checked={mySettings.sendTo === RoomType.EMPTY}
							/>
						</div>
						<div>
							<Form.Check
								inline
								className='checkbox-black'
								name='sendTo'
								type='radio'
								label={translations.autoposting.settings.radio.notActive}
								id='sendTo2'
								value={RoomType.INACTIVE}
								onChange={onChangeRadioHandler}
								checked={mySettings.sendTo === RoomType.INACTIVE}
							/>
						</div>
					</Form.Group>
					<p className='text-muted mb-2'>{translations.autoposting.settings.selectTitle}</p>
					<div className='w-50'>
						<SelectControl
							handleChange={handleChangeInactivity}
							options={translatedTime(notActiveOptions.current)}
							value={inactivityTimerHours}
							theme='light'
							isSearchable
							isCreatable
							disabled={mySettings.sendTo !== RoomType.INACTIVE}
						/>
					</div>
				</div>
				<div className='mb-5'>
					<p className='h5'>{translations.autoposting.delay.title}</p>
					<p className='text-secondary mb-4'>{translations.autoposting.delay.descr}</p>
					<div className='w-50'>
						<SelectControl
							handleChange={handleChangeDelay}
							options={translatedTime(delayOptions.current, 'sec')}
							value={sendDelaySeconds}
							theme='light'
							isSearchable
							isCreatable
						/>
					</div>
				</div>
				<div className='mb-5'>
					<p className='h5'>{translations.autoposting.messagesList.title}</p>
					<p className='text-secondary mb-4'>{translations.autoposting.messagesList.descr}</p>
					<Form.Group className='mb-4' controlId='autoposting-radio2'>
						<div className='mb-2'>
							<Form.Check
								inline
								className='checkbox-black'
								name='useDefaultMessagesList'
								type='radio'
								label={translations.autoposting.defaultList}
								id='useDefaultMessagesList1'
								onChange={onChangeRadioHandler}
								checked={mySettings?.useDefaultMessagesList}
								value='default'
							/>
						</div>
						<div>
							<Form.Check
								inline
								className='checkbox-black'
								name='useDefaultMessagesList'
								type='radio'
								label={translations.autoposting.customList}
								id='useDefaultMessagesList2'
								onChange={onChangeRadioHandler}
								checked={!mySettings?.useDefaultMessagesList}
								disabled={!isCustomMessagesList}
							/>
						</div>
					</Form.Group>
				</div>
			</div>
		</>
	);
};

export default observer(Settings);

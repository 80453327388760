import {useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import roomServices from 'store/roomServices';
import RoomService from 'services/api/RoomService';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {setPinnedMessages} = useLocalObservable(() => roomServices);

	const pinMessage = async (externalRoomId: string, messageId: number | null) => {
		await RoomService.pinMessage(accessToken, externalRoomId, messageId);
	};

	const unpinMessage = async (externalRoomId: string, messageId: number | null) => {
		await RoomService.unpinMessage(accessToken, externalRoomId, messageId);
	};

	const getPinnedMessage = async (externalRoomId: string) => {
		const response = await RoomService.getPinnedMessage(accessToken, externalRoomId);

		if (response.status === ResponseStatus.SUCCESS) {
			setPinnedMessages(response.data);
		}
	};

	const createRoom = async (roomData: any, pic?: Blob | null) => {
		const response = await RoomService.createRoom(roomData, accessToken, pic);

		return response;
	};

	const updateRoomData = async (roomData: any, roomId: string, pic?: Blob | null) => {
		const response = await RoomService.patchRoom(roomData, roomId, accessToken, pic);

		return response;
	};

	const uploadStreamVideo = async (externalRoomId: string, file: File) => {
		const response = await RoomService.uploadStreamVideo(accessToken, externalRoomId, file);

		return response;
	};

	const getStreamKey = async (externalRoomId: string, userId: number) => {
		const response = await RoomService.getStreamKey(accessToken, externalRoomId, userId);

		return response;
	};

	return {
		pinMessage,
		unpinMessage,
		getPinnedMessage,
		createRoom,
		updateRoomData,
		uploadStreamVideo,
		getStreamKey,
	};
};

import {action, makeObservable, observable} from 'mobx';
import {PollType} from 'models/enums/Poll.enum';
import {Poll, PollCreated, PollCreatedOption, PollOption} from 'models/poll';

class PollService {
	@observable
	public pollPreview: Poll | PollCreated | null = null;

	@observable
	public pollWithPics = {value: false, isChanged: false};

	@observable
	public polls: Poll[] = [];

	@observable
	public currentPoll: PollCreated | null = {
		type: PollType.POLL,
		isMultiple: false,
	};

	@observable
	public currentPollId: number | null = null;

	@observable
	public isNotValidFields = true;

	@observable
	public isFieldsEmpty = true;

	@observable
	public isQuestionEmpty = true;

	@observable
	public isRoomsEmpty = true;

	@observable
	public isStartTimeEmpty = false;

	@observable
	public isEndTimeEmpty = false;

	@action
	public setIsNotValidFields = (value: boolean) => {
		this.isNotValidFields = value;
	};

	@action
	public setIsFieldsEmpty = (value: boolean) => {
		this.isFieldsEmpty = value;
	};

	@action
	public setIsQuestionEmpty = (value: boolean) => {
		this.isQuestionEmpty = value;
	};

	@action
	public setIsRoomsEmpty = (value: boolean) => {
		this.isRoomsEmpty = value;
	};

	@action
	public setIsStartTimeEmpty = (value: boolean) => {
		this.isStartTimeEmpty = value;
	};

	@action
	public setIsEndTimeEmpty = (value: boolean) => {
		this.isEndTimeEmpty = value;
	};

	@action
	public setPollPreview = (poll: Poll | PollCreated | null) => {
		this.pollPreview = poll;
	};

	@action
	public setCurrentPoll = (poll: PollCreated | null) => {
		if (poll === null) {
			this.currentPoll = {
				type: PollType.POLL,
				isMultiple: false,
			};
			return;
		}
		this.currentPoll = poll;
	};

	@action
	public setCurrentPollId = (value: number | null) => {
		this.currentPollId = value;
	};

	@action
	public resetCurrentPoll = (poll: Poll) => {
		if (poll) {
			const newOptionsArray = poll.options.map((el: PollOption) => {
				const {votesLength, ...elRest} = el;
				return elRest;
			});

			this.currentPoll = {
				text: poll.text,
				isMultiple: poll.isMultiple,
				type: poll.type,
				options: newOptionsArray,
				status: poll.status,
				roomPolls: poll.roomPolls,
				autoSendResults: poll.autoSendResults,
				startTime: poll.startTime,
				endTime: poll.endTime,
			};
			return;
		}
		this.currentPoll = null;
	};

	@action
	public updateCurrentPoll = (currentPollData: PollCreated) => {
		this.currentPoll = {...this.currentPoll, ...currentPollData};
	};

	@action
	public setPollWithPics = (data: boolean, isChanged = false) => {
		this.pollWithPics = {value: data, isChanged};
	};

	@action
	public setPolls = (data: Poll[]) => {
		this.polls = data;
	};

	@action
	public resetIsRightPollCreatedOptions = () => {
		if (this.currentPoll?.options) {
			const newOptionsArray = this.currentPoll.options.map((el: PollCreatedOption) => {
				return {...el, isRight: false};
			});
			this.currentPoll = {...this.currentPoll, options: newOptionsArray};
		}
	};

	constructor() {
		makeObservable(this);
	}
}

export default new PollService();

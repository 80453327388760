import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Table,
	Spinner,
	Form,
	Button,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import userServices from 'store/userServices';

import BanService from 'services/api/BanService';
import AdminProxyService from 'services/api/AdminProxyService';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Ban} from 'models/ban';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

import useL10n from 'l10n/useL10n';
import {LIMIT} from 'constants/constants';

import PageTitle from 'components/pageTitle/PageTitle';
import BannedItem from './components/banned/BannedItem';

const Banned: FunctionComponent = function Banned() {
	const {accessToken, setAdmins} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [banned, setBanned] = useState<Ban[]>([]);
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [offset, setOffset] = useState(0);
	const [sortBy, setSortBy] = useState('createdAt_desc');

	const translations = useL10n();
	const getBans = async (limit?: number, currentOffset?: number, order?: string) => {
		setVisiblePreloader(true);
		const response = await BanService.getBanned(
			accessToken,
			limit || LIMIT,
			currentOffset || 0,
			order || 'createdAt_desc'
		);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) setBanned(response.data.bans);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getBans(LIMIT, offset - LIMIT, sortBy);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getBans(LIMIT, offset + LIMIT, sortBy);
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === '') return;
		setSortBy(value);
		setOffset(0);

		getBans(LIMIT, 0, value);
	};

	const getAdmins = async () => {
		setVisiblePreloader(true);
		const response = await AdminProxyService.getAdmins(accessToken);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) setAdmins(response.data);
	};

	const renderBan = useCallback(
		(elem: Ban) => {
			return <BannedItem ban={elem} getBans={getBans} key={elem.id} />;
		},
		[banned.length]
	);

	useEffect(() => {
		if (accessToken) {
			getBans();
			getAdmins();
		}
	}, [accessToken]);

	return (
		<Container fluid className='pt-4 text-lg'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.bans.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.bans.title}
						text={translations.sidebar.bans.descr}
					/>

					<div className='d-flex'>
						<div className='d-flex w-100 justify-content-end mb-3'>
							<Button
								variant='dark'
								className='d-flex'
								onClick={onPrevPageBtnClickHandler}
								disabled={(!banned.length && offset === 0) || offset === 0}>
								<BsArrowLeftCircleFill />
							</Button>
							&nbsp;
							<Button
								variant='dark'
								className='d-flex'
								onClick={onNextPageBtnClickHandler}
								disabled={!banned.length || banned.length < LIMIT}>
								<BsArrowRightCircleFill />
							</Button>
						</div>
					</div>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{banned.length > 0 ? (
						<Table
							responsive
							striped={appTheme === Theme.LIGHT}
							bordered
							hover
							variant={appTheme?.toLowerCase()}>
							<thead>
								<tr>
									<th className='col-2 align-middle'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.user}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy}>
												<option value=''>{translations.sorting.id} </option>
												<option value='user__id_desc'>{translations.sorting.id} &darr;</option>
												<option value='user__id_asc'>{translations.sorting.id} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='text-center align-middle'>
										<b>{translations.table.banIn}</b>
									</th>
									<th className='col-1 text-center align-middle'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.timeBan}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy}>
												<option value=''>{translations.table.timeBan}</option>
												<option value='createdAt_desc'>{translations.table.timeBan} &darr;</option>
												<option value='createdAt_asc'>{translations.table.timeBan} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='text-center'>
										<b>{translations.table.banDuration}</b>
									</th>
									<th className='col-2 text-center align-middle'>
										<OverlayTrigger
											placement='bottom'
											delay={{show: 50, hide: 50}}
											overlay={
												<Tooltip id='button-tooltip-2'>
													<b>{translations.table.reason}</b>
												</Tooltip>
											}>
											<Form.Select onChange={onSelect} value={sortBy}>
												<option value=''>{translations.table.reason}</option>
												<option value='reason_desc'>{translations.table.reason} &darr;</option>
												<option value='reason_asc'>{translations.table.reason} &uarr;</option>
											</Form.Select>
										</OverlayTrigger>
									</th>
									<th className='col-2 text-center align-middle'>
										<b>{translations.table.comment}</b>
									</th>
									<th className='text-center align-middle'>
										<b>{translations.table.creator}</b>
									</th>
									<th className='text-center'>
										<b> </b>
									</th>
								</tr>
							</thead>
							{!visiblePreloader && <tbody>{banned.map(renderBan)}</tbody>}
						</Table>
					) : (
						!visiblePreloader && <p>{translations.empty.bannedEmpty}</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Banned);

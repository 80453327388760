/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect} from 'react';
import {NavLink, useHistory} from 'react-router-dom';

import Moment from 'react-moment';

import {Badge, Alert, Image, OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import {BsFillPencilFill, BsXOctagonFill, BsTrashFill, BsEyeFill} from 'react-icons/bs';

import {PollStatus, PollType} from 'models/enums/Poll.enum';
import {Poll} from 'models/poll';

import useL10n from 'l10n/useL10n';
import usePolls from 'hooks/usePolls';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import modalServices from 'store/modalServices';
import pollServices from 'store/pollServices';
import './polls-table.scss';
import appService from 'store/appService';

interface IPollsItem {
	item: Poll;
	index: number;
}

const PollsItem: FunctionComponent<IPollsItem> = function PollsItem({item, index}) {
	const {poll, alerts} = useL10n();
	const {endPoll, deletePoll} = usePolls();
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {togglePollModalVisible, togglePollRoomModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {appTheme} = useLocalObservable(() => appService);

	const {setCurrentPoll, setPollPreview} = useLocalObservable(() => pollServices);

	const history = useHistory();
	const translations = useL10n();

	const totalVotes = item.options.map(el => el.votesLength || 0).reduce((sum, a) => sum + a, 0);

	const onEditBtnClickHandler = async (e: any) => {
		e.stopPropagation();
		history.push(`/polls/edit/${item.id}`);
	};

	const onEndBtnClickHandler = async (e: any) => {
		e.stopPropagation();
		let buttons = [
			{
				text: alerts.btns.end,
				type: AlertBtnType.DANGER,
				onClick: () => {
					endPoll(item.id);
					hideAlert();
				},
			},
			{
				text: alerts.btns.cancel,
				type: AlertBtnType.NORMAL,
				onClick: () => {
					hideAlert();
				},
			},
		];

		if (item?.type === PollType.POLL) {
			buttons = [
				{
					text: alerts.btns.endAndShare,
					type: AlertBtnType.DANGER,
					onClick: () => {
						endPoll(item.id, true);
						hideAlert();
					},
				},
				...buttons,
			];
		}

		showAlert({
			title: alerts.pollEnd,
			text: '',
			buttons,
		});
	};

	const onDeleteBtnClickHandler = async (e: any) => {
		e.stopPropagation();
		showAlert({
			title: alerts.pollDelete,
			text: '',
			buttons: [
				{
					text: alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: alerts.btns.delete,
					type: AlertBtnType.DANGER,
					onClick: () => {
						deletePoll(item.id);
						hideAlert();
					},
				},
			],
		});
	};

	const onShowBtnClickHandler = (e: any) => {
		e.stopPropagation();
		setPollPreview(item);
		togglePollModalVisible(true);
	};

	const openPollRoomsModal = (e: any) => {
		e.stopPropagation();
		togglePollRoomModalVisible(true);
		setCurrentPoll(item);
	};

	const onRowClickHandler = () => {
		history.push(`/polls/result/${item.id}`);
	};

	return (
		<tr key={index} onClick={onRowClickHandler}>
			<td>
				<div>
					<p className='h5 mb-2'>{item.text}</p>
					<small className='text-secondary'>{item.type}</small>
				</div>
			</td>
			<td>
				<div>
					{item.options.map((elem: any, i: number) => {
						return (
							<div key={elem.id} className='polls-table__option'>
								<div className='polls-table__numbers'>
									<Badge pill bg={appTheme.toLowerCase()} className='polls-table__numbers-percent'>
										{elem.votesLength && totalVotes > 0
											? `${Math.round((elem.votesLength / totalVotes) * 100)}%`
											: '0%'}
									</Badge>
									<Badge pill bg={appTheme.toLowerCase()}>
										{elem.votesLength}
									</Badge>
								</div>
								<div className='text-truncate mx-2'>
									<small> {elem.text}</small>
								</div>
							</div>
						);
					})}
				</div>
			</td>
			<td>
				<b className='text-truncate'>{item.votesLength}</b>
			</td>
			<td className='text-secondary'>
				{item.status === PollStatus.ACTIVE && (
					<Badge bg='success' pill>
						Active
					</Badge>
				)}
				{item.status === PollStatus.PLANNED && (
					<Badge bg='info' pill>
						Planned
					</Badge>
				)}
				{item.status === PollStatus.ENDED && (
					<Badge bg='danger' pill>
						Finished
					</Badge>
				)}
			</td>
			<td>
				{item.roomPolls.length > 0 && (
					<div className='text-truncate'>
						{item.roomPolls.length === 1 ? (
							<div>
								<p>{item.roomPolls[0].room.name}</p>
								<NavLink to={`/room/${encodeURIComponent(item.roomPolls[0].room.externalRoomId)}`}>
									<Badge bg={appTheme.toLowerCase()} pill>
										{item.roomPolls[0].room.externalRoomId}
									</Badge>
								</NavLink>
							</div>
						) : (
							<div className='text-truncate'>
								<div>
									<p>{item.roomPolls[0].room.name}</p>
									<NavLink
										to={`/room/${encodeURIComponent(item.roomPolls[0].room.externalRoomId)}`}>
										<Badge bg={appTheme.toLowerCase()} pill>
											{item.roomPolls[0].room.externalRoomId}
										</Badge>{' '}
										...
									</NavLink>
								</div>
								<div className='m-2'>
									<Button size='sm' variant='light' onClick={openPollRoomsModal}>
										{translations.polls.roomList}
									</Button>
								</div>
							</div>
						)}
					</div>
				)}
			</td>
			<td className='text-secondary'>
				<div>
					{item.startTime ? (
						<Moment format='DD.MM.YY HH:mm'>{item.startTime}</Moment>
					) : (
						<Moment format='DD.MM.YY HH:mm'>{item.createdAt}</Moment>
					)}
				</div>
				{item.creatorTalker ? (
					<div className='user-info'>
						<div
							className={`user-info__photo user-info__photo--sm background-color-${
								item.creatorTalker.user?.color || 0
							}`}>
							<Image src={item.creatorTalker.user?.pic} roundedCircle fluid />
						</div>
						<div className='text-truncate'>
							<Badge bg={appTheme.toLowerCase()} pill>
								id{item.creatorTalker.user?.id}
							</Badge>
							<div className='text-truncate'>{item.creatorTalker.user?.name}</div>
						</div>
					</div>
				) : (
					<div>
						<Badge bg={appTheme.toLowerCase()} pill>
							admin
						</Badge>
					</div>
				)}
			</td>
			<td className='text-center text-secondary'>
				<div>
					{item.endTime ? (
						<Moment format='DD.MM.YY HH:mm'>{item.endTime}</Moment>
					) : (
						item.finishedAt && <Moment format='DD.MM.YY HH:mm'>{item.finishedAt}</Moment>
					)}
				</div>
				{item.finisherTalker ? (
					<div className='user-info'>
						<div
							className={`user-info__photo user-info__photo--sm background-color-${
								item.finisherTalker.user?.color || 0
							}`}>
							<Image src={item.finisherTalker.user?.pic} roundedCircle fluid />
						</div>
						<div className='text-truncate'>
							<Badge bg={appTheme.toLowerCase()} pill>
								id{item.finisherTalker.user?.id}
							</Badge>
							<div className='text-truncate'>{item.finisherTalker.user?.name}</div>
						</div>
					</div>
				) : (
					(item.endTime || item.finishedAt) && (
						<div>
							<Badge bg={appTheme.toLowerCase()} pill>
								admin
							</Badge>
						</div>
					)
				)}
			</td>
			<td className='text-secondary'>
				<div className='d-flex align-items-center justify-content-end flex-wrap'>
					{item.status !== PollStatus.ENDED && (
						<OverlayTrigger
							placement='bottom'
							delay={{show: 50, hide: 50}}
							overlay={
								<Tooltip id='button-tooltip-2'>
									<b>{poll.toggleSubmenu.edit}</b>
								</Tooltip>
							}>
							<Button
								size='sm'
								variant='light'
								className='d-flex p-1'
								onClick={onEditBtnClickHandler}>
								<BsFillPencilFill className='text-secondary' />
							</Button>
						</OverlayTrigger>
					)}
					&nbsp;
					<OverlayTrigger
						placement='bottom'
						delay={{show: 50, hide: 50}}
						overlay={
							<Tooltip id='button-tooltip-2'>
								<b>{poll.toggleSubmenu.show}</b>
							</Tooltip>
						}>
						<Button
							size='sm'
							variant='light'
							className='d-flex p-1'
							onClick={onShowBtnClickHandler}>
							<BsEyeFill />
						</Button>
					</OverlayTrigger>
					&nbsp;
					{item.status === PollStatus.ACTIVE && (
						<OverlayTrigger
							placement='bottom'
							delay={{show: 50, hide: 50}}
							overlay={
								<Tooltip id='button-tooltip-2'>
									<b>{poll.toggleSubmenu.end}</b>
								</Tooltip>
							}>
							<Button
								size='sm'
								variant='light'
								className='d-flex p-1'
								onClick={onEndBtnClickHandler}>
								<BsXOctagonFill />
							</Button>
						</OverlayTrigger>
					)}
					&nbsp;
					<OverlayTrigger
						placement='bottom'
						delay={{show: 50, hide: 50}}
						overlay={
							<Tooltip id='button-tooltip-2'>
								<b>{poll.toggleSubmenu.delete}</b>
							</Tooltip>
						}>
						<Button
							variant='danger'
							size='sm'
							className='d-flex p-1'
							onClick={onDeleteBtnClickHandler}>
							<BsTrashFill className='text-white' />
						</Button>
					</OverlayTrigger>
				</div>
			</td>
		</tr>
	);
};

export default observer(PollsItem);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import Moment from 'react-moment';

import {Image, Alert, Tooltip, OverlayTrigger, Badge} from 'react-bootstrap';

import roomServices from 'store/roomServices';
import {Report} from 'models/reports';

import useL10n from 'l10n/useL10n';
import useReportReason from 'hooks/useReportReason';

import ReportsActions from './ReportsActions';

interface IReportsItem {
	report: Report;
	getReports: () => void;
}

const ReportsItem: FunctionComponent<IReportsItem> = function ReportsItem({report, getReports}) {
	const history = useHistory();
	const translations = useL10n();

	const {setCurrentMessageId} = useLocalObservable(() => roomServices);

	const {getReason} = useReportReason();

	const goToCurrentMessage = () => {
		setCurrentMessageId(report.message.id);
		history.push(`/room/${encodeURIComponent(report.room.externalRoomId)}`);
	};

	const textEnding = (number: number, txt: string[]) => {
		const cases = [2, 0, 1, 1, 1, 2];
		return txt[
			number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
		];
	};

	return (
		<tr>
			<td className='align-middle'>
				<div className='user-info'>
					<div className='user-info__photo user-info__photo--sm background-color-8'>
						{report.initiators && <Image src={report.initiators[0].pic} roundedCircle fluid />}
						{report.initiator && <Image src={report.initiator.pic} roundedCircle fluid />}
					</div>
					<div className='text-truncate'>
						<OverlayTrigger
							placement='right'
							delay={{show: 50, hide: 50}}
							overlay={
								<Tooltip id='button-tooltip-2'>
									<div>
										{report.initiators && (
											<small className='d-inline'>{report.initiators[0].name}</small>
										)}
										{report.initiator && (
											<small className='d-inline'>{report.initiator.name}</small>
										)}
									</div>
								</Tooltip>
							}>
							<div className='text-truncate'>
								{report.initiators && (
									<small className='d-inline'>{report.initiators[0].name}</small>
								)}
								{report.initiator && <small className='d-inline'>{report.initiator.name}</small>}
							</div>
						</OverlayTrigger>
					</div>
				</div>
				{report.size > 1 && (
					<p className='m-2'>
						{translations.and} <b>{report.size - 1}</b>{' '}
						{textEnding(report.size - 1, translations.textEndingUsers)}
					</p>
				)}
			</td>
			<td className='align-middle'>
				<div className='user-info'>
					<div
						className={`user-info__photo user-info__photo--sm background-color-${
							report.target.user?.color || 0
						}`}>
						<Image src={report.target.pic} roundedCircle fluid />
					</div>
					<div className='text-truncate'>
						<OverlayTrigger
							placement='right'
							delay={{show: 50, hide: 50}}
							overlay={
								<Tooltip id='button-tooltip-2'>
									<small className='d-inline'>{report.target.name}</small>
								</Tooltip>
							}>
							<div className='text-truncate'>
								<small className='d-inline'>{report.target.name}</small>
							</div>
						</OverlayTrigger>
					</div>
				</div>
			</td>
			<td className='text-center align-middle'>
				<small>
					{getReason(report.reason) ? (
						<Alert variant='warning'>{getReason(report.reason)?.text}</Alert>
					) : (
						<Alert variant='warning'>{report.reason}</Alert>
					)}
				</small>
			</td>
			{report.message && (
				<td className='align-middle'>
					<Alert
						key='warning'
						variant='secondary'
						className='cursor-pointer'
						onClick={goToCurrentMessage}>
						{report.message.isAutoposted === 1 && (
							<Badge pill bg='light' className='mx-2'>
								{translations.autoposting.badge}
							</Badge>
						)}
						<small className='text-link text-break'>{report.message.text || ''}</small>
					</Alert>
				</td>
			)}
			<td className='text-center align-middle text-truncate'>
				<NavLink to={`/room/${encodeURIComponent(report.room.externalRoomId)}`}>
					{report.room.externalRoomId}
				</NavLink>
			</td>
			<td className='text-center align-middle text-secondary'>
				{report.createdAt && (
					<small>
						<Moment format='DD.MM.YY HH:mm'>{report.createdAt}</Moment>
					</small>
				)}
			</td>
			<td className='text-center align-middle'>
				<ReportsActions report={report} getReports={getReports} />
			</td>
		</tr>
	);
};

export default observer(ReportsItem);

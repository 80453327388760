import {observer, useLocalObservable} from 'mobx-react-lite';
import {HighlightGradient, Highlights} from 'models/enums/Highlights.enum';
import {Project} from 'models/enums/Project.enum';

import {FunctionComponent, useEffect} from 'react';

import highlightsServices from 'store/highlightsServices';
import appService from 'store/appService';

import {highlightTemplates} from 'data/highlightTemplates';
import useL10n from 'l10n/useL10n';
import classNames from 'classnames';
import {getContrastColor} from 'utils/helpers';
import settingsServices from 'store/settingsServices';
import offerService from 'store/offerService';
import {Highlight} from 'models/highlights';

interface ITemplate {
	ad: Highlight;
	template: number;
	isSettings?: boolean;
	isLink?: boolean;
	templateDataJSON?: any;
	isOffer?: boolean;
}

const Template: FunctionComponent<ITemplate> = function Template({
	ad,
	template,
	isSettings,
	isLink,
	templateDataJSON,
	isOffer,
}) {
	const {currentHighlight, setCurrentHighlight, highlightPics, setCurrentStep} = useLocalObservable(
		() => highlightsServices
	);
	const {currentOffer, setCurrentOffer} = useLocalObservable(() => offerService);

	const {projectId} = useLocalObservable(() => appService);
	const {brandingColors, customHighlightColors} = useLocalObservable(() => settingsServices);

	const project = projectId.split('-')[0];

	const currentElem = isOffer ? currentOffer : currentHighlight;

	const setCurrentElem = (value: any) => {
		if (isOffer) {
			setCurrentOffer(value);
			return;
		}
		setCurrentHighlight(value);
	};

	const templateData = templateDataJSON ? JSON.parse(templateDataJSON) : currentElem.templateData;

	const translations = useL10n();

	let templateStyle: any = null;
	let templateImage: string | null = null;
	let templateImageDefault: string | null = null;

	const pics = template < 3 || template === 11 ? highlightTemplates : highlightPics;

	const customGradientFrom = customHighlightColors?.gradient?.from;
	const customGradientTo = customHighlightColors?.gradient?.to;
	const customHighlightBg = customHighlightColors?.bg;
	const customHighlightBtn = customHighlightColors?.btn;

	const themeBg = brandingColors.bg;
	const themeBtn = brandingColors.btn;

	templateStyle = pics.find((el: any) => el.template === template);
	templateImage = templateStyle?.pic;
	templateImageDefault = templateStyle?.imageDefault;

	const getTextColor = () => {
		return !isSettings && !templateDataJSON
			? getContrastColor(customGradientFrom || customHighlightBg || themeBg || '#000')
			: `${templateData?.textColor}`;
	};

	const renderTitle = () => (
		<div className='highlight__title' style={{color: template === 10 ? getTextColor() : ''}}>
			{ad.title}
			{Highlights[template] === 'TEMPLATE_4' && (
				<span style={{backgroundColor: `${themeBtn}`}}> </span>
			)}
		</div>
	);

	const renderText = () => (
		<div className='highlight__text' style={{color: template === 10 ? getTextColor() : ''}}>
			{ad.text}
		</div>
	);

	const getLinkBgColor = () => {
		return !isSettings && !templateDataJSON
			? `${customHighlightBtn || themeBtn}`
			: `${templateData?.btnColor}`;
	};

	const getLinkTextColor = () => {
		// eslint-disable-next-line no-nested-ternary
		return isSettings && template === 10
			? `${templateData?.btnTextColor}`
			: template !== 10
			? getContrastColor(themeBtn || '#fff')
			: getContrastColor(customHighlightBtn || themeBtn || '#fff');
	};

	const renderLink = () => {
		return (
			((!isSettings && ad.linkText) || isLink) && (
				<a
					href={ad.link}
					target='_blank'
					rel='noreferrer'
					className='highlight__href'
					style={{
						backgroundColor: template !== 10 ? `${themeBtn}` : getLinkBgColor(),
						color: getLinkTextColor(),
					}}>
					{template !== 11 ? ad.linkText || translations.highlights.learnMore : ''}
				</a>
			)
		);
	};

	const getBgColor = () => {
		return templateData?.gradient
			? `linear-gradient(${templateData?.gradientDirection},${templateData?.gradientFrom},${templateData?.gradientTo})`
			: `${templateData?.bgColor}`;
	};

	const getTemplateBgColor = () => {
		return customGradientFrom
			? `linear-gradient(${HighlightGradient.TOP},${customGradientFrom},${customGradientTo})`
			: `${customHighlightBg || themeBg}`;
	};

	const highlightsClasses = classNames(
		`highlight highlight--${Highlights[template].toLowerCase()}  ${
			currentElem.template === template ? `highlight--current` : ''
		}`,
		{
			'highlight--ligaTheme': project === Project.LIGA,
			'highlight--matchtvTheme': project === Project.MATCHTV,
			'highlight--winkTheme': project === Project.WINK,
			'highlight--tinkoffTheme': project === Project.TINKOFF,
			'highlight--center': (isSettings || templateDataJSON) && templateData?.alignment === 'center',
		}
	);

	const getNextStep = () => {
		setCurrentStep(2);
		setCurrentElem({...currentElem, template});
	};

	const renderHighlightContainer = () => {
		switch (Highlights[template]) {
			case 'CUSTOM_1':
			case 'CUSTOM_2':
			case 'CUSTOM_3':
				return (
					<div className='highlight__container'>
						{isSettings && (
							<div
								className={`highlight__img highlight__img ${
									!ad.pic ? `highlight__img--default` : ''
								}`}
								style={{
									backgroundImage: `url(${ad.pic ? ad.pic : templateImageDefault})`,
								}}
							/>
						)}

						{!isSettings && !ad.pic && (
							<div
								className='highlight__img'
								style={{
									backgroundImage: `url(${templateImage})`,
								}}
							/>
						)}

						{!isSettings && ad.pic && (
							<div
								className='highlight__img'
								style={{
									backgroundImage: `url(${ad.pic})`,
								}}
							/>
						)}

						<div className='highlight__body'>
							{ad.title && renderTitle()}
							{ad.text && renderText()}
							{isSettings && ad.linkText && renderLink()}
							{!isSettings && ad.link && renderLink()}
						</div>
					</div>
				);
			case 'TEMPLATE_1':
			case 'TEMPLATE_3':
			case 'TEMPLATE_4':
				return (
					<div
						className='highlight__container'
						style={
							Highlights[template] === 'TEMPLATE_4' && templateImage
								? {backgroundImage: `url(${templateImage})`}
								: undefined
						}>
						<div
							className='highlight__head'
							style={
								Highlights[template] !== 'TEMPLATE_4' && templateImage
									? {backgroundImage: `url(${templateImage})`}
									: undefined
							}>
							{ad.title && renderTitle()}
						</div>
						<div className='highlight__body'>
							{ad.text && renderText()}
							{isSettings && ad.linkText && renderLink()}
							{!isSettings && ad.link && renderLink()}
						</div>
					</div>
				);
			case 'TEMPLATE_8':
				return (
					<div
						className='highlight__container'
						style={{
							background: !isSettings && !templateDataJSON ? getTemplateBgColor() : getBgColor(),
						}}>
						{ad.title && renderTitle()}
						{ad.text && renderText()}
						{isSettings && ad.linkText && renderLink()}
						{!isSettings && ad.link && renderLink()}
					</div>
				);
			case 'CUSTOM_4':
				return (
					<div className='highlight__container'>
						{isSettings && templateImageDefault && (
							<img
								className={`highlight__img highlight__img ${
									!ad.pic ? `highlight__img--default` : ''
								}`}
								src={ad.pic ? ad.pic : templateImageDefault}
								alt=''
							/>
						)}

						{!isSettings && !ad.pic && templateImage && (
							<img className='highlight__img' src={templateImage} alt='' />
						)}

						{!isSettings && ad.pic && <img className='highlight__img' src={ad.pic} alt='' />}
						{!isSettings && ad.link && renderLink()}
					</div>
				);
			default:
				return (
					<div
						className='highlight__container'
						style={templateImage ? {backgroundImage: `url(${templateImage})`} : undefined}>
						{ad.title && renderTitle()}
						{ad.text && renderText()}
						{isSettings && ad.linkText && renderLink()}
						{!isSettings && ad.link && renderLink()}
					</div>
				);
		}
	};
	return (
		<div className={highlightsClasses}>
			{renderHighlightContainer()}
			{!ad.status && !isOffer && (
				<button type='button' className='highlight__click' onClick={getNextStep}>
					{' '}
				</button>
			)}
		</div>
	);
};
export default observer(Template);

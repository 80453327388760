import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';

import {Button, Form, Image} from 'react-bootstrap';
import {BsTrashFill} from 'react-icons/bs';

import {useDrag, useDrop} from 'react-dnd';
import type {Identifier, XYCoord} from 'dnd-core';

import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

import useL10n from 'l10n/useL10n';
import alertServices from 'store/alertServices';
import userServices from 'store/userServices';

import {AlertBtnType} from 'models/enums/Alert.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import LanguageTag from 'models/enums/LanguageTag.enum';
import appService from 'store/appService';
import useSticker from 'hooks/useSticker';
import settingsServices from 'store/settingsServices';
import {useHistory} from 'react-router-dom';
import SettingsService from 'services/api/SettingsService';

interface IStickerProps {
	index: number;
	elem: Sticker;
	moveSticker: (dragIndex: number, hoverIndex: number) => void;
	setVisiblePreloader: (value: boolean) => void;
	getStickers: () => void;
	stickers: any[];
	setStickers: (array: any[]) => void;
}

interface IDragItem {
	index: number;
	id: string;
	type: string;
}

const Sticker: FunctionComponent<IStickerProps> = function Sticker({
	index,
	elem,
	moveSticker,
	setVisiblePreloader,
	getStickers,
	stickers,
	setStickers,
}) {
	const translations = useL10n();

	const inputRef = useRef<HTMLInputElement>(null);

	const {language, appTheme} = useLocalObservable(() => appService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {emojiPicker, setEmojiPicker} = useLocalObservable(() => settingsServices);

	const {id} = elem;

	const [inputValue, setInputValue] = useState('');
	const [inputVisibleValue, setInputVisibleValue] = useState('');

	const {updateSticker, deleteStickerPack} = useSticker();
	const history = useHistory();

	const ref = useRef<HTMLDivElement>(null);

	const updateStickerPosition = async (dragId: number, hoverIndex: number) => {
		setVisiblePreloader(true);
		const stickerData: Sticker = {
			id: dragId,
			position: hoverIndex,
		};
		const response = await SettingsService.updateSticker(accessToken, stickerData);

		if (response.status === ResponseStatus.SUCCESS) {
			getStickers();
			setVisiblePreloader(false);
		}
	};

	const [{handlerId}, drop] = useDrop<IDragItem, void, {handlerId: Identifier | null}>({
		accept: 'CARD',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},

		hover(item: IDragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			moveSticker(dragIndex, hoverIndex);
			// eslint-disable-next-line no-param-reassign
			item.index = hoverIndex;
		},
	});

	const [{isDragging}, drag] = useDrag({
		type: 'CARD',
		item: () => {
			return {id, index};
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
			opacity: monitor.isDragging() ? 0.5 : 1,
		}),
		end: (item, monitor) => {
			const didDrop = monitor.didDrop();
			if (didDrop && item.id) updateStickerPosition(item.id, item.index + 1);
		},
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	const deletePicFile = () => {
		setStickers([...stickers.filter((el, elIndex) => elIndex !== index)]);
	};

	const deleteSticker = async () => {
		if (id) {
			let stickerPackId = null;
			let isEmptyStickerPack = false;
			if (stickers.length === 1) {
				stickerPackId = elem.stickerPackId;
				isEmptyStickerPack = true;
			}
			const response = await SettingsService.deleteSticker(accessToken, id);
			if (response.status === ResponseStatus.SUCCESS) {
				if (stickerPackId) await deleteStickerPack(stickerPackId);
				addToast({
					title: translations.toasts.success,
					text: translations.stickers.deleted,
					variant: 'success',
				});
				if (!isEmptyStickerPack) {
					getStickers();
					return;
				}
				history.push('/appearance');
			}
		}
	};

	const openAlert = async () => {
		showAlert({
			title: translations.btns.remove,
			text: translations.stickers.delete,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: async () => {
						hideAlert();
						await deleteSticker();
					},
				},
			],
		});
	};

	const handleKeyDown = (e: any) => {
		const isEmoji = e.key.match(/^[\p{Emoji}]$/u);
		const isDeleteOrBackspace = e.key === 'Delete' || e.key === 'Backspace';
		const isDigit = /[0-9]/.test(e.key);

		if (isDigit || (!isEmoji && !isDeleteOrBackspace)) {
			e.preventDefault();
		}
	};

	const changeStickerEmoji = (value: string) => {
		const newItems = [...stickers];
		newItems[index] = {...stickers[index], emojis: value || []};
		setStickers(newItems);
	};

	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const {value} = e.target;
		setInputValue(value);
		setInputVisibleValue(value);
		changeStickerEmoji(value);
	};

	const onInputClickHandler = () => {
		setEmojiPicker(elem.id || index);
	};

	const onBlurHandler = async () => {
		if (elem.id) await updateSticker({id: elem.id, emojis: inputValue || []});
	};

	const onEmojiSelectHandler = (emoji: any) => {
		const {unified} = emoji;

		const newItems = [...stickers];
		let value = '';
		if (inputRef.current && inputRef.current.selectionStart !== inputRef.current.value.length) {
			const {selectionStart} = inputRef.current;
			if (selectionStart) {
				value = `${inputRef.current.value.slice(0, selectionStart)}${
					emoji.native
				}${inputRef.current.value.slice(selectionStart, inputRef.current.value.length)}`;
			}
			setInputVisibleValue(value);
			inputRef.current.setSelectionRange(
				selectionStart + emoji.native.length,
				selectionStart + emoji.native.length
			);
		} else {
			value = `${inputRef.current?.value}${emoji.native}`;
			setInputVisibleValue(value);
			inputRef.current?.setSelectionRange(
				inputRef.current.value.length,
				inputRef.current.value.length
			);
		}
		const emojis = newItems[index].emojis?.length ? newItems[index].emojis : [];
		emojis.push(unified);

		newItems[index] = {
			...stickers[index],
			emojis,
		};
		setInputValue(emojis);
		setStickers(newItems);

		setEmojiPicker(null);
		inputRef.current?.blur();
		inputRef.current?.focus();
	};

	const getEmoji = (unicodeHex: string) => String.fromCodePoint(parseInt(unicodeHex, 16));

	useEffect(() => {
		if (elem) {
			if (elem.emojis?.length) {
				const arr = elem.emojis.map((el: any) => {
					return getEmoji(el);
				});

				setInputVisibleValue(arr.toString().replace(/,/g, ''));
			}
		}
	}, []);

	return (
		<div ref={ref} style={{opacity}} className='stickers__item m-3'>
			<Image src={elem.pic || elem.picFile} className='stickers__img' />
			<Form.Control
				ref={inputRef}
				placeholder='Alias Emoji'
				name='emojis'
				value={inputVisibleValue}
				onChange={onChangeHandler}
				onBlur={onBlurHandler}
				onClick={onInputClickHandler}
				onKeyDown={handleKeyDown}
				maxLength={140}
				required
				size='sm'
				autoComplete='off'
			/>
			{((elem.id && emojiPicker === elem.id) || (!elem.id && emojiPicker === index)) && (
				<Picker
					data={data}
					className='emoji-picker'
					locale={language === LanguageTag.ru ? LanguageTag.ru : LanguageTag.en}
					navPosition='bottom'
					searchPosition='none'
					previewPosition='none'
					dynamicWidth
					onEmojiSelect={onEmojiSelectHandler}
				/>
			)}
			<Button
				className='stickers__btn-delete'
				variant='danger'
				size='sm'
				onClick={() => (id ? openAlert() : deletePicFile())}>
				<BsTrashFill />
			</Button>
		</div>
	);
};

export default observer(Sticker);

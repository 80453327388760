import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Table, Spinner, Button} from 'react-bootstrap';
import {BsArrowLeftShort, BsArrowRightShort} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';

import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import userServices from 'store/userServices';
import modalServices from 'store/modalServices';
import changelogService from 'store/changelogService';

import {Theme} from 'models/enums/Theme.enum';
import {Changelog as ChangelogType} from 'models/changelog';

import PageTitle from 'components/pageTitle/PageTitle';
import useChangelog from 'hooks/useChangelog';
import SubscribeModal from 'components/modals/subscribeModal/SubscribeModal';
import ChangelogElem from './components/changelog/ChangelogElem';

const LIMIT = 5;

const Changelog: FunctionComponent = function Changelog() {
	const translations = useL10n();
	const history = useHistory();
	const changelogRef = useRef<HTMLDivElement>(null);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [offset, setOffset] = useState(0);
	const [changelogData, setChangelogData] = useState<ChangelogType[]>([]);
	const [allowClick, setAllowClick] = useState(true);

	const {appTheme} = useLocalObservable(() => appService);
	const {isSuperAdmin} = useLocalObservable(() => userServices);
	const {setSubscribeModalVisible} = useLocalObservable(() => modalServices);
	const {lastViewedChangelogId, setLastViewedChangelogId, setUnviewedChangelogCount} =
		useLocalObservable(() => changelogService);

	const {getChangelog, postViewedChangelogId} = useChangelog();

	const getChangelogData = async (value: {offset?: number; limit?: number; order?: string}) => {
		setAllowClick(false);
		setVisiblePreloader(true);
		const response = await getChangelog(value);
		setVisiblePreloader(false);
		setAllowClick(true);
		if (response) {
			setChangelogData(response);
		}
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		await getChangelogData({offset: offset + LIMIT, limit: LIMIT});
	};

	const onPrevPageBtnClickHandler = async () => {
		if (offset - LIMIT >= 0) {
			setOffset(offset - LIMIT);
			await getChangelogData({offset: offset - LIMIT, limit: LIMIT});
		}
	};

	const setLastViewedChangelog = async (id: number) => {
		const response = await postViewedChangelogId(id);
		if (response) {
			setLastViewedChangelogId(id);
			setUnviewedChangelogCount(0);
		}
	};

	useEffect(() => {
		if (changelogData.length) {
			if (changelogData[0].id) setLastViewedChangelog(changelogData[0].id);
		}
	}, [changelogData.length]);

	useEffect(() => {
		getChangelogData({limit: LIMIT, offset});
	}, []);

	const renderElements = useCallback(
		(el: ChangelogType) => {
			return (
				<ChangelogElem
					elem={el}
					key={el.id}
					getChangelog={() => getChangelogData({offset, limit: LIMIT})}
				/>
			);
		},
		[changelogData]
	);

	return (
		<Container fluid className='settings pt-4' ref={changelogRef}>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.information.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.changelog.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.changelog.title}
						text={translations.sidebar.changelog.descr}
					/>
				</Col>
				<Col md={3} className='d-flex align-items-center justify-content-end'>
					<div className='d-flex'>
						{isSuperAdmin && (
							<Button
								variant={appTheme === Theme.DARK ? 'outline-light' : 'outline-dark'}
								onClick={() => history.push('/changelog/create')}>
								{translations.btns.create}
							</Button>
						)}
						<Button variant='dark' className='mx-2' onClick={() => setSubscribeModalVisible(true)}>
							{translations.btns.subscribeToUpdates}
						</Button>
					</div>
				</Col>
			</Row>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{!visiblePreloader && <div className='changelog'>{changelogData.map(renderElements)}</div>}
			<div className='d-flex mb-5'>
				{offset !== 0 && (
					<Button
						variant='outline-dark'
						className='d-flex align-items-center'
						onClick={onPrevPageBtnClickHandler}
						disabled={!allowClick}>
						<BsArrowLeftShort className='mx-2' />
						<div className='d-flex flex-column align-items-start'>
							<span className={appTheme === Theme.DARK ? 'text-white' : 'text-muted'}>
								{translations.next}
							</span>
							<div>
								<small className='text-muted'>{translations.newerPosts}</small>
							</div>
						</div>
					</Button>
				)}
				&nbsp;
				<Button
					variant='outline-dark'
					className='d-flex align-items-center'
					onClick={onNextPageBtnClickHandler}
					disabled={!changelogData.length || changelogData.length < LIMIT || !allowClick}>
					<div className='d-flex flex-column align-items-start'>
						<span className={appTheme === Theme.DARK ? 'text-white' : 'text-muted'}>
							{translations.prev}
						</span>
						<div>
							<small className='text-muted'>{translations.olderPosts}</small>
						</div>
					</div>

					<BsArrowRightShort className='mx-2' />
				</Button>
			</div>
			<SubscribeModal />
		</Container>
	);
};

export default observer(Changelog);

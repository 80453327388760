import {observer, useLocalObservable} from 'mobx-react-lite';
import {Report} from 'models/reports';
import {FunctionComponent, useCallback} from 'react';

import {Table, Alert, Badge, Image} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import useReportReason from 'hooks/useReportReason';
import {User} from 'models/user';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IAutobanReports {
	reports: Report[];
	user: User;
}
const AutobanReports: FunctionComponent<IAutobanReports> = function AutobanReports({
	reports,
	user,
}) {
	const translations = useL10n();
	const {getReason} = useReportReason();
	const {appTheme} = useLocalObservable(() => appService);

	const renderReport = useCallback(
		(item: any, index: number) => {
			return (
				<tr key={index}>
					<td>
						<div className='user-info'>
							<div className='user-info__photo background-color-3'>
								<Image src={item.initiator?.pic} roundedCircle fluid />
							</div>
							<div className='text-truncate'>
								<p className='d-inline'>{item.initiator?.name}</p>
							</div>
						</div>
					</td>
					<td>
						<div className='user-info'>
							<div className='user-info__photo'>
								<Image src={user?.pic} roundedCircle fluid />
							</div>
							<div className='text-truncate'>
								<p className='d-inline'>{user?.name}</p>
							</div>
						</div>
					</td>
					<td className='text-center align-middle'>
						<small>
							{getReason(item.reason) ? (
								<Alert variant='warning'>{getReason(item.reason)?.text}</Alert>
							) : (
								<Alert variant='warning'>{item.reason}</Alert>
							)}
						</small>
					</td>
					{item.message?.text ? (
						<td>
							<Alert key='warning' variant='secondary'>
								{item.message.text}
							</Alert>
						</td>
					) : (
						<td>
							<Alert key='warning' variant='warning'>
								-
							</Alert>
						</td>
					)}
				</tr>
			);
		},
		[reports]
	);

	return (
		<Table
			responsive
			striped={appTheme === Theme.LIGHT}
			bordered
			hover
			variant={appTheme?.toLowerCase()}>
			<thead>
				<tr>
					<th>
						<b>{translations.table.accuser}</b>
					</th>
					<th>
						<b>{translations.table.accused}</b>
					</th>
					<th>
						<b>{translations.table.reason}</b>
					</th>
					<th>
						<b>{translations.table.message}</b>
					</th>
				</tr>
			</thead>
			<tbody>{reports.map((elem, index) => renderReport(elem, index))}</tbody>
		</Table>
	);
};

export default observer(AutobanReports);

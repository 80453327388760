import {action, makeObservable, observable} from 'mobx';
import {ProjectUser} from 'models/projectUser';

class UserServices {
	@observable
	public accessToken: UUID = '';

	@observable
	public isAdmin = false;

	@observable
	public isSuperAdmin = false;

	@observable
	public isCreator = false;

	@observable
	public chatUserId: string | null = null;

	@observable
	public projectUsers: ProjectUser[] = [];

	@observable
	public admins: any[] = [];

	@observable
	public adminData: any = null;

	@observable
	public admin: any = null;

	@action
	public setAccessToken = (value: UUID) => {
		this.accessToken = value;
	};

	@action
	public setIsAdmin = (value: boolean) => {
		this.isAdmin = value;
	};

	@action
	public setIsSuperAdmin = (value: boolean) => {
		this.isSuperAdmin = value;
	};

	@action
	public setIsCreator = (value: boolean) => {
		this.isCreator = value;
	};

	@action
	public setChatUserId = (value: string | null) => {
		this.chatUserId = value;
	};

	@action
	public setProjectUsers = (value: ProjectUser[]) => {
		this.projectUsers = value;
	};

	@action
	public setAdmins = (value: []) => {
		this.admins = value;
	};

	@action
	public setAdminData = (value: any) => {
		this.adminData = value;
	};

	@action
	public setAdmin = (value: any) => {
		this.admin = value;
		this.setProjectUsers(value.projectUsers);
		localStorage.setItem('projectUsers', JSON.stringify(value.projectUsers));
	};

	@action
	public clearUserData = () => {
		this.accessToken = '';
		this.isAdmin = false;
		this.projectUsers = [];
		this.admins = [];
		this.isSuperAdmin = false;
		this.isCreator = false;
		this.adminData = null;
		this.admin = null;
		localStorage.removeItem('accessToken');
		localStorage.removeItem('user');
		localStorage.removeItem('projectId');
		localStorage.removeItem('projectUsers');
		localStorage.removeItem('chatUser');
		localStorage.getItem('isSuperAdmin') && localStorage.removeItem('isSuperAdmin');
		localStorage.getItem('isCreator') && localStorage.removeItem('isCreator');
	};

	constructor() {
		makeObservable(this);
	}
}

export default new UserServices();

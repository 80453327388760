import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import {NamedPicType} from 'models/enums/NamedPicType.enum';
import {getFileSize} from 'utils/helpers';
import NamedPicModal from 'pages/main/components/settings/appearence/reactions/ReactionModal';
import UploadImage from 'components/uploadImage/uploadImage';
import settingsServices from 'store/settingsServices';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import Reaction from './reactions/Reaction';

interface IReactions {
	currentTab: string;
}
const Reactions: FunctionComponent<IReactions> = function Reactions({currentTab}) {
	const translations = useL10n();
	const {getEmotions, addNamedPic} = useSettings();
	const {theme, reactions, setReactions} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const addPic = async (file: File) => {
		addNamedPic(NamedPicType.EMOTION, file);
	};

	const setPic = (file: File | null) => {
		file && addPic(file);
	};

	const getEmotionsWithService = async () => {
		setVisiblePreloader(true);
		await getEmotions();
		setVisiblePreloader(false);
	};

	useEffect(() => {
		currentTab === NamedPicType.EMOTION && getEmotionsWithService();
	}, [theme]);

	useEffect(() => {
		currentTab === NamedPicType.EMOTION && getEmotionsWithService();
		return () => {
			setReactions([]);
		};
	}, [currentTab]);

	return (
		<div className='block mb-5'>
			<p className='h5 mb-5'>{translations.settings.reactions}</p>
			<div className='mb-3 col-6'>
				<p>
					<small>{translations.imageReqs.imageWeight(getFileSize(204800))}</small>
					<small>{translations.imageReqs.imageMinSize([128, 128])}</small>
				</p>
				<p>
					<small>{translations.imageReqs.formats('.png')}</small>
				</p>

				<UploadImage
					id='uploadReactions'
					setPic={setPic}
					objectMinSize={{width: 128, height: 128}}
					size={204800}
					acceptedFileTypes='.png'
					clearAfterLoad
					isSquare
				/>
			</div>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{reactions.length > 0 ? (
				<div className='settings__namedPics'>
					{reactions.map((elem, index) => {
						return <Reaction index={index} key={elem.id} elem={elem} type={NamedPicType.EMOTION} />;
					})}
				</div>
			) : (
				!visiblePreloader && <p>{translations.empty.reactionsEmpty}</p>
			)}

			<NamedPicModal />
		</div>
	);
};
export default observer(Reactions);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Table,
	Breadcrumb,
	Spinner,
	Button,
	Navbar,
	FloatingLabel,
	Form,
	InputGroup,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';

import ResponseStatus from 'models/enums/ResponseStatus.enum';

import FeedbackService from 'services/api/FeedbackService';
import userServices from 'store/userServices';
import {filter} from 'models/filter';
import {LIMIT} from 'constants/constants';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import PageTitle from 'components/pageTitle/PageTitle';
import FeedbackItem from './components/feedback/FeedbackItem';

const Feedback: FunctionComponent = function Feedback() {
	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [feedback, setFeedback] = useState([]);
	const [sortBy, setSortBy] = useState('createdAt_desc');
	const [offset, setOffset] = useState(0);
	const [search, setSearch] = useState('');
	const [searchBy, setSearchBy] = useState('room__externalRoomId');
	const [isSearching, setIsSearching] = useState(false);

	const [searchFilter, setSearchFilter] = useState<filter>({
		key: 'room__externalRoomId',
		value: null,
		prefix: 'like',
	});

	const getFeedback = async (
		limit?: number,
		currentOffset?: number,
		order?: string,
		filters?: filter
	) => {
		let allFilters: filter | null = null;
		if (filters?.value) {
			allFilters = filters;
		}
		setVisiblePreloader(true);
		const response = await FeedbackService.getFeedback(
			accessToken,
			limit || LIMIT,
			currentOffset || 0,
			order || 'createdAt_desc',
			allFilters
		);
		setVisiblePreloader(false);
		if (response.status === ResponseStatus.SUCCESS) {
			setFeedback(response.data);
		} else setFeedback([]);
	};

	const onSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === '') return;
		setSortBy(value);
		setOffset(0);

		getFeedback(LIMIT, 0, value, searchFilter);
	};

	const onSearchSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setSearchFilter({...searchFilter, key: value, value: search});
		setSearchBy(value);
	};

	const onSearchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
		setSearchFilter({...searchFilter, value});
	};

	const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setIsSearching(!isSearching);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getFeedback(LIMIT, offset - LIMIT, sortBy, searchFilter);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getFeedback(LIMIT, offset + LIMIT, sortBy, searchFilter);
	};

	const clearFilters = () => {
		setIsSearching(!isSearching);
		setSearchBy('room__externalRoomId');
		setSearch('');
		setSearchFilter({
			key: 'room__externalRoomId',
			value: null,
			prefix: 'like',
		});

		setSortBy('createdAt_desc');
	};

	useEffect(() => {
		if (accessToken) {
			setOffset(0);
			getFeedback(LIMIT, 0, sortBy, searchFilter);
		}
	}, [accessToken, isSearching]);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.feedback}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.soundQuality.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.soundQuality.title}
						text={translations.sidebar.soundQuality.descr}
					/>
				</Col>
			</Row>

			<Navbar variant='dark' bg='dark' expand='lg' className='mb-3'>
				<Container fluid>
					<Navbar className='w-100 d-flex p-0'>
						<div className=' w-100 d-flex align-items-center justify-content-between'>
							<div className='d-flex'>
								<Form onSubmit={handleSearch}>
									<InputGroup>
										<FloatingLabel
											controlId='floatingSelect'
											label={translations.search.placeholder}>
											<Form.Select onChange={onSearchSelect} value={searchBy}>
												<option value='room__externalRoomId'>{translations.search.roomId}</option>
												<option value='user__id'>{translations.sorting.id}</option>
												<option value='info'>{translations.search.info}</option>
											</Form.Select>
										</FloatingLabel>

										<Form.Control
											placeholder={translations.search.btn}
											value={search}
											onChange={onSearchChangeHandler}
										/>

										<Button variant='secondary' id='button-search' type='submit'>
											{translations.search.btn}
										</Button>
									</InputGroup>
								</Form>
								&nbsp;
								<Button variant='dark' onClick={clearFilters}>
									{translations.clear}
								</Button>
							</div>
							<div className='filters__btns'>
								<Button
									className='d-flex'
									onClick={onPrevPageBtnClickHandler}
									disabled={(!feedback.length && offset === 0) || offset === 0}>
									<BsArrowLeftCircleFill />
								</Button>
								&nbsp;
								<Button
									className='d-flex'
									onClick={onNextPageBtnClickHandler}
									disabled={!feedback.length || feedback.length < LIMIT}>
									<BsArrowRightCircleFill />
								</Button>
							</div>
						</div>
					</Navbar>
				</Container>
			</Navbar>

			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}

			{feedback.length > 0 ? (
				<Table
					responsive
					striped={appTheme === Theme.LIGHT}
					bordered
					hover
					variant={appTheme?.toLowerCase()}>
					<thead>
						<tr>
							<th className='align-middle col-1'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.externalRoomId}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.externalRoomId}</option>
										<option value='room__externalRoomId_desc'>
											{translations.table.externalRoomId} &darr;
										</option>
										<option value='room__externalRoomId_asc'>
											{translations.table.externalRoomId} &uarr;
										</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='text-center align-middle col-1'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.sentAt}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.sentAt}</option>
										<option value='createdAt_desc'>{translations.table.sentAt} &darr;</option>
										<option value='createdAt_asc'>{translations.table.sentAt} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='text-center align-middle col-2'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.user}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.user}</option>
										<option value='user__id_desc'>{translations.table.user} &darr;</option>
										<option value='user__id_asc'>{translations.table.user} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='text-center align-middle col-1'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.rate}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.rate}</option>
										<option value='rating_desc'>{translations.table.rate} &darr;</option>
										<option value='rating_asc'>{translations.table.rate} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='text-center align-middle col-2'>
								<OverlayTrigger
									placement='bottom'
									delay={{show: 50, hide: 50}}
									overlay={
										<Tooltip id='button-tooltip-2'>
											<b>{translations.table.issue}</b>
										</Tooltip>
									}>
									<Form.Select onChange={onSelect} value={sortBy} className='text-truncate'>
										<option value=''>{translations.table.issue}</option>
										<option value='issue_desc'>{translations.table.issue} &darr;</option>
										<option value='issue_asc'>{translations.table.issue} &uarr;</option>
									</Form.Select>
								</OverlayTrigger>
							</th>
							<th className='text-center align-middle'>
								<b>{translations.table.connectionInfo}</b>
							</th>
						</tr>
					</thead>
					<tbody>
						{feedback.map((elem, index) => (
							<FeedbackItem feedback={elem} key={index} />
						))}
					</tbody>
				</Table>
			) : (
				!visiblePreloader && <p>{translations.empty.feedbackEmpty}</p>
			)}
		</Container>
	);
};
export default observer(Feedback);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {Feedback} from 'models/feedback';
import {FunctionComponent, useEffect, useState} from 'react';
import Moment from 'react-moment';
import {NavLink} from 'react-router-dom';
import {Badge, Image, Alert} from 'react-bootstrap';
import AudioReasons from 'models/enums/AudioReason.enum';
import LanguageTag from 'models/enums/LanguageTag.enum';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

type AudioReasonItem = {
	reason: AudioReasons;
	text: string;
	textEn: string;
};

interface IFeedbackItem {
	feedback: Feedback;
}

const FeedbackItem: FunctionComponent<IFeedbackItem> = function FeedbackItem({feedback}) {
	const [info, setInfo] = useState<any[]>([]);
	const [issues, setIssues] = useState<any[]>([]);
	const {language, appTheme} = useLocalObservable(() => appService);

	const getInfo = () => {
		// eslint-disable-next-line array-callback-return
		Object.entries(JSON.parse(feedback.info)).map(([key, value]) => {
			if (value) {
				setInfo([...info, [key, value]]);
			}
		});
	};

	const audioReasonItems: AudioReasonItem[] = [
		{
			reason: AudioReasons.NOTHEAR,
			text: 'Ничего не было слышно',
			textEn: "I didn't hear anything",
		},
		{
			reason: AudioReasons.CONNECTION,
			text: 'Звук прерывался',
			textEn: 'The sound was choppy',
		},
		{
			reason: AudioReasons.MICROPHONE,
			text: 'Не получилось воспользоваться микрофоном',
			textEn: "Microphone didn't work",
		},
	];

	const getIssues = () => {
		setIssues(
			feedback.issue.split(',').map(el => {
				return audioReasonItems.find(item => item.reason === el.trim());
			})
		);
	};

	useEffect(() => {
		feedback.info && getInfo();
		feedback.issue && getIssues();
	}, [feedback]);

	return (
		<tr>
			<td className='align-middle text-truncate'>
				{feedback.room && (
					<NavLink to={`/room/${encodeURIComponent(feedback.room.externalRoomId)}`}>
						{feedback.room.externalRoomId}
					</NavLink>
				)}
			</td>
			<td className='text-center align-middle text-secondary'>
				{feedback.createdAt && <Moment format='DD.MM.YY HH:mm'>{feedback.createdAt}</Moment>}
			</td>
			<td className='align-middle'>
				{feedback.user && (
					<div className='user-info'>
						<div
							className={`user-info__photo user-info__photo--sm background-color-${
								feedback.user?.color || 0
							}`}>
							<Image src={feedback.user.pic} roundedCircle fluid />
						</div>
						<div className='text-truncate'>
							<Badge bg={appTheme.toLowerCase()} pill>
								id{feedback.user.id}
							</Badge>
							<div className='text-truncate'>{feedback.user.name}</div>
						</div>
					</div>
				)}
			</td>

			<td className='text-center align-middle text-truncate'>{feedback.rating}</td>
			<td className='text-center align-middle text-wrap'>
				{feedback.issue && (
					<small>
						{issues.map((el: any, index: number) => {
							return (
								el && (
									<Alert
										key={index}
										className='mb-1 p-2'
										variant={appTheme === Theme.LIGHT ? 'secondary' : 'light'}>
										{language === LanguageTag.ru ? el.text : el.textEn}
									</Alert>
								)
							);
						})}
					</small>
				)}
			</td>
			<td>
				{feedback.info && (
					<Alert variant='secondary'>
						{Object.keys(JSON.parse(feedback.info)).map((item, index) => (
							<div key={index}>
								<Badge bg={appTheme.toLowerCase()}>{item}</Badge>&nbsp;
								{item !== 'speed' && <small>{JSON.parse(feedback.info)[item]}</small>}
								{item === 'speed' && JSON.parse(feedback.info)[item].includes('Kbs') ? (
									<small>
										{(JSON.parse(feedback.info)[item].replace('Kbs', '') / 1000).toFixed(1)} Mbs
									</small>
								) : (
									item === 'speed' && <small>{JSON.parse(feedback.info)[item]}</small>
								)}
							</div>
						))}
					</Alert>
				)}
			</td>
		</tr>
	);
};

export default observer(FeedbackItem);

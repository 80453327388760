import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

export default class BadgeService {
	static uploadRanking = async (token: UUID, file: File) => {
		try {
			const formData = new FormData();
			formData.append('list', file);

			const {data, status} = await axios.post(`/admin/ranking`, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static createBadge = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(`/admin/badge`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateBadge = async (token: UUID, id: number, body: any) => {
		try {
			const {data, status} = await axios.patch(`/admin/badge/${id}`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteBadge = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.delete(`/admin/badge/${id}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBadge = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.get(`/admin/badge/${id}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBadgeUsers = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.get(`/admin/badge/${id}/users`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAllBadgeUsers = async (
		token: UUID,
		limit: number,
		offset: number,
		search?: {
			type: string;
			value: string;
		},
		filter?: {
			type: string;
			value: string;
		}
	) => {
		try {
			let apiUrl = `/admin/badge/users?limit=${limit}&offset=${offset}`;
			if (search?.value) {
				apiUrl = `${apiUrl}&${search.type}=${search.value}`;
			}

			if (filter?.value) {
				apiUrl = `${apiUrl}&${filter.type}=${filter.value}`;
			}
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addUserBadge = async (token: UUID, badgeId: number, userId: number) => {
		try {
			const apiUrl = `/admin/badge/${badgeId}/user/${userId}`;

			const body = {
				badgeId,
				userId,
			};
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteUserBadge = async (token: UUID, badgeId: number, userId: number) => {
		try {
			const apiUrl = `/admin/badge/${badgeId}/user/${userId}`;

			const {data, status} = await axios.delete(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBadges = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/badge`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadUserList = async (token: UUID, badgeId: number, body: any) => {
		try {
			const apiUrl = `/admin/badge/${badgeId}/users`;

			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

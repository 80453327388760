/* eslint-disable max-lines */

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import {Button, Tooltip, OverlayTrigger, Dropdown, DropdownButton} from 'react-bootstrap';

import MessageService from 'services/api/MessageService';
import ReportService from 'services/api/ReportService';

import toastService from 'store/toastServices';
import alertService from 'store/alertServices';
import userServices from 'store/userServices';

import {Report} from 'models/reports';
import {AlertBtnType} from 'models/enums/Alert.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';

interface IReportsActions {
	report: Report;
	getReports: () => void;
}
const ReportsActions: FunctionComponent<IReportsActions> = function ReportsActions({
	report,
	getReports,
}) {
	const {accessToken} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {showBanModal} = useLocalObservable(() => modalServices);
	const {addToast} = useLocalObservable(() => toastService);
	const translations = useL10n();

	const showToaster = (responseText: string, responseStatus: string, name?: string | null) => {
		addToast({
			title: name ? translations.toasts.user(name) : '',
			text: responseText,
			variant: responseStatus === ResponseStatus.SUCCESS ? 'success' : 'danger',
		});
	};

	const hideMessage = async (messageId: number, name: string | null) => {
		let responseText = '';
		const response = await MessageService.setMessageVisibility(messageId, false, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			getReports();
		} else if (response.status === ResponseStatus.ERROR) {
			responseText = translations.toasts.error;
			showToaster(responseText, response.status, name);
		}
	};

	const deleteMessage = async (messageId: number, name: string | null) => {
		let responseText = '';
		const response = await MessageService.deleteMessage(messageId, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			getReports();
		} else if (response.status === ResponseStatus.ERROR) {
			responseText = translations.toasts.error;
			showToaster(responseText, response.status, name);
		}
	};

	const solveReport = async (messageId: number, reason: string | null, name: string | null) => {
		let responseText = '';
		const response = await ReportService.solveReport(messageId, reason, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.reportClosed;
			getReports();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		showToaster(responseText, response.status, name);
	};

	const openAlert = (action: () => void, text: string) => {
		showAlert({
			title: translations.alerts.reports,
			text,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: text.split(' ')[0],
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
						action();
					},
				},
			],
		});
	};

	const openBanModal = (type: string) => {
		showBanModal({
			type,
			targetName: report.target.name,
			targetId: type === 'banUser' ? report.target.id : report.target.talkers[0].id,
			externalRoomId: report.externalRoomId,
			getReports,
		});
	};

	const isTalkerBanned = () => {
		if (report.target.talkerBans?.length) {
			const currentRoomTalkerBan = report.target.talkerBans.find(
				(el: any) => el.talker?.room?.externalRoomId === report.room.externalRoomId
			);
			if (currentRoomTalkerBan !== undefined) return true;
		}
		return false;
	};

	const banTitle = () => {
		if (!isTalkerBanned()) return translations.btns.banRoom;
		return translations.btns.banRoom;
	};

	return (
		<div>
			<div>
				<div className='d-flex justify-content-center flex-wrap'>
					<Button
						variant='dark'
						size='sm'
						disabled={Boolean(report.target.bans.length > 0)}
						onClick={() => openBanModal('banUser')}>
						{translations.btns.ban}
					</Button>
					&nbsp;
					<Button
						variant='secondary'
						size='sm'
						disabled={isTalkerBanned()}
						onClick={() => openBanModal('banTalker')}>
						{banTitle()}
					</Button>
				</div>
			</div>
			<div>
				<OverlayTrigger
					placement='left'
					delay={{show: 50, hide: 50}}
					overlay={
						<Tooltip id='button-tooltip-2'>
							{!report.message?.isVisible
								? translations.tooltip.messageHidden
								: translations.tooltip.hideMessage}
						</Tooltip>
					}>
					<Button
						size='sm'
						variant='warning'
						disabled={!report.message.isVisible}
						onClick={() =>
							openAlert(
								() => hideMessage(report.message?.id, report.target.name),
								translations.alerts.hideMessage
							)
						}>
						{!report.message?.isVisible ? translations.btns.hidden : translations.btns.hide}
					</Button>
				</OverlayTrigger>
				<OverlayTrigger
					placement='left'
					delay={{show: 50, hide: 50}}
					overlay={
						<Tooltip id='button-tooltip-2'>
							{report.message?.deletedAt !== null
								? translations.tooltip.messageDeleted
								: translations.tooltip.deleteMessage}
						</Tooltip>
					}>
					<Button
						variant='danger'
						size='sm'
						className='m-1'
						disabled={report.message?.deletedAt !== null}
						onClick={() =>
							openAlert(
								() => deleteMessage(report.message?.id, report.target.name),
								translations.alerts.deleteMessage
							)
						}>
						{report.message?.deletedAt !== null
							? translations.btns.removed
							: translations.btns.remove}
					</Button>
				</OverlayTrigger>
				<OverlayTrigger
					placement='left'
					delay={{show: 50, hide: 50}}
					overlay={<Tooltip id='button-tooltip-2'>{translations.btns.closeReport}</Tooltip>}>
					<Button
						size='sm'
						variant='success'
						onClick={() =>
							openAlert(
								() => solveReport(report.message.id, report.reason, report.target.name),
								translations.alerts.closeReport
							)
						}>
						{translations.btns.closeReport}
					</Button>
				</OverlayTrigger>
			</div>
		</div>
	);
};

export default observer(ReportsActions);

import useSettings from 'hooks/useSettings';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import useL10n from 'l10n/useL10n';

import {Form, FloatingLabel, Button} from 'react-bootstrap';
import '../settings.scss';
import checkRealTextLength from 'utils/checkTextLength';
import {Greeting} from 'models/settings';
import settingsServices from 'store/settingsServices';

interface IGreetingForm {
	currentGreeting: Greeting;
	setCurrentGreeting: (value: Greeting) => void;
	getGreeting: (lang?: string) => void;
	tabLang: string;
}
const GreetingForm: FunctionComponent<IGreetingForm> = function GreetingForm({
	currentGreeting,
	setCurrentGreeting,
	getGreeting,
	tabLang,
}) {
	const [validated, setValidated] = useState(false);

	const translations = useL10n();

	const {postGreeting, patchGreeting, deleteGreeting} = useSettings();

	const addGreeting = async () => {
		const newGreeting = {...currentGreeting};
		delete newGreeting.id;
		await postGreeting({...newGreeting, lang: tabLang});
		getGreeting(newGreeting.lang);
	};

	const updateGreeting = async (id: number) => {
		await patchGreeting(id, {
			title: currentGreeting.title,
			text: currentGreeting.text,
			lang: currentGreeting.lang,
		});
		getGreeting(currentGreeting.lang);
	};

	const deleteGreetingByService = async (id?: number) => {
		id && (await deleteGreeting(id));
		getGreeting(currentGreeting.lang);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else
			currentGreeting.lang === tabLang && currentGreeting.id
				? updateGreeting(currentGreeting.id)
				: addGreeting();
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setCurrentGreeting({
			...currentGreeting,
			[event.target.name]: value,
		});
	};

	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
			<div className='position-relative'>
				<FloatingLabel
					controlId={`floatingIdTitle${currentGreeting.lang}`}
					label={translations.title}
					className='w-100 text-secondary'>
					<Form.Control
						name='title'
						as='textarea'
						maxLength={80}
						placeholder={translations.title}
						className='mb-2'
						required
						value={currentGreeting.title}
						onChange={onChangeHandler}
					/>
				</FloatingLabel>
				{currentGreeting.title && (
					<span className='settings__incounter'>
						{80 - checkRealTextLength(currentGreeting.title)}{' '}
					</span>
				)}
			</div>
			<div className='position-relative'>
				<FloatingLabel
					controlId={`floatingIdText${currentGreeting.lang}`}
					label={translations.text}
					className='w-100 text-secondary mb-3'>
					<Form.Control
						name='text'
						as='textarea'
						maxLength={140}
						placeholder={translations.text}
						required
						value={currentGreeting.text}
						onChange={onChangeHandler}
					/>
				</FloatingLabel>
				{currentGreeting.text && (
					<span className='settings__incounter'>
						{140 - checkRealTextLength(currentGreeting.text)}{' '}
					</span>
				)}
			</div>
			<Button type='submit' variant='success'>
				{translations.btns.save}
			</Button>
			&nbsp;
			<Button
				variant='light'
				disabled={!currentGreeting.id || currentGreeting.lang !== tabLang}
				onClick={() => deleteGreetingByService(currentGreeting.id)}>
				{translations.btns.remove}
			</Button>
		</Form>
	);
};

export default observer(GreetingForm);

/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {Button, FloatingLabel, Form} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import {CopybettingType} from 'models/enums/CopybettingType.enum';
import OpenProfileType from 'models/enums/OpenProfileType.enum';

import WidgetV1Img from 'assets/images/widget_v1.png';
import WidgetV2Img from 'assets/images/widget_v2.png';
import UsersPopupOnImg from 'assets/images/usersPopupOn.png';
import UsersPopupOffImg from 'assets/images/usersPopupOff.png';

import {NavLink} from 'react-router-dom';
import appService from 'store/appService';

interface IControls {
	currentTab: string;
}
const Controls: FunctionComponent<IControls> = function Controls({currentTab}) {
	const translations = useL10n();

	const [mySettings, setMySettings] = useState<{
		showProfileSettings?: boolean;
		showExitButton?: boolean;
		showBettingButton?: boolean;
		showUserCounter?: boolean;
		showRules?: boolean;
		showAudioQualityCheckPopup?: boolean;
		enableOnboarding?: boolean;
		enableAvatarChange?: boolean;
		enableNameChange?: boolean;
		enableProfileDeletion?: boolean;
		enableNameVerification?: boolean;
		showAddToCalendarButton?: boolean;
		showShareButton?: boolean;
		copybettingType?: CopybettingType;
		enableLowerUsersModule?: boolean;
		copybettingVersion?: number;
		showDefaultStickers?: boolean;
		enableOpenProfile?: boolean;
		openProfileType?: OpenProfileType;
		enableAutoposting?: boolean;
		enableScreenSharing?: boolean;
		showNameToast?: boolean;
		copybettingBearer?: string;
		copybettingUrl?: string;
		showAnimatedEmotions?: boolean;
		enableMessagePics?: boolean;
		enableOnlySubscriptionsFilter?: boolean;
		enableThreads?: boolean;
		enableAutobans?: boolean;
		enableFullscreenOption?: boolean;
		enableWidgetText?: boolean;
	}>({});

	const [validatedUrl, setValidatedUrl] = useState(false);
	const {settings} = useLocalObservable(() => settingsServices);
	const {projectId} = useLocalObservable(() => appService);

	const {patchSettings} = useSettings();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name, value} = event.target;
		let formatValue: any =
			name === 'copybettingType' || name === 'openProfileType' ? value : +value;
		if (name === 'enableLowerUsersModule') {
			formatValue = Boolean(+value);
		}

		const isRadio =
			name === 'copybettingType' ||
			name === 'copybettingVersion' ||
			name === 'openProfileType' ||
			name === 'enableLowerUsersModule';

		setMySettings({
			...mySettings,
			[name]: isRadio ? formatValue : checked,
		});

		patchSettings({
			[name]: isRadio ? formatValue : checked,
		});
	};

	const onChangeUrlHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			copybettingUrl: value,
		});
	};

	const onChangeBearerHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			copybettingBearer: value,
		});
	};

	const handleSubmitUrl = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		const e: any = event;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidatedUrl(true);
		else {
			patchSettings({
				copybettingUrl: mySettings.copybettingUrl,
				copybettingBearer: mySettings.copybettingBearer,
			});
		}
	};

	useEffect(() => {
		settings &&
			setMySettings({
				showProfileSettings: settings?.showProfileSettings,
				showExitButton: settings?.showExitButton,
				showBettingButton: settings?.showBettingButton,
				showUserCounter: settings?.showUserCounter,
				showRules: settings?.showRules,
				showAudioQualityCheckPopup: settings?.showAudioQualityCheckPopup,
				enableOnboarding: settings?.enableOnboarding,
				enableAvatarChange: settings?.enableAvatarChange,
				enableNameChange: settings?.enableNameChange,
				enableProfileDeletion: settings?.enableProfileDeletion,
				enableNameVerification: settings?.enableNameVerification,
				showAddToCalendarButton: settings?.showAddToCalendarButton,
				showShareButton: settings?.showShareButton,
				copybettingType: settings?.copybettingType,
				enableLowerUsersModule: settings?.enableLowerUsersModule,
				copybettingVersion: settings?.copybettingVersion,
				showDefaultStickers: settings?.showDefaultStickers,
				enableOpenProfile: settings?.enableOpenProfile,
				openProfileType: settings?.openProfileType,
				enableScreenSharing: settings?.enableScreenSharing,
				showNameToast: settings?.showNameToast,
				copybettingBearer: settings?.copybettingBearer,
				copybettingUrl: settings?.copybettingUrl,
				showAnimatedEmotions: settings?.showAnimatedEmotions,
				enableMessagePics: settings?.enableMessagePics,
				enableOnlySubscriptionsFilter: settings?.enableOnlySubscriptionsFilter,
				enableThreads: settings?.enableThreads,
				enableAutobans: settings?.enableAutobans,
				enableFullscreenOption: settings?.enableFullscreenOption,
				enableWidgetText: settings?.enableWidgetText,
			});
	}, [settings]);

	return (
		<div>
			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.headerSettings.title}</p>
				<div className='mb-4'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showProfileSettings'
						id='showProfileSettingsToggle'
						label={translations.settings.headerSettings.profileSettings.title}
						checked={mySettings.showProfileSettings}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.headerSettings.profileSettings.description}
					</p>
				</div>
				<div className='mb-4'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showUserCounter'
						id='showUserCounterToggle'
						label={translations.settings.headerSettings.userCounter.title}
						checked={mySettings.showUserCounter}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.headerSettings.userCounter.description}
					</p>
				</div>
				<div className='mb-4'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showAddToCalendarButton'
						id='showAddToCalendarButtonToggle'
						label={translations.settings.headerSettings.addToCalendar.title}
						checked={mySettings.showAddToCalendarButton}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.headerSettings.addToCalendar.description}
					</p>
				</div>
				<div className='mb-4'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showShareButton'
						id='showShareButtonToggle'
						label={translations.settings.headerSettings.shareButton.title}
						checked={mySettings.showShareButton}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.headerSettings.shareButton.description}
					</p>
				</div>
				<div className='mb-4'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showExitButton'
						id='showExitButtonToggle'
						label={translations.settings.headerSettings.exitButton.title}
						checked={mySettings.showExitButton}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.headerSettings.exitButton.description}
					</p>
				</div>
				<div className='mb-4'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableScreenSharing'
						id='enableScreenSharing'
						label={translations.settings.headerSettings.enableScreenSharing.title}
						checked={mySettings.enableScreenSharing}
						className='mb-2'
						disabled={!settings.enableAgora}
					/>
					{!settings.enableAgora && (
						<p>
							<NavLink to='/liveStreams'>
								<small className='w-50 text-secondary settings__description mb-2'>
									{translations.settings.headerSettings.enableScreenSharing.warning}
								</small>
							</NavLink>
						</p>
					)}
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.headerSettings.enableScreenSharing.description}
					</p>
				</div>
				{settings?.enableScreenSharing && (
					<div className='mb-5'>
						<Form.Check
							onChange={onChangeHandler}
							type='switch'
							name='enableFullscreenOption'
							id='enableFullscreenOptionToggle'
							label={translations.settings.enableFullscreenOption.title}
							checked={mySettings.enableFullscreenOption}
						/>
					</div>
				)}
				{(projectId === 'dev' || projectId.includes('liga')) && (
					<div className='mb-4'>
						<Form.Check
							onChange={onChangeHandler}
							type='switch'
							name='enableOnlySubscriptionsFilter'
							id='enableOnlySubscriptionsFilter'
							label={translations.settings.headerSettings.enableOnlySubscriptionsFilter.title}
							checked={mySettings.enableOnlySubscriptionsFilter}
							className='mb-2'
						/>
						<p className='w-50 text-secondary settings__description'>
							{translations.settings.headerSettings.enableOnlySubscriptionsFilter.description}
						</p>
					</div>
				)}
			</div>

			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.userSettings.title}</p>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableNameChange'
						id='enableNameChange'
						label={translations.settings.userSettings.enableNameChange.title}
						checked={mySettings.enableNameChange}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.userSettings.enableNameChange.description}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableNameVerification'
						id='enableNameVerificationToggle'
						label={translations.settings.userSettings.enableNameVerification.title}
						checked={mySettings.enableNameVerification}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.userSettings.enableNameVerification.description}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableAvatarChange'
						id='enableAvatarChange'
						label={translations.settings.userSettings.enableAvatarChange.title}
						checked={mySettings.enableAvatarChange}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.userSettings.enableAvatarChange.description}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableProfileDeletion'
						id='enableProfileDeletion'
						label={translations.settings.userSettings.enableProfileDeletion.title}
						checked={mySettings.enableProfileDeletion}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.userSettings.enableProfileDeletion.description}
					</p>
				</div>
			</div>
			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.userProfiles.title}</p>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableOpenProfile'
						id='enableOpenProfileToggle'
						label={translations.settings.userProfiles.allowUserProfile.title}
						checked={mySettings.enableOpenProfile}
						disabled={mySettings.enableAutoposting}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.userProfiles.allowUserProfile.description}
					</p>
				</div>
				{settings.enableOpenProfile && (
					<div className='mb-3'>
						<Form.Group className='mb-3' controlId='openProfileType'>
							<div>
								<Form.Check
									inline
									className='checkbox-black'
									name='openProfileType'
									type='radio'
									label={OpenProfileType.GET}
									id='openProfileType1'
									value={OpenProfileType.GET}
									required
									onChange={onChangeHandler}
									checked={mySettings.openProfileType === OpenProfileType.GET}
								/>
							</div>
							<div>
								<Form.Check
									inline
									className='checkbox-black'
									name='openProfileType'
									type='radio'
									label='Post message'
									id='openProfileType2'
									value={OpenProfileType.PM}
									required
									onChange={onChangeHandler}
									checked={mySettings.openProfileType === OpenProfileType.PM}
								/>
							</div>
						</Form.Group>
					</div>
				)}
			</div>
			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.firstVisitOptions.title}</p>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableOnboarding'
						id='enableOnboarding'
						label={translations.settings.firstVisitOptions.onboarding.title}
						checked={mySettings.enableOnboarding}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.firstVisitOptions.onboarding.description}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showRules'
						id='showRulesToggle'
						label={translations.settings.firstVisitOptions.chatRulesAccept.title}
						checked={mySettings.showRules}
						className='mb-2'
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.firstVisitOptions.chatRulesAccept.description}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showNameToast'
						id='showNameToastToggle'
						label={translations.settings.showNameToast.title}
						checked={mySettings.showNameToast}
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.showNameToast.descr}
					</p>
				</div>
			</div>
			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.messaging}</p>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showDefaultStickers'
						id='showDefaultStickersToggle'
						label={translations.settings.showDefaultStickers.title}
						checked={mySettings.showDefaultStickers}
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.showDefaultStickers.descr}
					</p>
				</div>

				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showAnimatedEmotions'
						id='showAnimatedEmotionsToggle'
						label={translations.settings.showAnimatedEmotions.title}
						checked={mySettings.showAnimatedEmotions}
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.showAnimatedEmotions.description}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableMessagePics'
						id='enableMessagePicsToggle'
						label={translations.settings.enableMessagePics.title}
						checked={mySettings.enableMessagePics}
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.enableMessagePics.descr}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableThreads'
						id='enableThreadsToggle'
						label={translations.settings.enableThreads.title}
						checked={mySettings.enableThreads}
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.enableThreads.descr}
					</p>
				</div>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='enableWidgetText'
						id='enableWidgetTextToggle'
						label={translations.settings.enableWidgetText.title}
						checked={mySettings.enableWidgetText}
					/>
					<p className='w-50 text-secondary settings__description'>
						{translations.settings.enableWidgetText.descr}
					</p>
				</div>
			</div>
			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.copybetting.title}</p>
				<div className='mb-3'>
					<Form.Check
						onChange={onChangeHandler}
						type='switch'
						name='showBettingButton'
						id='showBettingButtonToggle'
						label={translations.settings.copybetting.bettingButton.title}
						checked={mySettings.showBettingButton}
						className='mb-2'
					/>
				</div>
				{settings.showBettingButton && (
					<>
						<div className='d-flex'>
							<Form.Group className='settings__copybetting-widgets' controlId='copybettingType'>
								<div className='settings__copybetting-widget'>
									<Form.Check
										inline
										className='checkbox-black mx-3'
										name='copybettingVersion'
										type='radio'
										label='V1 widget'
										id='copybettingVersion1'
										value={1}
										required
										onChange={onChangeHandler}
										checked={mySettings.copybettingVersion === 1}
									/>
									<img src={WidgetV1Img} alt='' />
								</div>

								<div className='settings__copybetting-widget'>
									<Form.Check
										inline
										className='checkbox-black mx-3'
										name='copybettingVersion'
										type='radio'
										label='V2 widget'
										id='copybettingVersion2'
										value={2}
										required
										onChange={onChangeHandler}
										checked={mySettings.copybettingVersion === 2}
									/>
									<img src={WidgetV2Img} alt='' />
								</div>
							</Form.Group>
							<div>
								<p className='w-50 text-secondary '>
									{translations.settings.copybetting.description}
								</p>
							</div>
						</div>

						<Form.Group className='mb-3' controlId='copybettingType'>
							<div>
								<Form.Check
									inline
									className='checkbox-black'
									name='copybettingType'
									type='radio'
									label={CopybettingType.BACK_TO_BACK}
									id='CopybettingType1'
									value={CopybettingType.BACK_TO_BACK}
									required
									onChange={onChangeHandler}
									checked={mySettings.copybettingType === CopybettingType.BACK_TO_BACK}
								/>
							</div>
							<div>
								<Form.Check
									inline
									className='checkbox-black'
									name='copybettingType'
									type='radio'
									label={CopybettingType.FRONT_TO_FRONT}
									id='CopybettingType2'
									value={CopybettingType.FRONT_TO_FRONT}
									required
									onChange={onChangeHandler}
									checked={mySettings.copybettingType === CopybettingType.FRONT_TO_FRONT}
								/>
							</div>
						</Form.Group>
						{settings.copybettingType === CopybettingType.BACK_TO_BACK && (
							<div className='w-50 mb-3'>
								<Form noValidate validated={validatedUrl} onSubmit={handleSubmitUrl}>
									<FloatingLabel
										controlId='copybettingUrl'
										label='URL'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='copybettingUrl'
											placeholder='Url'
											value={mySettings.copybettingUrl}
											onChange={onChangeUrlHandler}
											required
											type='url'
										/>
									</FloatingLabel>
									<FloatingLabel
										controlId='eventGambleUrl'
										label='Bearer'
										className='w-100 text-secondary mb-3'>
										<Form.Control
											name='copybettingBearer'
											placeholder='Bearer'
											value={mySettings.copybettingBearer}
											onChange={onChangeBearerHandler}
										/>
									</FloatingLabel>
									<Button type='submit' variant='dark'>
										{translations.btns.save}
									</Button>
								</Form>
							</div>
						)}
					</>
				)}
			</div>
			<div className='block mb-3'>
				<p className='h5 mb-4'>{translations.settings.usersPopup}</p>
				<div className='mb-3'>
					<Form.Group className='settings__copybetting-widgets' controlId='usersPopup'>
						<div className='settings__copybetting-widget'>
							<Form.Check
								inline
								className='checkbox-black mx-3'
								name='enableLowerUsersModule'
								type='radio'
								label={translations.settings.lowerUsersModule.off}
								id='enableLowerUsersModuleOff'
								onChange={onChangeHandler}
								checked={!mySettings.enableLowerUsersModule}
								value={0}
							/>
							<img src={UsersPopupOffImg} alt='' />
						</div>

						<div className='settings__copybetting-widget'>
							<Form.Check
								inline
								className='checkbox-black mx-3'
								name='enableLowerUsersModule'
								type='radio'
								label={translations.settings.lowerUsersModule.on}
								id='enableLowerUsersModuleOff'
								onChange={onChangeHandler}
								checked={mySettings.enableLowerUsersModule}
								value={1}
							/>
							<img src={UsersPopupOnImg} alt='' />
						</div>
					</Form.Group>
				</div>
			</div>
		</div>
	);
};

export default observer(Controls);

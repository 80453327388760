import useL10n from 'l10n/useL10n';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import {ReactComponent as IcoPin} from 'assets/svg/ico-pin.svg';
import {ReactComponent as IcoUnPin} from 'assets/svg/ico-unpin.svg';
import roomServices from 'store/roomServices';
import messagesServices from 'store/messagesServices';

import useRoom from 'hooks/useRoom';
import {getNextElementInArray, getPreviousElementInArray} from 'utils/helpers';

import ChatPinnedMessageSubmenu from './ChatPinnedMessageSubmenu';

interface IChatPinnedMessageProps {
	getAroundMessages?: (id: number) => Promise<void>;
}

const ChatPinnedMessage: FunctionComponent<IChatPinnedMessageProps> = function ChatPinnedMessage({
	getAroundMessages,
}) {
	const translations = useL10n();

	const [slicedPinnedMessages, setSlicedPinnedMessages] = useState<any[]>([]);
	const {
		currentRoomId,
		pinnedMessages,
		activePinnedMessage,
		submenuMessagePinned,
		setCurrentMessageId,
		setActivePinnedMessage,
	} = useLocalObservable(() => roomServices);

	const {getMessages} = useLocalObservable(() => messagesServices);

	const messagesRefs = useRef<Set<HTMLElement | null>>(new Set([]));
	const activePinnedMessageRef = useRef<any>(null);

	const {unpinMessage} = useRoom();

	const pinnedMessagePaginationItemClasses = (pinnedMessageId: number, index: number) => {
		const pinnedMessageIndex = pinnedMessages.findIndex(
			element => element.id === activePinnedMessage?.id
		);
		return classNames('chat__pinned-message-pagination-item', {
			'chat__pinned-message-pagination-item--active':
				(pinnedMessages.length < 6 && activePinnedMessage?.id === pinnedMessageId) ||
				(pinnedMessages.length > 5 && index === 0 && pinnedMessageIndex === 0) ||
				(pinnedMessages.length > 5 && index === 1 && pinnedMessageIndex === 1) ||
				(pinnedMessages.length > 5 &&
					index === 2 &&
					pinnedMessageIndex <= pinnedMessages.length - 3 &&
					pinnedMessageIndex > 1) ||
				(pinnedMessages.length > 5 &&
					index === 3 &&
					pinnedMessageIndex === pinnedMessages.length - 2) ||
				(pinnedMessages.length > 5 &&
					index === 4 &&
					pinnedMessageIndex === pinnedMessages.length - 1),
		});
	};

	const unpinMessageHandler = () => {
		if (currentRoomId && activePinnedMessage?.id)
			unpinMessage(currentRoomId, activePinnedMessage?.id);
	};

	const clickOnPinnedMessageHandler = async () => {
		if (activePinnedMessage?.id && getAroundMessages) {
			await getAroundMessages(activePinnedMessage.id);
			setCurrentMessageId(activePinnedMessage.id);
			const prevPinnedMessage = getPreviousElementInArray(pinnedMessages, activePinnedMessage.id);
			if (prevPinnedMessage) setActivePinnedMessage(prevPinnedMessage);
			else setActivePinnedMessage(pinnedMessages[pinnedMessages.length - 1]);
		}
	};

	const _observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				const {top, bottom} = entry.boundingClientRect;
				const prevPinnedMessage = getPreviousElementInArray(
					pinnedMessages,
					activePinnedMessageRef.current?.id
				);
				const nextPinnedMessage = getNextElementInArray(
					pinnedMessages,
					activePinnedMessageRef.current?.id
				);

				const currentElement = entry.target as HTMLDivElement;
				const currentElementId = Number(currentElement.getAttribute('data-id'));

				if (entry.isIntersecting) {
					const intersectingElement = entry.target as HTMLDivElement;
					const messageId = Number(intersectingElement.getAttribute('data-id'));
					if (
						messageId !== activePinnedMessageRef.current?.id &&
						messageId > nextPinnedMessage?.id
					) {
						if (bottom > 100) {
							if (nextPinnedMessage) setActivePinnedMessage(nextPinnedMessage);
						}
					}
				} else if (currentElementId <= activePinnedMessageRef.current?.id) {
					if (top > 100) {
						if (prevPinnedMessage) setActivePinnedMessage(prevPinnedMessage);
					}
				}
			});
		},
		{rootMargin: '100px 0px 100px 0px', threshold: 0.1}
	);

	useEffect(() => {
		messagesRefs.current.forEach(messageElement => {
			if (messageElement) _observer.observe(messageElement);
		});

		return () => {
			messagesRefs.current.forEach(messageElement => {
				if (messageElement) _observer.unobserve(messageElement);
			});
		};
	}, [messagesRefs.current.size, pinnedMessages.length]);

	useEffect(() => {
		if (pinnedMessages.length) {
			if (pinnedMessages.length > 5) {
				setSlicedPinnedMessages(pinnedMessages.slice(0, 5));
			} else {
				setSlicedPinnedMessages([...pinnedMessages]);
			}
		}
	}, [pinnedMessages.length]);

	useEffect(() => {
		if (activePinnedMessage) {
			activePinnedMessageRef.current = activePinnedMessage;
			getMessages.forEach(message => {
				const element = document.querySelector(
					`.chat__message[data-id="${message?.id}"]`
				) as HTMLElement;

				if (element) {
					messagesRefs.current.add(element);
				}
			});
		}
	}, [activePinnedMessage, getMessages.length]);

	return (
		<div className='chat__pinned-message'>
			<div className='chat__pinned-message-wrap'>
				<div className='chat__pinned-message-body'>
					<button
						type='button'
						onClick={clickOnPinnedMessageHandler}
						className='chat__pinned-message-scrollbtn'>
						{' '}
					</button>
					{pinnedMessages && pinnedMessages?.length > 1 && (
						<div className='chat__pinned-message-pagination'>
							{slicedPinnedMessages.map((el: any, index: number) => {
								return (
									<span
										key={el.id}
										className={pinnedMessagePaginationItemClasses(el.id, index)}
										style={{
											height: `${
												51 / slicedPinnedMessages.length - 3 / (slicedPinnedMessages.length - 1)
											}rem`,
										}}
									/>
								);
							})}
						</div>
					)}
					<IcoPin className='chat__pinned-message-ico' />
					<div className='chat__pinned-message-content'>
						<span className='chat__pinned-message-label text-secondary'>
							{translations.pinnedMessage}
						</span>

						<p className='chat__pinned-message-text'>{activePinnedMessage?.text}</p>
					</div>
				</div>
				<Button
					variant='light'
					size='sm'
					className='chat__pinned-message-unpin text-danger d-flex'
					onClick={unpinMessageHandler}>
					<span>{translations.btns.unpin}</span>
					<IcoUnPin className='ico-sm' />
				</Button>
			</div>
			{activePinnedMessage && submenuMessagePinned && (
				<ChatPinnedMessageSubmenu message={activePinnedMessage} />
			)}
		</div>
	);
};

export default observer(ChatPinnedMessage);

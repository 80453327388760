/* eslint-disable max-lines */
import {useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import classNames from 'classnames/bind';

import MessageService from 'services/api/MessageService';

import messagesServices from 'store/messagesServices';
import toastService from 'store/toastServices';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';

import {Message} from 'models/room';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import useL10n from 'l10n/useL10n';
import useChatMessage from 'hooks/useChatMessage';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import MessageType from 'models/enums/MessageType';
import useRoom from 'hooks/useRoom';
import modalServices from 'store/modalServices';

type Option = {
	name: string;
	action: () => void;
	type?: string;
};

interface IChatMessageSubmenuProps {
	message: Message;
}

const ChatMessageSubmenu: React.FC<IChatMessageSubmenuProps> = function ChatMessageSubmenu({
	message,
}) {
	const {id, externalRoomId, talker, user} = message;
	const {accessToken, adminData} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {setCurrentEditedMessage, setReactionMessage} = useLocalObservable(() => messagesServices);
	const {currentRoomId, setSubmenuMessage} = useLocalObservable(() => roomServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {reactionsModalVisible, setReactionsModalVisible} = useLocalObservable(() => modalServices);

	const submenuRef = useRef<HTMLDivElement>(null);
	const [submenuVisible, setSubmenuVisible] = useState(false);
	const [options, setOptions] = useState<Option[]>([]);
	const [submenuAboveMessage, setSubmenuAboveMessage] = useState(true);

	const {setMessageFlagged, deleteMessage} = useChatMessage();
	const {pinMessage, unpinMessage} = useRoom();
	const translations = useL10n();

	const roomId = externalRoomId || message.room?.externalRoomId;

	const setSubmenuPosition = () => {
		const subMenuHeight = submenuRef.current ? submenuRef.current.clientHeight : 0;
		const chat = document.querySelector('.chat');
		const chatScrollElement = document.querySelector('.chat__scroll');

		let chatPadding = 0;

		if (chat && getComputedStyle(chat))
			chatPadding =
				parseInt(getComputedStyle(chat).marginTop, 10) +
				parseInt(getComputedStyle(chat).paddingTop, 10);

		const messageElement: HTMLElement | null = document.querySelector(
			`.chat__message[data-id="${id}"]`
		);

		if (!messageElement || !chatScrollElement) {
			setSubmenuAboveMessage(true);
			return;
		}

		const offset = chatScrollElement.scrollTop + subMenuHeight + chatPadding;
		const isAboveMessage = messageElement.offsetTop >= offset;

		setSubmenuAboveMessage(isAboveMessage);
	};

	const showToaster = (responseText: string, responseStatus: string) => {
		addToast({
			title: '',
			text: responseText,
			variant: responseStatus === ResponseStatus.SUCCESS ? 'success' : 'danger',
		});
	};

	const setUserVisibility = async (visible: boolean) => {
		setSubmenuVisible(false);
		setSubmenuMessage(null);
		let responseText = '';
		const response = await MessageService.setUserVisibility(
			message,
			visible,
			accessToken,
			currentRoomId || roomId
		);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = visible
				? translations.toasts.messagesShown
				: translations.toasts.messagesHidden;

			// updateMessagesByTalkerId(talker?.id, visible, message.hiddenAt);
			// eslint-disable-next-line no-use-before-define
			getOptions();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		showToaster(responseText, response.status);
	};

	const showReactionsModal = async () => {
		setReactionMessage(message);
		if (!reactionsModalVisible) {
			setReactionsModalVisible(true);
		}

		setSubmenuVisible(false);
		setSubmenuMessage(null);
	};

	const pinMessageWithService = () => {
		if (currentRoomId) pinMessage(currentRoomId, message.id);
		setSubmenuVisible(false);
		setSubmenuMessage(null);
	};

	const unpinMessageWithService = () => {
		if (currentRoomId) unpinMessage(currentRoomId, message.id);
		setSubmenuVisible(false);
		setSubmenuMessage(null);
	};

	const goToRoom = () => {
		window.open(
			`/room/${encodeURIComponent(externalRoomId || message.room?.externalRoomId)}`,
			'_blank'
		);
		setSubmenuVisible(false);
		setSubmenuMessage(null);
	};

	const setFlagged = (msg: Message) => {
		setSubmenuVisible(false);
		setSubmenuMessage(null);
		setMessageFlagged(msg);
	};

	const setEditedMessage = () => {
		setCurrentEditedMessage(message);
		setSubmenuMessage(null);
		setSubmenuVisible(false);
	};

	const deleteCurrentMessage = () => {
		setSubmenuVisible(false);
		setSubmenuMessage(null);
		showAlert({
			title: translations.alerts.deleteMessage,
			text: '',
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.delete,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
						deleteMessage(message.id);
					},
				},
			],
		});
	};

	const getOptions = () => {
		let defaultOptions: Option[] = [];

		if (user)
			defaultOptions = [
				{
					name: translations.submenu.showReactions,
					action: () => showReactionsModal(),
				},
				{
					name: translations.submenu.hideMessages,
					action: () => setUserVisibility(false),
				},
				{
					name: translations.submenu.showMessages,
					action: () => setUserVisibility(true),
				},
			];

		if (user?.id === adminData?.id && currentRoomId) {
			defaultOptions.unshift({
				name: translations.submenu.deleteMessage,
				action: () => deleteCurrentMessage(),
			});
			defaultOptions.unshift({
				name: translations.submenu.editMessage,
				action: () => setEditedMessage(),
			});
		}

		if (!currentRoomId) {
			defaultOptions.push({
				name: translations.submenu.goToRoom,
				action: () => goToRoom(),
			});
		}

		if (
			currentRoomId &&
			message.type === MessageType.USER &&
			(message.externalRoomId === currentRoomId ||
				message.room?.externalRoomId === currentRoomId) &&
			!currentRoomId.includes('thread_')
		) {
			if (!message.isPinned)
				defaultOptions.unshift({
					name: translations.submenu.pinMessage,
					action: () => pinMessageWithService(),
				});
			else
				defaultOptions.unshift({
					name: translations.submenu.unpinMessage,
					action: () => unpinMessageWithService(),
				});
		}
		if (!message.isFlagged) {
			defaultOptions.push({
				name: translations.submenu.flagMessage,
				action: () => setFlagged(message),
			});
		}

		// defaultOptions.unshift({
		// 	name: !isVisible ? translations.submenu.showMessage : translations.submenu.hideMessage,
		// 	action: !isVisible ? () => setMessageVisibility(true) : () => setMessageVisibility(false),
		// 	type: !isVisible ? 'success' : '',
		// });

		setOptions([...defaultOptions]);
	};

	useEffect(() => {
		if (message) {
			setTimeout(() => {
				setSubmenuPosition();
			}, 100);

			setSubmenuVisible(true);
		}
	}, [message]);

	useEffect(() => {
		setOptions([]);
		getOptions();
	}, [message]);

	const chatMessageSubmenuClasses = classNames('chat__message-submenu', {
		'chat__message-submenu--visible': submenuVisible,
		'chat__message-submenu--top': submenuAboveMessage,
	});

	const chatMessageSubmenuItemClasses = (type?: string) => {
		return classNames('chat__message-submenu-item', {
			'chat__message-submenu-item--danger': type === 'danger',
			'chat__message-submenu-item--success': type === 'success',
		});
	};

	return (
		<div ref={submenuRef} className={chatMessageSubmenuClasses}>
			{options.map((option, index) => {
				return (
					<div key={index} className={chatMessageSubmenuItemClasses(option.type)}>
						<button className='chat__message-submenu-btn' type='button' onClick={option.action}>
							{option.name}
						</button>
					</div>
				);
			})}
		</div>
	);
};

export default observer(ChatMessageSubmenu);

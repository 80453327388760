import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {useHistory} from 'react-router-dom';
import authService from 'store/authService';
import useAdminProxy from 'hooks/useAdminProxy';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import ResponseMessage from 'models/enums/ResponseMessage.enum';
import appService from 'store/appService';
import LanguageTag from 'models/enums/LanguageTag.enum';

interface IStepCompany {
	setCurrentStep: (step: number) => void;
}

const StepCompany: FunctionComponent<IStepCompany> = function StepCompany({setCurrentStep}) {
	const {auth} = useL10n();
	const history = useHistory();
	const {user, setUser} = useLocalObservable(() => authService);
	const {language} = useLocalObservable(() => appService);
	const [validated, setValidated] = useState(false);

	const [error, setError] = useState('');

	const {register} = useAdminProxy();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		let {value} = event.target;
		if (!value.trim().length) {
			value = event.target.value.replace(/\s/g, '');
		}
		setUser({...user, [event.target.name]: value});
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		if (user) {
			const response = await register(user);
			if (response.status === ResponseStatus.ERROR) {
				if (response.data === ResponseMessage.USER_ALREADY_EXIST) {
					if (language === LanguageTag.en) setError(response.data);
					if (language === LanguageTag.ru) setError('Пользователь уже существует');
					return;
				}
			}
			if (response.status === ResponseStatus.SUCCESS) {
				setError('');
				history.push('/register/confirm');
			}
		}
	};

	return (
		<div className='auth__form-content'>
			<div className='auth__personal-info'>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className='mb-4'>
						<Form.Label>{auth.steps.company.name}</Form.Label>
						<Form.Control
							name='projectCompany'
							placeholder={auth.steps.company.name}
							value={user?.projectCompany || ''}
							onChange={onChangeHandler}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-4'>
						<Form.Label>{auth.steps.company.site}</Form.Label>
						<Form.Control
							name='projectSite'
							placeholder={auth.steps.company.site}
							type='url'
							value={user?.projectSite || ''}
							onChange={onChangeHandler}
							required
						/>
					</Form.Group>

					{error && <p className='text-danger text-center mb-2'>{error}</p>}
					<Button
						type='submit'
						variant='dark'
						size='lg'
						className='w-100 text-center'
						disabled={!!error}>
						{auth.btns.next}
					</Button>

					<div className='auth__form-footer'>
						<button type='button' className='btn-link text-info' onClick={() => setCurrentStep(2)}>
							{auth.btns.back}
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default observer(StepCompany);

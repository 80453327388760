import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {NavLink} from 'react-router-dom';
import Moment from 'react-moment';

import {
	Button,
	Image,
	Form,
	Badge,
	Alert,
	Accordion,
	Card,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import {BsChevronDown, BsCheckCircleFill} from 'react-icons/bs';

import userServices from 'store/userServices';
import alertService from 'store/alertServices';
import BanService from 'services/api/BanService';
import toastService from 'store/toastServices';
import appService from 'store/appService';

import {AlertBtnType} from 'models/enums/Alert.enum';
import {Theme} from 'models/enums/Theme.enum';

import translations from 'l10n/translations';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import useReportReasons from 'hooks/useBanReasons';
import {getBanDuration} from 'utils/helpers';

import AutobanReports from './AutobanReports';
import CustomToggle from './CustomToggle';

interface IAutoBannedItem {
	ban: any;
	getBans: () => void;
}

const AutoBannedItem: FunctionComponent<IAutoBannedItem> = function AutoBannedItem({ban, getBans}) {
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {appTheme} = useLocalObservable(() => appService);
	const {getReason} = useReportReasons();

	const [reason, setReason] = useState('');
	const [isApproved, setIsApproved] = useState(false);

	const patchBan = async (data: {description?: string; isApproved?: boolean}) => {
		let responseText = '';
		const response = ban.talker
			? await BanService.patchTalkerBan(accessToken, ban.id, data)
			: await BanService.patchUserBan(accessToken, ban.id, data);

		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.success;
			setIsApproved(true);
			getBans();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;

		addToast({
			title: translations.toasts.banUpdate,
			text: responseText,
		});
	};

	const unBan = async () => {
		patchBan({
			isApproved: true,
		});
		let responseText = '';
		const banData = {
			userId: ban.user?.id,
			value: false,
		};
		const response = await BanService.toggleUserBan(banData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.userUnbanned;
			getBans();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		addToast({
			title: translations.toasts.user(ban.user?.name || ''),
			text: responseText,
		});
	};

	const unBlock = async () => {
		patchBan({
			isApproved: true,
		});
		let responseText = '';
		const banData = {
			talkerId: ban.talker?.id,
			value: false,
			externalRoomId: ban.room?.externalRoomId,
		};
		const response = await BanService.toggleTalkerBan(banData, accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			responseText = translations.toasts.userUnbanned;
			getBans();
		} else if (response.status === ResponseStatus.ERROR) responseText = translations.toasts.error;
		addToast({
			title: translations.toasts.user(ban.talker?.user?.name || ''),
			text: responseText,
		});
	};

	const openAlert = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		showAlert({
			title: translations.toasts.user(ban.talker?.user?.name || ban.user?.name || ''),
			text: translations.alerts.unBan,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.btns.unBan,
					type: AlertBtnType.SUCCESS,
					onClick: () => {
						hideAlert();
						if (!ban.talker) unBan();
						else unBlock();
					},
				},
			],
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setReason(value);
	};

	const onBlurHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
		const {value} = event.target;
		if (value.trim() === '' && !ban.description) return;
		if (value !== ban.description) {
			patchBan({
				description: value,
			});
		}
	};

	const approveBan = async () => {
		patchBan({
			isApproved: true,
		});
	};

	const tooltip = (
		<Tooltip id='tooltip'>
			<small>{translations.btns.approve}</small>
		</Tooltip>
	);

	useEffect(() => {
		setReason(ban.description || '');
	}, [ban]);

	return (
		<Accordion className='mb-2' key={ban.id}>
			<Card>
				<Card.Header className='d-flex autobans-card'>
					<div className='d-flex align-items-center w-100'>
						<div className='col-2 align-middle'>
							<div className='user-info m-2'>
								<div
									className={`user-info__photo background-color-${ban.talker?.user?.color || 0}`}>
									<Image src={ban.talker?.user?.pic || ban.user?.pic} roundedCircle fluid />
								</div>
								<div className='text-truncate'>
									<Badge bg={appTheme.toLowerCase()} pill>
										id{ban.talker?.user?.id || ban.user?.id}
									</Badge>
									<div className='text-truncate'>{ban.talker?.user?.name || ban.user?.name}</div>
								</div>
							</div>
						</div>
						<div className='col-1 align-middle text-center'>
							{ban.talker && ban.room ? (
								<div>
									<Badge pill bg={appTheme.toLowerCase()}>
										{translations.inRoom}
									</Badge>
									<div className='text-truncate'>
										<NavLink to={`/room/${encodeURIComponent(ban.room.externalRoomId)}`}>
											{ban.room.externalRoomId}
										</NavLink>
									</div>
								</div>
							) : (
								<div>
									<Badge pill bg={appTheme.toLowerCase()}>
										{translations.inApp}
									</Badge>
									{ban.user?.banRoom && (
										<div className='text-truncate'>
											<NavLink to={`/room/${encodeURIComponent(ban.user?.banRoom.externalRoomId)}`}>
												{ban.user?.banRoom.externalRoomId}
											</NavLink>
										</div>
									)}
									{ban.room && (
										<div className='text-truncate'>
											<NavLink to={`/room/${encodeURIComponent(ban.room.externalRoomId)}`}>
												{ban.room.externalRoomId}
											</NavLink>
										</div>
									)}
								</div>
							)}
						</div>
						<div className='col-1 text-center text-secondary align-middle'>
							{ban.createdAt && (
								<small>
									<Moment format='DD.MM.YY HH:mm'>{ban.createdAt}</Moment>
								</small>
							)}
						</div>
						<div className='col-1 text-center text-secondary align-middle'>
							{ban.expires && ban.createdAt ? (
								<Badge pill bg='warning'>
									{getBanDuration(ban)}
								</Badge>
							) : (
								!ban.expires && (
									<Badge pill bg='danger'>
										{translations.forever}
									</Badge>
								)
							)}
						</div>
						<div className='col-2 text-center text-secondary align-middle'>
							<div className='small m-2'>
								{getReason(ban.reason) && (
									<Alert variant='warning' className='m-0'>
										{getReason(ban.reason)?.text}
									</Alert>
								)}
							</div>
						</div>
						<div className='col-2 text-center text-secondary'>
							<Form.Group controlId='exampleForm.ControlTextarea1'>
								<Form.Control
									as='textarea'
									rows={5}
									size='sm'
									value={reason}
									onBlur={onBlurHandler}
									onChange={onChangeHandler}
									maxLength={250}
								/>
							</Form.Group>
						</div>
						<div className='col-1 text-center align-middle text-secondary'>
							{translations.table.autoBan}
						</div>
						<div className='col-2 align-middle'>
							<div className='d-flex align-items-center mb-1'>
								<Button variant='outline-success' size='sm' onClick={openAlert}>
									<span>{translations.btns.unBan}</span>
								</Button>
								&nbsp;
								{!ban.isApproved && !isApproved && (
									<OverlayTrigger placement='bottom' overlay={tooltip}>
										<Button variant='success' onClick={approveBan} className='d-flex'>
											<BsCheckCircleFill />
										</Button>
									</OverlayTrigger>
								)}
								&nbsp;
							</div>
						</div>
					</div>
					{ban.reports?.length > 0 && (
						<CustomToggle eventKey='0'>
							<BsChevronDown className={appTheme === Theme.LIGHT ? 'text-dark' : 'text-white'} />
						</CustomToggle>
					)}
				</Card.Header>
				{ban.reports?.length > 0 && (
					<Accordion.Collapse eventKey='0'>
						<Card.Body>
							<AutobanReports reports={ban.reports} user={ban.talker?.user || ban.user} />
						</Card.Body>
					</Accordion.Collapse>
				)}
			</Card>
		</Accordion>
	);
};

export default observer(AutoBannedItem);

/* eslint-disable max-lines */
import {useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

import classNames from 'classnames/bind';
import {Message} from 'models/room';

import useL10n from 'l10n/useL10n';
import roomServices from 'store/roomServices';

type Option = {
	name: string;
	action: () => void;
	type?: string;
};

interface IChatPinnedMessageSubmenuProps {
	message: Message;
}

const ChatPinnedMessageSubmenu: React.FC<IChatPinnedMessageSubmenuProps> =
	function ChatPinnedMessageSubmenu({message}) {
		const {id, room, talker, isVisible} = message;
		const {
			currentRoomId,
			submenuMessagePinned,
			setSubmenuMessagePinned,
			pinnedMessages,
			activePinnedMessage,
		} = useLocalObservable(() => roomServices);

		const submenuRef = useRef<HTMLDivElement>(null);
		const [options, setOptions] = useState<Option[]>([]);

		const translations = useL10n();

		const goToThread = () => {
			if (activePinnedMessage?.thread)
				window.open(`/room/${encodeURIComponent(activePinnedMessage?.thread.name)}`, '_blank');
			setSubmenuMessagePinned(false);
		};

		const getOptions = () => {
			const defaultOptions: Option[] = [];
			if (activePinnedMessage?.isThreadStarter) {
				defaultOptions.push({
					name: translations.submenu.goToThread,
					action: () => goToThread(),
				});
			}
			setOptions([...defaultOptions]);
		};

		useEffect(() => {
			if (pinnedMessages.length) {
				setOptions([]);
				getOptions();
			}
		}, [pinnedMessages]);

		const ChatPinnedMessageSubmenuClasses = classNames('chat__message-submenu', {
			'chat__message-submenu--visible': submenuMessagePinned,
		});

		const ChatPinnedMessageSubmenuItemClasses = (type?: string) => {
			return classNames('chat__message-submenu-item', {
				'chat__message-submenu-item--danger': type === 'danger',
				'chat__message-submenu-item--success': type === 'success',
			});
		};

		return (
			<div ref={submenuRef} className={ChatPinnedMessageSubmenuClasses}>
				{options.map((option, index) => {
					return (
						<div key={index} className={ChatPinnedMessageSubmenuItemClasses(option.type)}>
							<button className='chat__message-submenu-btn' type='button' onClick={option.action}>
								{option.name}
							</button>
						</div>
					);
				})}
			</div>
		);
	};

export default observer(ChatPinnedMessageSubmenu);

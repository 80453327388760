import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {BearerService} from 'services/api/BearerService';
import userServices from 'store/userServices';

export default () => {
	const {accessToken} = useLocalObservable(() => userServices);

	const getBearer = async () => {
		const response = await BearerService.getBearer(accessToken);
		if (response.status === ResponseStatus.SUCCESS) return response.data;
		return [];
	};

	const generateBearer = async () => {
		const response = await BearerService.generateBearer(accessToken);
		if (response.status === ResponseStatus.SUCCESS) return true;
		return false;
	};

	const deleteBearer = async (id: number) => {
		await BearerService.deleteBearer(accessToken, id);
	};

	return {getBearer, deleteBearer, generateBearer};
};

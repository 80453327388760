import {observer} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Form} from 'react-bootstrap';
import {ReactComponent as IcoHide} from 'assets/svg/ico-hide.svg';
import {ReactComponent as IcoShow} from 'assets/svg/ico-show.svg';
import classNames from 'classnames';

interface IPasswordControl {
	label: string;
	name: string;
	value: string;
	onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
	maxLength?: number;
}

const PasswordControl: FunctionComponent<IPasswordControl> = function PasswordControl(props) {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const passwordBtnClasses = classNames('auth__password-input-btn', {
		'auth__password-input-btn--visible': isPasswordVisible,
	});

	const togglePasswordVisibility = () => {
		setIsPasswordVisible(prev => !prev);
	};

	return (
		<Form.Group className='auth__password-control'>
			<Form.Label>{props.label}</Form.Label>
			<Form.Control
				autoComplete='off'
				name={props.name}
				type={isPasswordVisible ? 'text' : 'password'}
				value={props.value}
				onChange={props.onChangeHandler}
				required
				maxLength={props.maxLength}
			/>
			<button type='button' className={passwordBtnClasses} onClick={togglePasswordVisibility}>
				{!isPasswordVisible && <IcoHide />}
				{isPasswordVisible && <IcoShow />}
			</button>
		</Form.Group>
	);
};

export default observer(PasswordControl);

import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Container, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {useHistory} from 'react-router-dom';
import useAdminProxy from 'hooks/useAdminProxy';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import PasswordControl from 'components/password/PasswordControl';

const MIN_PASSWORD_LENGTH = 8;
const SPECIAL_CHARACTERS_REGEX = /[!#$%^&*]/;
type PasswordReqs = {
	hasMinLength: boolean;
	hasCapitalLetter: boolean;
	hasSpecialSymbol: boolean;
	arePasswordsEqual: boolean;
};
const RecoverPassword: FunctionComponent = function RecoverPassword() {
	const {auth} = useL10n();
	const history = useHistory();
	const [validated, setValidated] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');

	const [token, setToken] = useState('');
	const [isPasswordChecked, setIsPasswordChecked] = useState(false);
	const [error, setError] = useState('');

	const [passwordReqs, setPasswordReqs] = useState<PasswordReqs>({
		hasMinLength: false,
		hasCapitalLetter: false,
		hasSpecialSymbol: false,
		arePasswordsEqual: false,
	});

	const {passwordUpdate} = useAdminProxy();

	const validatePasswordConditions = (value: string) => {
		const hasMinLength = value.length >= MIN_PASSWORD_LENGTH;
		const hasCapitalLetter = /[A-Z]/.test(value);
		const hasSpecialSymbol = SPECIAL_CHARACTERS_REGEX.test(value);
		const arePasswordsEqual = repeatPassword === value;

		setPasswordReqs({hasMinLength, hasCapitalLetter, hasSpecialSymbol, arePasswordsEqual});
		setIsPasswordChecked(hasMinLength && hasCapitalLetter && hasSpecialSymbol && arePasswordsEqual);
	};

	const onChangeNewPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setNewPassword(value);
		validatePasswordConditions(value);
	};

	const onChangeRepeatPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setRepeatPassword(value);
		const arePasswordsEqual = newPassword === value;
		setPasswordReqs({...passwordReqs, arePasswordsEqual});
	};

	useEffect(() => {
		const allReqsMet = Object.values(passwordReqs).every(condition => condition === true);

		if (allReqsMet) {
			setIsPasswordChecked(true);
			return;
		}
		setIsPasswordChecked(false);
	}, [passwordReqs]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		if (token && newPassword) {
			const response = await passwordUpdate(token, newPassword);
			if (response.status === ResponseStatus.SUCCESS) {
				history.push('/login');
				return;
			}
			if (response.status === ResponseStatus.ERROR) {
				setError(response.data);
			}
		}
	};

	const confirm = async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const queryToken = urlParams.get('token');

		if (queryToken) {
			setToken(queryToken);
			return;
		}
		history.push('/login');
	};

	useEffect(() => {
		confirm();
	}, []);

	if (!token) return null;

	return (
		<section className='auth'>
			<Container className='m-auto'>
				<div>
					<p className='h3 mb-2 text-center'>{auth.changePassword.title}</p>
					<p className='text-secondary mb-5 text-center'>{auth.changePassword.description}</p>
				</div>
				<div className='auth__form'>
					<div className='auth__form-content'>
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<PasswordControl
								label={auth.steps.password.newPassword}
								onChangeHandler={onChangeNewPasswordHandler}
								value={newPassword}
								name='newPassword'
								maxLength={32}
							/>

							<PasswordControl
								label={auth.steps.password.repeatPassword}
								onChangeHandler={onChangeRepeatPasswordHandler}
								value={repeatPassword}
								name='repeatPassword'
								maxLength={32}
							/>

							<div className='auth__password-reqs'>
								<p className='h5 mb-3'>{auth.steps.password.reqsTitle}</p>
								<ul className='auth__password-reqs-list'>
									<li className={passwordReqs.hasMinLength ? 'success' : ''}>
										{auth.steps.password.reqs[0]}
									</li>
									<li className={passwordReqs.hasCapitalLetter ? 'success' : ''}>
										{auth.steps.password.reqs[1]}
									</li>
									<li className={passwordReqs.hasSpecialSymbol ? 'success' : ''}>
										{auth.steps.password.reqs[2]} <b>(!#$%^&*)</b>
									</li>
									<li className={passwordReqs.arePasswordsEqual ? 'success' : ''}>
										{auth.steps.password.reqs[3]}{' '}
									</li>
								</ul>
							</div>

							{error && <p className='text-danger text-center mt-2'>{error}</p>}

							<Button
								type='submit'
								variant='dark'
								size='lg'
								className='w-100 text-center'
								disabled={!isPasswordChecked}>
								{auth.changePassword.save}
							</Button>
						</Form>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default observer(RecoverPassword);

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Form,
	Tabs,
	Tab,
	Navbar,
	FloatingLabel,
	Button,
	Spinner,
} from 'react-bootstrap';
import userServices from 'store/userServices';
import settingsServices from 'store/settingsServices';
import SettingsService from 'services/api/SettingsService';
import alertServices from 'store/alertServices';
import useSettings from 'hooks/useSettings';
import useL10n from 'l10n/useL10n';
import {AlertBtnType} from 'models/enums/Alert.enum';
import appService from 'store/appService';
import {Theme as AppTheme} from 'models/enums/Theme.enum';

import Theme from './Theme';
import Import from './Import';
import StickerPacks from './stickers/StickerPacks';
import Font from './Font';
import Controls from './Controls';
import Preloader from './Preloader';
import Avatars from './Avatars';
import Reactions from './Reactions';

const AppearanceSettings: FunctionComponent = function AppearanceSettings() {
	const {accessToken} = useLocalObservable(() => userServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {settings, themes, currentThemeId, setCurrentThemeId, tabKey, setTabKey} =
		useLocalObservable(() => settingsServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const {resetSettings} = useSettings();

	const [themeActive, setThemeActive] = useState('LIGHT');
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const translations = useL10n();

	const onThemeSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		const defaultTheme = themes.find(item => item.name === value);
		const responsePathSettings = await SettingsService.patchSettings(accessToken, {
			defaultThemeId: defaultTheme?.id,
		});
		if (responsePathSettings.status === ResponseStatus.SUCCESS) {
			setThemeActive(value);
		}
	};

	const onClickResetHandler = async () => {
		showAlert({
			title: `${translations.settings.reset}?`,
			text: ``,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.alerts.btns.yes,
					type: AlertBtnType.DANGER,
					onClick: async () => {
						hideAlert();
						setVisiblePreloader(true);
						await resetSettings();
					},
				},
			],
		});
	};

	useEffect(() => {
		if (settings.defaultTheme) {
			setThemeActive(settings.defaultTheme.name);
		}
	}, [settings.defaultTheme]);

	useEffect(() => {
		themes.length && tabKey === '' && setTabKey(`${themes[0].id}`);
	}, [themes.length, tabKey]);

	useEffect(() => {
		tabKey && setCurrentThemeId(+tabKey);
	}, [tabKey]);

	return (
		<>
			{visiblePreloader && (
				<div className='preloader'>
					<Spinner animation='border' variant={appTheme === AppTheme.DARK ? 'light' : 'dark'} />
				</div>
			)}
			<Row>
				<Col>
					<Navbar variant='dark' bg='dark' expand='lg' className='mb-5'>
						<Container fluid>
							<Row className='w-100'>
								<Col xs={3}>
									<FloatingLabel
										controlId='floatingSelect'
										label={translations.settings.defaultTheme}>
										<Form.Select onChange={onThemeSelect} value={themeActive}>
											{themes.map(th => (
												<option key={th.id} value={th.name}>
													{th.name}
												</option>
											))}
										</Form.Select>
									</FloatingLabel>
								</Col>
							</Row>
						</Container>
					</Navbar>
				</Col>
			</Row>

			<div className='position-relative pb-2 d-flex justify-content-end'>
				<Button variant='danger' onClick={onClickResetHandler} className='position-absolute'>
					{translations.settings.reset}
				</Button>
			</div>

			<Tabs
				activeKey={tabKey}
				onSelect={(k: any) => setTabKey(k)}
				id='theme-tabs'
				className='nav-tabs mb-3'
				variant='dark'>
				{themes.map(el => {
					return (
						<Tab
							key={el.id}
							eventKey={el.id}
							title={`${el.name} ${translations.settings.theme}`}
							tabClassName='text-success'>
							{el.id === currentThemeId && (
								<div className='w-100'>
									<Theme themeId={el.id} currentTab={tabKey} />
								</div>
							)}
						</Tab>
					);
				})}
				<Tab eventKey='AVATAR' title={translations.settings.avatars} tabClassName='text-secondary'>
					<div className='w-100'>
						<Avatars currentTab={tabKey} />
					</div>
				</Tab>
				<Tab
					eventKey='STICKER'
					title={translations.settings.stickers}
					tabClassName='text-secondary'>
					<div className='w-100'>
						<StickerPacks currentTab={tabKey} />
					</div>
				</Tab>
				<Tab
					eventKey='EMOTION'
					title={translations.settings.reactions}
					tabClassName='text-secondary'>
					<div className='w-100'>
						<Reactions currentTab={tabKey} />
					</div>
				</Tab>
				<Tab eventKey='font' title={translations.settings.font} tabClassName='text-secondary'>
					<div className='w-50'>
						<Font currentTab={tabKey} />
					</div>
				</Tab>
				<Tab
					eventKey='controls'
					title={translations.settings.controls}
					tabClassName='text-secondary'>
					<div className='w-100'>
						<Controls currentTab={tabKey} />
					</div>
				</Tab>
				<Tab eventKey='loader' title={translations.settings.loader} tabClassName='text-secondary'>
					<div className='w-100'>
						<Preloader />
					</div>
				</Tab>
				<Tab eventKey='import' title={translations.settings.import} tabClassName='text-secondary'>
					<div className='w-100'>
						<Import currentTab={tabKey} />
					</div>
				</Tab>
			</Tabs>
		</>
	);
};

export default observer(AppearanceSettings);

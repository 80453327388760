import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import {Popover, OverlayTrigger} from 'react-bootstrap';
import {ReactComponent as IcoClose} from 'assets/svg/ico-close-black.svg';
import {ReactComponent as IcoInfo} from 'assets/svg/ico-info-black.svg';
import {ReactComponent as IcoLearnMore} from 'assets/svg/ico-learn-more.svg';
import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

interface IPageTitle {
	title: string;
	text?: string;
	link?: string;
}
const PageTitle: FunctionComponent<IPageTitle> = function PagePopover({title, text, link}) {
	const translations = useL10n();

	const {appTheme} = useLocalObservable(() => appService);
	const [show, setShow] = useState(false);

	const popover = (
		<Popover id='popover-basic'>
			<Popover.Body>
				<div className='position-absolute top-0 end-0 p-2'>
					<button type='button' onClick={() => setShow(false)}>
						<IcoClose className='ico-sm' />
					</button>
				</div>

				<p className='h5 mb-2'>
					<b className='text-dark'>{title}</b>
				</p>
				{text && <p className={appTheme === Theme.LIGHT ? 'fs-6' : 'fs-6 text-dark'}>{text}</p>}
				{link && (
					<a
						href={link}
						target='_blank'
						rel='noreferrer'
						className='fs-6 d-flex align-items-center gap-1'>
						<IcoLearnMore className='ico-medium' />
						{translations.learnMore}
					</a>
				)}
			</Popover.Body>
		</Popover>
	);

	return (
		<p className='h3 mb-5 d-flex'>
			<span>{title}</span>
			<OverlayTrigger trigger='click' placement='right' overlay={popover} rootClose show={show}>
				<button type='button' className='btn-info mx-2' onClick={() => setShow(true)}>
					<IcoInfo />
				</button>
			</OverlayTrigger>
		</p>
	);
};

export default observer(PageTitle);

import {ToastBody} from 'models/toast';
import {action, computed, makeObservable, observable} from 'mobx';
import {v4 as uuidv4} from 'uuid';

class ToastService {
	@observable
	private _toasts: ToastBody[] = [];

	@action.bound
	addToast = (toast: Omit<ToastBody, 'id'>) => {
		this._toasts = [...this._toasts, {id: uuidv4(), ...toast}];
	};

	@action.bound
	deleteToast = (toast: ToastBody) => {
		this._toasts = this._toasts.filter(t => t.id !== toast.id);
	};

	@action.bound
	hideToasts = () => {
		this._toasts = [];
	};

	@computed
	get toasts() {
		return this._toasts.slice();
	}

	constructor() {
		makeObservable(this);
	}
}
export default new ToastService();

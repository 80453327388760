import React, {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import userServices from 'store/userServices';
import AuthService from 'services/api/AdminProxyService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {useHistory} from 'react-router-dom';
// bootstrap components
import {Container, Image, Button, Form, Col} from 'react-bootstrap';
import Logo from 'assets/images/auth-logo.png';
import {isMobile} from 'react-device-detect';

import useL10n from 'l10n/useL10n';
import UserRole from 'models/enums/UserRole.enum';
import {Admin} from 'models/admin';

import appService from 'store/appService';
import axios from 'axios';
import SelectProject from 'components/selectProject/selectProject';
import useClusterUrls from 'hooks/useClusterUrls';
import {ProjectUser} from 'models/projectUser';
import PasswordControl from 'components/password/PasswordControl';
import RegisterFooter from '../register/footer/RegisterFooter';
import MobileStub from './MobileStub';

const Login: FunctionComponent = function Login() {
	const history = useHistory();
	const translations = useL10n();

	const [loginData, setLoginData] = useState({
		email: '',
		password: '',
	});
	const [responseStatus, setResponseStatus] = useState(true);
	const [validated, setValidated] = useState(false);
	const [activeProjectId, setActiveProjectId] = useState<string | null>(null);
	const [isMobileStub, setIsMobileStub] = useState(false);
	const [hasProject, setHasProject] = useState(false);
	const {setProjectId} = useLocalObservable(() => appService);
	const {setAccessToken, setIsAdmin, projectUsers, setProjectUsers, setIsSuperAdmin, setIsCreator} =
		useLocalObservable(() => userServices);

	const {getClusterBackendUrls} = useClusterUrls();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		const valueTrim = value.trim();
		setLoginData({
			...loginData,
			[event.target.name]: valueTrim,
		});
	};

	const getUserBySelectedProject = (project: string, projectUsersData: ProjectUser[]) => {
		return projectUsersData.find((el: any) => el.projectId === project);
	};

	const setAuth = (token: string) => {
		setAccessToken(token);
		localStorage.setItem('accessToken', token);
	};

	const setSuperAdmin = () => {
		setIsSuperAdmin(true);
		localStorage.setItem('isSuperAdmin', 'true');
	};

	const setCreator = () => {
		setIsCreator(true);
		localStorage.setItem('isCreator', 'true');
	};

	const setAllUsers = (projectUsersData: ProjectUser[]) => {
		setProjectUsers(projectUsersData);
		localStorage.setItem('projectUsers', JSON.stringify(projectUsersData));
	};

	const setUser = (project: string, projectUsersData: ProjectUser[]) => {
		const user: any = getUserBySelectedProject(project, projectUsersData);
		localStorage.setItem('user', JSON.stringify(user));
		setIsAdmin(user?.role === UserRole.ADMIN);
	};

	const setProject = (projectUsersData: ProjectUser[]) => {
		const project = projectUsersData[0].projectId;
		setActiveProjectId(project);
		localStorage.setItem('projectId', project);
	};

	const setUserData = (user: Admin, accessToken: string) => {
		if (!user.projectUsers.length) {
			if (user.isActivated) {
				setAuth(accessToken);
				if (isMobile) {
					setIsMobileStub(true);
					return;
				}
				history.push('/register/project');
				return;
			}
			history.push('/register/confirm');
			return;
		}
		setHasProject(true);
		setAuth(accessToken);
		user.isSuperAdmin && setSuperAdmin();
		user.isCreator && setCreator();
		setAllUsers(user.projectUsers);
		setProject(user.projectUsers);
	};

	const setProjectData = (project: string, projectUsersData: ProjectUser[]) => {
		setUser(project, projectUsersData);
		axios.defaults.headers.common.project = project;
		localStorage.setItem('projectId', project);

		localStorage.setItem(
			'user',
			JSON.stringify(getUserBySelectedProject(project, projectUsersData))
		);
		getClusterBackendUrls(projectUsersData, project);
		setProjectId(project);
	};

	const login = async () => {
		const response = await AuthService.login(loginData);
		if (response.status === ResponseStatus.ERROR) {
			setResponseStatus(false);
			setValidated(false);
		}
		if (response.status === ResponseStatus.SUCCESS) {
			if (isMobile) {
				setIsMobileStub(true);
			}

			setUserData(response.data.user, response.data.accessToken);

			if (response.data.user.projectUsers.length === 1) {
				const project = response.data.user.projectUsers[0];
				setProjectData(project.projectId, response.data.user.projectUsers);
			}
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else await login();
	};

	const loginClickHandler = () => {
		if (activeProjectId) {
			setProjectData(activeProjectId, projectUsers);
		}

		history.push('/last-messages');
	};

	return (
		<section className='auth'>
			{!isMobileStub && (
				<Container as={Col} md='4' className='mt-auto'>
					<p className='h3 mb-5 text-center'>{translations.auth.title}</p>

					<div className='auth__form'>
						{!projectUsers.length ? (
							<Form noValidate validated={validated} onSubmit={handleSubmit}>
								<div className='mb-3'>
									<Form.Group className='mb-4'>
										<Form.Label>Эл. почта</Form.Label>
										<Form.Control
											name='email'
											placeholder='name@example.com'
											required
											value={loginData.email}
											onChange={onChangeHandler}
										/>
									</Form.Group>
									<PasswordControl
										label='Пароль'
										onChangeHandler={onChangeHandler}
										value={loginData.password}
										name='password'
									/>
									{!responseStatus && (
										<p className='text-danger mb-2'>{translations.auth.passwordWarn}</p>
									)}
								</div>

								<Button type='submit' size='lg' variant='dark' className='w-100'>
									{translations.btns.login}
								</Button>
								<div className='auth__form-footer'>
									<p className='auth__form-footer-text'>Забыли пароль?</p>
									<button
										type='button'
										className='btn-link text-info'
										onClick={() => history.push('/recover')}>
										Восстановить
									</button>
								</div>
							</Form>
						) : (
							projectUsers.length > 1 && (
								<div>
									<div className='mb-3'>
										<SelectProject
											projectUsers={projectUsers}
											activeProjectId={activeProjectId}
											setActiveProjectId={setActiveProjectId}
											theme='light'
										/>
									</div>

									<Button
										onClick={() => loginClickHandler()}
										type='button'
										variant='dark'
										size='lg'
										className='w-100'>
										{translations.btns.login}
									</Button>
								</div>
							)
						)}
					</div>
				</Container>
			)}
			{isMobileStub && <MobileStub hasProject={hasProject} />}
			<RegisterFooter />
		</section>
	);
};

export default observer(Login);

import {SIZE_ICON_SVG} from 'constants/constants';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useRef} from 'react';

import {Button, Image} from 'react-bootstrap';
import {BsTrashFill, BsFillPencilFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';

import alertServices from 'store/alertServices';

import {AlertBtnType} from 'models/enums/Alert.enum';

import '../settings.scss';
import {checkImageSize, getFileSize} from 'utils/helpers';
import useSettings from 'hooks/useSettings';
import classNames from 'classnames';
import {NamedPicType} from 'models/enums/NamedPicType.enum';
import settingsServices from 'store/settingsServices';

interface INamedPicProps {
	index: number;
	elem: any;
	name: string;
	id: number;
	payload: string;
	type: string;
	showName?: boolean;
	editModeOnly?: boolean;
	acceptedFileTypes?: string;
	size?: number;
	objectMinSize?: {
		width: number;
		height: number;
	};
}

const NamedPic: FunctionComponent<INamedPicProps> = function NamedPic({
	index,
	elem,
	name,
	id,
	payload,
	type,
	showName,
	editModeOnly,
	acceptedFileTypes,
	size,
	objectMinSize,
}) {
	const translations = useL10n();

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {currentThemeId} = useLocalObservable(() => settingsServices);
	const ref = useRef<HTMLDivElement>(null);
	const inputFileRef = useRef<HTMLInputElement>(null);

	const {patchIcon, deleteNamedPic, patchEmotion} = useSettings();

	const namedPicClasses = classNames('settings__namedPics-item m-3', {
		'settings__namedPics-reaction': type === NamedPicType.EMOTION,
	});

	const setPic = async (pic: File) => {
		// eslint-disable-next-line default-case
		switch (type) {
			case NamedPicType.ICON: {
				currentThemeId && (await patchIcon(currentThemeId, id, pic, payload));
				break;
			}
			case NamedPicType.EMOTION: {
				patchEmotion(id, pic, payload);
				break;
			}
		}

		if (inputFileRef.current) inputFileRef.current.value = '';
	};

	const changePic = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const pic = event.target.files && event.target.files[0];
		if (pic) {
			if (objectMinSize && size) {
				const isPicTypeSvg = pic.type.includes('svg');
				const minObjectWidth = isPicTypeSvg ? SIZE_ICON_SVG : objectMinSize.width;
				const minObjectHeight = isPicTypeSvg ? SIZE_ICON_SVG : objectMinSize.height;
				checkImageSize(pic).then((object: any) => {
					(async () => {
						if (object.height >= minObjectHeight && object.width >= minObjectWidth) {
							if (pic.size < size) {
								if (type === NamedPicType.ICON) {
									setPic(pic);
									return;
								}
								if (object.width / object.height === 1) {
									setPic(pic);
									return;
								}
							}
						}
						showAlert({
							title: translations.imageReqs.imageReqs,
							text: `${translations.imageReqs.imageWeight(
								getFileSize(size)
							)}  ${translations.imageReqs.imageMinSize([minObjectWidth, minObjectHeight])}`,
							buttons: [
								{
									text: translations.alerts.btns.ok,
									type: AlertBtnType.NORMAL,
									onClick: () => {
										hideAlert();
									},
								},
							],
						});
						if (inputFileRef.current) {
							inputFileRef.current.type = '';
							inputFileRef.current.type = 'file';
							inputFileRef.current.value = '';
						}

						// setPic(null);
					})();
				});
			} else setPic(pic);
		}
	};

	const openAlert = () => {
		showAlert({
			title: translations.btns.remove,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deleteNamedPic(type, id);
					},
				},
			],
		});
	};

	return (
		<div ref={ref} className={namedPicClasses}>
			<Image src={elem} className='settings__namedPics-img' />
			{showName && <p className='settings__namedPics-title'>{name}</p>}
			{!editModeOnly ? (
				<Button
					className='settings__namedPics-btn-delete'
					variant='danger'
					size='sm'
					onClick={() => openAlert()}>
					<BsTrashFill />
				</Button>
			) : (
				<label
					className='settings__namedPics-btn-edit'
					htmlFor={`namedPic-${payload ? payload + id : id}`}>
					<BsFillPencilFill />

					<input
						id={`namedPic-${payload ? payload + id : id}`}
						className='settings__namedPics-inputfile'
						type='file'
						ref={inputFileRef}
						onChange={changePic}
						accept={acceptedFileTypes}
					/>
				</label>
			)}
		</div>
	);
};

export default observer(NamedPic);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Table, Spinner, Button, Alert} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import useBearerTokens from 'hooks/useBearerTokens';
import userServices from 'store/userServices';
import {BsTrashFill} from 'react-icons/bs';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

const BearerTokens: FunctionComponent = function BearerTokens() {
	const translations = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {getBearer, deleteBearer, generateBearer} = useBearerTokens();
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [tokens, setTokens] = useState([]);
	const [error, setError] = useState('');

	const getBearerWithServices = async () => {
		setVisiblePreloader(true);
		const data = await getBearer();
		if (data) setTokens(data);
		setVisiblePreloader(false);
	};

	const removeClickHandler = async (id: number) => {
		await deleteBearer(id);
		getBearerWithServices();
	};

	const generateNew = async () => {
		setVisiblePreloader(true);
		const data = await generateBearer();
		if (data) getBearerWithServices();
	};

	useEffect(() => {
		getBearerWithServices();
	}, [accessToken]);

	const renderItem = useCallback(
		(item: any, index: number) => {
			return (
				<tr key={index}>
					<td className='align-middle text-break'>
						{' '}
						<small>{item.bearer}</small>
					</td>
					<td className='align-middle text-center'>
						{' '}
						<Button variant='danger' onClick={() => removeClickHandler(item.id)}>
							<BsTrashFill className='text-white' />
						</Button>
					</td>
				</tr>
			);
		},
		[tokens]
	);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>Bearer tokens</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h3 mb-5'>Bearer tokens</p>
					<div className='block'>
						{visiblePreloader && (
							<div className='text-center'>
								<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
							</div>
						)}

						{tokens.length > 0 && (
							<Table
								responsive
								striped={appTheme === Theme.LIGHT}
								bordered
								hover
								variant={appTheme?.toLowerCase()}
								className='mb-2'>
								<thead>
									<tr>
										<th className='align-middle'> </th>
										<th className='align-middle text-center col-1'>
											<b>delete</b>
										</th>
									</tr>
								</thead>
								<tbody>{tokens.map(renderItem)}</tbody>
							</Table>
						)}

						<Button type='button' variant='dark' onClick={generateNew} disabled={visiblePreloader}>
							{translations.btns.add}
						</Button>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(BearerTokens);

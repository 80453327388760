import PageTitle from 'components/pageTitle/PageTitle';
import useBot from 'hooks/useBot';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Breadcrumb, Col, Container, Row, Table, Image, Button, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {useLocalObservable} from 'mobx-react-lite';
import appService from 'store/appService';
import {BsFillPencilFill, BsTrashFill} from 'react-icons/bs';
import modalServices from 'store/modalServices';
import CreateBotModal from 'components/modals/createBot/CreateBotModal';
import {Theme} from 'models/enums/Theme.enum';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';

const Bot: FunctionComponent = function Bot() {
	const translations = useL10n();
	const [bots, setBots] = useState([]);
	const [editedBot, setEditedBot] = useState<GetBot | null>(null);
	const [mode, setMode] = useState('create');
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const {appTheme} = useLocalObservable(() => appService);
	const {setBotModalVisible} = useLocalObservable(() => modalServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const {getBot, deleteBot} = useBot();

	const getBotsWithService = async () => {
		setVisiblePreloader(true);
		const response = await getBot();
		if (response) {
			setBots(response);
		}
		setVisiblePreloader(false);
	};

	const onEditBtnClickHandler = (bot: GetBot) => {
		setMode('edit');
		setEditedBot(bot);
		setBotModalVisible(true);
	};

	const deleteBotItem = async (id: number) => {
		const response = await deleteBot(id);
		if (response) {
			getBotsWithService();
		}
	};

	const onDeleteBtnClickHandler = async (id: number) => {
		showAlert({
			title: translations.btns.remove,
			text: `${translations.btns.remove}?`,
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					type: AlertBtnType.DANGER,
					text: translations.btns.remove,
					onClick: () => {
						hideAlert();
						deleteBotItem(id);
					},
				},
			],
		});
	};

	const onCreateBtnClickHandler = () => {
		setMode('create');
		setBotModalVisible(true);
	};

	useEffect(() => {
		getBotsWithService();
	}, []);

	const renderBot = useCallback(
		(item: any, index: any) => {
			return (
				<tr>
					<td className='align-middle'>
						<div className={`user-info__photo background-color-${item.color}`}>
							<Image src={item.pic} roundedCircle fluid />
						</div>
					</td>
					<td className='align-middle'>
						<div className='text-truncate'>
							<p className='user-info__name text-truncate'>{item.name}</p>
						</div>
					</td>
					<td className='align-middle'>
						<div className='text-truncate'>{item.externalId}</div>
					</td>

					<td className='text-secondary'>
						<div className='d-flex align-items-center justify-content-end flex-wrap'>
							<Button
								variant='light'
								className='d-flex'
								onClick={() => onEditBtnClickHandler(item)}>
								<BsFillPencilFill className='text-secondary' />
							</Button>
							<Button
								variant='danger'
								className='d-flex mx-2'
								onClick={() => onDeleteBtnClickHandler(item.id)}>
								<BsTrashFill className='text-white' />
							</Button>
						</div>
					</td>
				</tr>
			);
		},
		[bots]
	);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.chatBot.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.chatBot.title}
						text={translations.sidebar.chatBot.descr}
					/>

					<div className='block w-50'>
						<Button variant='dark' className='d-flex mb-4' onClick={onCreateBtnClickHandler}>
							{translations.btns.create}
						</Button>
						{visiblePreloader && (
							<div>
								<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
							</div>
						)}
						<Table responsive bordered hover variant={appTheme?.toLowerCase()}>
							<thead>
								<tr>
									<th className='align-middle'>Avatar</th>
									<th className='align-middle'>Nickname</th>
									<th className='align-middle'>ExternalId</th>
									<th> </th>
								</tr>
							</thead>
							<tbody>{bots.map(renderBot)}</tbody>
						</Table>
					</div>
				</Col>
			</Row>
			<CreateBotModal onCloseSuccess={getBotsWithService} mode={mode} editedBot={editedBot} />
		</Container>
	);
};

export default Bot;

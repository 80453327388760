import Custom1Img from 'assets/images/highlights/custom_1.jpg';
import Custom2Img from 'assets/images/highlights/custom_2.jpg';
import Custom3Img from 'assets/images/highlights/custom_3.jpg';
import Custom4Img from 'assets/images/highlights/custom_4.jpg';
import CustomDefaultImg from 'assets/images/highlights/custom_default.png';
import Custom4DefaultImg from 'assets/images/highlights/custom4_default.jpg';

export const highlightTemplates = [
	{
		id: 0,
		template: 0,
		pic: Custom3Img,
		imageDefault: CustomDefaultImg,
	},
	{
		id: 1,
		template: 1,
		pic: Custom2Img,
		imageDefault: CustomDefaultImg,
	},
	{
		id: 2,
		template: 2,
		pic: Custom1Img,
		imageDefault: CustomDefaultImg,
	},
	{
		id: 3,
		template: 11,
		pic: Custom4Img,
		imageDefault: Custom4DefaultImg,
	},
];

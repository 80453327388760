import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Button, Form, InputGroup, Modal, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import useRoom from 'hooks/useRoom';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import {BsFillArrowLeftCircleFill, BsFillEyeFill} from 'react-icons/bs';
import {ReactComponent as IcoCopy} from 'assets/svg/ico-copy.svg';

const AgoraServerURLs = {
	DEFAULT: 'rtmp://rtls-ingress-prod-eu.agoramdn.com/live',
	US: 'rtmp://rtls-ingress-prod-na.agoramdn.com/live',
	HK: 'rtmp://rtls-ingress-prod-ap.agoramdn.com/live',
};

const StreamSettingsModal: FunctionComponent = function StreamSettingsModal() {
	const translations = useL10n();
	const {streamSettingsModal, setStreamSettingsModal, hideRoomTalkersModal, showRoomTalkersModal} =
		useLocalObservable(() => modalServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {addToast} = useLocalObservable(() => toastServices);
	const {getStreamKey} = useRoom();

	const [visibleLoader, setVisibleLoader] = useState(true);
	const [streamKey, setStreamKey] = useState('');
	const [inputType, setInputType] = useState('password');
	const [serverUrl, setServerUrl] = useState('');

	const hidestreamSettingsModal = () => {
		setStreamSettingsModal({visible: false, userId: null, externalRoomId: null});
		setInputType('password');
	};

	const onShow = async () => {
		hideRoomTalkersModal();
		if (streamSettingsModal.externalRoomId && streamSettingsModal.userId) {
			const response = await getStreamKey(
				streamSettingsModal.externalRoomId,
				streamSettingsModal.userId
			);
			if (response.status === ResponseStatus.SUCCESS && response.data?.streamKey) {
				setStreamKey(response.data.streamKey);
				setVisibleLoader(false);
				return;
			}
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'danger',
			});
		}
	};

	const onHide = () => {
		hidestreamSettingsModal();
	};

	const goBackHandler = () => {
		showRoomTalkersModal();
		hidestreamSettingsModal();
	};

	const getAgoraServerLink = (url: string) => {
		if (url.includes('chatadmin.us.watchers')) {
			return AgoraServerURLs.US;
		}
		if (url.includes('chatadmin.hk.watchers')) {
			return AgoraServerURLs.HK;
		}
		return AgoraServerURLs.DEFAULT;
	};

	useEffect(() => {
		setServerUrl(getAgoraServerLink(window.location.href));
	}, []);

	const handleCopy = (value: string) => {
		navigator.clipboard
			.writeText(value)
			.then(() => {
				addToast({
					title: translations.toasts.success,
					text: translations.toasts.textCopied,
					variant: 'success',
				});
			})
			.catch(error => {
				addToast({
					title: translations.toasts.error,
					text: translations.toasts.error,
					variant: 'danger',
				});
			});
	};

	return (
		<Modal
			className='modal--stream-settings'
			show={streamSettingsModal.visible}
			onShow={onShow}
			onHide={onHide}
			animation={false}
			centered
			restoreFocus={false}>
			<div className='modal-head'>
				<Modal.Header closeButton className='mb-0 pb-0'>
					<Modal.Title className='mb-0'> {translations.modals.streamSettings.title} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className='text-secondary mb-4'>{translations.modals.streamSettings.description}</p>

					{visibleLoader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{!visibleLoader && (
						<div className='mb-4'>
							<Form.Label className='text-muted'>
								{translations.modals.streamSettings.streamKey}
							</Form.Label>
							<InputGroup className='mb-3'>
								<Form.Control value={streamKey} disabled type={inputType} />
								<InputGroup.Text id='basic-addon1'>
									<button type='button' onClick={() => handleCopy(streamKey)}>
										<IcoCopy className='ico-medium' />
									</button>
								</InputGroup.Text>
							</InputGroup>

							<Form.Label className='text-muted'>Server</Form.Label>
							<InputGroup className='mb-3'>
								<Form.Control value={serverUrl} disabled type='text' />
								<InputGroup.Text id='basic-addon1'>
									<button type='button' onClick={() => handleCopy(serverUrl)}>
										<IcoCopy className='ico-medium' />
									</button>
								</InputGroup.Text>
							</InputGroup>

							{inputType !== 'text' && (
								<Button
									size='sm'
									variant={appTheme?.toLowerCase()}
									onClick={() => setInputType('text')}>
									<BsFillEyeFill className='text-success' />
									<span className='mx-2 text-success'>
										{translations.modals.streamSettings.showStreamKey}
									</span>
								</Button>
							)}
						</div>
					)}
					<div className='d-flex'>
						<Button
							variant='outline-secondary'
							className='d-flex align-items-center'
							onClick={goBackHandler}>
							<BsFillArrowLeftCircleFill
								className={appTheme === Theme.LIGHT ? 'text-dark mx-1' : 'text-white mx-1'}
							/>
							<span className={appTheme === Theme.LIGHT ? 'text-dark' : 'text-white'}>
								{translations.btns.back}
							</span>
						</Button>
						<Button variant='dark' className='mx-2' onClick={onHide}>
							{translations.btns.ok}
						</Button>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default observer(StreamSettingsModal);

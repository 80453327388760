/* eslint-disable max-lines */
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Chatbot} from 'models/settings';
import {Welcome} from 'models/welcome';
import checkResponse from 'utils/checkResponse';

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';

type GreetingData = {
	id?: number;
	title?: string;
	text?: string;
	lang: string;
};

export default class SettingsService {
	static settingsServiceBaseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		SettingsService.settingsServiceBaseUrl = baseUrl;
	};

	static getSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchSettings = async (token: UUID, settings: any) => {
		try {
			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings`,
				settings,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getGlobalSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/global`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchGlobalSettings = async (token: UUID, settings: any) => {
		try {
			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/global`,
				settings,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getThemes = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/theme`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTheme = async (token: UUID, themeId: number) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/theme/${themeId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getColors = async (token: UUID, themeId: number) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/colors/${themeId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setColors = async (token: UUID, themeId: number, colors: string) => {
		try {
			const body = {
				colors,
			};
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/colors/${themeId}`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getFont = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/font`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadFont = async (token: UUID, fonts: {name: string; file: File}[], name: string) => {
		try {
			const formData = new FormData();
			formData.append('name', name);

			fonts.forEach(el => {
				formData.append(el.name, el.file);
			});

			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/font`,
				formData,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteFont = async (token: UUID) => {
		try {
			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}/admin/font`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getEmotions = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/emotion`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getAvatars = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/avatar`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getIcons = async (token: UUID, themeId: number) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/namedPic/${themeId}/icon`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addNamedPic = async (token: UUID, type: string, pic: File, payload?: string) => {
		try {
			const formData = new FormData();
			payload ? formData.append(payload, pic) : formData.append('pic', pic);
			formData.append('name', pic.name);

			const {data, status} = await axios.post(
				`${
					SettingsService.settingsServiceBaseUrl
				}/admin/settings/namedPic/${type.toLocaleLowerCase()}`,
				formData,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchNamedPic = async (
		token: UUID,
		type: string,
		id: number,
		pic: File,
		payload?: string,
		themeId?: number
	) => {
		try {
			const formData = new FormData();
			payload ? formData.append(payload, pic) : formData.append('pic', pic);
			themeId && formData.append('themeId', `${themeId}`);

			const {data, status} = await axios.patch(
				`${
					SettingsService.settingsServiceBaseUrl
				}/admin/settings/namedPic/${type.toLocaleLowerCase()}/${id}`,
				formData,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteNamedPic = async (token: UUID, type: string, id: number) => {
		try {
			const {data, status} = await axios.delete(
				`${
					SettingsService.settingsServiceBaseUrl
				}/admin/settings/namedPic/${type.toLocaleLowerCase()}/${id}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchLoader = async (token: UUID, file: File) => {
		try {
			const formData = new FormData();
			formData.append('loader', file);

			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings`,
				formData,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static exportSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/export`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static importSettings = async (token: UUID, settings: any) => {
		try {
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/import`,
				settings,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static resetSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/reset`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static initSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/init`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(error.response.data.message);
		}
	};

	static getGreeting = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/greeting`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postGreeting = async (token: UUID, greeting: GreetingData) => {
		try {
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/greeting`,
				greeting,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchGreeting = async (token: UUID, id: number, greeting: GreetingData) => {
		try {
			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}/admin/greeting/${id}`,
				greeting,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteGreeting = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}/admin/greeting/${id}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getChatbotSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/chatbot`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getChatbotModels = async (token: UUID, apikey: string) => {
		try {
			const {data, status} = await axios.get(`/admin/openai/models/${apikey}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchChatbotSettings = async (token: UUID, settings: Chatbot) => {
		try {
			const formData = new FormData();
			// eslint-disable-next-line array-callback-return
			Object.entries(settings).map(([key, value]) => {
				key !== 'pic' && formData.append(key, `${value}`);
			});
			formData.append('pic', settings.pic);

			const {data, status} = await axios.patch(`/admin/chatbot`, formData, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTolokaUsage = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/toloka/usage`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getDefaultSettings = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/default`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getProjects = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/settings/projects`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getApiKey = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/apiKey`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRoomStatus = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/roomStatus`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postRoomStatus = async (token: UUID, value: any) => {
		try {
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/roomStatus`,
				value,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static patchRoomStatus = async (token: UUID, value: any) => {
		try {
			const apiUrl = `/admin/roomStatus/${value.id}`;
			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}${apiUrl}`,
				value,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteRoomStatus = async (token: UUID, id: number) => {
		try {
			const apiUrl = `/admin/roomStatus/${id}`;
			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}${apiUrl}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRoomStatusDefault = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/roomStatus/default`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStickerPacks = async (token: string) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/sticker/pack`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStickerPackById = async (token: string, id: number) => {
		try {
			const apiUrl = `/admin/sticker/pack/${id}`;
			const {data, status} = await axios.get(`${SettingsService.settingsServiceBaseUrl}${apiUrl}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addStickerPack = async (token: string, name: string) => {
		try {
			const body = {
				name,
			};
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/sticker/pack`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateStickerPack = async (
		token: string,
		value: {
			id: number;
			name: string;
			isEnabled?: boolean;
		}
	) => {
		try {
			const apiUrl = `/admin/sticker/pack/${value.id}`;
			const body: any = {};
			if (value.name) body.name = value.name;
			if (typeof value.isEnabled !== undefined) body.isEnabled = value.isEnabled;

			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}${apiUrl}`,
				body,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteStickerPack = async (token: string, stickerPackId: number) => {
		try {
			const apiUrl = `/admin/sticker/pack/${stickerPackId}`;

			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}${apiUrl}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addSticker = async (
		token: string,
		pics: {file: File; emojis?: string[]}[] | null,
		stickerPackId?: number
	) => {
		try {
			const formData = new FormData();
			if (pics?.length) {
				let picEmojis = {};
				pics.forEach(pic => {
					formData.append('pic', pic.file, pic.file.name);
					if (pic.emojis) {
						picEmojis = {...picEmojis, [pic.file.name]: pic.emojis};
					}
				});
				formData.append('emojis', JSON.stringify(picEmojis));
				if (stickerPackId) formData.append('stickerPackId', `${stickerPackId}`);
			}

			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/sticker`,
				formData,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error: any) {
			return generateErrorResponseObj(
				// eslint-disable-next-line no-nested-ternary
				error.response?.data.message
					? error.response.data.message
					: error.message
					? error.message
					: null
			);
		}
	};

	static deleteSticker = async (token: string, id: number) => {
		try {
			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}/admin/sticker/${id}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateSticker = async (token: string, stickerData: any) => {
		try {
			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}/admin/sticker/${stickerData.id || 0}`,
				{emojis: stickerData.emojis},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getWelcome = async (token: string, lang: string) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/welcome/${lang}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static postWelcome = async (token: string, body: Welcome) => {
		try {
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/welcome`,
				body,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteWelcome = async (token: string, lang: string) => {
		try {
			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}/admin/welcome/${lang}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRules = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`${SettingsService.settingsServiceBaseUrl}/admin/rules`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static addRules = async (
		token: UUID,
		value: {
			rulesHTML?: string;
			policyHTML?: string;
			lang: string;
		}
	) => {
		try {
			const {data, status} = await axios.post(
				`${SettingsService.settingsServiceBaseUrl}/admin/rules`,
				value,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateRules = async (
		token: UUID,
		id: number,
		value: {
			rulesHTML?: string;
			policyHTML?: string;
			lang: string;
		}
	) => {
		try {
			const {data, status} = await axios.patch(
				`${SettingsService.settingsServiceBaseUrl}/admin/rules/${id}`,
				value,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteRules = async (token: UUID, id: number) => {
		try {
			const {data, status} = await axios.delete(
				`${SettingsService.settingsServiceBaseUrl}/admin/rules/${id}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

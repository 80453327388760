import {observer} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import useL10n from 'l10n/useL10n';
import {ReactComponent as IcoDesktop} from 'assets/svg/ico-desktop.svg';
import {Button, Container, Image} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

interface IStepMobile {
	hasProject: boolean;
}

const StepMobile: FunctionComponent<IStepMobile> = function StepMobile({hasProject}) {
	const {auth} = useL10n();
	const history = useHistory();

	const goToSiteClickHandler = () => {
		// window.open('https://watchers.io/', '_blank');
		window.open('https://watchers.io/', '_self');
	};

	const continueClickHandler = () => {
		if (hasProject) {
			window.location.reload();
			return;
		}
		history.push('/register/project');
	};

	return (
		<section className='auth'>
			<Container className='mt-auto'>
				<div className='auth__start'>
					<div className='auth__form'>
						<div className='auth__mobile-stub'>
							<IcoDesktop className='auth__mobile-stub-ico' />
							<p>
								<b className='h5' dangerouslySetInnerHTML={{__html: auth.mobileStub.title}} />
							</p>
							<p className='text-secondary'>{auth.mobileStub.description}</p>
							<div>
								<Button variant='dark' className='w-100 mb-2' onClick={continueClickHandler}>
									{auth.btns.next}
								</Button>
								<Button variant='outline-dark' className='w-100' onClick={goToSiteClickHandler}>
									{auth.btns.backToSite}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default observer(StepMobile);

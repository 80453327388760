/* eslint-disable no-param-reassign */
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';

import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';

export default class AntimatService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		AntimatService.baseUrl = baseUrl;
	};

	static getVersions = async (type: string, token: UUID, projectId: string, lang?: string) => {
		try {
			const apiUrl = lang ? `/admin/${type}/version?lang=${lang}` : `/admin/${type}/version`;
			const {data, status} = await axios.get(`${AntimatService.baseUrl}${apiUrl}`, {
				transformRequest: (requestData, headers) => {
					if (headers) headers.project = projectId;
				},
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getWordList = async (type: string, token: UUID, projectId: string, lang?: string) => {
		try {
			const apiUrl = lang ? `/admin/${type}?lang=${lang}` : `/admin/${type}`;
			const {data, status} = await axios.get(`${AntimatService.baseUrl}${apiUrl}`, {
				transformRequest: (requestData, headers) => {
					if (headers) headers.project = projectId;
				},
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getVersionList = async (id: string, token: UUID, projectId: string, lang?: string) => {
		try {
			const apiUrl = lang ? `/admin/wordlist/${id}?lang=${lang}` : `/admin/wordlist/${id}`;
			const {data, status} = await axios.get(`${AntimatService.baseUrl}${apiUrl}`, {
				transformRequest: (requestData, headers) => {
					if (headers) headers.project = projectId;
				},
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static updateWordList = async (
		type: string,
		words: string,
		token: UUID,
		projectId: string,
		lang?: string
	) => {
		try {
			const apiUrl = lang ? `/admin/${type}?lang=${lang}` : `/admin/${type}`;

			const body = {
				words,
			};

			const {data, status} = await axios.post(`${AntimatService.baseUrl}${apiUrl}`, body, {
				transformRequest: (requestData, headers) => {
					if (headers) headers.project = projectId;
					requestData = {
						words,
					};
					return JSON.stringify(requestData);
				},
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json; charset=utf-8',
					Accept: 'application/json',
				},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

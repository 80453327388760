import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import {ShowFrequency} from 'models/enums/ShowEntry.enum';
import settingsServices from 'store/settingsServices';

interface IWelcomeFrequency {
	mySettings: {
		welcomeShowOnce?: boolean;
		welcomeDelay?: number;
		welcomeShowFrequency?: ShowFrequency;
		welcomeIsDelayed?: boolean;
	};
	setMySettings: (value: any) => void;
}

const WelcomeFrequency: FunctionComponent<IWelcomeFrequency> = function WelcomeFrequency({
	mySettings,
	setMySettings,
}) {
	const translations = useL10n();

	const [delay, setDelay] = useState(0);

	const {settings} = useLocalObservable(() => settingsServices);

	const onChangeShowEntryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			welcomeShowOnce: value === 'showOnce',
		});
	};

	const onChangeFrequencyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			welcomeShowFrequency:
				value === ShowFrequency.DAILY ? ShowFrequency.DAILY : ShowFrequency.ON_ENTRY,
		});
	};

	const onChangeDelayHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setMySettings({
			...mySettings,
			welcomeIsDelayed: value === 'withDelay',
		});
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		setDelay(+value);
	};

	useEffect(() => {
		setMySettings({
			...mySettings,
			welcomeDelay: delay,
		});
	}, [delay]);

	useEffect(() => {
		if (settings) {
			setMySettings({
				welcomeShowOnce: settings?.welcomeShowOnce,
				welcomeShowFrequency: settings?.welcomeShowFrequency,
				welcomeIsDelayed: settings?.welcomeIsDelayed,
				welcomeDelay: settings?.welcomeDelay,
			});
			setDelay(settings.welcomeDelay || 0);
			if (settings.welcomeIsDelayed && !delay) setDelay(5000);
		}
	}, [settings]);

	return (
		<section className='welcome__content'>
			<div className='block col-6 mb-5'>
				<div>
					<p className='h5'>{translations.welcome.steps.showEntry.showRepeat}</p>
					<p className='text-secondary'>{translations.welcome.steps.showEntry.showRepeatDescr}</p>
					<Form.Group controlId='showEntry'>
						<div>
							<Form.Check
								inline
								name='showEntry'
								type='radio'
								label={translations.welcome.steps.showEntry.showOnce}
								id='showEntry1'
								value='showOnce'
								required
								onChange={onChangeShowEntryHandler}
								checked={mySettings.welcomeShowOnce}
								className='checkbox-black'
							/>
						</div>
						<div>
							<Form.Check
								inline
								name='showEntry'
								type='radio'
								label={translations.welcome.steps.showEntry.showTimes}
								id='showEntry2'
								value='showTimes'
								required
								onChange={onChangeShowEntryHandler}
								checked={!mySettings.welcomeShowOnce}
								className='checkbox-black'
							/>
						</div>
					</Form.Group>
				</div>
				{!mySettings.welcomeShowOnce && (
					<div className='mt-5'>
						<div className='mb-5'>
							<p className='h5'>{translations.welcome.steps.frequency.title}</p>
							<p className='text-secondary'>{translations.welcome.steps.frequency.descr}</p>
							<Form.Group className='mb-3' controlId='frequency'>
								<div>
									<Form.Check
										inline
										name='frequency'
										type='radio'
										label={translations.welcome.steps.frequency.daily}
										id='frequency1'
										value={ShowFrequency.DAILY}
										required
										onChange={onChangeFrequencyHandler}
										checked={mySettings.welcomeShowFrequency === ShowFrequency.DAILY}
										className='checkbox-black'
									/>
								</div>
								<div>
									<Form.Check
										inline
										name='frequency'
										type='radio'
										label={translations.welcome.steps.frequency.onEntry}
										id='frequency2'
										value={ShowFrequency.ON_ENTRY}
										required
										onChange={onChangeFrequencyHandler}
										checked={mySettings.welcomeShowFrequency === ShowFrequency.ON_ENTRY}
										className='checkbox-black'
									/>
								</div>
							</Form.Group>
						</div>
						<div className='mb-5'>
							<p className='h5'>{translations.welcome.steps.moment.title}</p>
							<p className='text-secondary'>{translations.welcome.steps.moment.descr}</p>
							<Form.Group className='mb-3' controlId='delay'>
								<div>
									<Form.Check
										inline
										name='delay'
										type='radio'
										label={translations.welcome.steps.moment.onEntry}
										id='delay1'
										value='onEntry'
										required
										onChange={onChangeDelayHandler}
										checked={!mySettings.welcomeIsDelayed}
										className='checkbox-black'
									/>
								</div>
								<div className='mb-3'>
									<Form.Check
										inline
										name='delay'
										type='radio'
										label={translations.welcome.steps.moment.withDelay}
										id='delay2'
										value='withDelay'
										required
										onChange={onChangeDelayHandler}
										checked={mySettings.welcomeIsDelayed}
										className='checkbox-black'
									/>
								</div>
								{mySettings.welcomeIsDelayed && (
									<div className='col-3'>
										<p className='text-secondary'>{translations.welcome.steps.moment.showDelay}</p>
										<Form.Select onChange={onSelect} value={delay}>
											<option value='5000'>5{translations.sec}</option>
											<option value='10000'>10{translations.sec}</option>
											<option value='15000'>15{translations.sec}</option>
										</Form.Select>
									</div>
								)}
							</Form.Group>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default observer(WelcomeFrequency);

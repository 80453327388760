import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Container, Row, Col, Breadcrumb, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import useSettings from 'hooks/useSettings';
import {S3ProviderType} from 'models/enums/S3ProviderType.enum';

const S3Provider: FunctionComponent = function S3Provider() {
	const translations = useL10n();

	const [s3Provider, setS3Provider] = useState<S3ProviderType>();
	const {settings} = useLocalObservable(() => settingsServices);
	const {patchSettings} = useSettings();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		const s3 = value === S3ProviderType.AWS ? S3ProviderType.AWS : S3ProviderType.Yandex;
		setS3Provider(s3);
		patchSettings({
			s3Provider: s3,
		});
	};

	useEffect(() => {
		settings?.s3Provider && setS3Provider(settings?.s3Provider);
	}, [settings]);

	return (
		<Container fluid className='settings pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>S3Provider</Breadcrumb.Item>
					</Breadcrumb>

					<p className='h3 mb-5'>S3Provider</p>
					<div className='block w-50'>
						<Form.Group controlId='s3ProviderType'>
							<Form.Check
								inline
								className='checkbox-black'
								name='s3Provider'
								type='radio'
								label={S3ProviderType.AWS}
								id='s3Provider1'
								value={S3ProviderType.AWS}
								required
								onChange={onChangeHandler}
								checked={s3Provider === S3ProviderType.AWS}
							/>
							<Form.Check
								inline
								className='checkbox-black'
								name='s3Provider'
								type='radio'
								label={S3ProviderType.Yandex}
								id='s3Provider2'
								value={S3ProviderType.Yandex}
								required
								onChange={onChangeHandler}
								checked={s3Provider === S3ProviderType.Yandex}
							/>
						</Form.Group>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(S3Provider);

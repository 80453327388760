import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';

import AdminProxyService from 'services/api/AdminProxyService';

import useL10n from 'l10n/useL10n';
import {AuthUser} from 'models/auth';
import {ProjectUser} from 'models/projectUser';
import UserRole from 'models/enums/UserRole.enum';
import appService from 'store/appService';
import axios from 'axios';
import useClusterUrls from './useClusterUrls';

export default () => {
	const translations = useL10n();

	const {accessToken, setProjectUsers, setIsSuperAdmin, setIsCreator, setIsAdmin, setAdmin} =
		useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {setProjectId} = useLocalObservable(() => appService);

	const {getClusterBackendUrls} = useClusterUrls();

	const register = async (authUser: AuthUser) => {
		const response = await AdminProxyService.register(authUser);
		return response;
	};

	const authConfirm = async (token: string) => {
		const response = await AdminProxyService.authConfirm(token);
		return response;
	};

	const passwordReset = async (email: string) => {
		const response = await AdminProxyService.passwordReset(email);
		return response;
	};

	const passwordUpdate = async (token: string, password: string) => {
		const response = await AdminProxyService.passwordUpdate(token, password);
		return response;
	};

	const setSuperAdmin = () => {
		setIsSuperAdmin(true);
		localStorage.setItem('isSuperAdmin', 'true');
	};

	const setCreator = () => {
		setIsCreator(true);
		localStorage.setItem('isCreator', 'true');
	};

	const setProject = (projectUsersData: ProjectUser[]) => {
		const project = projectUsersData[0].projectId;
		localStorage.setItem('projectId', project);
	};

	const getUserBySelectedProject = (project: string, projectUsersData: ProjectUser[]) => {
		return projectUsersData.find((el: any) => el.projectId === project);
	};

	const setAllUsers = (projectUsersData: ProjectUser[]) => {
		setProjectUsers(projectUsersData);
		localStorage.setItem('projectUsers', JSON.stringify(projectUsersData));
	};

	const setUser = (project: string, projectUsersData: ProjectUser[]) => {
		const user: any = getUserBySelectedProject(project, projectUsersData);
		localStorage.setItem('user', JSON.stringify(user));
		setIsAdmin(user?.role === UserRole.ADMIN);
	};

	const setProjectData = (project: string, projectUsersData: ProjectUser[]) => {
		setProjectId(project);
		setUser(project, projectUsersData);
		axios.defaults.headers.common.project = project;
		localStorage.setItem('projectId', project);

		localStorage.setItem(
			'user',
			JSON.stringify(getUserBySelectedProject(project, projectUsersData))
		);
		getClusterBackendUrls(projectUsersData, project);
	};

	const getAllProjectUsers = async () => {
		const response = await AdminProxyService.self(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setAllUsers(response.data.projectUsers);
			setProject(response.data.projectUsers);
			setUser(response.data.projectUsers[0].projectId, response.data.projectUsers);
			response.data.isSuperAdmin && setSuperAdmin();
			response.data.isCreator && setCreator();
			const project = response.data.projectUsers[0];
			setProjectData(project.projectId, response.data.projectUsers);
		}
		return response;
	};

	const getAdmins = async () => {
		const response = await AdminProxyService.getAdmins(accessToken);
		return response;
	};

	const getAdmin = async (id: number) => {
		const response = await AdminProxyService.getAdmin(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			setAdmin(response.data);
		}
	};

	const addAdmin = async (admin: any) => {
		const response = await AdminProxyService.addAdmin(admin, accessToken);
		return response;
	};

	const updateAdmin = async (adminData: {
		name?: string;
		chatName?: string;
		chatPic?: string;
		password?: string;
	}) => {
		const response = await AdminProxyService.updateAdmin(accessToken, adminData);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	const updatePassword = async (oldPassword: string, password: string) => {
		const response = await AdminProxyService.updatePassword(accessToken, oldPassword, password);
		return response;
	};

	const updateProjectUser = async (id: number, adminData: {role: string}) => {
		const response = await AdminProxyService.updateProjectUser(accessToken, id, adminData);
		return response;
	};

	const deleteProjectUser = async (id: number) => {
		const response = await AdminProxyService.deleteProjectUser(id, accessToken);
		return response;
	};

	const getProjectName = async () => {
		const response = await AdminProxyService.getProjectName(accessToken);
		return response;
	};

	const updateProjectName = async (name: string) => {
		const response = await AdminProxyService.updateProjectName(accessToken, name);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: '',
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: '',
			text: translations.toasts.error,
			variant: 'danger',
		});
	};

	return {
		register,
		authConfirm,
		passwordReset,
		passwordUpdate,
		getAllProjectUsers,
		getAdmins,
		updateProjectUser,
		deleteProjectUser,
		getAdmin,
		addAdmin,
		updateAdmin,
		updatePassword,
		getProjectName,
		updateProjectName,
	};
};

import {action, makeObservable, observable} from 'mobx';
import {Prompt} from 'models/prompt';

class PromptService {
	@observable
	public prompt: Prompt = {
		openaiApiKey: '',
		openaiModel: '',
		text: '',
		messages: [],
	};

	@action
	public setPrompt = (value: Prompt) => {
		this.prompt = value;
	};

	@action
	public updatePrompt = (field: string, value: any) => {
		this.prompt = {...this.prompt, [field]: value};
	};

	@action
	public clearPrompt = () => {
		this.prompt = {
			openaiApiKey: '',
			openaiModel: '',
			text: '',
			messages: [],
		};
	};

	constructor() {
		makeObservable(this);
	}
}
export default new PromptService();

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import useL10n from 'l10n/useL10n';
import {Button, Form} from 'react-bootstrap';
import {OfferType} from 'models/enums/OfferType.enum';
import offerService from 'store/offerService';
import {BsArrowLeftCircleFill} from 'react-icons/bs';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import {useParams} from 'react-router-dom';
import OfferRooms from './OfferRooms';
import OfferUsers from './OfferUsers';

interface IOfferRecepients {
	nextStep: () => void;
	steps: string[];
}

const OfferRecepients: FunctionComponent<IOfferRecepients> = function OfferRecepients({
	nextStep,
	steps,
}) {
	const {slug} = useParams<{
		slug: string;
	}>();
	const translations = useL10n();

	const {currentOffer, setCurrentOffer, setCurrentStep, currentStep} = useLocalObservable(
		() => offerService
	);
	const {appTheme} = useLocalObservable(() => appService);

	const onPrevClickHandler = () => {
		setCurrentStep(currentStep - 1);
	};

	const clickSaveHandler = () => {
		if (currentOffer.type === OfferType.GLOBAL) {
			nextStep();
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setCurrentOffer({
			...currentOffer,
			type: value as OfferType,
		});
	};

	return (
		<section className='offer__recepients'>
			<div className='block w-50 mb-3'>
				<p className='h5 mb-2'>{translations.offers.sendMessage}</p>
				<p className='text-secondary mb-4'>{translations.offers.sendMessageDescr}</p>
				<Form.Group className='mb-3' controlId='formRoomRole'>
					<div>
						<Form.Check
							inline
							name='type'
							type='radio'
							label={translations.offers.radio.allRooms}
							id='offer-type1'
							value={OfferType.GLOBAL}
							onChange={onChangeHandler}
							className='checkbox-black'
							checked={currentOffer.type === OfferType.GLOBAL}
							disabled={slug !== undefined}
						/>
					</div>
					<div>
						<Form.Check
							inline
							name='type'
							type='radio'
							label={translations.offers.radio.roomList}
							id='eoffer-type2'
							value={OfferType.ROOM}
							onChange={onChangeHandler}
							className='checkbox-black'
							checked={currentOffer.type === OfferType.ROOM}
							disabled={slug !== undefined}
						/>
					</div>
					<div>
						<Form.Check
							inline
							name='type'
							type='radio'
							label={translations.offers.radio.userList}
							id='offer-type2'
							value={OfferType.USER}
							onChange={onChangeHandler}
							className='checkbox-black'
							checked={currentOffer.type === OfferType.USER}
							disabled={slug !== undefined}
						/>
					</div>
				</Form.Group>
			</div>
			{currentOffer.type === OfferType.ROOM && <OfferRooms nextStep={nextStep} />}
			{currentOffer.type === OfferType.USER && <OfferUsers nextStep={nextStep} />}

			<div className='offer__footer'>
				<Button
					variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
					onClick={onPrevClickHandler}>
					<BsArrowLeftCircleFill />
					&nbsp;&nbsp;
					{translations.welcome.steps.btns.prev}
				</Button>

				<Button
					variant='success'
					className='d-flex mx-2'
					type={currentOffer.type === OfferType.GLOBAL ? 'button' : 'submit'}
					onClick={clickSaveHandler}
					form={currentOffer.type === OfferType.ROOM ? 'offerRoomsForm' : 'offerUsersForm'}>
					{!slug ? translations.btns.send : translations.btns.save}
				</Button>
			</div>
		</section>
	);
};

export default observer(OfferRecepients);

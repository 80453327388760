enum ExportTypes {
	USER_ACTIVITY = 'USER_ACTIVITY',
	MESSAGES = 'MESSAGES',
	BAN = 'BAN',
}

enum ExportStatus {
	READY = 'READY',
	PENDING = 'PENDING',
	FAILED = 'FAILED',
}

export {ExportTypes, ExportStatus};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Spinner, Alert, Container, Row, Col, Breadcrumb, Form, Button} from 'react-bootstrap';
import {NavLink, useHistory, useParams} from 'react-router-dom';
import checkRealTextLength from 'utils/checkTextLength';

import useL10n from 'l10n/useL10n';

import appService from 'store/appService';
import settingsServices from 'store/settingsServices';

import UploadImageMultiple from 'components/uploadImageMultiple/uploadImageMultiple';
import {Theme} from 'models/enums/Theme.enum';
import {getFileSize} from 'utils/helpers';
import update from 'immutability-helper';
import useSticker from 'hooks/useSticker';

import './stickers.scss';
import PageTitle from 'components/pageTitle/PageTitle';
import Sticker from './Sticker';

const Stickers: FunctionComponent = function Stickers() {
	const {slug} = useParams<{
		slug: string;
	}>();
	const history = useHistory();
	const translations = useL10n();
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [visibleSavePreloader, setVisibleSavePreloader] = useState(false);
	const [stickers, setStickers] = useState<any[]>([]);
	const [name, setName] = useState('');
	const [currentPack, setCurrentPack] = useState<any>({
		name: '',
		nameLength: 0,
	});
	const [loader, setLoader] = useState(false);
	const [validated, setValidated] = useState(false);

	const {appTheme} = useLocalObservable(() => appService);
	const {setTabKey} = useLocalObservable(() => settingsServices);

	const {addStickerPack, getStickerPackById, addStickers, updateStickerPack} = useSticker();

	const getStickers = async () => {
		setVisiblePreloader(true);
		const response = await getStickerPackById(+slug);
		setVisiblePreloader(false);
		if (response) {
			setCurrentPack({...response, nameLength: checkRealTextLength(response.name)});
			setName(response.name || '');
			if (response.stickers)
				setStickers(
					response.stickers.map((el: Sticker) => {
						return {
							...el,
							emojis: el.emojis?.map((emoji: {id: number; name: string}) => emoji.name),
						};
					})
				);
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		setCurrentPack({
			...currentPack,
			[event.target.name]: value,
			[`${event.target.name}Length`]: checkRealTextLength(value),
		});
	};

	const setFiles = async (files: File[] | null) => {
		if (files?.length) {
			if (!slug) {
				const stickerArray = files.map(el => {
					return {file: el, picFile: URL.createObjectURL(el)};
				});
				setStickers([...stickers, ...stickerArray]);
				return;
			}

			const stickerArray = files.map(el => {
				return {file: el};
			});
			setVisiblePreloader(true);
			await addStickers(stickerArray, +slug);
			getStickers();
		}
	};

	const cancelClickHandler = () => {
		history.push('/appearance');
	};

	const save = async () => {
		if (!slug) {
			setVisibleSavePreloader(true);
			const response = await addStickerPack(currentPack.name);
			if (response) {
				if (stickers.length) {
					await addStickers(stickers, response.id);
					setVisibleSavePreloader(false);
					setTabKey('STICKER');
					history.push('/appearance');
				}
			}
			return;
		}

		const response = await updateStickerPack({id: +slug, name: currentPack.name});
		if (response) {
			setName(currentPack.name);
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else if (stickers.length) save();
	};

	const moveSticker = (dragIndex: number, hoverIndex: number) => {
		setStickers((prev: Sticker[]) => {
			return update(prev, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prev[dragIndex] as Sticker],
				],
			});
		});
	};

	useEffect(() => {
		setTabKey('STICKER');
		if (slug) {
			getStickers();
		}
		return () => {
			setStickers([]);
		};
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row className='stickers__wrapper'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.settings}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.appearance.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/appearance'>{translations.settings.stickers}</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>
							{slug ? translations.stickers.stickerPack : translations.stickers.newStickerPack}
						</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={slug ? translations.stickers.stickerPack : translations.stickers.newStickerPack}
					/>

					{!visibleSavePreloader && (
						<div>
							<div className='block w-50 mb-3'>
								<p className='h5 mb-4'>{translations.stickers.name}</p>
								<Form noValidate validated={validated} onSubmit={handleSubmit} id='stickerPack'>
									<Form.Group controlId='stickerPackName' className='mb-2'>
										<Form.Control
											placeholder={translations.stickers.name}
											name='name'
											value={currentPack.name}
											onChange={onChangeHandler}
											maxLength={140}
											required
										/>
									</Form.Group>
									{slug && (
										<Button
											variant='success'
											form='stickerPack'
											type='submit'
											disabled={visibleSavePreloader || name === currentPack.name}>
											{loader ? (
												<div className='d-flex align-items-center h-100'>
													<Spinner animation='border' />
												</div>
											) : (
												translations.btns.save
											)}
										</Button>
									)}
								</Form>

								<p className='text-end text-secondary'>
									<small>{currentPack.nameLength || 0}/140</small>
								</p>
							</div>
							<div className='block'>
								<p className='h5 mb-4'>{translations.stickers.add}</p>
								<Alert variant='light' className='w-50'>
									<small>{translations.stickers.disclaimer}</small>
								</Alert>
								<div className='mb-3 col-6'>
									<p className='mb-0'>
										<small>{translations.imageReqs.imageWeight(getFileSize(204800))}</small>
										<small>{translations.imageReqs.imageMaxSize([512, 512])}</small>
										<small>, </small>
										<small>{translations.imageReqs.imageMinSizeStikers([192, 192])}</small>
									</p>
									<p>
										<small>{translations.imageReqs.formats('.png, .webp')}</small>
									</p>

									<UploadImageMultiple
										id='uploadStickers'
										setFiles={setFiles}
										objectMinSize={{width: 192, height: 192}}
										objectMaxSize={{width: 512, height: 512}}
										size={204800}
										acceptedFileTypes='.png, .webp'
									/>
								</div>
								{visiblePreloader && (
									<div className='text-center'>
										<Spinner
											animation='border'
											variant={appTheme === Theme.DARK ? 'light' : 'dark'}
										/>
									</div>
								)}

								{stickers.length > 0 ? (
									<div className='stickers'>
										{stickers.map((elem, index) => {
											return (
												<Sticker
													key={elem.id || elem.picFile}
													index={index}
													elem={elem}
													getStickers={getStickers}
													moveSticker={moveSticker}
													setVisiblePreloader={setVisiblePreloader}
													stickers={stickers}
													setStickers={setStickers}
												/>
											);
										})}
									</div>
								) : (
									!visiblePreloader && (
										<p className={!stickers.length && validated ? 'text-danger' : ''}>
											{translations.empty.stickersEmpty}
										</p>
									)
								)}
							</div>
						</div>
					)}
					{visibleSavePreloader && (
						<div className='d-flex align-items-center justify-content-center w-100'>
							<Spinner animation='border' />
						</div>
					)}
				</Col>
			</Row>
			{!slug && (
				<div className='stickers__footer'>
					<Button
						variant='outline-dark'
						onClick={cancelClickHandler}
						disabled={visibleSavePreloader}>
						{translations.btns.cancel}
					</Button>
					<Button
						variant='success'
						className='mx-2'
						form='stickerPack'
						type='submit'
						disabled={visibleSavePreloader}>
						{loader ? (
							<div className='d-flex align-items-center h-100'>
								<Spinner animation='border' />
							</div>
						) : (
							translations.btns.create
						)}
					</Button>
				</div>
			)}
		</Container>
	);
};

export default observer(Stickers);

import useSettings from 'hooks/useSettings';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Form, Badge, Button, Spinner} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import settingsServices from 'store/settingsServices';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

interface IFont {
	currentTab: string;
}

const Font: FunctionComponent<IFont> = function Font({currentTab}) {
	const translations = useL10n();
	const [validated, setValidated] = useState(false);
	const [fonts, setFonts] = useState<{name: string; file: File}[]>([]);
	const [name, setName] = useState('');
	const [isUploadingFonts, setIsUploadingFonts] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const {font} = useLocalObservable(() => settingsServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {getFont, uploadFont, deleteFont} = useSettings();

	const fontStyles = ['font400', 'font500', 'font600'];

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				name === '' && setName(file.name.split(/[\s.-]+/)[0]);
				fonts.find(el => el.name === event.target.name)
					? fonts.map(el => (el.name === event.target.name ? {name: event.target.name, file} : el))
					: setFonts([...fonts, {name: event.target.name, file}]);
			}
		}
	};

	const uploadFonts = async () => {
		setVisiblePreloader(true);
		await uploadFont(fonts, name);
		await getFont();
		setVisiblePreloader(false);
		setIsUploadingFonts(false);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else uploadFonts();
	};

	const getFontWithServices = async () => {
		setVisiblePreloader(true);
		await getFont();
		setVisiblePreloader(false);
	};

	const onDeleteFontsHandler = async () => {
		setVisiblePreloader(true);
		await deleteFont();
		setVisiblePreloader(false);
	};

	useEffect(() => {
		currentTab === 'font' && getFontWithServices();
		setIsUploadingFonts(false);
		setValidated(false);
	}, [currentTab]);

	return (
		<div className='block'>
			<p className='h5 mb-5'>{translations.settings.font}</p>

			{visiblePreloader ? (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			) : (
				<div>
					{!isUploadingFonts && (
						<div>
							{font && (
								<p>
									{translations.settings.currentFont}: <b>{font.name}</b>
								</p>
							)}

							<Button onClick={() => setIsUploadingFonts(true)}>{translations.btns.change}</Button>

							{font && (
								<Button
									variant='secondary'
									type='button'
									className='mx-2'
									onClick={onDeleteFontsHandler}>
									{translations.btns.reset}
								</Button>
							)}
						</div>
					)}

					{isUploadingFonts && (
						<div>
							<p>
								<small>{translations.imageReqs.formats('.ttf, .woff, .woff2')}</small>
							</p>

							<Form noValidate validated={validated} onSubmit={handleSubmit}>
								{fontStyles.map(el => {
									return (
										<div className='mb-5' key={el}>
											<Badge className='mb-3' bg='dark'>
												{el}
											</Badge>
											<Form.Control
												type='file'
												name={el}
												onChange={uploadFile}
												accept='.ttf, .woff, .woff2'
												required
											/>
										</div>
									);
								})}
								<Button
									variant='secondary'
									type='button'
									onClick={() => setIsUploadingFonts(false)}>
									{translations.btns.cancel}
								</Button>
								&nbsp;
								<Button variant='success' type='submit'>
									{translations.btns.save}
								</Button>
							</Form>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
export default observer(Font);

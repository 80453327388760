import {observer, useLocalObservable} from 'mobx-react-lite';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {FunctionComponent, useRef} from 'react';

import {Form, Button} from 'react-bootstrap';
import {BsTrashFill} from 'react-icons/bs';

import alertServices from 'store/alertServices';
import './uploadImage.scss';

import useL10n from 'l10n/useL10n';

import {getFileSize} from 'utils/helpers';

interface IUploadImageMultiple {
	id: string;
	pic?: string | null;
	setFiles: (file: File[] | null) => void;
	size: number;
	objectMinSize: {
		width: number;
		height: number;
	};
	objectMaxSize?: {
		width: number;
		height: number;
	};
	acceptedFileTypes: string;
}

const uploadImageMultiple: FunctionComponent<IUploadImageMultiple> = function uploadImageMultiple({
	id,
	pic,
	setFiles,
	size,
	objectMinSize,
	objectMaxSize,
	acceptedFileTypes,
}) {
	const translations = useL10n();

	const inputFileRef = useRef<HTMLInputElement>(null);

	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const checkImageSize = (url: any) => {
		const newImg = new Image();

		const promise = new Promise((resolve, reject) => {
			newImg.onload = () => {
				const width = newImg.naturalWidth;
				const height = newImg.naturalHeight;
				resolve({width, height});
			};

			newImg.onerror = reject;
		});

		newImg.src = window.URL.createObjectURL(url);

		return promise;
	};

	const clear = () => {
		if (inputFileRef.current) {
			inputFileRef.current.type = '';
			inputFileRef.current.type = 'file';
			inputFileRef.current.value = '';
		}
	};

	const openWarningMinSizeAlert = (file: File) => {
		showAlert({
			title: translations.imageReqs.imageReqs,
			text: `${translations.imageReqs.imageWeight(
				getFileSize(size)
			)}  ${translations.imageReqs.imageMinSize([objectMinSize.width, objectMinSize.height])}`,
			description: `${translations.stickers.fileWarning(file.name)}`,
			buttons: [
				{
					text: translations.alerts.btns.ok,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const openWarningMaxSizeAlert = (file: File) => {
		showAlert({
			title: translations.imageReqs.imageReqs,
			text: `${translations.imageReqs.imageWeight(
				getFileSize(size)
			)}  ${translations.imageReqs.imageMaxSize([
				objectMaxSize?.width || objectMinSize.width,
				objectMaxSize?.height || objectMinSize.width,
			])}`,
			description: `${translations.stickers.fileWarning(file.name)}`,
			buttons: [
				{
					text: translations.alerts.btns.ok,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
			],
		});
	};

	const uploadPic = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			Array.from(e.target.files).forEach((file, index) => {
				checkImageSize(file).then((object: any) => {
					(async () => {
						if (e.target.files) {
							if (object.height >= objectMinSize.height && object.width >= objectMinSize.width) {
								if (objectMaxSize) {
									if (
										object.height <= objectMaxSize.height &&
										object.width <= objectMaxSize.width
									) {
										if (file.size < size) {
											if (object.width / object.height === 1) {
												if (index === Array.from(e.target.files).length - 1) {
													setFiles(Array.from(e.target.files));
													clear();
												}
												return;
											}
										}
									}
									openWarningMaxSizeAlert(file);
									clear();
									setFiles(null);
									return;
								}
								if (file.size < size) {
									if (object.width / object.height === 1) {
										if (index === Array.from(e.target.files).length - 1) {
											setFiles(Array.from(e.target.files));
											clear();
										}
										return;
									}
								}
							}
						}

						openWarningMinSizeAlert(file);
						clear();
						setFiles(null);
					})();
				});
			});
		}
	};

	return (
		<div className='upload-image'>
			{!pic ? (
				<label className='btn btn-dark' htmlFor={id}>
					{translations.btns.add}
					<input
						id={id}
						className='settings__namedPics-inputfile'
						type='file'
						ref={inputFileRef}
						onChange={uploadPic}
						accept={acceptedFileTypes}
						required
						multiple
					/>
				</label>
			) : (
				<div className='upload-image__preview'>
					<img src={pic} className='upload-image__preview-pic image-preview--lg' alt='' />
					<Button
						variant='danger'
						size='sm'
						className='upload-image__delete'
						onClick={() => setFiles(null)}>
						<BsTrashFill />
					</Button>
				</div>
			)}
		</div>
	);
};

export default observer(uploadImageMultiple);

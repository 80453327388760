import UserRole from 'models/enums/UserRole.enum';
import defaultAvatar from 'assets/images/anonim-ava.jpg';

export const USER_STUB = {
	id: 5146834951386002,
	pic: defaultAvatar,
	name: 'Watchers user002',
	color: 0,
	email: '',
	isModer: false,
	bans: [],
};

export const TALKER_STUB = {
	id: 5146834951386001,
	hand: false,
	role: UserRole.GUEST,
	user: USER_STUB,
	isModer: false,
	isMuted: true,
	isActive: false,
	bans: [],
	isSupressed: false,
};

export const SIZE_ICON_IMG = 64;
export const SIZE_ICON_SVG = 16;

export const LIMIT = 20;

export const RU_DOC_URL =
	'https://socialsolutions.notion.site/443e2dd466934330a3543ae22ea1979a?pvs=4';

export const EN_DOC_URL =
	'https://watchers.notion.site/Documentation_Rooms_by_Watchers-a32e913363f142a585e5b872ae839745?pvs=4';

export const languages = [
	'ru',
	'en',
	'hy',
	'sr',
	'de',
	'es',
	'ar',
	'ro',
	'tr',
	'hi',
	'pt',
	'zh',
	'ms',
	'bn',
	'th',
	'fr',
	'fi',
	'is',
	'ko',
	'pl',
	'az',
	'uz',
	'uk',
	'ja',
];

export const statisChatGPTModels = [
	'gpt-4-0314',
	'gpt-4-32k',
	'gpt-4-32k-0314',
	'gpt-3.5-turbo',
	'gpt-3.5-turbo-0301',
];

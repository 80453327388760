import axios from 'axios';
import checkResponse from 'utils/checkResponse';

export default class Webhooks {
	static checkWebhooksUrl = async (
		token: string,
		eventWebhookUrl: string,
		body: {event: string; value: {user_id: string; project: string}}
	) => {
		try {
			const apiUrl = eventWebhookUrl;
			const {data, status} = await axios.post(apiUrl, body, {
				headers: {Authorization: `Bearer ${token}`},
			});
			checkResponse(data, status);

			return {data, status};
		} catch (error: any) {
			return {status: error.response?.status, data: error.response?.data};
		}
	};
}

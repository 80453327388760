import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Table,
	Spinner,
	Form,
	Button,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import banServices from 'store/banServices';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

import useAutoBans from 'hooks/useAutoBans';
import PageTitle from 'components/pageTitle/PageTitle';
import AutoBannedItem from './components/bannedAuto/AutoBannedItem';

const LIMIT = 20;

const AutoBanned: FunctionComponent = function AutoBanned() {
	const {accessToken} = useLocalObservable(() => userServices);
	const {autoBans} = useLocalObservable(() => banServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [offset, setOffset] = useState(0);
	const [sortBy, setSortBy] = useState('createdAt_desc');

	const {getAutoBanned} = useAutoBans();

	const translations = useL10n();

	const getAutoBannedWithServices = async (limit: number, currentOffset: number, order: string) => {
		setVisiblePreloader(true);
		await getAutoBanned(limit, currentOffset, order);
		setVisiblePreloader(false);
	};

	const renderBan = useCallback(
		(elem: any) => {
			return <AutoBannedItem ban={elem} getBans={getAutoBanned} key={elem.id} />;
		},
		[autoBans.length]
	);

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		if (value === '') return;
		setSortBy(value);
		setOffset(0);

		getAutoBanned(LIMIT, 0, value);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getAutoBannedWithServices(LIMIT, offset - LIMIT, sortBy);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getAutoBannedWithServices(LIMIT, offset + LIMIT, sortBy);
	};

	useEffect(() => {
		accessToken && getAutoBannedWithServices(LIMIT, 0, sortBy);
	}, [accessToken]);

	return (
		<Container fluid className='pt-4 text-lg'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.autoBanned.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.autoBanned.title}
						text={translations.sidebar.autoBanned.descr}
					/>
					<div className='d-flex align-items-center justify-content-end mb-3'>
						<div className='filters__btns'>
							<Button
								className='d-flex'
								onClick={onPrevPageBtnClickHandler}
								disabled={(!autoBans.length && offset === 0) || offset === 0}>
								<BsArrowLeftCircleFill />
							</Button>
							&nbsp;
							<Button
								className='d-flex'
								onClick={onNextPageBtnClickHandler}
								disabled={!autoBans.length || autoBans.length < LIMIT}>
								<BsArrowRightCircleFill />
							</Button>
						</div>
					</div>

					{visiblePreloader && (
						<div className='text-center'>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{autoBans.length > 0 ? (
						<div>
							<div className='autobans'>
								<Table
									responsive
									striped={appTheme === Theme.LIGHT}
									bordered
									hover
									variant={appTheme?.toLowerCase()}>
									<thead>
										<tr>
											<th className='col-2 align-middle'>
												<OverlayTrigger
													placement='bottom'
													delay={{show: 50, hide: 50}}
													overlay={
														<Tooltip id='button-tooltip-2'>
															<b>{translations.table.user}</b>
														</Tooltip>
													}>
													<Form.Select onChange={onSelect} value={sortBy}>
														<option value=''>{translations.sorting.id}</option>
														<option value='id_desc'>{translations.sorting.id} &darr;</option>
														<option value='id_asc'>{translations.sorting.id} &uarr;</option>
													</Form.Select>
												</OverlayTrigger>
											</th>
											<th className='col-1 text-center align-middle'>
												<b>{translations.table.banIn}</b>
											</th>
											<th className='col-1 text-center align-middle'>
												<OverlayTrigger
													placement='bottom'
													delay={{show: 50, hide: 50}}
													overlay={
														<Tooltip id='button-tooltip-2'>
															<b>{translations.table.timeBan}</b>
														</Tooltip>
													}>
													<Form.Select onChange={onSelect} value={sortBy}>
														<option value=''>{translations.table.timeBan}</option>
														<option value='createdAt_desc'>
															{translations.table.timeBan} &darr;
														</option>
														<option value='createdAt_asc'>
															{translations.table.timeBan} &uarr;
														</option>
													</Form.Select>
												</OverlayTrigger>
											</th>
											<th className='col-1 text-center align-middle'>
												<b>{translations.table.until}</b>
											</th>
											<th className='col-2 text-center align-middle'>
												<OverlayTrigger
													placement='bottom'
													delay={{show: 50, hide: 50}}
													overlay={
														<Tooltip id='button-tooltip-2'>
															<b>{translations.table.reason}</b>
														</Tooltip>
													}>
													<Form.Select onChange={onSelect} value={sortBy}>
														<option value=''>{translations.table.reason}</option>
														<option value='reason_desc'>{translations.table.reason} &darr;</option>
														<option value='reason_asc'>{translations.table.reason} &uarr;</option>
													</Form.Select>
												</OverlayTrigger>
											</th>
											<th className='col-2 text-center align-middle'>
												<b>{translations.table.comment}</b>
											</th>
											<th className='col-1 text-center align-middle'>
												<b>{translations.table.creator}</b>
											</th>
											<th className='col-2 text-center'>
												<b> </b>
											</th>
										</tr>
									</thead>
								</Table>
							</div>
							{!visiblePreloader && <div className='w-100'>{autoBans.map(renderBan)}</div>}
						</div>
					) : (
						!visiblePreloader && <p>{translations.empty.bannedEmpty}</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(AutoBanned);

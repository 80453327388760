import {FunctionComponent, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import modalServices from 'store/modalServices';
import useChangelog from 'hooks/useChangelog';
import toastServices from 'store/toastServices';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';

const SubcribeModal: FunctionComponent = function SubscribeModal() {
	const translations = useL10n();
	const {subscribeModalVisible, setSubscribeModalVisible} = useLocalObservable(() => modalServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {appTheme} = useLocalObservable(() => appService);

	const [email, setEmail] = useState('');
	const [isWarning, setIsWarning] = useState(false);

	const {postChangelogSubscribe} = useChangelog();

	const onModalClose = () => {
		setSubscribeModalVisible(false);
		setEmail('');
	};
	const subscribeClickHandler = async () => {
		if (!email) setIsWarning(true);
		if (!isWarning && email) {
			await postChangelogSubscribe(email);
			onModalClose();
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
			});
		}
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;

		if (
			!/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
				value
			)
		) {
			setIsWarning(true);
		} else setIsWarning(false);
		setEmail(value);
	};

	return (
		<Modal
			className='modal'
			show={subscribeModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton>
				<Modal.Title> {translations.btns.subscribeToUpdates}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate>
					<Form.Label className='text-secondary'>{translations.subscribe.email}</Form.Label>
					<Form.Control
						name='email'
						onChange={onChangeHandler}
						className={isWarning ? 'is-error' : ''}
					/>

					<Modal.Footer>
						<Button variant='dark' type='button' onClick={subscribeClickHandler}>
							{translations.btns.subscribe}
						</Button>
						<Button
							variant={appTheme === Theme.DARK ? 'outline-light' : 'outline-dark'}
							onClick={onModalClose}>
							{translations.btns.cancel}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
export default observer(SubcribeModal);

import {ToastBody} from 'models/toast';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {TransitionGroup} from 'react-transition-group';
import classNames from 'classnames';

import toastService from 'store/toastServices';
import messagesServices from 'store/messagesServices';
import roomServices from 'store/roomServices';

import Toast from './Toast';
import './toasts.scss';

const ToastList: FunctionComponent = function ToastList() {
	const {toasts} = useLocalObservable(() => toastService);
	const {chatScrollPosition} = useLocalObservable(() => messagesServices);
	const {currentRoomId} = useLocalObservable(() => roomServices);

	const renderToast = useCallback(
		(toast: ToastBody) => <Toast value={toast} key={toast?.id} />,
		[toasts.length]
	);

	const toastsClasses = classNames('toasts', {
		'toasts--up': chatScrollPosition && !currentRoomId,
		'toasts--upRoom': chatScrollPosition && currentRoomId,
		'toasts--room': currentRoomId,
	});

	return (
		<div className={toastsClasses}>
			<TransitionGroup>{toasts.map(renderToast)}</TransitionGroup>
		</div>
	);
};

export default observer(ToastList);

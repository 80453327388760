import {observer, useLocalObservable} from 'mobx-react-lite';
import {Talker} from 'models/room';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Modal, Badge, InputGroup, Button, Form, Spinner, Row, Col} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {BsXLg, BsTrashFill, BsClipboardPlus} from 'react-icons/bs';
import modalServices from 'store/modalServices';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';
import useSemanticAnalysis from 'hooks/useSemanticAnalysis';

interface IPromptHistoryModal {
	setText: (text: string) => void;
}

const PromptHistoryModal: FunctionComponent<IPromptHistoryModal> = function PromptHistoryModal({
	setText,
}) {
	const {promptHistoryModalVisible, togglePromptHistoryModalVisible} = useLocalObservable(
		() => modalServices
	);
	const {appTheme} = useLocalObservable(() => appService);
	const {getPromptHistory, deletePromptHistory} = useSemanticAnalysis();

	const [promptHistory, setPromptHistory] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [search, setSearch] = useState('');
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const translations = useL10n();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
	};

	const onSearchClickHandler = () => {
		if (search)
			setFilteredData(
				promptHistory.filter((el: any) => el.text.toLowerCase().includes(search.toLowerCase()))
			);
	};

	const hidePromptHistoryModal = () => {
		togglePromptHistoryModalVisible(false);
	};

	const getHistory = async () => {
		setVisiblePreloader(true);
		const response = await getPromptHistory();
		if (response) {
			setPromptHistory(response.data);
			setFilteredData(response.data);
		}
		setVisiblePreloader(false);
	};

	const deleteClickHandler = async (id: number) => {
		setVisiblePreloader(true);
		await deletePromptHistory(id);
		await getHistory();
	};

	const pasteClickHanlder = (text: string) => {
		setText(text);
		hidePromptHistoryModal();
	};

	const onShowModalHandler = async () => {
		getHistory();
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearchClickHandler();
		}
	};

	const clear = () => {
		setSearch('');
		getHistory();
	};

	// useEffect(() => {}, []);

	const renderPromptHistoryItem = useCallback((elem: any, index: number) => {
		return (
			<Row key={index} className='mb-3'>
				<Col md={10}>
					<p>{elem.text}</p>
				</Col>
				<Col md={2}>
					<div className='mb-3 d-flex justify-content-center'>
						<button
							type='button'
							className='text-info d-flex align-items-center'
							onClick={() => pasteClickHanlder(elem.text)}>
							<BsClipboardPlus className='mx-2 h5 m-0' />
							&nbsp;
							{translations.btns.paste}
						</button>
					</div>
					<div className='d-flex justify-content-center'>
						<button
							type='button'
							className='text-danger d-flex align-items-center'
							onClick={() => deleteClickHandler(elem.id)}>
							<BsTrashFill className='mx-2 h5 m-0' />
							&nbsp;
							{translations.btns.remove}
						</button>
					</div>
				</Col>
			</Row>
		);
	}, []);

	return (
		<Modal
			className='modal modal--prompt-history'
			show={promptHistoryModalVisible}
			onHide={hidePromptHistoryModal}
			onShow={onShowModalHandler}
			animation={false}
			centered
			restoreFocus={false}>
			<div className='modal-head'>
				<Modal.Header closeButton>
					<Modal.Title>
						<h2 className='mb-0'>{translations.semantic.savedPrompts}</h2>
					</Modal.Title>
				</Modal.Header>

				<div className='d-flex align-items-unset p-4'>
					<InputGroup>
						<Form.Control
							placeholder={`${translations.search.searchBy} ${translations.search.talker}`}
							aria-label={translations.search.searchBy}
							value={search}
							onChange={onChangeHandler}
							onKeyPress={handleKeyPress}
						/>
						{(search || !filteredData.length) && (
							<Button variant='link' className='modal-clear-btn text-secondary' onClick={clear}>
								<BsXLg />
							</Button>
						)}
						<Button
							variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
							className='px-4'
							onClick={onSearchClickHandler}>
							{translations.btns.search}
						</Button>
					</InputGroup>
				</div>
			</div>

			<Modal.Body>
				{visiblePreloader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}

				{!visiblePreloader && (
					<div className='modal__talker-list'>
						{!filteredData.length ? (
							<p className='mx-2'>{translations.empty.empty}</p>
						) : (
							filteredData.map((item: any, index: number) => renderPromptHistoryItem(item, index))
						)}
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default observer(PromptHistoryModal);

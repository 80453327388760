import useImage from 'hooks/useImage';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {
	Container,
	Row,
	Col,
	Breadcrumb,
	Badge,
	Image,
	Spinner,
	Navbar,
	Button,
} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import PageTitle from 'components/pageTitle/PageTitle';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';
import appService from 'store/appService';
import {LIMIT} from 'constants/constants';
import {Theme} from 'models/enums/Theme.enum';
import {Message} from 'models/room';
import useChatMessage from 'hooks/useChatMessage';
import {User} from 'models/user';

const Images: FunctionComponent = function Images() {
	const translations = useL10n();
	const {getImages} = useImage();
	const {appTheme} = useLocalObservable(() => appService);
	const {changePicVisible} = useChatMessage();

	const [picMessages, setPicMessages] = useState<Message[]>([]);
	const [isBtnDisabled, setIsBtnDisabled] = useState(false);
	const [images, setImages] = useState<
		{url: string; isVisible: boolean; messageId: number; user: User}[]
	>([]);
	const [offset, setOffset] = useState(0);
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const getImagesWithService = async (limit?: number, currentOffset?: number) => {
		setVisiblePreloader(true);
		const response = await getImages(limit || LIMIT, currentOffset || 0);
		if (response) setVisiblePreloader(false);
		setPicMessages([...response]);
	};

	const onPrevPageBtnClickHandler = () => {
		setOffset(offset - LIMIT);
		getImagesWithService(LIMIT, offset - LIMIT);
	};

	const onNextPageBtnClickHandler = async () => {
		setOffset(offset + LIMIT);
		getImagesWithService(LIMIT, offset + LIMIT);
	};

	const toggleBlockClickHandler = async (image: {
		url: string;
		isVisible: boolean;
		messageId: number;
		user: User;
	}) => {
		// setIsBtnDisabled(true);

		let updatedPics = [];
		updatedPics = images
			.filter(el => el.messageId === image.messageId)
			.map(pic => {
				if (pic.url === image.url) {
					return {url: image.url, isVisible: !image.isVisible};
				}
				return {url: pic.url, isVisible: pic.isVisible};
			});
		await changePicVisible(image.messageId, {picData: JSON.stringify(updatedPics)});
		setImages(
			images.map(el => {
				if (el.url === image.url) {
					return {...el, isVisible: !image.isVisible};
				}
				return el;
			})
		);
		// setIsBtnDisabled(false);
		// getImagesWithService(LIMIT, offset);
	};

	const setPics = () => {
		const picsArray: {url: string; isVisible: boolean; messageId: number; user: User}[] = [];
		if (picMessages.length) {
			picMessages.map(el => {
				if (el.picData) {
					const pics = JSON.parse(el.picData);
					if (pics.length)
						pics.map((pic: {url: string; isVisible: boolean}) => {
							picsArray.push({
								url: pic.url,
								isVisible: pic.isVisible,
								messageId: el.id,
								user: el.user,
							});
							return pic;
						});
				}
				return el;
			});
			setImages(picsArray);
		}
	};

	useEffect(() => {
		setPics();
	}, [picMessages]);

	useEffect(() => {
		getImagesWithService();
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.images.title}</Breadcrumb.Item>
					</Breadcrumb>

					<PageTitle
						title={translations.sidebar.images.title}
						text={translations.sidebar.images.descr}
					/>

					<Navbar className='d-flex w-100 p-0 mb-2'>
						<div className=' w-100 d-flex align-items-center justify-content-between'>
							<div className='filters__btns'>
								<Button
									variant='dark'
									className='d-flex'
									onClick={onPrevPageBtnClickHandler}
									disabled={(!picMessages.length && offset === 0) || offset === 0}>
									<BsArrowLeftCircleFill />
								</Button>
								&nbsp;
								<Button
									variant='dark'
									className='d-flex'
									onClick={onNextPageBtnClickHandler}
									disabled={!picMessages.length || picMessages.length < LIMIT}>
									<BsArrowRightCircleFill />
								</Button>
							</div>
						</div>
					</Navbar>

					{visiblePreloader && (
						<div className='text-center position-absolute w-100' style={{top: '7rem'}}>
							<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
						</div>
					)}

					{images.length > 0 && (
						<div className='d-flex flex-wrap'>
							{images.map((image: any, index: number) => (
								<div
									key={index}
									className='block mb-2 text-center mx-2'
									style={{width: 'calc(100% / 3 - 1rem)'}}>
									<div
										className='mb-2 position-relative'
										style={{backgroundColor: '#ccc', height: '14rem'}}>
										<div className='chat__message-pic-loader'>
											<Spinner
												animation='border'
												variant={appTheme === Theme.DARK ? 'light' : 'dark'}
											/>
										</div>
										<Image
											src={image.url}
											alt=''
											loading='lazy'
											className='position-relative'
											style={{width: 'auto', maxWidth: '100%', height: '100%', objectFit: 'cover'}}
										/>
									</div>

									<div className='d-flex align-items-center justify-content-between flex-wrap'>
										<div className='user-info mb-1'>
											<div className={`user-info__photo background-color-${image.user.color}`}>
												<Image src={image.user?.pic} roundedCircle fluid loading='lazy' />
											</div>
											<div className='text-truncate'>
												<p className='mb-0 text-truncate' style={{maxWidth: '10rem'}}>
													{image.user?.name}
												</p>
											</div>
											<Badge bg='light' className='mx-2'>
												{image.user?.id}
											</Badge>
										</div>
										<Button
											variant={image.isVisible ? 'danger' : 'success'}
											disabled={isBtnDisabled}
											onClick={() => toggleBlockClickHandler(image)}>
											{image.isVisible ? translations.btns.block : translations.btns.unblock}
										</Button>
									</div>
								</div>
							))}
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Images);

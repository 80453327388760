import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';

import {Container, Row, Col, Breadcrumb, Spinner, Table, Button} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import useProjects from 'hooks/useProjects';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertServices from 'store/alertServices';
import toastServices from 'store/toastServices';
import useAdminProxy from 'hooks/useAdminProxy';
import appService from 'store/appService';
import {Theme} from 'models/enums/Theme.enum';

const ProjectList: FunctionComponent = function ProjectList() {
	const translations = useL10n();
	const [data, setData] = useState([]);
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [btnPreloader, setBtnPreloader] = useState<boolean | number>(false);

	const {accessToken, adminData} = useLocalObservable(() => userServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {showHiddenSettings, appTheme} = useLocalObservable(() => appService);

	const {getProjects, deleteProject} = useProjects();
	const {getAdmin} = useAdminProxy();

	const getProjectsWithServices = async () => {
		setVisiblePreloader(true);
		const response = await getProjects();
		if (response.status === ResponseStatus.SUCCESS) {
			setData(response.data);
			setVisiblePreloader(false);
		}
	};

	const removeProject = async (apiKey: string, index: number) => {
		setBtnPreloader(index);
		const response = await deleteProject(apiKey);
		setBtnPreloader(false);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.removed,
				variant: 'success',
			});
			getProjectsWithServices();
			await getAdmin(adminData.adminId);
		}
	};

	const onClickHandler = async (apikey: string, index: number) => {
		showAlert({
			title: translations.projects.removeProject,
			text: '',
			buttons: [
				{
					text: translations.alerts.btns.cancel,
					type: AlertBtnType.NORMAL,
					onClick: () => {
						hideAlert();
					},
				},
				{
					text: translations.btns.remove,
					type: AlertBtnType.DANGER,
					onClick: () => {
						hideAlert();
						removeProject(apikey, index);
					},
				},
			],
		});
	};

	useEffect(() => {
		if (accessToken) {
			getProjectsWithServices();
		}
	}, [accessToken]);

	const renderItem = useCallback(
		(item: any, index: number) => {
			return (
				<tr key={index}>
					<td className='align-middle'> {item.project}</td>
					<td className='align-middle'> {item.apiKey}</td>
					<td className='align-middle'> {item.amplitudeApiKey}</td>
					{showHiddenSettings && (
						<td className='align-middle text-center'>
							{btnPreloader === index ? (
								<div className='text-center'>
									<Spinner
										animation='border'
										variant={appTheme === Theme.DARK ? 'light' : 'dark'}
									/>
								</div>
							) : (
								<Button variant='danger' onClick={() => onClickHandler(item.apiKey, index)}>
									{translations.btns.remove}
								</Button>
							)}
						</td>
					)}
				</tr>
			);
		},
		[data, btnPreloader]
	);

	return (
		<Container fluid className='pt-4'>
			<Row className='align-items-center'>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.projects}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.projectsList}</Breadcrumb.Item>
					</Breadcrumb>
					<p className='h3 mb-5'>{translations.sidebar.projectsList}</p>
				</Col>
			</Row>

			<div className='block col-10'>
				{visiblePreloader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}

				{data.length > 0 ? (
					<Table
						responsive
						striped={appTheme === Theme.LIGHT}
						bordered
						hover
						variant={appTheme?.toLowerCase()}>
						<thead>
							<tr>
								<th className='align-middle col-3'>
									<b>{translations.projects.name}</b>
								</th>
								<th className='align-middle'>
									<b>apikey</b>
								</th>
								<th className='align-middle'>
									<b>amplitudeApiKey</b>
								</th>
								{showHiddenSettings && <th className='col-2'> </th>}
							</tr>
						</thead>
						<tbody>{data.map(renderItem)}</tbody>
					</Table>
				) : (
					!visiblePreloader && <p>{translations.empty.feedbackEmpty}</p>
				)}
			</div>
		</Container>
	);
};

export default observer(ProjectList);

import useBadge from 'hooks/useBadge';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Badge, Button, Col, Form, InputGroup, Row, Spinner, Table, Image} from 'react-bootstrap';
import badgeService from 'store/badgeService';
import useL10n from 'l10n/useL10n';
import {Theme} from 'models/enums/Theme.enum';
import appService from 'store/appService';
import {BsTrashFill, BsXLg} from 'react-icons/bs';
import {User} from 'models/user';

const BadgeUsers: FunctionComponent = function BadgeUsers() {
	const translations = useL10n();

	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
	const [search, setSearch] = useState('');

	const {badge} = useLocalObservable(() => badgeService);
	const {appTheme} = useLocalObservable(() => appService);

	const {uploadUserLists, getBadgeUsers, deleteUserBadge} = useBadge();

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setSearch(value);
	};

	const onSearchClickHandler = () => {
		if (search && badge.users)
			setFilteredUsers(
				badge.users.filter(
					(el: User) =>
						el.name.toLowerCase().includes(search.toLowerCase()) ||
						el.externalId?.includes(search.toLowerCase())
				)
			);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSearchClickHandler();
		}
	};

	const clear = () => {
		setSearch('');
		if (badge.users) setFilteredUsers(badge.users);
	};

	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>, id?: number) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length &&
			id
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				uploadUserLists(id, file);
			}
		}
	};

	const removeBtnClickHandler = (userId: number, badgeId?: number) => {
		if (badgeId) deleteUserBadge(badgeId, userId);
	};

	const getUsers = async (id: number) => {
		setVisiblePreloader(true);
		await getBadgeUsers(id);
		setVisiblePreloader(false);
	};

	useEffect(() => {
		if (badge.users) setFilteredUsers(badge.users);
	}, [badge.users]);

	useEffect(() => {
		if (badge.id) getUsers(badge.id);
	}, [badge.id]);

	const renderBadgeUserItem = useCallback(
		(user: User, index: number) => {
			return (
				<tr key={user.id}>
					<td className='align-middle'>
						{user && (
							<div className='user-info'>
								<div
									className={`user-info__photo user-info__photo--sm background-color-${
										user?.color || 0
									}`}>
									<Image src={user.pic} roundedCircle fluid />
								</div>
								<div className='text-truncate'>
									<div className='text-truncate'>{user.name}</div>
								</div>
							</div>
						)}
					</td>
					<td>
						<div className='text-truncate'>
							<Badge bg={appTheme.toLowerCase()} pill>
								id{user.id}
							</Badge>
							<div className='user-info__id'>
								<Badge bg={appTheme.toLowerCase()} pill>
									ext:
									{user.externalId}
								</Badge>
							</div>
						</div>
					</td>
					<td className='align-middle text-secondary'>
						<div className='d-flex justify-content-end'>
							<Button
								variant='danger'
								size='sm'
								className='d-flex p-1'
								onClick={() => removeBtnClickHandler(user.id, badge.id)}>
								<BsTrashFill className='text-white' />
							</Button>
						</div>
					</td>
				</tr>
			);
		},
		[badge]
	);

	return (
		<div>
			{visiblePreloader && (
				<div className='text-center'>
					<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
				</div>
			)}
			{!visiblePreloader && (
				<Row>
					<Col md={8}>
						<div className='block'>
							<div className='d-flex align-items-unset mb-2'>
								<InputGroup>
									<Form.Control
										placeholder={`${translations.search.searchBy} ${translations.search.talker}, ${translations.search.userId}`}
										aria-label={translations.search.searchBy}
										value={search}
										onChange={onChangeHandler}
										onKeyPress={handleKeyPress}
									/>
									{(search || !filteredUsers.length) && (
										<button
											type='button'
											className='badges-config__clear-btn text-secondary'
											onClick={clear}>
											<BsXLg />
										</button>
									)}
									<Button
										variant={appTheme === Theme.LIGHT ? 'dark' : 'secondary'}
										className='px-4'
										onClick={onSearchClickHandler}>
										{translations.btns.search}
									</Button>
								</InputGroup>
							</div>
							<div>
								{!filteredUsers.length ? (
									<p className='mx-2'>{translations.empty.usersEmpty}</p>
								) : (
									<Table responsive bordered hover variant={appTheme?.toLowerCase()}>
										<thead>
											<tr>
												<th className='align-middle col-2'>{translations.table.user}</th>
												<th className='align-middle col-3'>ID</th>
												<th className='align-middle col-1'> </th>
											</tr>
										</thead>
										<tbody>
											{filteredUsers.map((user: User, index: number) => {
												return renderBadgeUserItem(user, index);
											})}
										</tbody>
									</Table>
								)}
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className='block'>
							<p className='h5'>{translations.uploadBadges.create.file.title}</p>
							<p className=' text-secondary'>{translations.uploadBadges.create.file.text}</p>
							<InputGroup className='mb-3'>
								<Form.Control
									type='file'
									onChange={(e: any) => uploadFile(e, badge.id)}
									accept='.csv'
								/>
							</InputGroup>
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default observer(BadgeUsers);

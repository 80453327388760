import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';

import {Container, Row, Col, Breadcrumb, Button, Tabs, Tab} from 'react-bootstrap';

import useL10n from 'l10n/useL10n';
import useBadge from 'hooks/useBadge';
import appService from 'store/appService';
import badgeService from 'store/badgeService';
import modalServices from 'store/modalServices';

import PageTitle from 'components/pageTitle/PageTitle';

import TopX from './TopX';

import CustomBadges from './CustomBadges';
import AllBadgeUsers from './AllBadgeUsers';

const Badges: FunctionComponent = function Badges() {
	const translations = useL10n();

	const [key, setKey] = useState('toplist');
	const [visiblePreloader, setVisiblePreloader] = useState(true);

	const {appTheme} = useLocalObservable(() => appService);
	const {currentTab, setIsSearch} = useLocalObservable(() => badgeService);
	const {toggleBadgesModalVisible} = useLocalObservable(() => modalServices);

	const {getBadges, getAllBadgeUsers} = useBadge();

	const getBadgesWithServices = async () => {
		await getBadges();
		setVisiblePreloader(false);
	};
	const giveBadgeClickHandler = () => {
		toggleBadgesModalVisible(true);
		setIsSearch(true);
	};

	useEffect(() => {
		if (currentTab) setKey(currentTab);
	}, [currentTab]);

	useEffect(() => {
		if (key === 'badge') {
			getBadgesWithServices();
		}
		if (key === 'users') getAllBadgeUsers();
	}, [key]);

	return (
		<Container fluid className='pt-4 badges-config'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.badges.title}</Breadcrumb.Item>
					</Breadcrumb>
					<PageTitle
						title={translations.sidebar.badges.title}
						text={translations.sidebar.badges.descr}
					/>
				</Col>
				<Col>
					<div className=' w-100 d-flex align-items-center justify-content-end'>
						<Button variant='dark' onClick={giveBadgeClickHandler}>
							{translations.uploadBadges.giveBadge}
						</Button>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						id='badgeTabs'
						className='nav-tabs mb-3'
						variant={appTheme.toLowerCase()}>
						<Tab
							eventKey='toplist'
							title={translations.sidebar.toplist}
							tabClassName='text-secondary'>
							<TopX />
						</Tab>

						<Tab
							eventKey='badge'
							title={translations.uploadBadges.badge}
							tabClassName='text-secondary'>
							<CustomBadges visiblePreloader={visiblePreloader} />
						</Tab>
						<Tab
							eventKey='users'
							title={translations.uploadBadges.create.users}
							tabClassName='text-secondary'>
							<AllBadgeUsers currentTab={key} />
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
};

export default observer(Badges);

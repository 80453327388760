import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import useL10n from 'l10n/useL10n';
import appService from 'store/appService';
import {Button, Form, Spinner} from 'react-bootstrap';
import {Theme} from 'models/enums/Theme.enum';
import {useHistory} from 'react-router-dom';
import './stickers.scss';
import useSticker from 'hooks/useSticker';

import StickerPack from './StickerPack';

interface IStickerPacks {
	currentTab: string;
}

const StickerPacks: FunctionComponent<IStickerPacks> = function StickerPacks({currentTab}) {
	const translations = useL10n();
	const history = useHistory();
	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [stickerPacks, setStickerPacks] = useState([]);

	const {appTheme} = useLocalObservable(() => appService);

	const {getStickerPacks, deleteStickerPack} = useSticker();

	const getPacks = async () => {
		setVisiblePreloader(true);
		const response = await getStickerPacks();
		setVisiblePreloader(false);
		if (response) {
			setStickerPacks(response);
		}
	};

	useEffect(() => {
		currentTab === 'STICKER' && getPacks();
	}, [currentTab]);

	return (
		<div className='block'>
			<div className='stickers__packs'>
				<div className='stickers__packs-create config__create'>
					<div className='d-flex align-items-center justify-content-between w-100'>
						<div>
							<span className='h5'>{translations.stickers.newStickerPack}</span>
							<p className='text-secondary mb-0'>{translations.stickers.uploadNewStickerPack}</p>
						</div>
						<Button
							type='button'
							variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
							onClick={() => history.push('/stickers')}>
							{translations.btns.add}
						</Button>
					</div>
				</div>
				{visiblePreloader && (
					<div className='text-center'>
						<Spinner animation='border' variant={appTheme === Theme.DARK ? 'light' : 'dark'} />
					</div>
				)}
				{stickerPacks.length > 0 &&
					stickerPacks.map((elem: any, index: number) => (
						<StickerPack key={elem.id} elem={elem} index={index} getPacks={getPacks} />
					))}
			</div>
		</div>
	);
};

export default observer(StickerPacks);

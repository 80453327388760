import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import useL10n from 'l10n/useL10n';
import {useHistory} from 'react-router-dom';
import authService from 'store/authService';

interface IStepStart {
	setCurrentStep: (step: number) => void;
}
const StepStart: FunctionComponent<IStepStart> = function StepStart(props) {
	const {auth} = useL10n();
	const history = useHistory();
	const [agreement, setAgreement] = useState(false);
	const [validated, setValidated] = useState(false);

	const {user, setUser} = useLocalObservable(() => authService);

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setUser({...user, email: value});
	};

	const onCheckAgreement = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setAgreement(checked);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		props.setCurrentStep(1);
	};

	return (
		<div className='auth__form-content'>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Form.Group className='mb-4'>
					<Form.Label>{auth.steps.start.email}</Form.Label>
					<Form.Control
						type='email'
						placeholder='mail@example.com'
						value={user?.email || ''}
						onChange={onChangeHandler}
						required
					/>
				</Form.Group>
				<div className='auth__form-agreement'>
					<Form.Check
						checked={agreement}
						onChange={onCheckAgreement}
						id='register-agreement'
						required
						label={<span dangerouslySetInnerHTML={{__html: auth.agreement}} />}
					/>
				</div>
				<Button type='submit' variant='dark' size='lg' className='w-100 text-center'>
					{auth.btns.next}
				</Button>
				<div className='auth__form-footer'>
					<p className='auth__form-footer-text'>{auth.hasAccount}</p>
					<button
						type='button'
						className='btn-link text-info'
						onClick={() => history.push('/login')}>
						{auth.btns.login}
					</button>
				</div>
			</Form>
		</div>
	);
};

export default observer(StepStart);

import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';

export default class AgoraService {
	static startRecord = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/agora/start/${externalRoomId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static stopRecord = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/agora/stop/${externalRoomId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static statusRecord = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(`/admin/agora/status/${externalRoomId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}

import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import BadgeService from 'services/api/BadgeService';
import userServices from 'store/userServices';

import useL10n from 'l10n/useL10n';
import toastServices from 'store/toastServices';
import {Badge} from 'models/badge';
import badgeService from 'store/badgeService';
import RoomService from 'services/api/RoomService';

export default () => {
	const translations = useL10n();

	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {badge, setBadges, setBadge, setUsers, setCurrentUser, filter, search, offset} =
		useLocalObservable(() => badgeService);

	const uploadRanking = async (file: File) => {
		const response = await BadgeService.uploadRanking(accessToken, file);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const createBadge = async (value: Badge) => {
		const formData = new FormData();
		formData.append('text', value.text);
		formData.append('textColor', value.textColor);
		formData.append('backgroundColor', value.backgroundColor);
		if (value.tooltip) formData.append('tooltip', value.tooltip);
		if (value.communicationText) formData.append('communicationText', value.communicationText);
		if (value.communicationTitle) formData.append('communicationTitle', value.communicationTitle);
		if (value.picFile) formData.append('communicationPic', value.picFile);

		const response = await BadgeService.createBadge(accessToken, formData);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const updateBadge = async (id: number, value: Badge) => {
		const formData = new FormData();
		formData.append('text', value.text);
		formData.append('textColor', value.textColor);
		formData.append('backgroundColor', value.backgroundColor);
		if (value.tooltip !== null) formData.append('tooltip', value.tooltip);
		if (value.communicationText !== null)
			formData.append('communicationText', value.communicationText);
		if (value.communicationTitle !== null)
			formData.append('communicationTitle', value.communicationTitle);
		if (value.picFile) formData.append('communicationPic', value.picFile);
		else if (value.communicationPic !== null)
			formData.append('communicationPic', value.communicationPic);

		const response = await BadgeService.updateBadge(accessToken, id, formData);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getBadge = async (id: number) => {
		const response = await BadgeService.getBadge(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			setBadge(response.data);
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const deleteBadge = async (id: number) => {
		const response = await BadgeService.deleteBadge(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getBadges = async () => {
		const response = await BadgeService.getBadges(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setBadges(response.data);
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getBadgeUsers = async (id: number) => {
		const response = await BadgeService.getBadgeUsers(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			setBadge({...badge, users: response.data});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getAllBadgeUsers = async (
		limit?: number,
		currentOffset?: number,
		searchData?: {
			type: string;
			value: string;
		},
		filterData?: {
			type: string;
			value: string;
		}
	) => {
		const response = await BadgeService.getAllBadgeUsers(
			accessToken,
			limit || 10,
			currentOffset || 0,
			searchData,
			filterData
		);
		if (response.status === ResponseStatus.SUCCESS) {
			setUsers(response.data);
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getUserById = async (externalUserId: string) => {
		const response = await RoomService.getUserByUserId(accessToken, {externalUserId});
		if (response.status === ResponseStatus.SUCCESS) {
			setCurrentUser(response.data.user);
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const addUserBadge = async (badgeId: number, userId: number, isAllUsers?: boolean) => {
		const response = await BadgeService.addUserBadge(accessToken, badgeId, userId);

		if (response.status === ResponseStatus.SUCCESS) {
			if (isAllUsers) getAllBadgeUsers(10, offset, search, filter);
			else getBadgeUsers(badgeId);
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const deleteUserBadge = async (badgeId: number, userId: number, isAllUsers?: boolean) => {
		const response = await BadgeService.deleteUserBadge(accessToken, badgeId, userId);
		if (response.status === ResponseStatus.SUCCESS) {
			if (isAllUsers) getAllBadgeUsers();
			else getBadgeUsers(badgeId);
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const uploadUserLists = async (badgeId: number, file: File) => {
		const formData = new FormData();
		formData.append('list', file);

		const response = await BadgeService.uploadUserList(accessToken, badgeId, formData);
		if (response.status === ResponseStatus.SUCCESS) {
			getBadges();
			getBadgeUsers(badgeId);
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	return {
		uploadRanking,
		createBadge,
		getBadges,
		updateBadge,
		getBadge,
		deleteBadge,
		uploadUserLists,
		getBadgeUsers,
		addUserBadge,
		deleteUserBadge,
		getAllBadgeUsers,
		getUserById,
	};
};

import useL10n from 'l10n/useL10n';
import {ReportReasonEnum} from 'models/enums/ReportReason.enum';

export default () => {
	const translations = useL10n();

	const reportReasons = [
		{
			text: translations.reportsReasons.violence,
			type: ReportReasonEnum.VIOLENCE,
		},
		{
			text: translations.reportsReasons.provocations,
			type: ReportReasonEnum.PROVOCATIONS,
		},
		{
			text: translations.reportsReasons.personalDetails,
			type: ReportReasonEnum.PERSONALDETAILS,
		},
		{
			text: translations.reportsReasons.flood,
			type: ReportReasonEnum.FLOOD,
		},
		{
			text: translations.reportsReasons.spam,
			type: ReportReasonEnum.SPAM,
		},
		{
			text: translations.reportsReasons.fraudBegging,
			type: ReportReasonEnum.FRAUDBEGGING,
		},
		{
			text: translations.reportsReasons.other,
			type: ReportReasonEnum.OTHER,
		},
	];

	const getReasons = () => {
		return reportReasons;
	};

	const getReason = (type: string | null) => {
		return reportReasons.find(el => el.type === type);
	};

	return {
		getReasons,
		getReason,
	};
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {ToastContainer, Toast, Button} from 'react-bootstrap';
import {ToastBody} from 'models/toast';
import toastServices from 'store/toastServices';
import {CSSTransition} from 'react-transition-group';

import './toasts.scss';

const TOAST_TIME_TO_LIVE = 3000;
const INTERVAL_STEP = 200;

interface IToastProps {
	value: ToastBody;
}

const Toaster: FunctionComponent<IToastProps> = function Toaster({value}) {
	const {deleteToast} = useLocalObservable(() => toastServices);
	const [visibleToast, setVisibleToast] = useState(true);
	const [liveTime, setLiveTime] = useState(TOAST_TIME_TO_LIVE);
	const [currentTimerId, setCurrentTimerId] = useState<NodeJS.Timer | null>(null);
	const [isBtnClicked, setIsBtnClicked] = useState(false);

	const cancelTimer = useCallback(() => {
		if (currentTimerId) {
			clearInterval(currentTimerId);
			if (value?.onCancel) {
				value?.onCancel();
			}
			setVisibleToast(false);
			return;
		}
		setVisibleToast(false);
	}, [currentTimerId, value]);

	const btnClickHandler = (onClick?: () => void) => {
		setIsBtnClicked(true);
		cancelTimer();
		onClick && onClick();
	};

	/* eslint-disable */
	useEffect(() => {
		setVisibleToast(true);

		if (!value.stable) {
			let localLiveTime = liveTime;
			const timerId: NodeJS.Timer = setInterval(() => {
				if (localLiveTime <= 999) {
					clearInterval(timerId);
					setVisibleToast(false);
					return;
				}
				localLiveTime -= INTERVAL_STEP;
				setLiveTime(localLiveTime);
			}, INTERVAL_STEP);
			setCurrentTimerId(timerId);
			return () => {
				if (timerId) {
					clearInterval(timerId);
				}
			};
		}
	}, []);

	return (
		<CSSTransition
			in={visibleToast}
			timeout={500}
			classNames='fade'
			onExited={() => deleteToast(value)}
			unmountOnExit>
			<Toast bg={value.variant || 'primary'} onClose={cancelTimer}>
				{value.title && (
					<Toast.Header>
						<strong className='me-auto text-truncate'> {value.title} </strong>
						{/* <small>11 mins ago</small> */}
					</Toast.Header>
				)}
				<Toast.Body>
					<div className='toast__content'>
						{!value.button && <span className='toast__text'>{value.text}</span>}
						{value.button && (
							<span className='toast__text'>
								{!isBtnClicked ? value.text : value.button.afterClickText}
							</span>
						)}
						{value.button && value.button.onClick && !isBtnClicked && (
							<Button
								variant='link'
								onClick={() => btnClickHandler(value.button?.onClick)}
								disabled={isBtnClicked}>
								{value.button.text}
							</Button>
						)}
					</div>
				</Toast.Body>
			</Toast>
		</CSSTransition>
	);
};

export default observer(Toaster);

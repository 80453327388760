/* eslint-disable max-lines */
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import {useHistory, NavLink, Prompt} from 'react-router-dom';

import {Container, Row, Col, Breadcrumb, Button, Spinner, Tabs, Tab} from 'react-bootstrap';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill, BsTrashFill} from 'react-icons/bs';

import useL10n from 'l10n/useL10n';
import classNames from 'classnames';

import './welcome.scss';

import {AlertBtnType} from 'models/enums/Alert.enum';
import {Theme} from 'models/enums/Theme.enum';
import {Welcome} from 'models/welcome';
import {ShowFrequency} from 'models/enums/ShowEntry.enum';
import {LinkOpenType} from 'models/enums/LinkOpenType.enum';

import alertServices from 'store/alertServices';
import welcomeServices from 'store/welcomeServices';
import toastServices from 'store/toastServices';
import settingsServices from 'store/settingsServices';
import appService from 'store/appService';

import useWelcome from 'hooks/useWelcome';
import useSettings from 'hooks/useSettings';

import {isValidUrl} from 'utils/helpers';
import checkRealTextLength from 'utils/checkTextLength';

import WelcomeContent from './WelcomeContent';
import WelcomeFrequency from './WelcomeFrequency';

const CreateWelcome: FunctionComponent = function CreateWelcome() {
	const translations = useL10n();
	const history = useHistory();

	const {welcome, currentStep, setCurrentStep, welcomeDraft, setWelcomeDraft} = useLocalObservable(
		() => welcomeServices
	);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {appTheme} = useLocalObservable(() => appService);
	const {settings} = useLocalObservable(() => settingsServices);

	const {getWelcome, updateWelcome, deleteWelcome} = useWelcome();
	const {patchSettings} = useSettings();

	const [warning, setWarning] = useState(false);
	const [warningLink, setWarningLink] = useState(false);
	const [loader, setLoader] = useState(false);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [key, setKey] = useState('');
	const [availableLangs, setAvailableLangs] = useState<string[]>([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [myWelcome, setMyWelcome] = useState<Welcome>({
		title: '',
		titleLength: 0,
		text: '',
		buttonText: '',
		buttonTextLength: 0,
		buttonLink: '',
		openType: LinkOpenType.NEW_WINDOW,
	});

	const [mySettings, setMySettings] = useState<{
		welcomeShowOnce?: boolean;
		welcomeDelay?: number;
		welcomeShowFrequency?: ShowFrequency;
		welcomeIsDelayed?: boolean;
	}>({});

	const clear = () => {
		setMyWelcome({
			title: '',
			titleLength: 0,
			text: '',
			buttonText: '',
			buttonTextLength: 0,
			buttonLink: '',
			openType: LinkOpenType.NEW_WINDOW,
		});
	};
	const checkFields = () => {
		if (!myWelcome.title && !myWelcome.text) {
			setWarning(true);
			return false;
		}
		if (myWelcome.buttonLink && !isValidUrl(myWelcome.buttonLink)) {
			setWarningLink(true);
			return false;
		}
		setWarning(false);
		setWarningLink(false);
		return true;
	};

	const stepClasses = (step: number) =>
		classNames('welcome__steps-item', {
			'welcome__steps-item--active': currentStep === step,
		});

	const onPrevClickHandler = () => {
		setCurrentStep(1);
	};

	const onNextClickHandler = () => {
		if (checkFields()) setCurrentStep(2);
	};

	const deleteWelcomeWithServices = async () => {
		await deleteWelcome(key);
		clear();
		setConfirmedNavigation(true);
		// history.push('/welcome');
	};

	const removeClickHandler = () => {
		deleteWelcomeWithServices();
	};

	const saveWelcome = async () => {
		if (!checkFields()) return;
		setLoader(true);
		await updateWelcome({
			title: myWelcome.title,
			text: myWelcome.text,
			buttonText: myWelcome.buttonText,
			buttonLink: myWelcome.buttonLink,
			lang: key,
			openType: myWelcome.openType,
		});
		setLoader(false);
		localStorage.removeItem('welcomeDraft');
		setWelcomeDraft(null);
		// if (response) history.push('/welcome');
	};

	const updateWelcomeSettings = async () => {
		await patchSettings(mySettings);
		history.push('/welcome');
	};

	const saveDraft = (nextLocation?: any) => {
		setConfirmedNavigation(true);
		localStorage.setItem('welcomeDraft', JSON.stringify(myWelcome));
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.draftCreated,
			variant: 'success',
		});
		!nextLocation &&
			setTimeout(() => {
				history.push('/welcome');
			}, 100);
	};

	const handleBlockedNavigation = (nextLocation: any) => {
		setLastLocation(nextLocation.pathname);
		if (!confirmedNavigation && nextLocation.pathname !== '/login') {
			showAlert({
				title: translations.alerts.saveChanges,
				text: translations.alerts.unsavedChanges,
				buttons: [
					{
						text: translations.btns.dontSave,
						type: AlertBtnType.DANGER,
						onClick: () => {
							hideAlert();
							setConfirmedNavigation(true);
						},
					},
					{
						text: translations.alerts.btns.saveDraft,
						type: AlertBtnType.SUCCESS,
						onClick: () => {
							saveDraft(nextLocation);
							hideAlert();
							setConfirmedNavigation(true);
						},
					},
				],
			});
			return false;
		}
		return true;
	};

	const getWelcomeLang = async (lang: string) => {
		setVisiblePreloader(true);
		await getWelcome(lang);
		setVisiblePreloader(false);
	};

	useEffect(() => {
		if (key) {
			clear();
			getWelcomeLang(key);
			setWarning(false);
			setWarningLink(false);
		}
	}, [key]);

	useEffect(() => {
		if (settings) {
			settings.availableLangs && setAvailableLangs(settings.availableLangs?.split(','));
			settings.lang && setKey(settings.lang);
		}
	}, [settings]);

	useEffect(() => {
		welcomeDraft &&
			setMyWelcome({
				...welcomeDraft,
				titleLength: checkRealTextLength(welcomeDraft.title),
				buttonTextLength: checkRealTextLength(welcomeDraft.buttonText || ''),
			});
	}, [welcomeDraft]);

	useEffect(() => {
		if (welcome)
			setMyWelcome({
				...welcome,
				titleLength: checkRealTextLength(welcome.title),
				buttonTextLength: checkRealTextLength(welcome.buttonText || ''),
			});
	}, [welcome]);

	// useEffect(() => {
	// 	if (confirmedNavigation && lastLocation) {
	// 		history.push(lastLocation);
	// 	}
	// }, [confirmedNavigation, lastLocation]);

	useEffect(() => {
		return () => {
			clear();
			setCurrentStep(1);
		};
	}, []);

	return (
		<Container fluid className='pt-4'>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item active>{translations.breadcrumbs.title}</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.sidebar.widgets}</Breadcrumb.Item>
						<Breadcrumb.Item active>
							<NavLink to='/welcome'>{translations.sidebar.welcome}</NavLink>
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{translations.welcome.creating}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row className='align-items-start'>
				<Col>
					<p className='h3 mb-5'>{translations.welcome.createTitle}</p>
				</Col>
			</Row>

			<section className='welcome'>
				<ul className='welcome__steps'>
					<li className={stepClasses(1)}>
						<p className='welcome__steps-text'>{translations.welcome.steps.content.title}</p>
					</li>
					<li className={stepClasses(2)}>
						<p className='welcome__steps-text'>{translations.welcome.steps.showEntry.title}</p>
					</li>
				</ul>

				{currentStep === 1 && (
					<Tabs
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						id='welcome-lang-tabs'
						className='mb-3'>
						{availableLangs.length > 0 &&
							availableLangs.map((el: string) => {
								return (
									<Tab
										eventKey={el}
										title={el.toUpperCase()}
										tabClassName='text-secondary'
										key={el}>
										{!visiblePreloader && (
											<WelcomeContent
												myWelcome={myWelcome}
												setMyWelcome={setMyWelcome}
												warning={warning}
												warningLink={warningLink}
											/>
										)}
									</Tab>
								);
							})}
					</Tabs>
				)}
				{currentStep === 2 && (
					<WelcomeFrequency mySettings={mySettings} setMySettings={setMySettings} />
				)}
			</section>
			<div className='welcome__footer'>
				{currentStep !== 1 && (
					<Button
						variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
						onClick={onPrevClickHandler}
						disabled={loader}>
						<BsArrowLeftCircleFill />
						&nbsp;&nbsp;
						{translations.welcome.steps.btns.prev}
					</Button>
				)}

				{currentStep === 1 && (
					<Button
						variant={appTheme === Theme.LIGHT ? 'outline-dark' : 'outline-light'}
						className='mx-2'
						onClick={onNextClickHandler}
						disabled={loader}>
						{translations.welcome.steps.btns.next}
						&nbsp;&nbsp;
						<BsArrowRightCircleFill />
					</Button>
				)}

				<Button
					variant='success'
					className='mx-2'
					onClick={currentStep === 1 ? saveWelcome : updateWelcomeSettings}
					disabled={loader}>
					{loader ? (
						<div className='d-flex align-items-center h-100'>
							<Spinner animation='border' />
						</div>
					) : (
						translations.btns.save
					)}
				</Button>

				{currentStep === 1 && welcome?.project !== 'default' && (
					<Button
						variant='link'
						className='text-danger ms-auto'
						onClick={removeClickHandler}
						disabled={loader}>
						{/* <BsTrashFill /> */}
						{/* &nbsp;&nbsp; */}
						{translations.btns.reset}
					</Button>
				)}

				{/* {!welcome && <Prompt when message={handleBlockedNavigation} />} */}
			</div>
		</Container>
	);
};

export default observer(CreateWelcome);

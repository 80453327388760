/* eslint-disable max-lines */
import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import SettingsService from 'services/api/SettingsService';
import toastServices from 'store/toastServices';
import userServices from 'store/userServices';
import settingsServices from 'store/settingsServices';

import useL10n from 'l10n/useL10n';
import {NamedPicType} from 'models/enums/NamedPicType.enum';
import {Chatbot, GlobalSettings, Greeting, Pic, Settings} from 'models/settings';
import {Theme} from 'models/enums/Theme.enum';

export default () => {
	const translations = useL10n();

	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastServices);
	const {
		setSettings,
		setProjectLanguage,
		setThemes,
		setTheme,
		setThemeColors,
		setFonts,
		setAvatars,
		setIcons,
		setReactions,
		setExportJSON,
		setError,
		setBrandingColors,
		setGlobalSettings,
	} = useLocalObservable(() => settingsServices);

	const getSettings = async () => {
		const response = await SettingsService.getSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setSettings(response.data);
			if (response.data.lang) {
				setProjectLanguage(response.data.lang);
			}
		}
	};

	const getDefaultSettings = async () => {
		const response = await SettingsService.getDefaultSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const getGlobalSettings = async () => {
		const response = await SettingsService.getGlobalSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setGlobalSettings(response.data);
		}
	};

	const getThemes = async () => {
		const response = await SettingsService.getThemes(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setThemes(response.data);
		}
	};

	const getTheme = async (themeId: number) => {
		const response = await SettingsService.getTheme(accessToken, themeId);
		if (response.status === ResponseStatus.SUCCESS) {
			setTheme(response.data);
		}
	};

	const getColors = async (themeId: number) => {
		const response = await SettingsService.getColors(accessToken, themeId);
		setBrandingColors(response.data.colors || '');
		setThemeColors(response.data.colors || '');
		return response.data.colors;
	};

	const setColors = async (themeId: number, colors: string) => {
		const response = await SettingsService.setColors(accessToken, themeId, colors);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			getColors(themeId);
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getFont = async () => {
		const response = await SettingsService.getFont(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setFonts(response.data);
		}
		return response.data;
	};

	const uploadFont = async (fonts: {name: string; file: File}[], name: string) => {
		const response = await SettingsService.uploadFont(accessToken, fonts, name);
		return response.data;
	};

	const deleteFont = async () => {
		const response = await SettingsService.deleteFont(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setFonts(null);
		}
	};

	const getEmotions = async () => {
		const response = await SettingsService.getEmotions(accessToken);
		setReactions(response.data);
	};

	const getAvatars = async () => {
		const response = await SettingsService.getAvatars(accessToken);
		setAvatars(response.data);
	};

	const addNamedPic = async (type: string, pic: File, payload?: string) => {
		const response = await SettingsService.addNamedPic(accessToken, type, pic, payload);
		if (response.status === ResponseStatus.SUCCESS) {
			type === NamedPicType.AVATAR ? getAvatars() : getEmotions();
		}
	};

	const deleteNamedPic = async (type: string, id: number) => {
		const response = await SettingsService.deleteNamedPic(accessToken, type, id);

		if (response.status === ResponseStatus.SUCCESS) {
			type === NamedPicType.AVATAR ? getAvatars() : getEmotions();
			return;
		}

		addToast({
			title: translations.toasts.error,
			text: 'error',
			variant: 'error',
		});
	};

	const patchEmotion = async (id: number, pic: File, payload?: string) => {
		const response = await SettingsService.patchNamedPic(
			accessToken,
			NamedPicType.EMOTION,
			id,
			pic,
			payload
		);
		if (response.status === ResponseStatus.SUCCESS) {
			getEmotions();
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const getIcons = async (themeId: number) => {
		const response = await SettingsService.getIcons(accessToken, themeId);
		if (response.status === ResponseStatus.SUCCESS) {
			setIcons(response.data);
		}
		return response.data;
	};

	const patchIcon = async (themeId: number, id: number, pic: File, payload?: string) => {
		const response = await SettingsService.patchNamedPic(
			accessToken,
			NamedPicType.ICON,
			id,
			pic,
			payload,
			themeId
		);
		if (response.status === ResponseStatus.SUCCESS) {
			getIcons(themeId);
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			return;
		}
		addToast({
			title: translations.toasts.error,
			text: translations.toasts.error,
			variant: 'warning',
		});
	};

	const patchSettings = async (value: Settings) => {
		const response = await SettingsService.patchSettings(accessToken, value);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
			return;
		}
		getSettings();
	};

	const patchGlobalSettings = async (value: GlobalSettings) => {
		const response = await SettingsService.patchGlobalSettings(accessToken, value);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
			return;
		}
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.success,
			variant: 'success',
		});
		getGlobalSettings();
	};

	const updateLoader = async (value: File) => {
		const response = await SettingsService.patchLoader(accessToken, value);
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
			return;
		}
		addToast({
			title: translations.toasts.success,
			text: translations.toasts.success,
			variant: 'success',
		});
		getSettings();
	};

	const exportSettings = async () => {
		const response = await SettingsService.exportSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			setExportJSON(JSON.stringify(response.data, null, '\t'));
		}
	};

	const importSettings = async (settingsData: any) => {
		const response = await SettingsService.importSettings(accessToken, settingsData);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
			exportSettings();
			return;
		}
		setError(response.data);
	};

	const resetSettings = async () => {
		await SettingsService.resetSettings(accessToken);
		window.location.reload();
	};

	const initSettings = async () => {
		const response = await SettingsService.initSettings(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		}
	};

	const postGreeting = async (value: Greeting) => {
		const response = await SettingsService.postGreeting(accessToken, value);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		}
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const patchGreeting = async (id: number, value: Greeting) => {
		const response = await SettingsService.patchGreeting(accessToken, id, value);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		}
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const deleteGreeting = async (id: number) => {
		const response = await SettingsService.deleteGreeting(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		} else {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const getChatbotSettings = async () => {
		const response = await SettingsService.getChatbotSettings(accessToken);
		return response;
	};

	const patchChatbotSettings = async (chatbotData: Chatbot) => {
		const response = await SettingsService.patchChatbotSettings(accessToken, chatbotData);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		} else {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const getChatbotModels = async (apikey: string) => {
		const response = await SettingsService.getChatbotModels(accessToken, apikey);
		return response;
	};

	const getRoomStatus = async () => {
		const response = await SettingsService.getRoomStatus(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	const postRoomStatus = async (value: any) => {
		const response = await SettingsService.postRoomStatus(accessToken, value);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		} else {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const patchRoomStatus = async (value: any) => {
		const response = await SettingsService.patchRoomStatus(accessToken, value);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		}
		if (response.status === ResponseStatus.ERROR) {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const deleteRoomStatus = async (id: number) => {
		const response = await SettingsService.deleteRoomStatus(accessToken, id);
		if (response.status === ResponseStatus.SUCCESS) {
			addToast({
				title: translations.toasts.success,
				text: translations.toasts.success,
				variant: 'success',
			});
		} else {
			addToast({
				title: translations.toasts.error,
				text: translations.toasts.error,
				variant: 'warning',
			});
		}
	};

	const getRoomStatusDefault = async () => {
		const response = await SettingsService.getRoomStatusDefault(accessToken);
		if (response.status === ResponseStatus.SUCCESS) {
			return response.data;
		}
		return false;
	};

	return {
		getSettings,
		getDefaultSettings,
		getThemes,
		getTheme,
		getColors,
		setColors,
		getFont,
		uploadFont,
		deleteFont,
		getEmotions,
		getAvatars,
		addNamedPic,
		deleteNamedPic,
		patchEmotion,
		getIcons,
		patchIcon,
		patchSettings,
		updateLoader,
		exportSettings,
		importSettings,
		resetSettings,
		initSettings,
		postGreeting,
		patchGreeting,
		deleteGreeting,
		getChatbotSettings,
		patchChatbotSettings,
		getChatbotModels,
		getRoomStatus,
		getRoomStatusDefault,
		postRoomStatus,
		patchRoomStatus,
		deleteRoomStatus,
		getGlobalSettings,
		patchGlobalSettings,
	};
};

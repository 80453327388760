import {observer, useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {FunctionComponent, useEffect, useState} from 'react';

import {Button, Modal, Form, FloatingLabel} from 'react-bootstrap';
import modalServices from 'store/modalServices';

import useL10n from 'l10n/useL10n';
import useAdminProxy from 'hooks/useAdminProxy';

interface IChangeRoleModal {
	onCloseSuccess: () => void;
	onCloseError: (error: string) => void;
	admin: any;
}

const ChangeRoleModal: FunctionComponent<IChangeRoleModal> = function ChangeRoleModal({
	onCloseSuccess,
	onCloseError,
	admin,
}) {
	const [validated, setValidated] = useState(false);
	const [role, setRole] = useState('ADMIN');

	const {roleModalVisible, hideRoleModal} = useLocalObservable(() => modalServices);

	const translations = useL10n();
	const {updateProjectUser} = useAdminProxy();

	const clear = () => {
		setRole('ADMIN');
		setValidated(false);
	};

	const changeRole = async () => {
		const response = await updateProjectUser(admin.id, {role});
		if (response.status === ResponseStatus.SUCCESS) {
			if (response.data.error) {
				onCloseError(response.data.error);
			} else {
				onCloseSuccess();
				clear();
				hideRoleModal();
			}
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) setValidated(true);
		else changeRole();
	};

	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const {value} = event.target;
		const valueTrim = value.trim();
		setRole(valueTrim);
	};

	const onModalClose = () => {
		clear();
		hideRoleModal();
	};

	useEffect(() => {
		setRole(admin.role);
	}, [admin]);

	return (
		<Modal
			className='modal--dark'
			show={roleModalVisible}
			onHide={onModalClose}
			animation={false}
			centered
			backdrop='static'
			restoreFocus={false}>
			<Modal.Header closeButton className='text-primary'>
				<Modal.Title className='text-primary'> {translations.toasts.changeRole}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<FloatingLabel
						className='mb-3'
						controlId='floatingSelect'
						label={translations.table.role}>
						<Form.Select onChange={onSelect} value={role}>
							<option value='ADMIN'>ADMIN</option>
							<option value='MODER'>MODER</option>
						</Form.Select>
					</FloatingLabel>
					<Modal.Footer>
						<Button variant='secondary' onClick={hideRoleModal}>
							{translations.btns.cancel}
						</Button>
						<Button variant='success' type='submit'>
							{translations.btns.save}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default observer(ChangeRoleModal);

import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import useL10n from 'l10n/useL10n';

import {Form, InputGroup, Nav} from 'react-bootstrap';
import pollServices from 'store/pollServices';
import alertServices from 'store/alertServices';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {isArray, isString} from 'lodash';
import {Room} from 'models/rooms';

const PollRooms: FunctionComponent = function PollRooms() {
	const {slug} = useParams<{
		slug: string;
	}>();

	const translations = useL10n();

	const {currentPoll, updateCurrentPoll, setIsRoomsEmpty} = useLocalObservable(() => pollServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertServices);

	const [externalRoomIds, setExternalRoomIds] = useState<string | string[] | File>([]);
	const [isManual, setIsManual] = useState(true);
	const [csvData, setCsvData] = useState<any>([]);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const onChangeIManual = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;

		setExternalRoomIds([]);
		updateCurrentPoll({
			externalRoomIds: [],
		});
		if (inputFileRef.current) inputFileRef.current.value = '';
		setIsManual(!isManual);
		setIsRoomsEmpty(true);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		//  = value.trim().split(',');
		const roomIds = value
			.trim()
			.split(',')
			.map(id => {
				return id.trim();
			});
		if (roomIds.length > 100) {
			showAlert({
				title: translations.highlights.rooms,
				text: translations.highlights.maxRooms,
				buttons: [
					{
						text: translations.alerts.btns.ok,
						type: AlertBtnType.NORMAL,
						onClick: () => {
							hideAlert();
						},
					},
				],
			});
			return;
		}
		setExternalRoomIds(roomIds);
		updateCurrentPoll({
			externalRoomIds: roomIds,
		});

		setIsRoomsEmpty(value.trim().length === 0);
	};
	const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (
			(event.target as HTMLInputElement).files &&
			(event.target as HTMLInputElement).files?.length
		) {
			const file = event.target.files ? event.target.files[0] : null;
			if (file) {
				updateCurrentPoll({
					externalRoomIds: file,
				});
				setIsRoomsEmpty(false);
			}
		}
	};

	const exportToCsv = () => {
		const csvContent = `data:text/csv;charset=utf-8,${csvData
			.map((e: any) => e.join(','))
			.join('\n')}`;

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'Rooms.csv');
		document.body.appendChild(link);

		link.click();
	};

	const prepareStringToCsvFormat = () => {
		let data: any;
		if (typeof externalRoomIds === 'string') {
			data = `${externalRoomIds}`;
			data = data.split(',');
			data = data.map((element: any) => {
				return [element];
			});
		}

		return data;
	};

	const getExternalRoomIds = (roomPolls: {id: number; room: Room}[]) => {
		const arr = roomPolls.map(item => {
			return item.room.externalRoomId;
		});
		return arr?.join();
	};

	useEffect(() => {
		slug && typeof externalRoomIds === 'string' && setCsvData(prepareStringToCsvFormat);
		if (currentPoll?.roomPolls?.length)
			setExternalRoomIds(getExternalRoomIds(currentPoll?.roomPolls));
		if (currentPoll?.externalRoomIds) setExternalRoomIds(currentPoll?.externalRoomIds);
	}, [currentPoll]);

	return (
		<div className='mb-3'>
			<Form>
				{(isArray(externalRoomIds) || isString(externalRoomIds)) && (
					<Form.Group controlId='highlightRooms' className='mb-5'>
						{!slug && <Form.Label>{translations.highlights.manual}</Form.Label>}

						<InputGroup className='mb-2'>
							<InputGroup.Checkbox
								className='checkbox-black'
								checked={isManual}
								onChange={onChangeIManual}
								disabled={slug !== undefined}
							/>

							<Form.Control
								name='linkText'
								required={isManual}
								disabled={!isManual || slug !== undefined}
								placeholder='Room1, Room2'
								as='textarea'
								value={externalRoomIds}
								onChange={onChangeHandler}
							/>
						</InputGroup>
						{/* {slug && (
							<Nav.Link onClick={exportToCsv}>{translations.highlights.downloadFile}</Nav.Link>
						)} */}
					</Form.Group>
				)}

				{!slug && (
					<Form.Group>
						<Form.Label>{translations.highlights.downloadRoomList}</Form.Label>

						<InputGroup>
							<InputGroup.Checkbox
								className='checkbox-black'
								checked={!isManual}
								onChange={onChangeIManual}
							/>

							<Form.Control
								type='file'
								required={!isManual}
								ref={inputFileRef}
								onChange={uploadFile}
								accept='.csv'
								disabled={isManual}
							/>
						</InputGroup>
					</Form.Group>
				)}
			</Form>
		</div>
	);
};

export default observer(PollRooms);

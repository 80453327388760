enum BanReasonEnum {
	INSULTS,
	PERSONAL,
	FLOOD,
	LINKS,
	VIOLENCE,
	FRAUD,
	NICKNAME,
	POLITICAL,
	REPEATED,
	AUTO,
	SPAM,
}

export {BanReasonEnum};
